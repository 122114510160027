import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import {
  DetailedHTMLProps,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { REQUEST_TRIAL } from '../../../apiPublic/queries';
import {
  RequestTrialMutation,
  RequestTrialMutationVariables,
} from '../../../gql/graphql';
import { PUBLIC_CLIENT } from '../../../services/apollo';
import { useSession } from '../../../utils/hooks/useSession';
import { Message } from '../../common/Message';
import { FieldError } from '../../form/fields';
import Spinner from '../Spinner/Spinner';

const inputClassName =
  'w-2/3 rounded-none rounded-r-md bg-tw-light-shade px-3 text-xs placeholder:text-[#A4A4A4] dark:bg-tw-light-bg-dark';

const FormLine = ({
  label,
  name,
  placeholder,
  onChange,
  error,
  ...rest
}: DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string;
  name: string;
  placeholder?: string;
  error?: string;
}) => {
  return (
    <div className="text-right">
      <div className="mt-2 flex max-w-full justify-center rounded-md bg-tw-light-shade dark:bg-tw-light-bg-dark">
        <label
          htmlFor={name}
          className="inline-flex w-1/3 items-center rounded-l-md p-2 px-3 text-xs"
        >
          {label}
        </label>
        <span className="my-2 border-r-2 border-[#DAE1EE]"></span>

        <input
          name={name}
          id={name}
          autoComplete={name}
          className={twMerge(
            classNames(inputClassName, error && 'ring-2 ring-red-500'),
          )}
          placeholder={placeholder}
          onChange={onChange}
          {...rest}
        />
      </div>
      <FieldError error={error} />
    </div>
  );
};

const VALID_EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const TrialModalContent = ({
  product,
  onSubmit,
}: {
  product: string;
  onSubmit: (args: { email: string; name: string; company: string }) => void;
}) => {
  const { session } = useSession();

  const email = session?.identity?.traits?.email || '';
  const name = session?.identity?.traits?.name || '';
  const company = session?.identity?.traits?.company || '';

  const [variables, setVariables] = useState<{
    email: string;
    name: string;
    company: string;
  }>({
    email,
    name,
    company,
  });

  const [requestTrial, requestTrialMutation] = useMutation<
    RequestTrialMutation,
    RequestTrialMutationVariables
  >(REQUEST_TRIAL, {
    client: PUBLIC_CLIENT,
  });

  const [isTouched, setIsTouched] = useState(false);

  const handleSubmit = useCallback(() => {
    setIsTouched(true);
    const { email, name, company } = variables;
    requestTrial({
      variables: {
        input: {
          email,
          user: name,
          company,
          product,
        },
      },
    }).then(() => {
      onSubmit(variables);
    });
  }, [product, requestTrial, variables, onSubmit]);

  const [errors, setErrors] = useState<{
    email?: string;
    name?: string;
    company?: string;
  }>({});

  useEffect(() => {
    isTouched &&
      setErrors({
        company:
          variables.company && variables.company.length > 2
            ? ''
            : 'Company is required',
        name:
          variables.name && variables.name.length > 2 ? '' : 'Name is required',
        email:
          variables.email && variables.email.length > 2
            ? // check if email is valid
              VALID_EMAIL_REGEX.test(variables.email)
              ? ''
              : 'Email is not valid'
            : 'Email is required',
      });
    requestTrialMutation.loading && setErrors({});
  }, [variables, isTouched, requestTrialMutation.loading]);

  const hasAnyError = Object.values(errors).some((e) => e);

  return (
    <div
      data-testid="TrialModalContent"
      className="flex min-w-[30rem] flex-col gap-4 text-[14px] text-tw-main-text dark:text-tw-main-text-dark"
    >
      <div className="flex w-full flex-col items-center">
        <div className="flex w-full flex-col items-start">
          <p className="mb-2">
            Let us know a little more in order to connect you with one of our
            experts.
          </p>
        </div>
        <div className="w-full">
          <FormLine
            label="Email *"
            name="email"
            placeholder="Enter your email here"
            onChange={(e) =>
              setVariables({ ...variables, email: e.target.value })
            }
            error={errors.email}
            type="email"
            value={variables.email}
          />
          <FormLine
            label="Name *"
            name="name"
            placeholder="Enter your name here"
            onChange={(e) =>
              setVariables({ ...variables, name: e.target.value })
            }
            error={errors.name}
            value={variables.name}
          />
          <FormLine
            label="Company *"
            name="company"
            placeholder="Enter your company name here"
            onChange={(e) =>
              setVariables({ ...variables, company: e.target.value })
            }
            error={errors.company}
            value={variables.company}
          />
        </div>
      </div>

      {requestTrialMutation.error && (
        <div className="w-full max-w-[30rem]">
          <Message
            type="error"
            children={requestTrialMutation.error?.message}
          />
        </div>
      )}
      {/* Button */}
      <div className="flex h-full w-full flex-row items-end justify-end">
        <button
          onClick={handleSubmit}
          disabled={requestTrialMutation.loading || hasAnyError}
          className="rounded-md bg-blue-600 px-8 py-2 text-white hover:bg-blue-400"
          data-testid="TrialModalContent-Button"
        >
          {requestTrialMutation.loading ? (
            <Spinner className="h-5 w-5" />
          ) : (
            <span>Continue</span>
          )}
        </button>
      </div>
    </div>
  );
};
