import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useFeature } from 'featurehub-react-sdk';
import { capitalize } from 'lodash';
import { isEmpty } from 'ramda';
import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { ApplianceStatus, GetInventoryQuery } from '../../../../gql/graphql';
import { FeatureFlag } from '../../../../utils/featureHub/constants';
import { OverflowTooltip } from '../../../common/OverflowTooltip';
import { InfoCard } from './InfoCard';

export const ApplianceCardDetails = ({
  appliance,
}: {
  appliance: GetInventoryQuery['appliances'][0];
}) => {
  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(appliance?.applianceId);
    toast.success(
      <ToastMessage title={'Copied to Clipboard'}>
        Appliance ID copied to Clipboard
      </ToastMessage>,
    );
  }, [appliance]);

  const managedDevices = useMemo(
    () =>
      appliance?.installedProducts
        ?.flatMap((p) => p.licenses.flatMap((l) => l.licensedDevices))
        // Remove duplicates
        .reduce<
          GetInventoryQuery['appliances'][0]['installedProducts'][0]['licenses'][0]['licensedDevices']
        >(
          (unique, item) =>
            unique.find(
              (licensedDevice) =>
                licensedDevice.device.guid === item.device.guid,
            )
              ? unique
              : [...unique, item],
          [],
        ),
    [appliance],
  );

  const applianceOnlineStatusFlag = useFeature(
    FeatureFlag.APPLIANCE_ONLINE_STATUS,
  );

  const status =
    !appliance.online && applianceOnlineStatusFlag
      ? 'Offline'
      : appliance.status;

  return (
    <div
      data-testid="appliance-card-detail"
      className={classNames(
        'grid grid-cols-5 items-start gap-4 xl:grid-cols-6',
        'border-x border-b border-tw-border  dark:border-tw-border-dark',
        'bg-tw-background px-4 py-3 dark:bg-tw-background-dark',
      )}
    >
      <InfoCard
        className="col-span-2"
        title="Appliance ID"
        data-testid="info-id"
        content={
          <button
            onClick={handleCopy}
            className="group flex w-full gap-1 truncate"
          >
            <span className="truncate">{appliance?.applianceId}</span>
            <FontAwesomeIcon
              data-testid="copy-to-clipboard-icon"
              className="text-tw-label-tertiary transition-colors group-hover:text-tw-label-primary dark:text-tw-label-tertiary-dark group-hover:dark:text-tw-label-primary-dark"
              icon={faCopy}
            />
          </button>
        }
      />
      <InfoCard
        title="IP Address"
        content={appliance?.ipv4 || '-'}
        data-testid="info-ip-address"
      />
      <InfoCard
        title="Location"
        content={
          [appliance?.location?.city, appliance?.location?.country]
            .filter(Boolean)
            .join(', ') || '-'
        }
        data-testid="info-location"
      />
      <InfoCard
        title="Operating System"
        content={appliance?.osVersion || '-'}
        data-testid="info-operating-system"
      />
      {!isEmpty(managedDevices) && (
        <InfoCard
          title="Managed Devices"
          data-testid="info-managed-devices"
          className="col-span-2"
          content={
            <OverflowTooltip className="block truncate">
              {managedDevices
                .map((device) => device.device.name || device.device.guid)
                .join(', ')}
            </OverflowTooltip>
          }
        />
      )}
      <InfoCard
        title="Status"
        className="col-span-2"
        content={
          <div className="flex gap-2">
            <span
              className={classNames(
                (status === ApplianceStatus.Inactive ||
                  status === ApplianceStatus.Pending) &&
                  'text-tw-warning-strong dark:text-tw-warning-color-dark',
                status === 'Offline' &&
                  'text-tw-danger-strong dark:text-tw-danger-strong-dark',
              )}
            >
              {capitalize(status)}
            </span>
            {status === 'Offline' && appliance?.lastSeen && (
              <span
                className={`flex items-center gap-2 font-graphikMedium text-tw-label-primary dark:text-tw-label-primary-dark`}
                style={{ fontSize: '12px' }}
                data-testid="info-last-seen"
              >
                Last Seen{' '}
                {dayjs(appliance?.lastSeen).format('MM/DD/YYYY HH:MM')}
              </span>
            )}
          </div>
        }
        data-testid="info-status"
      />
    </div>
  );
};

export const ToastMessage = ({
  title,
  children,
}: {
  title?: React.ReactNode;
  children: React.ReactNode;
}) => {
  return title ? (
    <div className="flex flex-col">
      <div className="font-graphikMedium text-sm text-tw-label-primary dark:text-tw-label-primary-dark">
        {title}
      </div>
      <div className="font-graphikMedium text-xs text-tw-label-tertiary dark:text-tw-label-tertiary-dark">
        {children}
      </div>
    </div>
  ) : (
    <>children</>
  );
};
