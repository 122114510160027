import React from 'react';

export const GlobeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      width="1em"
      viewBox="0 0 35 32"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M17.5 29C24.0625 29 29.5 24.125 30.375 17.75L28.5625 17.3125C27.875 17.125 27.3125 16.625 27.125 16L26.0625 12.8125C25.75 11.8125 26.25 10.75 27.25 10.3125L28.8125 9.6875C26.6875 5.875 22.6875 3.25 18.125 3.0625L18.25 3.3125C18.6875 4.0625 18.625 4.9375 18.0625 5.625L17.0625 6.75C16.4375 7.5 16.4375 8.5625 17.0625 9.3125L17.875 10.3125C18.25 10.75 18.25 11.3125 18 11.75C17.6875 12.1875 17.125 12.375 16.625 12.25L15.125 11.75C14.6875 11.625 14.25 11.625 13.8125 11.875L13.4375 12.0625C12.875 12.3125 12.625 12.9375 12.8125 13.5625C12.9375 13.875 13.125 14.125 13.375 14.25L15.5625 15.5C16.125 15.8125 16.8125 16 17.5625 16H20.25C21 16 21.8125 16.3125 22.375 16.875L22.5625 17.125C23.125 17.6875 23.5 18.5 23.5 19.25V19.8125C23.5 20.875 22.875 21.875 21.9375 22.375L21.375 22.75C20.5 23.25 19.8125 24.0625 19.5 25.125L19.4375 25.4375C19.125 26.4375 18.375 27.1875 17.375 27.5C16.4375 27.75 15.5 27.0625 15.5 26.0625V24.125C15.5 23.4375 15.0625 22.8125 14.5 22.5C13.875 22.25 13.5 21.625 13.5 20.9375V18.4375C13.5 17.3125 12.75 16.3125 11.6875 16L8.0625 15C7.0625 14.6875 6.1875 14 5.6875 13.125L5.0625 12.0625C4.6875 13.3125 4.5 14.625 4.5 16C4.5 23.1875 10.3125 29 17.5 29ZM17.5 32C11.75 32 6.5 29 3.625 24C0.75 19.0625 0.75 13 3.625 8C6.5 3.0625 11.75 0 17.5 0C23.1875 0 28.4375 3.0625 31.3125 8C34.1875 13 34.1875 19.0625 31.3125 24C28.4375 29 23.1875 32 17.5 32Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default GlobeIcon;
