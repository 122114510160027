import React from 'react';

export const ContentAnalysisIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M16.7374 3.78027L13.4971 0.540039C13.15 0.192871 12.6832 0 12.1933 0H4.32417C3.30195 0.00385742 2.46875 0.833203 2.46875 1.85542V10.4922H0.617188C0.277734 10.4922 0 10.7699 0 11.1094V11.7266C0 12.066 0.277734 12.3438 0.617188 12.3438H4.98379L6.23745 14.8549C6.46504 15.3101 7.11309 15.3101 7.34067 14.8549L9.25781 11.0207L9.92129 12.3438H13.2695C13.7787 12.3438 14.1953 11.9271 14.1953 11.418C14.1953 10.9088 13.7787 10.4922 13.2695 10.4922H11.0631L9.80942 7.98101C9.58184 7.52583 8.93379 7.52583 8.7062 7.98101L6.78906 11.8153L6.12944 10.4922H4.32031V1.85542H10.4922V5.86714C10.4922 6.38018 10.9049 6.79292 11.418 6.79292H15.4297V17.8984H4.32417V14.1953H2.46875V17.8984C2.46875 18.9207 3.2981 19.75 4.32031 19.75H15.4297C16.4519 19.75 17.2812 18.9207 17.2812 17.8984V5.0918C17.2812 4.59805 17.0845 4.12744 16.7374 3.78027ZM12.3438 4.94136V2.00586L15.2792 4.94136H12.3438Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ContentAnalysisIcon;
