import React from 'react';

export const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <circle
        cx="12"
        cy="12"
        r="7.25"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M14.8233 13.9763C15.0577 14.2107 15.0577 14.5903 14.8233 14.8247C14.7071 14.9419 14.5533 15 14.3996 15C14.2458 15 14.0925 14.9414 13.9755 14.8242L11.9998 12.8495L10.0242 14.8238C9.90705 14.9419 9.7535 15 9.59995 15C9.4464 15 9.29304 14.9419 9.17577 14.8238C8.94141 14.5894 8.94141 14.2097 9.17577 13.9754L11.1519 11.9993L9.17577 10.0241C8.94141 9.78978 8.94141 9.41012 9.17577 9.17577C9.41012 8.94141 9.78978 8.94141 10.0241 9.17577L11.9998 11.1528L13.9759 9.17671C14.2102 8.94235 14.5899 8.94235 14.8242 9.17671C15.0586 9.41106 15.0586 9.79072 14.8242 10.0251L12.8481 12.0012L14.8233 13.9763Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloseIcon;
