import { Transition } from '@headlessui/react';
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  GetManagedDeviceQuery,
  Location,
  UpdateManagedDeviceInput,
} from '../../gql/graphql';
import { INCONPLETE_IPV4_MASK, isIpv4 } from '../../utils/format';
import { SimpleButton } from '../Buttons/SimpleButton';
import { ResolvedLocationSelect } from '../common/LocationSelect';
import { Message } from '../common/Message';
import { ConfirmationModalTrigger } from '../common/Modal';
import { InputText } from '../form/fields';
import { Switch } from '../form/fields/Switch/Switch';

export interface EditDeviceModalProps {
  onUpdate?: (appliance: UpdateManagedDeviceInput) => void;
  loading?: boolean;
  error?: string;
  device: GetManagedDeviceQuery['managedDevice'] | undefined;
  onDeleteConfirm?: (
    appliance: GetManagedDeviceQuery['managedDevice'] | undefined,
  ) => boolean | Promise<boolean>;
}

const EditDeviceModal = ({
  device,
  onUpdate,
  loading,
  error,
  onDeleteConfirm,
}: EditDeviceModalProps) => {
  const [touched, setTouched] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>(
    {},
  );

  const [ipv4, setIpv4] = useState<string | undefined | null>(device?.ipv4);
  const [location, setLocation] = useState<Location | undefined | null>(
    device?.location,
  );
  const [viewable, setViewable] = useState(device?.location ? true : false);

  useEffect(() => {
    setLocation(device?.location);
  }, [device?.location]);
  useEffect(() => {
    setIpv4(device?.ipv4);
  }, [device?.ipv4]);
  useEffect(() => {
    setTouched(false);
  }, [device]);

  const handleIpv4Change = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const validInput = e.currentTarget.value.match(INCONPLETE_IPV4_MASK);

      validInput && setTouched(true);
      validInput && setIpv4(e.currentTarget.value);
      errors.ipv4 &&
        validInput &&
        setErrors((prev) => ({
          ...prev,
          ipv4: undefined,
        }));
    },
    [errors],
  );

  const handleChangeViewable = useCallback((value: boolean) => {
    setViewable(value);
    setTouched(true);
  }, []);

  const handleLocationChange = useCallback(
    (result: Location | null) => {
      setLocation(result);
      setTouched(true);
      errors.location &&
        setErrors((prev) => ({
          ...prev,
          location: undefined,
        }));
    },
    [errors],
  );

  const onSubmitUpdate = (event?: FormEvent<HTMLFormElement>) => {
    event?.stopPropagation();
    event?.preventDefault();

    const errors = {
      ipv4: !ipv4 || isIpv4(ipv4 || '') ? undefined : 'Invalid IP Address',
      location:
        viewable && !location
          ? 'Location required when device is viewable on map'
          : undefined,
    };
    setErrors(errors);

    if (Object.values(errors).filter(Boolean).length) return;

    // Submit update to backend
    device &&
      onUpdate?.({
        guid: device.guid,
        ipv4,
        location:
          viewable && location
            ? {
                country: location.country,
                city: location.city,
                latitude: location.latitude,
                longitude: location.longitude,
              }
            : undefined,
      });
    setTouched(false);
  };

  return (
    <div
      data-testid="edit-device-form"
      className="w-96 items-center justify-start overflow-visible p-[2px] text-left text-xs"
    >
      <form onSubmit={onSubmitUpdate} className="flex flex-col gap-4">
        <div className="gap-0">
          <InputText
            data-testid="device-ip-address"
            name="device-ip-address"
            label="IP Address"
            value={ipv4 || ''}
            onChange={handleIpv4Change}
            error={errors.ipv4}
          />
        </div>
        <Switch
          data-testid="location-switch"
          checked={viewable}
          onChange={handleChangeViewable}
          labelPosition="right"
        >
          <span className="text-xs">Device viewable on map</span>
        </Switch>
        <Transition
          show={viewable}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <div data-testid="location-select">
            <ResolvedLocationSelect
              data-testid="device-location-select"
              onResolved={handleLocationChange}
              value={location}
              label="Location"
              error={errors.location}
            />
          </div>
        </Transition>
        <div className="flex flex-row gap-4">
          {error && (
            <Message data-testid="device-location-select" type="error">
              <span className="text-xs">
                Error updating managed device: {error || 'Unexpected error'}
              </span>
            </Message>
          )}
          <div className="ml-auto flex h-min shrink gap-4">
            <ConfirmationModalTrigger
              modalTitle="Delete managed device"
              modalContent={
                <div className="max-w-prose text-left">
                  <p data-testid="delete-confirm-message">
                    Are you sure you would like to remove "
                    <strong>{device?.name || device?.guid}</strong>"? You cannot
                    undo this action.
                  </p>
                </div>
              }
              onClick={(e) => {
                e.stopPropagation(), e.preventDefault();
              }}
              onConfirm={
                onDeleteConfirm ? () => onDeleteConfirm?.(device) : undefined
              }
            >
              <SimpleButton
                data-testid="device-remove-button"
                type="button"
                className="w-24 px-4"
                disabled={loading || !device}
                variant="critical"
              >
                Remove
              </SimpleButton>
            </ConfirmationModalTrigger>
            <SimpleButton
              data-testid="device-update-button"
              type="submit"
              className="ml-auto w-24 px-4"
              disabled={loading || !touched}
              loading={loading}
            >
              Update
            </SimpleButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditDeviceModal;
