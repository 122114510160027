/* eslint-disable no-case-declarations */
import {
  JSONSchema7,
  JSONSchema7Definition,
  JSONSchema7Type,
} from 'json-schema';
import { Schema, Validator, ValidatorResult, validate } from 'jsonschema';
import { isNil } from 'ramda';
import {
  FormFieldElement,
  FormFieldType,
  FormFieldValue,
  FormFields,
  ItemsTypeFormat,
  JSONObject,
  JSONValue,
} from '.';
import {
  JSONSchema7DefinitionWithUI,
  JSONSchema7Extended,
} from '../../../api/settings/types';
import { PickerOptions } from '../fields';

const anyOfOptionToValue = (
  option: JSONSchema7Definition,
  returnTitle = false,
): string | number | null => {
  return option === true || option === false
    ? `${option}`
    : returnTitle && option.title
    ? option.title
    : typeof option.const === 'string' || typeof option.const === 'number'
    ? option.const
    : option.enum &&
      !isNil(option.enum[0]) &&
      (typeof option.enum[0] === 'string' || typeof option.enum[0] === 'number')
    ? option.enum[0]
    : null;
};

const anyOfToOptions = (
  anyOf: JSONSchema7['anyOf'] | JSONSchema7['oneOf'],
): PickerOptions => {
  if (!anyOf) return [];
  return anyOf.map((option) => {
    const optionToValue = anyOfOptionToValue(option);
    const value = isNil(optionToValue) ? '' : optionToValue;
    return {
      label: anyOfOptionToValue(option, true),
      value: value,
    };
  });
};

export const PATH_SEPARATOR = '.';

export const propertyToFormFieldElement = ({
  property,
  key,
  value,
  required,
  parentPath = [],
}: {
  property: JSONSchema7DefinitionWithUI;
  key: string;
  value: JSONValue | null;
  required?: JSONSchema7['required'];
  parentPath: string[];
}): FormFieldElement | null => {
  if (
    property === undefined ||
    property === null ||
    property === false ||
    property === true
  ) {
    return null;
  }
  const baseProps = {
    id: `${parentPath.join(PATH_SEPARATOR)}${
      parentPath.length ? PATH_SEPARATOR : ''
    }${key}`,
    title: property.title || key,
    description: property.description,
    examples: property.examples,
    required: required?.includes(key),
    path: [...parentPath, key],
    disabled: property.readOnly,
  };

  if (property.anyOf) {
    return {
      type: FormFieldType.MULTI_SELECT,
      value: (value as string[]) || property.default,
      ...baseProps,
      options: anyOfToOptions(property.anyOf),
    };
  }
  if (property.oneOf) {
    return {
      type:
        property.uiProperties?.component === 'radioGroup'
          ? FormFieldType.RADIOGROUP
          : FormFieldType.SELECT,
      value: (value || property.default) as string,
      ...baseProps,
      options: anyOfToOptions(property.oneOf),
    };
  }
  if (property.enum) {
    return {
      type:
        property.uiProperties?.component === 'radioGroup'
          ? FormFieldType.RADIOGROUP
          : FormFieldType.SELECT,
      value: (value as string) || (property.default as string),
      options: enumToOptions(property.enum),
      ...baseProps,
    };
  }

  switch (property.type) {
    case 'string':
      switch (property.format) {
        case 'date':
          return {
            type: FormFieldType.DATE,
            ...(typeof value === 'string'
              ? { value }
              : typeof property.default === 'string'
              ? { value: property.default }
              : undefined),
            ...baseProps,
          };
        case 'date-time':
          return {
            type: FormFieldType.DATE_TIME,
            ...(typeof value === 'string'
              ? { value }
              : typeof property.default === 'string'
              ? { value: property.default }
              : undefined),
            ...baseProps,
          };
        case 'time':
          return {
            type: FormFieldType.TIME,
            ...(typeof value === 'string'
              ? { value }
              : typeof property.default === 'string'
              ? { value: property.default }
              : undefined),
            ...baseProps,
          };
        default:
          return {
            type:
              (property.minLength && property.minLength > 49) ||
              property.uiProperties?.component === 'textArea'
                ? FormFieldType.TEXT_AREA
                : FormFieldType.TEXT,
            ...(typeof value === 'string'
              ? { value }
              : typeof property.default === 'string'
              ? { value: property.default }
              : undefined),
            ...baseProps,
            secret: property.secret,
            minLength: property.minLength,
            maxLength: property.maxLength,
            pattern: property.pattern,
            // format: property.format,
          };
      }

    case 'integer':
    case 'number':
      return {
        type: FormFieldType.INPUT_NUMBER,
        ...(typeof value === 'number'
          ? { value }
          : typeof property.default === 'number'
          ? { value: property.default }
          : undefined),
        ...baseProps,
        format: property.type,
        min:
          property.minimum || property.minimum === 0
            ? property.minimum
            : property.exclusiveMinimum || property.exclusiveMinimum === 0
            ? property.exclusiveMinimum + 1
            : undefined,
        max:
          property.maximum || property.maximum === 0
            ? property.maximum
            : property.exclusiveMaximum || property.exclusiveMaximum === 0
            ? property.exclusiveMaximum - 1
            : undefined,
        multipleOf: property.multipleOf,
      };
    case 'boolean':
      return {
        type: FormFieldType.CHECKBOX,
        ...(typeof value === 'boolean'
          ? { value }
          : typeof property.default === 'boolean'
          ? { value: property.default }
          : undefined),
        ...baseProps,
      };
    case 'array':
      return typeof property.contains !== 'boolean' && property.contains?.enum
        ? {
            type: FormFieldType.MULTI_SELECT,
            ...(Array.isArray(property.default)
              ? { value: property.default }
              : undefined),
            value: (value || property.default) as string[],
            options: enumToOptions(property.contains.enum),
            minItems: property.minItems,
            maxItems: property.maxItems,
            ...baseProps,
          }
        : property.items &&
          property.uniqueItems &&
          typeof property.items !== 'boolean' &&
          !Array.isArray(property.items) &&
          (property.items?.enum || property.items?.oneOf)
        ? {
            type:
              property.uiProperties?.component === 'checkBoxGroup'
                ? FormFieldType.CHECKBOX_GROUP
                : FormFieldType.MULTI_SELECT,
            ...(Array.isArray(property.default)
              ? { value: property.default }
              : undefined),
            value: (value || property.default) as string[],
            options: property.items?.enum
              ? enumToOptions(property.items.enum)
              : property.items?.oneOf
              ? anyOfToOptions(property.items?.oneOf)
              : [],
            minItems: property.minItems,
            maxItems: property.maxItems,
            ...baseProps,
          }
        : property.uiProperties?.component === 'membersList'
        ? {
            type: FormFieldType.MEMBERS_LIST,
            ...(Array.isArray(property.default)
              ? { value: property.default }
              : undefined),
            value: (value || property.default) as string[],
            minItems: property.minItems,
            maxItems: property.maxItems,
            uniqueItems: property.uniqueItems,
            items: JSONSchema7ItemsToItemsTypeFormat(property.items),
            placeholder: JSONSchema7ItemsDefault(property.items) || undefined,
            formFieldElement: (index: number) => {
              return property.items &&
                typeof property.items !== 'boolean' &&
                !Array.isArray(property.items)
                ? propertyToFormFieldElement({
                    property: property.items,
                    key: `item[${index}]`,
                    value: null,
                    parentPath: [...parentPath, key],
                  })
                : null;
            },
            ...baseProps,
          }
        : {
            type: FormFieldType.LIST,
            ...(Array.isArray(property.default)
              ? { value: property.default }
              : undefined),
            value: (value || property.default) as string[],
            minItems: property.minItems,
            maxItems: property.maxItems,
            uniqueItems: property.uniqueItems,
            items: JSONSchema7ItemsToItemsTypeFormat(property.items),
            placeholder: JSONSchema7ItemsDefault(property.items) || undefined,
            formFieldElement: (index: number) => {
              return property.items &&
                typeof property.items !== 'boolean' &&
                !Array.isArray(property.items)
                ? propertyToFormFieldElement({
                    property: property.items,
                    key: `item[${index}]`,
                    value: null,
                    parentPath: [...parentPath, key],
                  })
                : null;
            },
            ...baseProps,
          };

    case 'object':
      const properties = property.properties;
      const fields =
        properties &&
        (Object.keys(properties)
          .map((subKey) =>
            propertyToFormFieldElement({
              property: properties[subKey],
              key: subKey,
              value:
                value &&
                typeof value === 'object' &&
                !Array.isArray(value) &&
                value[subKey],
              required: property.required,
              parentPath: [...parentPath, key],
            }),
          )
          .filter(Boolean) as FormFieldElement[]);
      return {
        type: FormFieldType.GROUP,
        iconName: property.uiProperties
          ? property.uiProperties.iconName
          : undefined,
        ...baseProps,
        fields: fields || [],
      };
    default:
      return property.enum
        ? {
            type: FormFieldType.SELECT,
            ...(typeof property.default === 'string' && {
              value: property.default,
            }),
            value: value as string,
            options: enumToOptions(property.enum),
            ...baseProps,
          }
        : {
            type: FormFieldType.TEXT,
            ...(typeof property.default === 'string' && {
              value: property.default,
            }),
            value: value as string,
            ...baseProps,
          };
  }
};

const JSONSchema7ItemsDefault = (
  items: JSONSchema7['items'],
): string | undefined => {
  if (!items) return undefined;
  if (Array.isArray(items)) return undefined;
  if (typeof items !== 'object') return undefined;
  if (Array.isArray(items?.default)) return undefined;
  if (typeof items?.default === 'string') return items.default;
  // if (typeof items?.default === 'number') return items.default;
  // if (typeof items?.default === 'boolean') return items.default;
  // if (typeof items?.default === 'object') return null;
  return undefined;
};

const JSONSchema7ItemsToItemsTypeFormat = (
  items: JSONSchema7['items'],
): ItemsTypeFormat | undefined => {
  return !items || Array.isArray(items) || typeof items !== 'object'
    ? undefined
    : {
        format:
          Array.isArray(items) ||
          (typeof items === 'object' && Array.isArray(items?.format))
            ? undefined
            : items?.format === 'date'
            ? 'date'
            : items?.format === 'date-time'
            ? 'date-time'
            : items?.format === 'time'
            ? 'time'
            : undefined,
        type:
          typeof items === 'object' &&
          !Array.isArray(items) &&
          !Array.isArray(items?.type)
            ? items.type === 'integer' || items.type === 'number'
              ? 'number'
              : 'text' || 'text'
            : 'text',
      };
};
// TODO: handle other than string/number
const enumToOptions = (
  jsEnum: JSONSchema7Type[] | undefined,
): PickerOptions => {
  if (typeof jsEnum === 'number' || typeof jsEnum === 'string') {
    return [jsEnum];
  }
  if (Array.isArray(jsEnum)) {
    return jsEnum
      .map((val) =>
        typeof val === 'number' || typeof val === 'string' ? val : undefined,
      )
      .filter((val) => !isNil(val)) as (number | string)[];
  }
  return [];
};
export const configSchemaToFormFields = (
  configSchema: JSONSchema7Extended,
  configValues: JSONObject,
): FormFields => {
  const formFields: FormFields = [];
  configSchema.properties &&
    Object.keys(configSchema.properties).forEach((key) => {
      const formField =
        configSchema.properties &&
        propertyToFormFieldElement({
          property: configSchema.properties[key],
          key,
          value: configValues[key],
          parentPath: [],
        });
      configSchema.properties && formField && formFields.push(formField);
    });
  return formFields;
};

export const pathAndValueToJSON = (
  path: string[],
  value: FormFieldValue,
): JSONObject => {
  const [first, ...rest] = path;
  if (rest.length === 0) {
    return {
      [first]: value as JSONValue,
    };
  }
  return {
    [first]: pathAndValueToJSON(rest, value),
  };
};

export const formFieldsToConfigValues = (
  formFields: FormFields,
): JSONObject => {
  const configValues: JSONValue = {};
  formFields.forEach((field) => {
    const fieldId = field.path?.at(-1) || field.id;
    if (field.type === FormFieldType.GROUP) {
      configValues[fieldId] = formFieldsToConfigValues(field.fields);
    } else if (field.type === FormFieldType.DATA_TABLE) {
      console.log(`${field.type} not implemented yet`);
    } else if (field.value !== undefined) {
      configValues[fieldId] = field.value as JSONValue;
    }
  });
  return configValues;
};

export const validationResultToFormFields = ({
  validationResult,
  formFields,
  parentPath,
}: {
  validationResult: ValidatorResult;
  formFields: FormFields;
  parentPath?: string;
}) => {
  const errors = validationResult.errors;
  const newFormFields: FormFields = formFields.map(
    (formField): FormFieldElement => {
      const error = errors.find((error) => {
        const path = formField.path?.join('.');
        const errorPath = error.property
          .replace(/^instance\./, '')
          // .replace(/\[(.*)\]/, '.item[$1]');
          .replace(/\[(.*)\]/, '');
        const errorPathWithProperty =
          error.name === 'required'
            ? // TODO show list errors on child field
              formField.type === 'list'
              ? `${errorPath}`
              : `${errorPath}.${error.argument}`
            : errorPath;
        return errorPathWithProperty === path;
      });
      const fields =
        formField.type === FormFieldType.GROUP &&
        validationResultToFormFields({
          validationResult,
          formFields: formField.fields,
          parentPath: parentPath
            ? `${parentPath}.${formField.id}`
            : formField.id,
        });
      const fieldsErrors: false | string[] =
        fields &&
        fields
          ?.map((field) => field.error || '')
          .filter(Boolean)
          .flat();
      const fieldError =
        error?.message ||
        (fieldsErrors && fieldsErrors.length > 0 ? fieldsErrors : undefined);
      return {
        ...formField,
        ...(fields && { fields }),
        error: fieldError,
      };
    },
  );
  return newFormFields;
};
export const jsonschemaValidation = (
  formFields: FormFields,
  configSchema: JSONSchema7,
) => {
  const values = formFieldsToConfigValues(formFields);
  const validatorResult = validate(values, configSchema);
  const validationResult = new Validator().validate(
    values,
    configSchema as Schema,
  );
  return {
    isValid: validatorResult.valid && validationResult.valid,
    validationResult,
    values,
    validatedFormFields: validationResultToFormFields({
      validationResult,
      formFields,
    }),
  };
};
