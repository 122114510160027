import React from 'react';

export const ClearFilter = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M0 1.3125C0 0.59375 0.5625 0 1.28125 0H14.6562C15.4062 0 16 0.59375 16 1.34375C16 1.59375 15.9062 1.875 15.7188 2.09375L12.8438 6.0625C11.875 6.15625 11 6.53125 10.25 7.0625L14.3125 1.5H1.71875L6.8125 7.78125C6.9375 7.9375 7 8.09375 7 8.25V10.4062L8 11.1875C8 11.3125 8 11.4062 8 11.5C8 12.1562 8.09375 12.7812 8.3125 13.375L5.96875 11.5C5.65625 11.25 5.5 10.9062 5.5 10.5312V8.53125L0.28125 2.125C0.09375 1.90625 0 1.625 0 1.3125ZM13.5 7C15.0938 7 16.5625 7.875 17.375 9.25C18.1875 10.6562 18.1875 12.375 17.375 13.75C16.5625 15.1562 15.0938 16 13.5 16C11.875 16 10.4062 15.1562 9.59375 13.75C8.78125 12.375 8.78125 10.6562 9.59375 9.25C10.4062 7.875 11.875 7 13.5 7ZM15.3438 10.375C15.5312 10.1875 15.5312 9.84375 15.3438 9.65625C15.1562 9.46875 14.8125 9.46875 14.625 9.65625L13.5 10.8125L12.3438 9.65625C12.1562 9.46875 11.8125 9.46875 11.625 9.65625C11.4375 9.84375 11.4375 10.1875 11.625 10.375L12.7812 11.5L11.625 12.6562C11.4375 12.8438 11.4375 13.1875 11.625 13.375C11.8125 13.5625 12.1562 13.5625 12.3438 13.375L13.5 12.2188L14.625 13.375C14.8125 13.5625 15.1562 13.5625 15.3438 13.375C15.5312 13.1875 15.5312 12.8438 15.3438 12.6562L14.1875 11.5L15.3438 10.375Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ClearFilter;
