import { SupernaHeader } from './components/SupernaHeader';
import { ThemeButton } from './components/ThemeButton';
import { RegistrationForm } from './components/RegistrationForm';

export const RegistrationPage = () => {
  return (
    <div className="h-screen overflow-auto pb-4">
      <ThemeButton />
      <div className="flex flex-col items-center justify-center">
        <SupernaHeader />
        <div className="mt-14 flex items-start justify-start gap-10">
          <RegistrationForm />
        </div>
      </div>
    </div>
  );
};
