/**
 * Returns a random element from an enum
 * @example
 * enum TestEnum {
 *  red = 'RED',
 *  green = 'GREEN',
 *  blue = 'BLUE',
 * }
 *
 * const randomEnum = randomEnum(TestEnum);
 * // randomEnum is either 'RED', 'GREEN' or 'BLUE'
 *
 * @param anEnum an enum
 * @returns a random element from an enum
 */
export const randomEnum = <T>(anEnum: T): T[keyof T] => {
  const keys = Object.keys(anEnum as unknown as object);
  const randomKey = keys[Math.floor(Math.random() * keys.length)];

  return anEnum[randomKey as keyof T];
};
