import React from 'react';

export const HelpIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M6.70359 0.901367C3.49823 0.901367 0.900391 3.4992 0.900391 6.70456C0.900391 9.90992 3.49823 12.5078 6.70359 12.5078C9.90895 12.5078 12.5068 9.90992 12.5068 6.70456C12.5068 3.4992 9.90895 0.901367 6.70359 0.901367ZM6.70359 11.4197C4.10348 11.4197 1.98849 9.30444 1.98849 6.70456C1.98849 4.10469 4.10348 1.98947 6.70359 1.98947C9.30369 1.98947 11.4187 4.10469 11.4187 6.70456C11.4187 9.30444 9.30369 11.4197 6.70359 11.4197ZM6.70359 8.51806C6.29555 8.51806 5.97819 8.83542 5.97819 9.24346C5.97819 9.6515 6.27515 9.96886 6.70359 9.96886C7.09122 9.96886 7.42899 9.6515 7.42899 9.24346C7.42899 8.83542 7.09122 8.51806 6.70359 8.51806ZM7.45392 3.80297H6.29555C5.41147 3.80297 4.70874 4.5057 4.70874 5.38978C4.70874 5.68447 4.95809 5.93383 5.25279 5.93383C5.54748 5.93383 5.79684 5.68447 5.79684 5.38978C5.79684 5.11775 6.00312 4.89106 6.27515 4.89106H7.43352C7.72595 4.89106 7.97304 5.11775 7.97304 5.38978C7.97304 5.57113 7.88236 5.70941 7.72368 5.80008L6.43156 6.59122C6.25021 6.70456 6.15954 6.88591 6.15954 7.06726V7.42996C6.15954 7.72466 6.40889 7.97401 6.70359 7.97401C6.99828 7.97401 7.24764 7.72466 7.24764 7.42996V7.38463L8.27 6.7499C8.74604 6.45521 9.04073 5.93383 9.04073 5.38978C9.06114 4.5057 8.35841 3.80297 7.45392 3.80297Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HelpIcon;
