import { FeatureHub } from 'featurehub-react-sdk';
import { Provider } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { ToastifyContainer } from './components/Toasts/ToastifyContainer';
import { MicroFrontendContext } from './microFrontend/context';
import { usePlugins } from './microFrontend/usePlugin';
import { useProducts } from './pages/dashboard/hooks/useProducts';
import { AppProvider } from './providers/app';
import { useAppSelector } from './redux/hooks';
import { store } from './redux/store';
import { AppRoutes } from './routes';
import { ApolloProviderWithSetup } from './services/apollo';
import { useAlertCountQuery } from './useAlertCountQuery';
import { Hotjar } from './utils/hooks/Hotjar';
import { Umami } from './utils/hooks/Umami';
import { SentryErrorHandler } from './utils/sentry/SentryErrorHandler';
import { DEFAULT_TOOLTIP_OPTIONS } from './utils/tooltilp';

export const MAIN_TOOLTIP_ID = 'main-tooltip';

function App() {
  return (
    <>
      <Provider store={store}>
        <FeatureHub
          url={`${window.env.REACT_APP_FEATUREHUB_URL}`}
          apiKey={`${window.env.REACT_APP_FEATUREHUB_API_KEY}`}
        >
          <>
            <Hotjar />
            <Umami />
            <ReduxChildren />
          </>
        </FeatureHub>
      </Provider>
    </>
  );
}

const ReduxChildren = () => {
  const { currentTenant } = useAppSelector((state) => state.login);

  return (
    <ApolloProviderWithSetup
      tenant={currentTenant}
      isMocked={process.env.REACT_APP_MOCKED === 'true'}
    >
      <SentryErrorHandler>
        <ApolloChildren />
      </SentryErrorHandler>
    </ApolloProviderWithSetup>
  );
};
const ApolloChildren = () => {
  // Products and alert polling
  useProducts();
  useAlertCountQuery();

  return (
    <AppProvider>
      <ToastifyContainer />
      <Tooltip id={MAIN_TOOLTIP_ID} {...DEFAULT_TOOLTIP_OPTIONS} />
      <MicroFrontendContext.Provider value={usePlugins()}>
        <AppRoutes />
      </MicroFrontendContext.Provider>
    </AppProvider>
  );
};

export default App;
