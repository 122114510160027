import classNames from 'classnames';
import { getDescriptionTextColorClassNames } from '../utils';

export const Description = ({
  children,
  position = 'bottom',
  size = 'medium',
  className,
  disabled,
  ...rest
}: {
  children: React.ReactNode;
  position?: 'top' | 'bottom';
  size?: 'small' | 'medium' | 'large';
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <div
      className={classNames(
        'font-graphikRegular',
        {
          'mb-2': position === 'top',
          'mt-2': position === 'bottom',
          'text-xs': size === 'small',
          'text-sm': size === 'medium',
          'text-base': size === 'large',
        },
        className,
        getDescriptionTextColorClassNames({ disabled }),
      )}
      data-testid="description"
      {...rest}
    >
      {children}
    </div>
  );
};

export default Description;
