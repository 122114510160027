import React from 'react';

export const CheckSolidCircleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="94"
      height="94"
      viewBox="0 0 94 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M47 94C72.9602 94 94 72.9602 94 47C94 21.0398 72.9602 0 47 0C21.0398 0 0 21.0398 0 47C0 72.9602 21.0398 94 47 94Z"
        fill="currentFill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.7457 38.3705L44.2457 61.8705C42.5199 63.5963 39.7293 63.5963 38.0219 61.8705L26.2719 50.1205C24.5461 48.3947 24.5461 45.6041 26.2719 43.8967C27.9977 42.1893 30.7883 42.1709 32.4957 43.8967L41.1246 52.5256L61.5035 32.1283C63.2293 30.4025 66.0199 30.4025 67.7273 32.1283C69.4348 33.8541 69.4531 36.6447 67.7273 38.3521L67.7457 38.3705Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CheckSolidCircleIcon;
