import { ChevronDownIcon } from '../../../assets';
import classNames from 'classnames';

interface IProps {
  open?: boolean;
  className?: string;
}

export const OpenAccordionIcon = ({ open, className }: IProps) => (
  <span
    className={classNames(
      'pointer-events-none absolute inset-y-0 right-0 flex items-center',
      className,
    )}
  >
    <ChevronDownIcon
      data-testid="OpenAccordionIcon"
      className={classNames('h-7 w-7 transition', !open && '-rotate-90')}
      aria-hidden="true"
    />
  </span>
);
