import { useQuery } from '@apollo/client';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { isNil } from 'ramda';
import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { GET_SERVER_COMPONENTS } from '../../../../api/inventory/queries';
import {
  GetApplianceServerComponentsQuery,
  GetApplianceServerComponentsQueryVariables,
  GetInventoryQuery,
  HealthStatus,
  ServerComponent,
} from '../../../../gql/graphql';
import { formatDate } from '../../../../utils/format';
import { useSession } from '../../../../utils/hooks/useSession';
import { SimpleButton } from '../../../Buttons/SimpleButton';
import { DataTable, DataTableColumn } from '../../../DataTable/DataTable';
import { SpinnerVertical } from '../../../Elements/Spinner/Spinner';
import { LayoutAreaId } from '../../../Layout/types';
import { Message } from '../../../common/Message';
import { Modal } from '../../../common/Modal';
import { ToastMessage } from './ApplianceCardDetails';
import { InfoCard } from './InfoCard';
import { StateIcon } from './StateIcon';

const LOADING_ROW: ServerComponent = {
  name: '-',
  cpu: 0,
  details: '-',
  // id: '-',
  ram: undefined,
  webAddress: '-',
  healthStatus: HealthStatus.Down,
};
// Create an array of 8 elements for the loading bone
const LOADING_ROWS: ServerComponent[] = [...Array(8)].map((u, i) => ({
  ...LOADING_ROW,
  name: `LOADING-${i}`,
}));

export const ServerComponentsModal = ({
  server,
  applianceId,
  onClose,
  isOpen,
}: {
  server?: GetInventoryQuery['appliances'][0]['servers'][0];
  applianceId?: string;
  isOpen?: boolean;
  onClose?: () => void;
}) => {
  const { skip } = useSession();

  const query = useQuery<
    GetApplianceServerComponentsQuery,
    GetApplianceServerComponentsQueryVariables
  >(GET_SERVER_COMPONENTS, {
    skip: skip || !isOpen || !server || !applianceId,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setTempLastCheck(dayjs()),
    variables: {
      serverId: server?.id || '',
      applianceId: applianceId || '',
    },
  });

  const handleCopy = useCallback(() => {
    server?.id && navigator.clipboard.writeText(server?.id);
    toast.success(
      <ToastMessage title={'Copied to Clipboard'}>
        {server?.name} ID copied to Clipboard
      </ToastMessage>,
    );
  }, [server]);

  const [tempLastCheck, setTempLastCheck] = useState(dayjs());

  const loadingRenderCell = useMemo(
    () =>
      query.loading
        ? () => (
            <div className="flex w-full px-1 py-0.5">
              <div className="flex w-full animate-pulse overflow-hidden rounded-lg bg-tw-strong-shade text-xs text-tw-strong-shade dark:bg-[#4A4A4A] dark:text-[#4A4A4A]">
                Loading...
              </div>
            </div>
          )
        : undefined,
    [query],
  );

  const columns: DataTableColumn<ServerComponent>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Component',
        sortable: true,
        classNames: { td: 'max-w-[1px] w-1/3' },
        renderCell:
          loadingRenderCell ||
          (({ row }) => (
            <div className="ml-2 flex flex-row items-center gap-3">
              <StateIcon state={row.healthStatus || HealthStatus.Down} />
              <span className="truncate">{row.name}</span>
            </div>
          )),
      },
      {
        field: 'details',
        headerName: 'Details',
        sortable: true,
        classNames: { td: 'w-1/3 max-w-[1px]' },
        renderCell: loadingRenderCell,
      },
      {
        field: 'cpu',
        headerName: 'CPU',
        sortable: true,
        classNames: { td: 'max-w-[1px]' },
        renderCell:
          loadingRenderCell ||
          (({ row }) => <span>{isNil(row.cpu) ? '-' : `${row.cpu}%`}</span>),
      },
      {
        field: 'ram',
        headerName: 'RAM',
        sortable: true,
        classNames: { td: 'max-w-[1px]' },
        renderCell: loadingRenderCell,
      },
      {
        field: 'webAddress',
        headerName: 'Web Address',
        sortable: true,
        classNames: { td: 'w-1/3 max-w-[1px]' },
        renderCell: loadingRenderCell,
      },
    ],
    [loadingRenderCell],
  );

  return !server ? null : (
    <Modal
      title={`${server.name} Components`}
      isOpen={!!isOpen}
      onClose={onClose}
      layoutArea={LayoutAreaId.CONTAINER}
    >
      <div className="flex w-[660px] flex-col gap-4">
        <div className="flex w-full flex-row gap-4 truncate text-left">
          <InfoCard
            title="State"
            content={server.state}
            className="flex"
            data-testid="server-info-state"
          />
          <InfoCard
            title="IP:port"
            content={[server.ipv4 || 'N/A', server.port]
              .filter(Boolean)
              .join(':')}
            data-testid="server-info-ip-port"
          />
          <InfoCard
            title="Token"
            className="flex shrink truncate"
            content={
              <button
                onClick={handleCopy}
                className="group flex w-full gap-1 truncate"
              >
                <span className="truncate">{server.id}</span>
                <FontAwesomeIcon
                  data-testid="copy-to-clipboard-icon"
                  className="text-tw-label-tertiary transition-colors group-hover:text-tw-label-primary dark:text-tw-label-tertiary-dark group-hover:dark:text-tw-label-primary-dark"
                  icon={faCopy}
                />
              </button>
            }
            data-testid="server-info-id"
          />
          <InfoCard
            title="Service Type"
            content={server.type}
            data-testid="server-info-type"
          />
        </div>
        <div className="flex w-full">
          <DataTable
            customClassnames={{
              container:
                'p-2 pt-0 rounded-lg bg-tw-surface-strong dark:bg-tw-surface-strong-dark h-96',
            }}
            defaultCellRender={(v) => (isNil(v) ? '-' : v)}
            stickyHeader
            rowClassName={() =>
              'truncate max-w-[1px] text-left even:bg-tw-surface-soft dark:even:bg-tw-surface-soft-dark [&>*:first-child]:rounded-l-xl [&>*:last-child]:rounded-r-xl'
            }
            columns={columns}
            keyExtractor={(e) => e.name}
            rows={
              query.loading
                ? LOADING_ROWS
                : query.data?.applianceServerComponents || []
            }
          />
        </div>
        <div className="flex w-full items-center gap-4">
          {query.loading ? (
            <SpinnerVertical className="h-5">Refreshing...</SpinnerVertical>
          ) : query.error ? (
            <Message type="error">
              <>{query.error.message}</>
            </Message>
          ) : (
            <span>Last check in: {formatDate(tempLastCheck.toString())}</span>
          )}
          <div className="ml-auto flex items-center">
            <SimpleButton
              variant="soft"
              onClick={() => {
                query.refetch();
              }}
            >
              Refresh
            </SimpleButton>
            <SimpleButton onClick={onClose}>Close</SimpleButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
