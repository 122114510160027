import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { Icon, IconName } from '../../../../assets/icons/Icon';
import { lazyImport } from '../../../../utils/lazyImport';
import { SimpleButton } from '../../../Buttons/SimpleButton';
import Spinner from '../../../Elements/Spinner/Spinner';
import {
  ChangeHandler,
  DataTableRow,
  FormElementProps,
  FormFieldElement,
  FormFieldProps,
  FormFieldValue,
} from '../../DynamicForm';
import { Label } from '../Common';
import { FieldError } from '../Common/FieldError';
import { InputText } from '../InputText';
import { getBaseTextAndBgClassNames } from '../utils';

// Prevent circular dependency
const { FormElement } = lazyImport(
  () => import('../../DynamicForm'),
  'FormElement',
);
const { FormField } = lazyImport(
  () => import('../../DynamicForm'),
  'FormField',
);
const { DynamicFormDataTable } = lazyImport(
  () => import('../../DynamicForm'),
  'DynamicFormDataTable',
);

export interface ListProps {
  id: string;
  className?: string;
  defaultValue?: FormFieldValue[];
  maxItems?: number;
  minItems?: number;
  placeholder?: string;
  inputLabel?: string;
  value?: FormFieldValue[];
  disabled?: boolean;
  uniqueItems?: boolean;
  onItemDelete?: (item: FormFieldValue) => void;
  onItemAdd?: (item: FormFieldValue) => void;
  onChange?: (items: FormFieldValue[]) => void;
  error?: string | string[];
  formFieldElement?: (index: number) => FormFieldElement | null;
  switchable?: boolean;
}

export const LazyList = (props: ListProps) => (
  <Suspense fallback={<Spinner />}>
    <List {...props} />
  </Suspense>
);

export const List = ({
  id,
  className,
  defaultValue,
  maxItems,
  minItems,
  placeholder = 'New item',
  inputLabel,
  value,
  disabled,
  uniqueItems,
  onItemDelete,
  onItemAdd,
  onChange,
  error,
  formFieldElement,
  switchable,
}: ListProps) => {
  const handleDelete = (item: FormFieldValue, index: number) => {
    const nextItems = !uniqueItems
      ? value?.filter((_, i) => i !== index)
      : value?.filter((i) => i !== item);
    onItemDelete && onItemDelete(item);
    onChange && onChange(nextItems || []);
  };

  const handleAdd = (item: FormFieldValue) => {
    const nextItems = [...(value || []), item];
    onItemAdd && onItemAdd(item);
    onChange && onChange(nextItems);
  };

  const isAddDisabled = useMemo(() => {
    return Boolean(disabled || (maxItems && (value?.length || 0 >= maxItems)));
  }, [disabled, maxItems, value]);

  const isCompact = useMemo(() => {
    const fieldElement = formFieldElement?.(0) && formFieldElement(0);
    const isObject = fieldElement?.type === 'group';
    const objectHasLessThan10Fields =
      isObject && fieldElement?.fields.length < 10;

    const anyFieldIsObject =
      isObject &&
      fieldElement?.fields.some(
        (field) => field.type === 'group' || field.type === 'list',
      );

    return isObject && objectHasLessThan10Fields && !anyFieldIsObject;
  }, [formFieldElement]);

  const columns: FormFieldElement[] = useMemo(() => {
    if (!isCompact) {
      return [];
    }
    const fieldElement = formFieldElement?.(0) && formFieldElement(0);
    const isObject = fieldElement?.type === 'group';
    const fields = isObject ? fieldElement?.fields : [];

    return fields.map((field) => ({
      ...field,
      id: field.path?.at(-1) || field.id,
    }));
  }, [formFieldElement, isCompact]);

  const formFieldElement0 = formFieldElement?.(0);

  const onChangeHandler = useCallback(
    (index: number, indexedField: FormFieldElement): ChangeHandler =>
      ({ id, value: v, pathArray }) => {
        const nextItems = value?.map((item, iIndex) =>
          iIndex === index
            ? pathArray?.length === 1
              ? v
              : {
                  ...(typeof item === 'object' && indexedField?.type === 'group'
                    ? item
                    : {}),
                  [pathArray?.at(-1) || id]: v,
                }
            : item,
        );
        onChange && onChange(nextItems || []);
      },
    [value, onChange],
  );

  const [showAsTable, setShowAsTable] = useState(true);

  return (
    <div
      className={classNames(
        getBaseTextAndBgClassNames({
          variant: isCompact ? 'white' : 'default',
        }),
        !isCompact &&
          'rounded-lg p-2 ring-1 ring-black ring-opacity-5 dark:ring-white/5',
        className,
      )}
    >
      {isCompact && switchable && (
        <div className="flex items-center justify-end">
          <div className="flex items-center gap-2">
            <Label className="mt-2">Table mode</Label>
            <Switch
              checked={showAsTable}
              onChange={setShowAsTable}
              className={`${
                showAsTable ? 'bg-blue-600' : 'bg-black/10 dark:bg-white/10'
              } relative inline-flex h-6 w-11 items-center rounded-full shadow-inner`}
            >
              <span className="sr-only">Enable table mode</span>
              <span
                className={`${
                  showAsTable ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white drop-shadow transition dark:bg-black`}
              />
            </Switch>
          </div>
        </div>
      )}
      {isCompact && formFieldElement0 && showAsTable ? (
        <div className="flex flex-col">
          <DynamicFormDataTable
            field={{
              id: id,
              type: 'data-table',
              title: formFieldElement0?.title || 'List',
              columns: columns || [],
              value: value as DataTableRow[],
            }}
            onChange={(value) =>
              // formFieldElement0 && onChangeHandler(0, formFieldElement0)(value)
              {
                const valueItems = value.value as DataTableRow[];
                onChange && onChange(valueItems);
              }
            }
          />
        </div>
      ) : (
        <ul aria-label="list" className="flex flex-col gap-2 p-1">
          {value?.map((item, index) => {
            const indexedField = formFieldElement && formFieldElement(index);
            const ItemRender =
              indexedField?.type === 'group' || indexedField?.type === 'list'
                ? FormElement
                : FormField;
            return (
              <li
                key={indexedField?.id || `item-${index}`}
                aria-label="list-item"
                className={classNames(
                  'flex w-full items-center justify-between',
                  'relative rounded-md',
                  getBaseTextAndBgClassNames(),
                )}
              >
                <>
                  {indexedField ? (
                    <IndexedField
                      key={`item-${index}`}
                      index={index}
                      indexedField={indexedField}
                      ItemRender={ItemRender}
                      item={item}
                      onChange={(val) =>
                        onChangeHandler(index, indexedField)(val)
                      }
                    />
                  ) : (
                    <InputText
                      key={`item-${index}`}
                      label={inputLabel}
                      value={item as string}
                      variant="white"
                      onChange={(event) => {
                        const nextItems = value?.map((item, iIndex) =>
                          iIndex === index ? event.currentTarget.value : item,
                        );
                        onChange && onChange(nextItems || []);
                      }}
                      placeholder={placeholder}
                    />
                  )}
                  <div
                    className={classNames(
                      'ml-2 flex items-center justify-center',
                      indexedField?.type === 'group' &&
                        'absolute right-3 top-3',
                    )}
                  >
                    <SimpleButton
                      variant="default-ghost"
                      onClick={() => {
                        if (!value) return;
                        const nextItems = value.map((i, iIndex) =>
                          iIndex === index ? value[index + 1] : i,
                        );
                        nextItems[index + 1] = item;
                        onChange && onChange(nextItems);
                      }}
                      disabled={!value || index === value.length - 1}
                      aria-label="Move item down"
                    >
                      <Icon
                        iconName={IconName.ChevronLeftIcon}
                        className="h-3 w-3 -rotate-90"
                      />
                    </SimpleButton>
                    <SimpleButton
                      variant="default-ghost"
                      onClick={() => {
                        if (!value) return;
                        const nextItems = value.map((i, iIndex) =>
                          iIndex === index ? value[index - 1] : i,
                        );
                        nextItems[index - 1] = item;
                        onChange && onChange(nextItems);
                      }}
                      disabled={index === 0}
                      aria-label="Move item up"
                    >
                      <Icon
                        iconName={IconName.ChevronLeftIcon}
                        className="h-3 w-3 rotate-90"
                      />
                    </SimpleButton>

                    <SimpleButton
                      variant="default-ghost"
                      onClick={() => handleDelete(item, index)}
                      aria-label="Delete item"
                    >
                      <Icon iconName={IconName.CrossIcon} className="h-3 w-3" />
                    </SimpleButton>
                  </div>
                </>
              </li>
            );
          })}
          <li className="flex w-full items-center justify-between">
            {value?.length === 0 && <>No items</>}
            <SimpleButton
              disabled={isAddDisabled}
              variant="primary"
              onClick={() => handleAdd(undefined)}
              className="ml-auto"
              aria-label="Add item"
            >
              <Icon
                iconName={IconName.CrossIcon}
                className="h-3 w-3 rotate-45"
              />
            </SimpleButton>
          </li>
        </ul>
      )}
      {error && <FieldError error={error} />}
    </div>
  );
};

const IndexedField = ({
  index,
  indexedField,
  ItemRender,
  item,
  onChange,
}: {
  index: number;
  indexedField: FormFieldElement;
  ItemRender: (props: FormElementProps | FormFieldProps) => JSX.Element | null;
  item: FormFieldValue;
  onChange: ChangeHandler;
}) => {
  return (
    <div
      data-testid={`suspense-item-${index}`}
      className={classNames(
        'flex-1',
        indexedField?.type === 'group' &&
          'rounded-md bg-tw-dark-shade p-4 ring-1 ring-black ring-opacity-5 dark:bg-tw-dark-shade-dark dark:ring-white/5',
      )}
    >
      <ItemRender
        field={{
          ...indexedField,
          value: item as any,
          title: `${indexedField.title} ${index + 1}`,
          variant: 'white',
          path: [`${index}`],
        }}
        onChange={onChange}
      />
    </div>
  );
};
