import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { Icon, IconName } from '../../assets/icons/Icon';
import Spinner from '../Elements/Spinner/Spinner';

export const Message = ({
  children: message,
  type,
  variant,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
  type?: 'success' | 'error' | 'warning' | 'info' | 'loading';
  variant?: 'card';
}) => {
  return message ? (
    <div
      data-type={type}
      data-testid="message-container"
      className={twMerge(
        classNames(
          'my-2 flex flex-row items-start justify-start gap-2 text-left text-tw-main-text dark:text-tw-main-text-dark',
          variant === 'card' &&
            'm-4 rounded bg-tw-light-shade p-5 drop-shadow-dark-bottom-20 dark:divide-[#292B2F] dark:bg-tw-strong-shade-dark dark:drop-shadow-dark-bottom-50',
        ),
      )}
      {...rest}
    >
      <div className="shrink-0">
        {type === 'loading' ? (
          <span data-testid="loadingSpinner">
            <Spinner className="h-5 w-5" />
          </span>
        ) : type === 'success' ||
          type === 'error' ||
          type === 'warning' ||
          type === 'info' ? (
          <span data-testid="message-icon">
            <Icon
              iconName={
                type === 'success'
                  ? IconName.CheckCircleIcon
                  : type === 'error'
                  ? IconName.ErrorIcon
                  : IconName.InfoCircleIcon
              }
              className={classNames(
                'h-5 w-5',
                type === 'success'
                  ? 'text-green-500'
                  : type === 'error' || type === 'warning'
                  ? 'text-tw-critical-color'
                  : 'text-tw-main-text dark:text-tw-main-text-dark',
              )}
            />
          </span>
        ) : null}
      </div>
      <div
        data-testid="message-text"
        className="mt-0.5 flex w-full text-sm font-normal"
      >
        {message}
      </div>
    </div>
  ) : null;
};
