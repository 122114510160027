import alertCodesENUS from '../../localization/en-US/alertCodes.json';

const codes = Object.keys(alertCodesENUS);

export const randomAlertCode = () =>
  codes[Math.floor(Math.random() * codes.length)];
export const randomAlertCodeObject = () => {
  const key = codes[
    Math.floor(Math.random() * codes.length)
  ] as keyof typeof alertCodesENUS;
  return alertCodesENUS[key] as {
    description?: string;
    severity?: string;
    help?: string;
  };
};
export const randomAlertDescription = () =>
  randomAlertCodeObject().description || s4();

export const randomDate = ({
  start = new Date(2023, 0, 1),
  end = new Date(),
}: {
  start?: Date;
  end?: Date;
} = {}) => {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime()),
  );
};

export const s4 = () =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
// example '5f2e75cc-48de-4f9c-804d-033a0b92c5f6'
export const randomId = () =>
  `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;

export const randomIntFromInterval = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const randomArray = <T>({
  render,
  minItems = 1,
  maxItems = 10,
}: {
  render: () => T;
  minItems?: number;
  maxItems?: number;
}): T[] => {
  return Array.from(
    { length: randomIntFromInterval(minItems, maxItems) },
    render,
  );
};

export const randomIp = () =>
  Array(4)
    .fill(0)
    .map((_, i) => Math.floor(Math.random() * 255) + (i === 0 ? 1 : 0))
    .join('.');

export const randomInt = (min = 0, max = 100) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
const BASE_SENTENCE =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum';
const WORDS = BASE_SENTENCE.toLowerCase()
  .replace(/\.|,|:/gi, '')
  .split(' ');
export const randomWord = () => WORDS[randomInt(0, WORDS.length - 1)];
export const randomSentence = (max = 10, min = 1) => {
  const sentence = [...Array(randomInt(min, max))]
    .map(() => randomWord())
    .join(' ');

  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};
