import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductKind } from '../../../api/product/types';
import { Category } from '../../../gql/graphql';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateProductsFilterCategory } from '../../../redux/products/filterSlice';
import { formatAsCeroToTen } from '../../../utils/format';
import { useProductsAndCategoriesByFilter } from '../../../utils/products';
import Spinner from '../../Elements/Spinner/Spinner';
import CardCoverageStrengths, {
  CoverageIcon,
  ScoreBar,
} from '../ProductCard/components/CardCoverageStrength';
import { getCoverageColor } from '../ProductCard/utils';
import { useCoverage } from './useCoverage';

export const CoverageCard = ({
  title = 'Coverage Strength',
  subTitle = 'Install more apps from Superna App Suite to upgrade your Health Score.',
}: {
  title?: string;
  subTitle?: string;
}) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation('coverage');

  const { coverages } = useAppSelector((state) => state.products);
  const { loading: loadingProducts, available } = useAppSelector(
    (state) => state.products.products,
  );
  const { category: selectedCategory, products: selectedProducts } =
    useAppSelector((state) => state.productFilter);

  const { coverageItems } = useCoverage();

  const { type: productOrCategory } = useProductsAndCategoriesByFilter();

  const isCatActive = (category: Category) => {
    if (productOrCategory === 'cat') {
      return selectedCategory === category;
    } else {
      const coverageItem = coverageItems.filter(
        (item) => item.category === category,
      );
      if (selectedProducts.length) {
        for (let i = 0; i < selectedProducts.length; i++) {
          if (coverageItem[0].products.includes(selectedProducts[i])) {
            return true;
          }
        }
        return false;
      } else {
        return true;
      }
    }
  };

  const updateSelectedCategory = (category: Category) => {
    if (selectedCategory === category) {
      dispatch(updateProductsFilterCategory(null));
    } else {
      dispatch(updateProductsFilterCategory(category));
    }
  };

  const loadingCoverages = useMemo(
    () => loadingProducts || coverages.some(({ loading }) => loading),
    [coverages, loadingProducts],
  );

  return (
    <div
      data-testid="coverage-card"
      className="w-full rounded-lg border-2 border-tw-light-bg bg-tw-dark-shade px-7 pb-8 pt-6 shadow-dark-bottom-20 dark:border-tw-dark-shade-dark dark:bg-tw-light-shade-dark"
    >
      {/* Add a hidden installed products map to force query coverage and dispatch a coverage update in redux products */}
      <div className="hidden">
        {available.map((product) => (
          <CardCoverageStrengths
            key={product.kind}
            productKind={product.kind as ProductKind}
          />
        ))}
      </div>
      {/* header Info */}
      <div className="flex flex-col gap-[5px]">
        <h3
          data-testid="coverage-card-title"
          className="font-graphikMedium text-[1.25rem] leading-[1.375rem] text-tw-main-text dark:text-tw-dark-shade"
        >
          {title}
        </h3>
        <p
          data-testid="coverage-card-subtitle"
          className="font-graphikRegular text-[0.75rem] leading-[0.813rem] text-tw-description-text"
        >
          {subTitle}
        </p>
      </div>

      {/* content */}
      <div data-testid="coverage-card-items" className="space-y-3.5">
        {coverageItems
          .sort(
            (a, b) =>
              Number(b.enabled) - Number(a.enabled) || b.score - a.score,
          )
          .map((coverageItem, index) => {
            const disabled = !(
              coverageItem.products.length && coverageItem.enabled
            );
            return (
              <div
                data-testid={`coverage-item-${coverageItem.category}`}
                data-test-category={coverageItem.category}
                key={index}
              >
                <button
                  data-testid={`coverage-item-button`}
                  className={classNames(
                    'flex w-full cursor-pointer flex-col justify-between transition',
                    !isCatActive(coverageItem.category) && 'opacity-20',
                  )}
                  disabled={disabled}
                  onClick={() =>
                    !disabled && updateSelectedCategory(coverageItem.category)
                  }
                >
                  <div className="flex w-full flex-row items-center justify-between">
                    <div
                      data-testid="coverage-item-category-title"
                      className={classNames(
                        'flex items-center font-graphikMedium text-[14px]',
                        coverageItem.enabled
                          ? 'text-tw-main-text dark:text-tw-dark-shade'
                          : 'text-[#AFAFAF]',
                      )}
                    >
                      <CoverageIcon
                        iconName={coverageItem.category}
                        className="fill-transparent"
                      />
                      {t(
                        `coverage.category.${coverageItem.category}.name`,
                        coverageItem.category,
                      )}
                    </div>
                    <div
                      data-testid="coverage-item-category-score"
                      data-test-value={coverageItem.score}
                      className="font-graphikMedium text-[12px] leading-[13px]"
                      style={{
                        color: coverageItem.enabled
                          ? getCoverageColor(coverageItem.score)
                          : '#AFAFAF',
                      }}
                    >
                      {loadingCoverages ? (
                        <Spinner className="h-4 w-4" />
                      ) : !disabled ? (
                        formatAsCeroToTen(coverageItem.score)
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>

                  <ScoreBar score={coverageItem.score} />
                </button>
              </div>
            );
          })}
      </div>
    </div>
  );
};
