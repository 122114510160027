export const ToggleMoonIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.02943 0.665862C3.51851 1.15713 0 4.987 0 9.63898C0 14.6218 4.03978 18.6622 9.02183 18.6622C13.6631 18.6622 17.4923 15.1532 17.9935 10.6416C18.0838 9.84953 17.2116 9.2179 16.4498 9.68911C15.6078 10.2305 14.6053 10.5413 13.5327 10.5413C10.5455 10.5413 8.11965 8.11506 8.11965 5.12736C8.11965 4.06462 8.4304 3.06204 8.96169 2.22989C9.41278 1.55816 8.92159 0.595681 8.02943 0.665862Z"
        fill="currentColor"
      />
    </svg>
  );
};
