import React from 'react';

export const ArchiveAvailabilityIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M13.2225 13.2897V14.4532H14.5251V13.2897H13.2225ZM15.8276 9.79931H14.5251V10.9628H15.8276V9.79931ZM13.2225 10.9628V12.1262H14.5251V10.9628H13.2225ZM15.8276 12.1262H14.5251V13.2897H15.8276V12.1262ZM23.0569 11.0319L19.6417 7.9814C19.2754 7.65418 18.7788 7.46875 18.2618 7.46875H9.95386C8.87517 7.47239 8 8.25409 8 9.21758V24.3426C8 25.3061 8.87517 26.0878 9.95386 26.0878H21.677C22.7557 26.0878 23.6309 25.3061 23.6309 24.3426V12.268C23.6309 11.8063 23.4233 11.3591 23.0569 11.0319ZM18.4206 9.35938L21.5183 12.1262H18.4206V9.35938ZM21.677 24.3426H9.95386V9.21758H13.1981V9.79931H14.5006V9.21758H16.4667V12.9988C16.4667 13.4824 16.9023 13.8714 17.4436 13.8714H21.677V24.3426ZM15.905 17.1328C15.8602 16.9292 15.6607 16.7801 15.4247 16.7801H14.5251V15.6166H13.2225V16.7801L12.4206 20.3105C12.1519 21.4921 13.1614 22.5974 14.5129 22.5974C15.8602 22.5974 16.8697 21.4994 16.6092 20.3214L15.905 17.1328ZM14.5251 21.6557C13.7964 21.6557 13.2062 21.2158 13.2062 20.6741C13.2062 20.1323 13.7964 19.6924 14.5251 19.6924C15.2537 19.6924 15.8439 20.1323 15.8439 20.6741C15.8439 21.2158 15.2537 21.6557 14.5251 21.6557ZM15.8276 14.4532H14.5251V15.6166H15.8276V14.4532Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArchiveAvailabilityIcon;
