import React from 'react';

export const OverviewIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <mask
        id="mask0_3763_159305"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.113281 0.170898H19.4532V19.5042H0.113281V0.170898Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3763_159305)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.1994 6.37213H6.32532V2.25832H2.1994V6.37213ZM0.161976 4.31575C0.161976 3.55074 0.0981099 2.77916 0.176962 2.02246C0.29501 0.889822 1.11328 0.199749 2.26678 0.183955C3.58894 0.165971 4.91167 0.167355 6.23383 0.183264C7.49893 0.198481 8.37817 1.08176 8.39189 2.34697C8.40607 3.65899 8.40619 4.97146 8.39189 6.28348C8.37794 7.56148 7.48832 8.43877 6.20582 8.44857C4.90268 8.4586 3.5992 8.46045 2.29617 8.448C1.01828 8.43589 0.138804 7.54823 0.121512 6.26976C0.112751 5.61854 0.120013 4.9672 0.120013 4.31586C0.133962 4.31586 0.147911 4.31575 0.161976 4.31575Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2502 6.38226H17.3661V2.25819H13.2502V6.38226ZM11.2128 4.31446C11.2128 3.54957 11.1492 2.77811 11.2278 2.02129C11.3453 0.890152 12.1654 0.199388 13.3187 0.18394C14.6408 0.166187 15.9636 0.167109 17.2858 0.183364C18.5505 0.198811 19.4293 1.08302 19.4426 2.34811C19.4566 3.66013 19.457 4.9726 19.4425 6.28462C19.4286 7.55133 18.5508 8.43196 17.2841 8.44718C15.9714 8.46297 14.6583 8.46066 13.3456 8.44798C12.0681 8.43565 11.1892 7.54718 11.1722 6.26859C11.1636 5.61737 11.1707 4.96592 11.1707 4.31469C11.1848 4.31458 11.1988 4.31458 11.2128 4.31446Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.20494 17.4217H6.3207V13.2977H2.20494V17.4217ZM8.35367 15.3892C8.35367 16.1446 8.41708 16.9065 8.3388 17.6538C8.22076 18.782 7.39915 19.4753 6.2491 19.491C4.92694 19.5091 3.60421 19.5077 2.28193 19.4918C1.01615 19.4766 0.137591 18.5941 0.123873 17.3282C0.109693 16.0161 0.109808 14.7037 0.123873 13.3916C0.137476 12.1303 1.02111 11.243 2.2824 11.2278C3.59499 11.2121 4.90804 11.2144 6.22074 11.2271C7.49852 11.2394 8.37742 12.1277 8.39425 13.4065C8.40302 14.0673 8.39575 14.7282 8.39575 15.3891C8.38169 15.3892 8.36774 15.3892 8.35367 15.3892Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2461 17.4219H17.3716V13.2984H13.2461V17.4219ZM11.2131 15.3571C11.2131 14.5922 11.1499 13.8207 11.228 13.0639C11.3443 11.9357 12.1679 11.2436 13.3209 11.2286C14.6431 11.2116 15.9658 11.2116 17.2881 11.2284C18.5527 11.2444 19.4299 12.1298 19.4428 13.3954C19.456 14.7074 19.4574 16.0198 19.4423 17.3319C19.4278 18.5985 18.5488 19.4776 17.2817 19.4922C15.969 19.5076 14.656 19.5059 13.3433 19.4928C12.0661 19.4799 11.1884 18.5904 11.1721 17.3112C11.1638 16.66 11.1707 16.0085 11.1707 15.3573C11.1847 15.3572 11.1989 15.3572 11.2131 15.3571Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default OverviewIcon;
