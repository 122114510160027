import { ActionButton, DialogTrigger } from '@adobe/react-spectrum';

import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { USER_PREFERENCES } from '../../../api/userPreferences/queries';
import { UserPreferencesResponse } from '../../../api/userPreferences/types';
import { LogoutIcon } from '../../../assets';
import { logoutOrySession } from '../../../ory/logout';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  resetLogin,
  updateLogin,
  updateTenant,
  updateUserPreferences,
} from '../../../redux/login/loginSlice';
import { updateProductsFilterDuration } from '../../../redux/products/filterSlice';
import { useSession } from '../../../utils/hooks/useSession';
import PrimaryButton from '../../Buttons/PrimaryButton';
import { SimpleButton } from '../../Buttons/SimpleButton';
import { Modal } from '../../common/Modal';
import { PickerOption, Select } from '../../form/fields';
import { SpinnerWithText } from '../Spinner/SpinnerWithText';

const Avatar = ({
  name = '',
  surname = '',
  email = '@',
  className,
  ...rest
}: {
  name: string;
  surname: string;
  email: string;
  className?: string;
}) => {
  const initials = [name[0], surname[0]].filter(Boolean).join('') || email[0];
  return (
    <div
      data-testid="user-avatar"
      className={classNames(
        'flex h-[30px] w-[30px] shrink-0 items-center justify-center rounded-[4px] bg-tw-main-color',
        className,
      )}
      {...rest}
    >
      <p className="font-graphikMedium text-[14px] leading-[8px] text-white">
        {initials.toLocaleUpperCase()}
      </p>
    </div>
  );
};

export const useLogOut = ({
  navigateTo = '/',
  logoutCallback,
  navigateAfterLogout = true,
}: {
  navigateTo?: string;
  logoutCallback?: () => void;
  navigateAfterLogout?: boolean;
} = {}) => {
  const { logoutToken } = useSession();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logOut = () => {
    dispatch(
      updateLogin({
        isLoginOut: true,
        flowFulfilled: false,
      }),
    );
    logoutOrySession(logoutToken)
      .then((success) => {
        if (success) {
          logoutCallback && logoutCallback();
          dispatch(resetLogin());
          navigateAfterLogout && navigate(navigateTo);
        }
      })
      .finally(() => {
        dispatch(
          updateLogin({
            isLoginOut: false,
            flowFulfilled: true,
          }),
        );
      });
  };

  return {
    logOut,
    logoutToken,
    logOutButton: (
      <SimpleButton
        variant="default-ghost"
        onClick={logOut}
        aria-label="log out"
        data-testid="log-out-button"
      >
        <div className="flex flex-row items-center justify-center gap-2">
          <LogoutIcon className="h-4 w-4" />
          Log out
        </div>
      </SimpleButton>
    ),
  };
};

export const User = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const sidebarCollapsed = useAppSelector(
    (state) => state.sidebarCollapse.collapsed,
  );
  const traits =
    useAppSelector((state) => state.login.session?.identity?.traits) || {};

  const userProfileName =
    [traits?.name, traits?.surname].filter(Boolean).join(' ') || traits?.email;
  const avatar = traits?.avatar;

  return (
    <div className="w-full" {...props}>
      <div
        data-testid="user"
        className="flex items-center justify-center rounded-[4px] p-2 transition-colors hover:bg-tw-strong-shade dark:hover:bg-[#2D3036]"
      >
        {avatar ? (
          <img
            className={classNames(
              'user-profile-picture h-[30px] w-[30px] rounded-lg',
              sidebarCollapsed ? 'mr-0' : 'mr-2',
            )}
            src={avatar}
            alt="User profile pic"
            data-testid="user-avatar"
          />
        ) : (
          <Avatar
            data-testid="user-avatar"
            name={traits?.name}
            surname={traits?.surname}
            email={traits?.email}
            className={classNames(!sidebarCollapsed && 'mr-2')}
          />
        )}
        {!sidebarCollapsed && (
          <div
            className="flex flex-col justify-center truncate"
            data-testid="user-details"
          >
            <span
              data-testid="user-name"
              className="grow truncate whitespace-nowrap break-keep text-left font-graphikSemi text-[14px] leading-[16px] text-tw-main-text dark:text-tw-main-text-dark"
            >
              {userProfileName}
            </span>
            <span
              data-testid="user-email"
              className="grow truncate whitespace-nowrap break-keep text-left font-graphikRegular text-[12px] leading-[16px] text-tw-light-text dark:text-tw-inactive-color-dark"
            >
              {traits?.email}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export const ExtendedUser = () => {
  const { currentTenant, tenants_info = [], skip } = useSession();

  const traits =
    useAppSelector((state) => state.login.session?.identity?.traits) || {};

  const userProfileName =
    [traits.name, traits.surname].filter(Boolean).join(' ') || traits.email;
  const [isOpen, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { logOut } = useLogOut();

  const { data, loading } = useQuery<UserPreferencesResponse>(
    USER_PREFERENCES,
    { skip },
  );
  useEffect(() => {
    if (!loading && data?.userPreferences) {
      dispatch(updateUserPreferences(data.userPreferences));
      data.userPreferences.dashboard.timeSpan &&
        dispatch(
          updateProductsFilterDuration(data.userPreferences.dashboard.timeSpan),
        );
    }
  }, [data, loading]);

  const handleOnChangeTenant = useCallback(
    (selected: PickerOption | undefined) => {
      const selectedTenant = tenants_info.find(
        (tenant) => tenant.tenantId === selected?.value,
      );
      selectedTenant && dispatch(updateTenant(selectedTenant));
    },
    [],
  );

  const tenantsOptions = useMemo(
    () =>
      tenants_info.map((tenant) => ({
        label: tenant.tenantId,
        value: tenant.tenantId,
      })),
    [tenants_info],
  );

  return (
    <div data-testid="user-profile" className="mb-5 mr-2">
      <DialogTrigger type="popover" placement="top" hideArrow>
        <ActionButton
          data-testid="user-profile-button"
          aria-label="user profile"
          width={'100%'}
          isQuiet
          onPress={() => setOpen(!isOpen)}
          // onFocusChange={(isFocused) => !isFocused && setOpen(isFocused)}
        >
          <User />
        </ActionButton>
        <div
          data-testid="user-profile-menu"
          className="dark:bg-tw-dark-bg
            font-graphikRegular] w-64 rounded-md
            bg-white p-4
            text-start text-[14px] leading-[16px] text-tw-main-text shadow-md dark:bg-tw-light-shade-dark
            dark:text-tw-dark-shade dark:ring-1 dark:ring-gray-700"
        >
          <div className="mb-2 flex flex-col gap-4">
            You are logged in as {userProfileName}
            {tenants_info?.length > 1 ? (
              <Select
                label="Tenant"
                options={tenantsOptions}
                value={currentTenant?.tenantId || undefined}
                onChange={handleOnChangeTenant}
              />
            ) : (
              <p className="">{currentTenant?.tenantId}</p>
            )}
            <p className="">Role: {currentTenant?.roles.join(', ')}</p>
          </div>
          <PrimaryButton
            data-testid="user-profile-settings-button"
            size="medium"
            className="w-full"
            onClick={() => navigate('/user/preferences')}
          >
            User Preferences
          </PrimaryButton>
          <PrimaryButton
            data-testid="user-profile-logout-button"
            size="medium"
            className="w-full"
            onClick={logOut}
          >
            Log Out
          </PrimaryButton>
        </div>
      </DialogTrigger>
    </div>
  );
};

export const LoginOutModal = () => {
  const { isLoginOut } = useAppSelector((state) => state.login);
  return (
    <Modal isOpen={isLoginOut} title="Closing session" isCloseable={false}>
      <SpinnerWithText text="Closing session..." />
    </Modal>
  );
};
