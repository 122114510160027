import { ContentLayout } from '.';
import {
  GetAvailablePublicProductsQuery,
  GetInstalledProductsQuery,
} from '../../gql/graphql';
import { AppStatusesAndCoverageChart } from '../../pages/dashboard/AppStatusesAndCoverageChart';
import { ProductsDateRangePicker } from '../../pages/dashboard/ProductsDateRangePicker';
import { useSearchParamsReduxFilter } from './hooks/useSearchParamsReduxFilter';

interface IProps {
  title: React.ReactNode;
  children: React.ReactNode;
  installedProducts: GetInstalledProductsQuery['products'];
  missingProducts: GetAvailablePublicProductsQuery['products'];
  loading?: boolean;
}

export const ContentLayoutWithProductFilter = ({
  title,
  children,
  installedProducts,
  missingProducts,
  loading,
}: IProps) => {
  useSearchParamsReduxFilter();
  return (
    <ContentLayout data-testid="content-layout">
      <AppStatusesAndCoverageChart
        installedApps={installedProducts}
        missingApps={missingProducts}
        loading={loading}
      />
      <div className="my-6 flex items-center justify-between">
        <h3
          data-testid="content-layout-title"
          className="font-graphikMedium text-[1.175rem] leading-[1.938rem] text-tw-main-text dark:text-tw-dark-shade"
        >
          {title}
        </h3>
        <div className="flex gap-3">
          <ProductsDateRangePicker />
        </div>
      </div>
      <div className="flex w-full flex-col">{children}</div>
    </ContentLayout>
  );
};
