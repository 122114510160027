import React from 'react';

export const ThumbsUpIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M9.61306 0.643641L14.3323 5.3629C14.6475 5.67808 14.8264 6.11253 14.8264 6.56401V15.074C14.8264 16.011 14.0597 16.7777 13.1227 16.7777H5.45602C4.77454 16.7777 4.1612 16.3688 3.88861 15.747L1.11157 9.26438C0.396019 7.57771 1.6312 5.70364 3.46269 5.70364H8.27565L7.46639 1.80216C7.3812 1.37623 7.50898 0.941789 7.81565 0.635123C8.31824 0.141048 9.11898 0.141048 9.61306 0.643641ZM18.2338 16.7777C17.2968 16.7777 16.5301 16.011 16.5301 15.074V8.2592C16.5301 7.32216 17.2968 6.55549 18.2338 6.55549C19.1708 6.55549 19.9375 7.32216 19.9375 8.2592V15.074C19.9375 16.011 19.1708 16.7777 18.2338 16.7777Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ThumbsUpIcon;
