import classNames from 'classnames';
import { useMemo } from 'react';
import { GetInventoryQuery, LicenseKind } from '../../../../../gql/graphql';
import { sizeByKind } from './utils';

export const LicensedCluster = ({
  product,
}: {
  product: GetInventoryQuery['appliances'][0]['installedProducts'][0];
}) => {
  // “Unlimited” if there are licenses but none is cluster or node kind
  const unlimitedCluster = useMemo(
    () =>
      product.licenses.length &&
      product.licenses.every(
        (license) =>
          ![LicenseKind.Cluster, LicenseKind.Node].includes(license.kind),
      ),
    [product],
  );
  const cluster = useMemo(
    () => sizeByKind(product, LicenseKind.Cluster),
    [product],
  );
  const nodes = useMemo(() => sizeByKind(product, LicenseKind.Node), [product]);

  return (
    <>
      <span>Clusters Licensed: </span>
      <span
        className={classNames(
          !product?.licenses.length &&
            'text-xs text-tw-label-tertiary opacity-50 dark:text-tw-label-tertiary-dark',
        )}
      >
        {product?.licenses.length
          ? unlimitedCluster
            ? 'Unlimited'
            : [
                Boolean(cluster.sizeLicensed) &&
                  `${cluster.sizeConsumed} / ${cluster.sizeLicensed} clusters`,
                Boolean(nodes.sizeLicensed) &&
                  `${nodes.sizeConsumed} / ${nodes.sizeLicensed} nodes`,
              ]
                .filter(Boolean)
                .join(', ')
          : '-'}
      </span>
    </>
  );
};
