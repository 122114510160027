import { QueryResult, useQuery } from '@apollo/client';
import { useContext, useMemo } from 'react';
import { GET_APP_SETTINGS } from '../../../api/settings/queries';
import { GetAppSettingsResponse } from '../../../api/settings/types';
import { MicroFrontendContext } from '../../../microFrontend/context';
import { useSession } from '../../../utils/hooks/useSession';

export const useFetchAppSettings = (
  appName: string,
): QueryResult<
  GetAppSettingsResponse,
  {
    id: string;
  }
> => {
  const { plugins } = useContext(MicroFrontendContext);
  const appId = plugins?.find((app) => app.path === appName)?.id;
  const { skip } = useSession();
  const options = useMemo(
    () => ({
      variables: {
        id: appId || '',
      },
      skip: skip || !appId,
    }),
    [appId],
  );

  const queryResult = useQuery<
    GetAppSettingsResponse,
    {
      id: string;
    }
  >(GET_APP_SETTINGS, options);

  return queryResult;
};
