import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import Spinner from '../Elements/Spinner/Spinner';

export type variant =
  | 'primary'
  | 'primary-ghost'
  | 'critical'
  | 'soft'
  | 'critical-ghost'
  | 'critical-ghost-ring'
  | 'default'
  | 'default-ghost'
  | 'default-ghost-ring';
const getEnabledClassName = (variant?: variant) =>
  twMerge(
    classNames(
      variant === 'default' &&
        'text-white bg-tw-main-color hover:brightness-125',
      variant === 'soft' &&
        'text-tw-main-text dark:text-tw-main-text-dark bg-tw-surface-soft dark:bg-tw-surface-soft-dark hover:brightness-125 ring-1 ring-tw-border dark:ring-tw-border-dark px-4',
      variant === 'default-ghost' &&
        'text-tw-main-text/50 dark:text-tw-main-text-dark/50 bg-transparent hover:text-tw-main-text dark:hover:text-tw-main-text-dark/80',
      variant === 'default-ghost-ring' &&
        'text-tw-main-text dark:text-tw-main-text-dark bg-transparent hover:bg-white/50 dark:hover:bg-black/50 hover:ring-tw-main-color dark:hover:ring-tw-main-color-dark hover:ring-2',
      variant === 'critical-ghost-ring' &&
        'text-tw-main-text dark:text-tw-main-text-dark bg-transparent hover:bg-white/50 dark:hover:bg-black/50 ring-tw-danger-color dark:ring-tw-danger-color-dark ring-1 hover:ring-2',
      variant === 'primary' &&
        'text-white bg-tw-main-color hover:brightness-125 px-4',
      variant === 'critical' &&
        'text-white bg-tw-danger-color dark:bg-tw-danger-color-dark hover:brightness-125 ',
      variant === 'critical-ghost' &&
        'text-tw-danger-color bg-transparent hover:bg-[#FDE8E8] dark:hover:bg-[#3D3D3D]',
      variant === 'primary-ghost' &&
        'text-tw-main-color bg-transparent hover:bg-[#E6F6FF] dark:hover:bg-[#3D3D3D] ring-1 ring-tw-main-color dark:ring-tw-main-color-dark hover:ring-2',
    ),
  );
const disabledClassName =
  'text-tw-inactive-color bg-tw-button-disabled dark:bg-tw-button-disabled-dark dark:text-tw-main-text-dark/10';

export const getContainerClassNames = ({
  disabled,
  variant,
  className,
}: {
  disabled?: boolean;
  className?: string;
  variant?: variant;
}) =>
  twMerge(
    classNames(
      `m-1 rounded-md p-2 font-graphikMedium transition active:brightness-75 text-sm`,
      disabled ? disabledClassName : getEnabledClassName(variant),
      className,
    ),
  );

export interface SimpleButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  disabled?: boolean;
  className?: string;
  variant?: variant;
  loading?: boolean;
}
export const SimpleButton = ({
  disabled,
  className,
  variant = 'primary',
  children,
  loading,
  ...rest
}: SimpleButtonProps) => {
  return (
    <button
      data-testid="SecondaryButton"
      className={getContainerClassNames({
        disabled,
        variant,
        className,
      })}
      disabled={disabled}
      children={loading ? <Spinner className="m-auto h-4 w-4" /> : children}
      {...rest}
    />
  );
};
