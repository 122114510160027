import TeamIcon from '../../../assets/icons/DBSizeNavIcons/TeamIcon';
import { SimpleButton } from '../../../components/Buttons/SimpleButton';
import {
  PickerOption,
  PickerOptions,
  Select,
} from '../../../components/form/fields';
import SearchInput from '../../../components/form/fields/SearchInput/SearchInput';
import { UserRole } from '../../../gql/graphql';
import { useSession } from '../../../utils/hooks/useSession';

interface IProps {
  onInvite: () => void;
  onSearch: (value: string) => void;
  onConfirmSearch?: () => void;
  onRoleFilter: (role: UserRole | undefined) => void;
  searchValue?: string;
  searchPaused?: boolean;
}

const filterOptions: PickerOptions = [
  {
    label: 'Admin',
    value: UserRole.OrgAdmin,
  },
  {
    label: 'User',
    value: UserRole.OrgUser,
  },
  {
    label: 'SuperAdmin',
    value: UserRole.PlAdmin,
  },
  // {
  //   label: 'Pending',
  //   value: 'Pending',
  // },
  {
    label: 'All Users',
    value: 'All Users',
  },
];

const Header = ({
  onInvite,
  onSearch,
  onConfirmSearch,
  onRoleFilter,
  searchValue,
  searchPaused,
}: IProps) => {
  const handleRoleFilter = (value: PickerOption | undefined) => {
    if (!value || value?.value === 'All Users') {
      onRoleFilter(undefined);
    } else {
      onRoleFilter(value?.value as UserRole);
    }
  };

  const { isAdmin, isSuperAdmin } = useSession();
  return (
    <div data-testid="team-header" className="flex w-full flex-col">
      <div className="flex w-full items-center gap-3 py-4">
        <div className="w-full">
          <SearchInput
            onChange={onSearch}
            onPressEnter={onConfirmSearch}
            placeholder={'Search users...'}
            pauseInput={searchPaused}
            value={searchValue}
            customClassNames={{
              input: 'w-full text-[11px] -pl-2 font-graphikRegular mt-0.5',
              searchIcon: 'h-5 w-5',
            }}
            data-testid="search-team-members"
            inputProps={{
              name: 'search-team-members',
            }}
          />
        </div>
        <div className="flex items-center gap-3">
          <Select
            value={'All Users'}
            options={filterOptions}
            onChange={handleRoleFilter}
            className="w-[170px]"
          />
          {(isAdmin || isSuperAdmin) && (
            <SimpleButton
              variant="primary"
              className="flex h-[42px] items-center whitespace-nowrap px-5 text-sm"
              onClick={() => onInvite()}
              aria-label="Add user"
            >
              <TeamIcon className="mr-2" /> Add User
            </SimpleButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
