import React from 'react';

export const WarningCircleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="31"
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <rect
        x="14.2348"
        y="10.3335"
        width="2.06667"
        height="7.10417"
        rx="1.03333"
        fill="currentColor"
      />
      <rect
        x="14.2348"
        y="19.5044"
        width="2.06667"
        height="2.06667"
        rx="1.03333"
        fill="currentColor"
      />
      <circle
        cx="15.5264"
        cy="15.5001"
        r="9.58333"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default WarningCircleIcon;
