import React from 'react';
import { ExtendedPlugin } from './types';

const defaultContextValue: {
  plugins: ExtendedPlugin[];
  loading: boolean;
} = {
  plugins: [],
  loading: true,
};

export const MicroFrontendContext = React.createContext(defaultContextValue);
