import { useQuery } from '@apollo/client';
import {
  faArchive,
  faBell as faBellSolid,
  faCalendarDay as faCalendarSolid,
} from '@fortawesome/free-solid-svg-icons';
import { faGrid2, faServer } from '@fortawesome/pro-regular-svg-icons';
import { KeyboardEventHandler, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_APPLIANCES } from '../../api/appliance/queries';
import {
  AlertType,
  Category,
  GetAppliancesQuery,
  GetAppliancesQueryVariables,
} from '../../gql/graphql';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  updateFilterAlertCodes,
  updateFilterAlertType,
  updateProductsFilterAppliance,
  updateProductsFilterCategory,
  updateProductsFilterDuration,
  updateProductsFilterProducts,
} from '../../redux/products/filterSlice';
import { toTitleCase } from '../../utils/format';
import { useSession } from '../../utils/hooks/useSession';
import { isInStringEnum, isProductKind } from '../../utils/products';
import { RANGE_OPTIONS } from '../CalenderRangePicker/utils';
import { FilterSelectInline } from '../FilterSelect/FilterSelectInline';
import { PickerOptions } from '../form/fields';

export const AlertFilterSelectInline = () => {
  const productFilter = useAppSelector((state) => state.productFilter);
  const { installed: installedProducts } = useAppSelector(
    (state) => state.products.products,
  );

  const { skip } = useSession();
  const appliancesQuery = useQuery<
    GetAppliancesQuery,
    GetAppliancesQueryVariables
  >(GET_APPLIANCES, {
    skip,
  });

  const dispatch = useAppDispatch();

  const productOptions = useMemo(
    () =>
      installedProducts.map((p) => ({
        label: p.name,
        value: p.id,
      })),
    [installedProducts],
  );

  const appliancesOptions = useMemo(
    () =>
      appliancesQuery?.data?.appliances.map((appliance) => ({
        label: appliance.description,
        value: appliance.id,
      })) || [],
    [appliancesQuery],
  );

  const durationOptions = useMemo(() => RANGE_OPTIONS, [RANGE_OPTIONS]);

  const { t, i18n } = useTranslation([
    'alertCodes',
    'coverage',
    'notifications',
  ]);

  const codes = useMemo(
    () =>
      Object.keys(i18n.getResourceBundle('en', 'alertCodes'))
        // Hide search alert codes in alert
        .filter((code) => !code.startsWith('ES')),
    [i18n],
  );
  const codeNames = useMemo(
    () =>
      codes.map((code) => ({
        label: t(`alertCodes:${code}.description`),
        value: code,
      })),
    [codes, t],
  );
  const alertCodesOptions = useMemo(
    (): PickerOptions =>
      codeNames.map((code) => ({
        value: code.value,
        textValue: code.label,
        label: (
          <>
            <span
              data-testid={`alarm-code-option`}
              data-test-value={code.value}
            >
              {code.value}:{' '}
            </span>
            {code.label}
          </>
        ),
      })),
    [codeNames],
  );

  const categoryOptions = useMemo(
    () =>
      Object.values(Category).map((category) => ({
        value: category,
        label: t(`coverage.category.${category}.name`, category, {
          ns: 'coverage',
        }),
      })),
    [t],
  );

  const alertTypeOptions = useMemo(
    (): PickerOptions =>
      Object.values(AlertType).map((value) => ({
        value,
        label: t(`notifications.type.${value}`, toTitleCase(value), {
          ns: 'notifications',
        }),
      })),
    [],
  );

  const handleSaveFilters = useCallback(
    (value: {
      capability?: string[];
      appliances?: string[];
      alertCodes?: string[];
      alertType?: string[];
      duration?: string;
      category?: string;
    }) => {
      value?.capability &&
        value.capability.every(isProductKind) &&
        dispatch(updateProductsFilterProducts(value.capability));
      value?.appliances &&
        dispatch(updateProductsFilterAppliance(value?.appliances));
      value?.duration &&
        dispatch(updateProductsFilterDuration(value?.duration));
      value?.alertCodes && dispatch(updateFilterAlertCodes(value?.alertCodes));
      value?.category &&
        isInStringEnum(Category)(value.category) &&
        dispatch(updateProductsFilterCategory(value?.category));
      value?.alertType &&
        value.alertType.every(isInStringEnum(AlertType)) &&
        dispatch(updateFilterAlertType(value?.alertType));
    },
    [],
  );
  const handleClearFilters = useCallback(
    (value: {
      capability?: true;
      appliances?: true;
      alertCodes?: true;
      duration?: true;
      category?: true;
      alertType?: true;
    }) => {
      value?.capability && dispatch(updateProductsFilterProducts([]));
      value?.appliances && dispatch(updateProductsFilterAppliance(null));
      value?.alertCodes && dispatch(updateFilterAlertCodes([]));
      value?.category && dispatch(updateProductsFilterCategory(null));
      value?.alertType && dispatch(updateFilterAlertType(undefined));
    },
    [],
  );

  const handleSearchKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) =>
    e.stopPropagation();

  return (
    <FilterSelectInline
      filters={{
        capability: {
          label: 'Capability',
          dataTestType: 'CAPABILITY',
          type: 'multi',
          options: productOptions,
          icon: faGrid2,
        },
        appliances: {
          label: 'Appliance',
          dataTestType: 'APPLIANCE',
          type: 'multi',
          options: appliancesOptions,
          icon: faServer,
        },
        alertCodes: {
          label: 'Alert',
          dataTestType: 'ALERT_CODE',
          type: 'multi',
          options: alertCodesOptions,
          icon: faBellSolid,
          pickerProps: {
            checkboxPosition: 'top',
            searchable: true,
            searchProps: {
              inputProps: {
                onKeyDown: handleSearchKeyDown,
              },
            },
            customClassNames: {
              main: 'max-h-[20rem] gap-2',
              options: 'scrollbar max-h-[20rem] w-full overflow-y-auto p-1',
            },
          },
        },
        duration: {
          label: 'Date',
          dataTestType: 'DATE',
          type: 'single',
          options: durationOptions,
          required: true,
          icon: faCalendarSolid,
        },
        category: {
          label: 'Category',
          dataTestType: 'CATEGORY',
          type: 'single',
          options: categoryOptions,
          icon: faArchive,
        },
        alertType: {
          label: 'Alert type',
          dataTestType: 'ALERT_TYPE',
          type: 'multi',
          options: alertTypeOptions,
          icon: faBellSolid,
        },
      }}
      value={{
        capability: productFilter.products,
        appliances: productFilter.appliances || undefined,
        alertCodes: productFilter.alertCodes,
        duration: productFilter.duration,
        category: productFilter.category ? productFilter.category : undefined,
        alertType: productFilter.alertType,
      }}
      onClear={handleClearFilters}
      onChange={handleSaveFilters}
    />
  );
};
