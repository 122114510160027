import { CoverageCard } from '../../components/DashCards/CoverageStrength';
import { ProductStatusesCard } from '../../components/DashCards/ProductStatuses';
import FilterButtonHolder from '../../components/Elements/FilterButtons/FilterButtonHolder';
import {
  GetAvailablePublicProductsQuery,
  GetInstalledProductsQuery,
} from '../../gql/graphql';
import { ComponentErrorHandler } from '../../utils/ComponentErrorHandler';

export const AppStatusesAndCoverageChart = ({
  installedApps,
  missingApps,
  loading,
}: {
  installedApps: GetInstalledProductsQuery['products'];
  missingApps: GetAvailablePublicProductsQuery['products'];
  loading?: boolean;
}) => {
  return (
    <>
      <FilterButtonHolder />
      <div className="mb-3 flex flex-col gap-3 lg:flex-row">
        <ComponentErrorHandler title="Error rendering alerts by capabilities">
          <ProductStatusesCard
            //change this to use the new applianceFilter
            installedProducts={installedApps}
            missingProducts={missingApps}
            loading={loading}
            className="w-full lg:w-2/6"
          />
        </ComponentErrorHandler>

        <ComponentErrorHandler title="Error rendering Coverage Strength">
          <CoverageCard />
        </ComponentErrorHandler>
      </div>
    </>
  );
};
