import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';
import { IconComponent, IconName } from '../../../../assets/icons/Icon';
import { FieldError, Label } from '../Common';
import { Size, Variant } from '../types';
import { DropDownButton } from './DropDownButton';

export interface PickerOption {
  label: React.ReactNode;
  value: string | number;
  description?: React.ReactNode;
  disabled?: boolean;
  data?: object;
}

export type PickerOptions =
  | PickerOption[]
  | string[]
  | number[]
  | (string | number)[];

export interface OptionRenderPropArg {
  active: boolean;
  selected: boolean;
  disabled: boolean;
}
export interface MenuProps {
  id?: string;
  variant?: Variant;
  size?: Size;
  className?: string;

  Icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  iconName?: IconName;
  label?: React.ReactNode;
  dropDownLabel: React.ReactNode;
  disabled?: boolean;
  clearable?: boolean;
  error?: string | string[];
  children?:
    | React.ReactNode
    | ((props: { open: boolean; close: () => void }) => React.ReactNode);
}

export const MenuField = ({
  dropDownLabel,
  label,
  variant = 'default',
  size = 'medium',
  Icon: iconComponent,
  iconName,
  id,
  disabled = false,
  clearable = false,
  error,
  className,
  children,
  ...props
}: MenuProps) => {
  const Icon = iconComponent || (iconName && IconComponent({ iconName }));

  return (
    <div
      data-testid="Picker"
      className={twMerge(classNames('w-full'))}
      {...props}
    >
      {label && <Label htmlFor={id}>{label}</Label>}
      <Menu>
        {({ open, close }) => (
          <div className={classNames('relative', className)}>
            <DropDownButton
              open={open}
              Icon={Icon}
              variant={variant}
              size={size}
              buttonType="menu"
              label={dropDownLabel}
              disabled={disabled}
              clearable={clearable}
              error={error}
              // TODO
              onClear={() => null}
              className="py-1.5"
            />
            <Transition
              as={Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="transform opacity-0"
              enterTo="transform opacity-100"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Menu.Items
                className={twMerge(
                  classNames(
                    'scrollbar',
                    'absolute z-50 mt-0 max-h-screen w-full overflow-auto rounded-b-md py-1 font-graphikRegular text-tw-light-text shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                    'dark:text-tw-light-text-dark dark:ring-white/5',
                    variant === 'default' &&
                      'bg-tw-light-shade dark:bg-tw-strong-shade-dark',
                    variant === 'white' &&
                      'bg-white dark:bg-tw-dark-shade-dark',
                    variant === 'soft' &&
                      'bg-tw-surface-soft dark:bg-tw-surface-soft-dark',
                  ),
                )}
              >
                {typeof children === 'function'
                  ? children({ open, close })
                  : children}
              </Menu.Items>
            </Transition>
          </div>
        )}
      </Menu>
      {error && <FieldError error={error} />}
    </div>
  );
};

export default MenuField;
