import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MAIN_TOOLTIP_ID } from '../../../App';
import { ProductKind } from '../../../api/product/types';
import { Icon, IconName } from '../../../assets/icons/Icon';
import { getContainerClassNames } from '../../Buttons/SimpleButton';
import { ProductIcon } from '../Icons/ProductIcons/ProductIcon';
import { IAppliance, IProduct } from './FilterButtonHolder';

interface IProps {
  selected: Array<string> | null;
  appliances: Array<IAppliance>;
  updateSelected: (app: string | null) => void;
}

const FilterButtonList = ({ selected, appliances, updateSelected }: IProps) => {
  const selectAllDisabled = useMemo(
    () =>
      !!(
        !selected ||
        (!selected?.length && appliances.length) ||
        selected?.length === appliances.length
      ),
    [selected, appliances],
  );

  return (
    <div
      data-testid="listbox-options"
      className="relative mb-2 flex flex-row items-center justify-end"
    >
      <Listbox value={selected || []} multiple>
        <Listbox.Button
          data-testid="appliance-filter-listbox-button"
          className={getContainerClassNames({
            variant: 'soft',
            className:
              'flex w-auto flex-row items-center justify-between rounded-lg p-6 py-3 font-graphikRegular',
          })}
        >
          {selected &&
          selected.length &&
          selected.length !== appliances.length ? (
            <>
              {selected.length} / {appliances.length} Appliances
            </>
          ) : (
            <>All Appliances</>
          )}
          <Icon className="-mr-4 ml-2" iconName={IconName.ChevronDownIcon} />
        </Listbox.Button>
        <Listbox.Options className="absolute right-0 top-[58px] z-50 rounded-[10px] border bg-white p-2 shadow-md dark:border-[#383A40] dark:bg-[#25282E]">
          <Listbox.Option
            value={'all'}
            data-test-value="all"
            onClick={() => updateSelected(null)}
            disabled={selectAllDisabled}
            className={classNames(
              'flex h-[60px] w-[300px] cursor-pointer flex-row items-center justify-between bg-white p-3 dark:bg-[#25282D]',
            )}
          >
            <span
              className={classNames(
                selectAllDisabled &&
                  'text-tw-label-quaternary dark:text-tw-label-quaternary-dark',
              )}
            >
              Select all appliances
            </span>
            <FontAwesomeIcon
              icon={faCheck}
              className={classNames(
                'h-[10px] w-[16px]',
                selectAllDisabled && 'text-tw-main-color',
              )}
            />
          </Listbox.Option>
          {appliances.map((appliance) => (
            <ListboxOption
              data-test-value={appliance.id}
              key={appliance.id}
              appliance={appliance}
              updateSelected={updateSelected}
              selected={selected}
            />
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

export default FilterButtonList;

interface IOptionProps {
  appliance: IAppliance;
  updateSelected: (app: string) => void;
  selected: Array<string> | null;
}

const ApplianceProductIcon = ({
  app,
  isSelected,
  hidden,
}: {
  app: IProduct;
  isSelected: boolean;
  hidden?: boolean;
}) => {
  const { t } = useTranslation('productInfo');
  return (
    <ProductIcon
      data-tooltip-id={MAIN_TOOLTIP_ID}
      data-tooltip-content={t(`${app.kind}.name`, app.kind)}
      key={app.kind}
      productKind={app.kind as ProductKind}
      disabled={!isSelected}
      variant="OnlyIcon"
      className={classNames(
        'h-10 w-10 rounded-md border-2',
        hidden && 'hidden',
        isSelected
          ? 'bg-tw-main-color fill-tw-main-color text-white dark:border-[#EEF4FF] dark:bg-white dark:fill-white dark:text-tw-main-color'
          : 'dark:border-[#383942] dark:fill-[#25282D] dark:text-[#897D7D]',
      )}
    />
  );
};

const ListboxOption = ({
  appliance,
  updateSelected,
  selected,
}: IOptionProps) => {
  const [hover, setHover] = useState(false);

  const isSelected =
    !selected || !selected.length || selected.includes(appliance.applianceId);
  const AppIcons = useMemo(() => {
    return (
      appliance.installedProducts
        // .slice(0, hover ? undefined : 1)
        .map((app, i) => (
          <ApplianceProductIcon
            key={app.id}
            hidden={i > 0 && !hover}
            app={app.product}
            isSelected={isSelected}
          />
        ))
    );
  }, [hover, appliance.installedProducts, isSelected]);

  return (
    <Listbox.Option
      data-testid="listbox-option"
      data-test-value={appliance.id}
      key={appliance.id}
      onClick={() => updateSelected(appliance.applianceId)}
      value={appliance}
      className={`flex h-[60px] w-[300px] cursor-pointer flex-row items-center justify-between overflow-hidden truncate bg-white p-3 dark:bg-[#25282D]`}
    >
      <div className="flex flex-row items-center gap-2 truncate">
        {appliance.installedProducts.length > 0 && (
          <div
            className="flex shrink-0 flex-row items-center gap-1"
            onMouseEnter={() =>
              appliance.installedProducts.length && setHover(true)
            }
            onMouseLeave={() => setHover(false)}
          >
            {AppIcons}
            {!hover && appliance.installedProducts.length > 1 && (
              <span className="font-graphikMedium text-[#897D7D]">
                +{appliance.installedProducts.length - 1}
              </span>
            )}
          </div>
        )}
        <div className="flex shrink flex-col items-start gap-1 truncate">
          <h5
            className={classNames(
              'w-full truncate font-graphikMedium',
              isSelected ? 'dark:text-white' : 'dark:text-[#897D7D]',
            )}
            style={{ fontSize: '12px' }}
          >
            {appliance.description}
          </h5>
          <p
            className="w-full truncate font-graphikMedium text-[#897D7D]"
            style={{ fontSize: '10px' }}
          >
            {appliance.node}
          </p>
        </div>
      </div>
      {isSelected && (
        <FontAwesomeIcon
          icon={faCheck}
          className="h-[10px] w-[16px] shrink-0 text-tw-main-color"
        />
      )}
    </Listbox.Option>
  );
};
