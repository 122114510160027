import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { MockedProvider } from '@apollo/client/testing';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { checkOrySession } from '../ory/checkSession';
import { useAppDispatch } from '../redux/hooks';
import { TenantInfo } from '../redux/login/loginSlice';

function hasOwnProperty<X extends object, Y extends PropertyKey>(
  obj: X,
  prop: Y,
): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

// Error handling on apollo calls ( Network and graphQL )
const errorLink = (dispatch?: Dispatch<AnyAction>) =>
  onError(({ graphQLErrors, networkError, ...rest }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, ...rest }) => {
        console.log(`[GraphQL error]`, {
          message,
          locations,
          path,
          rest,
        });
        toast.error(message, {
          // Do not duplicate toast with same text
          toastId: message,
        });
      });
    } else if (networkError) {
      console.log(`[Network error]: `, { networkError, rest });
      if (hasOwnProperty(networkError, 'statusCode')) {
        const is401 = networkError.statusCode === 401;
        // Check if the session is still valid
        is401 && dispatch && checkOrySession(dispatch);
      }
      toast.error(networkError.message, {
        // Do not duplicate toast with same text
        toastId: networkError.message,
      });
    }
  });

const getClient = (tenant = 'public', dispatch?: Dispatch<AnyAction>) => {
  const httpLink = createHttpLink({
    uri: `/api/graphql${tenant ? `/${tenant}` : ''}`,
  });
  return new ApolloClient({
    link: from([errorLink(dispatch), httpLink]),
    cache: new InMemoryCache(),
  });
};

export const PUBLIC_CLIENT = getClient();

export const ApolloProviderWithSetup = ({
  isMocked,
  tenant,
  children,
}: {
  isMocked?: boolean;
  tenant?: TenantInfo | null;
  children: React.ReactNode;
}) => {
  const dispatch = useAppDispatch();
  const client = useMemo(
    () => getClient(tenant?.tenantId || undefined, dispatch),
    [tenant],
  );

  const [mockedLink, setMockedLink] = useState<ApolloLink>();

  if (isMocked) {
    import('./mockApolloServer/mocksSchema')
      .then(({ link }) => setMockedLink(link))
      .catch((err) => {
        // Handle failure
        console.error('Error loading mock server', err);
      });
  }
  return mockedLink ? (
    <MockedProvider link={mockedLink} addTypename={false}>
      {children}
    </MockedProvider>
  ) : (
    <ApolloProvider client={client}>{children}</ApolloProvider>
  );
};
