import React from 'react';

export const SecurityAndAuditIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M19.4454 6.07567L15.8442 0.898961C15.6927 0.681169 15.4908 0.503253 15.2556 0.380389C15.0205 0.257524 14.7591 0.193357 14.4938 0.193359H5.24621C4.98091 0.193357 4.71953 0.257524 4.48439 0.380389C4.24925 0.503253 4.04731 0.681169 3.89581 0.898961L0.294626 6.07567C0.102815 6.3514 3.88131e-07 6.67923 0 7.01511L0 11.7084C0 12.6169 0.73648 13.3534 1.645 13.3534H18.095C19.0035 13.3534 19.74 12.6169 19.74 11.7084V7.01511C19.74 6.67922 19.6372 6.35142 19.4454 6.07567ZM5.24621 1.83836H14.4938L17.1639 5.67669H12.9658L11.8692 7.87003H7.87085L6.77418 5.67669H2.57607L5.24621 1.83836ZM18.095 11.7084H1.645V7.32169H5.7575L6.85417 9.51503H12.8858L13.9825 7.32169H18.095V11.7084Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SecurityAndAuditIcon;
