import { BellIcon, InventoryIcon, SettingsIcon } from '../../../assets';
import AlertsIcon from '../../../assets/icons/DBSizeNavIcons/AlertsIcon';
import OverviewIcon from '../../../assets/icons/DBSizeNavIcons/OverviewIcon';
import TeamIcon from '../../../assets/icons/DBSizeNavIcons/TeamIcon';

import { useFeature } from 'featurehub-react-sdk';
import OrganizationsIcon from '../../../assets/icons/DBSizeNavIcons/OrganizationsIcon';
import { NotificationBadgeWithQuery } from '../../../components/Elements/Notification/NotificationBadge';
import { FeatureFlag } from '../../../utils/featureHub/constants';
import { useSession } from '../../../utils/hooks/useSession';
import { SideNav } from '../SideNav';
import { SideNavGroupProps } from '../interfaces';

export const DB_SIDE_NAV_GROUPS: Array<SideNavGroupProps> = [
  {
    gridView: 'first',
    items: [
      {
        title: 'Capabilities',
        icon: <OverviewIcon />,
        sideNavPath: '/app/dashboard',
      },
      {
        title: 'Alerts',
        content: <NotificationBadgeWithQuery />,
        icon: <AlertsIcon />,
        sideNavPath: '/app/dashboard/alerts',
      },
    ],
  },

  // {
  //   gridView: 'second',
  //   items: [
  //     {
  //       title: 'System Health',
  //       icon: <SystemHealthIcon />,
  //       sideNavPath: '/app/dashboard/system-health',
  //     },
  //   ],
  // },
  {
    gridView: 'second',
    items: [
      {
        title: 'Team',
        icon: <TeamIcon />,
        sideNavPath: '/app/dashboard/team',
      },
    ],
  },
  {
    gridView: 'third',
    items: [],
  },
];

/**
 * Navigation component that manages visible elements using the "useFeature" hook
 * @returns A sidebar using the hook useFeature to handle visible menu items
 */
export const DefaultSidebar = () => {
  //RELEASE FLAG
  const featureAlertWorkflows = useFeature(FeatureFlag.ALERT_WORKFLOW);
  const featureInventory = useFeature(FeatureFlag.INVENTORY);
  const featureDynamicSettings = useFeature(FeatureFlag.DYNAMIC_SETTINGS);

  const { isAdmin } = useSession();
  const { isSuperAdmin } = useSession();

  const sideNavGroups: Array<SideNavGroupProps> = DB_SIDE_NAV_GROUPS.map(
    (group) => {
      if (group.gridView === 'first') {
        return {
          ...group,
          items: [
            ...group.items,
            ...(featureInventory && (isAdmin || isSuperAdmin)
              ? [
                  {
                    title: 'Inventory',
                    icon: <InventoryIcon />,
                    sideNavPath: '/app/dashboard/inventory',
                  },
                ]
              : []),
          ],
        };
      } else if (group.gridView === 'second') {
        return {
          ...group,
          items: [
            ...group.items,
            ...(featureAlertWorkflows
              ? [
                  {
                    title: 'Alert Workflows',
                    icon: <BellIcon />,
                    sideNavPath: '/app/dashboard/alert-workflows',
                  },
                ]
              : []),
            ...(featureDynamicSettings
              ? [
                  {
                    title: 'Settings',
                    icon: <SettingsIcon />,
                    sideNavPath: '/app/dashboard/settings',
                  },
                ]
              : []),
          ],
        };
      } else if (group.gridView === 'third') {
        return {
          ...group,
          items: [
            ...group.items,
            ...(isSuperAdmin
              ? [
                  {
                    title: 'Organizations',
                    icon: <OrganizationsIcon />,
                    sideNavPath: '/app/dashboard/organizations',
                  },
                ]
              : []),
          ],
        };
      } else {
        return group;
      }
    },
  );

  return <SideNav sideNavGroups={sideNavGroups} />;
};
