import {
  FrontendApiUpdateRecoveryFlowRequest,
  UiNodeInputAttributes,
} from '@ory/kratos-client';
import { ory } from './config';

export const FLOW_QUERY_PARAM = 'flow';

let recoveryFlowId: string, csrfToken: any;

export const createOtpRecoveryFlow = async (email: string) => {
  const { data } = await ory.frontend.createBrowserRecoveryFlow();
  recoveryFlowId = data.id;
  csrfToken = (data.ui.nodes[0].attributes as UiNodeInputAttributes).value;
  const requestParameters: FrontendApiUpdateRecoveryFlowRequest = {
    flow: String(recoveryFlowId),
    updateRecoveryFlowBody: {
      csrf_token: csrfToken,
      email: email,
      method: 'code',
    },
  };
  await ory.frontend.updateRecoveryFlow(requestParameters);
};

export const submitOtpCode = async (code: string) => {
  let errorMessages: string[] = [];
  const requestParameters: FrontendApiUpdateRecoveryFlowRequest = {
    flow: String(recoveryFlowId),
    updateRecoveryFlowBody: {
      csrf_token: csrfToken,
      method: 'code',
      code: code,
    },
  };

  try {
    const response = await ory.frontend.updateRecoveryFlow(requestParameters);
    errorMessages =
      response.data.ui.messages?.map((message) => message.text) || [];
  } catch (error: any) {
    if (error.response?.status === 422) {
      window.location.href = `${window.location.origin}/newPassword?${FLOW_QUERY_PARAM}=${recoveryFlowId}`;
    } else {
      throw error;
    }
  }

  return errorMessages;
};

export const submitOtpCodeFromParams = async (
  code: string,
  paramsFlow: string,
) => {
  let errorMessages: string[] = [];
  const requestParameters: FrontendApiUpdateRecoveryFlowRequest = {
    flow: paramsFlow,
    updateRecoveryFlowBody: {
      method: 'code',
      code: code,
    },
  };

  try {
    const response: any = await ory.frontend.updateRecoveryFlow(
      requestParameters,
    );
    errorMessages = response.data.ui.messages.map(
      (message: any) => message.text,
    );
  } catch (error: any) {
    if (error.response?.status === 422) {
      window.location.href = `${window.location.origin}/newPassword?${FLOW_QUERY_PARAM}=${recoveryFlowId}`;
    } else {
      throw error;
    }
  }

  return errorMessages;
};
