import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { BASE_BG } from '../fields/utils';
import { FormElement } from './FormElement';
import { ChangeHandler, FormFields, FormFieldValue } from './types';
import { updateFormFieldsValueByPath } from './utils';

export interface DynamicFormOnChangeEvent {
  id: string;
  value: FormFieldValue;
  data: FormFields;
  pathArray?: string[];
  isValid?: boolean;
}
export interface DynamicFormProps {
  formFields: FormFields;
  searchTerm?: string;
  onChange?: (event: DynamicFormOnChangeEvent) => void;
  customValidation?: (data: FormFields) => {
    isValid: boolean;
    validatedFormFields: FormFields;
  };
  liveValidate?: boolean;
}

export const DynamicForm = ({
  formFields,
  onChange,
  searchTerm,
}: DynamicFormProps) => {
  const [formData, setFormData] = useState(formFields);

  useEffect(() => {
    setFormData(formFields);
  }, [formFields]);

  const handleChange: ChangeHandler = ({ id, value, pathArray }) => {
    const newFormData = updateFormFieldsValueByPath(
      formData,
      id,
      pathArray,
      value,
    );
    setFormData(newFormData);
    onChange && onChange({ id, value, data: newFormData, pathArray });
  };

  return (
    <div className={classNames('flex flex-col gap-1 p-4', BASE_BG)}>
      {formData.map((field) => (
        <FormElement
          key={field.id}
          field={field}
          onChange={handleChange}
          searchTerm={searchTerm}
        />
      ))}
    </div>
  );
};
