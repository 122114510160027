import { Listbox, Menu, Popover } from '@headlessui/react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { CrossIcon } from '../../../../assets/icons';
import { Size, Variant } from '../types';
import { getPickerButtonClassName } from '../utils';
import { DropDownIcon } from './DropDownIcon';

type ButtonType = 'popover' | 'listBox' | 'button' | 'menu';
const getWrapper = ({ type }: { type: ButtonType }) => {
  switch (type) {
    case 'popover':
      return Popover.Button;
    case 'menu':
      return Menu.Button;
    case 'listBox':
      return Listbox.Button;
    default:
      return 'button';
  }
};

export const DropDownButton = ({
  open,
  className,
  Icon,
  label,
  placeholder = 'Select an option',
  variant,
  size,
  disabled,
  buttonType = 'popover',
  clearable = false,
  error,
  onClear,
}: {
  open?: boolean;
  className?: string;
  Icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  label?: string | React.ReactNode;
  placeholder?: string;
  variant?: Variant;
  size?: Size;
  disabled?: boolean;
  buttonType?: ButtonType;
  clearable?: boolean;
  error?: string | string[];
  onClear?: () => void;
}) => {
  const buttonClassName = getPickerButtonClassName({
    variant,
    size,
    open,
    hasIcon: !!Icon,
    disabled,
    error,
  });
  const Wrapper = getWrapper({ type: buttonType });
  return (
    <Wrapper
      className={twMerge(classNames(buttonClassName, className))}
      disabled={disabled}
    >
      {Icon && (
        <div
          className={classNames(
            'mr-4 h-4 w-4',
            disabled
              ? 'text-tw-inactive-color dark:text-tw-inactive-color-dark'
              : 'text-tw-main-text dark:text-tw-main-text-dark',
          )}
        >
          <Icon aria-hidden="true" aria-labelledby="icon" className="h-4 w-4" />
        </div>
      )}
      <span className="block truncate">
        {label || (
          <span className="text-tw-description-text">{placeholder}</span>
        )}
      </span>
      {clearable && (
        <span
          className={classNames(
            'absolute inset-y-0 right-8 ml-2 flex items-center pr-2',
            'text-tw-description-text hover:text-tw-main-text dark:text-tw-description-text dark:hover:text-white',
          )}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onClear && onClear();
          }}
        >
          <CrossIcon className="h-2.5 w-2.5" />
        </span>
      )}
      <DropDownIcon open={open} disabled={disabled} />
    </Wrapper>
  );
};
