import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { ProductKind } from '../../../../api/product/types';
import {
  AlertCount,
  AlertSeverity,
  GetInstalledProductsAlertsQuery,
} from '../../../../gql/graphql';
import { ExtendedInstalledProduct } from '../../../../pages/dashboard/hooks/useProducts';
import { ProductIconWithRoundedBgAndBorder } from '../../../Elements/Icons/ProductIcons/ProductIcons';

export type Severity = 'critical' | 'error' | 'standard' | undefined;

//Type Checking for Apps
export const isInstalledApp =
  (installedProducts: ExtendedInstalledProduct[]) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (x: any): x is ExtendedInstalledProduct =>
    !!installedProducts.find((item) => item?.kind === x?.kind);

export const totalAlertsCount = (
  product: GetInstalledProductsAlertsQuery['products'][0],
) =>
  product.alertsCount.critical +
  product.alertsCount.error +
  product.alertsCount.standard;

export const getMaxSeverityAlertCount = (alertCount: AlertCount) => {
  const { critical, error, standard } = alertCount;
  if (critical > 0) {
    return critical;
  }
  if (error > 0) {
    return error;
  }
  if (standard > 0) {
    return standard;
  }
  return 0;
};

export const getAlertSeverity = (
  product: GetInstalledProductsAlertsQuery['products'][0],
) => {
  const { critical, error, standard } = product.alertsCount;
  if (critical > 0) {
    return {
      critical,
    };
  }
  if (error > 0) {
    return {
      error,
    };
  }
  if (standard > 0) {
    return {
      standard,
    };
  }
  return undefined;
};

export const colorBySeverity = (severity?: Severity) => {
  switch (severity) {
    case 'critical':
      return '#E22134';
    case 'error':
      return '#FF9900';
    case 'standard':
      return '#2A63EF';
    default:
      return '#20B782';
  }
};
export const getBlankPathBySeverity = (severity?: Severity) => (
  <g key="empty">
    <path
      d={
        'M3.061616997868383e-15,-50A50,50,0,0,1,43.30127018922193,-25A50,50,0,0,0,3.061616997868383e-15,-50Z'
      }
      opacity={1}
      fill={colorBySeverity(severity)}
      fillOpacity={1}
      rx={1}
    />
  </g>
);
export const getIconClassBySeverity = (severity?: Severity | string) => {
  switch (severity) {
    case 'critical':
    case AlertSeverity.Critical:
      return 'text-[#E22134] stroke-[#E22134]';
    case 'error':
    case AlertSeverity.Error:
      return 'text-[#FF9900] stroke-[#FF9900]';
    case 'standard':
    case AlertSeverity.Standard:
      return 'text-[#2A63EF] stroke-[#2A63EF]';
    default:
      return 'text-[#20B782] stroke-[#20B782]';
  }
};
export const getLogo = (kind: ProductKind, severity?: Severity) => {
  return (
    <ProductIconWithRoundedBgAndBorder
      width="28px"
      height="28px"
      productKind={kind}
      strokeWidth={2}
      className={twMerge(
        classNames(
          'fill-white dark:fill-black',
          getIconClassBySeverity(severity),
          'transition duration-300',
        ),
      )}
      data-testid={`rose-chart-product-logo-${kind}`}
      aria-disabled={false}
    />
  );
};
export const getNotActivatedLogo = (productKind: string) => (
  <ProductIconWithRoundedBgAndBorder
    width="28px"
    height="28px"
    productKind={productKind as ProductKind}
    strokeWidth={2}
    className={
      'fill-white stroke-[#DCDCDC] text-[#DCDCDC] dark:fill-black dark:stroke-[#131517]'
    }
    data-testid={`rose-chart-product-logo-${productKind}`}
    aria-disabled={true}
  />
);
