import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faServer } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const EmptyMessage = ({
  title,
  description,
  icon = faServer,
}: {
  title: string;
  description: string;
  icon?: IconProp;
}) => {
  return (
    <div className="flex flex-col items-center p-10">
      <FontAwesomeIcon className="h-12 w-12" icon={icon} />
      <h6 className="font-graphikBold text-xl">{title}</h6>
      <p className="font-graphikMedium text-sm">{description}</p>
    </div>
  );
};
