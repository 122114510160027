import { useAutoAnimate } from '@formkit/auto-animate/react';
import { ProductKind } from '../../../api/product/types';
import { AlertCount } from '../../../gql/graphql';
import { DateRangeValue } from '../../CalenderRangePicker/CalendarRangePicker';
import CardComingSoon from './components/CardComingSoon';
import CardCoverageStrengths from './components/CardCoverageStrength';
import CardHeader from './components/CardHeader';
import { CardStatsLoader } from './components/CardStats';
export interface ProductCardProps {
  kind: ProductKind;
  dateRange?: DateRangeValue | null | undefined;
  inCurrentApplianceFilter?: boolean;
  alertsCount?: AlertCount;
  connected?: boolean;
  comingSoon?: boolean;
  name: string;
}

const ProductCard = ({
  kind,
  dateRange,
  alertsCount,
  connected,
  comingSoon,
  name,
  // rest for data-testid
  ...rest
}: ProductCardProps) => {
  if (
    !dateRange?.from ||
    !dateRange?.to ||
    Number.isNaN(dateRange?.from.getTime()) ||
    Number.isNaN(dateRange?.to.getTime())
  ) {
    return null;
  }

  const [parent] = useAutoAnimate();

  return (
    <div
      ref={parent}
      data-testid="product-card"
      data-test-kind={kind}
      data-test-connected={connected}
      data-test-coming-soon={comingSoon}
      className=" my-4 divide-y-2 divide-tw-light-bg rounded-[0.761rem] border-2 border-tw-light-bg bg-tw-dark-shade shadow-dark-bottom-20 dark:divide-tw-dark-shade-dark dark:border-tw-dark-shade-dark dark:bg-tw-light-shade-dark"
      {...rest}
    >
      <CardHeader
        kind={kind}
        alerts={alertsCount}
        connected={connected}
        comingSoon={comingSoon}
        name={name}
      />
      <CardCoverageStrengths
        disabled={comingSoon || !connected}
        productKind={kind}
      />
      {comingSoon || !connected ? (
        <CardComingSoon kind={kind} comingSoon={comingSoon} name={name} />
      ) : (
        <CardStatsLoader productKind={kind} />
      )}
    </div>
  );
};

export default ProductCard;
