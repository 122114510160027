import * as d3 from 'd3';
import { useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { isEmpty } from 'ramda';
import { ProductKind } from '../../../../api/product/types';
import { GetAvailablePublicProductsQuery } from '../../../../gql/graphql';
import { ExtendedInstalledProduct } from '../../../../pages/dashboard/hooks/useProducts';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { updateProductsFilterProducts } from '../../../../redux/products/filterSlice';
import { formatNumber } from '../../../../utils/format';
import { useProductsAndCategoriesByFilter } from '../../../../utils/products';
import Spinner from '../../../Elements/Spinner/Spinner';
import { ProductAlertCount } from '../../../ProductAlertCount';
import {
  Severity,
  colorBySeverity,
  getBlankPathBySeverity,
  getLogo,
  getMaxSeverityAlertCount,
  getNotActivatedLogo,
  isInstalledApp,
  totalAlertsCount,
} from './utils';

//This value is used for logo placement (larger number further from center)
const DISTANCE_FROM_CENTER = 70;

const defaultAlertsCount = {
  critical: 0,
  error: 0,
  standard: 0,
};

// Build the shapes
const arcPathGenerator = d3.arc();

interface RoseChartProps {
  installedProducts: ExtendedInstalledProduct[];
  missingProducts: GetAvailablePublicProductsQuery['products'];
  loading?: boolean;
  skipRefetch?: boolean;
}

export const RoseChart = ({
  installedProducts,
  missingProducts,
  loading,
  skipRefetch,
}: RoseChartProps) => {
  const width = 200; //max width of the chart
  const height = 200; //max height of the chart
  const innerRadius = 44; //This controls how big the center circle is
  const outerRadius = Math.min(width, height) / 2; //Total width/height the graph can be

  const isInstalled = isInstalledApp(installedProducts);

  const { alertCountByProductAndSeverity } = useAppSelector(
    (state) => state.products.products,
  );

  const loadingAlertCount = useMemo(
    () =>
      Object.values(alertCountByProductAndSeverity).some(
        (item) => item?.loading,
      ),
    [alertCountByProductAndSeverity],
  );

  const installedAppsWithAlertCount = useMemo(() => {
    return installedProducts.map((item) => {
      const alertsCount =
        alertCountByProductAndSeverity[item.kind as ProductKind];

      return {
        ...item,
        loading: alertsCount?.loading,
        alertsCount: alertsCount
          ? {
              critical: alertsCount.data?.CRITICAL.totalItems || 0,
              error: alertsCount.data?.ERROR.totalItems || 0,
              standard: alertsCount.data?.STANDARD.totalItems || 0,
            }
          : {
              critical: 0,
              error: 0,
              standard: 0,
            },
      };
    });
  }, [installedProducts, alertCountByProductAndSeverity]);

  const allAppsUnsorted = useMemo(() => {
    return [
      ...installedAppsWithAlertCount,
      ...missingProducts.map((p) => ({ ...p, inCurrentApplianceFilter: true })),
    ].map((item) => ({
      loading: false,
      alertsCount: null,
      ...item,
    }));
  }, [missingProducts, installedAppsWithAlertCount]);

  const allApps = useMemo(
    () =>
      allAppsUnsorted
        .sort(
          (
            { inCurrentApplianceFilter: a = false },
            { inCurrentApplianceFilter: b = false },
          ) => Number(b) - Number(a),
        )
        .sort((a, b) => {
          if (isInstalled(a) && isInstalled(b)) {
            const aCount = a.alertsCount;
            const bCount = b.alertsCount;

            return (
              bCount.critical - aCount.critical ||
              bCount.error - aCount.error ||
              bCount.standard - aCount.standard
            );
          } else if (isInstalled(a) && !isInstalled(b)) {
            return -1;
          } else if (isInstalled(b) && !isInstalled(a)) {
            return 1;
          } else {
            return 0;
          }
        }),
    [allAppsUnsorted, isInstalled],
  );

  const maxAlerts = useMemo(
    () =>
      installedAppsWithAlertCount.reduce((acc, curr) => {
        if (getMaxSeverityAlertCount(curr.alertsCount) > acc) {
          return getMaxSeverityAlertCount(curr.alertsCount);
        } else {
          return acc;
        }
      }, 0),
    [installedAppsWithAlertCount],
  );

  const { products: selectedProducts } = useProductsAndCategoriesByFilter();

  const theme = useAppSelector((state) => state.theme.value);
  const dispatch = useAppDispatch();

  const selectProduct = (productKind: ProductKind) => {
    if (selectedProducts.includes(productKind)) {
      const newProds = selectedProducts.filter((a) => a !== productKind);
      dispatch(updateProductsFilterProducts([...newProds]));
    } else {
      dispatch(
        updateProductsFilterProducts([...selectedProducts, productKind]),
      );
    }
  };

  const totalAlerts = useMemo(
    () =>
      installedAppsWithAlertCount.reduce((acc, curr) => {
        const isSelected =
          isEmpty(selectedProducts) ||
          selectedProducts.includes(curr.kind as ProductKind);
        return acc + (isSelected ? totalAlertsCount(curr) : 0) || 0;
      }, 0),
    [installedAppsWithAlertCount, selectedProducts],
  );

  const allAppsNames = allApps.map((d) => {
    if (isInstalled(d)) {
      return d.kind;
    } else {
      return d.name;
    }
  });
  const xScale = useMemo(() => {
    return d3
      .scaleBand()
      .domain(allAppsNames)
      .range([0, 2 * Math.PI]);
  }, [allAppsNames]);

  const yScale = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [min, max] = d3.extent(
      installedAppsWithAlertCount.map((d) => {
        return getMaxSeverityAlertCount(d.alertsCount);
      }),
    );
    return d3
      .scaleRadial()
      .domain([0, max || 12])
      .range([innerRadius, outerRadius]);
  }, [installedAppsWithAlertCount, outerRadius]);

  if (!installedProducts) {
    return null;
  }

  const allAppsWithSeverity = allApps.map((product) => {
    if (isInstalled(product)) {
      const { critical, error, standard } = {
        ...defaultAlertsCount,
        ...('alertsCount' in product && {
          critical: product.alertsCount.critical,
          error: product.alertsCount.error,
          standard: product.alertsCount.standard,
        }),
      };
      const severity: Severity =
        (critical && 'critical') ||
        (error && 'error') ||
        (standard && 'standard') ||
        undefined;

      return {
        name: product.kind,
        kind: product.kind as ProductKind,
        product,
        totalAlertCount:
          'alertsCount' in product ? totalAlertsCount(product) : 0,
        maxSeverityAlertCount:
          'alertsCount' in product
            ? getMaxSeverityAlertCount(product.alertsCount)
            : 0,
        severity,
        installed: true,
        inCurrentApplianceFilter: product.inCurrentApplianceFilter,
      };
    } else {
      return {
        name: product.name,
        kind: product.kind,
        product: product,
        totalAlertCount: 0,
        maxSeverityAlertCount: 0,
        severity: undefined,
        installed: false,
        inCurrentApplianceFilter: true,
      };
    }
  });

  const minYScale = yScale(maxAlerts ? maxAlerts / 6 : 4);

  const statusWedges = allAppsWithSeverity.map((group) => {
    const { maxSeverityAlertCount = 0 } = group;
    const angle = xScale(group.name);
    const bandWidth = xScale.bandwidth();

    //If the Angle or Bandwidth come back undefined, it will make a blank path for the svg
    if (angle === undefined || bandWidth === undefined) {
      return getBlankPathBySeverity(group.severity);
    }

    const appOuterRadius = Math.max(
      yScale(
        group.installed
          ? maxSeverityAlertCount === 0
            ? maxAlerts / 3
            : maxSeverityAlertCount
          : maxAlerts / 6,
      ),
      minYScale,
    );

    //these determine the location for the svg logo of the product

    //Takes the starting angle and the width of the wedge to determine where the center angle is
    const logoAngle = angle + bandWidth / 2;
    //Finally these 2 determine the X and Y of the Logo
    const logoXCoord = Math.floor(
      0 + DISTANCE_FROM_CENTER * Math.sin(logoAngle) - 14,
    );
    const logoYCoord = Math.floor(
      0 - DISTANCE_FROM_CENTER * Math.cos(logoAngle) - 14,
    );

    const disabled = Boolean(
      selectedProducts.length &&
        !selectedProducts.includes(group.kind as ProductKind),
    );

    return (
      <g
        data-tooltip-id={'rosechart-product-tooltip'}
        data-test-value={group.kind}
        data-tooltip-content={group.kind}
        onClick={() =>
          group.inCurrentApplianceFilter &&
          selectProduct(group.kind as ProductKind)
        }
        className={classNames(
          'transition',
          group.inCurrentApplianceFilter && 'cursor-pointer',
          disabled && 'opacity-10 hover:opacity-50',
          selectedProducts.length &&
            selectedProducts.includes(group.kind as ProductKind) &&
            'hover:opacity-75',
          'focus:outline-none',
        )}
        data-testid={`rose-chart-product-button-${group.kind}`}
        aria-disabled={disabled}
        key={group.kind}
      >
        <AnimatedArcPath
          innerRadius={innerRadius}
          outerRadius={appOuterRadius}
          startAngle={angle}
          endAngle={angle + bandWidth}
          opacity={1}
          className={classNames(
            'transition duration-300',
            group.installed && group.product.loading && 'animate-pulse',
          )}
          fill={
            group.installed &&
            group.inCurrentApplianceFilter &&
            !group.product.loading
              ? colorBySeverity(group.severity)
              : theme === 'light'
              ? '#DCDCDC'
              : '#131517'
          }
          fillOpacity={1}
          rx={1}
          stroke={theme === 'light' ? '#ffffff' : '#292B2F'}
          strokeWidth={2}
        />
        <g transform={`translate(${logoXCoord}, ${logoYCoord})`}>
          {isInstalled(group.product)
            ? getLogo(group.kind as ProductKind, group.severity)
            : getNotActivatedLogo(group.kind)}
        </g>
      </g>
    );
  });

  return (
    <div
      data-testid="rose-chart"
      id="rose-chart"
      className="relative flex min-h-full min-w-full flex-row justify-center"
    >
      <div
        className={`absolute z-0 flex min-h-full min-w-full items-center justify-center`}
      >
        {loading ? (
          <Spinner />
        ) : (
          <TotalAlertsCount
            totalAlertsCount={totalAlerts}
            loading={loadingAlertCount}
          />
        )}
      </div>
      <svg className="z-0" width={width} height={height}>
        <g transform={'translate(' + width / 2 + ',' + height / 2 + ')'}>
          {statusWedges}
        </g>
      </svg>
      {/* Force load product alert count */}
      {!skipRefetch && (
        <div className="hidden">
          {installedProducts.map((p) => (
            <ProductAlertCount key={p.id} kind={p.kind} />
          ))}
        </div>
      )}
    </div>
  );
};

export const AnimatedArcPath = ({
  innerRadius,
  outerRadius: outerRadiusProp,
  startAngle,
  endAngle,
  ...props
}: {
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
  endAngle: number;
} & React.SVGProps<SVGPathElement>) => {
  const [outerRadius, setOuterRadius] = useState(outerRadiusProp);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (outerRadiusProp !== outerRadius) {
      setAnimate(true);
    }
  }, [outerRadiusProp, outerRadius]);

  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        const nextRadius = outerRadius + (outerRadiusProp - outerRadius) / 1.5;
        if (Math.abs(nextRadius - outerRadiusProp) < 1) {
          setOuterRadius(outerRadiusProp);
          setAnimate(false);
        } else {
          setOuterRadius(nextRadius);
        }
      }, 50);
      return () => clearTimeout(timer);
    } else {
      return () => null;
    }
  }, [animate, outerRadius]);

  const path = useMemo(
    () =>
      arcPathGenerator({
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
      }),
    [innerRadius, outerRadius, startAngle, endAngle],
  );

  return <path d={path || ''} {...props} />;
};

const MAX_ALERT_COUNT = 999e12; // Max alerts to show in the center of the rose chart (999T)

export const TotalAlertsCount = ({
  totalAlertsCount,
  loading,
}: {
  totalAlertsCount: number;
  loading?: boolean;
}) => {
  const alertsCount =
    totalAlertsCount > MAX_ALERT_COUNT ? MAX_ALERT_COUNT : totalAlertsCount;

  const formattedAlertsCount = formatNumber(alertsCount);

  const showPlus = totalAlertsCount > MAX_ALERT_COUNT;
  const numberOfDigits = formattedAlertsCount.length + (showPlus ? 1 : 0);

  return (
    <p
      className={classNames(
        'relative flex flex-row items-center font-bold text-tw-main-text dark:text-tw-dark-shade',
        numberOfDigits > 3 ? 'text-3xl' : 'text-4xl',
      )}
      data-testid="rose-chart-count"
    >
      {alertsCount > 0 && formattedAlertsCount}
      {showPlus && <span className="-m-1 text-2xl">+</span>}
      {loading && (
        <Spinner className="absolute left-1/2 top-1/2 -z-10 -translate-x-1/2 -translate-y-1/2 transform" />
      )}
    </p>
  );
};

export const RoseChartWithReduxData = (props: RoseChartProps) => {
  const { installed, missing, loading } = useAppSelector(
    (state) => state.products.products,
  );

  return (
    <RoseChart
      {...props}
      installedProducts={installed}
      missingProducts={missing}
      loading={loading}
    />
  );
};
