import * as Sentry from '@sentry/react';
import { useFeature } from 'featurehub-react-sdk';
import { useCallback, useEffect, useState } from 'react';
import { FeatureFlag } from '../featureHub/constants';
import { maskEventUser } from './utils';

export const sentryInit = () => {
  const SENTRY_DSN = window.env.REACT_APP_SENTRY_DSN;
  SENTRY_DSN &&
    SENTRY_DSN !== '__REACT_APP_SENTRY_DSN__' &&
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: window.env.REACT_APP_INSTANCE || 'local',
      tracePropagationTargets: ['localhost', /^\//],
      // Called for message and error events
      beforeSend: maskEventUser,
      // beforeBreadcrumb: maskBreadcrumbURL,
      // beforeSendTransaction: maskEventUrl,
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      integrations: [
        new Sentry.Replay({
          // Mask attributes with sensitive data
          maskAttributes: [
            'data-test-email',
            'data-test-code',
            'data-test-value',
          ],
        }),
        // Add to mask navigate urls
        // browserTracingWithNavigateUrlMask
      ],
      // Performance Monitoring
      tracesSampleRate:
        Number(window.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) === 0
          ? 0
          : Number(window.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) || 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate:
        Number(window.env.REACT_APP_SENTRY_SESSION_SAMPLE_RATE) === 0
          ? 0
          : Number(window.env.REACT_APP_SENTRY_SESSION_SAMPLE_RATE) || 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate:
        Number(window.env.REACT_APP_SENTRY_ON_ERROR_SAMPLE_RATE) === 0
          ? 0
          : Number(window.env.REACT_APP_SENTRY_ON_ERROR_SAMPLE_RATE) || 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
};

/**
 * Ensures init only occurs one time
 */
export const useSentryInit = () => {
  const sentryEnabled = useFeature(FeatureFlag.SENTRY);
  const [initFunc, setInitFunc] = useState<(() => void) | null>(
    () => sentryInit,
  );
  const init = useCallback(() => {
    initFunc?.();
    initFunc && setInitFunc(null);
  }, []);
  // Sentry init config
  useEffect(() => {
    sentryEnabled && init();
  }, [sentryEnabled]);
};
