import React from 'react';
import { Badge } from '../common/Badge';

export const LabelWithCount = ({
  label,
  count,
}: {
  label: React.ReactNode;
  count?: number;
}) => {
  return (
    <div
      data-testid="label"
      className="flex items-center justify-between gap-2"
    >
      <span className="py-1">{label}</span>
      {count ? (
        <Badge
          data-testid="count"
          data-test-value={count}
          variant="primaryCount"
        >
          {count}
        </Badge>
      ) : null}
    </div>
  );
};
