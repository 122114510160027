import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppSelector } from '../../redux/hooks';

const getToastRoot = () => {
  const toastRoot = document.getElementById('toast-root');
  if (toastRoot) return toastRoot;
  const newEl = document.createElement('div');
  newEl.setAttribute('id', 'toast-root');
  document.body.appendChild(newEl);
  return newEl;
};

export const ToastifyContainer = () => {
  const theme = useAppSelector((state) => state.theme.value);

  const contextClass = {
    success: 'bg-green-200 dark:bg-[#25282E]',
    error: 'bg-[#FFE2E5] dark:bg-[#25282E]',
    info: 'bg-white dark:bg-[#25282E]',
    warning: 'bg-yellow-200 dark:bg-[#25282E]',
    default: 'bg-white dark:bg-[#25282E]',
  };

  return ReactDOM.createPortal(
    <ToastContainer
      toastClassName={(props) =>
        classNames(
          'test-toast-container',
          'dark:shadow-md text-tw-main-text dark:text-tw-main-text-dark border dark:border-white/10',
          props?.type && `test-type-${props?.type}`,
          contextClass[props?.type || 'default'],
          'relative flex p-2 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer font-medium m-3',
        )
      }
      theme={theme}
      position="bottom-center"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop
      closeOnClick
      pauseOnFocusLoss
      pauseOnHover
      limit={5}
    />,
    getToastRoot(),
  );
};
