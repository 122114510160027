import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  GET_INVENTORY,
  GET_INVENTORY_APPLIANCE,
  UPDATE_APPLIANCE,
} from '../../api/inventory/queries';
import { REMOVE_APPLIANCE_LOCATION } from '../../api/location/queries';
import {
  DeleteApplianceLocationMutation,
  DeleteApplianceLocationMutationVariables,
  GetInventoryApplianceQuery,
  GetInventoryApplianceQueryVariables,
  UpdateApplianceMutation,
  UpdateApplianceMutationVariables,
} from '../../gql/graphql';
import { SimpleButton } from '../Buttons/SimpleButton';
import { SpinnerWithText } from '../Elements/Spinner/SpinnerWithText';
import { LayoutAreaId } from '../Layout/types';
import { Message } from '../common/Message';
import Modal from '../common/Modal/Modal';
import EditApplianceModal, {
  EditApplianceModalProps,
} from './EditApplianceModal';

export interface EditApplianceModalWithQueriesProps {
  id?: string;
  onClose?: () => void;
}

const EditApplianceModalWithQueries = ({
  id,
  onClose,
}: EditApplianceModalWithQueriesProps) => {
  const getAppliance = useQuery<
    GetInventoryApplianceQuery,
    GetInventoryApplianceQueryVariables
  >(GET_INVENTORY_APPLIANCE, {
    variables: {
      applianceId: id || '',
    },
    fetchPolicy: 'network-only',
    skip: !id,
  });

  // Prevents data from hiding on close transition
  const [getApplianceQuery, setGetApplianceQuery] = useState(getAppliance);
  useEffect(() => {
    id && setGetApplianceQuery(getAppliance);
  }, [getAppliance]);
  const appliance = useMemo(
    () => getApplianceQuery?.data?.appliance,
    [getApplianceQuery],
  );

  const [updateAppliance, updateApplianceQuery] = useMutation<
    UpdateApplianceMutation,
    UpdateApplianceMutationVariables
  >(UPDATE_APPLIANCE, {
    refetchQueries: [GET_INVENTORY_APPLIANCE, GET_INVENTORY],
    onCompleted: (data) => {
      onClose?.();
      toast.success(
        `${
          data.updateAppliance.description || data.updateAppliance.id
        } succesfully updated`,
      );
    },
  });

  const [removeApplianceLocation, removeApplianceLocationQuery] = useMutation<
    DeleteApplianceLocationMutation,
    DeleteApplianceLocationMutationVariables
  >(REMOVE_APPLIANCE_LOCATION, {
    refetchQueries: [GET_INVENTORY_APPLIANCE, GET_INVENTORY],
  });

  const handleUpdateAppliance: EditApplianceModalProps['onUpdate'] = (
    input,
  ) => {
    updateAppliance({
      variables: {
        input,
      },
    });

    if (appliance?.location && !input.location) {
      removeApplianceLocation({
        variables: {
          id: appliance.id,
        },
      });
    }
  };

  const [applianceToDelete, setApplianceToDelete] = useState<string | null>(
    null,
  );

  const handleClose = () => {
    setApplianceToDelete(null);
    onClose?.();
  };

  return (
    <Modal
      data-testid="edit-appliance-modal"
      isOpen={Boolean(id)}
      onClose={handleClose}
      title={`${applianceToDelete ? 'Delete' : 'Edit'} ${
        appliance?.description || 'Appliance'
      }`}
      layoutArea={LayoutAreaId.CONTAINER}
    >
      <div
        data-testid="edit-appliance-modal-content"
        data-test-value={appliance?.applianceId}
        className="flex w-96 flex-col items-start"
      >
        {getApplianceQuery.loading ? (
          <SpinnerWithText
            data-testid="edit-appliance-loading"
            text="Loading appliance"
            className="m-auto"
          />
        ) : getApplianceQuery.error ? (
          <>
            <Message data-testid="edit-appliance-error-message" type="error">
              {getApplianceQuery.error.message || 'Unexpected error'}
            </Message>
            <div className="flex w-96 flex-col items-end">
              <SimpleButton
                data-testid="edit-appliance-close"
                onClick={handleClose}
                className="ml-auto"
              >
                Close
              </SimpleButton>
            </div>
          </>
        ) : (
          <EditApplianceModal
            appliance={appliance}
            onUpdate={handleUpdateAppliance}
            onDeleteClick={(appliance) =>
              setApplianceToDelete(appliance?.id || null)
            }
            onDeleteCancel={() => setApplianceToDelete(null)}
            onDeleted={() => {
              setApplianceToDelete(null);
              onClose?.();
            }}
            loading={
              updateApplianceQuery.loading ||
              removeApplianceLocationQuery.loading
            }
            error={updateApplianceQuery.error?.message}
          />
        )}
      </div>
    </Modal>
  );
};

export default EditApplianceModalWithQueries;
