import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { CoverageIcon } from '../../../components/DashCards/ProductCard/components/CardCoverageStrength';
import { ProductIcon } from '../../../components/Elements/Icons/ProductIcons/ProductIcon';
import { TrialModalContent } from '../../../components/Elements/Modal/TrialModalContent';
import { Badge } from '../../../components/common/Badge';
import { ModalTrigger } from '../../../components/common/Modal';
import {
  PickerOption,
  SelectWithoutBorder,
} from '../../../components/form/fields';
import {
  Category,
  GetAvailablePublicProductsQuery,
} from '../../../gql/graphql';
import { useAppSelector } from '../../../redux/hooks';
import { isProductKind } from '../../../utils/products';

export const ExploreApps = () => {
  const { available: allProducts } = useAppSelector(
    (state) => state.products.products,
  );

  const [filteredProducts, setFilteredProducts] =
    useState<GetAvailablePublicProductsQuery['products']>(allProducts);

  const [selectedFilter, setSelectedFilter] = useState<
    PickerOption | undefined
  >(undefined);

  useEffect(() => {
    if (selectedFilter) {
      const filtered = allProducts.filter((app) => {
        return app.categories?.includes(selectedFilter.value as Category);
      });
      setFilteredProducts(() =>
        [...filtered].sort(
          (a, b) => (a.comingSoon ? 1 : 0) - (b.comingSoon ? 1 : 0),
        ),
      );
    } else {
      setFilteredProducts(() =>
        [...allProducts].sort(
          (a, b) => (a.comingSoon ? 1 : 0) - (b.comingSoon ? 1 : 0),
        ),
      );
    }
  }, [allProducts, selectedFilter]);

  const handleSelectFilter = (option: PickerOption | undefined) => {
    setSelectedFilter(option);
  };

  const { t } = useTranslation('coverage');
  const categoryFilterOptions = useMemo(
    () =>
      Object.values(Category)
        .map((category) => {
          const capabilities = allProducts.filter((product) =>
            product.categories.some((cat) => cat === category),
          ).length;
          return {
            value: category,
            disabled: capabilities <= 0,
            label: (
              <div
                data-testid="category-filter-option"
                className={classNames(
                  'flex items-center font-graphikRegular text-sm',
                )}
              >
                <CoverageIcon
                  iconName={category}
                  className="fill-transparent"
                />
                {t(`coverage.category.${category}.name`, category)}
              </div>
            ),
          };
        })
        .filter((item) => !item.disabled),
    [t, allProducts],
  );

  return (
    <div className="flex min-h-[30rem] flex-col overflow-auto font-graphikRegular">
      <div className="flex max-w-[600px] items-center justify-between">
        <div className="w-96 font-graphikSemi text-[1.25rem] leading-[1.625rem] text-tw-main-text dark:text-tw-dark-shade">
          Explore Capabilities
        </div>
        <SelectWithoutBorder
          customClassNames={{
            dropDownButton: 'bg-white',
          }}
          placeholder="All Capabilities"
          clearable
          options={categoryFilterOptions}
          onChange={handleSelectFilter}
        />
      </div>
      <Capabilities filteredProducts={filteredProducts} />
    </div>
  );
};

export const Capabilities = ({
  filteredProducts,
  customClassNames,
  showTryButton,
  showLearnMoreButton,
  showWhy,
  ...rest
}: {
  filteredProducts: GetAvailablePublicProductsQuery['products'];
  customClassNames?: {
    container?: string;
    app?: string;
  };
  showTryButton?: boolean;
  showLearnMoreButton?: boolean;
  showWhy?: boolean;
}) => {
  return (
    <div
      className={twMerge(
        'scrollbar mt-6 flex h-full w-full flex-col gap-5 overflow-auto',
        customClassNames?.container,
      )}
      {...rest}
    >
      {filteredProducts.map((app) => {
        return (
          <Capability
            key={app.name}
            product={app}
            className={customClassNames?.app}
            showTryButton={showTryButton}
            showLearnMoreButton={showLearnMoreButton}
            showWhy={showWhy}
          />
        );
      })}
    </div>
  );
};

interface CapabilityProps {
  product?: GetAvailablePublicProductsQuery['products'][0];
  className?: string;
  showTryButton?: boolean;
  showLearnMoreButton?: boolean;
  showWhy?: boolean;
}
export const Capability = ({
  product,
  className,
  showTryButton = true,
  showLearnMoreButton = true,
  showWhy = true,
}: CapabilityProps) => {
  const { name, kind } = product || { name: '', kind: '' };
  if (!isProductKind(kind))
    return (
      <>
        {name}-{kind}
      </>
    );

  const { t } = useTranslation('productInfo');

  const hasWhy = useMemo(() => !!t(`${kind}.why`, ''), [t]);

  return (
    <div
      className={twMerge(
        'flex w-full flex-col rounded-lg bg-white text-tw-main-text shadow-sm dark:border-[0.063rem] dark:border-[#292B2F] dark:bg-tw-strong-shade-dark dark:text-tw-dark-shade',
        'w-[600px]',
        className,
      )}
    >
      <div
        className={classNames(
          'flex items-center justify-between gap-4 px-3 py-2',
          showWhy &&
            'border-b-[0.063rem] border-b-gray-100 dark:border-b-gray-700',
        )}
      >
        <div className="flex items-center gap-4">
          <div className="text-tw-main-fill-color">
            <ProductIcon productKind={kind} className="h-[48px] w-[48px]" />
          </div>
          <div>
            <p className="font-graphikSemi text-[1.25rem] leading-[1.375rem]">
              {t(`${kind}.name`, name)}
            </p>
            <p className="mt-1 font-graphikRegular text-[0.875rem] leading-[0.938rem] text-[#788297]">
              {t(`${kind}.description`, '')}
            </p>
          </div>
        </div>
        {showTryButton &&
          (product?.comingSoon ? (
            <Badge className="flex w-36 shrink-0 justify-center whitespace-nowrap rounded-lg px-3 pb-2.5 pt-3 text-center font-graphikSemi">
              Coming soon
            </Badge>
          ) : (
            <ModalTrigger
              modalTitle={`Try ${t(`${kind}.name`, name)} for free`}
              modalContent={(close) => (
                <TrialModalContent
                  product={t(`${kind}.name`, name)}
                  onSubmit={close}
                />
              )}
              layoutArea={null}
              style={{
                boxShadow: '0px 1.4065769910812378px 0px 0px #00000080',
              }}
              className="flex w-36 shrink-0 justify-center rounded-lg bg-tw-main-color px-3 pb-2.5 pt-3 text-center font-graphikSemi text-[1rem] leading-[1.1rem] text-white hover:bg-blue-700"
            >
              <span className="whitespace-nowrap">Try for free</span>
            </ModalTrigger>
          ))}
      </div>
      {showWhy && (
        <div className="flex items-center justify-between gap-4 p-3">
          <div className="flex max-w-md flex-col">
            {hasWhy && (
              <>
                <p className="font-graphikMedium text-[1rem] leading-[1.375rem]">
                  Why use {t(`${kind}.name`, name)}?
                </p>
                <p className="mt-2 max-w-md font-graphikRegular text-[12px] leading-[1rem] text-[#788297]">
                  {t(`${kind}.why`, '')}
                </p>
              </>
            )}
          </div>
          {showLearnMoreButton && (
            <div className="flex w-36">
              <Link
                to={`learn-more/${kind}`}
                className={classNames(
                  'w-36 rounded-lg border bg-transparent px-3 pb-2.5 pt-3 text-center font-graphikSemi text-[1rem]  leading-[1.1rem]  text-tw-light-text',
                  'border-tw-strong-shade dark:border-tw-strong-description',
                  'text-tw-light-text dark:text-tw-dark-shade',
                  'hover:bg-black/5  dark:hover:bg-white/5',
                )}
              >
                Learn more
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
