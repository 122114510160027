export enum FeatureFlag {
  PROD_SWITCH = 'PROD_SWITCH',
  CONNECT_APP = 'CONNECT_APP',

  SENTRY = 'SENTRY',
  UMAMI = 'UMAMI',
  HOTJAR = 'HOTJAR',

  INVENTORY = 'INVENTORY',
  INVENTORY_MANAGED_DEVICES = 'INVENTORY_MANAGED_DEVICES',
  INVENTORY_APPLIANCE_SERVERS = 'INVENTORY_APPLIANCE_SERVERS',
  INVENTORY_APPLIANCE_SERVER_COMPONENTS = 'INVENTORY_APPLIANCE_SERVER_COMPONENTS',
  APPLIANCE_ONLINE_STATUS = 'APPLIANCE_ONLINE_STATUS',

  COVERAGE_TOOLTIP_FEATURE = 'COVERAGE_TOOLTIP_FEATURE',
  USER_SETTINGS = 'USER_SETTINGS',
  DYNAMIC_SETTINGS = 'DYNAMIC_SETTINGS',
  DYNAMIC_SETTINGS_DEMO = 'DYNAMIC_SETTINGS_DEMO',

  ALERT_WORKFLOW = 'ALERT_WORKFLOW',
  ALERT_WORKFLOW_LOGS = 'ALERT_WORKFLOW_LOGS',

  LOCALIZATION = 'LOCALIZATION',

  // Shows the licenses information for inventory appliances installed products
  INVENTORY_LICENSES = 'INVENTORY_LICENSES',
}
