import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { Size } from '..';
import { getRingColorAsText } from '../utils';

export const RadioCheck = ({
  checked,
  disabled,
  size = 'medium',
}: {
  checked?: boolean;
  disabled?: boolean;
  size?: Size;
}) => {
  return (
    <div
      className={classNames(
        'block overflow-visible',
        {
          'h-2 w-2': size === 'small',
          'h-3 w-3': size === 'medium',
          'h-4 w-4': size === 'large',
        },
        'shrink-0 bg-white dark:bg-tw-dark-shade-dark',
      )}
    >
      <div
        className={classNames(
          'h-full w-full rounded-full',
          'flex items-center justify-center',
          getRingColorAsText({ checked, disabled, size }),
        )}
      >
        {checked && (
          <div
            className={twMerge(
              classNames(
                'h-full w-full rounded-full',
                'border-2 border-white dark:border-tw-dark-shade-dark',
                'animate-scaleIn',
                {
                  border: size === 'small',
                  'border-2': size === 'medium',
                  'border-3': size === 'large',
                },
                disabled
                  ? 'bg-grey-500'
                  : 'bg-tw-main-color dark:bg-tw-main-color-dark',
              ),
            )}
          ></div>
        )}
      </div>
    </div>
  );
};
