import { equals } from 'ramda';
import { useEffect, useRef, useState } from 'react';
import {
  GetInventoryQuery,
  GetManagedDevicesQuery,
} from '../../../gql/graphql';
import { IFilterButton } from '../types';
import InventoryFilterButton from './InventoryFilterButton';
import InventoryFilterButtonList from './InventoryFilterList';

export type IDevice = GetManagedDevicesQuery['managedDevices']['0'];
export type IAppliance = GetInventoryQuery['appliances'][0];

const SingleButton = ({ product }: { product: IFilterButton }) => {
  return (
    <div className="relative mb-2 flex min-h-[75px]">
      <div
        data-testid="appliance-filter-options"
        className="flex h-full w-full flex-row gap-2"
      >
        <InventoryFilterButton
          data-testid="appliance-filter-single"
          key={product.name}
          product={product}
          selectedProducts={[]}
        />
      </div>
    </div>
  );
};

const MultipleButtons = ({
  products,
  selectedProducts,
  updateSelected,
}: {
  products: Array<IFilterButton> | undefined;
  selectedProducts: Array<IFilterButton> | null;
  updateSelected: (product: IFilterButton) => void;
}) => {
  return (
    <div className="relative mb-2 flex min-h-[75px]">
      <div
        data-testid="appliance-filter-options"
        className="flex h-full w-full flex-row gap-2"
      >
        {products?.map((product) => (
          <InventoryFilterButton
            key={product.name}
            product={product}
            selectedProducts={selectedProducts}
            updateSelected={updateSelected}
          />
        ))}
      </div>
    </div>
  );
};

interface IProps {
  products: Array<IFilterButton> | undefined;
  setSelectedProducts: (str: Array<IFilterButton> | null) => void;
  selectedProducts: Array<IFilterButton> | null;
}

const InventoryFilterButtonHolder = ({
  products,
  setSelectedProducts,
  selectedProducts,
}: IProps) => {
  const myRef = useRef<HTMLDivElement | null>(null);
  const [overMaxAppliances, setOverMaxAppliances] = useState(false);

  const updateSelectedAppliance = (prod: IFilterButton) => {
    if (selectedProducts?.length) {
      if (selectedProducts.includes(prod)) {
        const updated = selectedProducts.filter((product) => prod !== product);
        setSelectedProducts(updated);
      } else {
        const newValue = [...selectedProducts, prod];
        const newValueOrEmpty = equals(
          [...newValue].sort(),
          [...selectedProducts].sort(),
        )
          ? null
          : newValue;

        // Unselect when all options are selected
        const selected =
          newValueOrEmpty?.length === products?.length ? null : newValueOrEmpty;

        setSelectedProducts(selected);
      }
    } else {
      setSelectedProducts([prod]);
    }
  };

  useEffect(() => {
    if (products && selectedProducts?.length) {
      // Filter the current selected ids to only include existing
      const selectedFiltered = selectedProducts?.filter((id) =>
        products?.includes(id),
      );
      selectedFiltered && setSelectedProducts(selectedFiltered);
    }
  }, [products]);

  useEffect(() => {
    if (myRef.current) {
      if (
        myRef.current?.offsetWidth < 1130 &&
        products &&
        products.length > 2
      ) {
        setOverMaxAppliances(true);
      }
    }
  }, [myRef, products]);
  return (
    <div data-testid="appliance-filter" ref={myRef}>
      {products && products.length === 1 ? (
        <SingleButton product={products[0]} />
      ) : products &&
        products.length > 1 &&
        products.length < 4 &&
        !overMaxAppliances ? (
        <MultipleButtons
          products={products}
          selectedProducts={selectedProducts}
          updateSelected={updateSelectedAppliance}
        />
      ) : (
        <InventoryFilterButtonList
          selectedProducts={selectedProducts}
          products={products}
          updateSelected={updateSelectedAppliance}
        />
      )}
    </div>
  );
};

export default InventoryFilterButtonHolder;
