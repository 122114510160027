import {
  faCheckCircle,
  faExclamationCircle,
  faMinusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { MAIN_TOOLTIP_ID } from '../../../../App';

export const WithStatusBadge = ({
  type,
  tooltip,
  children,
  loading,
}: {
  type?: 'success' | 'warning' | 'critical';
  tooltip?: string;
  loading?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <div className="relative">
      {children}
      {type && !loading && (
        <FontAwesomeIcon
          data-tooltip-id={tooltip ? MAIN_TOOLTIP_ID : undefined}
          data-tooltip-content={tooltip}
          className={classNames(
            'absolute -right-1.5 -top-1.5 h-3 w-3 rounded-full bg-white',
            type === 'success' &&
              'text-tw-success-strong dark:text-tw-success-strong-dark',
            type === 'warning' &&
              'text-tw-warning-strong dark:text-tw-warning-strong-dark',
            type === 'critical' &&
              'text-tw-danger-strong dark:text-tw-danger-strong-dark',
          )}
          icon={
            type === 'success'
              ? faCheckCircle
              : type === 'warning'
              ? faExclamationCircle
              : faMinusCircle
          }
        />
      )}
    </div>
  );
};
