import dayjs from 'dayjs';
import { tryCatch } from 'ramda';

enum TimeUnit {
  Millisecond = 'millisecond',
  Second = 'second',
  Minute = 'minute',
  Hour = 'hour',
  Day = 'day',
  Year = 'year',
}
const timeUnits = [
  { label: 'ms', value: 1, unit: TimeUnit.Millisecond },
  { label: 's', value: 1000, unit: TimeUnit.Second },
  { label: 'm', value: 60000, unit: TimeUnit.Minute },
  { label: 'h', value: 3600000, unit: TimeUnit.Hour },
  { label: 'd', value: 86400000, unit: TimeUnit.Day },
  { label: 'y', value: 31536000000, unit: TimeUnit.Year },
];

export const humanReadableMs = (
  milliseconds: number,
  options?: {
    significantDigits?: number;
  },
) => {
  const { significantDigits = 2 } = options || {};

  const isNegative = milliseconds < 0;
  const absoluteMilliseconds = Math.abs(milliseconds);
  // Return in human readable format. 2h 10s
  const timeUnitsToDisplay = timeUnits.filter((u) => {
    return u.value <= absoluteMilliseconds;
  });
  const timeUnitsToDisplayLength = timeUnitsToDisplay.length;
  if (timeUnitsToDisplayLength === 0) {
    return '0ms';
  }

  let mutableMilliseconds = absoluteMilliseconds;
  const defaultResult: {
    value: string;
    unit: string;
  }[] = [];
  return (
    timeUnitsToDisplay
      .reverse()
      // only show the most significant time units
      .slice(0, significantDigits)
      .reduce((acc, timeUnit) => {
        const timeValue = Math.floor(mutableMilliseconds / timeUnit.value);
        if (timeValue === 0) {
          return acc;
        }
        const timeValueString = timeValue.toString();
        const timeUnitString = timeUnit.label;
        mutableMilliseconds -= timeValue * timeUnit.value;
        return [
          ...acc,
          {
            value: timeValueString,
            unit: timeUnitString,
          },
        ];
        // return `${acc} ${timeValueString}${timeUnitString}`;
      }, defaultResult)
      .map(({ value, unit }) => `${value}${unit}`)
      .join(' ')
      .trim()
      // if the value is negative, add a minus sign
      .replace(/^/, isNegative ? '-' : '')
  );
};

export const humanReadableTime = (
  value: number,
  unit: `${TimeUnit}`,
  options?: {
    significantDigits?: number;
  },
): string => {
  const msInTimeUnit = timeUnits.find((u) => u.unit === unit);
  if (!msInTimeUnit) {
    throw new Error(`Invalid time unit ${unit}`);
  }

  const timeValueInMs = value * msInTimeUnit.value;

  return humanReadableMs(timeValueInMs, options);
};

export const MACHINE_READABLE_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DEFAULT_DATE_FORMAT = 'MMMM D, YYYY';
export const SHORT_DATE_TIME_FORMAT = 'YY-MM-DD h:mm';
export const DEFAULT_TIME_FORMAT = 'h:mm A';
export const TIME_FORMAT_WITH_SECONDS = 'h:mm:ss A';
export const DEFAULT_DATE_TIME_FORMAT = `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;

export const formatDate = (
  date: number | string,
  format = DEFAULT_DATE_TIME_FORMAT,
) => dayjs(date).format(format);

/**
 *
 * @param num number from 0 to 1
 * @returns
 * @example
 * formatAsPercent(0.5) // 50%
 * formatAsPercent(0.8) // 80%
 */
export const formatAsPercent = (num: number) => {
  return new Intl.NumberFormat('default', {
    style: 'percent',
    maximumFractionDigits: 0,
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 2,
  }).format(num);
};

export const formatDiffPercent = (num: number) => {
  return new Intl.NumberFormat('default', {
    style: 'percent',
    maximumFractionDigits: 0,
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 2,
    unitDisplay: 'narrow',
    signDisplay: 'always',
  }).format(num / 100);
};

/**
 * @param num number from 0 to 1
 * @returns
 * @example
 * formatAsCeroToTen(0.5) // 5
 * formatAsCeroToTen(0.8) // 8
 * formatAsCeroToTen(0.58) // 5.8
 * */
export const formatAsCeroToTen = (num: number) => {
  return new Intl.NumberFormat('default', {
    style: 'decimal',
    maximumFractionDigits: 0,
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 2,
  }).format(num * 10);
};

/**
 *
 * @param score number from 0 to 1
 * @returns
 */
export const getCoverageColor = (score: number) => {
  if (score < 0.3) return '#e22134';
  if (score < 0.5) return '#f90';
  if (score < 0.7) return '#2a63ef';
  return '#20b782';
};

export enum FileSizeUnit {
  byte = 'byte',
  kilobyte = 'kilobyte',
  megabyte = 'megabyte',
  gigabyte = 'gigabyte',
  terabyte = 'terabyte',
  petabyte = 'petabyte',
}

export const isFileSizeUnit = (unit: string) => {
  return Object.values(FileSizeUnit).includes(unit as FileSizeUnit);
};

export const humanReadableBytes = (
  value: number,
  unit: FileSizeUnit,
): {
  value: number;
  unit: FileSizeUnit;
} => {
  const units = Object.values(FileSizeUnit);
  const unitIndex = units.indexOf(unit);

  if (unitIndex === -1) {
    throw new Error(`Invalid unit ${unit}`);
  }

  if (value < 1000 || unitIndex === units.length - 1) {
    return { value, unit };
  }

  return humanReadableBytes(value / 1000, units[unitIndex + 1]);
};

export const formatNumber = (total: number) =>
  Intl.NumberFormat('en-US', {
    maximumSignificantDigits: 3,
    notation: 'compact',
  }).format(total);

export const humanizeValue = ({
  value: valueProps,
  unit: unitProps,
  locale = 'default',
  formatOptions,
  showCustomUnit = true,
}: {
  value: number;
  unit?: string;
  locale?: string;
  formatOptions?: Intl.NumberFormatOptions;
  showCustomUnit?: boolean;
}) => {
  let value = valueProps;
  let unit = unitProps;

  if (Number.isNaN(value)) return 'No data';

  // Split unit for formats like "megabyte-per-second"
  const [firstUnit, ...restUnit] = unit?.split('-') || [];
  if (firstUnit && isFileSizeUnit(firstUnit)) {
    const { value: roundedValue, unit: roundedUnit } = humanReadableBytes(
      value,
      firstUnit as FileSizeUnit,
    );
    value = roundedValue;
    // Unit and the rest of the split
    unit = roundedUnit + (restUnit.length ? `-${restUnit.join('-')}` : '');
  }

  const isTimeUnit =
    unit && ['millisecond', 'second', 'minute', 'hour'].includes(unit);
  if (isTimeUnit) {
    const timeUnit = firstUnit as TimeUnit;
    return humanReadableTime(value, timeUnit, {
      significantDigits: showCustomUnit ? 2 : 1,
    });
  }

  const isPercent = unit === 'percent';

  const style = isPercent ? 'percent' : undefined;

  const options: Intl.NumberFormatOptions = {
    style: style || 'unit',
    maximumFractionDigits: 2,
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 2,
    unit,
    unitDisplay: 'short',
    notation: value < 10e14 ? 'compact' : 'scientific',
    ...formatOptions,
  };
  const optionsCustomUnit: Intl.NumberFormatOptions = {
    ...options,
    style,
    unit: undefined,
  };

  const customUnit = showCustomUnit && unit ? ` ${unit}` : '';

  return tryCatch(
    () => new Intl.NumberFormat(locale, options).format(value),
    // if unit is not valid, try to format without unit
    () =>
      new Intl.NumberFormat(locale, optionsCustomUnit).format(value) +
      customUnit,
  )();
};

// CASE
export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
}

// MASK
export const INCONPLETE_IPV4_MASK =
  /^(?:\d{1,3}|$)(?:\.|$)(?:\d{1,3}|$)(?:\.|$)(?:\d{1,3}|$)(?:\.|$)(?:\d{1,3}|$)$/;
export const IPV4_REGEX = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
export const isIpv4 = (text?: string) => text?.match(IPV4_REGEX);

export const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
export const isURL = (text?: string) => text?.match(URL_REGEX);
