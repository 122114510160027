import { updateLogin } from '../redux/login/loginSlice';
import { ory } from './config';

export const checkOrySession = async (dispatch: any) =>
  ory.frontend
    .toSession()
    .then(({ data }) => {
      ory.frontend.createBrowserLogoutFlow().then(({ data: tokenData }) => {
        dispatch(
          updateLogin({
            id: null,
            email: null,
            company: null,
            token: null,
            session: data,
            logoutToken: tokenData.logout_token,
            flowFulfilled: true,
          }),
        );
      });
    })
    .catch(() => {
      dispatch(
        updateLogin({
          token: null,
          session: null,
          logoutToken: null,
          flowFulfilled: true,
        }),
      );
    });
