import dayjs from 'dayjs';
import { mergeDeepRight } from 'ramda';
import { ProductKind } from '../../api/product/types';
import { JSONSchema7WithUI } from '../../api/settings/types';
import { IconName } from '../../assets/icons/Icon';
import {
  AlertCount,
  AlertSeverity,
  Appliance,
  ApplianceStatus,
  BatchTypes,
  Category,
  ChannelTypes,
  ChartPolarity,
  Coverage,
  CreateForwardWorkflowInput,
  CreateForwardWorkflowMutation,
  ForwardGroup,
  ForwardWorkflow,
  GetAvailablePublicProductsQuery,
  GetForwardGroupsQuery,
  GetForwardGroupsQueryVariables,
  GetForwardWorkflowQuery,
  HealthStatus,
  InstalledProduct,
  InstalledProductLicense,
  LicenseKind,
  LicensedDeviceLink,
  ManagedDevice,
  ManagedDeviceLicenseState,
  PlatformType,
  Product,
  Server,
  ServerComponent,
  ServerState,
  ServerType,
  SlackChannel,
  SlackChannelType,
  TriggerRuleSources,
} from '../../gql/graphql';
import { randomEnum } from '../../utils/random';
import {
  randomAlertCode,
  randomAlertDescription,
  randomArray,
  randomDate,
  randomId,
  randomInt,
  randomIp,
  randomSentence,
  randomWord,
  s4,
} from './utils';

export const mockedConfigSchema: JSONSchema7WithUI = {
  type: 'object',
  title: 'Demo',
  properties: {
    mainExample: {
      type: 'object',
      title: 'Main example',
      description: 'This is an example of object',
      required: ['title'],
      properties: {
        title: {
          type: 'string',
          title: 'Title',
          description: 'This is an example of string. This is a required field',
        },
        done: {
          type: 'boolean',
          title: 'Done?',
          description: 'This is a boolean type example',
          default: false,
        },
        priority: {
          type: 'string',
          title: 'Priority',
          description: 'This is a string enum example',
          enum: ['Low', 'Medium', 'High'],
        },
        radioGroup: {
          type: 'string',
          title: 'Radio Group',
          description: 'This is a radio group example',
          enum: ['Low', 'Medium', 'High'],
          uiProperties: {
            component: 'radioGroup',
          },
        },
        subtasks: {
          type: 'array',
          title: 'Subtasks',
          description: 'This is a string array example',
          items: {
            type: 'string',
            default: 'A new subtask',
          },
        },
        dueDate: {
          type: 'string',
          format: 'date',
          title: 'Due date',
        },
      },
      uiProperties: {
        iconName: IconName.TeamIcon,
      },
    },
    inputs: {
      type: 'object',
      title: 'Input examples',
      description: 'This is a input examples group',
      uiProperties: {
        iconName: IconName.InventoryIcon,
      },
      properties: {
        strings: {
          type: 'object',
          title: 'Strings',
          description: 'String Inputs',
          properties: {
            stringInput: {
              type: 'string',
              title: 'String input',
              description: 'A basic string input',
            },
            readOnlyStringInput: {
              type: 'string',
              title: 'Read Only String input',
              description: 'A basic string input',
              readOnly: true,
            },
            textAreaInput: {
              type: 'string',
              title: 'Text area input',
              description: 'A text area input',
              uiProperties: {
                component: 'textArea',
              },
            },
            stringInputWithMaxAndMin: {
              type: 'string',
              title: 'String input with max and a min length',
              description:
                'String input with max length of 10 and a min length of 5',
              minLength: 5,
              maxLength: 10,
            },
            stringInputWithPattern: {
              type: 'string',
              title: 'String input with pattern',
              description:
                'String input with pattern ("^(\\([0-9]{3}\\))?[0-9]{3}-[0-9]{4}$")',
              pattern: '^(\\([0-9]{3}\\))?[0-9]{3}-[0-9]{4}$',
              examples: ['555-1212', '(888)555-1212'],
            },
            stringTextArea: {
              type: 'string',
              title: 'Text area',
              description:
                'By default, if a string has a min length greater than 50 it wil be rendered as Text area',
              minLength: 80,
            },
          },
        },
        numbers: {
          type: 'object',
          title: 'Numbers',
          description: 'Number Inputs',
          properties: {
            numberInput: {
              type: 'number',
              title: 'Number input',
              description: 'A basic number input',
            },
            numberInputWithMaxAndMin: {
              type: 'number',
              title: 'Number input with max and a min value',
              description:
                'Number input with max value of 10 and a min value of 5',
              minimum: 5,
              maximum: 10,
            },
            integerInput: {
              type: 'integer',
              title: 'Integer input',
              description:
                'A basic integer input. Try to type a decimal number and you will see the error',
            },
            numberInputMultipleOf: {
              type: 'number',
              title: 'Number input multiple of',
              description:
                'Number input with a multiple of 5. Try to type a number that is not multiple of 5 and you will see the error',
              multipleOf: 5,
              examples: [5, 10, 15],
            },
          },
        },
        dates: {
          type: 'object',
          title: 'Dates',
          description: 'Date Inputs',
          properties: {
            dateInput: {
              type: 'string',
              format: 'date',
              title: 'Date input',
              description: 'A basic date input',
            },
            dateTimeInput: {
              type: 'string',
              format: 'date-time',
              title: 'Date time input',
              description: 'A basic date time input',
            },
            timeInput: {
              type: 'string',
              format: 'time',
              title: 'Time input',
              description: 'A basic time input',
            },
          },
        },
      },
    },
    select: {
      type: 'object',
      title: 'Select',
      description: 'Select',
      properties: {
        select: {
          type: 'string',
          title: 'Select',
          description:
            'When a property of type string has enum as value, it wil be rendered as a select',
          enum: ['Option 1', 'Option 2', 'Option 3'],
        },
        numberSelect: {
          type: 'number',
          title: 'Number Select',
          description:
            'When a property of type number has enum as value, it wil be rendered as a select',
          enum: [1, 2, 3, 4],
        },
        labeledSelect: {
          type: 'number',
          title: 'Labeled Select',
          description: 'You can use "oneOf" to define a select with labels',
          oneOf: [
            {
              title: 'One',
              const: 1,
            },
            {
              title: 'Two',
              const: 2,
            },
            {
              title: 'Three',
              const: 3,
            },
          ],
        },
        multiSelect: {
          type: 'array',
          title: 'Multi select',
          description:
            'When the property is an array with unique items and items has enum, it wil be rendered as a multiselect',
          items: {
            type: 'string',
            enum: ['foo', 'bar', 'fuzz', 'qux'],
          },
          uniqueItems: true,
        },
      },
    },
    CheckboxGroups: {
      type: 'object',
      title: 'Checkbox groups',
      properties: {
        checkboxGroup: {
          type: 'array',
          title: 'Checkbox Group',
          description:
            'You can use "enum" and component: "checkBoxGroup" to define a Checkbox Group',
          uiProperties: {
            component: 'checkBoxGroup',
          },
          uniqueItems: true,
          items: {
            type: 'string',
            enum: ['Option One', 'Option Two', 'Option Three'],
          },
        },
        labeledCheckboxGroup: {
          type: 'array',
          title: 'Labeled Checkbox Group',
          description:
            'You can use "oneOf" and component: "checkBoxGroup" to define a Checkbox Group',
          uiProperties: {
            component: 'checkBoxGroup',
          },
          uniqueItems: true,
          items: {
            type: 'string',
            oneOf: [
              {
                title: 'Option One',
                const: 'One value',
              },
              {
                title: 'Option Two',
                const: '2 value',
              },
              {
                title: 'Option Three',
                const: '3 value',
              },
            ],
          },
        },
      },
    },
    radioGroup: {
      type: 'object',
      title: 'Radio Group',
      properties: {
        radioGroup: {
          type: 'string',
          title: 'Radio Group',
          description:
            'You can use "enum" and component: "radioGroup" to define a Radio Group',
          uiProperties: {
            component: 'radioGroup',
          },
          enum: ['Option One', 'Option Two', 'Option Three'],
        },
        labeledRadio: {
          type: 'number',
          title: 'Labeled Radio',
          description: 'You can use "oneOf" to define a radioGroup with labels',
          uiProperties: {
            component: 'radioGroup',
          },
          oneOf: [
            {
              title: 'One',
              const: 1,
            },
            {
              title: 'Two',
              const: 2,
            },
            {
              title: 'Three',
              const: 3,
            },
          ],
        },
      },
    },
    lists: {
      type: 'object',
      title: 'Lists',
      properties: {
        stringList: {
          type: 'array',
          title: 'String List',
          description: 'This is a string array example',
          items: {
            type: 'string',
          },
        },
        numberList: {
          type: 'array',
          title: 'Number List',
          description: 'This is a number array example',
          items: {
            type: 'number',
          },
        },
        integerList: {
          type: 'array',
          title: 'Integer List',
          description: 'This is a integer array example',
          items: {
            type: 'integer',
          },
        },
        selectList: {
          type: 'array',
          title: 'Select List',
          description: 'This is a select array example',
          items: {
            type: 'string',
            title: 'Select',
            description:
              'When a property of type string has enum as value, it wil be rendered as a select',
            enum: ['Option 1', 'Option 2', 'Option 3'],
          },
        },
        booleanList: {
          type: 'array',
          title: 'Boolean List',
          description: 'This is a boolean array example',
          items: {
            type: 'boolean',
            title: 'Boolean',
          },
        },
        dateLists: {
          type: 'object',
          title: 'Date Lists',
          description: 'This is a description',
          properties: {
            dateList: {
              type: 'array',
              title: 'Date List',
              description:
                'You can create a list of format date. The format Date, for example, 2018-11-13',
              items: {
                type: 'string',
                format: 'date',
              },
            },
            dateTime: {
              type: 'array',
              description:
                'You can create a list of format date-time. The format Date and time together, for example, 2018-11-13T20:20:39+00:00',
              title: 'Date Time List',
              items: {
                type: 'string',
                format: 'date-time',
              },
            },
          },
        },
        objectLists: {
          type: 'object',
          title: 'Object Lists',
          description: 'You can create a list containing complex objects',
          properties: {
            persons: {
              type: 'array',
              description: 'You can create a list containing complex objects',
              title: 'Persons',
              items: {
                type: 'object',
                required: ['name'],
                title: 'Person',
                properties: {
                  name: {
                    type: 'string',
                    title: 'Name',
                    description: 'This is a required field',
                  },
                  surname: {
                    type: 'string',
                    title: 'Surname',
                  },
                },
              },
            },
            tasks: {
              type: 'array',
              title: 'Object list',
              description:
                'In this example you can create a list of tasks, and each task can have subtasks',
              items: {
                type: 'object',
                title: 'Task',
                required: ['name'],
                properties: {
                  name: {
                    type: 'string',
                    title: 'Name',
                    default: 'A new task is added',
                  },
                  done: {
                    type: 'boolean',
                    title: 'Done?',
                    default: false,
                  },
                  severity: {
                    type: 'string',
                    title: 'Severity',
                    enum: ['Low', 'Medium', 'High'],
                  },
                  subtasks: {
                    type: 'array',
                    title: 'Sub tasks',
                    items: {
                      type: 'object',
                      title: 'Sub-Task',
                      required: ['name'],
                      properties: {
                        name: {
                          type: 'string',
                          title: 'Name',
                          default: 'A new subtask',
                        },
                        done: {
                          type: 'boolean',
                          title: 'Done?',
                          default: false,
                        },
                        severity: {
                          type: 'string',
                          title: 'Severity',
                          enum: ['Low', 'Medium', 'High'],
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        arrayLists: {
          type: 'object',
          title: 'Array list',
          description: 'List containing lists',
          properties: {
            arrayList: {
              type: 'array',
              description: 'You can create a list containing lists',
              title: 'Array List',
              items: {
                type: 'array',
                title: 'List',
                items: {
                  type: 'string',
                },
              },
            },
            nestedArrayList: {
              type: 'array',
              description: 'You can create nested lists',
              title: 'Nested Array List',
              items: {
                type: 'array',
                title: 'Level 1 List',
                items: {
                  type: 'array',
                  title: 'Level 2 list',
                  items: {
                    type: 'array',
                    title: 'Level 3 List',
                    items: {
                      items: {
                        type: 'array',
                        title: 'Level 4 List',
                        items: {
                          type: 'string',
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    team: {
      type: 'object',
      title: 'Members',
      properties: {
        members: {
          uiProperties: {
            component: 'membersList',
          },
          type: 'array',
          title: 'Team',
          description: 'Manage user access and permissions',
          items: {
            type: 'string',
          },
        },
      },
    },
  },
};

export const coverage: Coverage = {
  __typename: 'Coverage',
  health: [
    {
      key: Category.RealtimePerformance,
      value: 0.34,
    },
  ],
};

export const randomAlertCount = (): AlertCount => ({
  critical: Math.floor(Math.random() * 10),
  error: Math.floor(Math.random() * 10),
  standard: Math.floor(Math.random() * 10),
});

// Mocked data for the product list
export const mockedProducts: { [key in ProductKind]: Product } = {
  AG: {
    id: 'AG',
    kind: 'AG',
    alertsCount: randomAlertCount(),
    categories: [randomEnum(Category), randomEnum(Category)],
    name: 'AG',
    coverage: {
      health: [
        {
          key: Category.ProtectionAndRecovery,
          value: 0.55,
        },
        {
          key: Category.SecurityAndAudit,
          value: 0.07,
        },
      ],
    },
    statsAvailable: [
      {
        code: 'average_rpo_across_all_policies',
        title: 'Average RPO across all policies',
        description: 'Some description',
        polarity: ChartPolarity.Positive,
        unit: 'second',
      },
      {
        code: 'vault_cluster_capacity_(%used)',
        title: 'Vault cluster capacity (%used)',
        description: 'Some description',
        polarity: ChartPolarity.Positive,
        unit: 'second',
      },
      {
        code: '%_of_time_vault_is_open_per_day',
        title: '% of time vault is open per day',
        description: 'Some description',
        polarity: ChartPolarity.Positive,
        unit: 'second',
      },
    ],
    stats: [],
    comingSoon: false,
  },
  EA: {
    id: 'EA',
    kind: 'EA',
    categories: [randomEnum(Category), randomEnum(Category)],
    name: 'EA',
    alertsCount: randomAlertCount(),
    coverage: {
      health: [
        {
          key: Category.Compliance,
          value: 0.3,
        },
        {
          key: Category.SecurityAndAudit,
          value: 0.07,
        },
      ],
    },
    statsAvailable: [
      {
        code: 'file_events_processed',
        title: 'File events processed',
        description: 'Some description',
        polarity: ChartPolarity.Positive,
        unit: 'second',
      },
      {
        code: 'total_data_written',
        title: 'Total data written',
        description: 'Some description',
        polarity: ChartPolarity.Positive,
        unit: 'second',
      },
      {
        code: 'total_data_read',
        title: 'Total data read',
        description: 'Some description',
        polarity: ChartPolarity.Positive,
        unit: 'second',
      },
    ],
    stats: [],
    comingSoon: false,
  },
  DR: {
    id: 'DR',
    kind: 'DR',
    categories: [randomEnum(Category), randomEnum(Category)],
    name: 'DR',
    alertsCount: randomAlertCount(),
    coverage: {
      health: [
        {
          key: Category.ProtectionAndRecovery,
          value: 0,
        },
        {
          key: Category.Compliance,
          value: 0.07,
        },
      ],
    },
    statsAvailable: [
      {
        code: 'average_rpo_across_all_policies',
        title: 'Average RPO across all policies',
        description: 'Some description',
        polarity: ChartPolarity.Positive,
        unit: 'second',
      },
      {
        code: 'synciq_data_transferred',
        title: 'SyncIQ Data Transferred',
        description: 'Some description',
        polarity: ChartPolarity.Positive,
        unit: 'second',
      },
      {
        code: 'failover_readiness_percentage',
        title: 'Failover Readiness Percentage',
        description: 'Some description',
        polarity: ChartPolarity.Positive,
        unit: 'second',
      },
    ],
    stats: [],
    comingSoon: false,
  },
  GC: {
    id: 'GC',
    kind: 'GC',
    categories: [randomEnum(Category), randomEnum(Category)],
    name: 'GC',
    alertsCount: randomAlertCount(),
    coverage: {
      health: [
        {
          key: Category.CapacityManagementAndReporting,
          value: 0,
        },
      ],
    },
    statsAvailable: [],
    stats: [],
    comingSoon: false,
  },
  RWD: {
    id: 'RWD',
    kind: 'RWD',
    categories: [randomEnum(Category), randomEnum(Category)],
    name: 'RWD',
    alertsCount: randomAlertCount(),
    coverage: {
      health: [
        {
          key: Category.SecurityAndAudit,
          value: 0.25,
        },
      ],
    },
    statsAvailable: [
      {
        code: 'file_events_processed',
        title: 'File events processed',
        description: 'Some description',
        polarity: ChartPolarity.Positive,
        unit: 'second',
      },
      {
        code: 'ransomware_events_processed',
        title: 'Ransomware events processed',
        description: 'Some description',
        polarity: ChartPolarity.Positive,
        unit: 'second',
      },
      {
        code: 'false_positive_rate',
        title: 'False positive rate',
        description: 'Some description',
        polarity: ChartPolarity.Positive,
        unit: 'second',
      },
    ],
    stats: [],
    comingSoon: false,
  },
  SEARCH: {
    id: 'SEARCH',
    kind: 'SEARCH',
    categories: [randomEnum(Category), randomEnum(Category)],
    name: 'SEARCH',
    alertsCount: randomAlertCount(),
    coverage: {
      health: [
        {
          key: Category.CapacityManagementAndReporting,
          value: 0.34,
        },
      ],
    },
    statsAvailable: [],
    stats: [],
    comingSoon: false,
  },
  SMART_ARCHIVER: {
    id: 'SMART_ARCHIVER',
    kind: 'SMART_ARCHIVER',
    categories: [randomEnum(Category), randomEnum(Category)],
    name: 'SMART_ARCHIVER',
    alertsCount: randomAlertCount(),
    coverage: {
      health: [
        {
          key: Category.CapacityManagementAndReporting,
          value: 0.34,
        },
      ],
    },
    statsAvailable: [],
    stats: [],
    comingSoon: false,
  },
  ANY_COPY: {
    id: 'ANY_COPY',
    kind: 'ANY_COPY',
    categories: [randomEnum(Category), randomEnum(Category)],
    name: 'ANY_COPY',
    alertsCount: randomAlertCount(),
    coverage: {
      health: [
        {
          key: Category.RealtimePerformance,
          value: 0.34,
        },
      ],
    },
    statsAvailable: [],
    stats: [],
    comingSoon: false,
  },
  CSM: {
    id: 'CSM',
    kind: 'CSM',
    categories: [randomEnum(Category), randomEnum(Category)],
    name: 'CSM',
    alertsCount: randomAlertCount(),
    coverage: {
      health: [
        {
          key: Category.CapacityManagementAndReporting,
          value: 0.34,
        },
      ],
    },
    statsAvailable: [],
    stats: [],
    comingSoon: true,
  },
  PA: {
    id: 'PA',
    kind: 'PA',
    categories: [randomEnum(Category), randomEnum(Category)],
    name: 'PA',
    alertsCount: randomAlertCount(),
    coverage: {
      health: [
        {
          key: Category.RealtimePerformance,
          value: 0.34,
        },
      ],
    },
    statsAvailable: [],
    stats: [],
    comingSoon: false,
  },
};

export const productWithAlertCount = (app: Product, count: AlertCount) => ({
  ...app,
  alertsCount: count,
});

// Remove app from this list to simulate it not being installed
export const mockedInstalledProducts = [
  productWithAlertCount(mockedProducts.AG, {
    critical: 0,
    error: 5,
    standard: 0,
  }),
  productWithAlertCount(mockedProducts.EA, {
    critical: 0,
    error: 0,
    standard: 5,
  }),
  productWithAlertCount(mockedProducts.DR, {
    critical: 5,
    error: 0,
    standard: 0,
  }),
  productWithAlertCount(mockedProducts.RWD, {
    critical: 0,
    error: 6,
    standard: 6,
  }),
  mockedProducts.CSM,
  // mockedProducts.PA,
  // appWithAlertCount(mockedProducts.SMART_ARCHIVER, {
  //   critical: 0,
  //   error: 0,
  //   standard: 0,
  // }),
  // appWithAlertCount(mockedProducts.GC, {
  //   critical: 10,
  //   error: 0,
  //   standard: 0,
  // }),
  // appWithAlertCount(mockedProducts.SEARCH, {
  //   critical: 20,
  //   error: 0,
  //   standard: 0,
  // }),
];

export const mockedAllProducts = [
  mockedProducts.AG,
  mockedProducts.EA,
  mockedProducts.DR,
  mockedProducts.GC,
  mockedProducts.RWD,
  mockedProducts.SEARCH,
  mockedProducts.SMART_ARCHIVER,
  mockedProducts.ANY_COPY,
  mockedProducts.CSM,
  mockedProducts.PA,
];
export const mockedPublicProducts: GetAvailablePublicProductsQuery['products'] =
  mockedAllProducts.map((prod) => ({
    ...prod,
    __typename: 'Product',
    comingSoon: false,
    kind: prod.kind as ProductKind,
  }));

export const defaultMockForwardWorkflow: ForwardWorkflow = {
  enabled: true,
  id: 'e6c3d149-3d59-481e-8885-d990473cf26a',
  name: 'Alert Workflow 1',
  triggerRules: [
    {
      source: TriggerRuleSources.ProductKind,
      valueList: [ProductKind.ANY_COPY, ProductKind.GC],
    },
    {
      source: TriggerRuleSources.AlertSeverity,
      valueList: [AlertSeverity.Critical, AlertSeverity.Error],
    },
  ],
  batchConfigs: [],
  channels: [
    {
      type: ChannelTypes.Email,
      valueList: [
        {
          value: 'first name',
          key: 'first@email.com',
        },
        {
          value: 'second name',
          key: 'second@email.com',
        },
      ],
    },
    {
      type: ChannelTypes.Slack,
      valueList: [],
    },
  ],
};

export const getMockAlertWorkflow = (
  partial?: Partial<ForwardWorkflow>,
): ForwardWorkflow =>
  partial
    ? (mergeDeepRight(defaultForwardGroup, partial) as ForwardWorkflow)
    : defaultMockForwardWorkflow;

export const mockedAlertWorkflows: GetForwardWorkflowQuery['forwardWorkflow'][] =
  [
    {
      enabled: true,
      id: 'e6c3d149-3d59-481e-8885-d990473cf26a',
      name: randomAlertDescription(),
      triggerRules: [
        {
          source: TriggerRuleSources.ProductKind,
          valueList: [ProductKind.ANY_COPY, ProductKind.GC],
        },
        {
          source: TriggerRuleSources.AlertSeverity,
          valueList: [AlertSeverity.Critical, AlertSeverity.Error],
        },
      ],
      batchConfigs: [],
      channels: [
        {
          type: ChannelTypes.Email,
          valueList: [
            {
              value: 'first name',
              key: 'first@email.com',
            },
            {
              value: 'second name',
              key: 'second@email.com',
            },
          ],
        },
        {
          type: ChannelTypes.Slack,
          valueList: [],
        },
      ],
    },
    {
      enabled: true,
      id: 'e6c3d149-3d59-481e-8885-d990473cf262',
      name: randomAlertDescription(),
      triggerRules: [
        {
          source: TriggerRuleSources.ProductKind,
          valueList: [ProductKind.Ag, ProductKind.Csm],
        },
        {
          source: TriggerRuleSources.ApplianceId,
          valueList: ['Installation 1', 'Installation 2'],
        },
      ],
      batchConfigs: [],
      channels: [
        {
          type: ChannelTypes.Slack,
          valueList: [],
        },
      ],
    },
    {
      enabled: false,
      id: 'e6c3d149-3d59-481e-8885-d990473cf263',
      name: randomAlertDescription(),
      triggerRules: [
        {
          source: TriggerRuleSources.AlertSeverity,
          valueList: [AlertSeverity.Critical, AlertSeverity.Error],
        },
      ],
      batchConfigs: [
        {
          enabled: true,
          type: BatchTypes.Finding,
          threshold: 1,
          condition: AlertSeverity.Error,
        },
      ],
      channels: [
        {
          type: ChannelTypes.Email,
          valueList: [],
        },
      ],
    },
  ];

const defaultForwardGroup: ForwardGroup = {
  alertCodes: ['SCA0089', 'SCA0080', 'SCA0081'],
  id: 'e6c3d149-3d59-481e-8885-d990473cf262',
  name: 'e6c3d149-3d59-481e-8885-d990473cf262',
  sentAt: dayjs(),
  channelResults: [
    {
      channel: ChannelTypes.Email,
      results: [
        {
          item: 'email@superna.io',
          result: true,
        },
        {
          item: 'email2@superna.io',
          result: true,
        },
        {
          item: 'email3@superna.io',
          result: true,
        },
      ],
    },
  ],
  workflow: mockedAlertWorkflows[2] as ForwardWorkflow,
};

export const getMockAlertWorkflowLog = (
  partial?: Partial<ForwardGroup>,
): ForwardGroup =>
  partial
    ? (mergeDeepRight(defaultForwardGroup, partial) as ForwardGroup)
    : defaultForwardGroup;

export const randomForwardGroup = (
  partial?: Partial<ForwardGroup>,
): ForwardGroup => {
  const randomLog: ForwardGroup = {
    id: randomId(),
    name: randomId(),
    workflow: mockedAlertWorkflows[
      Math.floor(Math.random() * mockedAlertWorkflows.length)
    ] as ForwardWorkflow,
    sentAt: randomDate({
      start: new Date(new Date().setDate(new Date().getDate() - 5)),
    }),
    alertCodes: randomArray({
      render: randomAlertCode,
      maxItems: 20,
    }),
    channelResults: [
      {
        channel: ChannelTypes.Email,
        results: randomArray({
          render: () => ({
            item: `email.${s4()}@superna.io`,
            result: Math.random() > 0.1,
          }),
        }),
      },
    ],
  };
  return partial
    ? (mergeDeepRight(randomLog, partial) as ForwardGroup)
    : randomLog;
};

export const mockForwardGroups: ForwardGroup[] = [
  {
    alertCodes: ['SCA0089', 'SCA0080', 'SCA0081'],
    id: 'e6c3d149-3d59-481e-8885-d990473cf26a',
    name: 'e6c3d149-3d59-481e-8885-d990473cf26a',
    sentAt: new Date().toDateString(),
    channelResults: [
      {
        channel: ChannelTypes.Email,
        results: [
          {
            item: 'email@superna.io',
            result: true,
          },
          {
            item: 'email2@superna.io',
            result: true,
          },
          {
            item: 'email3@superna.io',
            result: true,
          },
        ],
      },
    ],
    workflow: mockedAlertWorkflows[0] as ForwardWorkflow,
  },
  {
    alertCodes: ['SCA0089', 'SCA0080', 'SCA0081'],
    id: 'e6c3d149-3d59-481e-8885-d990473cf26b',
    name: 'e6c3d149-3d59-481e-8885-d990473cf26b',
    sentAt: new Date().toDateString(),
    channelResults: [
      {
        channel: ChannelTypes.Email,
        results: [
          {
            item: 'email@superna.io',
            result: false,
          },
          {
            item: 'email2@superna.io',
            result: true,
          },
          {
            item: 'email3@superna.io',
            result: true,
          },
        ],
      },
      {
        channel: ChannelTypes.Slack,
        results: [
          {
            item: 'Superna: dev-security-alerts',
            result: true,
          },
        ],
      },
    ],
    workflow: mockedAlertWorkflows[0] as ForwardWorkflow,
  },
  {
    alertCodes: [
      'SCA0080',
      'SCA0081',
      'SCA0082',
      'SCA0083',
      'SCA0084',
      'SCA0085',
      'SCA0086',
      'SCA0087',
      'SCA0088',
      'SCA0089',
      'SCA0090',
      'SCA0091',
    ],
    id: 'e6c3d149-3d59-481e-8885-d990473cf261',
    name: 'e6c3d149-3d59-481e-8885-d990473cf261',
    sentAt: dayjs().subtract(1, 'day'),
    channelResults: [
      {
        channel: ChannelTypes.Email,
        results: [
          {
            item: 'email@superna.io',
            result: false,
          },
          {
            item: 'email2@superna.io',
            result: true,
          },
          {
            item: 'email3@superna.io',
            result: true,
          },
          {
            item: 'email4@superna.io',
            result: false,
          },
          {
            item: 'email5@superna.io',
            result: true,
          },
          {
            item: 'email6@superna.io',
            result: true,
          },
        ],
      },
      {
        channel: ChannelTypes.Slack,
        results: [
          {
            item: 'Superna: dev-security-alerts',
            result: true,
          },
        ],
      },
    ],
    workflow: mockedAlertWorkflows[1] as ForwardWorkflow,
  },
  ...randomArray({
    render: randomForwardGroup,
    minItems: 10,
    maxItems: 100,
  }),
].sort((a, b) => new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime());

export const getMockQueryForwardGroups = (
  args?: GetForwardGroupsQueryVariables,
): GetForwardGroupsQuery => {
  const totalNodes = mockForwardGroups.filter((log) => {
    if (
      !args?.filter?.from
      // && !args?.filter?.forwardWorkflowIds
    ) {
      return true;
    }
    const fromDayjs = dayjs(args?.filter?.from);
    const sentAt = dayjs(log.sentAt);
    const fromFilterPass = !args?.filter?.from || fromDayjs.isBefore(sentAt);
    // const idsFilterPass =
    //   !args?.filter?.forwardWorkflowIds ||
    //   args?.filter?.forwardWorkflowIds.includes(log.workflow.id);
    // return fromFilterPass && idsFilterPass;
    return fromFilterPass;
  });

  const nodes = args?.first ? totalNodes.slice(0, args.first) : totalNodes;

  return {
    forwardGroups: {
      nodes,
      __typename: 'ForwardGroupConnection',
      pageInfo: {
        startCursor: nodes[0]?.id,
        endCursor: nodes[nodes.length - 1]?.id,
        hasNextPage:
          nodes[nodes.length - 1]?.id !== totalNodes[totalNodes.length - 1]?.id,
        hasPreviousPage: nodes[0]?.id !== totalNodes[0]?.id,
      },
      totalItems: nodes.length,
    },
  };
};

export const mockedCreateAlertWorkflows = (
  input: CreateForwardWorkflowInput,
): CreateForwardWorkflowMutation['createForwardWorkflow'] => {
  return {
    batchConfigs: input.batchConfigs || [],
    channels: input.channels || [],
    enabled: false,
    id: randomId(),
    name: input.name,
    triggerRules: input.triggerRules || [],
  };
};

export const randomBoolean = () => Math.random() > 0.5;

export const randomServer = (): Server => {
  const id = randomId();
  return {
    createdAt: randomDate(),
    updatedAt: randomDate(),
    ipv4: randomIp(),
    id,
    name: randomSentence(4),
    state: randomEnum(ServerState),
    type: randomEnum(ServerType),
  };
};

export const DEFAULT_APPLIANCE: Appliance = {
  applianceId: '0',
  description: 'Default appliance',
  id: '0',
  installedProducts: [],
  node: 'node',
  online: false,
  status: ApplianceStatus.Active,
  servers: [],
};

export const randomProduct = () => {
  const prodArray = Object.values(mockedProducts);
  const randomProd = prodArray[Math.floor(Math.random() * prodArray.length)];
  return randomProd;
};

export const getRandomInstalledProduct = (
  partial?: Partial<InstalledProduct>,
): InstalledProduct => {
  const prodArray = Object.values(mockedProducts);
  const randomProd = prodArray[Math.floor(Math.random() * prodArray.length)];
  return {
    appliance: DEFAULT_APPLIANCE,
    licenses: [],
    id: randomProd.id,
    softwareVersion: randomIp(),
    product: {
      ...randomProd,
      alertsCount: randomAlertCount(),
    },
    ...partial,
  };
};

export const randomDeviceLink = (
  partial?: Partial<LicensedDeviceLink>,
): LicensedDeviceLink => ({
  state: randomEnum(ManagedDeviceLicenseState),
  device: getRandomManagedDeviceMock(),
  license: getRandomInstalledProductLicenseMock(),
  ...partial,
});

export const getRandomApplianceMock = (
  partial?: Partial<Appliance>,
): Appliance => {
  const id = randomId();
  const prodArray = Object.values(mockedProducts);
  const start = Math.round(Math.random() * prodArray.length - 1);
  const end =
    Math.round(Math.random() * (prodArray.length - 1 - start)) + start;
  const installedProducts: InstalledProduct[] =
    partial?.installedProducts ||
    prodArray.slice(start, end).map(
      (product): InstalledProduct => ({
        appliance: DEFAULT_APPLIANCE,
        id: product.id,
        softwareVersion: randomIp(),
        licenses: randomArray({
          render: getRandomInstalledProductLicenseMock,
        }),
        product: {
          ...product,
          alertsCount: randomAlertCount(),
        },
      }),
    );
  return {
    id,
    applianceId: id,
    installedProducts,
    description: randomSentence(4),
    node: randomWord(),
    online: randomBoolean(),
    status: randomEnum(ApplianceStatus),
    servers: randomArray({
      render: randomServer,
    }),
    ipv4: randomIp(),
    osVersion: randomIp(),
    ...partial,
  };
};

export const getRandomSlackChannelMock = (
  partial?: Partial<SlackChannel>,
): SlackChannel => {
  return {
    name: randomSentence(4),
    id: randomId(),
    topic: randomSentence(),
    purpose: randomSentence(),
    channelType: randomEnum(SlackChannelType),
    isGeneral: randomBoolean(),
    isArchived: randomBoolean(),
    ...partial,
  };
};

export const getRandomServerComponent = (): ServerComponent => ({
  // id: randomId(),
  healthStatus: randomEnum(HealthStatus),
  name: randomSentence(4),
  details: randomSentence(10),
  cpu: Math.round(Math.random() * 100),
  ram: Math.round(Math.random() * 100),
  webAddress: `http://${randomIp()}/web.adress.com`,
});

export const getRandomInstalledProductLicenseMock = (
  partial?: Partial<InstalledProductLicense>,
): InstalledProductLicense => {
  const id = randomId();
  const sizeLicensed = randomInt();
  return {
    id,
    description: randomSentence(),
    expiration: randomDate({
      start: new Date(),
      end: new Date(2029, 0, 1),
    }),
    kind: randomEnum(LicenseKind),
    // kind: LicenseKind.Capacity,
    platform: randomEnum(PlatformType),
    sizeConsumed: randomInt(0, sizeLicensed),
    sizeLicensed,
    perpetual: randomBoolean(),
    licensedDevices: [],
    installedProduct: {
      id,
      softwareVersion: randomIp(),
      appliance: DEFAULT_APPLIANCE,
      product: mockedProducts.RWD,
      // licenses: randomArray({
      //   render: getRandomInstalledProductLicenseMock,
      //   maxItems: 2,
      // }),
      licenses: [],
    },
    ...partial,
  };
};
export const getRandomManagedDeviceMock = (
  partial?: Partial<ManagedDevice>,
): ManagedDevice => {
  const id = randomId();

  return {
    guid: id,
    licenseLinks: [
      {
        device: {
          guid: '',
          licenseLinks: [],
        },
        license: {
          description: '',
          expiration: randomDate(),
          id: randomId(),
          installedProduct: {
            appliance: DEFAULT_APPLIANCE,
            product: randomProduct(),
            id: randomId(),
            licenses: [],
          },
          kind: randomEnum(LicenseKind),
          licensedDevices: [],
          perpetual: true,
          platform: randomEnum(PlatformType),
          sizeConsumed: randomInt(),
          sizeLicensed: randomInt(),
        },
        state: randomEnum(ManagedDeviceLicenseState),
      },
    ],
    ipv4: randomIp(),
    ...partial,
  };
};
