import {
  faCheckCircle,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

export interface StatusBarProps {
  type?: 'success' | 'warning' | 'critical';
  children?: React.ReactNode;
  hideIcon?: boolean;
}
export const StatusBar = ({
  hideIcon,
  type = 'success',
  children,
}: StatusBarProps) => {
  return (
    <div
      className={classNames(
        'm-[1px] flex items-center gap-2 rounded-md p-3 pl-5 text-base text-tw-label-primary ring-1 dark:text-tw-label-primary-dark',
        type === 'critical' &&
          'bg-tw-danger-soft ring-tw-danger-strong dark:bg-tw-danger-soft-dark dark:ring-tw-danger-strong-dark',
        type === 'warning' &&
          'bg-tw-warning-soft ring-tw-warning-strong dark:bg-tw-warning-soft-dark dark:ring-tw-warning-strong-dark',
        type === 'success' &&
          'bg-tw-success-soft ring-tw-success-strong dark:bg-tw-success-soft-dark dark:ring-tw-success-strong-dark',
      )}
    >
      <div className="w-full">{children}</div>
      {!hideIcon && (
        <FontAwesomeIcon
          className={classNames(
            'ml-auto h-6 w-6',
            type === 'critical' && 'text-tw-danger-strong',
            type === 'warning' && 'text-tw-warning-strong',
            type === 'success' && 'text-tw-success-strong',
          )}
          icon={type === 'success' ? faCheckCircle : faCircleExclamation}
        />
      )}
    </div>
  );
};
