import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { AlertSeverity } from '../../../gql/graphql';

const DEFAULT_CLASSNAME = 'severity-badge';

export interface IAppIconProps {
  severity: AlertSeverity | string;
  className?: string;
}

const getVariantClassName = (
  variant: AlertSeverity | string,
  disabled?: boolean,
): string | null => {
  if (disabled) return 'bg-gray-400';
  switch (variant.toLowerCase()) {
    case AlertSeverity.Critical.toLowerCase():
      return 'bg-tw-critical-color';
    case AlertSeverity.Error.toLowerCase():
    case 'major':
    case 'warning':
      return 'bg-tw-warning-color';
    case AlertSeverity.Standard:
    default:
      return 'bg-tw-main-color';
  }
};

export const SeverityBadge = ({
  severity = AlertSeverity.Standard,
  className,
  ...rest
}: IAppIconProps) => {
  const { t } = useTranslation('notifications');
  const contentClassNames = classNames(
    DEFAULT_CLASSNAME,
    className,
    getVariantClassName(severity),
    'text-white rounded uppercase py-[5px] px-2 font-graphikMedium w-auto text-center shadow-dark-bottom-50',
  );

  return (
    <p
      className={contentClassNames}
      {...rest}
      style={{ fontSize: '12px', lineHeight: '13px' }}
    >
      {t(`notifications.severity.${severity.toLowerCase()}`, severity)}
    </p>
  );
};

export default SeverityBadge;
