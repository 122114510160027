import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { useCallback, useEffect, useId, useMemo, useState } from 'react';
import { IconComponent, IconName } from '../../../../assets/icons/Icon';
import { FieldError, Label } from '../Common';
import { SelectionType, Size, Variant } from '../types';
import { optionsMapper } from '../utils';
import { DropDownButton } from './DropDownButton';
import { PickerOption, PickerOptions } from './Select';

const SELECT_ALL_VALUE = 'select-all';
const SELECT_ALL_LABEL = 'Select All';
const DESELECT_ALL_LABEL = 'Deselect All';

export type LabelType = 'label' | 'values' | 'label-count';
interface IProps {
  options: PickerOptions;
  onSelectionChange?: (selectedIds: (string | number)[]) => void;
  buttonLabel?: string;
  label?: string;
  defaultSelected?: string[] | number[];
  variant?: Variant;
  size?: Size;
  className?: string;
  Icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  iconName?: IconName;
  type?: SelectionType;
  showSelectAllOption?: boolean;
  selectAllLabel?: string;
  deselectAllLabel?: string;
  disabled?: boolean;
  id?: string;
  labelType?: LabelType;
  error?: string | string[];
}

export const MultiSelect = ({
  buttonLabel = 'Select options',
  label,
  Icon: iconComponent,
  iconName,
  variant = 'default',
  size,
  className,
  options: defaultOptions,
  defaultSelected: defaultSelectedIds,
  onSelectionChange: onChange = console.log,
  showSelectAllOption,
  selectAllLabel = SELECT_ALL_LABEL,
  deselectAllLabel = DESELECT_ALL_LABEL,
  disabled,
  id: idProp,
  labelType = 'label',
  error,
  ...rest
}: IProps) => {
  const [options, setOptions] = useState<PickerOption[]>(
    defaultOptions.map(optionsMapper),
  );

  useEffect(() => {
    setOptions(defaultOptions.map(optionsMapper));
  }, [defaultOptions]);

  const [selectedIds, setSelectedIds] = useState<(string | number)[]>(
    defaultSelectedIds || [],
  );

  useEffect(() => {
    setSelectedIds(defaultSelectedIds || []);
  }, [defaultSelectedIds]);

  const isAllSelected = useMemo(
    () => selectedIds.length === options.length,
    [options.length, selectedIds.length],
  );

  const handleSelect = useCallback(
    (option: PickerOption) => {
      const isSelectAll = option.value === SELECT_ALL_VALUE;
      if (isSelectAll) {
        const newSelectedIds = isAllSelected ? [] : options.map((o) => o.value);
        setSelectedIds(newSelectedIds);
        onChange(newSelectedIds);
        return;
      }
      const newSelectedIds = selectedIds.includes(option.value)
        ? selectedIds.filter((id) => id !== option.value)
        : [...selectedIds, option.value];
      setSelectedIds(newSelectedIds);
      onChange(newSelectedIds);
    },
    [isAllSelected, onChange, options, selectedIds],
  );

  const Icon = useMemo(
    () => iconComponent || (iconName && IconComponent({ iconName })),
    [iconComponent, iconName],
  );

  const myId = useId();
  const id = useMemo(() => idProp || `MultiSelect-${myId}`, [idProp]);

  return (
    <div
      className={classNames('relative flex flex-col')}
      data-testid="multi-select-container"
      {...rest}
    >
      {label && (
        <Label data-testid="multi-select-label" className="mb-1" htmlFor={id}>
          {label}
        </Label>
      )}
      <Popover
        className={classNames(
          'relative z-10 inline-block text-left ',
          className,
        )}
        data-testid="multi-select"
        id={id}
      >
        {({ open }) => (
          <>
            <DropDownButton
              open={open}
              Icon={Icon}
              variant={variant}
              size={size}
              className={className}
              buttonType="popover"
              label={
                labelType === 'label' ? (
                  buttonLabel
                ) : labelType === 'values' ? (
                  selectedIds
                    .map((id) => options.find((o) => o.value === id)?.label)
                    .join(', ')
                ) : (
                  <>
                    {buttonLabel}
                    <span className="pl-auto ml-1">({selectedIds.length})</span>
                  </>
                )
              }
              disabled={disabled}
              error={error}
            />
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Popover.Panel
                data-testid="multi-select-panel"
                className={classNames(
                  'scrollbar z-50',
                  'absolute z-50 mt-0 max-h-60 w-full overflow-auto rounded-b-md py-1 font-graphikRegular text-tw-light-text shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                  'dark:text-tw-light-text-dark',
                  variant === 'default' &&
                    'bg-tw-light-shade dark:bg-tw-strong-shade-dark',
                  variant === 'white' && 'bg-white dark:bg-tw-dark-shade-dark',
                )}
              >
                {[
                  ...(showSelectAllOption
                    ? [
                        {
                          label: isAllSelected
                            ? deselectAllLabel
                            : selectAllLabel,
                          value: SELECT_ALL_VALUE,
                        },
                      ]
                    : []),
                  ...options,
                ].map((option) => {
                  const checked = selectedIds.includes(option.value);
                  const isSelectAll = option.value === SELECT_ALL_VALUE;
                  return (
                    <div
                      data-testid="multi-select-option"
                      data-test-value={option.value}
                      className="relative select-none px-1 py-1"
                      key={String(option.value)}
                    >
                      <div
                        className={classNames(
                          'flex w-full items-center justify-between',
                          'px-4 py-2',
                          'rounded-md px-3 py-1 transition',
                          checked ? 'font-semibold' : 'font-normal',
                          'hover:bg-tw-light-hover hover:dark:bg-tw-light-hover-dark',
                        )}
                        style={{ fontSize: '14px', lineHeight: '15px' }}
                        key={String(option)}
                      >
                        <input
                          type="checkbox"
                          className="mr-3.5 h-4 w-max rounded border-gray-300 text-tw-main-color sm:left-6"
                          checked={isSelectAll ? isAllSelected : checked}
                          id={String(option.value)}
                          name={String(option.label)}
                          onChange={() => handleSelect(option)}
                          disabled={option.disabled}
                        />
                        <label
                          className={classNames(
                            'block grow cursor-pointer truncate',
                            option.disabled && 'text-tw-description-text',
                          )}
                          htmlFor={String(option.value)}
                        >
                          {option.label}
                        </label>
                      </div>
                    </div>
                  );
                })}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      {error && <FieldError error={error} />}
    </div>
  );
};

export default MultiSelect;
