import { useMemo } from 'react';
import { rangeIdToValue } from '../../components/CalenderRangePicker/utils';
import ProductCard from '../../components/DashCards/ProductCard/ProductCard';
import { AlertCountTooltip } from '../../components/DashCards/ProductStatuses/components/AlertCountTooltip';
import { ContentLayoutWithProductFilter } from '../../components/Layout/ContentLayoutWithProductFilter';
import { useAppSelector } from '../../redux/hooks';
import { useProductCards } from './hooks/useProductCards';

export const Dashboard = () => {
  const {
    installed: installedProducts,
    missing: missingProducts,
    available: availableProducts,
    loading,
  } = useAppSelector((state) => state.products.products);

  const { duration } = useAppSelector((state) => state.productFilter);

  const dateRange = useMemo(() => {
    return rangeIdToValue(duration);
  }, [duration]);

  const productCardsProps = useProductCards(
    installedProducts,
    availableProducts,
  );

  return (
    <ContentLayoutWithProductFilter
      title={<span data-testid="capabilities-page-title">Capabilities</span>}
      installedProducts={installedProducts}
      missingProducts={missingProducts}
      loading={loading}
    >
      <AlertCountTooltip id="product-tooltip" />
      {/* App Cards */}
      {productCardsProps
        .filter((product) => product.inCurrentApplianceFilter)
        .map((product) => (
          <ProductCard
            key={product.kind}
            kind={product.kind}
            connected={product.connected}
            comingSoon={product.comingSoon}
            name={product.name}
            dateRange={dateRange}
          />
        ))}
    </ContentLayoutWithProductFilter>
  );
};
