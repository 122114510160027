import classNames from 'classnames';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import WarningCircleIcon from '../../assets/icons/WarningCircleIcon';
import { MicroFrontendContext } from '../../microFrontend/context';
import { useAppSelector } from '../../redux/hooks';
import { APPS_URL_PATH } from '../../routes/protected';
import { ProductProps, dashBoardNav } from '../../utils/navigationList';
import { CustomSelect } from '../CustomSelect';
import Spinner from '../Elements/Spinner/Spinner';
import { SideNav } from '../SideNavigation/SideNav';

export const ProductSwitchView = ({
  product,
  collapsed,
}: {
  product?: ProductProps;
  collapsed?: boolean;
}) => {
  if (!product) {
    return null;
  }
  return (
    <div className="flex h-12 flex-row items-center justify-start gap-2">
      <div className="h-9 w-9 shrink-0">{product.icon}</div>
      {!collapsed && (
        <div className="product-switch-text cursor-pointer text-left">
          <p className="product-switch-title whitespace-nowrap text-tw-main-text dark:text-tw-main-text-dark">
            {product.title}
          </p>
          <p className="product-switch-description text-tw-description-text">
            {product.description}
          </p>
        </div>
      )}
    </div>
  );
};

export const ProductSwitch = () => {
  // Add new logic above to hide products that are not installed, Can use the Plugins array from protected.tsx until we can get from BE
  const { plugins, loading } = useContext(MicroFrontendContext);
  const products = useMemo((): ProductProps[] => {
    return [dashBoardNav].concat(
      plugins.map((plugin) => ({
        id: `/${APPS_URL_PATH}/${plugin.path}`,
        title: plugin.title || plugin.path,
        description: plugin.description,
        icon:
          plugin.icon ||
          (plugin.error ? (
            <WarningCircleIcon width={39} height={39} />
          ) : (
            <Spinner />
          )),
        to: `/${APPS_URL_PATH}/${plugin.path}${
          plugin.defaultRoute ? `/${plugin.defaultRoute}` : ``
        }`,
        sideNav: (
          <SideNav
            sideNavGroups={
              plugin?.getSideNavItems &&
              plugin?.getSideNavItems(`${APPS_URL_PATH}/${plugin.path}`)
            }
          />
        ),
        disabled: !plugin.installed,
      })),
    );
  }, [plugins]);

  const sidebarCollapsed = useAppSelector(
    (state) => state.sidebarCollapse.collapsed,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(
    products.find((prod) => location.pathname.includes(prod.id))?.id ||
      products[0].id,
  );

  useEffect(() => {
    const prod = products.find((product) =>
      location.pathname.includes(product.id),
    );
    setSelectedKey(prod?.id || products[0].id);
  }, [location, products]);

  return (
    <div
      data-testid="ProductSwitch"
      className={!sidebarCollapsed ? '-ml-1.5' : 'my-2 flex justify-center'}
    >
      <CustomSelect
        loading={loading}
        onChange={({ key }) => {
          const prod = products.find((prod) => prod.id === key);
          setSelectedKey(key);
          navigate(prod?.to || '');
        }}
        showSelectedOptions
        optionsListClassName={sidebarCollapsed ? 'w-72' : ''}
        customSelectIcon={(sidebarCollapsed && <></>) || undefined}
        mainButtonClassName={classNames(
          'focus:ring-0 focus:outline-none  bg-transparent',
          sidebarCollapsed ? 'p-0' : 'pr-8',
        )}
        inAnimation={false}
        selectedItemRender={(item) => {
          const prod = products.find((prod) => prod.id === item.key);
          return (
            <ProductSwitchView product={prod} collapsed={sidebarCollapsed} />
          );
        }}
        value={selectedKey}
        options={products.map((prod) => ({
          key: prod.id,
          label: <ProductSwitchView product={prod} />,
          disabled: prod.disabled,
        }))}
      />
    </div>
  );
};
