import React from 'react';

export const Calendar = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M3.75 0C4.14844 0 4.5 0.351562 4.5 0.75V1.5H7.5V0.75C7.5 0.351562 7.82812 0 8.25 0C8.64844 0 9 0.351562 9 0.75V1.5H10.125C10.7344 1.5 11.25 2.01562 11.25 2.625V3.75H0.75V2.625C0.75 2.01562 1.24219 1.5 1.875 1.5H3V0.75C3 0.351562 3.32812 0 3.75 0ZM0.75 4.5H11.25V10.875C11.25 11.5078 10.7344 12 10.125 12H1.875C1.24219 12 0.75 11.5078 0.75 10.875V4.5ZM2.625 6C2.41406 6 2.25 6.1875 2.25 6.375V8.625C2.25 8.83594 2.41406 9 2.625 9H4.875C5.0625 9 5.25 8.83594 5.25 8.625V6.375C5.25 6.1875 5.0625 6 4.875 6H2.625Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Calendar;
