import { configureStore } from '@reduxjs/toolkit';
import localeReducer from './locale/localeSlice';
import loginReducer from './login/loginSlice';
import productFilterSlice from './products/filterSlice';
import productsReducer from './products/productsSlice';
import sidebarCollapseReducer from './sidebar/sidebarCollapseSlice';
import themeReducer from './theme/themeSlice';

export const store = configureStore({
  reducer: {
    theme: themeReducer,
    locale: localeReducer,
    login: loginReducer,
    sidebarCollapse: sidebarCollapseReducer,
    products: productsReducer,
    productFilter: productFilterSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
