import { useMutation } from '@apollo/client';
import { Transition } from '@headlessui/react';
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { DELETE_APPLIANCE, GET_INVENTORY } from '../../api/inventory/queries';
import {
  DeleteApplianceMutation,
  DeleteApplianceMutationVariables,
  GetInventoryApplianceQuery,
  Location,
  UpdateApplianceInput,
} from '../../gql/graphql';

import { toast } from 'react-toastify';
import { INCONPLETE_IPV4_MASK, isIpv4 } from '../../utils/format';
import { SimpleButton } from '../Buttons/SimpleButton';
import { ResolvedLocationSelect } from '../common/LocationSelect';
import { Message } from '../common/Message';
import { InputText } from '../form/fields';
import { Switch } from '../form/fields/Switch/Switch';

export interface EditApplianceModalProps {
  appliance: GetInventoryApplianceQuery['appliance'] | undefined;
  onUpdate?: (appliance: UpdateApplianceInput) => void;
  onDeleteClick?: (
    appliance: GetInventoryApplianceQuery['appliance'] | undefined,
  ) => void;
  onDeleteCancel?: () => void;
  onDeleted?: () => void;
  onDeleteConfirm?: (
    appliance: GetInventoryApplianceQuery['appliance'] | undefined,
  ) => void;
  loading?: boolean;
  error?: string;
}

const EditApplianceModal = ({
  appliance: data,
  onUpdate,
  onDeleteCancel,
  onDeleteClick,
  onDeleted,
  onDeleteConfirm,
  loading,
  error,
}: EditApplianceModalProps) => {
  const [touched, setTouched] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>(
    {},
  );

  const [ipv4, setIpv4] = useState<string | undefined | null>(data?.ipv4);

  const [description, setDescription] = useState<string | undefined>(
    data?.description,
  );

  const [location, setLocation] = useState<Location | undefined | null>(
    data?.location,
  );

  const [viewable, setViewable] = useState(
    data?.location?.latitude ? true : false,
  );

  useEffect(() => {
    setLocation(data?.location);
  }, [data?.location]);
  useEffect(() => {
    setDescription(data?.description);
  }, [data?.description]);
  useEffect(() => {
    setIpv4(data?.ipv4);
  }, [data?.ipv4]);
  useEffect(() => {
    setTouched(false);
  }, [data]);

  const handleIpv4Change = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const validInput = e.currentTarget.value.match(INCONPLETE_IPV4_MASK);

      validInput && setTouched(true);
      validInput && setIpv4(e.currentTarget.value);
      errors.ipv4 &&
        validInput &&
        setErrors((prev) => ({
          ...prev,
          ipv4: undefined,
        }));
    },
    [errors],
  );

  const handleDescriptionChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDescription(e.currentTarget.value);
      setTouched(true);
      errors.description &&
        setErrors((prev) => ({
          ...prev,
          description: undefined,
        }));
    },
    [],
  );

  const handleChangeViewable = useCallback((value: boolean) => {
    setViewable(value);
    setTouched(true);
  }, []);

  const handleLocationChange = useCallback(
    (result: Location | null) => {
      setLocation(result);
      setTouched(true);
      errors.location &&
        setErrors((prev) => ({
          ...prev,
          location: undefined,
        }));
    },
    [errors],
  );

  const [removeApplianceOpen, setRemoveApplianceOpen] = useState(false);

  const onSubmitUpdate = (event?: FormEvent<HTMLFormElement>) => {
    event?.stopPropagation();
    event?.preventDefault();

    const errors = {
      description: !description ? 'Description is required' : undefined,
      ipv4: !ipv4 || isIpv4(ipv4 || '') ? undefined : 'Invalid IP Address',
      location:
        viewable && !location
          ? 'Location required when appliance is viewable on map'
          : undefined,
    };
    setErrors(errors);

    if (Object.values(errors).filter(Boolean).length) return;

    // Submit update to backend
    const newLocation =
      viewable && location
        ? {
            country: location.country,
            city: location.city,
            latitude: location.latitude,
            longitude: location.longitude,
          }
        : undefined;
    data &&
      onUpdate?.({
        applianceId: data?.applianceId,
        description,
        ipv4,
        location: newLocation,
      });

    setTouched(false);
  };

  const [deleteAppliance, deleteApplianceQuery] = useMutation<
    DeleteApplianceMutation,
    DeleteApplianceMutationVariables
  >(DELETE_APPLIANCE, {
    refetchQueries: [GET_INVENTORY],
    onCompleted: (result) => {
      result?.deleteAppliance && onDeleted?.();
      result?.deleteAppliance &&
        toast.success(`'${data?.description}' Appliance succesfully removed`);
    },
    update(cache, updateData) {
      updateData.data?.deleteAppliance &&
        cache.modify({
          fields: {
            appliances(existing = []) {
              return existing.filter(
                (e: { id?: string; __ref?: string }) =>
                  e.id !== data?.id && e.__ref !== `Appliance:${data?.id}`,
              );
            },
          },
        });
    },
  });

  const onRemoveAppliance = () => {
    // remove appliance
    onDeleteConfirm?.(data);
    data?.id &&
      deleteAppliance({
        variables: {
          id: data?.id,
        },
      });
  };

  return (
    <div
      data-testid="edit-appliance-form"
      className="w-96 items-center justify-start overflow-visible p-[2px] text-left text-xs"
    >
      {removeApplianceOpen ? (
        <div className="flex flex-col gap-4">
          <p data-testid="delete-confirm-message">{`Are you sure you would like to remove "${data?.description}"? You cannot undo this action.`}</p>
          {!deleteApplianceQuery.data?.deleteAppliance && (
            <div className="flex flex-row items-center justify-end gap-2">
              <SimpleButton
                data-testid="delete-confirm-button"
                loading={deleteApplianceQuery.loading}
                className="w-24 px-4"
                variant="critical"
                onClick={onRemoveAppliance}
                disabled={
                  loading ||
                  deleteApplianceQuery.loading ||
                  deleteApplianceQuery.data?.deleteAppliance
                }
              >
                Remove
              </SimpleButton>
              <SimpleButton
                data-testid="delete-cancel-button"
                className="w-24 px-4"
                variant="soft"
                onClick={() => {
                  onDeleteCancel?.();
                  setRemoveApplianceOpen(false);
                }}
                disabled={
                  loading ||
                  deleteApplianceQuery.loading ||
                  deleteApplianceQuery.data?.deleteAppliance
                }
              >
                Cancel
              </SimpleButton>
            </div>
          )}
        </div>
      ) : (
        <form onSubmit={onSubmitUpdate} className="flex flex-col gap-4">
          <div className="gap-0">
            <InputText
              data-testid="appliance-name"
              name="appliance-name"
              label="Name"
              value={description}
              onChange={handleDescriptionChange}
              error={errors.description}
            />
          </div>
          <div className="gap-0">
            <InputText
              data-testid="appliance-ip-address"
              name="appliance-ip-address"
              label="IP Address"
              value={ipv4 || ''}
              onChange={handleIpv4Change}
              error={errors.ipv4}
            />
          </div>
          <Switch
            data-testid="location-switch"
            checked={viewable}
            onChange={handleChangeViewable}
            labelPosition="right"
          >
            <span className="text-xs">Device viewable on map</span>
          </Switch>
          <Transition
            show={viewable}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <div data-testid="location-select">
              <ResolvedLocationSelect
                data-testid="appliance-location-select"
                onResolved={handleLocationChange}
                value={location}
                label="Location"
                error={errors.location}
              />
            </div>
          </Transition>
          <div className="flex flex-row gap-4">
            {error && (
              <Message data-testid="appliance-error-message" type="error">
                <span className="text-xs">
                  Error updating Appliance: {error || 'Unexpected error'}
                </span>
              </Message>
            )}
            <div className="ml-auto flex h-min shrink gap-4">
              <SimpleButton
                data-testid="appliance-remove-button"
                type="button"
                className="w-24 px-4"
                disabled={loading || !data}
                onClick={() => {
                  data && onDeleteClick?.(data);
                  setRemoveApplianceOpen(true);
                }}
                variant="critical"
              >
                Remove
              </SimpleButton>
              <SimpleButton
                data-testid="appliance-update-button"
                type="submit"
                className="w-24 px-4"
                disabled={loading || !touched}
                loading={loading}
              >
                Update
              </SimpleButton>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditApplianceModal;
