import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import ReactSelect, { components } from 'react-select';
import {
  ActionMeta,
  ClassNamesConfig,
  ControlProps,
  GroupBase,
  SingleValue,
} from 'react-select/dist/declarations/src';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { twMerge } from 'tailwind-merge';
import {
  FIND_LOCATION_CANDIDATES,
  RESOLVE_LOCATION_CANDIDATE,
} from '../../api/location/queries';
import { Icon, IconName } from '../../assets/icons/Icon';
import {
  FindLocationCandidatesQuery,
  FindLocationCandidatesQueryVariables,
  Location,
  LocationCandidate,
  ResolveLocationCandidateQuery,
  ResolveLocationCandidateQueryVariables,
} from '../../gql/graphql';
import { useSession } from '../../utils/hooks/useSession';
import { FieldError, Label } from '../form/fields';
import { UseControlledOrUncontrolled } from '../form/hooks/UseControlledOrUncontrolled';

export interface LocationSetectProps
  extends StateManagerProps<LocationOption, false> {
  onSearchTermChange?: (term: string) => void;
  onAdd?: (location: LocationCandidate) => void;
  maxItems?: number;
  label?: string;
  error?: string;
}

interface LocationOption {
  label: string;
  value: string;
  data: LocationCandidate & {
    resolved?: ResolveLocationCandidateQuery['resolveLocationCandidate'];
  };
}

const CLASS_NAMES = (
  error?: string,
): ClassNamesConfig<LocationOption, false, GroupBase<LocationOption>> => ({
  singleValue: () => 'dark:!text-tw-main-text-dark',
  input: () => 'dark:!text-tw-main-text-dark',
  control: () =>
    twMerge(
      classNames(
        error &&
          '!ring-2 !dark:ring-tw-danger-strong-dark !ring-tw-danger-strong',
        'dark:!bg-tw-strong-shade-dark dark:!text-tw-main-text-dark !border-none !rounded-lg hover:!bg-tw-light-hover dark:hover:!bg-tw-light-hover-dark ring-1 ring-black ring-opacity-5 dark:ring-white/5',
      ),
    ),
  menu: () =>
    'dark:!bg-[#1a1c1eff] dark:!text-tw-main-text-dark !ring-1 dark:!ring-[#393939] px-2 scrollbar shadow-md',
  menuList: () => 'scrollbar',
  option: (props) =>
    classNames(
      'my-2 rounded active:dark:!bg-black/20',
      !props.isDisabled && '!cursor-pointer',
      props.isFocused && 'dark:bg-white/5',
      props.isSelected && 'dark:bg-white/5',
    ),
});

const Control = ({
  children,
  ...props
}: ControlProps<LocationOption, false>) => {
  return (
    <components.Control {...props}>
      <Icon
        iconName={IconName.LocationIcon}
        className="ml-3 h-3 w-3 text-[#8C8D8E]"
      />
      {children}
    </components.Control>
  );
};

export const getInputErrorMessage = (locationTerm: string) =>
  !locationTerm ? 'Term is required' : undefined;

export const LocationSelect = ({
  onAdd,
  onSearchTermChange,
  maxItems = 10,
  isLoading,
  label,
  error,
  ...rest
}: LocationSetectProps) => {
  const [query, setQuery] = useState<string>('');
  const [selectedLocation, setSelectedLocation] =
    useState<LocationOption | null>(null);

  const handleSearchTermChange = useCallback(
    (term: string) => {
      const termSliced = term.slice(0, 100);
      onSearchTermChange?.(termSliced);
      setQuery(termSliced);
    },
    [onSearchTermChange],
  );

  const handleAdd = useCallback(
    (location: LocationCandidate) => () => {
      onAdd?.(location);
      setQuery('');
      setSelectedLocation(null);
    },
    [onAdd],
  );

  const { skip } = useSession();

  const { data, loading: loadingCandidates } = useQuery<
    FindLocationCandidatesQuery,
    FindLocationCandidatesQueryVariables
  >(FIND_LOCATION_CANDIDATES, {
    variables: {
      search: query,
    },
    skip: skip || !query,
  });

  const candidates = useMemo(
    () => data?.findLocationCandidates.slice(0, maxItems),
    [data, maxItems],
  );

  const candidateOptions = candidates?.map(
    (candidate): LocationOption => ({
      label: candidate.label,
      value: candidate.id,
      data: candidate,
    }),
  );

  return (
    <>
      {label && <Label>{label}</Label>}
      <ReactSelect
        data-testid="location-select"
        menuPortalTarget={document.getElementById('select-portal')}
        loadingMessage={() => null}
        value={selectedLocation}
        menuPlacement="auto"
        placeholder="Enter location"
        noOptionsMessage={({ inputValue }) => {
          const error = getInputErrorMessage(inputValue);
          return (
            <div
              data-testid="no-options-message"
              className="flex flex-col text-sm"
            >
              {inputValue && !error && (
                <span>No locations found matching "{inputValue}"</span>
              )}
              {error === 'Term is required' && (
                <span data-testid="term-required">
                  Type to search locations
                </span>
              )}
            </div>
          );
        }}
        className="w-full text-left font-graphikRegular text-tw-main-text dark:text-tw-main-text-dark"
        classNames={CLASS_NAMES(error)}
        isClearable
        inputValue={query}
        onInputChange={handleSearchTermChange}
        options={candidateOptions}
        onChange={(option) => option && handleAdd(option.data)()}
        isLoading={isLoading || loadingCandidates}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          Control,
        }}
        {...rest}
      />
      {error && <FieldError error={error} />}
    </>
  );
};

export const ResolvedLocationSelect = ({
  onResolved,
  onChange,
  value,
  defaultValue,
  isLoading,
  isDisabled,
  ...rest
}: Omit<LocationSetectProps, 'value' | 'defaultValue'> & {
  value?: Location | null;
  defaultValue?: Location | null;
  onResolved?: (result: Location | null) => void;
}) => {
  const [candidateValue, setCandidateValue] =
    useState<SingleValue<LocationOption>>();

  const { value: resolvedValue, setValue: setResolvedValue } =
    UseControlledOrUncontrolled({
      value,
      defaultValue,
      defaultInternalValue: null,
    });

  const resolveQuery = useQuery<
    ResolveLocationCandidateQuery,
    ResolveLocationCandidateQueryVariables
  >(RESOLVE_LOCATION_CANDIDATE, {
    variables: {
      candidateId: candidateValue?.data.id || '',
    },
    onCompleted: (data) => {
      setResolvedValue(data.resolveLocationCandidate);
      onResolved?.(data.resolveLocationCandidate);
    },
    skip: !candidateValue?.data.id,
  });

  const mergedValue: SingleValue<LocationOption> | undefined = resolvedValue
    ? {
        data: {
          id: '',
          label: '',
          resolved: resolvedValue,
        },
        value: '',
        ...candidateValue,
        label:
          [resolvedValue.city, resolvedValue.country]
            .filter(Boolean)
            .join(', ') ||
          candidateValue?.label ||
          '',
      }
    : candidateValue;

  const handleChange = useCallback(
    (
      newValue: SingleValue<LocationOption>,
      actionMeta: ActionMeta<LocationOption>,
    ) => {
      setCandidateValue(newValue);
      // handle clear
      if (actionMeta.action === 'clear') {
        setResolvedValue(null);
        onResolved?.(null);
      }
      onChange?.(newValue, actionMeta);
    },
    [setResolvedValue],
  );

  return (
    <>
      <LocationSelect
        onChange={handleChange}
        value={mergedValue}
        isDisabled={isDisabled || resolveQuery.loading}
        isLoading={isLoading || resolveQuery.loading}
        {...rest}
      />
    </>
  );
};
