import classNames from 'classnames';
import { isEmpty } from 'ramda';
import { Icon, IconName } from '../../../assets/icons/Icon';
import { AccordionItem } from '../AccordionMenu/AccordionMenu';
import {
  FormFieldElement,
  FormFieldType,
  FormFieldValue,
  FormFields,
  FormGroup,
  FormInputNumber,
  FormList,
  FormMultiSelect,
  FormText,
  FormTextArea,
  MinMaxValue,
} from './types';

export const updateFormFieldsValue = (
  formData: FormFields,
  id: string,
  value: any,
): FormFields => {
  return formData.map((field) => {
    if (field.id === id) {
      typeof field.value === 'boolean' && (value = !field.value);
      return {
        ...field,
        value,
        touched: true,
      };
    }
    if (field.type === FormFieldType.GROUP) {
      return {
        ...field,
        fields: updateFormFieldsValue(field.fields, id, value),
      };
    }
    return field;
  });
};
export const updateFormFieldsValueByPath = (
  formData: FormFields,
  id: string,
  pathArray: string[] = [],
  value: FormFieldValue,
): FormFields => {
  return formData.map((field) => {
    if (field.path === pathArray) {
      return {
        ...field,
        value: value as any,
        touched: true,
      };
    }
    return field.type === FormFieldType.GROUP
      ? {
          ...field,
          ...(field.type === FormFieldType.GROUP && {
            fields: updateFormFieldsValueByPath(
              field.fields,
              id,
              pathArray,
              value,
            ),
          }),
        }
      : field;
  });
};

export const filterFormFieldsByText = (
  formFields: FormFields,
  searchTerm = '',
): FormFields => {
  if (!searchTerm) {
    return formFields;
  }
  return formFields
    .filter((field) => {
      const label = field.title.toLowerCase();
      const description = field.description?.toLowerCase();
      return (
        label.includes(searchTerm.toLowerCase()) ||
        description?.includes(searchTerm.toLowerCase()) ||
        (field.type === FormFieldType.GROUP &&
          filterFormFieldsByText(field.fields, searchTerm).length > 0)
      );
    })
    .map((field) => {
      return {
        ...field,
        ...(field.type === FormFieldType.GROUP && {
          fields: filterFormFieldsByText(field.fields, searchTerm),
        }),
      };
    });
};
export const getMinMaxValue = (value?: MinMaxValue): number | undefined => {
  if (!value) {
    return value;
  }
  if (typeof value === 'number') {
    return value;
  }
  if (typeof value === 'string') {
    return parseInt(value, 10);
  }
  if (typeof value === 'object') {
    return getMinMaxValue(value.value);
  }
  return undefined;
};

export const validateMultiSelectField = (
  field: FormMultiSelect,
): string | undefined => {
  const min = getMinMaxValue(field.minItems);
  const max = getMinMaxValue(field.maxItems);
  if (min && field.value && field.value.length < min) {
    const message =
      typeof field.minItems !== 'number'
        ? field.minItems?.message
        : `Select at least ${min} options`;
    return message;
  }
  if (max && field.value && field.value.length > max) {
    const message =
      typeof field.maxItems !== 'number'
        ? field.maxItems?.message
        : `Select at most ${max} options`;
    return message;
  }
  return undefined;
};

export const validateNumberField = (
  field: FormInputNumber,
): string | undefined => {
  const hasValue = field.value || field.value === 0;
  if (field.required && !hasValue) {
    return typeof field.required === 'string'
      ? field.required
      : 'This field is required';
  }
  const min = getMinMaxValue(field.min);
  const max = getMinMaxValue(field.max);
  if (
    (field.value || field.value === 0) &&
    (min || min === 0) &&
    field.value < min
  ) {
    const message =
      typeof field.min !== 'number'
        ? field.min?.message
        : `Value must be greater than ${min}`;
    return message;
  }
  if (
    (field.value || field.value === 0) &&
    (max || max === 0) &&
    field.value > max
  ) {
    const message =
      typeof field.max !== 'number'
        ? field.max?.message
        : `Value must be less than ${max}`;
    return message;
  }
  if (
    field.value &&
    field.format === 'integer' &&
    !Number.isInteger(+field.value)
  ) {
    return 'Value must be an integer';
  }
  if (field.value && field.multipleOf && field.value % field.multipleOf !== 0) {
    return `Value must be a multiple of ${field.multipleOf}`;
  }
  return undefined;
};
const validateInputField = (
  field: FormText | FormTextArea,
): string | undefined => {
  const minLength = getMinMaxValue(field.minLength);
  const maxLength = getMinMaxValue(field.maxLength);
  const value = field.value ?? '';
  if (
    (value && minLength && value.length < minLength) ||
    (!value && minLength)
  ) {
    const message =
      typeof field.minLength !== 'number'
        ? field.minLength?.message
        : `Value must be at least ${minLength} characters`;
    return message;
  }
  if (value && maxLength && value.length > maxLength) {
    const message =
      typeof field.maxLength !== 'number'
        ? field.maxLength?.message
        : `Value must be less than ${maxLength} characters`;
    return message;
  }
  if (value && field.pattern && !new RegExp(field.pattern).test(value)) {
    return 'Value does not match pattern';
  }
  return undefined;
};

export const validateListField = (field: FormList): string | undefined => {
  const { minItems, maxItems } = field;
  if ((minItems || minItems === 0) && (field.value?.length || 0) < minItems) {
    return `Value must have at least ${minItems} items`;
  }
  if (maxItems && (field.value?.length || 0) > maxItems) {
    return `Value must have at most ${maxItems} items`;
  }
  return undefined;
};

export const validateFields = (
  formFields: FormFields,
): { isValid: boolean; validatedFormFields: FormFields } => {
  const validatedFormFields = formFields.map((field) => {
    if (field.type === FormFieldType.GROUP) {
      const { isValid, validatedFormFields } = validateFields(field.fields);
      return {
        ...field,
        error: isValid ? '' : 'Contains invalid fields',
        fields: validatedFormFields,
      };
    }
    return {
      ...field,
      error:
        (field.required &&
        (!field.value ||
          isEmpty(field.value) ||
          // TODO change checkbox onChange to return boolean
          field.value === 'false')
          ? typeof field.required === 'string'
            ? field.required
            : 'This field is required'
          : '') ||
        (field.type === FormFieldType.INPUT_NUMBER &&
          validateNumberField(field)) ||
        ((field.type === FormFieldType.TEXT ||
          field.type === FormFieldType.TEXT_AREA) &&
          validateInputField(field)) ||
        (field.type === FormFieldType.MULTI_SELECT &&
          validateMultiSelectField(field)) ||
        (field.type === FormFieldType.LIST && validateListField(field)) ||
        '',
    };
  });
  return {
    isValid: validatedFormFields.every((field) => !field.error),
    validatedFormFields,
  };
};
export const findFormFieldsById = (
  formFields: FormFields,
  id?: string,
): FormFields => {
  if (!id) {
    return formFields;
  }
  const fields = formFields.filter((field) => field.id === id);
  if (fields.length > 0) {
    return fields;
  }
  for (const field of formFields) {
    if (field.type === FormFieldType.GROUP) {
      const fields = findFormFieldsById(field.fields, id);
      if (fields.length > 0) {
        return fields;
      }
    }
  }
  return [];
};

export const fieldOrDescendantHasError = (
  formFieldElement: FormFieldElement,
): boolean => {
  if (formFieldElement.error) {
    return true;
  }
  if (formFieldElement.type === FormFieldType.GROUP) {
    return formFieldElement.fields.some((field) =>
      fieldOrDescendantHasError(field),
    );
  }
  return false;
};

export const formFieldsToNav = (
  formFields: FormFields,
  level = 0,
): AccordionItem<FormFields>[] => {
  return (
    formFields.filter(
      (field) => field.type === FormFieldType.GROUP,
    ) as FormGroup[]
  ).map((field) => ({
    id: field.id,
    label: (
      <div className="flex items-center">
        {field.iconName ? (
          <Icon iconName={field.iconName} className="-ml-1 mr-1 h-6 w-6 p-1" />
        ) : level === 0 ? (
          <Icon
            iconName={IconName.SettingsIcon}
            className="-ml-1 mr-1 h-6 w-6 p-1"
          />
        ) : null}
        <span className="block truncate">{field.title}</span>
        {field.error && (
          <div
            className={classNames(
              'ml-2 rounded-full px-1 text-white',
              'flex h-4 w-4 items-center justify-center text-center font-graphikRegular text-xxs',
              'bg-tw-danger-color dark:bg-tw-danger-color-dark',
            )}
          >
            {field.error.length}
          </div>
        )}
      </div>
    ),
    children: formFieldsToNav(field.fields, level + 1),
    value: [field],
  }));
};
