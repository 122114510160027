import { Identity, Session } from '@ory/kratos-client';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { equals } from 'ramda';
import { Location } from 'react-router-dom';
import { UserPreferences } from '../../api/userPreferences/types';

export enum Role {
  ORG_USER = 'ORG_USER',
  ORG_ADMIN = 'ORG_ADMIN',
  PL_ADMIN = 'PL_ADMIN',
}

export interface TenantInfo {
  roles: Role[];
  tenantId: string;
}
export interface IdentityWithRole extends Identity {
  metadata_public?: {
    tenants_info?: TenantInfo[];
  };
}
export interface SessionWithRole extends Session {
  identity?: IdentityWithRole;
}
export interface LoginState {
  //Maybe this should be UserState?
  id: number | null; //string?
  email: string | null;
  company: string | null;
  token: string | null;
  logoutToken: string | null;
  session: SessionWithRole | null;
  currentTenant: TenantInfo | null;
  role: string | null;
  flowFulfilled: boolean;
  userPreferences: UserPreferences | null;
  isLoginOut: boolean;
  landingPage?: Location;
}

const defaultTenantInfo: TenantInfo[] | undefined =
  (process.env.REACT_APP_TENANT_IDS &&
    process.env.REACT_APP_TENANT_IDS?.replace(/ /g, '')
      .split(',')
      .map(
        (tenantId): TenantInfo => ({
          roles: (process.env.REACT_APP_ROLES?.replace(/ /g, '').split(
            ',',
          ) as Role[]) || [Role.ORG_USER, Role.ORG_ADMIN],
          tenantId,
        }),
      )) ||
  undefined;

const defaultSession: SessionWithRole | null =
  process.env.REACT_APP_USER_AUTH === 'true'
    ? {
        id: 'session-id',
        identity: {
          id: 'identity-id',
          schema_url: 'schema-url',
          schema_id: 'schema-id',
          traits: {
            role: Role.ORG_USER,
            tenants: ['demo'],
            email: 'admin@superna',
            name: 'admin',
            surname: 'superna',
          },
          metadata_public: {
            tenants_info: defaultTenantInfo,
          },
        },
      }
    : null;

export const initialState: LoginState = {
  id: null,
  email: null,
  company: null,
  token: null,
  logoutToken: null,
  session: defaultSession,
  flowFulfilled: false,
  userPreferences: null,
  currentTenant: null,
  role: null,
  isLoginOut: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    updateLogin(state, action: PayloadAction<Partial<LoginState>>) {
      const tenants_info =
        defaultTenantInfo ||
        action?.payload?.session?.identity?.metadata_public?.tenants_info ||
        [];

      const session = defaultSession || action.payload.session;

      Sentry.setTag('tenant', tenants_info[0]?.tenantId);
      action?.payload?.session &&
        Sentry.setUser({
          id: action?.payload?.session?.identity?.id,
        });

      return {
        ...state,
        ...action.payload,
        ...(session && {
          session: {
            ...session,
            ...(session.identity && {
              identity: {
                ...session.identity,
                metadata_public: {
                  ...session.identity?.metadata_public,
                  tenants_info,
                },
              },
            }),
          },
        }),
        ...(session && {
          currentTenant: tenants_info[0],
        }),
      };
    },
    updateUserPreferences(state, action: PayloadAction<UserPreferences>) {
      return { ...state, userPreferences: action.payload };
    },
    updateTenant(state, action: PayloadAction<TenantInfo>) {
      Sentry.setTag('tenant', action.payload?.tenantId);
      return {
        ...state,
        currentTenant: action.payload,
      };
    },
    resetLogin() {
      return initialState;
    },
    updateLandingPage(state, action: PayloadAction<Location>): LoginState {
      return !equals(action.payload, state.landingPage)
        ? { ...state, landingPage: action.payload }
        : state;
    },
  },
});

export const {
  updateLogin,
  updateUserPreferences,
  updateTenant,
  resetLogin,
  updateLandingPage,
} = loginSlice.actions;
export default loginSlice.reducer;
