import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { toggleSidebarCollapse } from '../../../redux/sidebar/sidebarCollapseSlice';
import { SimpleButton } from '../../Buttons/SimpleButton';

export function SidebarCollapse() {
  const dispatch = useAppDispatch();
  const sidebarCollapsed = useAppSelector(
    (state) => state.sidebarCollapse.collapsed,
  );

  return (
    <SimpleButton
      variant="default-ghost"
      className="m-0 flex items-center justify-start gap-2 truncate rounded-[4px] bg-transparent p-2 py-1 transition-colors hover:bg-tw-strong-shade dark:hover:bg-[#2D3036]"
      data-testid="sidebar-colapse-button"
      aria-label="sidebar collapse"
      onClick={() => dispatch(toggleSidebarCollapse())}
    >
      <div
        className={classNames(
          'flex h-[30px] w-[30px] items-center justify-center',
          sidebarCollapsed && 'm-auto',
        )}
      >
        <FontAwesomeIcon
          className={classNames(
            'h-4 w-4 transform duration-300 ease-in-out',
            sidebarCollapsed && ' -rotate-180',
          )}
          icon={faAngleLeft}
        />
      </div>
      {!sidebarCollapsed && <span>Collapse</span>}
    </SimpleButton>
  );
}
