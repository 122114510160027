import { CrossIcon } from '../../../assets';
import DeleteIcon from '../../../assets/icons/DeleteIcon';
import { GetUsersQuery } from '../../../gql/graphql';

interface IProps {
  member: GetUsersQuery['users'][0];
  onDelete: (member: GetUsersQuery['users'][0]) => void;
  onClose: () => void;
}

const DeleteUserModal = ({ member, onDelete, onClose }: IProps) => {
  return (
    <div className="flex flex-col items-center justify-between gap-5 px-5">
      <button onClick={onClose} className="absolute right-5 top-5">
        <CrossIcon className="h-6 w-6" color="#5D6D7A" />
      </button>
      <div className="flex h-20 w-20 items-center justify-center rounded-xl bg-[#F8F6F6] dark:bg-[#161A1F]">
        <DeleteIcon width={35} height={35} color="red" />
      </div>
      <div className="flex w-[350px] flex-col gap-2">
        <p className="my-2 font-graphikSemi text-3xl">Delete User</p>
        <p className="flex items-center justify-center font-graphikRegular text-xl leading-7">
          Are you sure you would like to delete "{member.name}"?
        </p>
        <p className="mt-2 text-[#5D6D7A] dark:text-[#D8D8D8]">
          You cannot undo this action.
        </p>
      </div>
      <div className="flex w-full justify-center gap-4 pt-7 text-xl ">
        <button
          data-testid="cancel-delete-user"
          className="rounded-lg border border-[#5D6D7A] px-16 py-2 font-graphikMedium text-[#5D6D7A] hover:bg-transparent/5 dark:border-[#BACCD9] dark:text-[#BACCD9]"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          data-testid="confirm-delete-user"
          className="rounded-lg bg-tw-critical-color px-16 py-2 font-graphikMedium text-white  hover:bg-tw-critical-color/90"
          onClick={() => onDelete(member)}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DeleteUserModal;
