import ChevronDownIcon from '../../assets/icons/ChevronDownIcon';
import { useAppSelector } from '../../redux/hooks';
import React, { Dispatch, SetStateAction, useState } from 'react';

const times = {
  day: Number(Date.now()) - 86400 * 1000,
  week: Number(Date.now()) - 604800 * 1000,
  month: Number(Date.now()) - 2629743 * 1000,
};

export function TimePicker({
  setFromDate,
}: {
  setFromDate: Dispatch<SetStateAction<number>>;
}) {
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const theme = useAppSelector((state) => state.theme.value);
  const [actualFilter, setActualFilter] = useState<string>('Past 7 Days');
  return (
    <div className="relative inline-block text-left ">
      <div>
        <button
          className={`inline-flex w-full items-center justify-between rounded-lg py-1 pl-8 pr-2 text-sm 
            ${
              theme === 'light'
                ? 'border-blue-200 bg-white text-tw-main-text hover:bg-gray-50'
                : 'border-gray-300 bg-tw-dark-shade-dark text-white hover:bg-gray-800'
            }
            ${!showFilters && 'rounded-none rounded-t-md'}`}
          onClick={() => setShowFilters((p) => !p)}
        >
          {actualFilter}
          <ChevronDownIcon
            className={`-mr-1 ml-2 h-7 w-7 transition-all duration-300 ${
              showFilters ? 'rotate-0' : 'rotate-180'
            }`}
          />
        </button>
      </div>

      <div
        className={`absolute right-0 w-full rounded-b-lg bg-white focus:outline-none ${
          showFilters && 'hidden'
        } ${
          theme === 'light'
            ? 'bg-white text-tw-main-text'
            : 'bg-tw-dark-shade-dark text-white'
        }`}
      >
        <button
          className={`m-3 block w-11/12 rounded-md px-1 py-2 text-left text-sm ${
            theme === 'light' ? 'hover:bg-gray-50' : 'hover:bg-gray-800'
          }`}
          onClick={() => {
            setShowFilters((p) => !p);
            setActualFilter('Past Day');
            setFromDate(times.day);
          }}
        >
          Past Day
        </button>
        <button
          className={`m-3 block w-11/12 rounded-md px-1 py-2 text-left text-sm ${
            theme === 'light' ? 'hover:bg-gray-50' : 'hover:bg-gray-800'
          }`}
          onClick={() => {
            setShowFilters((p) => !p);
            setActualFilter('Past 7 Days');
            setFromDate(times.week);
          }}
        >
          Past 7 Days
        </button>
        <button
          className={`m-3 block w-11/12 rounded-md px-1 py-2 text-left text-sm ${
            theme === 'light' ? 'hover:bg-gray-50' : 'hover:bg-gray-800'
          }`}
          onClick={() => {
            setShowFilters((p) => !p);
            setActualFilter('Past 30 Days');
            setFromDate(times.month);
          }}
        >
          Past 30 Days
        </button>
      </div>
    </div>
  );
}
