import React from 'react';

export const ChevronRightIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M3.16998 4.99998L0.70998 7.45998C0.31998 7.84998 0.31998 8.47998 0.70998 8.86998C1.09998 9.25998 1.72998 9.25998 2.11998 8.86998L5.29998 5.69998C5.68998 5.30998 5.68998 4.67998 5.29998 4.28997L2.12998 1.11998C1.73998 0.729976 1.10998 0.729976 0.71998 1.11998C0.32998 1.50998 0.32998 2.13998 0.71998 2.52998L3.16998 4.99998Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronRightIcon;
