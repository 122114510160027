import { DependencyList, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToAnchor = (dependencies: DependencyList = []) => {
  const location = useLocation();
  const [lastHash, setLastHash] = useState('');

  useEffect(() => {
    const elementId = location.hash && location.hash.slice(1);
    elementId && setLastHash(elementId);

    if (elementId && document.getElementById(elementId)) {
      setTimeout(() => {
        document
          .getElementById(elementId)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    }
  }, [location.hash, ...dependencies]);

  return lastHash;
};
