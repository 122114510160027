import { useSearchParams } from 'react-router-dom';
import { SingleFormLayout } from '../../components/Layout/SingleFormLayout';
import {
  ProtectedComponent,
  UnauthorizedMessage,
} from '../../components/common/ProtectedComponent';
import { Role } from '../../redux/login/loginSlice';
import { ApplianceRegistrationForm } from './ApplianceRegistrationForm';

const ID_PARAM = 'applianceId';
const DESCRIPTION_PARAM = 'description';
const PUBLIC_KEY_PARAM = 'publicKey';
const APPLICATIONS_PARAM = 'application';

export const ApplianceRegistration = () => {
  const [searchParams] = useSearchParams();
  const applianceId = searchParams.get(ID_PARAM);
  const description = searchParams.get(DESCRIPTION_PARAM);
  const publicKey = searchParams.get(PUBLIC_KEY_PARAM);
  const applications = searchParams.getAll(APPLICATIONS_PARAM);

  return (
    <SingleFormLayout>
      <ProtectedComponent
        message="You must be logged in as administrator to view this page."
        requiredRole={[Role.ORG_ADMIN, Role.PL_ADMIN]}
        unauthorizedMessage={
          <UnauthorizedMessage message="User must be administrator to register appliances." />
        }
      >
        {applianceId && publicKey && publicKey && applications ? (
          <ApplianceRegistrationForm
            appliance={{
              applianceId,
              description,
              publicKey,
              products: applications,
            }}
            onSubmit={console.log}
          />
        ) : (
          <div>Something went wrong</div>
        )}
      </ProtectedComponent>
    </SingleFormLayout>
  );
};
