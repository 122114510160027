import React from 'react';

export const ErrorCircleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M24 8C15.1625 8 8 15.1625 8 24C8 32.8375 15.1625 40 24 40C32.8375 40 40 32.8375 40 24C40 15.1625 32.8375 8 24 8ZM22.5 17.5C22.5 16.675 23.175 16 24 16C24.825 16 25.5 16.6719 25.5 17.5V25.5C25.5 26.3281 24.8281 27 24 27C23.1719 27 22.5 26.3313 22.5 25.5V17.5ZM24 33C22.915 33 22.035 32.12 22.035 31.035C22.035 29.95 22.9144 29.07 24 29.07C25.0856 29.07 25.965 29.95 25.965 31.035C25.9625 32.1188 25.0875 33 24 33Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ErrorCircleIcon;
