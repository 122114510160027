import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { useFeature } from 'featurehub-react-sdk';
import { GET_ALERTS_COUNT } from '../../../../../api/alerts/queries';
import { ProductKind } from '../../../../../api/product/types';
import {
  AlertType,
  GetAlertsCountQuery,
  GetAlertsCountQueryVariables,
  GetInventoryQuery,
} from '../../../../../gql/graphql';
import { useAppSelector } from '../../../../../redux/hooks';
import { FeatureFlag } from '../../../../../utils/featureHub/constants';
import { useSession } from '../../../../../utils/hooks/useSession';
import { rangeIdToValue } from '../../../../CalenderRangePicker/utils';
import { ProductIcon } from '../../../../Elements/Icons/ProductIcons/ProductIcon';
import { WithStatusBadge } from '../WithStatusBadge';
import { Expiration } from './Expiration';
import { LicensedCapacity } from './LicensedCapacity';
import { LicensedCluster } from './LicensedCluster';

export const InstalledProduct = ({
  product,
  applianceId,
}: {
  product: GetInventoryQuery['appliances'][0]['installedProducts'][0];
  applianceId: string;
}) => {
  const { skip } = useSession();

  const { duration } = useAppSelector((state) => state.productFilter);
  const alertsCountQuery = useQuery<
    GetAlertsCountQuery,
    GetAlertsCountQueryVariables
  >(GET_ALERTS_COUNT, {
    variables: {
      from: rangeIdToValue(duration)?.from?.toISOString(),
      productKind: [product.product.kind],
      appliance: applianceId,
      type: [AlertType.Health],
    },
    skip: skip || !applianceId,
  });

  const licensesFeature = useFeature(FeatureFlag.INVENTORY_LICENSES);

  return (
    <div
      key={product.id}
      className={classNames(
        'flex flex-col items-start justify-between gap-4 rounded-lg border border-tw-border p-3 font-graphikMedium text-sm text-tw-label-primary dark:border-tw-border-dark  dark:text-tw-label-primary-dark',
        '',
      )}
      data-testid="appliance-card-product"
    >
      <div
        data-testid="appliance-card-product-header"
        className="col-span-3 flex w-full flex-row items-center gap-2"
      >
        {/* TODO check product status */}
        <WithStatusBadge
          loading={alertsCountQuery?.loading}
          type={
            alertsCountQuery?.data?.CRITICAL.totalItems
              ? 'critical'
              : alertsCountQuery?.data?.ERROR.totalItems ||
                alertsCountQuery?.data?.STANDARD.totalItems
              ? 'warning'
              : 'success'
          }
          tooltip={
            alertsCountQuery?.data?.CRITICAL.totalItems
              ? `${alertsCountQuery?.data?.CRITICAL.totalItems} critical alerts`
              : alertsCountQuery?.data?.ERROR.totalItems ||
                alertsCountQuery?.data?.STANDARD.totalItems
              ? `${
                  (alertsCountQuery?.data?.ERROR.totalItems || 0) +
                  (alertsCountQuery?.data?.STANDARD.totalItems || 0)
                } alerts`
              : 'Healthy'
          }
        >
          <ProductIcon
            key={product.id}
            productKind={product.product.kind as ProductKind}
            className="h-9 w-9 overflow-hidden rounded-lg"
          />
        </WithStatusBadge>
        <div className="truncate">
          <p
            className="truncate font-graphikMedium text-sm text-tw-label-primary dark:text-tw-label-primary-dark"
            data-testid="appliance-card-product-name"
          >
            {product.product.name}
          </p>
          <p
            className="truncate  font-graphikRegular text-xs text-tw-label-secondary dark:text-tw-label-secondary-dark"
            data-testid="appliance-card-product-version"
          >
            {product.softwareVersion || '-'}
          </p>
        </div>
      </div>
      {licensesFeature && (
        <div className="w-full font-graphikRegular text-xs">
          <p
            className="col-span-2 truncate"
            data-testid="appliance-card-product-licensed-capacity"
          >
            <LicensedCapacity product={product} />
          </p>
          <p
            className="col-span-2 truncate"
            data-testid="appliance-card-product-licensed-cluster"
          >
            <LicensedCluster product={product} />
          </p>
          <p
            className="col-span-2 truncate"
            data-testid="appliance-card-product-license-expiration"
          >
            <Expiration product={product} />
          </p>
        </div>
      )}
    </div>
  );
};
