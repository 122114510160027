import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { Icon, IconName } from '../../../../assets/icons/Icon';
import { lazyImport } from '../../../../utils/lazyImport';
import { SimpleButton } from '../../../Buttons/SimpleButton';
import Spinner from '../../../Elements/Spinner/Spinner';
import { FormFieldElement, FormFieldValue } from '../../DynamicForm';
import { FieldError } from '../Common/FieldError';
import { InputText } from '../InputText';
import SearchInput from '../SearchInput/SearchInput';
import { Select } from '../pickers';
import { getBaseTextAndBgClassNames } from '../utils';

// Prevent circular dependency
const { FormElement } = lazyImport(
  () => import('../../DynamicForm'),
  'FormElement',
);
const { FormField } = lazyImport(
  () => import('../../DynamicForm'),
  'FormField',
);

const roles = [
  { label: 'User', value: 1 },
  { label: 'Admin', value: 2 },
  { label: 'SuperAdmin', value: 3 },
];
const group = [
  { label: 'No group', value: 1 },
  { label: 'Group 1', value: 2 },
  { label: 'Group 2', value: 3 },
];

export const MembersList = ({
  className,
  defaultValue,
  maxItems,
  minItems,
  placeholder = 'New item',
  inputLabel,
  value,
  disabled,
  uniqueItems,
  onItemDelete,
  onItemAdd,
  onChange,
  error,
  formFieldElement,
}: {
  className?: string;
  defaultValue?: FormFieldValue[];
  maxItems?: number;
  minItems?: number;
  placeholder?: string;
  inputLabel?: string;
  value?: FormFieldValue[];
  disabled?: boolean;
  uniqueItems?: boolean;
  onItemDelete?: (item: FormFieldValue) => void;
  onItemAdd?: (item: FormFieldValue) => void;
  onChange?: (items: FormFieldValue[]) => void;
  error?: string | string[];
  formFieldElement?: (index: number) => FormFieldElement | null;
}) => {
  const [valueItems, setValueItems] = useState(value || defaultValue || []);
  const [popupIsOpen, setPopupIsOpen] = useState(false);

  useEffect(() => {
    setValueItems(value || defaultValue || []);
  }, [value, defaultValue]);

  const handleDelete = (item: FormFieldValue, index: number) => {
    setValueItems((prev) => {
      const nextItems = !uniqueItems
        ? prev.filter((_, i) => i !== index)
        : prev.filter((i) => i !== item);
      onItemDelete && onItemDelete(item);
      onChange && onChange(nextItems);
      return nextItems;
    });
  };

  const handleAdd = (item: FormFieldValue) => {
    setValueItems((prev) => {
      const nextItems = [...prev, item];
      onItemAdd && onItemAdd(item);
      onChange && onChange(nextItems);
      return nextItems;
    });
  };

  const isAddDisabled = useMemo(() => {
    return Boolean(disabled || (maxItems && valueItems.length >= maxItems));
  }, [disabled, maxItems, valueItems]);

  return (
    <div
      className={classNames(
        getBaseTextAndBgClassNames(),
        'rounded-lg bg-tw-dark-shade p-2 py-4 dark:bg-tw-dark-shade-dark',
        className,
      )}
    >
      <div className="mb-2 flex w-full gap-3">
        <SearchInput
          placeholder="Search users..."
          inputProps={{
            name: 'search-team-member',
          }}
          onChange={() => console.log('dev')}
        />
        <SimpleButton
          disabled={isAddDisabled}
          variant="primary"
          onClick={() => setPopupIsOpen(true)}
          className="flex items-center gap-2 px-6"
          aria-label="Open add popup"
        >
          <Icon iconName={IconName.TeamIcon} className="-mt-[0.2rem] h-4 w-4" />
          <span className="whitespace-nowrap">Add User</span>
        </SimpleButton>
      </div>
      <ul
        aria-label="popupList"
        className="flex flex-col items-center justify-center gap-2 p-1"
      >
        {valueItems.length === 0 && <>There's not any user yet...</>}
        {valueItems.map((item, index) => {
          const indexedField = formFieldElement && formFieldElement(index);
          const ItemRender =
            indexedField?.type === 'group' || indexedField?.type === 'list'
              ? FormElement
              : FormField;
          return (
            <li
              key={indexedField?.id || `item-${index}`}
              aria-label="popupList-item"
              className={classNames(
                'flex w-full items-center justify-between',
                'relative rounded-md',
              )}
            >
              {indexedField ? (
                <Suspense fallback={<Spinner />}>
                  <div
                    data-testid={`suspense-item-${index}`}
                    className={classNames(
                      'flex-1',
                      indexedField?.type === 'group' &&
                        'rounded-md bg-tw-dark-shade p-4 dark:bg-tw-dark-shade-dark',
                    )}
                  >
                    <ItemRender
                      field={{
                        ...indexedField,
                        value: item as any,
                        title: `${indexedField.title} ${index + 1}`,
                        variant: 'white',
                        path: [`${index}`],
                      }}
                      onChange={({ id, value, pathArray }) => {
                        const nextItems = valueItems.map((item, iIndex) =>
                          iIndex === index
                            ? pathArray?.length === 1
                              ? value
                              : {
                                  ...(typeof item === 'object' &&
                                  indexedField?.type === 'group'
                                    ? item
                                    : {}),
                                  [pathArray?.at(-1) || id]: value,
                                }
                            : item,
                        );
                        setValueItems(nextItems);
                        onChange && onChange(nextItems);
                      }}
                    />
                  </div>
                </Suspense>
              ) : (
                <InputText
                  key={`item-${index}`}
                  label={inputLabel}
                  value={item as string}
                  variant="white"
                  onChange={(event) => {
                    const nextItems = valueItems.map((item, iIndex) =>
                      iIndex === index ? event.currentTarget.value : item,
                    );
                    setValueItems(nextItems);
                    onChange && onChange(nextItems);
                  }}
                  placeholder={placeholder}
                />
              )}
              <div
                className={classNames(
                  'ml-2 flex items-center justify-center',
                  indexedField?.type === 'group' && 'absolute right-3 top-3',
                )}
              >
                <SimpleButton
                  variant="default-ghost"
                  onClick={() => {
                    setPopupIsOpen(true);
                  }}
                  aria-label="Move item up"
                >
                  <Icon iconName={IconName.PencilIcon} className="h-4 w-4" />
                </SimpleButton>
                <SimpleButton
                  variant="default-ghost"
                  onClick={() => handleDelete(item, index)}
                  aria-label="Delete item"
                >
                  <Icon iconName={IconName.TrashIcon} className="h-4 w-4" />
                </SimpleButton>
              </div>
            </li>
          );
        })}
      </ul>
      <Dialog
        open={popupIsOpen}
        onClose={() => setPopupIsOpen(false)}
        className="relative z-50"
      >
        <Dialog.Panel>
          <div className="fixed inset-0 bg-black/40" aria-hidden="true" />
          <div className="fixed inset-0 m-auto flex max-h-[300px] max-w-[600px] items-center justify-center rounded-lg bg-tw-light-text-dark dark:bg-tw-dark-shade-dark dark:text-tw-main-text-dark">
            <div className="flex h-full w-full flex-col">
              <div className="flex items-center justify-between border-b-[1px] border-b-tw-light-text dark:border-b-tw-inactive-color-dark">
                <span className="ml-3 font-graphikRegular text-lg">
                  Invite people
                </span>
                <SimpleButton
                  variant="default-ghost"
                  onClick={() => setPopupIsOpen(false)}
                >
                  <Icon iconName={IconName.CrossIcon} className="h-5 w-5" />
                </SimpleButton>
              </div>
              <div className="flex items-center justify-between p-3">
                <div className="flex flex-col">
                  <span className="ml-1">Email</span>
                  <InputText
                    placeholder="PabloRios@superna.net"
                    className="w-72"
                  />
                </div>
                <div className="flex flex-col">
                  <span className="ml-1">Role</span>
                  <Select options={roles} className="w-64" />
                </div>
              </div>
              <div className="mb-10 flex flex-col p-3">
                <span className="ml-1">Group</span>
                <Select options={group} />
              </div>
            </div>
            <div className="absolute bottom-2 right-2">
              <SimpleButton
                disabled={isAddDisabled}
                variant="primary"
                className="px-12 text-sm"
                onClick={() => {
                  handleAdd('Pablo Rios, admin, no group');
                  setPopupIsOpen(false);
                }}
                aria-label="Add item"
              >
                Send Invite
              </SimpleButton>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      {error && <FieldError error={error} />}
    </div>
  );
};
