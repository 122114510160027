import { Configuration, FrontendApi, IdentityApi } from '@ory/kratos-client';

const basePath = '/kratos';

export const config = new Configuration({
  basePath,
  // accessToken: process.env.ORY_API_KEY,
  baseOptions: {
    withCredentials: true,
  },
});

export const ory = {
  identity: new IdentityApi(config),
  frontend: new FrontendApi(config),
};
