import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import CheckSolidCircleIcon from '../../../assets/icons/CheckSolidCircleIcon';

export const RegistrationApprovalForm = () => {
  const { t } = useTranslation('userAccount');
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center gap-10 font-graphikRegular">
      <div
        className={`h-auto w-[28.375rem] rounded-2xl bg-white p-8 pb-6 dark:bg-tw-light-shade-dark`}
      >
        <div className="flex flex-col items-center gap-3 text-tw-main-text dark:text-tw-main-text-dark">
          <h1
            className="font-graphikBold"
            style={{ fontSize: '28px', lineHeight: '31px' }}
          >
            Successful
          </h1>
          <div className="flex flex-col items-center justify-start">
            <p>Your account creation is successfully completed!</p>
            <p>You can now use it to log in.</p>
          </div>
          <div className="fill-[#007AFF] py-5">
            <CheckSolidCircleIcon />
          </div>
          <PrimaryButton
            size="large"
            type="submit"
            className="w-full"
            onClick={() => navigate('/')}
          >
            {t('registration.goToLogin')}
          </PrimaryButton>
        </div>
      </div>
      <span className="font-graphikRegular">
        {`Already an account? `}
        <Link to={'/'} className="text-tw-main-color">
          Sign in
        </Link>
      </span>
    </div>
  );
};
