import { isEmpty } from 'ramda';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { MAIN_TOOLTIP_ID } from '../../../App';
import { Icon, IconName } from '../../../assets/icons/Icon';
import {
  GetAvailablePublicProductsQuery,
  GetInstalledProductsQuery,
} from '../../../gql/graphql';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { resetProductsFilter } from '../../../redux/products/filterSlice';
import {
  SimpleButton,
  getContainerClassNames,
} from '../../Buttons/SimpleButton';
import { AlertCountTooltip } from './components/AlertCountTooltip';
import { RoseChart } from './components/RoseChart';

export function classNames(...classes: (false | null | undefined | string)[]) {
  return classes.filter(Boolean).join(' ');
}

export const ProductStatusesCard = ({
  installedProducts,
  missingProducts,
  loading,
  className,
}: {
  installedProducts: GetInstalledProductsQuery['products'];
  missingProducts: GetAvailablePublicProductsQuery['products'];
  loading?: boolean;
  className?: string;
}) => {
  const { t } = useTranslation('notifications');

  const dispatch = useAppDispatch();
  const { products, category } = useAppSelector((state) => state.productFilter);
  const isClearFiltersDisabled = useMemo(
    () => isEmpty(products) && category === null,
    [products, category],
  );

  return (
    <div
      data-testid="product-statuses-card"
      className={twMerge(
        classNames(
          'flex flex-col justify-between rounded-lg px-7 pb-8 pt-6 align-middle',
          'border-2 border-tw-light-bg shadow-dark-bottom-20 dark:border-tw-dark-shade-dark',
          'bg-tw-dark-shade dark:bg-tw-light-shade-dark',
          className,
        ),
      )}
    >
      {/* header Info */}
      <div
        data-testid="product-statuses-card-header-info"
        className="flex flex-col gap-[5px]"
      >
        <h3 className="font-graphikMedium text-[1.25rem] leading-[1.375rem] text-tw-main-text dark:text-tw-dark-shade">
          Alerts by Capabilities
        </h3>
        <p className="font-graphikRegular text-[0.75rem] leading-[0.813rem] text-tw-description-text">
          See your suite category strength
        </p>
      </div>

      <AlertCountTooltip float />

      {/* content */}
      <div
        data-testid="product-statuses-card-content"
        className="relative flex flex-row items-center justify-center"
      >
        <RoseChart
          installedProducts={installedProducts}
          missingProducts={missingProducts}
          loading={loading}
        />
      </div>
      <div className="flex w-full justify-center">
        <Link
          data-testid="view-all-alerts"
          to="/app/dashboard/alerts"
          className={getContainerClassNames({
            variant: 'soft',
            className: 'rounded-lg p-6 py-3',
          })}
        >
          {t('notifications.view')}
        </Link>
        <SimpleButton
          data-tooltip-id={MAIN_TOOLTIP_ID}
          data-tooltip-content={
            isClearFiltersDisabled ? null : 'Clear capabilities filter'
          }
          onClick={() => dispatch(resetProductsFilter())}
          disabled={isClearFiltersDisabled}
          variant="soft"
          className="rounded-lg p-3 py-3"
        >
          <Icon iconName={IconName.ClearFilter} />
        </SimpleButton>
      </div>
    </div>
  );
};
