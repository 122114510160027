import { RecoveryForm } from './components/RecoveryForm';
import { SupernaHeader } from './components/SupernaHeader';
import { ThemeButton } from './components/ThemeButton';

export const RecoveryPage = () => {
  return (
    <div className="h-screen overflow-hidden">
      <ThemeButton />
      <div className="flex h-full flex-col items-center justify-center">
        <SupernaHeader />
        <div className="mt-14 flex h-full items-start justify-start gap-10">
          <RecoveryForm />
        </div>
      </div>
    </div>
  );
};
