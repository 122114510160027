import { useFeature } from 'featurehub-react-sdk';
import { GetInventoryQuery } from '../../../../gql/graphql';
import { FeatureFlag } from '../../../../utils/featureHub/constants';
import { Message } from '../../../common/Message';
import { SupportingNode, SupportingNodeProps } from './SupportingNode';

export const ApplianceSupportingNodes = ({
  servers,
  onClickServerDetails,
}: {
  servers?: GetInventoryQuery['appliances'][0]['servers'];
  onClickServerDetails?: SupportingNodeProps['onClickDetails'];
}) => {
  const inventoryServersFlag = useFeature(
    FeatureFlag.INVENTORY_APPLIANCE_SERVERS,
  );
  return inventoryServersFlag ? (
    <>
      <h6 className="mt-2 font-graphikMedium text-sm">Supporting Nodes</h6>
      <div
        className="grid grid-cols-2 gap-4 rounded-b-lg"
        data-testid="appliance-card-servers"
      >
        {servers?.length ? (
          servers?.map((server) => {
            return (
              <SupportingNode
                key={server.id}
                server={server}
                onClickDetails={onClickServerDetails}
              />
            );
          })
        ) : (
          <Message type="info">
            No supporting nodes found for current appliance
          </Message>
        )}
      </div>
    </>
  ) : null;
};
