import { ory } from './config';

export const logoutOrySession = async (token: string | null) =>
  ory.frontend
    .updateLogoutFlow({
      token: token || undefined,
    })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
