import React from 'react';

export const PerformanceIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M4.82549 9.38799C5.08184 9.64434 5.49732 9.64434 5.75367 9.38799L7.875 7.26707L11.3486 10.7407C11.605 10.997 12.0204 10.997 12.2768 10.7407L16.7975 6.21953L18.0071 7.42949C18.6272 8.04965 19.6875 7.61037 19.6875 6.73346V2.59375C19.6875 2.23117 19.3938 1.9375 19.0312 1.9375H14.8915C14.0146 1.9375 13.5754 2.99775 14.1955 3.61791L15.4055 4.82746L11.8125 8.42043L8.33889 4.94682C8.08254 4.69047 7.66705 4.69047 7.4107 4.94682L4.3616 7.99592C4.10525 8.25227 4.10525 8.66775 4.3616 8.9241L4.82549 9.38799ZM20.3438 14.4062H1.96875V1.28125C1.96875 0.918672 1.67508 0.625 1.3125 0.625H0.65625C0.293672 0.625 0 0.918672 0 1.28125V15.0625C0 15.7872 0.587754 16.375 1.3125 16.375H20.3438C20.7063 16.375 21 16.0813 21 15.7188V15.0625C21 14.6999 20.7063 14.4062 20.3438 14.4062Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PerformanceIcon;
