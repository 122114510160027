import classNames from 'classnames';
import dayjs from 'dayjs';
import { Icon, IconComponent } from '../../../assets/icons/Icon';
import {
  CheckBox,
  Description,
  InputNumber,
  InputText,
  Label,
  LazyList,
  MultiSelect,
  RadioGroup,
  Select,
  TextArea,
} from '../fields';
import CheckBoxGroup from '../fields/CheckBox/CheckBoxGroup';
import { MembersList } from '../fields/MembersList';
import {
  DynamicFormDataTable,
  DynamicFormTableSelect,
} from './DynamicFormDataTable';
import { ChangeHandler, FormFieldElement, FormFieldType } from './types';
import { getMinMaxValue } from './utils';

export interface FormFieldProps {
  field: FormFieldElement;
  onChange: ChangeHandler;
}

export const FormField = ({ field, onChange }: FormFieldProps) => {
  switch (field.type) {
    case FormFieldType.GROUP:
      return (
        <div
          key={field.id}
          className={classNames(
            'flex flex-col space-y-4',
            field.description && 'mb-4',
          )}
        >
          <div className="flex flex-col space-y-2">
            {field.iconName && (
              <Icon iconName={field.iconName} className="h-6 w-6" />
            )}
            <Label htmlFor={field.id} size="large">
              {field.title}
            </Label>
            {field.fields.map((subField) => {
              const subFieldValue =
                field.value && field.value[subField.path?.at(-1) || ''];
              const formFieldElement = {
                ...subField,
                value: subFieldValue,
              } as FormFieldElement;
              return (
                <FormField
                  key={formFieldElement.id}
                  field={{ ...formFieldElement, label: subField.title }}
                  onChange={onChange}
                />
              );
            })}
          </div>
          {field.description && <Description>{field.description}</Description>}
        </div>
      );
    case FormFieldType.TEXT:
      return (
        <InputText
          secret={field.secret}
          key={field.id}
          id={field.id}
          label={field.label}
          value={field.value}
          placeholder={field.placeholder}
          disabled={field.disabled}
          variant={field.error ? 'white' : field.variant}
          // error={field.error}
          required={field.required}
          onChange={(event) =>
            onChange({
              id: field.id,
              value: event.currentTarget.value,
              pathArray: field.path,
            })
          }
          minLength={getMinMaxValue(field.minLength)}
          maxLength={getMinMaxValue(field.maxLength)}
        />
      );
    case FormFieldType.TEXT_AREA:
      return (
        <TextArea
          key={field.id}
          id={field.id}
          value={field.value}
          placeholder={field.placeholder}
          disabled={field.disabled}
          variant={field.error ? 'white' : field.variant}
          // error={field.error}
          onChange={(event) =>
            onChange({
              id: field.id,
              value: event.currentTarget.value,
              pathArray: field.path,
            })
          }
          className="scrollbar"
          minLength={getMinMaxValue(field.minLength)}
          maxLength={getMinMaxValue(field.maxLength)}
        />
      );
    case FormFieldType.INPUT_NUMBER:
      return (
        <InputNumber
          key={field.id}
          id={field.id}
          value={field.value}
          placeholder={field.placeholder}
          disabled={field.disabled}
          variant={field.error ? 'white' : field.variant}
          min={getMinMaxValue(field.min)}
          max={getMinMaxValue(field.max)}
          // error={field.error}
          onChange={(event) =>
            onChange({
              id: field.id,
              value: event.currentTarget.valueAsNumber,
              pathArray: field.path,
            })
          }
        />
      );
    case FormFieldType.SELECT:
      return (
        <Select
          key={field.id}
          id={field.id}
          value={field.value}
          options={field.options}
          placeholder={field.placeholder}
          disabled={field.disabled}
          className="max-w-lg"
          clearable={field.clearable}
          variant={field.error ? 'white' : field.variant}
          // error={field.error}
          onChange={(option) =>
            onChange({
              id: field.id,
              value: option && option.value,
              pathArray: field.path,
            })
          }
          Icon={field.iconName && IconComponent({ iconName: field.iconName })}
        />
      );
    case FormFieldType.MULTI_SELECT:
      return (
        <MultiSelect
          key={field.id}
          id={field.id}
          buttonLabel={field.buttonLabel || field.title}
          showSelectAllOption={field.showSelectAllOption}
          defaultSelected={field.value}
          options={field.options}
          disabled={field.disabled}
          className="max-w-lg"
          onSelectionChange={(value) => {
            onChange({ id: field.id, value, pathArray: field.path });
          }}
          variant={field.error ? 'white' : field.variant}
          // error={field.error}
          Icon={field.iconName && IconComponent({ iconName: field.iconName })}
          labelType={field.labelType || 'values'}
        />
      );
    case FormFieldType.DATA_TABLE:
      return (
        <DynamicFormDataTable
          key={field.id}
          field={field}
          onChange={onChange}
        />
      );
    case FormFieldType.TABLE_SELECT:
      return (
        <DynamicFormTableSelect
          key={field.id}
          field={field}
          onChange={onChange}
        />
      );
    case FormFieldType.LIST:
      return (
        <LazyList
          id={field.id}
          value={field.value}
          maxItems={field.maxItems}
          minItems={field.minItems}
          placeholder={field.placeholder}
          uniqueItems={field.uniqueItems}
          disabled={field.disabled}
          inputLabel={field.inputLabel}
          // error={field.error}
          onChange={(value) => {
            onChange({ id: field.id, value, pathArray: field.path });
          }}
          formFieldElement={field.formFieldElement}
        />
      );
    case FormFieldType.MEMBERS_LIST:
      return (
        <MembersList
          value={field.value}
          maxItems={field.maxItems}
          minItems={field.minItems}
          placeholder={field.placeholder}
          uniqueItems={field.uniqueItems}
          disabled={field.disabled}
          inputLabel={field.inputLabel}
          onChange={(value) => {
            onChange({ id: field.id, value, pathArray: field.path });
          }}
          formFieldElement={field.formFieldElement}
        />
      );
    case FormFieldType.CHECKBOX:
      return (
        <CheckBox
          field={field}
          onChange={(event) =>
            onChange({
              id: field.id,
              value: event.currentTarget.checked,
              pathArray: field.path,
            })
          }
        />
      );
    case FormFieldType.RADIOGROUP:
      return (
        <RadioGroup
          id={field.id}
          value={field.value || ''}
          options={field.options}
          disabled={field.disabled}
          onChange={(value) => {
            onChange({ id: field.id, value, pathArray: field.path });
          }}
          direction={field.direction}
        />
      );
    case FormFieldType.CHECKBOX_GROUP:
      return (
        <CheckBoxGroup
          id={field.path?.join('.') || field.id}
          options={field.options}
          value={field.value || []}
          onChange={(value) => {
            onChange({ id: field.id, value, pathArray: field.path });
          }}
          searchable={field.searchable}
          searchPlaceholder={field.searchPlaceholder}
        />
      );
    case FormFieldType.DATE:
      return (
        <InputText
          key={field.id}
          id={field.id}
          value={field.value}
          placeholder={field.placeholder}
          disabled={field.disabled}
          variant={field.error ? 'white' : field.variant}
          // error={field.error}
          required={field.required}
          type="date"
          onChange={(event) =>
            onChange({
              id: field.id,
              value: event.currentTarget.value,
              pathArray: field.path,
            })
          }
        />
      );
    case FormFieldType.DATE_TIME:
      return (
        <InputText
          key={field.id}
          id={field.id}
          value={
            field.value
              ? dayjs(field.value).format('YYYY-MM-DDTHH:mm:ss')
              : field.value
          }
          placeholder={field.placeholder}
          disabled={field.disabled}
          variant={field.error ? 'white' : field.variant}
          // error={field.error}
          type="datetime-local"
          required={field.required}
          onChange={(event) =>
            onChange({
              id: field.id,
              value:
                dayjs(event.currentTarget.value).format(
                  'YYYY-MM-DDTHH:mm:ssZ',
                ) || event.currentTarget.value,
              pathArray: field.path,
            })
          }
        />
      );
    case FormFieldType.TIME:
      return (
        <InputText
          key={field.id}
          id={field.id}
          value={
            field.value
              ? dayjs(`2000-01-01T${field.value}`).format('HH:mm')
              : field.value
          }
          placeholder={field.placeholder}
          disabled={field.disabled}
          variant={field.error ? 'white' : field.variant}
          // error={field.error}
          type="time"
          required={field.required}
          onChange={(event) =>
            onChange({
              id: field.id,
              value: event.currentTarget.value
                ? `${event.currentTarget.value}:00`
                : event.currentTarget.value,
              pathArray: field.path,
            })
          }
        />
      );

    default:
      return null;
  }
};
