import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { HealthStatus, ServerState } from '../../../../gql/graphql';

export type StateStatus = 'Success' | 'Inactive' | 'Critical' | 'Warning';
type State = ServerState | HealthStatus | StateStatus;

export interface StateIconProps {
  state?: State;
  className?: string;
}

const succesValues: State[] = [ServerState.Active, HealthStatus.Up, 'Success'];
const criticalValues: State[] = [
  ServerState.Inactive,
  HealthStatus.Down,
  'Critical',
];
const warningValues: State[] = ['Warning'];
const inactiveValues: State[] = ['Inactive'];

export const StateIcon = ({ state, className, ...rest }: StateIconProps) => {
  return (
    <div
      className={twMerge(
        classNames(
          'h-3 w-3 shrink-0 rounded-full ring-4',
          state
            ? succesValues.includes(state)
              ? 'bg-tw-success-strong ring-tw-success-soft dark:bg-tw-success-strong-dark dark:ring-tw-success-soft-dark'
              : criticalValues.includes(state)
              ? 'bg-tw-danger-strong ring-tw-danger-soft dark:bg-tw-danger-strong-dark dark:ring-tw-danger-soft-dark'
              : warningValues.includes(state)
              ? 'bg-tw-warning-strong ring-tw-warning-soft dark:bg-tw-warning-strong-dark dark:ring-tw-warning-soft-dark'
              : inactiveValues.includes(state)
              ? 'bg-tw-inactive-color ring-tw-inactive-color/20 dark:bg-tw-inactive-color-dark dark:ring-tw-inactive-color-dark/20'
              : ''
            : 'bg-tw-warning-strong ring-tw-warning-soft dark:bg-tw-warning-strong-dark dark:ring-tw-warning-soft-dark',
          className,
        ),
      )}
      {...rest}
    />
  );
};
