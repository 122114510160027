import React from 'react';

export const DataArchiveIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M5.34583 6.56367V7.87617H6.67917V6.56367H5.34583ZM8.0125 2.62617H6.67917V3.93867H8.0125V2.62617ZM5.34583 3.93867V5.25117H6.67917V3.93867H5.34583ZM8.0125 5.25117H6.67917V6.56367H8.0125V5.25117ZM15.4125 4.0166L11.9167 0.575391C11.5417 0.20625 11.0333 -0.00292969 10.5042 -0.00292969H2C0.895833 0.00117187 0 0.883008 0 1.96992V19.0324C0 20.1193 0.895833 21.0012 2 21.0012H14C15.1042 21.0012 16 20.1193 16 19.0324V5.41113C16 4.89023 15.7875 4.38574 15.4125 4.0166ZM10.6667 2.12988L13.8375 5.25117H10.6667V2.12988ZM14 19.0324H2V1.96992H5.32083V2.62617H6.65417V1.96992H8.66667V6.23555C8.66667 6.78105 9.1125 7.21992 9.66667 7.21992H14V19.0324ZM8.09167 10.899C8.04583 10.6693 7.84167 10.5012 7.6 10.5012H6.67917V9.18867H5.34583V10.5012L4.525 14.4838C4.25 15.8168 5.28333 17.0637 6.66667 17.0637C8.04583 17.0637 9.07917 15.825 8.8125 14.4961L8.09167 10.899ZM6.67917 16.0014C5.93333 16.0014 5.32917 15.5051 5.32917 14.8939C5.32917 14.2828 5.93333 13.7865 6.67917 13.7865C7.425 13.7865 8.02917 14.2828 8.02917 14.8939C8.02917 15.5051 7.425 16.0014 6.67917 16.0014ZM8.0125 7.87617H6.67917V9.18867H8.0125V7.87617Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DataArchiveIcon;
