import { ActionButton, DialogTrigger } from '@adobe/react-spectrum';
import React, { useMemo } from 'react';

import NotificationBellIcon from '../../../assets/icons/NotificationBellIcon';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { AlertSeverity, GetAlertsCountQuery } from '../../../gql/graphql';
import { useAlertCountQuery } from '../../../useAlertCountQuery';
import { NotificationBadge } from './NotificationBadge';
import { NotificationWidgetContent } from './NotificationWidgetContent';

const getSeverity = (notifications: GetAlertsCountQuery) =>
  (notifications.CRITICAL &&
    notifications.CRITICAL.totalItems &&
    AlertSeverity.Critical) ||
  (notifications.ERROR &&
    notifications.ERROR.totalItems &&
    AlertSeverity.Error) ||
  (notifications.STANDARD &&
    notifications.STANDARD.totalItems &&
    AlertSeverity.Standard) ||
  AlertSeverity.Standard;

export function Notification({
  notifications: propsNotifications,
}: {
  notifications?: GetAlertsCountQuery;
}) {
  const { t } = useTranslation('notifications');

  const { data } = useAlertCountQuery();

  const notifications = useMemo(
    () =>
      propsNotifications ||
      data ||
      ({
        CRITICAL: { totalItems: 0 },
        ERROR: { totalItems: 0 },
        STANDARD: { totalItems: 0 },
      } as GetAlertsCountQuery),
    [propsNotifications, data],
  );

  const severity = getSeverity(notifications);

  const notificationsCount = useMemo(
    () =>
      notifications?.CRITICAL?.totalItems ||
      notifications?.ERROR?.totalItems ||
      notifications?.STANDARD?.totalItems ||
      0,
    [notifications],
  );

  const hasNotifications = useMemo(
    () => notificationsCount > 0,
    [notificationsCount],
  );

  return (
    <div
      data-testid="Notification"
      className="notification-wrapper font-graphikRegular"
    >
      <React.Fragment>
        <DialogTrigger type="popover" placement="bottom" hideArrow>
          <ActionButton
            aria-label={t('notifications.view')}
            isQuiet
            data-testid="notification-widget-trigger-button"
          >
            <div className="relative flex h-6 w-6 cursor-pointer items-center justify-center">
              <NotificationBellIcon
                className={classNames(
                  'notification-svg h-5 w-[1.125rem] text-[#BEBDBD]',
                  hasNotifications && severity.toLowerCase(),
                )}
              />
              {hasNotifications && (
                <div
                  className={classNames(
                    'notification-dot absolute h-2.5 w-2.5 rounded-full',
                    'right-0.5 top-px',
                    'border-2 border-tw-dark-shade dark:border-tw-dark-shade-dark',
                    severity.toLowerCase(),
                  )}
                />
              )}
            </div>
            <NotificationBadge
              notificationsCount={notificationsCount}
              severity={severity}
            />
          </ActionButton>
          {(close) => (
            <NotificationWidgetContent
              count={notificationsCount}
              onViewAllClick={close}
            />
          )}
        </DialogTrigger>
      </React.Fragment>
    </div>
  );
}
