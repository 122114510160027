import { faMemoCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useFeature } from 'featurehub-react-sdk';
import { GetInventoryQuery, ServerState } from '../../../../gql/graphql';
import { FeatureFlag } from '../../../../utils/featureHub/constants';
import { SimpleButton } from '../../../Buttons/SimpleButton';
import { StateIcon } from './StateIcon';

export interface SupportingNodeProps {
  server: GetInventoryQuery['appliances'][0]['servers'][0];
  onClickDetails?: (
    server: GetInventoryQuery['appliances'][0]['servers'][0],
  ) => void;
}
export const SupportingNode = ({
  server,
  onClickDetails,
  ...rest
}: SupportingNodeProps) => {
  const serverComponentsFlag = useFeature(
    FeatureFlag.INVENTORY_APPLIANCE_SERVER_COMPONENTS,
  );

  return (
    <div
      className={classNames(
        'flex flex-col items-start justify-between gap-4 rounded-lg border border-tw-border p-3 font-graphikMedium text-sm text-tw-label-primary dark:border-tw-border-dark  dark:text-tw-label-primary-dark',
        '',
      )}
      data-testid="appliance-card-server"
      {...rest}
    >
      <div
        data-testid="appliance-server"
        className="col-span-2 flex w-full flex-row items-center gap-4 pl-1"
      >
        <StateIcon state={server.state} />
        <span data-testid="appliance-server-name">
          {server.name} (
          {server.state === ServerState.Active ? 'Online' : 'Offline'})
        </span>
        {serverComponentsFlag && (
          <SimpleButton
            variant="soft"
            className="m-0 ml-auto h-7 w-7 items-center p-0 pl-0.5 pt-0.5"
            onClick={() => onClickDetails?.(server)}
          >
            <FontAwesomeIcon icon={faMemoCircleInfo} />
          </SimpleButton>
        )}
      </div>
    </div>
  );
};
