import { Suspense, useEffect } from 'react';
import {
  Navigate,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import { PageNotFound } from '../pages/404/PageNotFound';
import { LoginPage } from '../pages/loginPage';
import { ChangePasswordPage } from '../pages/loginPage/ChangePasswordPage';
import { RecoveryPage } from '../pages/loginPage/RecoveryPage';
import { RegistrationPage } from '../pages/loginPage/RegistrationPage';
import { RouteErrorWithMainLayout } from './RouteErrorWithMainLayout';
import {
  useAdminRoutes,
  useProtectedRoutes,
  useSuperAdminRoutes,
} from './protected';

import { useFeature } from 'featurehub-react-sdk';
import { useTranslation } from 'react-i18next';
import Spinner from '../components/Elements/Spinner/Spinner';
import { checkOrySession } from '../ory/checkSession';
import { ApplianceConfirmation } from '../pages/applianceConfirmation/ApplianceConfirmation';
import { ApplianceRegistration } from '../pages/applianceRegistration/ApplianceRegistration';
import { RecoveryOTPPage } from '../pages/loginPage/RecoveryOTPPage';
import { RegistrationApprovalPage } from '../pages/loginPage/RegistrationApprovalPage';
import { RegistrationFailurePage } from '../pages/loginPage/RegistrationFailurePage';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { updateLocale } from '../redux/locale/localeSlice';
import { FeatureFlag } from '../utils/featureHub/constants';
import { useCDNTranslation } from '../utils/hooks/useCDNTranslation';
import { useSession } from '../utils/hooks/useSession';
import { getSupportedLang } from '../utils/lang/lang';
import { lazyImport } from '../utils/lazyImport';

const { LearnMoreWithParams } = lazyImport(
  () => import('../pages/learnMore/LearnMore'),
  'LearnMoreWithParams',
);

export const DEFAULT_TITLE = 'Superna';
export const TITLE_JOIN = ' - ';

export const AppRoutes = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const language = i18n.language;
  const landingPage = useAppSelector((state) => state.login.landingPage);

  useEffect(() => {
    checkOrySession(dispatch);
  }, []);

  // RELEASE FLAG
  const flag_localization = useFeature(FeatureFlag.LOCALIZATION);
  useEffect(() => {
    if (!flag_localization) {
      dispatch(updateLocale(getSupportedLang(language)));
    }
  }, [language]);

  useCDNTranslation({
    ns: 'alertCodes',
    path: 'alertCodes/alertCodes.json',
  });

  const { auth, recovery } = useSession();

  const protectedRoutes = useProtectedRoutes();

  const adminRoutes = useAdminRoutes();
  const superAdminRoutes = useSuperAdminRoutes();
  const commonRoutes: RouteObject[] = [
    {
      path: '/',
      element: auth ? <Navigate to={landingPage || '/app/'} /> : <LoginPage />,
      ErrorBoundary: RouteErrorWithMainLayout,
    },
    {
      path: '/learn-more',
      ErrorBoundary: RouteErrorWithMainLayout,
      children: [
        {
          path: `:kind`,
          element: (
            <Suspense
              fallback={
                <div className="flex h-screen w-screen items-center justify-center">
                  <Spinner />
                </div>
              }
            >
              <LearnMoreWithParams />
            </Suspense>
          ),
        },
      ],
    },
    // REGISTRATION
    {
      path: '/registrationApproval',
      element: auth ? <Navigate to="/app/" /> : <RegistrationApprovalPage />,
    },
    {
      path: '/registrationFailure',
      element: auth ? <Navigate to="/app/" /> : <RegistrationFailurePage />,
    },
    {
      path: '/registration',
      element: auth ? <Navigate to="/app/" /> : <RegistrationPage />,
    },
    {
      path: '/passwordRecovery',
      element: <RecoveryPage />,
    },
    {
      path: '/newPassword',
      element: <ChangePasswordPage />,
    },
    {
      path: '/passwordOTPRecovery',
      element: recovery ? <RecoveryOTPPage /> : <LoginPage />,
    },
    {
      path: 'confirmAppliance',
      element: <ApplianceConfirmation />,
    },

    {
      path: 'registerAppliance',
      element: <ApplianceRegistration />,
    },
    { path: '/*', element: <PageNotFound /> },
  ];

  const router = createBrowserRouter([
    ...protectedRoutes,
    ...commonRoutes,
    ...adminRoutes,
    ...superAdminRoutes,
  ]);

  return <RouterProvider router={router} />;
};
