import classNames from 'classnames';
import { useEffect, useId, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import SearchInput, { SearchInputProps } from '../SearchInput/SearchInput';
import { CheckboxSwitch } from '../Switch/CheckboxSwitch';
import { PickerOptions } from '../pickers';
import { optionsMapper } from '../utils';

export interface CheckBoxGroupProps {
  id?: string;
  options?: PickerOptions;
  value: string[];
  checkboxPosition?: 'top' | 'middle';
  searchPlaceholder?: string;
  customClassNames?: {
    main?: string;
    search?: SearchInputProps['customClassNames'];
    options?: string;
  };
  onChange: (selected: string[]) => void;
  searchable?: boolean;
  searchProps?: Partial<SearchInputProps>;
}

const CheckBoxGroup = ({
  id,
  searchable = false,
  options: defaultOptions = [],
  value: defaultSelected,
  searchPlaceholder,
  onChange,
}: CheckBoxGroupProps) => {
  const [options, setOptions] = useState(defaultOptions.map(optionsMapper));

  useEffect(() => {
    setOptions(defaultOptions.map(optionsMapper));
  }, [defaultOptions]);

  const [selected, setSelected] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  // this is not unique, but it's good enough for this use case
  const uniqueId = useId();

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const filteredOptions = options.filter(
    (type) =>
      !searchValue ||
      type.label?.toString().toLowerCase().includes(searchValue.toLowerCase()),
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextSelected = event.target.checked
      ? [...selected, event.target.value]
      : selected.filter((val) => val !== event.target.value);
    setSelected(nextSelected);

    onChange(nextSelected);
  };

  return (
    <div className="flex flex-col items-center" data-testid="CheckBoxGroup">
      {searchable && (
        <SearchInput
          onChange={setSearchValue}
          placeholder={searchPlaceholder}
        />
      )}

      <div className="w-full">
        {filteredOptions.map((value) => (
          <div className="mt-2 flex w-full items-center" key={value.value}>
            <input
              type="checkbox"
              className="mr-2 h-4 w-4 shrink-0 rounded border-gray-300 text-tw-main-color focus:ring-indigo-500"
              id={`${value.value}-${uniqueId}`}
              name={`${value.value}`}
              value={value.value || ''}
              checked={Boolean(selected?.includes(`${value.value}`))}
              onChange={handleChange}
            />
            <label
              htmlFor={`${value.value}-${uniqueId}`}
              className="mt-0.5 font-graphikRegular"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                const checkbox = document.getElementById(
                  `${value.value}-${uniqueId}`,
                );
                if (checkbox) {
                  checkbox.click();
                }
              }}
            >
              {value.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export const CheckBoxSwitchGroup = ({
  searchable = false,
  options: defaultOptions = [],
  value: defaultSelected,
  searchPlaceholder,
  checkboxPosition,
  onChange,
  customClassNames,
  searchProps,
  ...rest
}: CheckBoxGroupProps) => {
  const [options, setOptions] = useState(defaultOptions.map(optionsMapper));

  useEffect(() => {
    setOptions(defaultOptions.map(optionsMapper));
  }, [defaultOptions]);

  const [selected, setSelected] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  // this is not unique, but it's good enough for this use case
  const uniqueId = useId();

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const filteredOptions = options.filter(
    (type) =>
      !searchValue ||
      type.label
        ?.toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase()) ||
      type.textValue?.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const handleChange = (value: string) => (checked: boolean) => {
    const nextSelected = checked
      ? [...selected, value]
      : selected.filter((val) => val !== value);
    setSelected(nextSelected);

    onChange(nextSelected);
  };

  const showNoResultsMessage = useMemo(
    () =>
      searchable &&
      searchValue &&
      defaultOptions.length &&
      filteredOptions.length === 0,
    [searchable, searchValue, defaultOptions, filteredOptions],
  );

  return (
    <div
      className={twMerge(
        classNames('flex flex-col items-center', customClassNames?.main),
      )}
      data-testid="CheckBoxGroup"
      {...rest}
    >
      {searchable && (
        <SearchInput
          customClassNames={customClassNames?.search}
          data-testid="search"
          onChange={setSearchValue}
          placeholder={searchPlaceholder}
          {...searchProps}
        />
      )}

      <div className={twMerge(classNames('w-full', customClassNames?.options))}>
        {showNoResultsMessage && (
          <span className="flex p-2">
            There are no results that match your search
          </span>
        )}
        {filteredOptions.map((value) => (
          <div
            className="mt-2 flex w-full items-center"
            data-testid="checkboxgroup-option"
            data-test-value={value.value}
            key={value.value}
          >
            <CheckboxSwitch
              checked={Boolean(selected?.includes(`${value.value}`))}
              onChange={handleChange(`${value.value}`)}
              checkboxPosition={checkboxPosition}
            >
              <label
                htmlFor={`${value.value}-${uniqueId}`}
                className="mt-0.5 font-graphikRegular"
              >
                {value.label}
              </label>
            </CheckboxSwitch>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckBoxGroup;
