import { RegistrationFlow, UiNodeInputAttributes } from '@ory/kratos-client';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import { WarningIcon } from '../../../components/Elements/Icons/WarningIcon';
import Spinner from '../../../components/Elements/Spinner/Spinner';
import { InputField } from '../../../components/form/InputField';
import OTPInput from '../../../components/form/OTPInput';
import { ory } from '../../../ory/config';
import { submitRegistrationFlow } from '../../../ory/registration';

type ApprovedRegistrationFields = {
  email: string;
  name: string;
  surname: string;
  password: string;
  confirmPassword: string;
};

export const RegistrationForm = () => {
  const [flow, setFlow] = useState<RegistrationFlow>();
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const flowId = searchParams.get('flow');

  useEffect(() => {
    // If ?flow=.. was in the URL, we fetch it
    if (flowId) {
      ory.frontend
        .getRegistrationFlow({
          id: String(flowId),
        })
        .then(({ data }) => {
          setFlow(data);
        })
        .catch((err: AxiosError) => {
          switch (err.response?.status) {
            // Status code 410 means the request has expired
            // Status code 403 implies some other issue (e.g. CSRF)
            case 410:
            case 403:
            // return router.push("/verification")
          }
          throw err;
        })
        .finally(async () => {
          setLoading(false);
        });
      return;
    } else {
      setLoading(false);
    }
  }, []);

  const { t } = useTranslation('userAccount');
  return (
    <div className="flex flex-col items-center gap-10 font-graphikRegular">
      <div className="h-auto w-[28.375rem] rounded-2xl bg-white p-8 pb-6 dark:bg-tw-light-shade-dark">
        {loading ? (
          <Spinner />
        ) : (
          <div className="flex flex-col items-center gap-3">
            {flow ? (
              <RegistrationLayout flow={flow} />
            ) : (
              <p>{t('registration.flowError')}</p>
            )}
          </div>
        )}
      </div>
      <span className="font-graphikRegular">
        {`Already an account? `}
        <Link to={'/'} className="text-tw-main-color">
          Sign in
        </Link>
      </span>
    </div>
  );
};

const RegistrationLayout = ({ flow }: { flow: RegistrationFlow }) => {
  const { t } = useTranslation(['userAccount', 'ory']);

  const [otp, setOtp] = useState('');
  const onOtpChange = (value: string) => setOtp(value);

  const [error, setError] = useState<string>('');

  const {
    setFocus,
    register,
    handleSubmit,
    watch,
    formState: { errors, submitCount },
    trigger,
  } = useForm<ApprovedRegistrationFields>();

  const isTouched = submitCount > 0;

  const onSubmit: SubmitHandler<ApprovedRegistrationFields> = async (data) => {
    setError('');
    setLoading(true);
    console.log(data);
    const response = await submitRegistrationFlow({
      flow,
      identifier: data.email,
      password: data.password,
      name: data.name,
      surname: data.surname,
    });
    setLoading(false);
    !response.success &&
      setError(
        response.messages[0]
          ? t(
              `ory.messages.${response.messages[0].id}`,
              response.messages[0].text,
              { ns: 'ory' },
            )
          : t('registration.error'),
      );
  };

  useEffect(() => {
    setFocus('name');
  }, []);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    isTouched && trigger('confirmPassword');
  }, [watch('password')]);

  if (loading) {
    return <SubmittingRegistration />;
  }
  return (
    <>
      <h1 className="mt-2 font-graphikBold text-[28px] leading-[31px]">
        {t('registration.title')}
      </h1>
      <h5 className="mb-1 font-graphikRegular text-xs">
        {t('registration.description')}
      </h5>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full p-3">
        <InputField
          label={t('registration.fields.name.label')}
          register={register('name', {
            required: {
              value: true,
              message: t('registration.fields.name.validation.required'),
            },
            value: (
              flow?.ui?.nodes?.find(
                (node) =>
                  (node.attributes as UiNodeInputAttributes)?.name ===
                  'traits.name',
              )?.attributes as UiNodeInputAttributes
            )?.value,
          })}
          error={errors.name}
          watchValue={watch('name')}
          disabled={loading}
          isTouched={isTouched}
        />
        <InputField
          label={t('registration.fields.surname.label')}
          register={register('surname', {
            required: {
              value: true,
              message: t('registration.fields.surname.validation.required'),
            },
            value: (
              flow?.ui?.nodes?.find(
                (node) =>
                  (node.attributes as UiNodeInputAttributes)?.name ===
                  'traits.surname',
              )?.attributes as UiNodeInputAttributes
            )?.value,
          })}
          error={errors.surname}
          watchValue={watch('surname')}
          disabled={loading}
          isTouched={isTouched}
        />
        <InputField
          label={t('registration.fields.email.label')}
          disabled={
            (
              flow?.ui?.nodes?.find(
                (node) =>
                  (node.attributes as UiNodeInputAttributes)?.name ===
                  'traits.email',
              )?.attributes as UiNodeInputAttributes
            )?.value
          }
          register={register('email', {
            value: (
              flow?.ui?.nodes?.find(
                (node) =>
                  (node.attributes as UiNodeInputAttributes)?.name ===
                  'traits.email',
              )?.attributes as UiNodeInputAttributes
            )?.value,
            required: {
              value: true,
              message: t('registration.fields.email.validation.required'),
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: t('registration.fields.email.validation.invalid'),
            },
          })}
          error={errors.email}
          watchValue={watch('email')}
          isTouched={isTouched}
        />
        <InputField
          label={t('registration.fields.password.label')}
          register={register('password', {
            required: {
              value: true,
              message: t('registration.fields.password.validation.required'),
            },
            minLength: {
              value: 8,
              message: t('registration.fields.password.validation.minLength'),
            },
            maxLength: {
              value: 20,
              message: t('registration.fields.password.validation.maxLength'),
            },
          })}
          error={errors.password}
          watchValue={watch('password')}
          type="password"
          disabled={loading}
          isTouched={isTouched}
        />
        <InputField
          label={t('registration.fields.confirmPassword.label')}
          register={register('confirmPassword', {
            required: {
              value: true,
              message: t('registration.fields.password.validation.required'),
            },
            validate: (value) =>
              value === watch('password') ||
              t('registration.fields.confirmPassword.validation.match'),
          })}
          error={errors.confirmPassword}
          watchValue={watch('confirmPassword')}
          type="password"
          disabled={loading}
          isTouched={isTouched}
        />

        <div className="flex w-full flex-col gap-2">
          <label
            className={classNames(
              'transform font-graphikRegular font-medium text-[#A4A4A4] duration-300',
            )}
          >
            Invitation Code
          </label>
          <OTPInput value={otp} valueLength={6} onChange={onOtpChange} />
        </div>

        <PrimaryButton
          disabled={
            otp.length <= 5 ||
            loading ||
            Boolean(errors.email || errors.password || errors.confirmPassword)
          }
          size="large"
          type="submit"
          className="w-full"
        >
          {t('registration.submit')}
        </PrimaryButton>
        {error && (
          <div className="mt-2 flex items-center gap-2 text-red-500">
            <WarningIcon className="h-6 w-6" />
            <span className="w-full text-xs">{error}</span>
          </div>
        )}
        {loading && (
          <div className="mt-2 flex flex-col items-center gap-2">
            <Spinner className="h-6 w-6 animate-scaleIn" />
          </div>
        )}
      </form>
    </>
  );
};

const SubmittingRegistration = () => {
  return (
    <div className="flex flex-col items-center gap-10 font-graphikRegular">
      <div className="flex flex-col items-center gap-3 text-tw-main-text dark:text-tw-main-text-dark">
        <h1
          className="font-graphikBold"
          style={{ fontSize: '28px', lineHeight: '31px' }}
        >
          Submitting
        </h1>
        <div className="flex flex-col items-center justify-start">
          <p className="text-center">
            We are validating and submitting your personal details, password,
            and invitation code.
          </p>
        </div>
        <div className="py-5 text-[#E22134]">
          <Spinner className="h-[94px] w-[94px]" />
        </div>
      </div>
    </div>
  );
};
