import { useCallback, useState } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { Icon, IconName } from '../assets/icons/Icon';
import disconnectDark from '../assets/icons/darkMode/disconnect-img.svg';
import disconnectLight from '../assets/icons/lightMode/disconnect-img.svg';
import { PageNotFound } from '../pages/404/PageNotFound';
import { useAppSelector } from '../redux/hooks';

export const RouteError = () => {
  const [view, setView] = useState<boolean>(false);
  const toggleView = useCallback(() => setView((prev) => !prev), []);
  const error = useRouteError();
  const theme = useAppSelector((state) => state.theme.value);
  const location = useLocation();

  if (isRouteErrorResponse(error)) {
    if (error.status === 401) {
      // ...
    } else if (error.status === 404) {
      return <PageNotFound />;
    }

    return (
      <div id="error-page">
        <h1>Oops! {error.status}</h1>
        <p>{error.statusText}</p>
        {error.data?.message && (
          <p>
            <i>{error.data.message}</i>
          </p>
        )}
      </div>
    );
  } else if (error instanceof Error) {
    return (
      <div
        id="error-page"
        className="relative flex h-full flex-col bg-bottom bg-no-repeat p-4"
        style={{
          backgroundImage: `url(${
            theme === 'light' ? disconnectLight : disconnectDark
          })`,
        }}
      >
        <div className="pt-16">
          <div className="flex flex-col text-center align-middle">
            <h1
              className="mt-6 font-graphikSemi text-tw-main-text dark:text-tw-main-text-dark"
              style={{ fontSize: '36px', lineHeight: '40px' }}
            >
              Oops... Unexpected Error.
            </h1>
            <p
              className="mt-4 font-graphikSemi text-tw-main-text dark:text-tw-main-text-dark"
              style={{ fontSize: '16px', lineHeight: '18px' }}
            >
              Sorry,{' '}
              <span className="cursor-pointer" onClick={toggleView}>
                Something
              </span>{' '}
              went wrong.
            </p>
            <div className="mt-12">
              <Link
                to="/app/dashboard"
                className="flex flex-row items-center justify-center gap-2 font-graphikBold text-xl text-tw-main-text dark:text-tw-main-text-dark"
              >
                <span aria-hidden="true">
                  <Icon className="h-6 w-6" iconName={IconName.ArrowLeftIcon} />
                </span>
                Go back to Home
              </Link>
              <Link
                className="flex flex-row items-center justify-center gap-2 font-graphikBold text-xl text-tw-main-text dark:text-tw-main-text-dark"
                to={location.pathname}
              >
                Reload current page
              </Link>
            </div>
          </div>
        </div>

        {view && (
          <p>
            <i>{error.message}</i>
          </p>
        )}
        {view && error.stack && (
          <p className="mt-2 rounded bg-white p-4 shadow-md ring-1 ring-black/5 dark:bg-black dark:ring-white/10">
            <i>{error.stack}</i>
          </p>
        )}
      </div>
    );
  } else {
    return (
      <div id="error-page" className="p-4">
        {`${error}`}
      </div>
    );
  }
};
