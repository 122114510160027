import { ProductKind } from '../../api/product/types';
import { IFilterButton } from '../../components/Inventory/types';
import {
  AlertSeverity,
  GetInventoryQuery,
  GetManagedDevicesQuery,
} from '../../gql/graphql';
import { ExtendedInstalledProduct } from '../dashboard/hooks/useProducts';
import { MostSevereCount } from './AppliancesStatusBar';

export const devicesToFilterButton = (
  devices?: GetManagedDevicesQuery,
  inventory?: GetInventoryQuery,
  products?: ExtendedInstalledProduct[],
): IFilterButton[] | undefined => {
  return (
    products?.map((product) => {
      const numberOfDevices =
        devices?.managedDevices.filter((device) =>
          device.licenseLinks.some(
            (link) =>
              link.license.installedProduct.product.kind === product.kind,
          ),
        )?.length || 0;

      const numberOfAppliances =
        inventory?.appliances.filter((appliance) =>
          appliance.installedProducts.some(
            (installedProduct) =>
              installedProduct.product.kind === product.kind,
          ),
        )?.length || 0;

      return {
        name: product.name,
        kind: product.kind as ProductKind,
        description: `On ${numberOfAppliances} appliance${
          numberOfAppliances != 1 ? 's' : ''
        } and ${numberOfDevices} managed device${
          numberOfDevices != 1 ? 's' : ''
        }`,
      };
    }) || []
  );
};

const severityValue = {
  [AlertSeverity.Critical]: 3,
  [AlertSeverity.Error]: 2,
  [AlertSeverity.Standard]: 1,
};
export const mostSevereCountSort = (
  a: { count: MostSevereCount | null },
  b: { count: MostSevereCount | null },
) => {
  const aSeverityScore = a.count?.severity
    ? severityValue[a.count.severity]
    : 0;
  const bSeverityScore = b.count?.severity
    ? severityValue[b.count.severity]
    : 0;
  const aCountScore = a.count?.count || 0;
  const bCountScore = b.count?.count || 0;
  return bSeverityScore - aSeverityScore || bCountScore - aCountScore;
};
