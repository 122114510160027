import { QueryResult, useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { GET_PRODUCT_ALERTS } from '../api/dashboard/queries';
import { ProductKind } from '../api/product/types';
import {
  AlertSeverity,
  GetAlertsCountQuery,
  GetProductAlertsQuery,
  GetProductAlertsQueryVariables,
} from '../gql/graphql';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { updateAlertCountByProductAndSeverity } from '../redux/products/productsSlice';
import { formatNumber } from '../utils/format';
import { useSession } from '../utils/hooks/useSession';
import { rangeIdToValue } from './CalenderRangePicker/utils';
import Spinner from './Elements/Spinner/Spinner';

export interface ProductAlertCountRenderProps {
  total?: number | undefined;
  mostSeverity?: AlertSeverity;
  mostSevereCount?: number;
  query: QueryResult<GetProductAlertsQuery, GetProductAlertsQueryVariables>;
}

export const defaultRender = ({ total, query }: ProductAlertCountRenderProps) =>
  query.loading ? (
    <Spinner className="h-5 w-5" />
  ) : (
    <>{formatNumber(total || 0)}</>
  );

const POLLING_INTERVAL = 30000;

export const getAlertCountTotals = (alertCountQuery?: GetAlertsCountQuery) => {
  const total =
    (alertCountQuery?.CRITICAL.totalItems || 0) +
    (alertCountQuery?.ERROR.totalItems || 0) +
    (alertCountQuery?.STANDARD.totalItems || 0);

  const mostSevereCount =
    alertCountQuery?.CRITICAL.totalItems ||
    alertCountQuery?.ERROR.totalItems ||
    alertCountQuery?.STANDARD.totalItems ||
    0;

  const mostSeverity =
    (alertCountQuery?.CRITICAL.totalItems && AlertSeverity.Critical) ||
    (alertCountQuery?.ERROR.totalItems && AlertSeverity.Error) ||
    AlertSeverity.Standard;

  return { total, mostSevereCount, mostSeverity };
};

export const ProductAlertCount = ({
  kind,
  children = defaultRender,
}: {
  kind: ProductKind | string;
  children?: ({ total, query }: ProductAlertCountRenderProps) => JSX.Element;
}) => {
  const { skip } = useSession();
  const { duration, appliances } = useAppSelector(
    (state) => state.productFilter,
  );

  const dateRange = useMemo(() => {
    return rangeIdToValue(duration);
  }, [duration]);
  const alertCountQuery = useQuery<
    GetProductAlertsQuery,
    GetProductAlertsQueryVariables
  >(GET_PRODUCT_ALERTS, {
    variables: {
      from: dateRange?.from,
      appliance: appliances,
      productKind: kind,
    },
    pollInterval: POLLING_INTERVAL,
    skip,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    !skip &&
      dispatch(
        updateAlertCountByProductAndSeverity({
          [kind as ProductKind]: {
            data: alertCountQuery.data,
            loading: alertCountQuery.loading,
          },
        }),
      );
  }, [alertCountQuery]);

  const { total, mostSevereCount, mostSeverity } = useMemo(
    () => getAlertCountTotals(alertCountQuery.data),
    [alertCountQuery.data],
  );

  return children({
    total,
    mostSeverity,
    mostSevereCount,
    query: alertCountQuery,
  });
};
