import classNames from 'classnames';
import { prop } from 'ramda';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { ChevronDownIcon } from '../../assets/icons';
import Spinner from '../Elements/Spinner/Spinner';
import Pagination from './Pagination';

export interface DataTableColumn<T> {
  field: keyof T;
  headerName?: React.ReactNode;
  description?: string;
  sortable?: boolean;
  classNames?: {
    th?: string;
    td?: string;
    thContainer?: string;
  };
  renderCell?: (params: DataTableGetterParams<T>) => React.ReactNode;
  valueGetter?: (params: DataTableGetterParams<T>) => number | string;
}

export interface DefaultData {
  [key: string]: number | string;
}

export interface DataTableGetterParams<T = DefaultData> {
  row: T;
}

const DataTableContainer = ({
  children,
  stickyHeader,
  className,
}: {
  children: React.ReactNode;
  stickyHeader?: boolean;
  className?: string;
}) => {
  return (
    <div
      className={twMerge(
        classNames(
          'scrollbar flex w-full flex-col overflow-auto',
          stickyHeader && 'relative max-h-full',
          className,
        ),
      )}
    >
      {children}
    </div>
  );
};

interface Props<T> {
  rows: T[];
  columns: DataTableColumn<T>[];
  pageSize?: number;
  checkboxSelection?: boolean;
  pagination?: boolean;
  stickyHeader?: boolean;
  loading?: boolean;
  keyExtractor: (item: T) => string;
  onSelect?: (selectedIds: string[]) => void;
  onSort?: (sort: { field: keyof T; direction: 'asc' | 'desc' }) => void;
  isRowSelectionDisabled?: (row: T) => boolean;
  defaultSortField?: keyof T;
  defaultSortDirection?: 'asc' | 'desc';
  defaultSelectedRows?: string[];
  rowClassName?: (row: T) => string;
  onRowClick?: (row: T) => void;
  singleSelect?: boolean;
  variant?: 'default' | 'shade';
  customClassnames?: {
    container?: string;
  };
  defaultCellRender?: (value: unknown) => unknown;
  // Infinite
  onFetchMore?: () => void;
  hasMore?: boolean;
}

export const DataTable = <T extends object>({
  rows,
  columns,
  pageSize = 5,
  pagination,
  checkboxSelection,
  keyExtractor,
  onSelect,
  onSort,
  onRowClick,
  stickyHeader,
  loading,
  isRowSelectionDisabled,
  defaultSelectedRows,
  defaultSortField,
  defaultSortDirection,
  rowClassName,
  variant = 'default',
  customClassnames,
  defaultCellRender = (v) => v,
  onFetchMore,
  hasMore,
}: Props<T>) => {
  const [sort, setSort] = useState<{
    field?: keyof T;
    direction: 'asc' | 'desc';
  }>({
    field: defaultSortField,
    direction: defaultSortDirection || 'asc',
  });

  const handleSort = useCallback(
    (field: keyof T) => {
      const newSort = {
        field,
        direction: (sort.field === field
          ? sort.direction === 'asc'
            ? 'desc'
            : 'asc'
          : 'asc') as 'asc' | 'desc',
      };
      setSort(newSort);
      onSort?.(newSort);
    },
    [sort.field, sort.direction],
  );

  useEffect(() => {
    setSort({
      field: defaultSortField,
      direction: defaultSortDirection || 'asc',
    });
  }, [defaultSortField, defaultSortDirection]);

  // Can be used for triggering a refresh of the selected rows
  useEffect(() => {
    setSelectedKeys(defaultSelectedRows || []);
  }, [defaultSelectedRows]);

  const sortedRows = useMemo(() => {
    if (!sort.field) {
      return rows;
    }
    const field = sort.field;
    const sorted = [...rows].sort((a, b) => {
      const column = columns.find((c) => c.field === sort.field);
      const valueGetter = column?.valueGetter;
      const aValue = valueGetter ? valueGetter({ row: a }) : prop(field, a);
      const bValue = valueGetter ? valueGetter({ row: b }) : prop(field, b);
      if (aValue < bValue) {
        return sort.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sort.direction === 'asc' ? 1 : -1;
      }
      if (aValue === bValue) {
        return 0;
      }
      return 1;
    });
    return sorted;
  }, [columns, rows, sort.field, sort.direction]);

  const [pageNumber, setPageNumber] = useState(0);
  const pagedRows = useMemo(() => {
    if (!pagination) {
      return sortedRows;
    }
    const start = pageNumber * pageSize;
    const end = start + pageSize;
    return sortedRows.slice(start, end);
  }, [pageNumber, pageSize, pagination, sortedRows]);

  const columnFields = useMemo(() => columns.map((c) => c.field), [columns]);

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const selectRow = (key: string) => {
    const nextSelected = selectedKeys.includes(key)
      ? selectedKeys.filter((k) => k !== key)
      : [...selectedKeys, key];
    setSelectedKeys(nextSelected);
    onSelect?.(nextSelected);
  };

  const handleSelectRows = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextSelected = pagedRows
      .filter((row) => {
        const isSelectionDisabled = isRowSelectionDisabled?.(row);
        const alreadySelected = selectedKeys.includes(keyExtractor(row));

        return e.target.checked
          ? !isSelectionDisabled || alreadySelected
          : isSelectionDisabled && alreadySelected;
      })
      .map(keyExtractor);

    setSelectedKeys(nextSelected);
    onSelect?.(nextSelected);
  };

  const classNameBg = useMemo(() => {
    return classNames(
      variant === 'shade' && 'bg-tw-light-shade dark:bg-tw-strong-shade-dark',
      // variant === 'default' && 'bg-white dark:bg-tw-dark-shade-dark',
    );
  }, [variant]);

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onFetchMore?.();
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, onFetchMore]);

  return (
    <DataTableContainer
      stickyHeader={stickyHeader}
      className={customClassnames?.container}
    >
      <table
        className="max-h-full min-w-full overflow-y-auto p-2 dark:divide-tw-inactive-color"
        data-testid="DataTable"
      >
        <thead
          className={classNames(
            classNameBg,
            stickyHeader &&
              'bg-tw-surface-strong dark:bg-tw-surface-strong-dark',
            stickyHeader && 'sticky top-0 z-10',
            'border-b border-b-tw-label-primary/5 dark:border-b-tw-label-primary-dark/5',
          )}
        >
          <tr>
            {checkboxSelection && (
              <th className="w-12 px-6 sm:w-16 sm:px-8">
                <div className="flex items-center justify-center">
                  <input
                    disabled={
                      loading ||
                      pagedRows.filter((row) => !isRowSelectionDisabled?.(row))
                        .length === 0
                    }
                    data-testid="CheckboxSelect"
                    aria-label={
                      selectedKeys.length === rows.length
                        ? 'Unselect all'
                        : 'Select all'
                    }
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                    checked={
                      selectedKeys.length ===
                      rows.filter(
                        (row) =>
                          !isRowSelectionDisabled?.(row) ||
                          selectedKeys.includes(keyExtractor(row)),
                      ).length
                    }
                    onChange={handleSelectRows}
                  />
                </div>
              </th>
            )}
            {columns.map((column, index) => (
              <th
                key={`th-${column.field?.toString()}`}
                scope="col"
                className={twMerge(
                  classNames(
                    'select-none py-3.5 text-left font-graphikRegular text-xs text-tw-label-tertiary dark:text-tw-label-tertiary-dark',
                    column.sortable && 'cursor-pointer',
                    index % 2 === 0 ? 'even' : 'odd',
                    column.classNames?.th,
                  ),
                )}
                onClick={() => column.sortable && handleSort(column.field)}
              >
                <div
                  className={twMerge(
                    classNames('flex flex-col', column.classNames?.thContainer),
                  )}
                >
                  <div className="flex flex-row items-center justify-between">
                    <p
                      className={[
                        'p-1',
                        sort.field === column.field
                          ? 'text-tw-main-text dark:text-tw-main-text-dark'
                          : 'text-tw-inactive-color',
                      ]
                        .filter(Boolean)
                        .join(' ')}
                    >
                      {column.headerName}
                    </p>
                    {column.sortable && (
                      <div className={'h-5 w-5 text-tw-inactive-color'}>
                        {sort.field === column.field && (
                          <ChevronDownIcon
                            className={[
                              'h-5 w-5 transition-all',
                              sort.field === column.field
                                ? 'text-tw-main-text dark:text-tw-main-text-dark'
                                : 'text-tw-inactive-color dark:text-tw-inactive-color',
                              sort.direction === 'asc'
                                ? 'rotate-180'
                                : 'rotate-0',
                            ]
                              .filter(Boolean)
                              .join(' ')}
                          />
                        )}
                      </div>
                    )}
                  </div>
                  {column.description && (
                    <p className="p-1 text-xs text-tw-inactive-color">
                      *{column.description}
                    </p>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody
          className={classNames(
            // 'divide-y divide-gray-200 dark:divide-zinc-600',
            classNameBg,
          )}
        >
          {pagedRows.map((row, rowIndex) => (
            <tr
              key={keyExtractor(row)}
              className={twMerge(
                classNames(
                  'transition',
                  !isRowSelectionDisabled?.(row)
                    ? 'cursor-pointer hover:border-tw-main-color hover:text-tw-main-color'
                    : 'hover:bg-tw-light-shade dark:hover:bg-zinc-800',
                  selectedKeys.includes(keyExtractor(row)) &&
                    'bg-tw-hover-alternative dark:bg-tw-light-shade-dark',
                  rowIndex % 2 === 0 ? 'even' : 'odd',
                  rowClassName?.(row),
                ),
              )}
              onClick={() => {
                checkboxSelection &&
                  !isRowSelectionDisabled?.(row) &&
                  selectRow(keyExtractor(row));
                onRowClick?.(row);
              }}
            >
              {checkboxSelection && (
                <td className="p-2">
                  <div className="flex items-center justify-center">
                    <input
                      disabled={isRowSelectionDisabled?.(row)}
                      type="checkbox"
                      checked={selectedKeys.includes(keyExtractor(row))}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                      onChange={() => selectRow(keyExtractor(row))}
                    />
                  </div>
                </td>
              )}
              {columnFields.map((field) => {
                const column = columns.find((column) => column.field === field);
                const rowRender = column?.renderCell
                  ? column.renderCell({ row })
                  : defaultCellRender(prop(field, row));
                return (
                  <td
                    key={`td-${field.toString()}`}
                    className={twMerge(
                      classNames(
                        'truncate whitespace-nowrap px-1 py-2.5 font-graphikRegular',
                        column?.classNames?.td,
                      ),
                    )}
                  >
                    <>{rowRender}</>
                  </td>
                );
              })}
            </tr>
          ))}
          {onFetchMore && hasMore && (
            <tr
              ref={observerTarget}
              onClick={!loading && hasMore ? onFetchMore : undefined}
            >
              {checkboxSelection && (
                <td className="p-2">
                  <CellBone />
                </td>
              )}
              {columnFields.map((field) => {
                return (
                  <td
                    key={`td-${field.toString()}`}
                    className={twMerge(
                      classNames(
                        'truncate whitespace-nowrap px-1 py-2.5 font-graphikRegular',
                      ),
                    )}
                  >
                    <CellBone />
                  </td>
                );
              })}
            </tr>
          )}
        </tbody>
        {((pagination && rows.length > pageSize) ||
          loading ||
          (!loading && rows.length < 1)) && (
          <tfoot>
            <tr>
              <td colSpan={columns.length + (checkboxSelection ? 1 : 0)}>
                <div className="flex items-center justify-between p-2">
                  {pagination && (
                    <Pagination
                      className="pagination-bar"
                      currentPage={pageNumber + 1}
                      totalCount={rows.length}
                      pageSize={pageSize}
                      onPageChange={(page) => setPageNumber(page - 1)}
                    />
                  )}
                  {loading && !hasMore && <Spinner />}
                  {!loading && rows.length < 1 && (
                    <p className="text-xs text-tw-inactive-color">
                      No data available
                    </p>
                  )}
                </div>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </DataTableContainer>
  );
};

const CellBone = () => (
  <div
    data-testid={'loading-cell'}
    className="flex h-full w-full animate-pulse rounded bg-tw-inactive-color text-transparent dark:bg-tw-inactive-color-dark"
  >
    .
  </div>
);
