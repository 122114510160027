import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faFilter } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { SimpleButton } from '../Buttons/SimpleButton';
import { LabelWithCount } from './LabelWithCount';

export const FilterOption = ({
  label,
  icon,
  type,
  selected,
  count,
  children,
  onClick,
  shouldOpen,
  visible,
  dataTestType,
}: {
  label: string;
  type: 'select' | 'radio';
  dataTestType?: string;
  selected?: boolean;
  children: React.ReactNode;
  count?: number;
  onClick?: () => void;
  shouldOpen?: boolean;
  visible?: boolean;
  icon?: IconProp;
}) => {
  const handleClick = useCallback(() => onClick?.(), []);

  const useBackIcon = useMemo(
    () => selected && !shouldOpen,
    [selected, shouldOpen],
  );

  const iconName = useMemo(() => {
    if (useBackIcon) return faChevronLeft;
    return icon || faFilter;
  }, [type, useBackIcon, icon]);

  const isOpen = useMemo(() => selected || shouldOpen, [selected, shouldOpen]);

  return (
    <Transition
      show={visible || isOpen}
      enter="ease-out duration-300"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div
        data-testid="filter-option"
        data-test-type={dataTestType || label}
        className="flex flex-col"
      >
        <SimpleButton
          data-testid="filter-option-button"
          onClick={handleClick}
          variant="default-ghost"
          className="text-tw-main-text dark:text-tw-main-text-dark"
        >
          <LabelWithCount
            label={
              <div className="flex items-center gap-2">
                <FontAwesomeIcon
                  icon={iconName}
                  className={classNames(useBackIcon && 'p-0.5', 'h-4 w-4')}
                />
                {label}
              </div>
            }
            count={count}
          />
        </SimpleButton>
        <Transition
          show={isOpen}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          className="px-5 pb-5"
        >
          {children}
        </Transition>
      </div>
    </Transition>
  );
};
