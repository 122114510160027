import { Location } from '../../../gql/graphql';
import { IPoint } from '../types';
import { points } from './values';

//used to determine how far the calculated cx and cy values are from a real point in the values file
export const calculateEuclideanDistance = (
  x1: string,
  y1: string,
  x2: string,
  y2: string,
) => {
  const dx = Number(x1) - Number(x2);
  const dy = Number(y1) - Number(y2);
  return Math.sqrt(dx * dx + dy * dy);
};

export const findClosestPoint = (
  userLatitude = 0,
  userLongitude = 0,
): IPoint | null => {
  const mapCxToLongitude = (cxValue: number) => {
    const uniqueCxValues = Array.from(new Set(points.map((obj) => obj.cx)));
    uniqueCxValues.sort((a, b) => parseFloat(a) - parseFloat(b));
    return uniqueCxValues[Math.round(55 + cxValue / 3)];
  };

  const mapCyToLatitude = (cyValue: number) => {
    const uniqueCyValues = Array.from(new Set(points.map((obj) => obj.cy)));
    uniqueCyValues.sort((a, b) => parseFloat(a) - parseFloat(b));
    return uniqueCyValues[Math.round(37 - cyValue / 3)];
  };

  // Convert userLatitude and userLongitude to c* values
  const userCx = mapCxToLongitude(userLongitude);
  const userCy = mapCyToLatitude(userLatitude);

  let closestPoint = null;
  let closestDistance = Number.MAX_VALUE;

  points.forEach((obj) => {
    const distance = calculateEuclideanDistance(userCx, userCy, obj.cx, obj.cy);
    if (distance < closestDistance) {
      closestDistance = distance;
      closestPoint = obj;
    }
  });

  return closestPoint;
};

export interface LocationPoint {
  id: string;
  location?: Location | null;
  closestPoint?: IPoint | null;
  type: 'appliance' | 'device';
  status: 'online' | 'offline';
  text: string;
}

export const sortedLocationPoints = (
  locations: Array<LocationPoint> | undefined,
) => {
  const arr = locations?.map((info) => {
    const closestPoint = findClosestPoint(
      info.location?.latitude,
      info.location?.longitude,
    );
    return {
      ...info,
      closestPoint: closestPoint,
    };
  });

  return arr;
};
