import { PickerOptions } from './Select';

export const mapPickerOptionToValue = (option: PickerOptions[0]) => {
  if (typeof option === 'object') {
    return option.value;
  }
  return option;
};

export const pickerOptionLabel = (option?: PickerOptions[0]) => {
  if (!option) {
    return;
  }

  if (typeof option === 'object') {
    return option.label;
  }
  return option;
};
