import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductKind } from '../../../../api/product/types';
import { QuestionMark } from '../../../../assets/icons';
import { Category } from '../../../../gql/graphql';
import { ModalTrigger } from '../../../common/Modal';
import {
  AGModal,
  CSMModal,
  DRModal,
  EasyAuditorModal,
  RSWModal,
} from './CoverageDefinitions';

interface IProps {
  disabled?: boolean;
  category: Category;
  appName?: ProductKind;
}

const getModalContent = (appName: string | undefined) => {
  switch (appName) {
    case 'EA':
      return <EasyAuditorModal />;
    case 'DR':
      return <DRModal />;
    case 'AG':
      return <AGModal />;
    case 'RWD':
      return <RSWModal />;
    case 'CSM':
    default:
      return <CSMModal />;
  }
};

const CardInfoModal = ({ disabled, category: type, appName }: IProps) => {
  const { t } = useTranslation('coverage');

  const modalContent = useMemo(() => getModalContent(appName), [appName]);

  return (
    <ModalTrigger
      modalContent={<div className="px-5">{modalContent}</div>}
      disabled={disabled}
      modalTitle={t(`coverage.category.${type}.name`)}
    >
      <div
        className={`ml-2 flex h-5 w-5 place-content-center items-center rounded-full ${
          disabled
            ? 'bg-blue-600/20 dark:bg-[#1a2f6d]'
            : 'bg-tw-round-small-icon-color dark:bg-tw-round-small-icon-color/50'
        }`}
      >
        <QuestionMark
          width={'10px'}
          height={'10px'}
          className={classNames(
            disabled
              ? 'text-white dark:text-tw-main-text'
              : 'text-white dark:text-tw-main-text',
          )}
        />
      </div>
    </ModalTrigger>
  );
};

export default CardInfoModal;
