import { Link } from 'react-router-dom';
import ErrorCircleIcon from '../../../assets/icons/ErrorCircleIcon';

export const RegistrationFailureForm = () => {
  return (
    <div className="flex flex-col items-center gap-10 font-graphikRegular">
      <div
        className={`h-auto w-[28.375rem] rounded-2xl bg-white p-8 pb-6 dark:bg-tw-light-shade-dark`}
      >
        <div className="flex flex-col items-center gap-3 text-tw-main-text dark:text-tw-main-text-dark">
          <h1
            className="font-graphikBold"
            style={{ fontSize: '28px', lineHeight: '31px' }}
          >
            Unsuccessful
          </h1>
          <div className="flex flex-col items-center justify-start">
            <p className="text-center">
              Looks like your invitation code has expired or is wrong, please
              contact your admin
            </p>
          </div>
          <div className="py-5 text-[#E22134]">
            <ErrorCircleIcon height="94" width="94" />
          </div>
        </div>
      </div>
      <span className="font-graphikRegular">
        {`Already an account? `}
        <Link to={'/'} className="text-tw-main-color">
          Sign in
        </Link>
      </span>
    </div>
  );
};
