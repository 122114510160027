import React from 'react';

export const SecurityAndAuditIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M25.6531 15.7768L22.0227 10.7583C21.87 10.5471 21.6664 10.3746 21.4293 10.2555C21.1923 10.1364 20.9288 10.0742 20.6613 10.0742H11.3386C11.0712 10.0742 10.8077 10.1364 10.5706 10.2555C10.3336 10.3746 10.13 10.5471 9.97725 10.7583L6.34682 15.7768C6.15345 16.0441 6.04981 16.3619 6.0498 16.6875V21.2373C6.0498 22.1181 6.79227 22.832 7.70817 22.832H24.2918C25.2077 22.832 25.9501 22.1181 25.9501 21.2373V16.6875C25.9501 16.3619 25.8465 16.0441 25.6531 15.7768ZM11.3386 11.6689H20.6613L23.3531 15.39H19.1209L18.0154 17.5163H13.9846L12.879 15.39H8.6468L11.3386 11.6689ZM24.2918 21.2373H7.70817V16.9847H11.8541L12.9596 19.111H19.0403L20.1459 16.9847H24.2918V21.2373Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SecurityAndAuditIcon;
