import React from 'react';
import { twMerge } from 'tailwind-merge';

export const SpinnerIcon = ({
  className,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="100%"
      height="100%"
      aria-hidden="true"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      className={twMerge(
        'animate-spin fill-blue-600 text-gray-200 dark:text-gray-600',
        className,
      )}
      {...props}
    >
      <path
        opacity="0.3"
        d="M22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11ZM3.42999 11C3.42999 15.1808 6.8192 18.57 11 18.57C15.1808 18.57 18.57 15.1808 18.57 11C18.57 6.8192 15.1808 3.42999 11 3.42999C6.8192 3.42999 3.42999 6.8192 3.42999 11Z"
        fill="#2961EB"
      />
      <path
        d="M20.285 11C21.2322 11 22.0137 10.2276 21.8666 9.29193C21.7319 8.43521 21.4961 7.59542 21.1627 6.79048C20.6099 5.4559 19.7996 4.24327 18.7782 3.22183C17.7567 2.20038 16.5441 1.39013 15.2095 0.837325C14.4046 0.50391 13.5648 0.268087 12.7081 0.133422C11.7724 -0.0136531 11 0.767829 11 1.71499C11 2.66216 11.7774 3.41067 12.7004 3.62343C13.1082 3.71743 13.5084 3.84531 13.8969 4.00622C14.8154 4.38665 15.6499 4.94425 16.3528 5.64719C17.0557 6.35013 17.6134 7.18464 17.9938 8.10308C18.1547 8.49155 18.2826 8.89182 18.3766 9.29963C18.5893 10.2226 19.3378 11 20.285 11Z"
        fill="#2961EB"
      />
    </svg>
  );
};

export default SpinnerIcon;
