import {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { toast } from 'react-toastify';
import { messagesFromData } from '../ory/utils';

const onRequest = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = (
  error: AxiosError<{
    error?: {
      message?: string;
    };
  }>,
): Promise<AxiosError> => {
  const message =
    error.response?.data.error?.message || error.message || 'Server error';
  // Add exception to initial check session request

  if (error.response?.request.responseURL.includes('/kratos/')) {
    if (
      error.response?.status === 401 &&
      error.response?.request.responseURL.includes('/kratos/sessions/whoami')
    ) {
      return Promise.reject(error);
    }

    sdkError(error).catch(Promise.reject);
  } else {
    toast.error(message, {
      // Do not duplicate toast with same text
      toastId: `${message}`,
    });

    console.error(
      `[response error] [${JSON.stringify(error.response?.data.error)}]`,
    );
  }
  return Promise.reject(error);
};

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance,
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}

/**
 * @param error - Error
 */
export const sdkError = (
  error: AxiosError<any, unknown>,
): Promise<AxiosError | void> => {
  const responseData = error.response?.data || {};
  switch (error.response?.status) {
    case 400: {
      if (error.response.data?.error?.id === 'session_already_available') {
        console.warn(
          'sdkError 400: `session_already_available`. Navigate to /',
        );
        return Promise.resolve();
      }
      break;
    }
    case 401: {
      console.warn('sdkError 401: Navigate to /login');
      return Promise.resolve();
    }
    case 403: {
      // the user might have a session, but would require 2FA (Two-Factor Authentication)
      if (responseData.error?.id === 'session_aal2_required') {
        return Promise.resolve();
      }

      if (
        responseData.error?.id === 'session_refresh_required' &&
        responseData.redirect_browser_to
      ) {
        console.warn('sdkError 403: Redirect browser to');
        window.location = responseData.redirect_browser_to;
        return Promise.resolve();
      }
      break;
    }
    case 422: {
      if (responseData.redirect_browser_to !== undefined) {
        const currentUrl = new URL(window.location.href);
        const redirect = new URL(
          responseData.redirect_browser_to,
          // need to add the base url since the `redirect_browser_to` is a relative url with no hostname
          window.location.origin,
        );

        // Path has changed
        if (currentUrl.pathname !== redirect.pathname) {
          console.warn('sdkError 422: Update path');
          // remove /ui prefix from the path in case it is present (not setup correctly inside the project config)
          // since this is an SPA we don't need to redirect to the Account Experience.
          redirect.pathname = redirect.pathname.replace('/ui', '');
          // navigate(redirect.pathname + redirect.search, {
          //   replace: true,
          // });
          return Promise.resolve();
        }
      } else {
        console.warn('sdkError 422: Redirect browser to');
        window.location = responseData.redirect_browser_to;
        return Promise.resolve();
      }
    }
  }

  toast.error(
    messagesFromData(error.response?.data)
      .map((error) => error.text)
      .join(' ') || error.message,
  );

  throw error;
};
