import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  DELETE_MANAGED_DEVICE,
  GET_MANAGED_DEVICE,
  GET_MANAGED_DEVICES,
  UPDATE_MANAGED_DEVICE,
} from '../../api/inventory/queries';
import { REMOVE_MANAGED_DEVICE_LOCATION } from '../../api/location/queries';
import {
  DeleteManagedDeviceLocationMutation,
  DeleteManagedDeviceLocationMutationVariables,
  DeleteManagedDeviceMutation,
  DeleteManagedDeviceMutationVariables,
  GetManagedDeviceQuery,
  GetManagedDeviceQueryVariables,
  UpdateManagedDeviceMutation,
  UpdateManagedDeviceMutationVariables,
} from '../../gql/graphql';
import { SimpleButton } from '../Buttons/SimpleButton';
import { SpinnerWithText } from '../Elements/Spinner/SpinnerWithText';
import { Message } from '../common/Message';
import Modal from '../common/Modal/Modal';
import EditDeviceModal, { EditDeviceModalProps } from './EditDeviceModal';

export interface EditDeviceModalWithQueriesProps {
  id?: string;
  onClose?: () => void;
}

const EditDeviceModalWithQueries = ({
  id,
  onClose,
}: EditDeviceModalWithQueriesProps) => {
  const getManagedDevice = useQuery<
    GetManagedDeviceQuery,
    GetManagedDeviceQueryVariables
  >(GET_MANAGED_DEVICE, {
    variables: {
      guid: id || '',
    },
    fetchPolicy: 'network-only',
    skip: !id,
  });

  // Prevents data from hiding on close transition
  const [getManagedDeviceQuery, setGetManagedDeviceQuery] =
    useState(getManagedDevice);
  useEffect(() => {
    id && setGetManagedDeviceQuery(getManagedDevice);
  }, [getManagedDevice]);
  const managedDevice = useMemo(
    () => getManagedDeviceQuery?.data?.managedDevice,
    [getManagedDeviceQuery],
  );

  const [updateManagedDevice, updateManagedDeviceQuery] = useMutation<
    UpdateManagedDeviceMutation,
    UpdateManagedDeviceMutationVariables
  >(UPDATE_MANAGED_DEVICE, {
    onCompleted: (e) => {
      onClose?.();
      toast.success(
        `${
          e.updateManagedDevice.name || e.updateManagedDevice.guid
        } succesfully updated`,
      );
    },
    refetchQueries: [GET_MANAGED_DEVICE, GET_MANAGED_DEVICES],
  });
  const [removeManagedDeviceLocation, removeManagedDeviceLocationQuery] =
    useMutation<
      DeleteManagedDeviceLocationMutation,
      DeleteManagedDeviceLocationMutationVariables
    >(REMOVE_MANAGED_DEVICE_LOCATION, {
      refetchQueries: [GET_MANAGED_DEVICE, GET_MANAGED_DEVICES],
    });

  const handleUpdateManagedDevice: EditDeviceModalProps['onUpdate'] = (
    input,
  ) => {
    updateManagedDevice({
      variables: {
        input,
      },
    });

    if (managedDevice?.location && !input.location) {
      removeManagedDeviceLocation({
        variables: {
          guid: managedDevice.guid,
        },
      });
    }
  };

  const handleClose = () => {
    onClose?.();
  };

  const [deleteDevice] = useMutation<
    DeleteManagedDeviceMutation,
    DeleteManagedDeviceMutationVariables
  >(DELETE_MANAGED_DEVICE, {
    refetchQueries: [GET_MANAGED_DEVICES],
    onCompleted: (result) => {
      result?.deleteManagedDevice && onClose?.();
      result?.deleteManagedDevice &&
        toast.success(
          `'${
            managedDevice?.name || managedDevice?.guid
          }' managed device succesfully removed`,
        );
      !result?.deleteManagedDevice &&
        toast.error(
          `Error deleting '${
            managedDevice?.name || managedDevice?.guid
          }' managed device`,
        );
    },
    update(cache, updateData) {
      updateData.data?.deleteManagedDevice &&
        cache.modify({
          fields: {
            appliances(existing = []) {
              return existing.filter(
                (e: { id?: string; __ref?: string }) =>
                  e.id !== managedDevice?.guid &&
                  e.__ref !== `Appliance:${managedDevice?.guid}`,
              );
            },
          },
        });
    },
  });

  return (
    <Modal
      isOpen={Boolean(id)}
      onClose={handleClose}
      title={'Edit ' + (managedDevice?.name || 'Managed Device')}
    >
      <div
        data-test-value={managedDevice?.guid}
        className="flex w-96 flex-col items-start"
      >
        {getManagedDeviceQuery.loading ? (
          <SpinnerWithText text="Loading managed device" className="m-auto" />
        ) : getManagedDeviceQuery.error ? (
          <>
            <Message type="error">
              {getManagedDeviceQuery.error.message || 'Unexpected error'}
            </Message>
            <div className="flex w-96 flex-col items-end">
              <SimpleButton onClick={handleClose} className="ml-auto">
                Close
              </SimpleButton>
            </div>
          </>
        ) : (
          <EditDeviceModal
            device={managedDevice}
            onUpdate={handleUpdateManagedDevice}
            loading={
              updateManagedDeviceQuery.loading ||
              removeManagedDeviceLocationQuery.loading
            }
            error={updateManagedDeviceQuery.error?.message}
            onDeleteConfirm={async (device) => {
              return device?.guid
                ? deleteDevice({
                    variables: {
                      guid: device.guid,
                    },
                  })
                    .then((result) => result.data?.deleteManagedDevice || false)
                    .catch(() => false)
                : false;
            }}
          />
        )}
      </div>
    </Modal>
  );
};

export default EditDeviceModalWithQueries;
