import React from 'react';

export const ChevronLeftIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M4.31668 6.73502L8.0956 2.95611C8.6947 2.35701 8.6947 1.38924 8.0956 0.790143C7.4965 0.191046 6.52873 0.191046 5.92964 0.790143L1.0447 5.65972C0.4456 6.25882 0.4456 7.22659 1.0447 7.82568L5.91427 12.6953C6.51337 13.2944 7.48114 13.2944 8.08024 12.6953C8.67933 12.0962 8.67933 11.1284 8.08024 10.5293L4.31668 6.73502Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronLeftIcon;
