import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useFeature } from 'featurehub-react-sdk';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MAIN_TOOLTIP_ID } from '../../../App';
import { Icon, IconName } from '../../../assets/icons/Icon';
import { ApplianceStatus, GetInventoryQuery } from '../../../gql/graphql';
import { FeatureFlag } from '../../../utils/featureHub/constants';
import { useSession } from '../../../utils/hooks/useSession';
import { SimpleButton } from '../../Buttons/SimpleButton';
import { ApplianceCardDisclosurePanel } from './ApplianceCardDisclosurePanel';
import { APPLIANCE_HASH_START, AppliancesProps } from './Appliances';

export const ApplianceCardDisclosure = ({
  appliance,
  hashAppliance,
  onSettingsClick,
  onClickServerDetails,
}: {
  appliance: GetInventoryQuery['appliances']['0'];
  onSettingsClick: AppliancesProps['onSettingsClick'];
  onClickServerDetails?: AppliancesProps['onClickServerDetails'];
  hashAppliance?: string;
}) => {
  const startOpen = useMemo(
    () => hashAppliance === encodeURIComponent(appliance.applianceId),
    [appliance, hashAppliance],
  );

  // Programmatically opening the disclosure after initial render is currently not supported.
  // So workaround is getting the ref to the collapse button and pressing it in a useEffect...
  const btnRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(startOpen);

  useEffect(() => {
    if (startOpen) {
      const openBtn = btnRef?.current;
      if (!isOpen) {
        openBtn?.click();
      }
    }
  }, [startOpen]);

  const { isAdmin, isSuperAdmin } = useSession();

  const applianceOnlineStatusFlag = useFeature(
    FeatureFlag.APPLIANCE_ONLINE_STATUS,
  );

  const status =
    !appliance.online && applianceOnlineStatusFlag
      ? 'Offline'
      : appliance.status;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const handleDisclosureClick = useCallback(
    () =>
      // Clears hash on close
      isOpen &&
      navigate({
        search: searchParams.toString(),
        hash: isOpen
          ? ''
          : `${APPLIANCE_HASH_START}${encodeURIComponent(
              appliance.applianceId,
            )}`,
      }),
    [appliance, isOpen],
  );

  return (
    <div
      id={`appliance-${encodeURIComponent(appliance.applianceId)}`}
      data-testid="appliance-card-container"
      data-test-value={appliance.applianceId}
      key={appliance.id}
    >
      <Disclosure defaultOpen={startOpen}>
        {({ open }) => {
          setIsOpen(open);
          return (
            <>
              <Disclosure.Button
                data-testid="appliance-card-details-button"
                ref={btnRef}
                onClick={handleDisclosureClick}
                className={'w-full'}
              >
                <div
                  data-testid="appliance-card"
                  data-test-state={open ? 'open' : 'closed'}
                  className={classNames(
                    'border-md flex w-full flex-row items-center justify-between gap-4 border-tw-border bg-tw-background px-4 py-2 dark:border-tw-border-dark dark:bg-tw-background-dark',
                    open
                      ? 'rounded-t-lg border-x border-t'
                      : 'rounded-lg border',
                    'hover:bg-tw-light-hover hover:dark:bg-tw-light-hover-dark',
                  )}
                >
                  <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center gap-2.5">
                      <div
                        data-testid="appliance-card-status"
                        data-test-value={appliance}
                        data-tooltip-id={MAIN_TOOLTIP_ID}
                        data-tooltip-content={`Appliance is ${status.toLowerCase()}`}
                        className="relative"
                      >
                        <span
                          aria-description={status}
                          className={classNames(
                            'absolute -right-0.5 -top-0.5 h-2 w-2 rounded-full',
                            status === ApplianceStatus.Active &&
                              'bg-tw-success-strong dark:bg-tw-success-strong-dark',
                            (status === ApplianceStatus.Inactive ||
                              status === ApplianceStatus.Pending) &&
                              'bg-tw-warning-strong dark:bg-tw-warning-color-dark',
                            status === 'Offline' &&
                              'bg-tw-danger-strong dark:bg-tw-danger-strong-dark',
                          )}
                        />
                        <Icon
                          iconName={IconName.SupernaLogoNoText}
                          className="h-5 w-5 rounded p-1 dark:bg-white dark:text-tw-main-color"
                        />
                      </div>
                      <h6
                        className="font-graphikMedium text-sm"
                        data-testid="appliance-card-description"
                      >
                        {appliance.description}
                      </h6>
                    </div>
                  </div>

                  <div
                    className="flex flex-row items-center gap-3"
                    data-testid="appliance-card-actions"
                  >
                    {status === 'Offline' && (
                      <FontAwesomeIcon
                        data-tooltip-id={MAIN_TOOLTIP_ID}
                        data-tooltip-content={`Appliance is Offline`}
                        icon={faExclamation}
                        className="h-4 w-4 rounded bg-tw-danger-strong p-0.5 text-white dark:bg-tw-danger-strong-dark"
                        data-testid="alert-icon"
                      />
                    )}
                    {(isAdmin || isSuperAdmin) && (
                      <SimpleButton
                        variant="default-ghost"
                        data-testid="appliance-card-edit-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          onSettingsClick(appliance);
                        }}
                      >
                        <Icon
                          iconName={IconName.SettingsIcon}
                          height="14"
                          width="14"
                        />
                      </SimpleButton>
                    )}

                    <Icon
                      iconName={IconName.ChevronDownIcon}
                      data-test-value={open ? 'open' : 'closed'}
                      className={`transition-all duration-300 ${
                        !open ? '' : 'rotate-180'
                      }`}
                    />
                  </div>
                </div>
              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <ApplianceCardDisclosurePanel
                  appliance={appliance}
                  onClickServerDetails={onClickServerDetails}
                />
              </Transition>
            </>
          );
        }}
      </Disclosure>
    </div>
  );
};
