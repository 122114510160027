import { faDiscDrive, faRefresh } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure } from '@headlessui/react';
import { useFeature } from 'featurehub-react-sdk';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Spinner from '../../../components/Elements/Spinner/Spinner';
import { Message } from '../../../components/common/Message';
import { GetManagedDevicesQuery } from '../../../gql/graphql';
import { FeatureFlag } from '../../../utils/featureHub/constants';
import { SimpleButton } from '../../Buttons/SimpleButton';
import { Badge } from '../../common/Badge';
import { EmptyMessage } from '../common/EmptyMessage';
import ManagedCards from './ManagedCards';

const DEVICE_HASH_START = '#device-';

export interface ManagedDevicesProps {
  devices: Array<GetManagedDevicesQuery['managedDevices']['0']> | undefined;
  loading?: boolean;
  error?: string;
  onSettingsClick: (
    args: GetManagedDevicesQuery['managedDevices']['0'] | undefined,
  ) => void;
  refetch?: () => void;
}

const ManagedDevices = ({
  devices,
  onSettingsClick,
  loading,
  error,
  refetch,
}: ManagedDevicesProps) => {
  //RELEASE FLAGS
  const inventoryManagedDevicesFlag = useFeature(
    FeatureFlag.INVENTORY_MANAGED_DEVICES,
  );

  const location = useLocation();
  const hashDevice = useMemo(
    () =>
      location.hash?.startsWith(DEVICE_HASH_START)
        ? location.hash.replace(DEVICE_HASH_START, '')
        : undefined,
    [location.hash],
  );

  return (
    <div data-testid="devices-section" className="flex flex-col gap-3">
      <div className="flex flex-row items-center gap-4">
        <FontAwesomeIcon icon={faDiscDrive} className="h-5 w-5" />
        <h5
          className="font-graphikMedium text-tw-label-primary dark:text-tw-label-primary-dark"
          style={{ fontSize: '14px' }}
        >
          Managed Devices
        </h5>
        {!inventoryManagedDevicesFlag && (
          <Badge variant="warning">Coming soon</Badge>
        )}
      </div>
      {inventoryManagedDevicesFlag && (
        <div className="flex w-full flex-col gap-2 rounded-lg bg-tw-surface-strong p-3 dark:bg-tw-surface-strong-dark">
          {loading && <Spinner className="h-5 w-5" />}
          {error && (
            <Message type="error">
              <div className="flex items-center gap-3">
                {error}
                {refetch && (
                  <SimpleButton
                    onClick={() => refetch?.()}
                    variant="soft"
                    className="m-0 p-0"
                  >
                    <FontAwesomeIcon icon={faRefresh} />
                  </SimpleButton>
                )}
              </div>
            </Message>
          )}
          {!loading && !error && !devices?.length && (
            <EmptyMessage
              title="No Managed Devices"
              description="There are no managed devices."
            />
          )}
          {devices?.map((device) => (
            <div
              id={`device-${encodeURIComponent(device.guid)}`}
              data-testid="device-card-container"
              data-test-value={device.guid}
              key={device.guid}
            >
              <Disclosure
                defaultOpen={hashDevice === encodeURIComponent(device.guid)}
              >
                {({ open }) => (
                  <ManagedCards
                    open={open}
                    device={device}
                    onSettingsClick={onSettingsClick}
                  />
                )}
              </Disclosure>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ManagedDevices;
