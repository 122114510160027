import { QueryResult, useQuery } from '@apollo/client';
import { isEmpty } from 'ramda';
import { useEffect, useMemo } from 'react';
import { GET_ALERTS } from '../../api/alerts/queries';
import { rangeIdToValue } from '../../components/CalenderRangePicker/utils';
import { GetAlertsQuery, GetAlertsQueryVariables } from '../../gql/graphql';
import { useAppSelector } from '../../redux/hooks';
import { useSession } from '../../utils/hooks/useSession';

const POLL_INTERVAL = 5000;
const FIRST = 100;
const FETCH_MORE_FIRST = 100;

export interface UseAlertsQueryResult
  extends QueryResult<GetAlertsQuery, GetAlertsQueryVariables> {
  handleFetchMore: () => void;
  hasMore: boolean | undefined;
  newAlertsQuery:
    | QueryResult<GetAlertsQuery, GetAlertsQueryVariables>
    | undefined;
  clearCachedAlerts: (ids?: string[]) => void;
}

export const useAlertsQuery = ({
  filter,
  autoUpdateCache = true,
}: {
  filter?: Partial<GetAlertsQueryVariables['filter']>;
  autoUpdateCache?: boolean;
}): UseAlertsQueryResult => {
  const { skip } = useSession();

  const { products, duration, appliances, alertCodes, alertType } =
    useAppSelector((state) => state.productFilter);

  const dateRange = useMemo(() => {
    return rangeIdToValue(duration);
  }, [duration]);

  const variables: GetAlertsQueryVariables = useMemo(() => {
    return {
      filter: {
        ...(!isEmpty(products) && {
          productKind: products,
        }),
        ...(!isEmpty(alertCodes) && {
          code: alertCodes,
        }),
        from: dateRange?.from?.toISOString(),
        ...(!isEmpty(alertType) && {
          type: alertType,
        }),
        ...filter,
        appliance: appliances,
      },
      first: FIRST,
    };
  }, [dateRange, products, filter, appliances, alertCodes, alertType]);

  const queryResult: QueryResult<GetAlertsQuery, GetAlertsQueryVariables> =
    useQuery<GetAlertsQuery, GetAlertsQueryVariables>(GET_ALERTS, {
      variables,
      skip,
    });

  useEffect(() => {
    queryResult.refetch();
  }, [variables]);

  const startCursor = useMemo(
    () => queryResult.data?.alerts?.pageInfo?.startCursor,
    [queryResult.data],
  );

  const newAlertsQuery = useQuery<GetAlertsQuery, GetAlertsQueryVariables>(
    GET_ALERTS,
    {
      variables: {
        ...variables,
        before: startCursor,
        last: FIRST,
        first: undefined,
      },
      pollInterval: POLL_INTERVAL,
      skip,
    },
  );

  // refetch if there are items in newAlertsQuery but not in alerts
  useEffect(() => {
    !startCursor &&
      newAlertsQuery?.data?.alerts?.totalItems &&
      queryResult.refetch();
  }, [startCursor, newAlertsQuery]);

  useEffect(() => {
    if (autoUpdateCache && newAlertsQuery.data?.alerts?.nodes?.length) {
      queryResult.client.cache.updateQuery<
        GetAlertsQuery,
        GetAlertsQueryVariables
      >(
        {
          query: GET_ALERTS,
          variables,
        },
        (data) => {
          const mergedDataAlerts: GetAlertsQuery['alerts'] | null = data
            ? {
                ...newAlertsQuery.data?.alerts,
                ...data?.alerts,
                nodes: [
                  ...(newAlertsQuery.data?.alerts?.nodes || []),
                  ...(data?.alerts?.nodes || []),
                ],
                pageInfo: {
                  ...newAlertsQuery.data?.alerts?.pageInfo,
                  ...data?.alerts?.pageInfo,
                  startCursor:
                    newAlertsQuery.data?.alerts?.pageInfo?.startCursor || '',
                },
                totalItems:
                  (newAlertsQuery.data?.alerts?.nodes?.length || 0) +
                  (data?.alerts?.totalItems || 0),
              }
            : null;

          return data && mergedDataAlerts
            ? {
                ...data,
                alerts: mergedDataAlerts,
              }
            : null;
        },
      );
    }
  }, [newAlertsQuery.data]);

  const handleFetchMore = () => {
    return queryResult.fetchMore({
      variables: {
        ...variables,
        first: FETCH_MORE_FIRST,
        after: queryResult.data?.alerts?.pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult, variables: fetchVariables }) => {
        // Check if the fetchMore result refers to the same variables as the current ones
        const isNext =
          fetchVariables.after === prev?.alerts?.pageInfo.endCursor;

        if (!fetchMoreResult || !isNext) return prev;
        return {
          alerts: {
            ...fetchMoreResult.alerts,
            nodes: [
              ...(prev?.alerts?.nodes || []),
              ...fetchMoreResult.alerts.nodes,
            ],
            pageInfo: {
              ...prev?.alerts?.pageInfo,
              ...fetchMoreResult?.alerts?.pageInfo,
              startCursor,
            },
          },
        };
      },
    });
  };

  const hasMore = useMemo(
    () => queryResult.data?.alerts?.pageInfo?.hasNextPage,
    [queryResult.data],
  );

  const clearCachedAlerts = (ids?: string[]) => {
    // Clear all ids from cache
    if (!ids) {
      const alertsToClear = queryResult.data?.alerts?.nodes;

      queryResult.client.cache.updateQuery<
        GetAlertsQuery,
        GetAlertsQueryVariables
      >(
        {
          query: GET_ALERTS,
          variables: {
            ...variables,
            filter: {
              ...variables.filter,
              active: true,
            },
          },
        },
        (data) => {
          return data
            ? {
                ...data,
                alerts: {
                  ...data.alerts,
                  nodes: [],
                  totalItems: 0,
                },
              }
            : null;
        },
      );

      queryResult.client.cache.updateQuery<
        GetAlertsQuery,
        GetAlertsQueryVariables
      >(
        {
          query: GET_ALERTS,
          variables: {
            ...variables,
            filter: {
              ...variables.filter,
              active: false,
            },
          },
        },
        (data) => {
          const newNodes = (data?.alerts?.nodes || [])
            .concat(alertsToClear || [])
            .sort((a, b) => {
              if (!a?.createdAt || !b?.createdAt) return 0;
              return (
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
              );
            });

          return data && alertsToClear
            ? {
                ...data,
                alerts: {
                  ...data.alerts,
                  nodes: newNodes,
                  totalItems:
                    (data?.alerts?.totalItems || 0) + alertsToClear.length,
                },
              }
            : null;
        },
      );
    } else {
      const alertsToClear = queryResult.data?.alerts?.nodes
        ?.filter((alert) => ids.includes(alert?.id || ''))
        .map((alert) => ({
          ...alert,
          active: false,
        }));

      const filteredAlerts = queryResult.data?.alerts?.nodes?.filter(
        (alert) => !ids.includes(alert?.id || ''),
      );

      queryResult.client.cache.updateQuery<
        GetAlertsQuery,
        GetAlertsQueryVariables
      >(
        {
          query: GET_ALERTS,
          variables: {
            ...variables,
            filter: {
              ...variables.filter,
              active: true,
            },
          },
        },
        (data) => {
          return data && filteredAlerts
            ? {
                ...data,
                alerts: {
                  ...data.alerts,
                  nodes: filteredAlerts,
                  totalItems:
                    (data?.alerts?.totalItems || ids.length) - ids.length,
                },
              }
            : null;
        },
      );

      queryResult.client.cache.updateQuery<
        GetAlertsQuery,
        GetAlertsQueryVariables
      >(
        {
          query: GET_ALERTS,
          variables: {
            ...variables,
            filter: {
              ...variables.filter,
              active: false,
            },
          },
        },
        (data) => {
          const newNodes = (data?.alerts?.nodes || [])
            .concat(alertsToClear || [])
            .sort((a, b) => {
              if (!a?.createdAt || !b?.createdAt) return 0;
              return (
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
              );
            });

          return data && alertsToClear
            ? {
                ...data,
                alerts: {
                  ...data.alerts,
                  nodes: newNodes,
                  totalItems:
                    (data?.alerts?.totalItems || 0) + alertsToClear.length,
                },
              }
            : null;
        },
      );
    }
  };

  return {
    ...queryResult,
    handleFetchMore,
    hasMore,
    newAlertsQuery,
    clearCachedAlerts,
  };
};
