import { defaultTheme, Provider } from '@adobe/react-spectrum';
import React, { useEffect } from 'react';
import '../i18n';
import { useAppSelector } from '../redux/hooks';
import { useCDNTranslation } from '../utils/hooks/useCDNTranslation';

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const theme = useAppSelector((state) => state.theme.value);

  useCDNTranslation({
    ns: 'productInfo',
    path: `capabilities.json`,
  });

  // add class name to body
  useEffect(() => {
    document.body.classList.add(theme);
    return () => {
      document.body.classList.remove(theme);
    };
  }, [theme]);

  return (
    <div className={`${theme}`}>
      <Provider theme={defaultTheme} colorScheme={theme}>
        {children}
      </Provider>
    </div>
  );
};
