import { gql } from '@apollo/client';

export const GET_COVERAGES = gql`
  query GetCoverages(
    $productKind: String!
    $from: DateTime!
    $appliance: [String!]
  ) {
    coverage(
      filter: { productKind: $productKind, from: $from, appliance: $appliance }
    ) {
      health {
        key
        value
      }
    }
  }
`;

export const GET_INSTALLED_PRODUCTS = gql`
  query GetInstalledProducts {
    products {
      id
      kind
      categories
      name
    }
  }
`;

export const GET_INSTALLED_PRODUCTS_ALERTS = gql`
  query GetInstalledProductsAlerts($from: DateTime, $appliance: [String!]) {
    products {
      id
      kind
      categories
      name
      alertsCount(from: $from, appliance: $appliance) {
        critical
        error
        standard
      }
    }
  }
`;

export const GET_PRODUCT_ALERTS = gql`
  query GetProductAlerts(
    $from: DateTime!
    $appliance: [String!]
    $productKind: [String!]
  ) {
    CRITICAL: alerts(
      first: 1
      filter: {
        severity: CRITICAL
        from: $from
        active: true
        appliance: $appliance
        productKind: $productKind
      }
    ) {
      totalItems
    }
    ERROR: alerts(
      first: 1
      filter: {
        severity: ERROR
        from: $from
        active: true
        appliance: $appliance
        productKind: $productKind
      }
    ) {
      totalItems
    }
    STANDARD: alerts(
      first: 1
      filter: {
        severity: STANDARD
        from: $from
        active: true
        appliance: $appliance
        productKind: $productKind
      }
    ) {
      totalItems
    }
  }
`;
