import { ErrorBoundary } from 'react-error-boundary';
// import Toast from '../components/Toasts/Toast';
import { toast } from 'react-toastify';
import { Icon, IconName } from '../assets/icons/Icon';
import { SimpleButton } from '../components/Buttons/SimpleButton';

const ErrorFallback = ({
  error,
  resetErrorBoundary,
  title = 'Something went wrong',
  message,
}: {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
  title?: React.ReactNode;
  message?: React.ReactNode;
}) => {
  return (
    <div className="flex w-full flex-row gap-4 rounded bg-tw-critical-color/20 p-4">
      <Icon iconName={IconName.ErrorCircleIcon} className="h-6 w-6" />
      <div className="grow">
        <h2>{title}</h2>
        {message && (
          <div className="text-xs italic text-tw-description-text dark:text-tw-description-text-dark">
            {message}
          </div>
        )}
        {error instanceof Error && (
          <div className="text-xs italic text-tw-description-text dark:text-tw-description-text-dark">
            {error.message}
          </div>
        )}
        <div className="mt-2 flex">
          <SimpleButton className="ml-auto" onClick={resetErrorBoundary}>
            Try again
          </SimpleButton>
        </div>
      </div>
    </div>
  );
};

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  toast.error(error.message, {
    type: 'error',
  });
  console.log('THE ERROR: ', error);
  console.log('THE INFO: ', info);
};

export const ComponentErrorHandler = ({
  children,
  ...rest
}: {
  children: React.ReactNode;
  title?: React.ReactNode;
  message?: React.ReactNode;
}) => {
  return (
    <ErrorBoundary
      fallbackRender={(props) => <ErrorFallback {...props} {...rest} />}
      onError={myErrorHandler}
    >
      {children}
    </ErrorBoundary>
  );
};
