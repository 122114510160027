export const ErrorIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <circle cx="10" cy="10.8438" r="10" fill="#FB5057" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6.84375C10.4156 6.84375 10.75 7.20042 10.75 7.64375V10.8438C10.75 11.2871 10.4156 11.6438 10 11.6438C9.58438 11.6438 9.25 11.2871 9.25 10.8438V7.64375C9.25 7.20042 9.58438 6.84375 10 6.84375ZM9 13.7771C9 13.1871 9.44687 12.7104 10 12.7104C10.5531 12.7104 11 13.1871 11 13.7771C11 14.3671 10.5531 14.8438 10 14.8438C9.44687 14.8438 9 14.3671 9 13.7771Z"
        fill="white"
      />
    </svg>
  );
};
