import { useState } from 'react';
import { Link } from 'react-router-dom';
import OTPInput from './components/OTPInput';
import { SupernaHeader } from './components/SupernaHeader';
import { ThemeButton } from './components/ThemeButton';

export const RecoveryOTPPage = () => {
  const [otp, setOtp] = useState('');
  const onOtpChange = (value: string) => setOtp(value);
  const handleGenNew = () => {
    //
  };

  const onSubmit = () => {
    //otpFlow(data.otp);
  };

  return (
    <div className="h-screen overflow-hidden ">
      <ThemeButton />
      <div className="flex h-full flex-col items-center justify-center text-center">
        <SupernaHeader />
        <div className="mt-14 flex items-start justify-start gap-10">
          <div
            className={
              'rounded-2xl bg-white p-6 font-graphikRegular text-tw-main-text dark:bg-neutral-800 dark:text-tw-dark-shade'
            }
          >
            <h1 className="mt-2 pb-4 font-graphikBold text-[28px] leading-[31px]">
              Forgot Password
            </h1>
            <h5 className="mb-3 font-graphikRegular text-sm">
              Please enter the 6 digit code sent to your email address.
            </h5>

            <div className="group relative z-0 mb-3 w-full">
              <OTPInput value={otp} valueLength={6} onChange={onOtpChange} />

              <div className="pt-3">
                {/* Errors */}{' '}
                <a
                  className="font-graphikRegular text-blue-600"
                  href="#"
                  onClick={handleGenNew}
                >
                  Generate new
                </a>
              </div>

              <button
                disabled={otp?.length === 6 ? false : true}
                type="submit"
                onClick={onSubmit}
                className={`h-14 w-full rounded-xl font-graphikRegular ${
                  otp?.length === 6
                    ? 'bg-tw-main-color text-white hover:bg-blue-700'
                    : 'bg-blue-400/25 text-white/50'
                } mt-4`}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <div className="my-4 flex flex-row items-center justify-items-end gap-1 text-center">
          <Link
            to="/"
            className="text-sm font-normal text-tw-main-color underline"
          >
            Go home
          </Link>
        </div>
      </div>
    </div>
  );
};
