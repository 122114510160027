import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import alertCodesENUS from './localization/en-US/alertCodes.json';
import productInfoENUS from './localization/en-US/capabilities.json';
import commonENUS from './localization/en-US/common.json';
import coverageENUS from './localization/en-US/coverage.json';
import notificationsENUS from './localization/en-US/notifications.json';
import oryENUS from './localization/en-US/ory.json';
import userAccountENUS from './localization/en-US/userAccount.json';

const resources = {
  en: {
    alertCodes: alertCodesENUS,
    common: commonENUS,
    coverage: coverageENUS,
    notifications: notificationsENUS,
    ory: oryENUS,
    userAccount: userAccountENUS,
    productInfo: productInfoENUS,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resources,
    fallbackLng: 'en',
    // debug: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
