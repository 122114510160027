import { gql } from '@apollo/client';

export const GET_INVENTORY_APPLIANCE = gql`
  query GetInventoryAppliance($applianceId: String!) {
    appliance(id: $applianceId) {
      id
      applianceId
      description
      status
      online
      ipv4
      osVersion
      lastSeen
      location {
        id
        city
        country
        latitude
        longitude
      }
      installedProducts {
        id
        licenses {
          expiration
          licensedDevices {
            state
            device {
              ipv4
              guid
              name
              diskUsage
              diskCapacity
            }
          }
        }
        product {
          name
          kind
          categories
        }
      }
    }
  }
`;

export const GET_INVENTORY = gql`
  query GetInventory($filter: QueryApplianceInput) {
    appliances(filter: $filter) {
      id
      applianceId
      description
      node
      status
      online
      ipv4
      osVersion
      lastSeen
      location {
        id
        city
        country
        latitude
        longitude
      }
      servers {
        id
        createdAt
        ipv4
        name
        port
        state
        type
        updatedAt
      }
      installedProducts {
        id
        softwareVersion
        product {
          name
          kind
          categories
        }
        licenses {
          expiration
          perpetual
          kind
          sizeConsumed
          sizeLicensed
          licensedDevices {
            state
            device {
              ipv4
              guid
              name
              diskUsage
              diskCapacity
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_APPLIANCE = gql`
  mutation UpdateAppliance($input: UpdateApplianceInput!) {
    updateAppliance(input: $input) {
      id
      applianceId
      description
      node
      status
      online
      ipv4
      osVersion
      lastSeen
      location {
        id
        city
        country
        latitude
        longitude
      }
      installedProducts {
        id
        softwareVersion
        licenses {
          expiration
          licensedDevices {
            state
            device {
              ipv4
              guid
              name
              diskUsage
              diskCapacity
            }
          }
        }
        product {
          name
          kind
          categories
        }
      }
    }
  }
`;

export const GET_MANAGED_DEVICES = gql`
  query getManagedDevices {
    managedDevices {
      guid
      name
      ipv4
      diskCapacity
      diskUsage
      platform
      location {
        id
        city
        country
        latitude
        longitude
      }
      licenseLinks {
        state
        device {
          ipv4
          guid
          name
          diskUsage
          diskCapacity
        }
        license {
          expiration
          licensedDevices {
            state
            device {
              ipv4
              guid
              name
              diskUsage
              diskCapacity
            }
          }
          installedProduct {
            product {
              kind
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_MANAGED_DEVICE = gql`
  query GetManagedDevice($guid: ID!) {
    managedDevice(guid: $guid) {
      guid
      name
      ipv4
      diskCapacity
      diskUsage
      platform
      location {
        id
        city
        country
        latitude
        longitude
      }
      licenseLinks {
        state
        device {
          ipv4
          guid
          name
          diskUsage
          diskCapacity
        }
      }
    }
  }
`;

export const UPDATE_MANAGED_DEVICE = gql`
  mutation UpdateManagedDevice($input: UpdateManagedDeviceInput!) {
    updateManagedDevice(input: $input) {
      guid
      name
      ipv4
      diskCapacity
      diskUsage
      location {
        id
        city
        country
        latitude
        longitude
      }
      licenseLinks {
        state
        device {
          ipv4
          guid
          name
          diskUsage
          diskCapacity
        }
      }
    }
  }
`;

export const DELETE_APPLIANCE = gql`
  mutation DeleteAppliance($id: String!) {
    deleteAppliance(id: $id)
  }
`;

export const DELETE_MANAGED_DEVICE = gql`
  mutation DeleteManagedDevice($guid: ID!, $appliance: String) {
    deleteManagedDevice(appliance: $appliance, guid: $guid)
  }
`;

export const GET_SERVER_COMPONENTS = gql`
  query GetApplianceServerComponents($serverId: ID!, $applianceId: String!) {
    applianceServerComponents(serverId: $serverId, applianceId: $applianceId) {
      cpu
      details
      healthStatus
      name
      ram
      webAddress
    }
  }
`;
