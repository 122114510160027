import { useMutation } from '@apollo/client';
import { equals, isEmpty, mergeDeepRight } from 'ramda';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UPDATE_SETTINGS_APP_CONFIG } from '../../api/settings/queries';
import { UpdateSettingsAppConfigResponse } from '../../api/settings/types';
import Spinner from '../../components/Elements/Spinner/Spinner';
import { JSONObject } from '../../components/form/DynamicForm';
import {
  DynamicFormSchema,
  DynamicFormSchemaOnChangeEvent,
} from '../../components/form/DynamicForm/DynamicFormSchema';
import { useFetchAppSettings } from '../../components/form/DynamicForm/useFetchAppSettings';

const useSaveSettings = (id?: string) => {
  const [saveSettings, response] = useMutation<UpdateSettingsAppConfigResponse>(
    UPDATE_SETTINGS_APP_CONFIG,
  );

  const [nextConfig, setNextConfig] = useState<JSONObject>({});

  const [currentConfig, setCurrentConfig] = useState<JSONObject>();

  const saveConfig = async (config: JSONObject) => {
    setCurrentConfig(config);
    if (!id) {
      return {
        saved: false,
      };
    }
    try {
      const result = await saveSettings({
        variables: {
          id: id,
          config,
        },
      })
        .then((res) => {
          return res;
        })
        .catch((e) => {
          return e;
        });
      return {
        saved: result.errors ? false : true,
      };
    } catch (e) {
      return {
        saved: false,
      };
    }
  };

  useEffect(() => {
    if (isEmpty(nextConfig)) {
      return;
    }

    const equalsConfig = equals(nextConfig, currentConfig);

    !response.loading && !equalsConfig && saveConfig(nextConfig);
    equalsConfig && setNextConfig({});
  }, [nextConfig, currentConfig, response.loading]);

  const addDataToSave = (newData: JSONObject) => {
    setNextConfig(mergeDeepRight(nextConfig, newData));
  };

  return {
    saveConfig,
    addDataToSave,
    response,
    nextConfig,
  };
};

export const Settings = ({
  // TODO - this should be a required prop
  appName: propsAppName,
}: {
  appName?: string;
}) => {
  const { name: paramsAppName } = useParams();
  const appName = paramsAppName || propsAppName;

  const { data, loading, error, variables } = appName
    ? useFetchAppSettings(appName)
    : {
        data: undefined,
        loading: false,
        error: {
          message: 'No app name provided',
        },
        variables: undefined,
      };

  const { addDataToSave } = useSaveSettings(variables?.id);

  const handleChange = async ({
    valueJSON,
    isValid,
  }: DynamicFormSchemaOnChangeEvent) => {
    // merge dataToSave with valueJSON
    isValid && addDataToSave(valueJSON);
  };

  return loading ? (
    <div className="flex h-full items-center justify-center">
      <Spinner />
    </div>
  ) : error ? (
    <div className="m-auto flex max-w-prose flex-col items-center justify-center p-2">
      Could not load settings for {appName}:
      <div className="flex items-center justify-center text-xs text-red-500">
        {error?.message}
      </div>
    </div>
  ) : (
    <DynamicFormSchema
      configSchema={data?.appSettings?.configSchema?.value || {}}
      config={data?.appSettings?.config?.value || {}}
      onChange={handleChange}
    />
  );
};
