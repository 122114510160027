import classNames from 'classnames';
import { DetailedHTMLProps, InputHTMLAttributes, useId, useState } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { CheckCircleIcon } from '../../assets';
import { CircleCrossIcon } from '../Elements/Icons/CircleCrossIcon';
import { EyeIcon } from '../Elements/Icons/EyeIcon';
import { EyeSlashIcon } from '../Elements/Icons/EyeSlashIcon';
import { ErrorsField } from './ErrorsField';

interface IProps {
  label: string;
  register: UseFormRegisterReturn;
  error: FieldError | undefined;
  watchValue: string;
  isTouched?: boolean;
  otherError?: string;
}

export const InputField = ({
  label,
  register,
  error,
  watchValue,
  isTouched,
  otherError,
  ...props
}: IProps &
  DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >) => {
  const id = useId();
  const [passVisible, setPassVisible] = useState<boolean>(false);
  const togglePassVisible = () => setPassVisible((p) => !p);

  return (
    <div className="relative">
      <div className="group relative z-0 w-full">
        <input
          {...register}
          id={id}
          className={classNames(
            'text-md peer block h-[60px] w-full appearance-none rounded-lg bg-tw-light-shade pl-6 pr-16 pt-4 text-gray-900 placeholder-shown:pt-0',
            'focus:pt-4 focus:outline focus:outline-2 focus:outline-tw-main-color focus-visible:outline-tw-main-color',
            'dark:bg-tw-dark-shade-dark dark:text-tw-main-text-dark',
            (error || otherError) &&
              'text-red-600 outline outline-2 outline-red-600 dark:text-red-600',
          )}
          aria-invalid={Boolean(error || otherError)}
          placeholder=" "
          {...props}
          type={
            props.type === 'password'
              ? passVisible
                ? 'text'
                : 'password'
              : props.type
          }
        />
        <label
          htmlFor={id}
          className={classNames(
            'absolute transform font-graphikMedium text-[1rem] leading-[1.1rem] duration-300',
            '-translate-y-3',
            'left-6 top-[1.25rem] scale-75',
            'origin-[0] ',
            'peer-placeholder-shown:top-[1.563rem] peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 ',
            'peer-autofill:top-[1.25rem] peer-autofill:-translate-y-3 peer-autofill:scale-75 peer-focus:top-[1.25rem] peer-focus:-translate-y-3 peer-focus:scale-75',
            'pointer-events-none select-none',
            error || otherError
              ? 'text-red-600'
              : 'text-[#A4A4A4] dark:text-tw-description-text',
          )}
        >
          {label}
        </label>
        {props.type === 'password' && watchValue?.length > 0 && (
          <button
            type="button"
            onClick={togglePassVisible}
            className={`absolute right-6 top-[50%] -translate-y-[50%] scale-90 transform font-graphikRegular text-tw-description-text duration-300 hover:text-blue-600 dark:text-tw-description-text dark:hover:text-tw-main-color ${
              otherError && 'text-red-600 dark:text-red-600'
            }`}
            aria-label={passVisible ? 'hidePassword' : 'showPassword'}
            tabIndex={-1}
          >
            {passVisible ? (
              <EyeSlashIcon className="h-6 w-6" />
            ) : (
              <EyeIcon className="h-6 w-6" />
            )}
          </button>
        )}
        {isTouched && (
          <div className="absolute -right-7 top-0 flex h-[4.25rem] items-center align-middle">
            {error ? (
              <>
                <CircleCrossIcon width={16} height={16} />
              </>
            ) : (
              <CheckCircleIcon
                className="text-[#00BB7E]"
                width={16}
                height={16}
              />
            )}
          </div>
        )}
      </div>
      <div className="min-h-[18px] py-2">
        <ErrorsField error={error} />
      </div>
    </div>
  );
};
