import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface sidebarCollapseState {
  collapsed: boolean;
}

const initialState: sidebarCollapseState = {
  collapsed: false,
};

const sidebarCollapseSlice = createSlice({
  name: 'sidebarCollapse',
  initialState,
  reducers: {
    toggleSidebarCollapse(state) {
      state.collapsed = !state.collapsed;
    },
    setSidebarCollapse(state, action: PayloadAction<boolean>) {
      state.collapsed = action.payload;
    },
  },
});

export const { toggleSidebarCollapse, setSidebarCollapse } =
  sidebarCollapseSlice.actions;
export default sidebarCollapseSlice.reducer;
