import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query GetUsers($filter: QueryUserInput!) {
    users(filter: $filter) {
      id
      email
      name
      roles
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      email
      name
      roles
    }
  }
`;
export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      email
      name
      roles
    }
  }
`;
export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;
