import { useEffect, useState } from 'react';

export const useAwsCdn = <T>(bucketJsonLink: string) => {
  const [response, setResponse] = useState<T | null>(null);

  useEffect(() => {
    let controller: AbortController;

    const fetchData = async () => {
      try {
        controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 5000);

        const response = await fetch(bucketJsonLink, {
          signal: controller.signal,
        });

        clearTimeout(timeoutId);

        if (response.ok) {
          const data = await response.json();
          setResponse(data);
        } else {
          console.error(`Error fetching data from ${bucketJsonLink}`);
        }
      } catch (error) {
        console.error(`Error fetching data from ${bucketJsonLink}`, error);
      }
    };

    fetchData();

    return () => {
      // Abort ongoing request if the component is unmounted or the dependency changes
      if (controller) {
        controller.abort();
      }
    };
  }, [bucketJsonLink]);

  return response;
};
