import React from 'react';

export const ComplianceIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M23.6312 23.0957V10.334C23.6312 9.37323 22.881 8.59375 21.9564 8.59375H9.67477C8.75016 8.59375 8 9.37323 8 10.334V23.0957C8 24.0565 8.75016 24.8359 9.67477 24.8359H21.9564C22.881 24.8359 23.6312 24.0565 23.6312 23.0957ZM9.67477 23.0957V10.334H21.9564V23.0957H9.67477ZM11.3495 12.9443V13.5244C11.3495 13.8449 11.5994 14.1045 11.9078 14.1045H16.3738C16.6823 14.1045 16.9321 13.8449 16.9321 13.5244V12.9443C16.9321 12.6238 16.6823 12.3643 16.3738 12.3643H11.9078C11.5994 12.3643 11.3495 12.6238 11.3495 12.9443ZM11.3495 16.4248V17.0049C11.3495 17.3254 11.5994 17.585 11.9078 17.585H19.7234C20.0318 17.585 20.2816 17.3254 20.2816 17.0049V16.4248C20.2816 16.1043 20.0318 15.8447 19.7234 15.8447H11.9078C11.5994 15.8447 11.3495 16.1043 11.3495 16.4248ZM11.3495 19.9053V20.4854C11.3495 20.8058 11.5994 21.0654 11.9078 21.0654H14.1408C14.4493 21.0654 14.6991 20.8058 14.6991 20.4854V19.9053C14.6991 19.5848 14.4493 19.3252 14.1408 19.3252H11.9078C11.5994 19.3252 11.3495 19.5848 11.3495 19.9053Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ComplianceIcon;
