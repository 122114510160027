import { ProductKind } from '../../../../api/product/types';
import { PRODUCT_BACKGROUND_PATH, ProductPath } from './ProductPath';

export const ProductIconContainer = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      {props.children}
    </svg>
  );
};

export const ProductIconOnlyLogo = ({
  productKind,
  ...props
}: {
  productKind: `${ProductKind}` | string;
} & React.SVGProps<SVGSVGElement>) => {
  return (
    <ProductIconContainer {...props}>
      <ProductPath productKind={productKind} />
    </ProductIconContainer>
  );
};

export const ProductIconWithBg = ({
  productKind,
  ...props
}: {
  productKind: `${ProductKind}`;
} & React.SVGProps<SVGSVGElement>) => {
  return (
    <ProductIconContainer {...props}>
      {PRODUCT_BACKGROUND_PATH}
      <ProductPath productKind={productKind} />
    </ProductIconContainer>
  );
};

export const ProductIconWithRoundedBgAndBorder = ({
  productKind,
  ...props
}: {
  productKind: `${ProductKind}`;
} & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <rect width="66" height="66" x="1" y="1" rx="68" fill="currentFill" />
      <g transform="translate(5,5)" stroke="none">
        <ProductPath productKind={productKind} />
      </g>
    </svg>
  );
};
