import { useEffect, useMemo, useState } from 'react';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { Label } from '../../fields';
import { FormDataTable } from '../DynamicFormDataTable';
import { FormElement } from '../FormElement';
import { ChangeHandler, FormFieldElement, FormFieldValue } from '../types';
import { updateFormFieldsValue, validateFields } from '../utils';

export const AddRowForm = ({
  field,
  onAdd,
}: {
  field: FormDataTable;
  onAdd: (values: { [x: string]: FormFieldValue }) => void;
  defaultValues?: { [x: string]: FormFieldValue };
}) => {
  const idPrefix = useMemo(() => `${field.id}-`, [field.id]);

  const defaultFields = useMemo(
    (): FormFieldElement[] =>
      field.columns.map((col) => ({
        ...col,
        value: col.value || col.defaultValue || (undefined as any),
        id: `${idPrefix}${col.id}`,
      })),
    [field.columns, idPrefix],
  );

  const [fieldColumnsWithValues, setFieldColumnsWithValues] =
    useState<FormFieldElement[]>(defaultFields);

  useEffect(() => {
    setFieldColumnsWithValues(
      field.columns.map((col) => ({
        ...col,
        value: col.value || col.defaultValue || (undefined as any),
        id: `${idPrefix}${col.id}`,
      })),
    );
  }, [field.columns, idPrefix]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { isValid, validatedFormFields } = validateFields(
      fieldColumnsWithValues,
    );
    if (!isValid) {
      setFieldColumnsWithValues(validatedFormFields);
    } else {
      setFieldColumnsWithValues(defaultFields);
      onAdd(
        validatedFormFields.reduce((acc, cur) => {
          // remove the field id prefix
          const id = cur.id.split(idPrefix).join('');
          return { ...acc, [id]: cur.value };
        }, {}),
      );
    }
  };

  const handleChange: ChangeHandler = ({ id, value }) => {
    setFieldColumnsWithValues((prev) => updateFormFieldsValue(prev, id, value));
  };

  return (
    <form
      className="mt-2 flex flex-col gap-2 border-t border-gray-200 p-2"
      onSubmit={handleSubmit}
    >
      <Label size="large">Add row</Label>
      {fieldColumnsWithValues.map((col) => (
        <FormElement key={col.id} field={col} onChange={handleChange} />
      ))}
      <div className="flex justify-end gap-2">
        <PrimaryButton type="submit" size="small">
          Add row
        </PrimaryButton>
      </div>
    </form>
  );
};
