import { useQuery } from '@apollo/client';
import { useFeature } from 'featurehub-react-sdk';
import { replace } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_INSTALLED_PRODUCTS } from '../api/dashboard/queries';
import { ProductKind } from '../api/product/types';
import { GetInstalledProductsQuery } from '../gql/graphql';
import { FeatureFlag } from '../utils/featureHub/constants';
import { useSession } from '../utils/hooks/useSession';
import { preFetch } from './MicroFrontend';
import { BasePlugin, ExtendedPlugin } from './types';

const formatter = (value: string): string => {
  return replace(value.toLowerCase(), ' ', '-');
};

export const usePlugins = () => {
  const { t } = useTranslation('productInfo');

  const { skip } = useSession();

  const [plugins, setPlugins] = useState<ExtendedPlugin[]>([]);

  //RELEASE FLAG
  const prodSwitch = useFeature(FeatureFlag.PROD_SWITCH);

  const { data, loading } = useQuery<GetInstalledProductsQuery>(
    GET_INSTALLED_PRODUCTS,
    {
      skip: skip || !prodSwitch,
    },
  );

  const loadedPlugins: BasePlugin[] = useMemo(
    () =>
      data?.products?.map(
        (product): BasePlugin => ({
          id: product.id,
          path: formatter(t(`${product.kind}.name`, product.kind)),
          kind: product.kind as ProductKind,
          buildPath:
            // TODO remove this temporary hardcode
            product.kind.toLowerCase() === 'rwd'
              ? 'rsw'
              : formatter(product.kind),
          title: t(`${product.kind}.name`, product.kind),
          description: t(`${product.kind}.summary`, product.kind),
          installed: true,
        }),
      ) || [],
    [data],
  );

  useEffect(() => {
    setPlugins(
      loadedPlugins
        .filter((plugin) => plugin.installed)
        .map(
          (plugin): ExtendedPlugin => ({
            ...plugin,
            fetched: false,
            onLoad: handleFetch,
          }),
        ),
    );
    for (const plugin of loadedPlugins) {
      preFetch(plugin, handleFetch);
    }
  }, [loadedPlugins]);

  const handleFetch = ({
    plugin: loadedPlugin,
  }: {
    plugin: ExtendedPlugin;
  }) => {
    setPlugins((prev) =>
      prev.map((prevPlugin) =>
        prevPlugin.buildPath === loadedPlugin.buildPath
          ? {
              ...prevPlugin,
              fetched: true,
              ...loadedPlugin,
            }
          : prevPlugin,
      ),
    );
  };

  return { plugins, loading };
};
