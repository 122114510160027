import React from 'react';

export const CheckCircleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M23.2375 29.2375C22.5563 29.9187 21.4437 29.9187 20.7625 29.2375L16.7625 25.2375C16.0813 24.5562 16.0813 23.4437 16.7625 22.7625C17.4437 22.0813 18.5563 22.0813 19.2375 22.7625L22 25.525L28.7625 18.7625C29.4438 18.0813 30.5562 18.0813 31.2375 18.7625C31.9187 19.4437 31.9187 20.5563 31.2375 21.2375L23.2375 29.2375ZM40 24C40 32.8375 32.8375 40 24 40C15.1625 40 8 32.8375 8 24C8 15.1625 15.1625 8 24 8C32.8375 8 40 15.1625 40 24ZM24 11C16.8188 11 11 16.8188 11 24C11 31.1812 16.8188 37 24 37C31.1812 37 37 31.1812 37 24C37 16.8188 31.1812 11 24 11Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CheckCircleIcon;
