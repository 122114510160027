import { gql } from '@apollo/client';

export const GET_AVAILABLE_STATS = gql`
  query getAvailableStats {
    products {
      id
      kind
      statsAvailable {
        code
        title
        description
        unit
        polarity
      }
    }
  }
`;

export const GET_APP_AVAILABLE_STATS = gql`
  query getAppAvailableStats($kind: String!) {
    product(kind: $kind) {
      id
      kind
      statsAvailable {
        code
        title
        description
        unit
        polarity
      }
    }
  }
`;
