import React from 'react';

export const TermIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g clipPath="url(#clip0_1_3496)">
        <path
          d="M12.9277 1H11.9333C11.3832 1 10.9388 1.44687 10.9388 2C10.9388 2.55313 11.3832 3 11.9333 3H12.9277C13.4778 3 13.9222 3.44688 13.9222 4V5.17188C13.9222 5.96875 14.236 6.73125 14.7954 7.29375L15.4978 8L14.7954 8.70625C14.236 9.26875 13.9222 10.0312 13.9222 10.8281V12C13.9222 12.5531 13.4778 13 12.9277 13H11.9333C11.3832 13 10.9388 13.4469 10.9388 14C10.9388 14.5531 11.3832 15 11.9333 15H12.9277C14.5748 15 15.9111 13.6562 15.9111 12V10.8281C15.9111 10.5625 16.0167 10.3094 16.2032 10.1219L17.6109 8.70625C17.9994 8.31563 17.9994 7.68125 17.6109 7.29063L16.2032 5.875C16.0167 5.6875 15.9111 5.43437 15.9111 5.16875V4C15.9111 2.34375 14.5748 1 12.9277 1ZM4.97218 1C3.32513 1 1.98884 2.34375 1.98884 4V5.17188C1.98884 5.4375 1.88318 5.69063 1.69673 5.87813L0.292074 7.29375C-0.0963813 7.68437 -0.0963813 8.31875 0.292074 8.70938L1.69983 10.125C1.88629 10.3125 1.99195 10.5656 1.99195 10.8313V12C1.99195 13.6562 3.32824 15 4.97529 15H5.96973C6.51978 15 6.96417 14.5531 6.96417 14C6.96417 13.4469 6.51978 13 5.96973 13H4.97218C4.42213 13 3.97773 12.5531 3.97773 12V10.8281C3.97773 10.0312 3.66386 9.26875 3.10449 8.70625L2.40216 8L3.10449 7.29375C3.66386 6.73125 3.97773 5.96875 3.97773 5.17188V4C3.97773 3.44688 4.42213 3 4.97218 3H5.96662C6.51667 3 6.96107 2.55313 6.96107 2C6.96107 1.44687 6.51667 1 5.96662 1H4.97218Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_3496">
          <rect width="17.9" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TermIcon;
