import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { twMerge } from 'tailwind-merge';
import { MAIN_TOOLTIP_ID } from '../../../App';
import { Alert, AlertSeverity } from '../../../gql/graphql';
import { formatNumber } from '../../../utils/format';
import { ConfirmationModalTrigger } from '../../common/Modal';
import Spinner from '../Spinner/Spinner';
import AlertGroup, { AlertComponentBone } from './Alert';

const ALERT_HEIGHT = 84;
const ALERTS_PER_SCROLL = 5;

export interface IAlertsGroupProps {
  alerts: AlertGroupedByCode[];
  severityGroupKey: string;
  selectedId?: string;
  onItemClick?: (alert: AlertGroupedByCode) => void;
  onClearAllClick?: () => void;
  severity?: AlertSeverity[];
  count?: number;
  cleared?: boolean;
  onClear?: (ids: string[]) => Promise<boolean>;
}

export const getAlertKey = (severityGroupKey: string, id?: string) =>
  `alert-${severityGroupKey}-${id}`;

export interface IAlertListProps {
  alerts?: Alert[];
  loading?: boolean;
  onFetchMore: () => void;
  hasMore?: boolean;
  error?: string;
  totalCount?: number;
  keyId: string;
  onClear?: (
    clearAll: boolean,
  ) => ((ids: string[]) => Promise<boolean>) | undefined;
  onClearAll?: () => Promise<boolean>;
  clearAllLoading?: boolean;
  title?: string;
  className?: string;
  selectedId: string;
  onSelect?: (alert: AlertGroupedByCode | null) => void;
  onUpdateSelected?: (alert: AlertGroupedByCode | null) => void;
  refetch?: () => void;
}

export interface AlertGroupedByCode extends Alert {
  count: number;
  key: string;
  first: string;
  // At least id and created at is required
  alerts: (Partial<Alert> & {
    id: Alert['id'];
    createdAt: Alert['createdAt'];
  })[];
}

export const getAlertGroupKey = (alert?: Alert | AlertGroupedByCode | null) =>
  alert ? `${alert.appliance.applianceId}-${alert.code}` : '';

export const AlertList = ({
  alerts,
  onSelect,
  onUpdateSelected,
  loading,
  onFetchMore,
  hasMore = false,
  error,
  totalCount,
  keyId,
  onClear,
  onClearAll,
  refetch,
  clearAllLoading,
  title,
  className,
  selectedId,
  ...rest
}: IAlertListProps) => {
  const handleItemClick = useCallback(
    (alert?: AlertGroupedByCode) => {
      onSelect?.(alert || null);
    },
    [onSelect],
  );

  const { t } = useTranslation('notifications');

  const handleClearAllClick = useCallback(async () => {
    if (alerts && onClearAll) {
      onClearAll?.()
        .then(() => {
          refetch?.();
          return true;
        })
        .catch(() => {
          refetch?.();
          return false;
        });
    }
    return true;
  }, [onClearAll, alerts]);

  const scrollableDivId = useMemo(() => `scrollableDiv-${keyId}`, [keyId]);

  const alertGroups = useMemo(() => {
    const alertsObject = alerts?.reduce<{ [key: string]: AlertGroupedByCode }>(
      (acc, alert) => {
        // Grouped by appliance and alert code
        const key = getAlertGroupKey(alert);
        const alerts = [...(acc[key]?.alerts || []), alert]
          // Clear unnecessary data
          .map(({ id, createdAt }) => ({
            id,
            createdAt,
          }));
        acc[key] = {
          // The main data is the last in time occurrence
          ...(acc[key] || alert),
          key,
          count: alerts.length,
          alerts,
        };
        return acc;
      },
      {},
    );
    return Object.values(alertsObject || {}).sort(
      (a, b) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(),
    );
  }, [alerts]);

  useEffect(() => {
    if (onUpdateSelected) {
      const selectedAlert = alertGroups?.find((alert) =>
        alert.alerts.some((a) => a.id === selectedId),
      );
      selectedAlert &&
        selectedAlert.id !== selectedId &&
        onUpdateSelected(selectedAlert);
    }
  }, [alertGroups]);

  const [lastConsecutiveAlertsCount, setLastConsecutiveAlertsCount] =
    useState(0);
  // Call onFetchMore when minimum alerts are less than ALERTS_PER_SCROLL but there are more alerts to fetch
  useEffect(() => {
    const consecutiveAlertsCount = (alertGroups || []).length;
    if (
      !loading &&
      hasMore &&
      // If there are less than ALERTS_PER_SCROLL alerts, fetch more
      // If there are the same number of consecutiveCodeAlerts, fetch more
      (consecutiveAlertsCount <= ALERTS_PER_SCROLL ||
        lastConsecutiveAlertsCount === consecutiveAlertsCount)
    ) {
      onFetchMore();
    }
    setLastConsecutiveAlertsCount(consecutiveAlertsCount);
  }, [alertGroups]);

  const formattedAlertsCount = formatNumber(totalCount || 0);
  const formattedAlertsCountExtended =
    totalCount && totalCount > 1000
      ? Intl.NumberFormat('en', {
          maximumSignificantDigits: 5,
          notation: 'standard',
        }).format(totalCount || 0)
      : undefined;

  return (
    <section
      data-testid={`${keyId}-container`}
      className={twMerge(classNames('overflow-hidden rounded-lg', className))}
      {...rest}
    >
      <header
        data-testid={`${keyId}-header`}
        className="z-10 flex bg-[#FBFBFB] px-5 py-2 text-[#131517] dark:bg-tw-light-bg-dark dark:text-tw-main-text-dark"
      >
        <p
          data-testid={`${keyId}-title`}
          className="font-graphikRegular"
          style={{ fontSize: '14px', lineHeight: '15px' }}
        >
          {title || keyId}
          <span
            data-tooltip-id={MAIN_TOOLTIP_ID}
            data-tooltip-content={formattedAlertsCountExtended}
          >
            {totalCount ? ` (${formattedAlertsCount})` : ''}
          </span>
        </p>
        {onClearAll && !error && (
          <div className="ml-auto flex items-center font-graphikRegular text-neutral-800 dark:text-neutral-100">
            <ConfirmationModalTrigger
              modalTitle={`Clear ${totalCount} ${title} alerts`}
              modalContent={`Are you sure you want to clear all ${totalCount} ${title} alerts?`}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onConfirm={handleClearAllClick}
              disabled={clearAllLoading}
              data-testid={`${keyId}-clear-all`}
              aria-label={`${t('notifications.clearAll')} ${keyId}`}
              className="ml-auto flex items-center font-graphikRegular text-neutral-800 dark:text-neutral-100"
              style={{ fontSize: '12px', lineHeight: '13px' }}
            >
              <>
                {clearAllLoading && <Spinner className="mr-2 h-4 w-4" />}
                {t('notifications.clearAll')}
              </>
            </ConfirmationModalTrigger>
          </div>
        )}
      </header>
      <div
        id={scrollableDivId}
        className="scrollbar relative snap-y overflow-auto bg-tw-dark-shade dark:bg-tw-light-shade-dark"
        style={{
          maxHeight: ALERT_HEIGHT * ALERTS_PER_SCROLL,
        }}
      >
        <div
          data-testid={`${keyId}-list`}
          className={classNames(
            'grow divide-y-2 divide-tw-light-bg rounded-lg',
            alertGroups?.length === 0 && 'shadow',
            'dark:divide-tw-dark-shade-dark',
          )}
        >
          {loading && <AlertComponentBone />}
          <InfiniteScroll
            dataLength={alerts?.length || 0}
            next={onFetchMore}
            hasMore={hasMore}
            loader={<AlertComponentBone />}
            className="snap-y divide-y-2 divide-tw-light-bg overflow-visible dark:divide-tw-dark-shade-dark dark:bg-tw-light-shade-dark"
            {...(!hasMore && {
              style: {
                // For the snap to work
                overflow: 'visible',
              },
            })}
            scrollableTarget={scrollableDivId}
          >
            <AlertSeverityGroup
              alerts={alertGroups || []}
              count={totalCount}
              severityGroupKey={keyId}
              selectedId={selectedId}
              onItemClick={handleItemClick}
              onClear={onClear?.(false)}
            />
          </InfiniteScroll>
          {error && (
            <div className="flex h-20 items-center justify-center">
              <p className="text-red-500">Error loading alerts</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export const AlertSeverityGroup = ({
  alerts,
  onItemClick,
  severityGroupKey,
  selectedId,
  onClear,
  ...rest
}: IAlertsGroupProps) => {
  return (
    <div
      className="divide-y-2 divide-tw-light-bg dark:divide-tw-dark-shade-dark dark:bg-tw-light-shade-dark"
      data-testid={`${severityGroupKey}-list`}
      // rest used for data-testid
      {...rest}
    >
      {alerts.map((alertGroup) => {
        const key = getAlertKey(severityGroupKey, alertGroup.key);
        const selectedKey = getAlertKey(severityGroupKey, selectedId);

        return (
          <AlertGroup
            data={alertGroup}
            key={key}
            onClick={onItemClick}
            active={selectedKey === key}
            onClear={onClear}
            data-test-value={alertGroup.key}
            data-testid={'alert-group'}
          />
        );
      })}
    </div>
  );
};

export default AlertList;
