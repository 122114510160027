import React from 'react';

export const AlertsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <rect
        x="8.83594"
        y="5.45703"
        width="1.86667"
        height="6.41667"
        rx="0.933333"
        fill="currentColor"
      />
      <rect
        x="8.83594"
        y="13.7402"
        width="1.86667"
        height="1.86667"
        rx="0.933333"
        fill="currentColor"
      />
      <circle
        cx="10.0013"
        cy="10.1234"
        r="8.33333"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};

export default AlertsIcon;
