import { gql } from '@apollo/client';

export const GET_AVAILABLE_PUBLIC_PRODUCTS = gql`
  query GetAvailablePublicProducts {
    products {
      kind
      name
      categories
      comingSoon
    }
  }
`;

export const REQUEST_TRIAL = gql`
  mutation RequestTrial($input: RequestTrialInput!) {
    requestTrial(input: $input)
  }
`;
