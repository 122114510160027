import { Text } from '@adobe/react-spectrum';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { isNil } from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { GET_CHART } from '../../../../api/chart/queries';
import { GET_APP_AVAILABLE_STATS } from '../../../../api/product/queries';
import {
  ChartInfo,
  ChartPolarity,
  GetAppAvailableStatsQuery,
  GetAppAvailableStatsQueryVariables,
  GetChartQuery,
  GetChartQueryVariables,
} from '../../../../gql/graphql';
import { useAppSelector } from '../../../../redux/hooks';
import { formatDiffPercent, humanizeValue } from '../../../../utils/format';
import { useSession } from '../../../../utils/hooks/useSession';
import { rangeIdToValue } from '../../../CalenderRangePicker/utils';
import Spinner from '../../../Elements/Spinner/Spinner';
import { GraphLine } from '../../Graph';

export const CardStatsLoader = ({ productKind }: { productKind: string }) => {
  const { duration, appliances } = useAppSelector(
    (state) => state.productFilter,
  );

  const dateRange = useMemo(() => {
    return rangeIdToValue(duration);
  }, [duration]);

  const { skip } = useSession();

  const { data } = useQuery<
    GetAppAvailableStatsQuery,
    GetAppAvailableStatsQueryVariables
  >(GET_APP_AVAILABLE_STATS, {
    variables: {
      kind: productKind,
    },
    skip,
  });

  const variables = useMemo(() => {
    return data?.product?.statsAvailable?.map((chart) => ({
      productKind,
      chartCode: chart.code,
      from: dateRange?.from || undefined,
      appliances: appliances,
      chart,
    }));
  }, [data?.product?.statsAvailable, productKind, dateRange, appliances]);

  return variables && Boolean(variables.length) ? (
    <div className="flex flex-row justify-between py-6">
      {/* This container is used for the border */}
      <div className="mx-2 flex w-full flex-row flex-wrap justify-between gap-0.5 bg-tw-light-bg dark:bg-tw-dark-shade-dark">
        {variables.map((variable) => (
          <CardStatLoader
            key={`${variable.productKind}-${variable.chartCode}`}
            variables={variable}
            chartInfo={variable.chart}
          />
        ))}
      </div>
    </div>
  ) : null;
};

export const CardStatLoader = ({
  variables,
  chartInfo,
}: {
  variables: GetChartQueryVariables;
  chartInfo?: ChartInfo;
}) => {
  const { skip } = useSession();
  const { data, loading } = useQuery<GetChartQuery, GetChartQueryVariables>(
    GET_CHART,
    {
      variables,
      // 5 minutes
      pollInterval: 5 * 60 * 1000,
      skip,
    },
  );

  const [lastLoadedChart, setLastLoadedChart] = useState<
    GetChartQuery['chart'] | undefined
  >(data?.chart);

  useEffect(() => {
    data?.chart && setLastLoadedChart(data?.chart);
  }, [data?.chart]);

  return (
    <CardStat stat={lastLoadedChart} loading={loading} chartInfo={chartInfo} />
  );
};

export const CardStat = ({
  stat,
  loading,
  chartInfo,
}: {
  stat?: GetChartQuery['chart'];
  loading: boolean;
  chartInfo?: ChartInfo;
}) => {
  const { language } = useAppSelector((state) => state.locale);
  const actualValue = useMemo(() => {
    return !isNil(stat?.value)
      ? humanizeValue({
          value: stat?.value || 0,
          locale: language,
          unit: chartInfo?.unit || '',
          // Hide units like Files, Events, etc. Inferred from the title
          showCustomUnit: false,
        })
      : 'No data';
  }, [stat, language, chartInfo]);
  const diffPercentage = useMemo(() => {
    if (!stat?.value || !stat?.diffPercentage) return '';

    return formatDiffPercent(stat?.diffPercentage);
  }, [stat]);

  const polarityClassName = useMemo(() => {
    if (!chartInfo?.polarity) return '';

    const polaritySign =
      chartInfo?.polarity === ChartPolarity.Positive
        ? 1
        : chartInfo?.polarity === ChartPolarity.Negative
        ? -1
        : 0;
    const diffVal = stat?.diffPercentage || 0;
    const diffSign = diffVal > 0 ? 1 : diffVal < 0 ? -1 : 0;
    const diffSignWithPolarity = polaritySign * diffSign;
    return diffSignWithPolarity > 0
      ? 'text-green-500'
      : diffSignWithPolarity < 0
      ? 'text-red-500'
      : 'text-tw-description-text dark:text-tw-description-text-dark';
  }, [chartInfo?.polarity, stat?.diffPercentage]);

  return (
    <div
      className={'w-80 grow bg-tw-dark-shade px-4 dark:bg-tw-light-shade-dark'}
    >
      <div className="flex flex-col items-center justify-center">
        <div className="flex w-full flex-row items-center">
          <div className="flex flex-col">
            <Text marginBottom="size-50">
              <p className="mb-2 font-graphikRegular text-tw-description-text">
                {/* TODO get the title from backend */}
                {chartInfo?.title || stat?.code}
              </p>
            </Text>
            <div className="flex flex-row items-center gap-2">
              <h3 className="whitespace-pre font-graphikSemi text-2xl text-tw-main-text dark:text-tw-main-text-dark">
                {actualValue}
              </h3>
              <h4
                className={classNames(
                  'font-graphikRegular text-base',
                  polarityClassName,
                )}
              >
                {diffPercentage}
              </h4>
            </div>
          </div>

          <div className="relative flex h-28 w-min min-w-[10rem] grow">
            {loading && (
              <div className="absolute right-2 top-2 z-10 h-6 w-6">
                <Spinner
                  className="rounded-full bg-tw-dark-shade ring-4
                ring-tw-dark-shade dark:bg-tw-light-shade-dark dark:ring-tw-light-shade-dark"
                />
              </div>
            )}
            {stat?.data?.values && Boolean(stat?.data?.values?.length) && (
              <GraphLine
                timeRange={stat.timeRange}
                statsData={stat.data}
                unit={chartInfo?.unit || ''}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardStatsLoader;
