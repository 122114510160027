import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useAppSelector } from '../../../redux/hooks';
import { SimpleButton } from '../../Buttons/SimpleButton';

export function Logout({ onClick }: { onClick?: () => void }) {
  const sidebarCollapsed = useAppSelector(
    (state) => state.sidebarCollapse.collapsed,
  );

  return (
    <SimpleButton
      data-testid="logout"
      variant="default-ghost"
      className="m-0 flex flex-row items-center gap-2 rounded-[4px] bg-transparent p-2 py-1 transition-colors hover:bg-tw-strong-shade dark:hover:bg-[#2D3036]"
      onClick={onClick}
    >
      <div
        className={classNames(
          'flex items-center justify-center',
          'h-[30px] w-[30px] rounded-lg',
          sidebarCollapsed && 'm-auto',
        )}
      >
        <FontAwesomeIcon className="h-3 w-3" icon={faArrowRightFromBracket} />
      </div>
      {!sidebarCollapsed && <span>Sign Out</span>}
    </SimpleButton>
  );
}
