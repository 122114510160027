import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { isNil } from 'ramda';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { IconComponent, IconName } from '../../../../assets/icons/Icon';
import { FieldError, Label } from '../Common';
import { RadioCheck } from '../RadioGroup/RadioCheck';
import { SelectionType, Size, Variant } from '../types';
import { optionsMapper } from '../utils';
import { DropDownButton } from './DropDownButton';

export interface PickerOption<T = string | number> {
  label: React.ReactNode;
  value: T;
  textValue?: string;
  description?: React.ReactNode;
  disabled?: boolean;
  data?: object;
}

export type PickerOptions<T = string> =
  | PickerOption[]
  | string[]
  | number[]
  | T[]
  | (string | number)[];

export interface OptionRenderPropArg {
  active: boolean;
  selected: boolean;
  disabled: boolean;
}
export interface SelectProps {
  options: PickerOptions;
  id?: string;
  onChange?: (value: PickerOption | undefined) => void;
  value?: string | number;
  defaultValue?: PickerOption;
  placeholder?: string;
  variant?: Variant;
  size?: Size;
  className?: string;
  customClassNames?: {
    container?: string;
    optionsContainer?: string;
    option?: string;
    optionContent?: string | ((props: OptionRenderPropArg) => string);
    dropDownButton?: string;
  };
  Icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  iconName?: IconName;
  type?: SelectionType;
  label?: string;
  disabled?: boolean;
  clearable?: boolean;
  error?: string | string[];
  CustomCheckComponent?: React.FC<{ checked?: boolean; disabled?: boolean }>;
}

export const CheckTypeCheck = ({
  checked,
}: {
  checked?: boolean;
  disabled?: boolean;
}) => (
  <input
    className="mr-2 h-4 w-4 rounded border-gray-300 text-tw-main-color focus:ring-indigo-500 dark:text-tw-main-color-dark"
    type="checkbox"
    checked={checked}
    readOnly
  />
);

const CheckTypeRadio = ({
  checked,
  disabled,
}: {
  checked?: boolean;
  disabled?: boolean;
}) => <RadioCheck checked={checked} disabled={disabled} />;

export const Select = ({
  options: defaultOptions,
  onChange = console.log,
  defaultValue,
  value,
  label,
  variant = 'default',
  size = 'medium',
  placeholder = 'Select an option',
  Icon: iconComponent,
  iconName,
  type = 'default',
  id,
  disabled = false,
  clearable = false,
  error,
  CustomCheckComponent,
  className,
  customClassNames = {},
  ...props
}: SelectProps) => {
  const [options, setOptions] = useState<PickerOption[]>(
    defaultOptions.map(optionsMapper),
  );

  const [selected, setSelected] = useState<PickerOption | undefined>(
    // 0 is a valid value
    (!isNil(value) && options.find((option) => option.value === value)) ||
      defaultValue,
  );

  useEffect(() => {
    const option = options.find((option) => option.value === value);
    if (option !== selected) {
      setSelected(option);
      onChange(option);
    }
  }, [value]);

  useEffect(() => {
    setOptions(defaultOptions.map(optionsMapper));
  }, [defaultOptions]);

  useEffect(() => {
    setSelected(
      (!isNil(value) && options.find((option) => option.value === value)) ||
        defaultValue,
    );
  }, [defaultValue, options, value]);

  const handleSelect = useCallback(
    (value: PickerOption | undefined) => {
      setSelected(value);
      onChange(value);
    },
    [onChange],
  );

  const Icon = iconComponent || (iconName && IconComponent({ iconName }));

  const CheckComponent = useMemo(
    () =>
      CustomCheckComponent ||
      (type === 'checkbox' && CheckTypeCheck) ||
      (type === 'radio' && CheckTypeRadio) ||
      null,
    [CustomCheckComponent, type],
  );

  return (
    <div
      data-testid={id || 'Picker'}
      data-test-value={value}
      className={twMerge(classNames('w-full', customClassNames.container))}
      {...props}
    >
      {label && <Label htmlFor={id}>{label}</Label>}
      <Listbox value={selected} onChange={handleSelect} disabled={disabled}>
        {({ open }) => (
          <div className={classNames('relative', className)}>
            <DropDownButton
              open={open}
              Icon={Icon}
              variant={variant}
              size={size}
              buttonType="listBox"
              label={selected?.label}
              placeholder={placeholder}
              disabled={disabled}
              clearable={clearable && Boolean(selected)}
              error={error}
              onClear={() => {
                handleSelect(undefined);
              }}
              className={customClassNames.dropDownButton}
            />
            <Transition
              as={Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="transform opacity-0"
              enterTo="transform opacity-100"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                id={id}
                className={twMerge(
                  classNames(
                    'scrollbar',
                    'absolute z-50 mt-0 max-h-60 w-full overflow-auto rounded-b-md py-1 font-graphikRegular text-tw-light-text shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                    'dark:text-tw-light-text-dark dark:ring-white/5',
                    variant === 'default' &&
                      'bg-tw-light-shade dark:bg-tw-strong-shade-dark',
                    variant === 'white' &&
                      'bg-white dark:bg-tw-dark-shade-dark',
                    customClassNames.optionsContainer,
                  ),
                )}
              >
                {options.map((option, optionIdx) => (
                  <Listbox.Option
                    data-testid="select-option"
                    data-test-value={option.value || optionIdx}
                    key={option.value || optionIdx}
                    className={({ selected }) =>
                      twMerge(
                        classNames(
                          { 'cursor-pointer': !selected },
                          size !== 'small' && 'py-1',
                          'relative select-none px-1',
                          customClassNames.option,
                        ),
                      )
                    }
                    value={option}
                    disabled={option.disabled}
                  >
                    {({ selected, active, disabled }) => (
                      <>
                        <span
                          data-test-value={option.value || optionIdx}
                          className={twMerge(
                            classNames(
                              'flex items-center truncate rounded-md px-3 py-1 transition',
                              selected ? 'font-semibold' : 'font-normal',
                              (active || selected) &&
                                'bg-tw-light-hover dark:bg-tw-light-hover-dark',
                              disabled && 'text-tw-description-text',
                              type === 'radio' && 'gap-2',
                              customClassNames.optionContent &&
                                (typeof customClassNames.optionContent ===
                                'string'
                                  ? customClassNames.optionContent
                                  : customClassNames.optionContent({
                                      active,
                                      selected,
                                      disabled,
                                    })),
                            ),
                          )}
                        >
                          {CheckComponent && (
                            <CheckComponent
                              checked={selected}
                              disabled={disabled}
                            />
                          )}
                          {option.label}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
      {error && <FieldError error={error} />}
    </div>
  );
};

export default Select;

export const SelectWithoutBorder = ({
  customClassNames,
  ...props
}: SelectProps) => (
  <Select
    customClassNames={{
      ...customClassNames,
      dropDownButton: twMerge(
        customClassNames?.dropDownButton,
        'ring-0 dark:ring-0',
      ),
      optionsContainer: twMerge(
        customClassNames?.optionsContainer,
        'ring-0 dark:ring-0',
      ),
    }}
    {...props}
  />
);
