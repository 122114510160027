import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Theme = 'light' | 'dark';
interface ThemeState {
  value: Theme;
}
const initialState: ThemeState = {
  value: (localStorage.getItem('@theme') as Theme) ?? 'light',
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    updateTheme(state, action: PayloadAction<Theme>) {
      return setTheme(state, action.payload);
    },
    changeTheme(state) {
      state.value === 'light'
        ? setTheme(state, 'dark')
        : setTheme(state, 'light');
    },
  },
});

const setTheme = (state: ThemeState, theme: Theme) => {
  window.dispatchEvent(
    new CustomEvent('parent:theme:changed', {
      detail: {
        theme,
      },
      bubbles: true,
    }),
  );
  state.value = theme;
  theme === 'light'
    ? localStorage.setItem('@theme', 'light')
    : localStorage.setItem('@theme', 'dark');
};

export const { changeTheme, updateTheme } = themeSlice.actions;
export default themeSlice.reducer;
