import { Icon, IconName } from '../../../assets/icons/Icon';

const NoAlerts = ({ active = true }: { active?: boolean }) => {
  return (
    <div
      data-testid="no-alerts-container"
      className="flex w-full grow flex-col items-center gap-5 rounded-lg bg-tw-light-shade py-24 dark:bg-black"
    >
      <Icon
        iconName={IconName.NotificationBellIcon}
        className="h-[75px] w-[75px] text-tw-inactive-color dark:text-white"
      />
      <h4
        data-testid="no-alerts-title"
        className="font-graphikBold text-tw-inactive-color dark:text-white"
        style={{ fontSize: '20px', lineHeight: '23px' }}
      >
        {active ? 'No Active Alerts' : 'No Cleared Alerts'}
      </h4>
      <p
        className="w-[274px] text-center font-graphikMedium text-tw-inactive-color"
        style={{ fontSize: '14px', lineHeight: '19px' }}
      >
        {active
          ? "There aren't any active alerts to review right now. That's great!"
          : "There are currently no cleared alerts to display. Check back later or navigate to 'Active Alerts' for real-time monitoring."}
      </p>
    </div>
  );
};

export default NoAlerts;
