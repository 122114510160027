import { gql } from '@apollo/client';

export const FIND_LOCATION_CANDIDATES = gql`
  query FindLocationCandidates($search: String!) {
    findLocationCandidates(search: $search, language: "en") {
      id
      label
    }
  }
`;
export const RESOLVE_LOCATION_CANDIDATE = gql`
  query ResolveLocationCandidate($candidateId: ID!) {
    resolveLocationCandidate(candidateId: $candidateId, language: "en") {
      id
      latitude
      longitude
      country
      city
    }
  }
`;

export const REMOVE_APPLIANCE_LOCATION = gql`
  mutation DeleteApplianceLocation($id: String!) {
    deleteApplianceLocation(id: $id)
  }
`;
export const REMOVE_MANAGED_DEVICE_LOCATION = gql`
  mutation DeleteManagedDeviceLocation($guid: ID!) {
    deleteManagedDeviceLocation(guid: $guid)
  }
`;
