import SupernaLogo from '../../assets/icons/SupernaLogo';
import light404 from '../../assets/icons/lightMode/404-icon.svg';
import dark404 from '../../assets/icons/darkMode/404-icon.svg';
import disconnectLight from '../../assets/icons/lightMode/disconnect-img.svg';
import disconnectDark from '../../assets/icons/darkMode/disconnect-img.svg';
import { useAppSelector } from '../../redux/hooks';
import { Icon, IconName } from '../../assets/icons/Icon';

import { Link } from 'react-router-dom';

export const PageNotFound = () => {
  const theme = useAppSelector((state) => state.theme.value);
  return (
    <div className="flex min-h-screen flex-col pt-16">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center">
          <SupernaLogo className="h-[65px] w-[277px] text-tw-main-text dark:text-tw-main-text-dark" />
        </div>
        <div className="pt-16">
          <div className="flex flex-col text-center align-middle">
            <div className="flex w-full justify-center">
              <img src={theme === 'light' ? light404 : dark404} alt="404" />
            </div>
            <h1
              className="mt-6 font-graphikSemi text-tw-main-text dark:text-tw-main-text-dark"
              style={{ fontSize: '36px', lineHeight: '40px' }}
            >
              Oops... Page not found.
            </h1>
            <p
              className="mt-4 font-graphikSemi text-tw-main-text dark:text-tw-main-text-dark"
              style={{ fontSize: '16px', lineHeight: '18px' }}
            >
              Sorry, we couldn't find the page you're looking for.
            </p>
            <div className="mt-12">
              <Link
                to="/"
                className="flex flex-row items-end justify-center gap-2 font-graphikBold text-tw-main-text dark:text-tw-main-text-dark"
                style={{ fontSize: '20px', lineHeight: '22px' }}
              >
                <span aria-hidden="true">
                  <Icon className="h-6 w-6" iconName={IconName.ArrowLeftIcon} />
                </span>
                Go back to Home
              </Link>
            </div>
          </div>
        </div>
      </main>
      <div className="flex w-full justify-center">
        <img
          className="h-auto w-[983px]"
          src={theme === 'light' ? disconnectLight : disconnectDark}
          alt="404"
        />
      </div>
    </div>
  );
};
