import { useQuery } from '@apollo/client';
import { Transition } from '@headlessui/react';
import { useFeature } from 'featurehub-react-sdk';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GET_ALERTS_COUNT } from '../../api/alerts/queries';
import { ProductKind } from '../../api/product/types';
import { SimpleButton } from '../../components/Buttons/SimpleButton';
import { rangeIdToValue } from '../../components/CalenderRangePicker/utils';
import { ProductIcon } from '../../components/Elements/Icons/ProductIcons/ProductIcon';
import { APPLIANCE_HASH_START } from '../../components/Inventory/Appliances/Appliances';
import { StatusBar } from '../../components/common/StatusBar';
import {
  AlertSeverity,
  AlertType,
  ApplianceStatus,
  GetAlertsCountQuery,
  GetAlertsCountQueryVariables,
  GetInventoryQuery,
} from '../../gql/graphql';
import { useAppSelector } from '../../redux/hooks';
import { FeatureFlag } from '../../utils/featureHub/constants';
import { formatNumber, toTitleCase } from '../../utils/format';
import { useSession } from '../../utils/hooks/useSession';
import { isProductKind } from '../../utils/products';
import { mostSevereCountSort } from './utils';

export const AppliancesStatusBar = ({
  appliances,
  ...props
}: {
  appliances?: GetInventoryQuery['appliances'];
}) => {
  const applianceOnlineStatusFlag = useFeature(
    FeatureFlag.APPLIANCE_ONLINE_STATUS,
  );

  const inactiveAppliances = useMemo(
    () =>
      appliances?.filter(
        (appliance) =>
          appliance.status === ApplianceStatus.Inactive ||
          (applianceOnlineStatusFlag && !appliance.online),
      ),
    [appliances],
  );

  const [appliancesHealth, setAppliancesHealth] =
    useState<OnChangeData | null>();

  const affectedProducts = useMemo(
    () =>
      inactiveAppliances
        ?.flatMap((appliance) => appliance.installedProducts)
        .concat(
          (appliancesHealth?.count &&
            appliancesHealth?.appliance?.installedProducts) ||
            [],
        ),
    [inactiveAppliances, appliancesHealth],
  );

  const uniqueAffectedProducts = useMemo(
    () =>
      affectedProducts?.reduce<{
        [key in ProductKind]?: GetInventoryQuery['appliances'][0]['installedProducts'][0][];
      }>((acc, curr) => {
        return {
          ...acc,
          [curr.product.kind as ProductKind]: [
            ...(acc[curr.product.kind as ProductKind] || []).concat([curr]),
          ],
        };
      }, {}),
    [affectedProducts],
  );

  const type = inactiveAppliances?.length
    ? 'critical'
    : appliancesHealth?.count
    ? appliancesHealth.count?.severity === AlertSeverity.Critical
      ? 'critical'
      : 'warning'
    : 'success';

  const encodedApplianceHash = useMemo(
    () =>
      `${encodeURIComponent(
        appliancesHealth?.applianceId ||
          inactiveAppliances?.[0]?.applianceId ||
          '',
      )}`,
    [inactiveAppliances, appliancesHealth],
  );

  const label =
    inactiveAppliances?.length || appliancesHealth?.count
      ? 'Warning: Issue detected'
      : 'Capabilities Healthy and Active';

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <Transition
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      show={!!appliances}
    >
      <StatusBar {...props} type={type} hideIcon={type !== 'success'}>
        <div className="flex w-full flex-row items-center gap-3">
          <span>{label}</span>
          <AppliancesHealthCheck
            appliances={appliances}
            onChange={setAppliancesHealth}
          />

          <div className="ml-auto flex flex-row gap-1">
            {uniqueAffectedProducts &&
              Object.keys(uniqueAffectedProducts).map(
                (key) =>
                  isProductKind(key) && (
                    <ProductIcon
                      key={key}
                      className="h-7 w-7 fill-tw-dark-shade text-tw-label-tertiary dark:fill-[#494949] dark:text-white"
                      productKind={key}
                    />
                  ),
              )}
          </div>
          {type !== 'success' && (
            <SimpleButton
              data-testid="status-bar-see-details-button"
              onClick={() => {
                navigate({
                  search: searchParams.toString(),
                  hash: `${APPLIANCE_HASH_START}${encodedApplianceHash}`,
                });
                // Force scroll even if currently on same hash
                document
                  .getElementById(`appliance-${encodedApplianceHash}`)
                  ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }}
              variant="critical-ghost-ring"
              className="m-0 bg-tw-surface-soft px-4 dark:bg-tw-surface-soft-dark"
            >
              See Details
            </SimpleButton>
          )}
        </div>
      </StatusBar>
    </Transition>
  );
};

export interface OnChangeData {
  appliance?: GetInventoryQuery['appliances'][0];
  applianceId: string;
  count: MostSevereCount | null;
}
export interface AppliancesHealthCheckProps {
  appliances?: GetInventoryQuery['appliances'];
  onChange?: (data: OnChangeData | null) => void;
}
export const AppliancesHealthCheck = ({
  appliances,
  onChange,
}: AppliancesHealthCheckProps) => {
  const [healthScores, setHealthScores] = useState<
    {
      applianceId: string;
      count: MostSevereCount | null;
    }[]
  >([]);

  const handleOnCompleted = (data: {
    applianceId: string;
    count: MostSevereCount | null;
  }) => {
    setHealthScores((prev) =>
      prev
        .filter((prevItem) => prevItem.applianceId !== data.applianceId)
        .concat(data)
        .sort(mostSevereCountSort),
    );
  };

  const mostSevere = useMemo(() => {
    const applianceIds = appliances?.map((appliance) => appliance.applianceId);
    const filtered = healthScores.filter((health) =>
      applianceIds?.includes(health.applianceId),
    );
    return (
      filtered[0] && {
        ...filtered[0],
        appliance: appliances?.find(
          (a) => a.applianceId === filtered[0].applianceId,
        ),
      }
    );
  }, [healthScores, appliances]);

  useEffect(() => onChange?.(mostSevere || null), [mostSevere]);

  return (
    <>
      {appliances?.map((appliance) => (
        <ApplianceHealthCheck
          applianceId={appliance.applianceId}
          key={appliance.applianceId}
          onCompleted={handleOnCompleted}
        />
      ))}
      {mostSevere?.count && mostSevere.appliance ? (
        <>
          <span data-testid="appliance-status-alert-count">
            {formatNumber(mostSevere.count.count)}
          </span>{' '}
          {toTitleCase(mostSevere.count.severity)} health alerts on{' '}
          {mostSevere.appliance.description}
        </>
      ) : (
        ''
      )}
    </>
  );
};

export interface MostSevereCount {
  count: number;
  severity: AlertSeverity;
}
export const mostSevereCount = (
  data: GetAlertsCountQuery,
): MostSevereCount | null => {
  const mostSevereCount =
    data?.CRITICAL.totalItems ||
    data?.ERROR.totalItems ||
    data?.STANDARD.totalItems ||
    0;

  const hasAlerts = mostSevereCount > 0;

  const mostSeverity =
    (hasAlerts && data?.CRITICAL.totalItems && AlertSeverity.Critical) ||
    (data?.ERROR.totalItems && AlertSeverity.Error) ||
    AlertSeverity.Standard;

  return hasAlerts
    ? {
        count: mostSevereCount,
        severity: mostSeverity,
      }
    : null;
};

export const ApplianceHealthCheck = ({
  visible = false,
  applianceId,
  onCompleted,
}: {
  visible?: boolean;
  applianceId: string;
  onCompleted?: (data: {
    applianceId: string;
    count: MostSevereCount | null;
  }) => void;
}) => {
  const [count, setCount] = useState<MostSevereCount | null>();

  const { skip } = useSession();
  const { duration } = useAppSelector((state) => state.productFilter);
  useQuery<GetAlertsCountQuery, GetAlertsCountQueryVariables>(
    GET_ALERTS_COUNT,
    {
      variables: {
        from: rangeIdToValue(duration)?.from?.toISOString(),
        appliance: applianceId,
        type: [AlertType.Health],
      },
      skip: skip,
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        const count = mostSevereCount(data);
        setCount(count);
        onCompleted?.({
          count,
          applianceId,
        });
      },
    },
  );

  return visible ? (
    <>
      {formatNumber(count?.count || 0)} {toTitleCase(count?.severity || '')}{' '}
      Health Alerts
    </>
  ) : null;
};
