import { useQuery } from '@apollo/client';
import { isEmpty } from 'ramda';
import { useEffect, useMemo } from 'react';
import { GET_APPLIANCES } from '../../../api/appliance/queries';
import { GET_INSTALLED_PRODUCTS } from '../../../api/dashboard/queries';
import { ProductKind } from '../../../api/product/types';
import { GET_AVAILABLE_PUBLIC_PRODUCTS } from '../../../apiPublic/queries';
import { rangeIdToValue } from '../../../components/CalenderRangePicker/utils';
import {
  GetAppliancesQuery,
  GetAppliancesQueryVariables,
  GetAvailablePublicProductsQuery,
  GetInstalledProductsQuery,
} from '../../../gql/graphql';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  updateAvailableProducts,
  updateInstalledProducts,
  updateLoadingProducts,
  updateMissingProducts,
} from '../../../redux/products/productsSlice';
import { PUBLIC_CLIENT } from '../../../services/apollo';
import { useSession } from '../../../utils/hooks/useSession';

export type ExtendedInstalledProduct =
  GetInstalledProductsQuery['products'][0] & {
    inCurrentApplianceFilter?: boolean;
  };

//Polling interval set in milliseconds
const POLLING_INTERVAL = 30000;

// This hook should be only called 1 time
export const useProducts = () => {
  const { skip } = useSession();
  const { duration, appliances } = useAppSelector(
    (state) => state.productFilter,
  );

  const dateRange = useMemo(() => {
    return rangeIdToValue(duration);
  }, [duration]);

  const { data, loading, error } = useQuery<GetInstalledProductsQuery>(
    GET_INSTALLED_PRODUCTS,
    {
      pollInterval: POLLING_INTERVAL,
      skip,
    },
  );

  const availablePublicProducts = useQuery<GetAvailablePublicProductsQuery>(
    GET_AVAILABLE_PUBLIC_PRODUCTS,
    {
      pollInterval: POLLING_INTERVAL,
      client: PUBLIC_CLIENT,
      onCompleted: (data) => {
        dispatch(updateAvailableProducts(data.products));
      },
    },
  );

  const {
    data: publicData,
    loading: publicLoading,
    error: publicError,
  } = availablePublicProducts;

  const availableProducts = useMemo<
    GetAvailablePublicProductsQuery['products']
  >(() => publicData?.products || [], [data]);

  // Used to hide product cards not included in current appliance filter
  const appliancesQuery = useQuery<
    GetAppliancesQuery,
    GetAppliancesQueryVariables
  >(GET_APPLIANCES, {
    variables: {
      filter: {
        appliance: appliances,
      },
    },
    skip: skip || isEmpty(appliances),
  });

  const productsInAppliances = useMemo(
    () =>
      appliancesQuery?.data?.appliances.flatMap((appliance) =>
        appliance.installedProducts.map(
          (product) => product.product.kind as ProductKind,
        ),
      ),
    [appliancesQuery?.data],
  );

  const installedProducts = useMemo<ExtendedInstalledProduct[]>(
    () =>
      data?.products.map((p) => ({
        ...p,
        inCurrentApplianceFilter:
          !productsInAppliances ||
          productsInAppliances.includes(p.kind as ProductKind),
      })) || [],
    [data, productsInAppliances],
  );

  const missingProducts = useMemo<GetAvailablePublicProductsQuery['products']>(
    () =>
      availableProducts.filter(
        (product) =>
          !installedProducts.find(
            (installed) => product.kind === installed.kind,
          ),
      ),
    [availableProducts, installedProducts],
  );

  const dispatch = useAppDispatch();

  // If the Installed Appliances or Available Appliances change (likely as a result of Query) then this Effect
  // runs to determine which app the user has installed and which are available to install
  useEffect(() => {
    dispatch(updateMissingProducts(missingProducts));
  }, [missingProducts]);

  useEffect(() => {
    if (installedProducts) {
      dispatch(updateInstalledProducts(installedProducts));
    }
  }, [installedProducts]);

  useEffect(() => {
    dispatch(updateLoadingProducts(loading));
  }, [loading]);

  return {
    installedProducts,
    availableProducts,
    missingProducts,
    loading,
    data,
    error,
    publicLoading,
    publicData,
    publicError,
  };
};
