import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { UseControlledOrUncontrolled } from '../../form/hooks/UseControlledOrUncontrolled';

interface IProps {
  sections: Array<{
    key: string;
    label?: React.ReactNode;
    value?: JSX.Element;
    className?: string;
  }>;
  defaultTabIndex?: number;
  sectionClass?: string;
  tabsClass?: string;
  onChange?: (key: string) => void;
  value?: string;
  defaultValue?: string;
  onlyRenderCurrent?: boolean;
}

export default function Tabs({
  sections,
  sectionClass,
  tabsClass,
  onChange,
  value,
  defaultValue,
  onlyRenderCurrent,
}: IProps) {
  const { value: selectedTabKey, setValue: setSelectedTabKey } =
    UseControlledOrUncontrolled({
      value,
      defaultValue: defaultValue || sections[0]?.key,
      defaultInternalValue: '',
    });

  const handleTabClick = useCallback((key: string) => {
    setSelectedTabKey(key);
    onChange?.(key);
  }, []);

  return (
    <>
      <div
        className={twMerge(
          classNames(
            'flex flex-row items-center gap-9 border-b border-b-[#e5e7eb] pl-5 pt-3 dark:border-b-[#393939]',
            tabsClass,
          ),
        )}
        data-testid="Tabs"
      >
        {sections.map((section) => (
          <h3
            key={section.key}
            data-testid={`tabs-${section.key}`}
            onClick={() =>
              section.key !== selectedTabKey && handleTabClick(section.key)
            }
            className={classNames(
              `cursor-pointer pb-3 font-graphikMedium transition duration-150 ${
                selectedTabKey === section.key
                  ? 'border-b-2 border-[#292A2B] dark:border-white dark:text-white'
                  : 'text-tw-inactive-color hover:text-black dark:hover:text-white'
              }`,
            )}
            style={{ fontSize: '14px', lineHeight: '15px' }}
          >
            {section.label || section.key}
          </h3>
        ))}
      </div>

      {sections
        .filter(({ value }) => Boolean(value))
        .filter(
          (section) => !onlyRenderCurrent || selectedTabKey === section.key,
        )
        .map((section) => (
          <div
            key={section.key}
            className={classNames(
              twMerge(
                'explorer-scroll h-full grow overflow-auto',
                sectionClass,
                section.className,
                selectedTabKey === section.key ? '' : 'hidden',
              ),
            )}
          >
            {section.value}
          </div>
        ))}
    </>
  );
}

export const TabsWithQueryParams = ({
  searchParam = 'tab',
  ...rest
}: IProps & { searchParam?: string }) => {
  const [searchParams, setSearchParams] = useSearchParams({
    [searchParam]: rest.sections[0].key,
  });

  const currentTab = searchParams.get(searchParam) || rest.sections[0].key;

  const [stateTab, setStateTab] = useState(currentTab);

  useEffect(() => {
    rest.onChange?.(stateTab);
  }, []);

  const handleTabChange = useCallback(
    (tabKey: string) => {
      // Change the state to fix the component re-render
      stateTab;
      setStateTab(tabKey);
      rest.onChange?.(tabKey);
      setSearchParams((prev) => {
        prev.set(searchParam, tabKey);
        return prev;
      });
    },
    [setSearchParams, searchParams],
  );

  return <Tabs {...rest} value={currentTab} onChange={handleTabChange} />;
};
