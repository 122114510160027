import { gql } from '@apollo/client';

export const GET_ALERTS = gql`
  query GetAlerts(
    $filter: QueryAlertInput!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    alerts(
      filter: $filter
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalItems
      nodes {
        id
        code
        type
        severity
        message
        createdAt
        active
        product {
          kind
        }
        appliance {
          description
          applianceId
        }
      }
    }
  }
`;

export const GET_ALERTS_COUNT = gql`
  query GetAlertsCount(
    $appliance: [String!]
    $from: DateTime!
    $to: DateTime
    $productKind: [String!]
    $type: [AlertType!]
  ) {
    CRITICAL: alerts(
      first: 1
      filter: {
        severity: CRITICAL
        from: $from
        to: $to
        active: true
        appliance: $appliance
        productKind: $productKind
        type: $type
      }
    ) {
      totalItems
    }
    ERROR: alerts(
      first: 1
      filter: {
        severity: ERROR
        from: $from
        to: $to
        active: true
        appliance: $appliance
        productKind: $productKind
        type: $type
      }
    ) {
      totalItems
    }
    STANDARD: alerts(
      first: 1
      filter: {
        severity: STANDARD
        from: $from
        to: $to
        active: true
        appliance: $appliance
        productKind: $productKind
        type: $type
      }
    ) {
      totalItems
    }
  }
`;

// Alerts

export const GET_ALERT = gql`
  query GetAlert($alertId: ID!) {
    alert(id: $alertId) {
      id
      code
      type
      severity
      message
      createdAt
      active
      product {
        kind
      }
      appliance {
        description
        applianceId
      }
      info
    }
  }
`;
// Mutations

export const MAX_ALERTS_TO_CLEAR = 5000;

export const CLEAR_ALERTS = gql`
  mutation DeleteAlerts($ids: [ID!]!) {
    deleteAlerts(ids: $ids)
  }
`;

export const CLEAR_ALL_ALERTS = gql`
  mutation ClearAllAlerts($filter: QueryAlertInput!) {
    clearAllAlerts(filter: $filter)
  }
`;
