import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { SpinnerIcon } from '../../../assets';

export const WithSpinner = ({
  loading,
  children = 'Loaded',
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  loading?: boolean;
}) => {
  return loading ? <Spinner {...props} /> : <>{children}</>;
};

export const Spinner = ({
  className,
  loading = true,
  children = <span className="sr-only">Loading...</span>,
  ...rest
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  loading?: boolean;
}) => {
  if (!loading) {
    return null;
  }
  return (
    <div
      role="status"
      className={twMerge(classNames('h-8 w-8 overflow-hidden', className))}
      data-testid="spinner"
      {...rest}
    >
      <SpinnerIcon />
      {children}
    </div>
  );
};

export const SpinnerVertical = ({
  className,
  ...rest
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <Spinner
    className={classNames('flex w-auto flex-row items-center gap-3', className)}
    {...rest}
  />
);

export default Spinner;
