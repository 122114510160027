import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import { ProductKind } from '../../../api/product/types';
import { Icon, IconName } from '../../../assets/icons/Icon';
import { ProductIcon } from '../../../components/Elements/Icons/ProductIcons/ProductIcon';
import { IFilterButton } from '../types';

interface IProps {
  products: Array<IFilterButton> | undefined;
  selectedProducts: Array<IFilterButton> | null;
  updateSelected: (app: IFilterButton) => void;
}

const InventoryFilterButtonList = ({
  products,
  selectedProducts,
  updateSelected,
}: IProps) => {
  return (
    <div
      data-testid="listbox-options"
      className="relative mb-2 flex flex-row items-center justify-end"
    >
      {!!products?.length && (
        <Listbox value={selectedProducts || []} multiple>
          <Listbox.Button
            data-testid="appliance-filter-listbox-button"
            className="flex h-[50px] w-auto flex-row items-center justify-between rounded-md px-4 ring-1 ring-tw-border dark:ring-[#25282E]"
          >
            {selectedProducts && selectedProducts.length ? (
              <>
                {selectedProducts.length} / {products?.length}{' '}
              </>
            ) : (
              <>All </>
            )}
            Capabilities Selected
            <Icon iconName={IconName.ChevronDownIcon} />
          </Listbox.Button>
          <Listbox.Options className="absolute right-0 top-[55px] z-50 rounded-[10px] border-2 bg-white py-2 shadow-md dark:border-[#383A40] dark:bg-[#25282E]">
            {products?.map((product) => (
              <ListboxOption
                data-test-value={product.name}
                key={product.name}
                product={product}
                updateSelected={updateSelected}
                selectedProducts={selectedProducts}
              />
            ))}
          </Listbox.Options>
        </Listbox>
      )}
    </div>
  );
};

export default InventoryFilterButtonList;

interface IOptionProps {
  product: IFilterButton;
  updateSelected: (app: IFilterButton) => void;
  selectedProducts: Array<IFilterButton> | null;
}

const FilterProductIcon = ({
  app,
  isSelected,
}: {
  app: IFilterButton;
  isSelected: boolean;
}) => {
  return (
    <ProductIcon
      key={app.kind}
      productKind={app.kind as ProductKind}
      disabled={!isSelected}
      className={classNames(
        'h-9 w-9 rounded-md ring-2',
        isSelected
          ? 'bg-white fill-white text-[#013FFF] ring-[#EEF4FF]'
          : 'fill-tw-white text-tw-inactive-color ring-0 ring-tw-inactive-color dark:fill-[#25282D] dark:text-[#897D7D] dark:ring-1 dark:ring-[#383942]',
      )}
    />
  );
};
const ListboxOption = ({
  product,
  updateSelected,
  selectedProducts,
}: IOptionProps) => {
  const isSelected =
    !selectedProducts ||
    !selectedProducts.length ||
    selectedProducts.includes(product);

  return (
    <Listbox.Option
      data-testid="listbox-option"
      data-test-value={product.name}
      key={product.name}
      onClick={() => updateSelected(product)}
      value={product}
      className={`flex h-[60px] w-[300px] cursor-pointer flex-row items-center justify-between bg-transparent p-3 dark:bg-[#25282D]`}
    >
      <div className="flex flex-row items-center gap-2">
        <div className="flex flex-row items-center gap-1">
          <FilterProductIcon app={product} isSelected={isSelected} />
        </div>

        <div className="flex flex-col items-start gap-1">
          <h5
            className={`font-graphikMedium ${
              isSelected ? 'dark:text-white' : 'dark:text-[#897D7D]'
            }`}
            style={{ fontSize: '12px' }}
          >
            {product.name}
          </h5>
          {!!product.description && (
            <p
              data-testid="node"
              className="font-graphikMedium text-[#897D7D]"
              style={{ fontSize: '10px' }}
            >
              {product.description}
            </p>
          )}
        </div>
      </div>
      {isSelected && (
        <FontAwesomeIcon icon={faCheck} className="h-[10px] w-[16px]" />
      )}
    </Listbox.Option>
  );
};
