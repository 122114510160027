import PastDurationPicker, {
  PastDurationValue,
} from '../../components/CalenderRangePicker/PastDurationPicker';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { updateProductsFilterDuration } from '../../redux/products/filterSlice';

export const ProductsDateRangePicker = () => {
  const { duration } = useAppSelector((state) => state.productFilter);
  const dispatch = useAppDispatch();

  const handleOnChangeExtended = (value: PastDurationValue) => {
    dispatch(updateProductsFilterDuration(value.duration));
  };

  return (
    <PastDurationPicker
      data-testid="products-date-range-picker"
      onChange={handleOnChangeExtended}
      value={duration}
    />
  );
};
