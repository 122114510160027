const suffixes: string[] = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export const convertSize = (sizeInMB?: number) => {
  if (sizeInMB === null || sizeInMB === undefined) return '-';

  let index = 0;
  while (sizeInMB >= 1024 && index < suffixes.length - 1) {
    sizeInMB /= 1024.0;
    index++;
  }

  const sizeWithSuffix = `${sizeInMB.toFixed(2)} ${suffixes[index]}`;
  return sizeWithSuffix;
};
