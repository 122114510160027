import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import ChevronRightIcon from '../../assets/icons/ChevronRightIcon';
import { useClickOutsideHandler } from '../../utils/hooks/useClickOutsideHandler';
import './style.css';
import { useTranslation } from 'react-i18next';
import Spinner from '../Elements/Spinner/Spinner';
import { twMerge } from 'tailwind-merge';
import { Icon, IconName } from '../../assets/icons/Icon';

export interface ICustomSelectOption {
  key: string;
  label?: string | JSX.Element;
  disabled?: boolean;
}

const DEFAULT_PLACEHOLDER = 'Select an option';

export interface ICustomSelectProps {
  placeholder?: string | JSX.Element;
  options: ICustomSelectOption[];
  value?: string; // key of the selected option. Uncontrolled if not provided
  onChange: ({ key, selected }: { key: string; selected: string[] }) => void;
  className?: string;
  label?: string;
  noOptionsMessage?: string;
  isMultiselect?: boolean;
  minSelectedItems?: number;
  showSelectedOptions?: boolean;
  loading?: boolean;
  optionsListClassName?: string;
  customSelectIcon?: JSX.Element;
  selectedItemRender?: (selectedItem: ICustomSelectOption) => JSX.Element;
  mainButtonClassName?: string;
  inAnimation?: boolean;
}

/**
 * A custom select component that uses a dialog trigger to display the options
 * @param props
 * @returns
 * @example Uncontrolled
 * const options = [
 *  { key: '1', label: 'Option 1' },
 * { key: '2', label: 'Option 2' },
 * { key: '3', label: 'Option 3' },
 * ];
 * const [value, setValue] = useState('1');
 * return (
 * <CustomSelect
 * options={options}
 * value={value}
 * onChange={setValue}
 * />
 * );
 * @example Controlled
 * const options = [
 * { key: '1', label: 'Option 1' },
 * { key: '2', label: 'Option 2' },
 * { key: '3', label: 'Option 3' },
 * ];
 * return (
 * <CustomSelect
 * options={options}
 * onChange={}
 * />
 * );
 */
export const CustomSelect = ({
  placeholder = DEFAULT_PLACEHOLDER,
  options,
  value,
  className,
  label,
  onChange,
  isMultiselect = false,
  minSelectedItems = 0,
  showSelectedOptions = false,
  noOptionsMessage,
  loading,
  optionsListClassName,
  customSelectIcon,
  selectedItemRender,
  mainButtonClassName,
  inAnimation,
}: ICustomSelectProps) => {
  const { t } = useTranslation('common');

  const ref = useRef(null);

  const [selected, setSelected] = useState<ICustomSelectOption[]>();

  const [isOpen, setIsOpen] = useState(false);

  useClickOutsideHandler(ref, () => setIsOpen(false));

  const valueOption = useMemo(
    () => (value ? options.find((o) => o.key === value) : undefined),
    [options, value],
  );

  const handleSelect = useCallback(
    (option: ICustomSelectOption, isCurrent: boolean) => {
      const currentCannotBeRemoved =
        isCurrent && selected && selected.length <= minSelectedItems;
      const isCurrentSingleSelect = isCurrent && !isMultiselect;
      const skipActions =
        option.disabled || isCurrentSingleSelect || currentCannotBeRemoved;

      if (skipActions) {
        return;
      }

      const nextSelected = isMultiselect
        ? isCurrent
          ? (selected || []).filter((o) => o.key !== option.key)
          : [...(selected || []), option]
        : [option];

      !value && setSelected(nextSelected);
      onChange({
        key: option.key,
        selected: nextSelected.map((option) => option.key),
      });
      !isMultiselect && setIsOpen(false);
    },
    [setSelected, value, isMultiselect, selected, onChange, minSelectedItems],
  );

  // Map the selected value to the options
  useEffect(() => {
    const newSelected = selected?.map(
      (option) => options.find((o) => o.key === option.key) || option,
    );
    setSelected(newSelected);
  }, [options]);

  useEffect(() => {
    if (value) {
      valueOption && setSelected([valueOption]);
    }
  }, [valueOption]);

  const optionsToDisplay = useMemo(
    () =>
      options.filter(
        (option) =>
          showSelectedOptions || !selected?.some((s) => s.key === option.key),
      ),
    [options, selected, showSelectedOptions],
  );

  const deselectOption = useCallback(
    (option: ICustomSelectOption) => {
      const nextSelected = (selected || []).filter((o) => o.key !== option.key);
      !value && setSelected(nextSelected);

      onChange({
        key: option.key,
        selected: nextSelected.map((option) => option.key),
      });
    },
    [setSelected, value, selected, onChange],
  );

  return (
    <div
      className={twMerge(classNames(className, 'customSelect'))}
      data-testid="custom-select"
    >
      {label && (
        <label
          id="listbox-label"
          className="block text-sm font-medium text-gray-700 dark:text-gray-100"
        >
          {label}
        </label>
      )}
      <div className="relative mt-1">
        <button
          type="button"
          className={twMerge(
            classNames(
              isMultiselect && selected?.length ? 'p-1' : 'p-2 px-3',
              'relative flex w-full cursor-pointer items-center rounded-lg bg-black/5 text-left transition hover:bg-black/10 dark:bg-transparent dark:hover:bg-white/10',
              'pr-8 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm',
              mainButtonClassName,
            ),
          )}
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="flex items-center">
            <div className="flex flex-wrap gap-1">
              {(selected &&
                selected.length &&
                selected.map((selectedOption) => {
                  return (
                    <div
                      key={selectedOption.key}
                      className={classNames(
                        inAnimation && 'inAnimation',
                        'flex flex-1',
                        isMultiselect &&
                          'flex-grow-0 items-center rounded bg-black/5 p-1 hover:ring-1 hover:ring-indigo-500 dark:bg-white/5',
                      )}
                    >
                      <span className="grow">
                        {selectedItemRender
                          ? selectedItemRender(selectedOption)
                          : selectedOption.label || selectedOption.key}
                      </span>
                      {isMultiselect && (
                        <span
                          className="ml-2 px-2 text-tw-description-text transition hover:text-tw-main-text dark:text-tw-description-text-dark dark:hover:text-white"
                          onClick={(e) => {
                            e.stopPropagation();
                            deselectOption(selectedOption);
                          }}
                        >
                          <Icon
                            iconName={IconName.CrossIcon}
                            height="12"
                            width="12"
                          />
                        </span>
                      )}
                    </div>
                  );
                })) ||
                placeholder}
            </div>
          </span>
          <span className="custom-select-icon pointer-events-none absolute inset-y-0 right-0 ml-2 flex items-center pr-3">
            {loading && <Spinner className="mr-2 h-4 w-4" />}
            {customSelectIcon || (
              <ChevronRightIcon
                className={classNames(
                  'h-[0.625rem] w-[0.625rem] transition',
                  isOpen ? '-rotate-90' : 'rotate-90 ',
                )}
              />
            )}
          </span>
        </button>
        <ul
          ref={ref}
          data-testid="custom-select-dialog"
          className={twMerge(
            classNames(
              'absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
              isOpen ? 'visible' : 'invisible',
              'bg-tw-dark-shade dark:bg-tw-light-shade-dark',
              'dark:ring-1 dark:ring-gray-700',
              optionsListClassName,
            ),
          )}
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-option-3"
        >
          {[
            ...(optionsToDisplay.length
              ? optionsToDisplay
              : [
                  {
                    key: 'no-options',
                    label: noOptionsMessage || t('common.noOptions'),
                    disabled: true,
                  },
                ]),
          ].map((option) => {
            const isCurrent =
              selected?.some((s) => s.key === option.key) || false;
            return (
              <li
                data-testid="custom-select-option"
                key={option.key}
                className={classNames(
                  'relative cursor-default select-none py-2 pl-3 pr-9',
                  isCurrent || option.disabled
                    ? 'current bg-tw-light-shade text-tw-inactive-color dark:bg-black dark:text-tw-description-text'
                    : 'cursor-pointer hover:bg-indigo-200 dark:hover:bg-gray-700',
                  isMultiselect &&
                    !option.disabled &&
                    'cursor-pointer hover:bg-indigo-200 dark:hover:bg-gray-700',
                )}
                onClick={() => handleSelect(option, isCurrent)}
              >
                {option.label || option.key}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
