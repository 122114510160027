import { useFeature } from 'featurehub-react-sdk';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ProductKind } from '../../../../api/product/types';
import { FeatureFlag } from '../../../../utils/featureHub/constants';
import { SimpleButton } from '../../../Buttons/SimpleButton';

interface IProps {
  kind: ProductKind;
  comingSoon?: boolean;
  name: string;
}

const CardComingSoon = ({ kind, comingSoon }: IProps) => {
  const { t } = useTranslation('productInfo');
  const navigate = useNavigate();
  //RELEASE FLAG
  const connectAppFeature = useFeature(FeatureFlag.CONNECT_APP);
  return (
    <div data-testid="product-card-coming-soon" className="flex flex-col gap-2">
      <div className="w-full">
        <p
          data-testid="product-card-coming-soon-why"
          className="px-4 py-5 font-graphikRegular text-tw-description-text"
          style={{ fontSize: '14px', lineHeight: '18px' }}
        >
          {t(`${kind}.why`, kind)}
        </p>
      </div>

      <div className="flex w-full flex-col items-end self-end">
        <div className="flex flex-row items-center gap-2 p-2 pr-4">
          <SimpleButton
            data-testid="product-card-coming-soon-learn-more"
            variant="primary-ghost"
            className="px-3"
            onClick={() => navigate(`/learn-more/${kind}`)}
          >
            Learn More
          </SimpleButton>

          {comingSoon ? (
            <button
              data-testid="product-card-coming-soon-coming-soon"
              className="w-25 h-auto rounded-[10px] border border-white bg-tw-inactive-blue px-2 py-2.5 text-center font-graphikSemi  text-white  dark:border-0 dark:bg-[#2D3036] dark:text-tw-main-text"
              disabled
              style={{ fontSize: '16px', lineHeight: '18px' }}
            >
              Coming soon
            </button>
          ) : connectAppFeature ? (
            <SimpleButton
              data-testid="product-card-coming-soon-connect"
              onClick={() => navigate(`/`)}
            >
              Connect
            </SimpleButton>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CardComingSoon;
