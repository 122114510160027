import classNames from 'classnames';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import WarningCircleIcon from '../../../assets/icons/WarningCircleIcon';
import { SimpleButton } from '../../../components/Buttons/SimpleButton';
import { TrialModalContent } from '../../../components/Elements/Modal/TrialModalContent';
import { WithSpinner } from '../../../components/Elements/Spinner/Spinner';
import { ModalTrigger } from '../../../components/common/Modal';
import OTPInput from '../../../components/form/OTPInput';
import { logoutOrySession } from '../../../ory/logout';
import {
  FLOW_QUERY_PARAM,
  createOtpRecoveryFlow,
  submitOtpCode,
  submitOtpCodeFromParams,
} from '../../../ory/otpRecovery';
import { useAppDispatch } from '../../../redux/hooks';
import { resetLogin, updateLogin } from '../../../redux/login/loginSlice';
import { useSession } from '../../../utils/hooks/useSession';

type Input = {
  email: string;
};

const checkOryParams = (flow?: string | null) => {
  // Check flow, if provided, return true and go directly to OTP layout
  if (flow === null) return false;
  return true;
};

export const RecoveryForm = () => {
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('no-email@email.com');
  const [searchParams] = useSearchParams();

  return (
    <div
      data-testid="recovery-form"
      className="flex flex-col items-center gap-10 font-graphikRegular"
    >
      <div className={'w-96 rounded-2xl bg-white p-6 dark:bg-neutral-800'}>
        <div className="flex flex-col items-center gap-3">
          <h1
            data-testid="recovery-form-title"
            className="mb-3 mt-2 font-graphikBold text-[28px] leading-[31px]"
          >
            Password Recovery
          </h1>
          {emailSent || checkOryParams(searchParams.get(FLOW_QUERY_PARAM)) ? (
            <OTPLayout email={email} flow={searchParams.get('flow')} />
          ) : (
            <EmailNotSentLayout
              setEmailSent={setEmailSent}
              setEmail={setEmail}
            />
          )}
        </div>
      </div>
      <span className="font-graphikRegular">
        {`Don't have an account? `}
        <ModalTrigger
          modalTitle="Request Demo"
          modalContent={(close) => (
            <TrialModalContent product={'Platform'} onSubmit={close} />
          )}
          layoutArea={null}
          data-testid="recovery-form-request-demo"
        >
          <span className="text-tw-main-color">Request Demo</span>
        </ModalTrigger>
      </span>
    </div>
  );
};

const OTPLayout = ({
  email = 'mockEmail@superna.net',
  flow,
}: {
  email?: string;
  flow?: string | null;
}) => {
  const [otp, setOtp] = useState('      ');
  const [errors, setErrors] = useState<string[]>([]);
  const [gen, setGen] = useState('');
  const [loadingNew, setLoadingNew] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const dispatch = useAppDispatch();
  const { logoutToken } = useSession();

  const logoutSession = () => {
    dispatch(
      updateLogin({
        isLoginOut: true,
      }),
    );
    logoutOrySession(logoutToken)
      .then((success) => {
        if (success) {
          dispatch(resetLogin());
        }
      })
      .finally(() => {
        dispatch(
          updateLogin({
            isLoginOut: false,
          }),
        );
      });
  };

  const onOtpChange = (value: string) => {
    setOtp(value);
    resetStates();
  };
  const handleGenNew = () => {
    resetStates();
    setLoadingNew(true);
    createOtpRecoveryFlow(email)
      .then(() => {
        resetStates();

        setGen('Successfully generated and sent new code');
      })
      .catch(() => {
        resetStates();
        setErrors(['Error generating new code']);
      })
      .finally(() => setLoadingNew(false));
  };

  const resetStates = () => {
    setGen('');
    setErrors([]);
  };

  const onSubmit = () => {
    setLoadingSubmit(true);
    const submit = () => {
      if (typeof flow === 'string') {
        return submitOtpCodeFromParams(otp, flow);
      } else {
        return submitOtpCode(otp);
      }
    };

    submit()
      .then((data) => {
        logoutSession();
        resetStates();
        setErrors(data);
      })
      .catch(console.log)
      .finally(() => setLoadingSubmit(false));
  };

  const isValidOTP = useMemo(() => Boolean(otp.match(/\s/)?.length), [otp]);

  return (
    <>
      <h5 className="mb-3 text-center font-graphikRegular text-sm">
        You may recieve an OTP in your email. Please <br />
        check on your Inbox or Spam folder
      </h5>
      <div className="group relative z-0 mb-3 w-full">
        <OTPInput value={otp} valueLength={6} onChange={onOtpChange}></OTPInput>

        <div className="pt-3">
          <p
            data-testid="recovery-form-error-messages"
            className={`text-center font-graphikRegular text-red-500`}
          >
            {errors}
          </p>
          <p className={`text-center font-graphikRegular text-green-500`}>
            {' '}
            {gen}
          </p>
          <button
            data-testid="recovery-form-generate-new"
            className="flex w-full flex-row items-center justify-center gap-2 font-graphikRegular text-blue-600"
            onClick={!loadingNew ? handleGenNew : undefined}
          >
            <WithSpinner className="h-4 w-4" loading={loadingNew}>
              Generate new
            </WithSpinner>
          </button>
        </div>
        <SimpleButton
          data-testid="recovery-form-otp-submit"
          disabled={loadingSubmit || isValidOTP}
          type="submit"
          onClick={onSubmit}
          className={`mt-4 h-14 w-full rounded-xl`}
          loading={loadingSubmit}
        >
          Submit
        </SimpleButton>
      </div>
    </>
  );
};

const EmailNotSentLayout = ({
  setEmailSent,
  setEmail,
}: {
  setEmailSent: Dispatch<SetStateAction<boolean>>;
  setEmail: Dispatch<SetStateAction<string>>;
}) => {
  const {
    setFocus,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Input>();
  const onSubmit: SubmitHandler<Input> = (data) => {
    createOtpRecoveryFlow(data.email);
    setEmail(data.email);
    setEmailSent(true);
  };

  useEffect(() => {
    setFocus('email');
  }, []);

  return (
    <>
      <h5 className="mb-1 font-graphikRegular text-xs">
        Enter your email to reset your password
      </h5>
      <form
        data-testid="recovery-form-email-form"
        onSubmit={handleSubmit(onSubmit)}
        className="w-full p-3"
      >
        <div className="group relative z-0 mb-3 w-full">
          <input
            data-testid="recovery-form-email-form-email"
            {...register('email', {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'invalid email address',
              },
            })}
            type="text"
            id="floating_device"
            className={classNames(
              errors.email ? 'focus:outline-red-500' : 'focus:outline-none',
              'peer block h-12 w-full appearance-none  rounded-lg px-4 pt-3 text-sm  text-tw-main-text placeholder-shown:pt-0 focus:pt-3 dark:text-tw-main-text-dark',
              'bg-tw-light-shade dark:bg-tw-dark-shade-dark',
            )}
            placeholder=" "
          />
          <label
            htmlFor="floating_device"
            className="absolute left-4 top-4 origin-[0] -translate-y-2.5 scale-90 transform font-graphikRegular text-xs text-tw-description-text duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100  peer-focus:-translate-y-2.5 peer-focus:scale-90 peer-focus:font-medium peer-focus:text-blue-600 dark:text-tw-description-text peer-focus:dark:text-tw-main-color"
          >
            Email
          </label>
          {errors.email && (
            <WarningCircleIcon className="absolute right-3 top-2 w-8 scale-90 transform font-graphikRegular duration-300" />
          )}
        </div>
        {errors.email && (
          <div className="absolute ml-1 flex -translate-y-2 items-center justify-center text-xs text-red-600">
            <WarningCircleIcon className="w-5" />
            Invalid email format. Please check it and try again.
          </div>
        )}
        <button
          data-testid="recovery-form-email-form-submit"
          disabled={watch('email')?.length === 0}
          type="submit"
          className={`h-14 w-full rounded-xl font-graphikRegular ${
            watch('email')?.length > 0
              ? 'bg-tw-main-color text-white hover:bg-blue-700'
              : 'bg-blue-400/25 text-white/50'
          } mt-4`}
        >
          Send email
        </button>
      </form>
    </>
  );
};
