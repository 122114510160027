import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import { anyPass, equals, isEmpty, isNil, reject } from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SimpleButton } from '../Buttons/SimpleButton';
import {
  PickerOption,
  PickerOptions,
  RadioGroup,
  RadioGroupProps,
} from '../form/fields';
import {
  CheckBoxGroupProps,
  CheckBoxSwitchGroup,
} from '../form/fields/CheckBox/CheckBoxGroup';
import SearchInput from '../form/fields/SearchInput/SearchInput';
import MenuField from '../form/fields/pickers/Menu';
import { FilterOption } from './FilterOption';
import { LabelWithCount } from './LabelWithCount';

export interface FilterOptionPropsBase {
  label: string;
  icon?: IconProp;
  type: 'multi' | 'single';
  // for test id
  dataTestType?: string;
  search?: (searchTerm?: string) => boolean;
  required?: boolean;
  options: PickerOptions;
}
export interface FilterOptionPropsMulti extends FilterOptionPropsBase {
  type: 'multi';
  pickerProps?: Partial<CheckBoxGroupProps>;
}
export interface FilterOptionPropsSingle extends FilterOptionPropsBase {
  type: 'single';
  pickerProps?: RadioGroupProps;
}
export type FilterOptionProps =
  | FilterOptionPropsMulti
  | FilterOptionPropsSingle;

export type FilterSelectFilters = { [key: string]: FilterOptionProps };
export type FilterSelectValue<T = FilterSelectFilters> = {
  [key in keyof T]?: T[key] extends { type: 'multi' | 'single' }
    ? T[key]['type'] extends 'single'
      ? string
      : string[]
    : never;
};
export interface FilterSelectProps<
  T = FilterSelectFilters,
  U = FilterSelectValue<T>,
> {
  value?: U;
  onSave?: (value: U) => void;
  filters: T;
}

export const FilterSelect = <T extends { [key: string]: FilterOptionProps }>({
  filters,
  value,
  onSave,
}: FilterSelectProps<T>) => {
  const [pendingValue, setPendingValue] = useState<
    { [x: string]: string[] | string | undefined } | undefined
    // FilterSelectValue<T> | undefined
  >(value);

  useEffect(() => setPendingValue(value), [value]);

  const [searchTerm, setSearchTerm] = useState('');

  const count = useMemo(() => {
    const toCount = { ...value, ...pendingValue };
    const count = Object.entries(toCount).map(([key, value]) =>
      filters[key].type === 'multi' ? value?.length : value ? 1 : 0,
    );
    return {
      byType: count,
      total: Object.values(count).reduce((acc = 0, curr = 0) => acc + curr, 0),
    };
  }, [pendingValue, value]);

  const hasChanges = useMemo(() => {
    return !equals(
      reject(anyPass([isEmpty, isNil]))(value || {}),
      reject(anyPass([isEmpty, isNil]))(pendingValue || {}),
    );
  }, [value, pendingValue]);

  const [currentFilter, setCurrentFilter] = useState<string | null>(null);
  const handleTypeClick = useCallback(
    (type: string) => () =>
      setCurrentFilter((prev) => (prev === type ? null : type)),
    [],
  );

  const handleApplyFilters = useCallback(
    (close: () => void) => () => {
      pendingValue &&
        onSave?.((pendingValue as FilterSelectProps<T>['value']) || {});
      close();
    },
    [pendingValue, close],
  );

  const instanceOfPickerOption = (object: any): object is PickerOption => {
    if (typeof object !== 'object') {
      return false;
    }
    return 'label' in object;
  };

  const filteredFilters = useMemo(
    () =>
      Object.entries(filters).map(([key, filterValue]) => ({
        key,
        options: (filterValue.options as PickerOptions<any>).filter(
          (option) =>
            !searchTerm ||
            (!instanceOfPickerOption(option)
              ? `${option}`.toLowerCase().includes(searchTerm.toLowerCase())
              : (typeof option.label === 'string' ? option.label : undefined)
                  ?.toLowerCase()
                  .includes(searchTerm.toLowerCase()) ||
                (typeof option.value === 'string' ||
                typeof option.value === 'number'
                  ? `${option.value}`
                  : undefined
                )
                  ?.toLowerCase()
                  .includes(searchTerm.toLowerCase()) ||
                (typeof option.textValue === 'string'
                  ? option.textValue
                  : undefined
                )
                  ?.toLowerCase()
                  .includes(searchTerm.toLowerCase())),
        ),
      })),
    [searchTerm, filters],
  );

  const hasSearchResults = useMemo(
    () => filteredFilters.some((f) => f.options.length),
    [filteredFilters],
  );

  return (
    <MenuField
      data-testid="filter-menu"
      className="w-[235px] text-xs"
      variant="soft"
      size="small"
      dropDownLabel={
        <LabelWithCount
          data-testid="filter-dropdown-button"
          label="Filter"
          count={count.total}
        />
      }
    >
      {({ close }) => (
        <div data-testid="filter-content">
          <div className="flex w-full bg-tw-surface-strong dark:bg-tw-surface-strong-dark">
            <SearchInput
              data-testid="filter-search"
              size="small"
              value={searchTerm}
              customClassNames={{
                wrapper: classNames(
                  'm-0.5 rounded-none',
                  'bg-tw-surface-strong dark:bg-tw-surface-strong-dark',
                ),
              }}
              placeholder="Filter by..."
              onChange={setSearchTerm}
              // Enable space without closing menu
              inputProps={{
                onKeyDown: (e) => {
                  e.stopPropagation();
                },
              }}
            />
          </div>
          {!hasSearchResults && (
            <div
              data-testid="filter-no-results"
              className="flex w-full p-4 pb-0"
            >
              No results for current search
            </div>
          )}
          <div
            data-testid="filter-options"
            className="scrollbar flex max-h-[25rem] flex-col overflow-y-auto overflow-x-hidden p-2"
          >
            {Object.entries(filters).map(([key, filterValue]) => {
              const options: PickerOptions = (filteredFilters.find(
                (f) => f.key === key,
              )?.options || []) as PickerOptions;

              return (
                <FilterOption
                  key={key}
                  type={'select'}
                  dataTestType={filterValue.dataTestType}
                  label={filterValue.label || key}
                  icon={filterValue.icon}
                  onClick={handleTypeClick(key)}
                  selected={currentFilter === key && !searchTerm}
                  count={
                    filterValue.type === 'multi'
                      ? pendingValue?.[key]?.length
                      : pendingValue?.[key]
                      ? 1
                      : 0
                  }
                  shouldOpen={!!searchTerm && options.length > 0}
                  visible={
                    (options.length > 0 && !!searchTerm) ||
                    (!searchTerm && !currentFilter) ||
                    (currentFilter === key && !searchTerm)
                  }
                >
                  {filterValue.type === 'multi' && (
                    <CheckBoxSwitchGroup
                      data-testid={`filter-${
                        filterValue.dataTestType || filterValue.label
                      }-options`.toLowerCase()}
                      value={
                        pendingValue?.[key]
                          ? (pendingValue?.[key] as string[])
                          : []
                      }
                      onChange={(value) =>
                        setPendingValue((prev) => ({
                          ...prev,
                          [key]: value,
                        }))
                      }
                      options={options}
                      {...filterValue.pickerProps}
                    />
                  )}
                  {filterValue.type === 'single' && (
                    <RadioGroup
                      data-testid={`filter-${
                        filterValue.dataTestType || filterValue.label
                      }-options`.toLowerCase()}
                      value={
                        typeof pendingValue?.[key] === 'string'
                          ? (pendingValue?.[key] as string)
                          : ''
                      }
                      onChange={(value) =>
                        setPendingValue((prev) => ({
                          ...prev,
                          [key]: value,
                        }))
                      }
                      options={options}
                      {...filterValue.pickerProps}
                    />
                  )}
                </FilterOption>
              );
            })}
          </div>
          <div data-testid="filter-actions" className="flex w-full px-2 pb-1">
            <SimpleButton
              data-testid="filter-apply-button"
              className="w-full"
              disabled={!hasChanges}
              onClick={handleApplyFilters(close)}
            >
              Apply
            </SimpleButton>
          </div>
        </div>
      )}
    </MenuField>
  );
};
