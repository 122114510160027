import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductKind } from '../../api/product/types';
import {
  Coverage,
  GetAlertsCountQuery,
  GetAvailablePublicProductsQuery,
  GetInstalledProductsQuery,
  GetProductAlertsQuery,
  Product,
} from '../../gql/graphql';
import { ExtendedInstalledProduct } from '../../pages/dashboard/hooks/useProducts';
export interface ProductsState {
  coverages: {
    kind: ProductKind;
    loading?: boolean;
    error?: Error;
    coverage?: Coverage;
  }[];
  products: {
    installed: (ExtendedInstalledProduct & Partial<Product>)[];
    alertCountByProductAndSeverity: {
      [key in ProductKind]?: {
        data?: GetProductAlertsQuery;
        loading?: boolean;
      };
    };
    available: GetAvailablePublicProductsQuery['products'];
    missing: GetAvailablePublicProductsQuery['products'];
    loading?: boolean;
  };
  alertsCount: GetAlertsCountQuery;
}

const initialState: ProductsState = {
  products: {
    installed: [],
    available: [],
    missing: [],
    alertCountByProductAndSeverity: {},
  },
  coverages: [],
  alertsCount: {
    CRITICAL: {
      totalItems: 0,
    },
    ERROR: {
      totalItems: 0,
    },
    STANDARD: {
      totalItems: 0,
    },
  },
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    updateInstalledProducts(
      state,
      action: PayloadAction<GetInstalledProductsQuery['products']>,
    ): ProductsState {
      return {
        ...state,
        products: {
          ...state.products,
          installed: action.payload,
        },
      };
    },
    updateAlertCountByProductAndSeverity(
      state,
      action: PayloadAction<
        ProductsState['products']['alertCountByProductAndSeverity']
      >,
    ): ProductsState {
      return {
        ...state,
        products: {
          ...state.products,
          alertCountByProductAndSeverity: {
            ...state.products.alertCountByProductAndSeverity,
            ...action.payload,
          },
        },
      };
    },
    updateAvailableProducts(
      state,
      action: PayloadAction<GetAvailablePublicProductsQuery['products']>,
    ): ProductsState {
      return {
        ...state,
        products: {
          ...state.products,
          available: action.payload,
        },
      };
    },
    updateLoadingProducts(
      state,
      action: PayloadAction<boolean>,
    ): ProductsState {
      return {
        ...state,
        products: {
          ...state.products,
          loading: action.payload,
        },
      };
    },
    updateMissingProducts(
      state,
      action: PayloadAction<GetAvailablePublicProductsQuery['products']>,
    ): ProductsState {
      return {
        ...state,
        products: {
          ...state.products,
          missing: action.payload,
        },
      };
    },
    updateAlertsCount(
      state,
      action: PayloadAction<GetAlertsCountQuery>,
    ): ProductsState {
      return {
        ...state,
        alertsCount: action.payload,
      };
    },
    updateInstalledProductCoverage(
      state,
      action: PayloadAction<ProductsState['coverages'][0]>,
    ): ProductsState {
      return {
        ...state,
        coverages: [
          ...state.coverages.filter(
            (coverage) => coverage.kind !== action.payload.kind,
          ),
          action.payload,
        ],
      };
    },
  },
});

export const {
  updateAvailableProducts,
  updateAlertCountByProductAndSeverity,
  updateInstalledProducts,
  updateMissingProducts,
  updateAlertsCount,
  updateLoadingProducts,
  updateInstalledProductCoverage,
} = productsSlice.actions;
export default productsSlice.reducer;
