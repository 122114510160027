import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CREATE_USER,
  DELETE_USER,
  GET_USERS,
  UPDATE_USER,
} from '../../api/team/queries';
import Spinner from '../../components/Elements/Spinner/Spinner';
import { ContentLayout } from '../../components/Layout';
import { Modal } from '../../components/common/Modal';
import {
  CreateUserMutation,
  DeleteUserMutation,
  GetUsersQuery,
  GetUsersQueryVariables,
  MutationCreateUserArgs,
  MutationDeleteUserArgs,
  MutationUpdateUserArgs,
  UpdateUserMutation,
  UserRole,
} from '../../gql/graphql';
import { useSession } from '../../utils/hooks/useSession';
import DeleteUserModal from './components/DeleteUserModal';
import Header from './components/Header';
import MemberBar from './components/MemberBar';
import { MemberEditInvite } from './components/MemberEditInvite';

export type IModal = 'Add' | 'Edit' | false;

const Team = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [members, setMembers] = useState<GetUsersQuery['users']>([]);
  const [selectedMember, setSelectedMember] = useState<
    GetUsersQuery['users'][0] | undefined
  >();
  const [memberIdToEdit, setMemberIdToEdit] = useState<string | undefined>('');
  const [searchString, setSearchString] = useState<string>('');
  const [roleFilter, setRoleFilter] = useState<UserRole | undefined>();

  const session = useSession();
  const { skip, isAdmin, isSuperAdmin } = session;
  const currentUserID = session.session?.identity?.id;

  const { data, loading } = useQuery<GetUsersQuery, GetUsersQueryVariables>(
    GET_USERS,
    {
      variables: {
        filter: {
          wildcard: searchString,
          role: roleFilter,
        },
      },
      skip,
      pollInterval: 10000,
      fetchPolicy: 'cache-and-network',
    },
  );

  const [createUser, createUserResult] = useMutation<
    CreateUserMutation,
    MutationCreateUserArgs
  >(CREATE_USER);

  const [updateUser, updateUserResult] = useMutation<
    UpdateUserMutation,
    MutationUpdateUserArgs
  >(UPDATE_USER);
  const [deleteUser, deleteUserResult] = useMutation<
    DeleteUserMutation,
    MutationDeleteUserArgs
  >(DELETE_USER);

  const [deletingUserIds, setDeletingUserIds] = useState<string[]>([]);

  useEffect(() => {
    if (data) setMembers(data.users);
  }, [data]);

  const handleAddMembers = ({
    email,
    name,
    roles,
  }: {
    email: string;
    name: string;
    roles: UserRole[];
  }) => {
    createUser({
      variables: {
        input: {
          name,
          email,
          roles,
        },
      },
    })
      .then((result) => {
        setModalOpen(false);
        result?.data &&
          setMembers((p) => [
            ...p,
            ...(result?.data?.createUser ? [result?.data?.createUser] : []),
          ]);
      })
      .catch(console.log);
  };
  const handleEditMember = (newMember: GetUsersQuery['users'][0]) => {
    updateUser({
      variables: {
        input: {
          id: newMember.id,
          name: newMember.name,
          roles: newMember.roles,
        },
      },
    })
      .then(() => {
        setMemberIdToEdit(undefined);
        setModalOpen(false);
      })
      .catch(console.log);
  };

  const onDeleteMemberHandler = ({ id }: GetUsersQuery['users'][0]) => {
    setDeleteModalOpen(false);
    setDeletingUserIds((p) => [...p, id]);
    deleteUser({
      variables: {
        id,
      },
    })
      .catch(() => null)
      .then((result) => {
        setMembers((p) =>
          p.filter((m) => m.id !== result?.data?.deleteUser?.id),
        );
      })
      .finally(() => {
        setDeletingUserIds((p) => p.filter((i) => i !== id));
      });
  };

  const onClickEditMemberHandler = useCallback(
    (member: GetUsersQuery['users'][0]) => {
      updateUserResult.reset();
      setMemberIdToEdit(member.id);
      setModalOpen(true);
    },
    [],
  );

  const onClickInviteHandler = useCallback(() => {
    createUserResult.reset();
    setMemberIdToEdit(undefined);
    setModalOpen(true);
  }, []);

  const modalContent = useMemo(() => {
    const member = members.find((m) => m.id === memberIdToEdit);
    return (
      <MemberEditInvite
        member={member}
        onEdit={handleEditMember}
        onInvite={handleAddMembers}
        loading={createUserResult.loading || updateUserResult.loading}
        error={
          member
            ? updateUserResult.error?.message
            : createUserResult.error?.message
        }
      />
    );
  }, [
    memberIdToEdit,
    handleAddMembers,
    handleEditMember,
    createUserResult,
    updateUserResult,
  ]);

  const handleDelete = (member: GetUsersQuery['users'][0]) => {
    setSelectedMember(member);
    setDeleteModalOpen(true);
  };

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchString(searchValue), 2000);
    return () => clearTimeout(timeOutId);
  }, [searchValue]);

  const handleSearch = useCallback(
    (value: string) => {
      setSearchValue(value);
    },
    [loading],
  );

  return (
    <>
      <ContentLayout
        title="Team"
        data-testid="team-container"
        description="Manage user access and permissions."
        variant="md"
      >
        <Header
          onInvite={onClickInviteHandler}
          onSearch={handleSearch}
          onConfirmSearch={() => setSearchString(searchValue)}
          onRoleFilter={setRoleFilter}
          searchPaused={loading}
        />
        {loading && (
          <div className="flex h-20 items-center justify-center">
            <Spinner className="h-8 w-8" />
          </div>
        )}

        <div data-testid="team-members" className="flex w-full flex-col">
          {!members.length && !loading && <span>No results</span>}
          {!loading &&
            members.map((member) => {
              return (
                <MemberBar
                  key={member.id}
                  member={member}
                  onDelete={handleDelete}
                  deleting={
                    deleteUserResult.loading &&
                    deletingUserIds.includes(member.id)
                  }
                  onEdit={onClickEditMemberHandler}
                  isAdmin={isAdmin}
                  isSuperAdmin={isSuperAdmin}
                  currentUserID={currentUserID}
                />
              );
            })}
        </div>
      </ContentLayout>
      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        title={memberIdToEdit ? 'Edit User' : 'Invite people'}
      >
        {modalContent}
      </Modal>
      {selectedMember && (
        <Modal isOpen={deleteModalOpen} isCloseable={false}>
          <DeleteUserModal
            member={selectedMember}
            onDelete={onDeleteMemberHandler}
            onClose={() => {
              setDeleteModalOpen(false);
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default Team;
