import { useState } from 'react';
import './Tooltip.css';

export const CardCoverageTooltip = ({
  children,
  delay,
  content,
  direction,
  color,
  disabled,
}: {
  children: JSX.Element;
  delay?: number;
  content: JSX.Element | undefined;
  direction?: string;
  color: string;
  disabled: boolean | undefined;
}) => {
  let timeout: NodeJS.Timeout | undefined;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 100);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="Tooltip-Wrapper"
      // When to show the tooltip
      onMouseEnter={!disabled ? showTip : undefined}
      onMouseLeave={hideTip}
    >
      {/* Wrapping */}
      {children}
      {active && content && (
        <div
          className={`Tooltip-Tip ${direction || 'top'}`}
          style={{
            background: color,
            color: 'white',
            borderTopColor: color,
          }}
        >
          {/* Content */}
          {content}
        </div>
      )}
    </div>
  );
};
