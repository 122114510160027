import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { ModalTrigger } from '../../../common/Modal';
import { getTextColorClassNames } from '../utils';

export const Label = ({
  children,
  htmlFor,
  className,
  size = 'medium',
  placement = 'top',
  help,
  disabled,
  required,
  ...rest
}: {
  children: React.ReactNode;
  htmlFor?: string;
  className?: string;
  size?: 'small' | 'medium' | 'large' | 'xl';
  placement?: 'left' | 'right' | 'top' | 'bottom' | 'none';
  help?: React.ReactNode;
  disabled?: boolean;
  required?: boolean | string;
}) => {
  return children ? (
    <label
      htmlFor={htmlFor}
      className={twMerge(
        classNames(
          size === 'small' && 'text-xs',
          size === 'medium' && 'text-sm',
          size === 'large' && 'text-base',
          'flex gap-2',
          typeof required === 'string' ? 'w-full' : 'w-fit',
          getTextColorClassNames({ disabled }),
          {
            'mb-1.5': placement === 'top',
            'mt-1': placement === 'bottom',
            'mr-1': placement === 'left',
            'ml-1': placement === 'right',
          },
          className,
        ),
      )}
      {...rest}
    >
      {children}
      {required && <span className="text-tw-critical-color">*</span>}
      {help && (
        <ModalTrigger modalContent={help} modalTitle={children}>
          <span className="flex h-3.5 w-3.5 cursor-pointer items-center justify-center rounded-full bg-black/10 text-[9px] text-black/50 hover:text-black/80 dark:bg-tw-light-hover-dark dark:text-white/50 dark:hover:text-white/80">
            ?
          </span>
        </ModalTrigger>
      )}
      {typeof required === 'string' && (
        <span className="ml-auto text-tw-critical-color">{required}</span>
      )}
    </label>
  ) : null;
};
