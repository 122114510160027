import { JSONSchema7, validate } from 'json-schema';
import { useCallback, useEffect, useState } from 'react';
import { jsonschemaValidation } from './jsonSchemaUtils';
import { FormFields, JSONValue } from './types';

export const useValidation = (configSchema: JSONSchema7, values: JSONValue) => {
  const [validationResult, setValidationResult] = useState(
    validate(values, configSchema),
  );

  useEffect(() => {
    setValidationResult(validate(values, configSchema));
  }, [values]);

  const formFieldsValidation = useCallback(
    (formFields: FormFields) => {
      const result = jsonschemaValidation(formFields, configSchema);
      setValidationResult(result.validationResult);
      return result;
    },
    [configSchema],
  );

  return {
    values,
    isValid: validationResult.valid,
    formFieldsValidation,
    validationResult,
  };
};
