import classNames from 'classnames';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { twMerge } from 'tailwind-merge';

export const PRIMARY_BUTTON_TEST_ID = 'primaryButtonTestID';

export interface ButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size?: 'small' | 'medium' | 'large';
}

const PrimaryButton = ({
  children,
  size = 'medium',
  className,
  ...rest
}: ButtonProps) => {
  return (
    <button
      data-testid={PRIMARY_BUTTON_TEST_ID}
      className={twMerge(
        classNames(
          'mt-4 rounded-xl font-graphikMedium text-white dark:shadow-[0px_1.4065769910812378px_0px_0px_#00000080]',
          'bg-tw-main-color shadow-[0px_1.4065769910812378px_0px_0px_#00000080] hover:bg-[#0099FF]',
          'disabled:bg-tw-inactive-blue disabled:shadow-none disabled:dark:bg-[#2D3036] disabled:dark:text-tw-dark-shade-dark',
          size === 'large' && 'h-[78px] w-[333px]',
          size === 'medium' && 'h-[49px] w-[205px]',
          size === 'small' && 'h-[40px] w-[140px]',
          className,
        ),
      )}
      {...rest}
    >
      <span
        className={`
        ${size === 'large' && 'text-xl'}
        ${size === 'medium' && ''}
        ${size === 'small' && 'text-sm'}
         `}
      >
        {children}
      </span>
    </button>
  );
};

export default PrimaryButton;
