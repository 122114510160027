import classNames from 'classnames';
import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { FieldError, Label } from '../Common';
import { Size, Variant } from '../types';
import { getInputFieldClassName } from '../utils';

export interface InputTextProps
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'required'
  > {
  placeholder?: string;
  label?: string;
  titleHelp?: React.ReactNode;
  variant?: Variant;
  variantSize?: Size;
  error?: string | string[];
  required?: boolean | string;
  afterInput?: React.ReactNode;
  labelClassname?: string;
  secret?: boolean;
}

export const InputText = ({
  label,
  variant = 'default',
  variantSize = 'medium',
  className,
  error,
  value,
  required,
  afterInput,
  titleHelp,
  labelClassname,
  secret,
  type,
  ...props
}: InputTextProps) => {
  return (
    <>
      {label && (
        <Label
          htmlFor={props.id}
          help={titleHelp}
          size={variantSize}
          className={labelClassname}
        >
          {label}
        </Label>
      )}
      <input
        // change from controlled to uncontrolled
        value={value ?? ''}
        type={secret ? 'password' : type}
        required={Boolean(required)}
        data-testid="InputField"
        className={twMerge(
          classNames(
            'truncate',
            getInputFieldClassName({
              disabled: props.disabled,
              variant,
              error,
            }),
            className,
          ),
        )}
        {...props}
      />
      {afterInput}
      {error && <FieldError error={error} />}
    </>
  );
};
