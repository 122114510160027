import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { SimpleButton } from '../../Buttons/SimpleButton';
import { LayoutAreaId } from '../../Layout/types';

export type BoundsId = LayoutAreaId | string | null;
export interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  isCloseable?: boolean;
  title?: React.ReactNode;
  children: React.ReactNode;
  childrenClassName?: string;
  layoutArea?: BoundsId;
}

export const Modal = ({
  isOpen,
  onClose,
  isCloseable = true,
  title,
  children,
  childrenClassName = 'm-5 scrollbar overflow-auto',
  layoutArea = LayoutAreaId.BODY,
  ...rest
}: ModalProps) => {
  const boundsElement = useMemo(
    () =>
      layoutArea ? document.getElementById(layoutArea) ?? undefined : undefined,
    [layoutArea, isOpen],
  );

  const [bound, setBound] = useState(boundsElement?.getBoundingClientRect());

  useEffect(() => {
    setBound(boundsElement?.getBoundingClientRect());
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      setBound(boundsElement?.getBoundingClientRect());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [boundsElement]);

  const boundsStyle = useMemo(
    () =>
      bound
        ? {
            height: `${bound?.height}px`,
            width: `${bound?.width}px`,
            top: `${bound?.top}px`,
            left: `${bound?.left}px`,
          }
        : {
            width: '100%',
            height: '100%',
          },
    [bound],
  );

  return (
    <Transition
      data-testid="Modal"
      appear
      show={isOpen}
      as={Fragment}
      {...rest}
    >
      <Dialog
        onClose={() => {
          onClose?.();
        }}
        itemID="Modal"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-black/20 dark:bg-black/50"
            aria-hidden="true"
            style={boundsStyle}
          />
        </Transition.Child>
        <div
          className={`margin-auto fixed inset-0 flex items-center justify-center text-sm text-tw-main-text dark:text-tw-main-text-dark`}
          style={boundsStyle}
        >
          <div className="flex max-h-full min-h-full flex-col items-center justify-center overflow-hidden p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="max-w-screen flex h-auto max-h-screen w-auto flex-col overflow-auto rounded-lg  bg-white shadow dark:bg-[#1F2225]">
                <Dialog.Title
                  className={classNames(
                    'flex items-center justify-between gap-4 border-b-2 border-black/10 dark:border-[#292B2F]',
                    !isCloseable && !title && 'hidden',
                  )}
                >
                  <label
                    data-testid="modal-title"
                    className={classNames(
                      'pb-1 pl-4 pt-2 font-graphikMedium text-base text-tw-main-text dark:text-tw-main-text-dark',
                      !isCloseable && 'pr-4',
                    )}
                  >
                    {title}
                  </label>
                  {isCloseable && (
                    <SimpleButton
                      data-testid="modal-close-button"
                      variant="default-ghost"
                      onClick={onClose}
                    >
                      <FontAwesomeIcon icon={faClose} />
                    </SimpleButton>
                  )}
                </Dialog.Title>
                <div className={childrenClassName}>{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
