import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { SupernaHeader } from '../../pages/loginPage/components/SupernaHeader';
import { ThemeButton } from '../../pages/loginPage/components/ThemeButton';
import { LayoutAreaId } from './types';

export const SingleFormLayout = ({
  children,
  className,
  ...props
}: {
  children: React.ReactNode;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <div
      id={LayoutAreaId.CONTAINER}
      className={twMerge(
        'flex max-h-screen flex-col overflow-auto bg-tw-light-shade pb-4 dark:bg-tw-dark-shade-dark lg:h-screen lg:overflow-hidden',
        'text-tw-main-text dark:text-tw-main-text-dark',
        className,
      )}
      {...props}
    >
      <ThemeButton />
      <div
        className="flex h-full flex-col items-center justify-center overflow-auto"
        id={LayoutAreaId.BODY}
      >
        <SupernaHeader />
        <div
          className="scrollbar mt-14 flex h-full items-start justify-start gap-10 overflow-auto px-1"
          id={LayoutAreaId.CONTENT}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
