import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { range } from 'lodash';
import React, { useEffect, useId, useMemo, useRef, useState } from 'react';
import { ProductKind } from '../../../api/product/types';
import { ProductIcon } from '../Icons/ProductIcons/ProductIcon';
import { IAppliance, IProduct } from './FilterButtonHolder';

const renderProductIcon = (product: IProduct, isSelected: boolean) => (
  <ProductIcon
    key={product.kind}
    productKind={product.kind as ProductKind}
    className={`h-10 w-10 rounded-md border-2 ${
      isSelected
        ? 'border-[#EEF4FF] bg-white fill-white text-[#013FFF]'
        : 'bg-white fill-white text-tw-inactive-color dark:border-[#383942] dark:bg-[#25282D] dark:fill-[#25282D] dark:text-[#897D7D]'
    } `}
  />
);
interface IProps {
  appliance: IAppliance;
  updateSelected?: (product: string) => void;
  selected: Array<string> | null;
}
const FilterButton = ({
  appliance,
  selected,
  updateSelected,
  ...rest
}: IProps) => {
  // no selection means all is selected
  const isSelected =
    !selected || !selected.length || selected.includes(appliance.applianceId);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    updateSelected?.(appliance.applianceId);
  };
  const [hover, setHover] = useState(false);
  const [width, setWidth] = useState(0);
  const ref = useRef<HTMLButtonElement | null>(null);
  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref, ref.current?.offsetWidth]);

  const id = useId();

  const getAppIcons = () => {
    const renderIcons = (count: number) => {
      return (
        <>
          {range(count).map(
            (num) =>
              appliance.installedProducts[num] &&
              renderProductIcon(
                appliance.installedProducts[num]?.product,
                isSelected,
              ),
          )}
          {appliance.installedProducts.length > count && (
            <span className="font-graphikMedium text-[#897D7D]">
              +{appliance.installedProducts.length - count}
            </span>
          )}
        </>
      );
    };

    if (width > 430) {
      return hover
        ? appliance.installedProducts.map((installedProduct) =>
            renderProductIcon(installedProduct.product, isSelected),
          )
        : renderIcons(3);
    } else {
      return hover
        ? appliance.installedProducts.map((installedProduct) =>
            renderProductIcon(installedProduct.product, isSelected),
          )
        : renderIcons(1);
    }
  };

  const info = useMemo(() => {
    if ((width <= 430 && !hover) || width > 430) {
      return (
        <div data-testid="info" className="flex flex-col items-start gap-1">
          <h5
            data-testid="description"
            id={`${id}-description`}
            className="font-graphikMedium dark:text-white"
            style={{ fontSize: '12px' }}
          >
            {appliance.description}
          </h5>
          <p
            data-testid="node"
            className="font-graphikMedium text-[#897D7D]"
            style={{ fontSize: '10px' }}
          >
            {appliance.node}
          </p>
        </div>
      );
    } else {
      return null;
    }
  }, [appliance, width, hover]);

  return (
    <button
      data-testid="appliance-filter-option-button"
      data-test-value={appliance.id}
      role="option"
      aria-checked={isSelected}
      aria-labelledby={`${id}-description`}
      onClick={handleClick}
      className={`flex h-[70px] flex-grow basis-1/4 cursor-pointer flex-row items-center justify-between rounded-md border-2 ${
        isSelected ? 'border-[#2A63EF]' : 'dark:border-[#25282D]'
      } p-3 dark:bg-[#25282D]`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      ref={ref}
      {...rest}
    >
      <div className="flex flex-row items-center gap-2">
        {appliance.installedProducts.length > 0 && (
          <div className="flex flex-row items-center gap-1">
            {getAppIcons()}
          </div>
        )}
        {info}
      </div>
      <input
        data-testid="appliance-filter-option-checkbox"
        type="checkbox"
        className="hidden"
        aria-labelledby={`${id}-description`}
        checked={isSelected}
        readOnly={true}
      />
      {isSelected && (
        <FontAwesomeIcon
          icon={faCheck}
          data-testid="selected-icon"
          className="h-[10px] w-[16px]"
        />
      )}
    </button>
  );
};
export default FilterButton;
