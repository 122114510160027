import { LoginFlow, RegistrationFlow, SettingsFlow } from '@ory/kratos-client';

export const messagesFromData = (
  data: LoginFlow | RegistrationFlow | SettingsFlow,
) => {
  if (!data) {
    return [];
  }
  const { messages: flowMessages } = data?.ui || {};
  const nodesMessages = data?.ui?.nodes?.map((node) => node?.messages).flat();

  return [...(flowMessages || []), ...nodesMessages];
};
