import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { DateRangeValue } from './PastDurationPicker';
dayjs.extend(duration);

export type Duration = 'P1D' | 'P7D' | 'P30D' | string;
export interface IRanges {
  value: Duration;
  label: string;
}

export const RANGE_OPTIONS: Array<IRanges> = [
  { value: 'P1D', label: 'Last Day' },
  { value: 'P7D', label: 'Past 7 Days' },
  { value: 'P30D', label: 'Past 30 Days' },
];

export const getStartDateFromRange = (range: IRanges) => {
  // TODO: discuss with Backend team if it is a good idea to have AllTime as a range
  if (range.value === 'AllTime')
    return dayjs().startOf('day').subtract(dayjs.duration('P100Y')).toDate();

  return dayjs().startOf('day').subtract(dayjs.duration(range.value)).toDate();
};
export const rangeIdToRange = (id: Duration) => {
  return RANGE_OPTIONS.find((p) => p.value === id);
};

export const rangeIdToValue = (id: Duration): DateRangeValue | null => {
  const range = rangeIdToRange(id);
  if (!range) return null;
  return {
    from: getStartDateFromRange(range),
    to: dayjs().endOf('day').toDate(),
  };
};
