import { useCallback } from 'react';
import { PickerOption } from '../form/fields';
import { SelectWithCheckMark } from '../form/fields/pickers/SelectWithCheckMark';
import {
  Duration,
  RANGE_OPTIONS,
  rangeIdToRange,
  rangeIdToValue,
} from './utils';

export interface DateRangeValue {
  from: Date | null;
  to: Date | null;
}
export interface PastDurationValue {
  duration: Duration;
  dateRange: DateRangeValue;
}

interface PastDurationPickerProps {
  onChange?: (value: PastDurationValue) => void;
  value?: DateRangeValue | Duration;
}

const PastDurationPicker = ({
  value,
  onChange,
  ...rest
}: PastDurationPickerProps) => {
  const handleChange = useCallback(
    (value: PickerOption | undefined) => {
      const range = rangeIdToRange(value?.value as Duration);
      if (range) {
        if (range.value !== 'custom') {
          const dateRange = rangeIdToValue(range.value);
          if (dateRange) {
            onChange?.({
              duration: range.value,
              dateRange: dateRange,
            });
          }
        }
      }
    },
    [onChange],
  );

  return (
    <SelectWithCheckMark
      options={RANGE_OPTIONS}
      onChange={handleChange}
      value={value ? (value as string) : undefined}
      {...rest}
    />
  );
};

export default PastDurationPicker;
