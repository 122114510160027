import React from 'react';

export const TeamIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <mask
        id="mask0_3739_156529"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="10"
        width="15"
        height="7"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.949219 10.1162H14.7686V16.5371H0.949219V10.1162Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0_3739_156529)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2996 15.0523C13.1808 14.6485 13.1192 14.2535 12.9535 13.9084C12.227 12.3955 11.0123 11.6124 9.32494 11.5955C8.34337 11.5857 7.36163 11.5904 6.37997 11.5961C6.1406 11.5974 5.8976 11.6068 5.66268 11.6488C4.31929 11.8889 3.33458 12.6147 2.77456 13.866C2.61019 14.2333 2.52958 14.638 2.40234 15.0523H13.2996ZM7.84447 16.5352C5.94304 16.5352 4.04161 16.5343 2.14018 16.5361C1.73488 16.5364 1.36167 16.4426 1.14474 16.078C1.03121 15.8873 0.942668 15.6425 0.9496 15.4255C0.994569 14.009 1.47166 12.7559 2.477 11.7374C3.31046 10.8928 4.31712 10.3596 5.50218 10.206C5.94255 10.1489 6.38985 10.1311 6.83451 10.1238C7.61682 10.1111 8.39954 10.1174 9.18209 10.1209C10.4504 10.1267 11.6264 10.4341 12.6438 11.2219C13.7154 12.0518 14.4047 13.1251 14.6491 14.4669C14.703 14.7627 14.7326 15.0634 14.7627 15.3629C14.8286 16.0174 14.3387 16.5281 13.6311 16.5336C12.7663 16.5404 11.9013 16.5352 11.0364 16.5352C9.9724 16.5352 8.90848 16.5352 7.84447 16.5352Z"
          fill="currentColor"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2335 2.43578C13.59 1.3693 15.7411 1.47764 16.9972 3.04613C18.0454 4.35487 18.0228 6.53404 16.472 7.84254C15.1802 8.93237 12.9894 8.84977 11.8398 7.60853C12.9497 5.96767 13.112 4.24645 12.2335 2.43578Z"
        fill="currentColor"
      />
      <mask
        id="mask1_3739_156529"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="0"
        width="9"
        height="9"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.92188 0.748047H11.7889V8.6269H3.92188V0.748047Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask1_3739_156529)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3306 4.68445C10.3345 3.29889 9.26091 2.21731 7.86974 2.20518C6.47873 2.19313 5.38279 3.27983 5.37892 4.67512C5.37504 6.06052 6.44878 7.14226 7.83979 7.15439C9.23063 7.16644 10.3267 6.07958 10.3306 4.68445ZM7.8529 8.62695C5.56094 8.55293 3.91456 6.93749 3.9219 4.67226C3.92949 2.3496 5.63363 0.737047 7.86784 0.748103C10.1659 0.75949 11.7879 2.43054 11.7889 4.67696C11.79 6.9478 10.1393 8.55656 7.8529 8.62695Z"
          fill="currentColor"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4688 10.6142C13.9798 10.6142 14.4313 10.599 14.8815 10.6178C15.5035 10.6439 16.1333 10.6436 16.7439 10.7477C17.7794 10.9241 18.6547 11.4287 19.376 12.2051C20.0869 12.9704 20.4928 13.8725 20.6476 14.8935C20.6853 15.1419 20.7073 15.4021 20.6773 15.6496C20.6112 16.195 20.2395 16.5272 19.6911 16.5312C18.2974 16.5412 16.9036 16.5345 15.4867 16.5345C15.5501 16.3867 15.6149 16.2551 15.6641 16.1179C15.8395 15.628 15.7732 15.1249 15.711 14.63C15.575 13.5468 15.1675 12.5674 14.5016 11.7014C14.3142 11.4576 14.097 11.236 13.8865 11.0107C13.77 10.886 13.639 10.7747 13.4688 10.6142Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TeamIcon;
