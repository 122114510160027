import SupernaLogo from '../../assets/icons/SupernaLogo';
import { useSession } from '../../utils/hooks/useSession';
import { ModeSwitch } from '../Elements/ModeSwitch';
import { Notification } from '../Elements/Notification';

export const Head = ({
  logo = (
    <SupernaLogo className="h-7 w-32 text-tw-main-text dark:text-tw-main-text-dark" />
  ),
  hideNotification,
  ...props
}: {
  logo?: React.ReactNode;
  hideNotification?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const { auth } = useSession();
  return (
    <div
      data-testid="Head"
      id="Head"
      className="flex flex-row items-center justify-between border-b-2 border-tw-hover-alternative bg-white p-4 dark:border-[#292B2F] dark:bg-[#1A1C1E]"
      {...props}
    >
      <div className="flex h-full w-full items-center justify-between gap-2">
        {logo}
        <div className="flex">
          <ModeSwitch aria-label="theme-switch" />
          {auth && !hideNotification && <Notification />}
        </div>
      </div>
    </div>
  );
};
