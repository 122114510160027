import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { useLocation } from 'react-router-dom';
import { Icon, IconName } from '../assets/icons/Icon';
import { ProductIcon } from '../components/Elements/Icons/ProductIcons/ProductIcon';
import Spinner from '../components/Elements/Spinner/Spinner';
import { store } from '../redux/store';
import { APPS_BUILD_PATH, APPS_URL_PATH } from '../routes/protected';
import { BasePlugin, ExtendedPlugin } from './types';

declare global {
  interface Window {
    [key: string]: any;
  }
}

const extendPlugin = (plugin: BasePlugin): ExtendedPlugin => ({
  ...plugin,
  ...window[plugin.buildPath],
  error: !window[plugin.buildPath]?.available && 'App not available',
});

const preFetch = (
  plugin: BasePlugin,
  onLoad?: ({ plugin }: { plugin: ExtendedPlugin }) => void,
  forceFetch = false,
) => {
  const scriptId = `micro-frontend-script-${plugin}`;
  const scriptElement = document.getElementById(scriptId);
  if (!forceFetch && scriptElement) {
    if (onLoad) {
      if (window[plugin.buildPath]?.available) {
        onLoad({
          plugin: extendPlugin(plugin),
        });
      } else {
        scriptElement.onload = () => {
          onLoad &&
            onLoad({
              plugin: extendPlugin(plugin),
            });
        };
      }
    }
    return;
  }
  fetch(`/${APPS_BUILD_PATH}/${plugin.buildPath}/asset-manifest.json`)
    .then((res) => res.json())
    .then((manifest) => {
      const script = document.createElement('script');
      script.id = scriptId;
      script.crossOrigin = '';
      script.async = true;
      script.src = manifest.files['main.js'];
      script.onload = () => {
        console.log(`Loaded ${plugin.title} app`);
        onLoad &&
          onLoad({
            plugin: extendPlugin(plugin),
          });
      };
      document.head.appendChild(script);
      const css_href = manifest.files['main.css'];
      if (css_href) {
        const css = document.createElement('link');
        css.id = `micro-frontend-css-${plugin.buildPath}`;
        css.rel = 'stylesheet';
        css.type = 'text/css';
        css.crossOrigin = '';
        css.href = `${css_href}`;
        document.head.appendChild(css);
      }
    })
    .catch((err) => {
      console.log(`Could not find ${plugin.title} app: ${err} prefetch`, err);
      window[plugin.buildPath] = {
        available: false,
      };
      onLoad &&
        onLoad({
          plugin: {
            ...plugin,
            available: false,
            error: `Could not find ${plugin.title}`,
            title: plugin.title,
            description: `${plugin.title} not available`,
            icon: (
              <ProductIcon
                disabled
                productKind={plugin.kind}
                className="h-9 w-9"
              />
            ),
          },
        });
    });
};

const reload = (plugin: ExtendedPlugin) => {
  preFetch(plugin, plugin.onLoad, true);
};

const MicroFrontend = ({
  plugin,
}: {
  plugin: ExtendedPlugin;
  state?: { text: string };
}) => {
  const { buildPath, path } = plugin;
  const id = `${buildPath}-container`;
  const location = useLocation();

  useEffect(() => {
    let root: ReactDOM.Root;
    const renderMicroFrontend = () => {
      const element = document.getElementById(id);
      if (element) {
        if (plugin.available && plugin.render) {
          root = plugin.render(id, store, `${APPS_URL_PATH}/${path}`);
        } else {
          root = ReactDOM.createRoot(element);
          root.render(
            <div className="flex w-full flex-col items-center justify-center p-5">
              <div style={{ width: '100%', textAlign: 'center' }}>
                <h1>{plugin.title} App is not available</h1>
                <p>
                  Try to refresh the page or{' '}
                  <span
                    className="cursor-pointer text-tw-main-color"
                    onClick={() => {
                      if (root) root.unmount();
                      reload({ ...plugin, available: false });
                    }}
                  >
                    reload plugin
                  </span>
                </p>
                <div className="mt-12">
                  <a
                    href="/"
                    className="flex flex-row items-center gap-2 font-graphikBold text-tw-main-text dark:text-tw-main-text-dark"
                    style={{ fontSize: '20px', lineHeight: '22px' }}
                  >
                    <span aria-hidden="true">
                      <Icon
                        iconName={IconName.ArrowLeftIcon}
                        width="12"
                        height="12"
                      />
                    </span>
                    Go back to Home
                  </a>
                </div>
              </div>
            </div>,
          );
        }
      } else {
        console.log(`container '${id}' not found`);
        return;
      }
    };

    if (plugin.fetched) {
      renderMicroFrontend();
      return;
    }

    return () => {
      if (root) root.unmount();
    };
  }, [plugin, location]);

  return (
    <div id={id} className="h-full w-full">
      <div className="flex h-full w-full flex-col items-center justify-center p-5">
        <Spinner />
        <span>{`Loading ${plugin.title}`}</span>
      </div>
    </div>
  );
};

export { MicroFrontend, preFetch };
export default MicroFrontend;
