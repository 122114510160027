import classNames from 'classnames';
import { InputHTMLAttributes, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  CrossIcon,
  SearchIcon as DefaultSearchIcon,
} from '../../../../assets/icons';

type Size = 'small' | 'medium' | 'large';
export interface SearchInputProps {
  onChange: (value: string) => void;
  onPressEnter?: () => void;
  value?: string;
  placeholder?: string;
  size?: Size;
  keepStrong?: boolean;
  inlineChildAfter?: React.ReactNode;
  customClassNames?: {
    input?: string;
    wrapper?: string;
    searchIcon?: string;
    clearIcon?: string;
  };
  // temporary disable input without losing focus
  pauseInput?: boolean;
  SearchIcon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  error?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
}

const SearchInput = ({
  onChange,
  onPressEnter,
  placeholder = 'Search',
  size = 'medium',
  keepStrong = false,
  inlineChildAfter,
  customClassNames,
  value = '',
  pauseInput,
  SearchIcon = DefaultSearchIcon,
  error,
  inputProps,
  ...rest
}: SearchInputProps) => {
  const [searchValue, setSearchValue] = useState<string>(value);
  const [inputFocus, setInputFocus] = useState<boolean>(false);

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchValue(e.target.value);
    onChange(e.target.value);
  };

  const clearSearch = () => {
    setSearchValue('');
    onChange('');
  };

  return (
    <div
      data-testid="search-input"
      className={twMerge(
        classNames(
          size === 'large'
            ? 'rounded-xl py-3.5 pl-4 pr-4 text-base'
            : 'rounded-md py-3 pl-4 pr-3 text-sm',
          'relative flex h-full w-full items-center transition',
          'bg-tw-light-shade p-2 dark:bg-tw-strong-shade-dark',
          inputFocus &&
            'border-transparent outline-none ring-2 ring-tw-main-color',
          inputFocus || (keepStrong && searchValue.length)
            ? 'text-tw-main-text dark:text-tw-main-text-dark'
            : 'text-tw-description-text dark:text-tw-description-text',
          error &&
            'ring-2 ring-tw-critical-color dark:ring-tw-critical-color-dark',
          customClassNames?.wrapper,
        ),
      )}
      {...rest}
    >
      <SearchIcon
        className={classNames('absolute', customClassNames?.searchIcon)}
      />
      <input
        type="text"
        aria-label="Search"
        name="Search"
        placeholder={placeholder}
        className={classNames(
          'ml-8 w-full bg-transparent focus:outline-none active:outline-none',
          customClassNames?.input,
        )}
        value={searchValue}
        onFocus={() => setInputFocus(true)}
        onBlur={() => setInputFocus(false)}
        onChange={!pauseInput ? handleChange : undefined}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            clearSearch();
          }
          if (e.key === 'Enter') {
            e.preventDefault();
            onPressEnter && onPressEnter();
          }
        }}
        autoComplete="off"
        {...inputProps}
      />
      {Boolean(searchValue.length) && (
        <button
          aria-label="Clear search"
          data-testid="search-input-clear-button"
          onClick={!pauseInput ? clearSearch : undefined}
        >
          <CrossIcon
            data-testid="SearchInputClear"
            className={classNames(
              {
                'h-7 w-7': size === 'large',
                'h-5 w-5': size === 'medium',
                'h-3 w-3': size === 'small',
              },
              'text-tw-description-text transition-colors hover:text-tw-main-text dark:hover:text-white',
              customClassNames?.clearIcon,
            )}
          />
        </button>
      )}
      {inlineChildAfter}
    </div>
  );
};

export default SearchInput;
