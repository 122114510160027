export const RWDBreakdown = () => {
  // Graphql API call to get events and other relevant data

  // Mock variables
  const securityGuardPassed = true;
  const criticalEvents = 5;

  return (
    <div>
      <p>
        {criticalEvents} Critical Events{criticalEvents > 0 ? '(0%)' : '(75%)'}
      </p>
      <br></br>
      <p>
        Security Guard {securityGuardPassed ? 'passed' : 'failed'}
        {securityGuardPassed ? '(25%)' : '(0%)'}
      </p>
    </div>
  );
};

export const EABreakdown = () => {
  // Graphql API call to get events and other relevant data

  // Mock variables
  const RoboAudit = true;
  const events = 5;

  return (
    <div>
      <p>
        {events} Active Events{events > 0 ? '(0%)' : '(75%)'}
      </p>
      <br></br>
      <p>
        RoboAudit {RoboAudit ? 'passed' : 'failed'}
        {RoboAudit ? '(25%)' : '(0%)'}
      </p>
    </div>
  );
};

export const DRBreakdown = () => {
  // Graphql API call to get events and other relevant data

  // Mock variables
  const RunbookRobot = true;
  const Readiness = 'OK';

  return (
    <div>
      <p> Readiness {Readiness === 'OK' ? '(0%)' : '(75%)'}</p>
      <br></br>
      <p>
        RoboAudit {RunbookRobot ? 'passed' : 'failed'}
        {RunbookRobot ? '(25%)' : '(0%)'}
      </p>
    </div>
  );
};

export const AGBreakdown = () => {
  // Graphql API call to get events and other relevant data

  // Mock variables
  const failedJobs = 0;

  return (
    <div>
      <p>
        Previous jobs {failedJobs > 0 ? 'failed' : 'passed'}
        {failedJobs > 0 ? '(0%)' : '(100%)'}
      </p>
    </div>
  );
};

export const CSMBreakdown = () => {
  // Graphql API call to get events and other relevant data

  // Mock variables
  const failedJobs = 0;

  return (
    <div>
      <p>
        Previous jobs {failedJobs > 0 ? 'failed' : 'passed'}
        {failedJobs > 0 ? '(0%)' : '(100%)'}
      </p>
    </div>
  );
};
