import classNames, { Argument } from 'classnames';
import { twMerge } from 'tailwind-merge';

export enum BadgeVariant {
  Danger = 'danger',
  Warning = 'warning',
  Success = 'success',
  Primary = 'primary',
  PrimarySoft = 'primarySoft',
  PrimaryCount = 'primaryCount',
}

export interface BadgeProps {
  children: React.ReactNode;
  variant?: BadgeVariant | `${BadgeVariant}`;
  className?: Argument;
}

export const baseClassname = 'p-2 py-1 font-graphikMedium text-xs';
export const borderClassname = 'rounded-lg border-2 uppercase leading-3';

export const VARIANT_CLASSNAMES: {
  [key in BadgeVariant]: string;
} = {
  danger: borderClassname + ' border-tw-danger-strong bg-tw-danger-soft',
  primary: borderClassname + ' border-tw-primary-strong bg-tw-primary-soft',
  success: borderClassname + ' border-tw-success-strong bg-tw-success-soft',
  warning: borderClassname + ' border-tw-warning-strong bg-tw-warning-soft',
  primarySoft:
    'rounded-md py-1.5 bg-tw-primary-soft dark:bg-tw-primary-soft-dark',
  primaryCount:
    'min-w-[2em] text-center bg-tw-primary-strong rounded-md text-white',
};

export const Badge = ({
  variant = BadgeVariant.Primary,
  className,
  children,
  ...rest
}: BadgeProps) => {
  return (
    <span
      className={twMerge(
        classNames(baseClassname, VARIANT_CLASSNAMES[variant], className),
      )}
      {...rest}
    >
      {children}
    </span>
  );
};
