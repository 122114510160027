import classNames from 'classnames';
import { isNil } from 'ramda';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ProductKind } from '../../../../api/product/types';
import { Icon, IconName } from '../../../../assets/icons/Icon';
import InfoCircleIcon from '../../../../assets/icons/InfoCircleIcon';
import { AlertCount, AlertSeverity } from '../../../../gql/graphql';
import { useAppDispatch } from '../../../../redux/hooks';
import { updateProductsFilterProducts } from '../../../../redux/products/filterSlice';
import { formatNumber, toTitleCase } from '../../../../utils/format';
import { ProductIcon } from '../../../Elements/Icons/ProductIcons/ProductIcon';
import Spinner from '../../../Elements/Spinner/Spinner';
import { ProductAlertCount } from '../../../ProductAlertCount';

interface IProps {
  kind: ProductKind;
  // If there is no alert count, a new query will be sent
  alerts?: AlertCount;
  connected?: boolean;
  comingSoon?: boolean;
  name: string;
}

const CardHeader = ({ kind, alerts, connected, comingSoon, name }: IProps) => {
  const { t } = useTranslation('productInfo');
  const dispatch = useAppDispatch();

  const severityAlerts = useMemo(
    () =>
      alerts && (alerts?.critical || alerts?.error || alerts?.standard || 0),
    [alerts],
  );
  const severityAlertsFormated = useMemo(
    () => (isNil(severityAlerts) ? undefined : formatNumber(severityAlerts)),
    [severityAlerts],
  );

  return (
    <div
      data-testid="product-card-header"
      className="flex items-center justify-between"
    >
      <div
        className={classNames(
          'flex grow flex-row items-center justify-between gap-2 p-4',
          connected &&
            'border-r-2 border-tw-light-bg dark:border-tw-dark-shade-dark',
        )}
      >
        <div className="flex w-full items-center">
          <ProductIcon
            productKind={kind}
            className="mr-4 h-12 w-12 fill-tw-main-fill-color text-white"
          />

          <div className="flex flex-col justify-end gap-1">
            <h2
              data-testid="product-card-header-name"
              className="font-graphikMedium text-tw-main-text dark:text-tw-dark-shade"
              style={{ lineHeight: '20px', fontSize: '18px' }}
            >
              {t(`${kind}.name`, kind)}
            </h2>

            <p
              data-testid="product-card-header-description"
              className="font-graphikRegular text-[0.875rem] leading-[1rem] text-tw-description-text lg:leading-[1.688rem]"
            >
              {t(`${kind}.description`, kind)}
            </p>
          </div>
        </div>

        {!connected || comingSoon ? null : (
          <div className="flex grow flex-row items-end">
            <div className="flex w-full flex-row items-start lg:items-center">
              <Link
                data-testid="product-card-header-learn-more"
                className="text-grey-600 w-15 mr-3 hidden h-auto rounded-lg border border-gray-400 px-3 py-1.5 text-center hover:bg-gray-200 dark:border-gray-900 dark:text-tw-main-text-dark dark:hover:bg-tw-dark-shade-dark lg:block"
                to={`/learn-more/${kind}`}
              >
                <div className="h-15">
                  <InfoCircleIcon className="h-[25px] w-[25px] text-tw-round-small-icon-color" />
                </div>
              </Link>
              {/* <Link
                className="w-[106px] rounded-lg border border-tw-main-color px-3 py-2.5 text-center font-graphikMedium text-[1rem] leading-[1.125rem] text-tw-main-color hover:bg-blue-100"
                to="#"
              >
                Open App
              </Link> */}
            </div>
          </div>
        )}
      </div>

      {connected ? (
        <div className="flex w-[82px] lg:w-[215px]">
          <div className="flex flex-col items-start self-center p-4">
            <div
              data-tooltip-id={'product-tooltip'}
              data-test-value={kind}
              data-tooltip-content={kind}
              className="flex flex-row items-center gap-3"
            >
              <Link
                data-testid="product-card-header-alerts"
                className="relative flex h-[3rem] w-[3rem] items-center rounded-lg  bg-[#F7F6F9] px-3 py-1.5 text-center text-tw-main-text dark:bg-tw-dark-shade-dark dark:text-tw-main-text-dark"
                to="./alerts"
                onClick={() => {
                  dispatch(updateProductsFilterProducts([kind]));
                }}
              >
                {severityAlerts ? (
                  <div
                    data-testid="product-card-header-alerts-count"
                    className="absolute -right-2 -top-2 flex h-[22px] w-[22px] items-center justify-center rounded-full border border-white bg-red-500 font-graphikSemi text-white lg:hidden"
                    style={{ fontSize: '12px', lineHeight: '13px' }}
                  >
                    {severityAlertsFormated || (
                      <ProductAlertCount kind={kind} />
                    )}
                  </div>
                ) : null}
                <Icon iconName={IconName.BellIcon} height="32" />
              </Link>
              <div className="hidden flex-col items-start lg:flex">
                {severityAlertsFormated ? (
                  <>
                    <h3
                      className="mr-10 font-graphikSemi text-tw-main-text dark:text-tw-main-text-dark"
                      style={{ fontSize: '18px', lineHeight: '20px' }}
                    >
                      {severityAlertsFormated || (
                        <ProductAlertCount kind={kind} />
                      )}
                    </h3>
                    <p
                      className="font-graphikRegular text-tw-description-text"
                      style={{ fontSize: '14px', lineHeight: '15px' }}
                    >
                      Alerts
                    </p>
                  </>
                ) : (
                  <ProductAlertCount kind={kind}>
                    {({ query, mostSevereCount, mostSeverity }) =>
                      query.loading ? (
                        <Spinner />
                      ) : (
                        <>
                          <h3
                            className="mr-10 font-graphikSemi text-tw-main-text dark:text-tw-main-text-dark"
                            style={{ fontSize: '18px', lineHeight: '20px' }}
                          >
                            {formatNumber(mostSevereCount || 0)}
                          </h3>
                          <p
                            className="font-graphikRegular text-tw-description-text"
                            style={{ fontSize: '14px', lineHeight: '15px' }}
                          >
                            {toTitleCase(
                              mostSeverity?.replace(
                                AlertSeverity.Standard,
                                'Alerts',
                              ) || '',
                            )}
                          </p>
                        </>
                      )
                    }
                  </ProductAlertCount>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CardHeader;
