import { useEffect, useState } from 'react';
import { SimpleButton } from '../../../components/Buttons/SimpleButton';
import Spinner from '../../../components/Elements/Spinner/Spinner';
import { Message } from '../../../components/common/Message';
import { Select } from '../../../components/form/fields';
import { GetUsersQuery, UserRole } from '../../../gql/graphql';
import { useSession } from '../../../utils/hooks/useSession';

export interface RoleWithLabel {
  value: UserRole;
  label: string;
}

const teamRoles: RoleWithLabel[] = [
  { value: UserRole.OrgAdmin, label: 'Admin' },
  {
    value: UserRole.OrgUser,
    label: 'User',
  },
];

const superAdminRoles: RoleWithLabel[] = [
  ...teamRoles,
  {
    value: UserRole.PlAdmin,
    label: 'SuperAdmin',
  },
];

export const MemberEditInvite = ({
  member,
  loading,
  onEdit,
  onInvite,
  error,
}: {
  member?: GetUsersQuery['users'][0];
  loading?: boolean;
  onEdit: (member: GetUsersQuery['users'][0]) => void;
  onInvite: (val: { email: string; name: string; roles: UserRole[] }) => void;
  error?: string;
}) => {
  const [email, setEmail] = useState<string>(member?.email || '');
  const [name, setName] = useState<string>(member?.name || '');
  const { isSuperAdmin } = useSession();
  const roles: RoleWithLabel[] = isSuperAdmin ? superAdminRoles : teamRoles;

  const [role, setRole] = useState<RoleWithLabel | null>(
    member ? roles.find((r) => r.value === member.roles[0]) || null : null,
  );

  useEffect(() => {
    if (member) {
      setRole(roles.find((r) => r.value === member.roles[0]) || null);
      setEmail(member.email);
    }
  }, [member]);

  return (
    <div data-testid="member-edit-invite">
      <div
        data-testid="member-edit-invite-form"
        className="mt-4 flex h-[350px] w-[500px] flex-col"
      >
        <div className="flex w-full flex-col justify-between gap-5 px-4">
          <div className="flex flex-col items-start">
            <label
              htmlFor="email"
              className="block font-graphikRegular text-xs text-tw-main-text dark:text-tw-main-text-dark"
            >
              Email
            </label>
            <div className="mt-1.5 w-full">
              <input
                data-testid="member-edit-invite-email"
                value={email}
                onInput={(e) => setEmail((e.target as HTMLInputElement).value)}
                type="email"
                name="email"
                id="email"
                className="block w-full rounded-md border-gray-300 bg-tw-light-shade p-2 font-graphikRegular text-sm text-[#6F7174] focus:border-indigo-500 focus:ring-indigo-500 dark:bg-[#131517]"
                placeholder="email@example.com"
                autoComplete="off"
                disabled={!!member}
              />
            </div>
          </div>
          <div className="flex flex-col items-start">
            <label
              htmlFor="name"
              className="block font-graphikRegular text-xs text-tw-main-text dark:text-tw-main-text-dark"
            >
              Name
            </label>
            <div className="mt-1.5 w-full">
              <input
                data-testid="member-edit-invite-name"
                value={name}
                onInput={(e) => setName((e.target as HTMLInputElement).value)}
                type="name"
                name="name"
                id="name"
                className="block w-full rounded-md border-gray-300 bg-tw-light-shade p-2 font-graphikRegular text-sm text-[#6F7174] focus:border-indigo-500 focus:ring-indigo-500 dark:bg-[#131517]"
                placeholder="John Doe"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="flex flex-col items-start">
            <label
              htmlFor="role"
              className="block font-graphikRegular text-tw-main-text dark:text-tw-main-text-dark"
              style={{ fontSize: '12px', lineHeight: '13px' }}
            >
              Role
            </label>
            <Select
              variant="white"
              value={role?.value}
              onChange={(val) =>
                setRole(
                  roles.find((r) => r.value === (val?.value as UserRole)) ||
                    null,
                )
              }
              options={roles}
              data-testid="role-select"
            />
          </div>
          <Message
            data-testid="member-edit-invite-error-message"
            children={error}
            type="error"
          />
        </div>
      </div>
      <div
        data-testid="member-edit-invite-actions"
        className="absolute bottom-3 right-3"
      >
        <SimpleButton
          variant="primary"
          disabled={!role || !email || !name || loading}
          className="relative flex items-center gap-2 px-12 text-sm"
          onClick={() =>
            member
              ? onEdit?.({
                  ...member,
                  name,
                  roles: !role ? [UserRole.OrgUser] : [role.value],
                })
              : onInvite({
                  email,
                  name,
                  roles: !role ? [UserRole.OrgUser] : [role.value],
                })
          }
          aria-label={
            member
              ? 'Edit role'
              : 'Send invite to ' + email + ' with role ' + role?.label
          }
          data-testid="member-edit-invite-button"
        >
          {loading && <Spinner className="absolute left-2 h-4 w-4" />}
          {!member ? 'Send Invite' : 'Edit role'}
        </SimpleButton>
      </div>
    </div>
  );
};
