import classNames from 'classnames';
import { useMemo } from 'react';
import { GetInventoryQuery, LicenseKind } from '../../../../../gql/graphql';
import { convertSize } from '../../../functions';
import { sizeByKind } from './utils';

export const LicensedCapacity = ({
  product,
}: {
  product: GetInventoryQuery['appliances'][0]['installedProducts'][0];
}) => {
  // “Unlimited” if there are licenses but none is capacity kind
  const unlimitedCapacity = useMemo(
    () =>
      product.licenses.length &&
      product.licenses.every(
        (license) => license.kind !== LicenseKind.Capacity,
      ),
    [product],
  );
  const { sizeConsumed, sizeLicensed } = useMemo(
    () => sizeByKind(product, LicenseKind.Capacity),
    [product],
  );

  const sizeLicensedFormated = useMemo(
    () => convertSize(sizeLicensed),
    [sizeLicensed],
  );
  const sizeConsumedFormated = useMemo(
    () => convertSize(sizeConsumed),
    [sizeConsumed],
  );

  return (
    <>
      <span>Capacity Licensed: </span>
      <span
        className={classNames(
          (!product?.licenses.length || (!sizeConsumed && !sizeLicensed)) &&
            'text-xs text-tw-label-tertiary opacity-50 dark:text-tw-label-tertiary-dark',
        )}
      >
        {product?.licenses.length
          ? unlimitedCapacity
            ? 'Unlimited'
            : !sizeConsumed && !sizeLicensed
            ? '-'
            : [sizeConsumedFormated || '-', sizeLicensedFormated || '-'].join(
                ' / ',
              )
          : '-'}
      </span>
    </>
  );
};
