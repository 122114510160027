import { useAppSelector } from '../../redux/hooks';

export const SystemHealth = () => {
  const theme = useAppSelector((state) => state.theme.value);
  return (
    <iframe
      src={`/grafana?&kiosk&theme=${theme}`}
      className="h-full w-full"
    ></iframe>
  );
};
