import classNames from 'classnames';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { ApplianceStatus } from '../../../../gql/graphql';

export const InfoCard = ({
  title,
  content,
  contentClassName = 'text-tw-label-primary dark:text-tw-label-primary-dark',
  className,
  ...rest
}: {
  title: ApplianceStatus | string | undefined;
  content: string | ApplianceStatus | undefined | null | React.ReactNode;
  contentClassName?: string;
  className?: string;
}) => (
  <div className={className} {...rest}>
    <div className="flex w-full flex-col gap-1">
      <h6
        className="font-graphikRegular text-xs text-tw-label-tertiary opacity-50 dark:text-tw-label-tertiary-dark"
        data-testid="info-title"
      >
        {title}
      </h6>
      <p
        className={twMerge(
          classNames(
            'items-center gap-2 overflow-hidden truncate font-graphikMedium text-sm',
            contentClassName,
          ),
        )}
        data-testid="info-value"
      >
        {content}
      </p>
    </div>
  </div>
);
