import { useFeature } from 'featurehub-react-sdk';
import { Suspense, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  Navigate,
  Outlet,
  RouteObject,
  useLocation,
  useParams,
} from 'react-router-dom';
import { DEFAULT_TITLE, TITLE_JOIN } from '.';
import { ChevronLeftIcon } from '../assets';
import Spinner from '../components/Elements/Spinner/Spinner';
import { SpinnerWithText } from '../components/Elements/Spinner/SpinnerWithText';
import { Head } from '../components/Head';
import { MainLayout, MainLayoutProps } from '../components/Layout';
import { SingleFormLayout } from '../components/Layout/SingleFormLayout';
import { DynamicFormSchema } from '../components/form/DynamicForm/DynamicFormSchema';
import MicroFrontend from '../microFrontend/MicroFrontend';
import { MicroFrontendContext } from '../microFrontend/context';
import { ExtendedPlugin } from '../microFrontend/types';
import { PageNotFound } from '../pages/404/PageNotFound';
import { UnderConstruction } from '../pages/dashboard';
import Team from '../pages/team/Team';
import { updateLandingPage } from '../redux/login/loginSlice';
import { mockedConfigSchema } from '../services/mockApolloServer/mocks';
import { RouteError } from '../utils/RouteError';
import { FeatureFlag } from '../utils/featureHub/constants';
import { toTitleCase } from '../utils/format';
import { useSession } from '../utils/hooks/useSession';
import { lazyImport } from '../utils/lazyImport';
import { RouteErrorWithMainLayout } from './RouteErrorWithMainLayout';

export const APPS_URL_PATH = 'apps';
export const APPS_BUILD_PATH = 'apps';

const { Dashboard } = lazyImport(
  () => import('../pages/dashboard'),
  'Dashboard',
);
const { SystemHealth } = lazyImport(
  () => import('../pages/dashboard'),
  'SystemHealth',
);
const { InventoryPage } = lazyImport(
  () => import('../pages/dashboard'),
  'InventoryPage',
);
const { Alerts } = lazyImport(() => import('../pages/dashboard'), 'Alerts');
const { Settings } = lazyImport(() => import('../pages/dashboard'), 'Settings');

const { UserPreferences } = lazyImport(
  () => import('../pages/user/UserPreferences'),
  'UserPreferences',
);
const { AlertWorkflowsPage } = lazyImport(
  () => import('../pages/alertWorkflows'),
  'AlertWorkflowsPage',
);
const { AlertWorkflow } = lazyImport(
  () => import('../pages/alertWorkflows'),
  'AlertWorkflow',
);
const { EditAlertWorkflow } = lazyImport(
  () => import('../pages/alertWorkflows'),
  'EditAlertWorkflow',
);

const { OrganizationsPage } = lazyImport(
  () => import('../pages/organizations/OrganizationsPage'),
  'OrganizationsPage',
);

const { Organization } = lazyImport(
  () => import('../pages/organizations/Organization'),
  'Organization',
);

const { EditOrganization } = lazyImport(
  () => import('../pages/organizations/EditOrganization'),
  'EditOrganization',
);

const { CancellationPage: SlackCancellationPage } = lazyImport(
  () => import('../pages/slack/CancellationPage'),
  'CancellationPage',
);

const { SuccessPage: SlackSuccessPage } = lazyImport(
  () => import('../pages/slack/SuccessPage'),
  'SuccessPage',
);

const App = (props?: Omit<MainLayoutProps, 'children'>) => {
  const location = useLocation();

  const titleByLocation = location.pathname.startsWith('/app/dashboard/')
    ? [DEFAULT_TITLE, ...location.pathname.split('/').slice(3)]
        .map((path) => toTitleCase(path.replaceAll('-', ' ')))
        .join(TITLE_JOIN)
    : `${DEFAULT_TITLE}${TITLE_JOIN}Dashboard`;

  useEffect(() => {
    if (titleByLocation) document.title = titleByLocation;
  }, [titleByLocation]);

  return (
    <MainLayout {...props}>
      <Suspense>
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

const SettingsLayout = () => {
  return (
    <MainLayout
      head={
        <Head
          logo={
            <h1
              className="flex cursor-pointer items-center font-graphikRegular text-xl dark:text-tw-main-text-dark"
              onClick={() => {
                window.history.back();
              }}
            >
              <ChevronLeftIcon className="mb-0.5 mr-2 h-3.5 w-3.5" />
              Settings
            </h1>
          }
        />
      }
      sidebar={false}
    >
      <Suspense>
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

function PluginMicroFrontEnd({
  state,
  plugins = [],
  loading = false,
}: {
  state?: { text: string };
  plugins?: ExtendedPlugin[];
  loading?: boolean;
}) {
  const { name } = useParams();
  const result = useMemo(() => {
    for (const plugin of plugins) {
      if (plugin.path === name) {
        return <MicroFrontend plugin={plugin} state={state} />;
      }
    }
    return null;
  }, [name, plugins, state]);

  if (result) {
    return result;
  }

  return loading ? (
    <div className="flex items-center justify-center p-5">
      <Spinner />
    </div>
  ) : (
    <PageNotFound />
  );
}

export const AuthLoader = () => {
  return (
    <SingleFormLayout>
      <div
        data-testid="auth-loader"
        className="flex items-center justify-center p-5"
      >
        <SpinnerWithText
          text="Checking your credentials, please wait..."
          spinnerProps={{
            'data-testid': 'auth-spinner',
          }}
        />
      </div>
    </SingleFormLayout>
  );
};

const AppWithAuthLoader = ({
  to = '/',
  children = <App />,
}: {
  to?: string;
  children?: JSX.Element;
}) => {
  const { auth, flowFulfilled } = useSession();
  const location = useLocation();
  const dispatch = useDispatch();
  !auth && flowFulfilled && dispatch(updateLandingPage(location));
  return auth ? (
    children
  ) : flowFulfilled ? (
    <Navigate to={to} />
  ) : (
    <AuthLoader />
  );
};

export const useProtectedRoutes = ({
  redirect = '/',
}: {
  redirect?: string;
} = {}): RouteObject[] => {
  // RELEASE FLAG: DYNAMIC_SETTINGS
  const dynamic_settings_feature = useFeature(FeatureFlag.DYNAMIC_SETTINGS);
  const dynamic_settings_demo_feature = useFeature(
    FeatureFlag.DYNAMIC_SETTINGS_DEMO,
  );
  const user_settings_feature = useFeature(FeatureFlag.USER_SETTINGS);
  const alert_workflow_feature = useFeature(FeatureFlag.ALERT_WORKFLOW);
  const { plugins, loading } = useContext(MicroFrontendContext);

  // This value will need to come from backend and most likely be stored in redux store
  // const [isRswConfigured, setIsRswConfigured] = useState(true);

  return [
    {
      path: 'slack',
      element: <App sidebar={null} head={<Head hideNotification />} />,
      ErrorBoundary: RouteErrorWithMainLayout,
      children: [
        {
          path: 'cancellation',
          element: <SlackCancellationPage />,
        },
        {
          path: 'success',
          element: <SlackSuccessPage />,
        },
      ],
    },
    {
      path: 'app',
      element: <AppWithAuthLoader to={redirect} />,
      ErrorBoundary: RouteErrorWithMainLayout,
      children: [
        {
          path: 'dashboard',
          element: <Dashboard />,
        },
        {
          path: 'dashboard/system-health',
          element: <SystemHealth />,
        },
        {
          path: 'dashboard/alerts',
          element: <Alerts />,
        },
        {
          path: 'dashboard/team',
          element: <Team />,
        },
        ...(alert_workflow_feature
          ? [
              {
                path: 'dashboard/alert-workflows',
                element: <AlertWorkflowsPage />,
              },
            ]
          : []),
        // { path: 'dashboard/inventory', element: <InventoryPage /> },
        {
          path: '',
          element: <Navigate to="dashboard" />,
        },
        { path: '*', element: <Navigate to="." />, ErrorBoundary: RouteError },
      ],
    },
    {
      path: APPS_URL_PATH,
      element: <AppWithAuthLoader to={redirect} />,
      ErrorBoundary: RouteErrorWithMainLayout,
      children: [
        {
          path: ':name',
          element: <PluginMicroFrontEnd plugins={plugins} loading={loading} />,
        },
        {
          path: ':name/*',
          element: <PluginMicroFrontEnd plugins={plugins} loading={loading} />,
        },
      ],
    },
    {
      path: 'app',
      element: (
        <AppWithAuthLoader to={redirect}>
          <SettingsLayout />
        </AppWithAuthLoader>
      ),
      ErrorBoundary: RouteErrorWithMainLayout,
      children: [
        {
          path: 'dashboard/settings',
          element: dynamic_settings_feature ? (
            <Settings />
          ) : (
            <UnderConstruction />
          ),
        },
        ...(dynamic_settings_demo_feature
          ? [
              {
                path: 'dashboard/settings-demo',
                element: (
                  <DynamicFormSchema
                    configSchema={mockedConfigSchema}
                    config={{
                      title: 'A new task',
                      done: false,
                      priority: 'Low',
                      subtasks: ['A new subtask'],
                      dueDate: '2021-01-01',
                      inputs: {
                        strings: {
                          readOnlyStringInput: 'Read only string input',
                        },
                      },
                    }}
                    onChange={console.log}
                  />
                ),
              },
            ]
          : []),
      ],
    },
    {
      path: APPS_URL_PATH,
      element: (
        <AppWithAuthLoader to={redirect}>
          <SettingsLayout />
        </AppWithAuthLoader>
      ),

      children: [
        ...(dynamic_settings_feature
          ? [
              {
                path: `:name/settings`,
                // Will get the app name from the url and pass it to the settings page
                element: <Settings />,
              },
            ]
          : []),
      ],
    },
    ...(user_settings_feature
      ? [
          {
            path: 'user',
            element: <AppWithAuthLoader to={redirect} />,
            children: [{ path: 'preferences', element: <UserPreferences /> }],
          },
        ]
      : []),
  ];
};

export const getAdminRoutes = (
  redirect = '/',
  inventoryFeature: boolean | undefined,
  alertWorkflowFeature: boolean | undefined,
): RouteObject[] => {
  return [
    {
      path: 'app',
      element: <AppWithAuthLoader to={redirect} />,
      ErrorBoundary: RouteErrorWithMainLayout,
      children: [
        ...(alertWorkflowFeature
          ? [
              {
                path: 'dashboard/alert-workflows/new-workflow',
                element: <AlertWorkflow />,
              },
              {
                path: 'dashboard/alert-workflows/:id',
                element: <EditAlertWorkflow />,
              },
            ]
          : []),
        ...(inventoryFeature
          ? [
              {
                path: 'dashboard/inventory',
                element: <InventoryPage />,
              },
            ]
          : []),
      ],
    },
  ];
};

export const getSuperAdminRoutes = (
  redirect = '/',
  inventoryFeature: boolean | undefined,
  alertWorkflowFeature: boolean | undefined,
): RouteObject[] => {
  return [
    {
      path: 'app',
      element: <AppWithAuthLoader to={redirect} />,
      ErrorBoundary: RouteErrorWithMainLayout,
      children: [
        {
          path: 'dashboard/organizations',
          element: <OrganizationsPage />,
        },
        {
          path: 'dashboard/organizations/new',
          element: <Organization />,
        },
        {
          path: 'dashboard/organizations/:id',
          element: <EditOrganization />,
        },
        ...(alertWorkflowFeature
          ? [
              {
                path: 'dashboard/alert-workflows/new-workflow',
                element: <AlertWorkflow />,
              },
              {
                path: 'dashboard/alert-workflows/:id',
                element: <EditAlertWorkflow />,
              },
            ]
          : []),
        ...(inventoryFeature
          ? [
              {
                path: 'dashboard/inventory',
                element: <InventoryPage />,
              },
            ]
          : []),
      ],
    },
  ];
};

export const useAdminRoutes = ({
  redirect = '/',
}: {
  redirect?: string;
} = {}): RouteObject[] => {
  const { isAdmin } = useSession();
  const inventoryFeature = useFeature(FeatureFlag.INVENTORY);
  const alertWorkflowFeature = useFeature(FeatureFlag.ALERT_WORKFLOW);

  return isAdmin
    ? getAdminRoutes(redirect, inventoryFeature, alertWorkflowFeature)
    : [];
};

export const useSuperAdminRoutes = ({
  redirect = '/',
}: {
  redirect?: string;
} = {}): RouteObject[] => {
  const { isSuperAdmin } = useSession();
  const inventoryFeature = useFeature(FeatureFlag.INVENTORY);
  const alertWorkflowFeature = useFeature(FeatureFlag.ALERT_WORKFLOW);

  return isSuperAdmin
    ? getSuperAdminRoutes(redirect, inventoryFeature, alertWorkflowFeature)
    : [];
};
