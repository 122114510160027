import React from 'react';

export const PencilIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M1.37273 7.58231L8.50078 0.45309C9.08671 -0.132801 10.0383 -0.132801 10.6242 0.45309L11.5477 1.37723C11.6203 1.45036 11.6836 1.52934 11.7398 1.61254C12.1242 2.19473 12.0609 2.98622 11.5477 3.4995L4.41796 10.6268C4.38749 10.6573 4.35703 10.6667 4.32421 10.7159C4.09921 10.9151 3.83203 11.0417 3.5414 11.1472L0.721167 11.9768C0.523823 12.0354 0.310307 11.9815 0.16476 11.8151C0.0192088 11.6909 -0.0352084 11.4776 0.0228697 11.2784L0.852417 8.45887C0.949917 8.1284 1.12874 7.82606 1.37273 7.58231ZM1.93171 8.77528L1.39312 10.6081L3.22499 10.069C3.3539 10.0245 3.51093 9.94247 3.62343 9.83231L8.97656 4.47684L7.50234 3.00262L2.1682 8.37684C2.15437 8.3909 2.14101 8.40497 2.12812 8.42137C2.03765 8.52215 1.97039 8.64403 1.93171 8.77528Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PencilIcon;
