import { gql } from '@apollo/client';

export const GET_APP_SETTINGS = gql`
  query GetAppSettings($id: ID!) {
    appSettings(id: $id) {
      id
      deployment {
        location {
          host
          port
        }
        instances
      }
      configSchema {
        id
        value
      }
      config {
        id
        value
      }
    }
  }
`;

export const UPDATE_SETTINGS_APP_CONFIG = gql`
  mutation UpdateSettingsAppConfig($id: ID!, $config: JSON!) {
    updateSettingsConfig(id: $id, input: $config) {
      id
      value
    }
  }
`;
