import { ButtonHTMLAttributes, DetailedHTMLProps, useState } from 'react';
import { SimpleButton, SimpleButtonProps } from '../../Buttons/SimpleButton';
import Spinner from '../../Elements/Spinner/Spinner';
import { BoundsId, Modal } from './Modal';

export interface ConfirmationModalTriggerProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: React.ReactNode;
  layoutArea?: BoundsId;

  modalTitle?: React.ReactNode;
  modalContent: React.ReactNode | ((close: () => void) => React.ReactNode);

  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClose?: () => void;
  onConfirm?: () => boolean | Promise<boolean>;
  disabled?: boolean;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  cancelButtonProps?: Partial<SimpleButtonProps>;
  confirmButtonProps?: Partial<SimpleButtonProps>;
}

export const ConfirmationModalTrigger = ({
  children,
  onClick,
  onClose,
  onConfirm,
  modalTitle,
  modalContent,
  disabled,
  confirmButtonLabel = 'Confirm',
  cancelButtonLabel = 'Cancel',
  layoutArea,
  cancelButtonProps,
  confirmButtonProps,
  ...rest
}: ConfirmationModalTriggerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  const onClickHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    onClick?.(e);
    setIsOpen(true);
  };

  const onCloseHandler = () => {
    onClose?.();
    setIsOpen(false);
  };

  const onConfirmHandler = async () => {
    setIsWaiting(true);
    const isOk = onConfirm ? await onConfirm() : true;
    setIsWaiting(false);
    isOk && setIsOpen(false);
  };

  return (
    <>
      <button
        onClick={onClickHandler}
        disabled={disabled}
        data-testid="modal-trigger-button"
        {...rest}
      >
        {children}
      </button>
      <Modal
        title={modalTitle}
        isOpen={isOpen}
        isCloseable={false}
        layoutArea={layoutArea}
        data-testid="confirmation-modal"
      >
        {typeof modalContent === 'function'
          ? modalContent(onCloseHandler)
          : modalContent}
        <div className="mt-4 flex items-center justify-end gap-2">
          {isWaiting && <Spinner className="h-5 w-5" />}
          <SimpleButton
            data-testid="cancel-modal-button"
            className="text-sm"
            variant="default-ghost"
            onClick={onCloseHandler}
            disabled={isWaiting}
            {...cancelButtonProps}
          >
            {cancelButtonLabel}
          </SimpleButton>
          <SimpleButton
            data-testid="confirm-modal-button"
            className="px-4 text-sm"
            variant="default"
            onClick={onConfirmHandler}
            disabled={isWaiting}
            {...confirmButtonProps}
          >
            {confirmButtonLabel}
          </SimpleButton>
        </div>
      </Modal>
    </>
  );
};
