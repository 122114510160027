import { useLocation } from 'react-router-dom';

import classNames from 'classnames';
import { useFeature } from 'featurehub-react-sdk';
import { useContext, useMemo } from 'react';
import SettingsIcon from '../../assets/icons/DBSizeNavIcons/SettingsIcon';
import { MicroFrontendContext } from '../../microFrontend/context';
import { ExtendedPlugin } from '../../microFrontend/types';
import { useAppSelector } from '../../redux/hooks';
import { APPS_URL_PATH } from '../../routes/protected';
import { FeatureFlag } from '../../utils/featureHub/constants';
import { dashBoardNav, navigationList } from '../../utils/navigationList';
import { Logout } from '../Elements/Logout/Logout';
import { SidebarCollapse } from '../Elements/SidebarCollapse';
import Spinner from '../Elements/Spinner/Spinner';
import { ExtendedUser, LoginOutModal, User, useLogOut } from '../Elements/User';
import { ProductSwitch } from '../ProductSwitch';
import { SideNav } from '../SideNavigation/SideNav';
import { SideNavGroupProps } from '../SideNavigation/interfaces';

const getSettingsSideNavGroup = (plugin: ExtendedPlugin): SideNavGroupProps => {
  return {
    gridView: 'last',
    items: [
      {
        title: 'Settings',
        icon: <SettingsIcon />,
        sideNavPath: `${plugin.path}/settings`,
      },
    ],
  };
};

const getSideNavItemsWithSettings = (plugin: ExtendedPlugin) => {
  const baseURL = `${APPS_URL_PATH}/${plugin.path}`;
  const sideNavGroups =
    plugin.getSideNavItems && plugin.getSideNavItems(baseURL);
  const hasSettings = sideNavGroups?.find((x) =>
    x.items.some((item) => item.sideNavPath === `/${baseURL}/settings`),
  );
  if (hasSettings) {
    return sideNavGroups;
  }

  return sideNavGroups?.concat(getSettingsSideNavGroup(plugin));
};

export const Sidebar = () => {
  const location = useLocation();
  const { logOut } = useLogOut();
  const sidebarCollapsed = useAppSelector(
    (state) => state.sidebarCollapse.collapsed,
  );

  const app_switch_feature = useFeature(FeatureFlag.PROD_SWITCH);

  const { plugins } = useContext(MicroFrontendContext);

  // dynamically load sidebar's for each app
  const sideNav = useMemo(() => {
    const matchedPlugin = plugins.find((plugin) =>
      location.pathname.includes(`/${APPS_URL_PATH}/${plugin.path}`),
    );
    return (
      <div
        key={`sideNav-${location.pathname}-${
          matchedPlugin ? matchedPlugin.path : 'appList'
        }`}
      >
        {matchedPlugin ? (
          matchedPlugin.getSideNavItems ? (
            <SideNav
              sideNavGroups={getSideNavItemsWithSettings(matchedPlugin)}
            />
          ) : matchedPlugin.error ? (
            <SideNav sideNavGroups={[getSettingsSideNavGroup(matchedPlugin)]} />
          ) : (
            <Spinner className="h-10 w-10 p-2" />
          )
        ) : (
          navigationList.find((x) => location.pathname.indexOf(x.to) > -1)
            ?.sideNav || dashBoardNav.sideNav
        )}
      </div>
    );
  }, [location.pathname, plugins]);

  //RELEASE FLAGS
  const userSettings = useFeature(FeatureFlag.USER_SETTINGS);

  return (
    <div
      data-testid="Sidebar"
      className={`absolute left-0 z-20 flex flex-col items-center justify-between border-r border-tw-hover-alternative bg-white transition-all dark:border-[#292B2F] dark:bg-[#1A1C1E] lg:relative ${
        sidebarCollapsed
          ? 'w-[80px]'
          : 'w-[208px] shadow-[7px_12px_15px_rgba(0,0,0,0.25)] lg:shadow-none'
      }`}
      style={{
        height: 'calc(100vh - 66px)',
      }}
    >
      <div>
        <div
          className={`${
            app_switch_feature
              ? 'border-b border-b-tw-hover-alternative dark:border-[#292B2F]'
              : ''
          }`}
          data-testid="SidebarUp"
        >
          {app_switch_feature ? <ProductSwitch /> : null}
        </div>

        <div className="overflow-hidden">
          <div>{sideNav}</div>
        </div>
      </div>

      <div data-testid="SidebarDown" className={classNames('w-full')}>
        <div className="flex flex-col p-2">
          {userSettings ? <ExtendedUser /> : <User />}
          <SidebarCollapse />
          <Logout onClick={logOut} />
        </div>
      </div>
      <LoginOutModal />
    </div>
  );
};
