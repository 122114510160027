import React, { useRef } from 'react';
import { Icon, IconName } from '../../../assets/icons/Icon';
import { SimpleButton } from '../../../components/Buttons/SimpleButton';
import { useSwipeToDismiss } from '../../../components/Elements/Alerts/useSwipeToDismiss';
import { GetUsersQuery, UserRole } from '../../../gql/graphql';

export const MemberWithoutActions = ({
  member,
  hideRole,
  children,
}: {
  member: GetUsersQuery['users'][0];
  hideRole?: boolean;
  children?: React.ReactNode;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div
      data-testid={`team-member`}
      data-test-email={member.email}
      aria-label={`Team member ${member.email}`}
      className={`flex w-full flex-col items-center ${
        member.roles ? 'text-[#6F7174]' : ''
      }`}
      ref={ref}
    >
      <div className="my-4 flex w-full justify-between">
        <div className="flex w-full items-center">
          <div className="mr-2 items-center font-graphikSemi">
            <div
              data-testid="team-member-avatar"
              className="flex h-[26px] w-[26px] items-center justify-center rounded-lg bg-tw-inactive-color text-white dark:bg-tw-light-shade-dark"
            >
              {member.name ? member.name[0] : 'G'}
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <h4
              data-testid="team-member-name"
              className="font-graphikMedium text-xs text-tw-main-text dark:text-tw-main-text-dark"
            >
              {member.name}
            </h4>
            <p
              data-testid="team-member-email"
              className="font-graphikRegular text-[10px] text-[#6F7174]"
            >
              {member.email}
            </p>
          </div>
        </div>
        {!hideRole && (
          <div className="flex w-[300px] flex-col gap-1">
            <h4 className="font-graphikMedium text-xs">
              {member.roles.includes(UserRole.OrgAdmin)
                ? 'Admin'
                : member.roles.includes(UserRole.OrgUser)
                ? 'User'
                : 'SuperAdmin'}
            </h4>
            <p className="whitespace-nowrap font-graphikRegular text-[10px] text-[#6F7174]">
              Full permissions
            </p>
          </div>
        )}
        {children && (
          <div
            data-testid="team-member-actions"
            className="flex items-center justify-end space-x-4 text-[#6F7174] "
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

const MemberBar = ({
  isAdmin,
  isSuperAdmin,
  member,
  onEdit,
  onDelete,
  deleting,
}: {
  member: GetUsersQuery['users'][0];
  onEdit?: (member: GetUsersQuery['users'][0]) => void;
  onDelete?: (member: GetUsersQuery['users'][0]) => void;
  deleting?: boolean;
  isAdmin?: boolean;
  isSuperAdmin?: boolean;
  currentUserID?: string;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const handleDelete = () => {
    onDelete?.(member);
  };
  (isAdmin || isSuperAdmin) && useSwipeToDismiss(ref, handleDelete, false, 50);

  return (
    <MemberWithoutActions member={member}>
      {(isAdmin || isSuperAdmin) && (
        <SimpleButton
          onClick={() => {
            onEdit?.(member);
          }}
          variant="default-ghost"
          aria-label="Edit item"
          data-testid="team-member-edit-button"
        >
          <Icon iconName={IconName.PencilIcon} className="h-3 w-3" />
        </SimpleButton>
      )}

      {(isAdmin || isSuperAdmin) && (
        <SimpleButton
          variant="default-ghost"
          aria-label="Delete item"
          disabled={deleting}
          onClick={() => onDelete?.(member)}
          data-testid="team-member-delete-button"
        >
          <Icon
            iconName={deleting ? IconName.SpinnerIcon : IconName.TrashIcon}
            className="h-3 w-3"
          />
        </SimpleButton>
      )}
    </MemberWithoutActions>
  );
};

export default MemberBar;
