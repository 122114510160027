import { useQuery } from '@apollo/client';
import { equals } from 'ramda';
import { useEffect, useMemo, useRef, useState } from 'react';
import { GET_APPLIANCES } from '../../../api/appliance/queries';
import {
  GetAppliancesQuery,
  GetAppliancesQueryVariables,
} from '../../../gql/graphql';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateProductsFilterAppliance } from '../../../redux/products/filterSlice';
import { useSession } from '../../../utils/hooks/useSession';
import Spinner from '../Spinner/Spinner';
import FilterButton from './FilterButton';
import FilterButtonList from './FilterButtonList';

export type IProduct =
  GetAppliancesQuery['appliances'][0]['installedProducts'][0]['product'];
export type IAppliance = GetAppliancesQuery['appliances'][0];

const SingleButton = ({ appliance }: { appliance: IAppliance }) => {
  return (
    <div className="relative mb-2 flex min-h-[75px]">
      <div
        data-testid="appliance-filter-options"
        className="flex h-full w-full flex-row gap-2"
      >
        <FilterButton
          data-testid="appliance-filter-single"
          appliance={appliance}
          selected={[]}
        />
      </div>
    </div>
  );
};

const MultipleButtons = ({
  APPLIANCES,
  selected,
  updateSelected,
}: {
  APPLIANCES: Array<IAppliance>;
  selected: Array<string> | null;
  updateSelected: (product: string) => void;
}) => {
  return (
    <div className="relative mb-2 flex min-h-[75px]">
      <div
        data-testid="appliance-filter-options"
        className="flex h-full w-full flex-row gap-2"
      >
        {APPLIANCES.map((appliance) => (
          <FilterButton
            key={appliance.id}
            appliance={appliance}
            selected={selected}
            updateSelected={updateSelected}
          />
        ))}
      </div>
    </div>
  );
};

const FilterButtonHolder = () => {
  const { skip } = useSession();
  const { loading, error, data } = useQuery<
    GetAppliancesQuery,
    GetAppliancesQueryVariables
  >(GET_APPLIANCES, {
    skip,
  });
  const dispatch = useAppDispatch();
  const myRef = useRef<HTMLDivElement | null>(null);
  const [overMaxAppliances, setOverMaxAppliances] = useState(false);
  const selected = useAppSelector((state) => state.productFilter.appliances);
  const appliances = data?.appliances || [];
  const availableApplianceIds = useMemo(
    () => appliances.map((app) => app.applianceId),
    [appliances],
  );

  const updateSelectedAppliance = (app: string | null) => {
    if (app === null) {
      dispatch(updateProductsFilterAppliance(availableApplianceIds));
    } else if (selected?.length) {
      if (selected.includes(app)) {
        const updated = selected.filter((appliance) => app !== appliance);
        dispatch(updateProductsFilterAppliance(updated));
      } else {
        const newValue = [...selected, app];
        const newValueOrEmpty = equals(
          [...newValue].sort(),
          [...availableApplianceIds].sort(),
        )
          ? null
          : newValue;
        dispatch(updateProductsFilterAppliance(newValueOrEmpty));
      }
    } else {
      dispatch(
        updateProductsFilterAppliance(
          availableApplianceIds?.filter((appliance) => app !== appliance) || [],
        ),
      );
    }
  };

  useEffect(() => {
    if (data?.appliances && selected?.length) {
      // Filter the current selected ids to only include existing
      const selectedFiltered = selected?.filter((id) =>
        availableApplianceIds?.includes(id),
      );
      selectedFiltered &&
        dispatch(updateProductsFilterAppliance(selectedFiltered));
    }
  }, [data]);

  useEffect(() => {
    if (myRef.current) {
      if (myRef.current?.offsetWidth < 1130 && appliances.length > 2) {
        setOverMaxAppliances(true);
      }
    }
  }, [myRef]);

  if (loading)
    return (
      <div
        data-testid="appliance-filter-loading"
        className="flex w-full justify-center py-2"
      >
        <Spinner />
      </div>
    );
  if (error)
    return (
      <div data-testid="appliance-filter-error">Error! {error.message}</div>
    );

  return (
    <div data-testid="appliance-filter" ref={myRef}>
      {appliances.length === 1 ? (
        <SingleButton appliance={appliances[0]} />
      ) : appliances.length > 1 &&
        appliances.length < 4 &&
        !overMaxAppliances ? (
        <MultipleButtons
          APPLIANCES={appliances}
          selected={selected}
          updateSelected={updateSelectedAppliance}
        />
      ) : (
        <FilterButtonList
          selected={selected}
          appliances={appliances}
          updateSelected={updateSelectedAppliance}
        />
      )}
    </div>
  );
};

export default FilterButtonHolder;
