import classNames from 'classnames';
import React from 'react';
import {
  AlertsIcon,
  ArchiveBoxIcon,
  ArrowLeftIcon,
  BellIcon,
  Calendar,
  CapacityReportIcon,
  CheckCircleIcon,
  CheckSolidCircleIcon,
  CheckmarkBoldIcon,
  CheckmarkIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClearFilter,
  CloseIcon,
  ContentAnalysisIcon,
  CrossIcon,
  DataArchiveIcon,
  DataProtectionIcon,
  DiscDriveIcon,
  ErrorCircleIcon,
  ErrorIcon,
  GlobeIcon,
  HalfRectangleIcon,
  HelpIcon,
  InfoCircleIcon,
  InventoryIcon,
  LocationIcon,
  LogoutIcon,
  NotificationBellDotIcon,
  NotificationBellIcon,
  OverviewIcon,
  PencilIcon,
  PerformanceIcon,
  QuestionMark,
  SearchIcon,
  SecurityAndAuditIcon,
  SettingsIcon,
  SolidCloseIcon,
  SpinnerIcon,
  SupernaLogo,
  SupernaLogoNoText,
  SystemHealthIcon,
  TeamIcon,
  TermIcon,
  ThumbsUpIcon,
  ThumbsUpIconOutline,
  ToggleMoonIcon,
  ToggleSunIcon,
  TrashIcon,
  WarningCircleIcon,
  WarningCircleSolidIcon,
} from './';

import { ProductIconOnlyLogo } from '../../components/Elements/Icons/ProductIcons/ProductIcons';
import { DeleteIcon } from './DeleteIcon';

export enum IconName {
  ArchiveBoxIcon = 'ArchiveBoxIcon',
  Calendar = 'Calendar',
  CheckCircleIcon = 'CheckCircleIcon',
  CheckSolidCircleIcon = 'CheckSolidCircleIcon',
  ChevronDownIcon = 'ChevronDownIcon',
  ChevronLeftIcon = 'ChevronLeftIcon',
  ChevronRightIcon = 'ChevronRightIcon',
  CloseIcon = 'CloseIcon',
  CrossIcon = 'CrossIcon',
  DeleteIcon = 'DeleteIcon',
  ErrorCircleIcon = 'ErrorCircleIcon',
  ErrorIcon = 'ErrorIcon',
  HalfRectangleIcon = 'HalfRectangleIcon',
  HelpIcon = 'HelpIcon',
  InfoCircleIcon = 'InfoCircleIcon',
  LogoutIcon = 'LogoutIcon',
  NotificationBellDotIcon = 'NotificationBellDotIcon',
  NotificationBellIcon = 'NotificationBellIcon',
  PencilIcon = 'PencilIcon',
  QuestionMark = 'QuestionMark',
  SearchIcon = 'SearchIcon',
  SolidCloseIcon = 'SolidCloseIcon',
  TermIcon = 'TermIcon',
  SpinnerIcon = 'SpinnerIcon',
  SupernaLogo = 'SupernaLogo',
  ThumbsUpIcon = 'ThumbsUpIcon',
  ToggleMoonIcon = 'ToggleMoonIcon',
  ToggleSunIcon = 'ToggleSunIcon',
  TrashIcon = 'TrashIcon',
  WarningCircleIcon = 'WarningCircleIcon',
  WarningCircleSolidIcon = 'WarningCircleSolidIcon',
  AlertsIcon = 'AlertsIcon',
  CapacityReportIcon = 'CapacityReportIcon',
  ContentAnalysisIcon = 'ContentAnalysisIcon',
  DataArchiveIcon = 'DataArchiveIcon',
  DataProtectionIcon = 'DataProtectionIcon',
  GlobeIcon = 'GlobeIcon',
  InventoryIcon = 'InventoryIcon',
  OverviewIcon = 'OverviewIcon',
  PerformanceIcon = 'PerformanceIcon',
  SecurityAndAuditIcon = 'SecurityAndAuditIcon',
  SettingsIcon = 'SettingsIcon',
  SystemHealthIcon = 'SystemHealthIcon',
  TeamIcon = 'TeamIcon',
  AirGap = 'AirGap',
  EasyAuditor = 'EasyAuditor',
  Eyeglass = 'Eyeglass',
  GoldenCopy = 'GoldenCopy',
  RansomwareDefender = 'RansomwareDefender',
  Search = 'Search',
  SmartAchiver = 'SmartAchiver',
  AnyCopy = 'AnyCopy',
  CusterStorageMonitor = 'CusterStorageMonitor',
  PerformanceAuditor = 'PerformanceAuditor',
  ThumbsUpOutlineIcon = 'ThumbsUpOutlineIcon',
  ArrowLeftIcon = 'ArrowLeftIcon',
  CheckmarkIcon = 'CheckmarkIcon',
  ClearFilter = 'ClearFilter',
  CheckmarkBoldIcon = 'CheckmarkBoldIcon',
  BellIcon = 'BellIcon',
  DiscDriveIcon = 'DiscDriveIcon',
  SupernaLogoNoText = 'SupernaLogoNoText',
  LocationIcon = 'LocationIcon',
}

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  iconName: IconName;
}

export const IconComponent = ({
  iconName,
}: IconProps): ((props: React.SVGProps<SVGSVGElement>) => JSX.Element) => {
  switch (iconName) {
    case IconName.ArchiveBoxIcon:
      return ArchiveBoxIcon;
    case IconName.ChevronDownIcon:
      return ChevronDownIcon;
    case IconName.ChevronLeftIcon:
      return ChevronLeftIcon;
    case IconName.ChevronRightIcon:
      return ChevronRightIcon;
    case IconName.CrossIcon:
      return CrossIcon;
    case IconName.DeleteIcon:
      return DeleteIcon;
    case IconName.ErrorIcon:
      return ErrorIcon;
    case IconName.SearchIcon:
      return SearchIcon;
    case IconName.AlertsIcon:
      return AlertsIcon;
    case IconName.CapacityReportIcon:
      return CapacityReportIcon;
    case IconName.ContentAnalysisIcon:
      return ContentAnalysisIcon;
    case IconName.DataArchiveIcon:
      return DataArchiveIcon;
    case IconName.DataProtectionIcon:
      return DataProtectionIcon;
    case IconName.InventoryIcon:
      return InventoryIcon;
    case IconName.GlobeIcon:
      return GlobeIcon;
    case IconName.OverviewIcon:
      return OverviewIcon;
    case IconName.PerformanceIcon:
      return PerformanceIcon;
    case IconName.SecurityAndAuditIcon:
      return SecurityAndAuditIcon;
    case IconName.SettingsIcon:
      return SettingsIcon;
    case IconName.SystemHealthIcon:
      return SystemHealthIcon;
    case IconName.TeamIcon:
      return TeamIcon;
    case IconName.TrashIcon:
      return TrashIcon;
    case IconName.PencilIcon:
      return PencilIcon;
    case IconName.CheckSolidCircleIcon:
      return CheckSolidCircleIcon;
    case IconName.QuestionMark:
      return QuestionMark;
    case IconName.Calendar:
      return Calendar;
    case IconName.CheckCircleIcon:
      return CheckCircleIcon;
    case IconName.ErrorCircleIcon:
      return ErrorCircleIcon;
    case IconName.LocationIcon:
      return LocationIcon;
    case IconName.WarningCircleIcon:
      return WarningCircleIcon;
    case IconName.WarningCircleSolidIcon:
      return WarningCircleSolidIcon;
    case IconName.HelpIcon:
      return HelpIcon;
    case IconName.LogoutIcon:
      return LogoutIcon;
    case IconName.ThumbsUpIcon:
      return ThumbsUpIcon;
    case IconName.NotificationBellIcon:
      return NotificationBellIcon;
    case IconName.NotificationBellDotIcon:
      return NotificationBellDotIcon;
    case IconName.ToggleMoonIcon:
      return ToggleMoonIcon;
    case IconName.ToggleSunIcon:
      return ToggleSunIcon;
    case IconName.CloseIcon:
      return CloseIcon;
    case IconName.SolidCloseIcon:
      return SolidCloseIcon;
    case IconName.TermIcon:
      return TermIcon;
    case IconName.SpinnerIcon:
      return SpinnerIcon;
    case IconName.HalfRectangleIcon:
      return HalfRectangleIcon;
    case IconName.SupernaLogo:
      return SupernaLogo;
    case IconName.ThumbsUpOutlineIcon:
      return ThumbsUpIconOutline;
    case IconName.DiscDriveIcon:
      return DiscDriveIcon;
    case IconName.SupernaLogoNoText:
      return SupernaLogoNoText;
    case IconName.AirGap:
      return (props: React.SVGProps<SVGSVGElement>) => (
        <ProductIconOnlyLogo productKind="AG" {...props} />
      );
    case IconName.EasyAuditor:
      return (props: React.SVGProps<SVGSVGElement>) => (
        <ProductIconOnlyLogo productKind="EA" {...props} />
      );
    case IconName.Eyeglass:
      return (props: React.SVGProps<SVGSVGElement>) => (
        <ProductIconOnlyLogo productKind="EYE_GLASS" {...props} />
      );
    case IconName.GoldenCopy:
      return (props: React.SVGProps<SVGSVGElement>) => (
        <ProductIconOnlyLogo productKind="GC" {...props} />
      );
    case IconName.RansomwareDefender:
      return (props: React.SVGProps<SVGSVGElement>) => (
        <ProductIconOnlyLogo productKind="RWD" {...props} />
      );
    case IconName.Search:
      return (props: React.SVGProps<SVGSVGElement>) => (
        <ProductIconOnlyLogo productKind="SEARCH" {...props} />
      );
    case IconName.SmartAchiver:
      return (props: React.SVGProps<SVGSVGElement>) => (
        <ProductIconOnlyLogo productKind="SMART_ARCHIVER" {...props} />
      );
    case IconName.AnyCopy:
      return (props: React.SVGProps<SVGSVGElement>) => (
        <ProductIconOnlyLogo productKind="ANY_COPY" {...props} />
      );
    case IconName.CusterStorageMonitor:
      return (props: React.SVGProps<SVGSVGElement>) => (
        <ProductIconOnlyLogo productKind="CSM" {...props} />
      );
    case IconName.PerformanceAuditor:
      return (props: React.SVGProps<SVGSVGElement>) => (
        <ProductIconOnlyLogo productKind="PA" {...props} />
      );

    case IconName.ArrowLeftIcon:
      return ArrowLeftIcon;
    case IconName.CheckmarkIcon:
      return CheckmarkIcon;
    case IconName.ClearFilter:
      return ClearFilter;
    case IconName.CheckmarkBoldIcon:
      return CheckmarkBoldIcon;
    case IconName.BellIcon:
      return BellIcon;
    case IconName.InfoCircleIcon:
    default:
      return InfoCircleIcon;
  }
};

export const Icon = ({ iconName, ...props }: IconProps) => {
  const Component = IconComponent({ iconName });
  return <Component {...props} />;
};

export const IconGrid = ({ className = '' }: { className?: string }) => {
  return (
    <div className="flex flex-wrap justify-center gap-2">
      {Object.values(IconName).map((iconName) => (
        <div
          key={iconName}
          className="rounded-lg border border-gray-300 bg-white p-1 text-tw-main-text hover:bg-gray-200 dark:bg-black dark:text-tw-main-text-dark dark:hover:bg-gray-800"
        >
          <Icon
            className={classNames('h-20 w-20 p-1', className)}
            iconName={iconName}
          />
          <span className="text-xs">{iconName}</span>
        </div>
      ))}
    </div>
  );
};
