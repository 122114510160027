import { LoginFlow } from '@ory/kratos-client';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SimpleButton } from '../../../components/Buttons/SimpleButton';
import { Logout } from '../../../components/Elements/Logout/Logout';
import Spinner from '../../../components/Elements/Spinner/Spinner';
import { User, useLogOut } from '../../../components/Elements/User';
import { Message } from '../../../components/common/Message';
import { InputField } from '../../../components/form/InputField';
import { initializeLoginFlow, submitLoginWithFlow } from '../../../ory/login';
import { useAppDispatch } from '../../../redux/hooks';
import { useSession } from '../../../utils/hooks/useSession';

type Inputs = {
  device: string;
  password: string;
};

export const LoginForm = () => {
  const { t } = useTranslation(['userAccount']);
  const { auth, flowFulfilled } = useSession();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    watch,
    setFocus,
    formState: { errors },
  } = useForm<Inputs>();

  const session = useSession();
  const { logOut } = useLogOut();

  const [loginFlow, setLoginFlow] = useState<LoginFlow>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setError('');
    setLoading(true);

    const newLoginFlow =
      loginFlow ||
      (await initializeLoginFlow().then((r) => {
        setLoginFlow(r);
        return r;
      }));

    const response = await submitLoginWithFlow(
      newLoginFlow,
      data.device,
      data.password,
      dispatch,
    );
    setLoading(false);
    if (!response.success) {
      setError(
        response.messages[0]
          ? t(
              `ory.messages.${response.messages[0].id}`,
              response.messages[0].text,
              { ns: 'ory' },
            )
          : t('login.error'),
      );
    }
  };

  useEffect(() => {
    flowFulfilled && setFocus('device');
    // Should set focus on device input when flow is fulfilled to work on first page load
  }, [flowFulfilled]);

  return (
    <div
      data-testid="LoginForm"
      className={
        'rounded-2xl bg-white p-6 font-graphikRegular text-tw-main-text dark:bg-tw-light-shade-dark dark:text-tw-dark-shade'
      }
    >
      <div className="flex flex-col items-center">
        <h1 className="my-2 font-graphikBold text-[1.75rem] leading-[1.938rem]">
          {t('login.title')}
        </h1>
        <p
          data-testid="LoginForm-registrationLink"
          className="mb-5 text-center font-graphikRegular text-sm"
        >
          <Trans
            ns="userAccount"
            i18nKey="registration.link"
            defaults="Have a registration code? <0>Register</0>"
            components={[
              <Link
                to="/registration"
                className="font-graphikRegular text-tw-main-color"
              />,
            ]}
          />
        </p>
        {session.recovery ? (
          <div className="flex flex-col items-center">
            <p>
              You have requested a <Link to={'/newPassword'}>new password</Link>
              .
            </p>
            <p>
              Sign out or{' '}
              <Link className="text-tw-main-color" to={'/newPassword'}>
                change your password
              </Link>
            </p>
            <div className="flex flex-row items-center gap-4 p-4">
              <User className="w-min" />
              <Logout onClick={logOut} />
            </div>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-[21rem]"
            onChange={() => error && setError('')}
          >
            <InputField
              data-testid="LoginForm-deviceInput"
              disabled={!flowFulfilled}
              label={t('login.fields.identifier.label')}
              register={register('device', {
                required: {
                  message: t('login.fields.identifier.validation.required'),
                  value: true,
                },
              })}
              error={errors.device}
              watchValue={watch('device')}
              otherError={error}
              autoFocus
            />
            <InputField
              data-testid="LoginForm-passwordInput"
              disabled={!flowFulfilled}
              label={t('registration.fields.password.label')}
              register={register('password', {
                required: {
                  value: true,
                  message: t('login.fields.password.validation.required'),
                },
                minLength: {
                  value: 8,
                  message: t('login.fields.password.validation.minLength'),
                },
              })}
              error={errors.password}
              watchValue={watch('password')}
              type="password"
              otherError={error}
              autoComplete="new-password"
            />

            <SimpleButton
              data-testid="LoginForm-submitButton"
              disabled={auth || loading}
              onClick={handleSubmit(onSubmit)}
              className={classNames(
                'mx-0 w-full rounded-md bg-tw-main-fill-color px-3 py-3.5 text-center font-graphikSemi text-[1rem] leading-[1.1rem] text-white hover:bg-tw-main-fill-color-hover ',
                'disabled:bg-tw-main-fill-color disabled:text-white disabled:dark:bg-tw-main-fill-color disabled:dark:text-white',
              )}
            >
              {t('login.loginButton')}
            </SimpleButton>
            {error && (
              <Message
                data-testid="login-error-message"
                type="error"
                className="hidden"
              >
                {error}
              </Message>
            )}
            <p
              data-testid="LoginForm-passwordRecoveryLink"
              className="mb-3 mt-6 text-center font-graphikRegular text-sm"
            >
              <Trans
                ns="userAccount"
                i18nKey="recovery.link"
                defaults="Forgot your password? <0>Reset password</0>"
                components={[
                  <Link
                    to="/passwordRecovery"
                    className="font-graphikRegular text-tw-main-color"
                    style={{ fontSize: '14px', lineHeight: '15px' }}
                  />,
                ]}
              />
            </p>
            {loading && (
              <div className="mt-2 flex flex-col items-center gap-2">
                <Spinner className="h-6 w-6 animate-scaleIn" />
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
};
