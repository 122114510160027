import { useCallback } from 'react';
import { FilterOptionProps } from './FilterSelect';
import { InlineFilter } from './InlineFilter';

export type FilterSelectFilters = { [key: string]: FilterOptionProps };
export type FilterType = 'multi' | 'single';
export type FilterSelectValue<T = FilterSelectFilters> = {
  [key in keyof T]?: T[key] extends { type: FilterType }
    ? T[key]['type'] extends 'single'
      ? string
      : string[]
    : never;
};
export type FilterSelectClear<T = FilterSelectFilters> = {
  [key in keyof T]?: true;
};
export interface FilterSelectProps<
  T = FilterSelectFilters,
  U = FilterSelectValue<T>,
  K = FilterSelectClear<T>,
> {
  value?: U;
  onClear?: (value: K) => void;
  onChange?: (value: U) => void;
  filters: T;
}

export const FilterSelectInline = <
  T extends { [key: string]: FilterOptionProps },
>({
  filters,
  value,
  onClear,
  onChange,
}: FilterSelectProps<T>) => {
  const handleChange = useCallback(
    (value: FilterSelectValue<T>) => onChange?.(value),
    [onChange],
  );

  return (
    <div
      data-testid="inline-filters"
      className="flex h-min w-full flex-row flex-wrap gap-2"
    >
      {Object.entries(filters).map(([key, filter]) => {
        return (
          <InlineFilter
            key={key}
            label={filter.label || key}
            value={value?.[key] || ''}
            filterValue={filter}
            onClear={() => onClear?.({ [key]: true } as FilterSelectClear<T>)}
            onChange={(value) =>
              handleChange({ [key]: value } as FilterSelectValue<T>)
            }
          />
        );
      })}
    </div>
  );
};
