import { faEarthAmerica } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { groupBy, map, pipe, prop } from 'ramda';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import {
  KeepScale,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import { twMerge } from 'tailwind-merge';
import { Icon, IconName } from '../../../assets/icons/Icon';
import { DEFAULT_TOOLTIP_OPTIONS } from '../../../utils/tooltilp';
import { SimpleButton } from '../../Buttons/SimpleButton';
import { Switch } from '../../form/fields/Switch/Switch';
import { LocationPoint } from './functions';
import { points } from './values';

interface IProps {
  listOfLocations: LocationPoint[] | undefined;
  hideAppliances?: boolean;
  hideDevices?: boolean;
}

const StatusIcon = ({ status, ...rest }: { status: 'online' | 'offline' }) => {
  return (
    <span
      className={classNames(
        'ml-auto h-3 w-3 rounded-full border-2',
        status === 'online'
          ? 'bg-tw-main-color dark:bg-tw-main-color-dark'
          : 'bg-tw-danger-strong dark:bg-tw-danger-strong-dark',
      )}
      {...rest}
    />
  );
};

let messageResponsePending = true;

const tooltipRender =
  (locations: LocationPoint[] | undefined) =>
  ({
    content,
  }: {
    content: string | null;
    activeAnchor: HTMLElement | null;
  }) => {
    const locs = useMemo(
      () =>
        locations?.filter(
          ({ closestPoint }) =>
            `${closestPoint?.cx}-${closestPoint?.cy}` === content,
        ),
      [locations, content],
    );

    const [_, setSearchParams] = useSearchParams();

    if (!locs?.length) return null;
    const locationPointsWithId = pipe(
      map((loc: LocationPoint) => ({
        ...loc,
        locId: [loc.location?.city, loc.location?.country]
          .filter(Boolean)
          .join(', '),
      })),
      groupBy(prop('locId')),
    )(locs);

    return (
      <div
        data-testid="location-tooltip-content"
        className="flex max-w-sm flex-col items-start justify-start divide-y divide-tw-border font-graphikMedium font-medium dark:divide-white/5"
      >
        {Object.entries(locationPointsWithId).map(([key, locationPoints]) => (
          <div
            data-testid="location-group"
            data-test-value={key}
            className="flex w-full flex-col py-2"
            key={key}
          >
            <div
              data-testid="locations"
              className="flex w-full flex-col text-left "
            >
              {locationPoints.map(({ text, id, status, locId, type }) => (
                <div
                  data-testid="location"
                  data-test-value={id}
                  key={id}
                  className="flex w-full cursor-pointer flex-row items-center gap-4 truncate hover:underline"
                  onClick={() =>
                    setSearchParams({
                      [type]: id,
                    })
                  }
                >
                  <span
                    data-testid="location-name"
                    className="max-w-xs truncate"
                  >
                    {text}
                  </span>
                  <StatusIcon
                    data-testid="location-status"
                    data-test-value={status}
                    status={status}
                  />
                </div>
              ))}
            </div>
            <span
              data-testid="location-description"
              className="text-tw-main-text/50 dark:text-tw-main-text-dark/50"
            >
              {key}
            </span>
          </div>
        ))}
      </div>
    );
  };

const Map = ({ listOfLocations, hideAppliances, hideDevices }: IProps) => {
  const hasLocations = useMemo(
    () => !!listOfLocations?.length,
    [listOfLocations],
  );

  const filteredLocations = useMemo(
    () =>
      listOfLocations?.filter(
        (location) =>
          (!hideAppliances || location.type !== 'appliance') &&
          (!hideDevices || location.type !== 'device'),
      ),
    [listOfLocations, hideDevices, hideAppliances],
  );

  const TooltipRender = useMemo(
    () => tooltipRender(filteredLocations),
    [filteredLocations],
  );

  const closestPoints = useMemo(
    () =>
      pipe(
        map((loc: LocationPoint) => ({
          ...loc,
          key: [loc.closestPoint?.cx, loc.closestPoint?.cy]
            .filter(Boolean)
            .join('-'),
        })),
        groupBy(prop('key')),
      )(filteredLocations || []),
    [filteredLocations],
  );

  const [viewMap, setViewMap] = useState(true);
  const [showFirstTimeModal, setShowFirstTimeModal] = useState<boolean | null>(
    null,
  );

  const [tooltipOpen, setTooltipOpen] = useState<null | string | 'hover'>(null);

  return (
    <div
      className="relative flex flex-col gap-4"
      data-testid="map-component"
      onClick={() => setTooltipOpen(null)}
    >
      <div className="flex items-center gap-1.5">
        <Switch checked={viewMap} onChange={setViewMap}>
          <h5 className="flex items-center gap-4 font-graphikMedium text-tw-label-primary dark:text-tw-label-primary-dark">
            <FontAwesomeIcon icon={faEarthAmerica} className="h-5 w-5" />
            Location Monitoring
          </h5>
        </Switch>
      </div>
      <Tooltip
        offset={15}
        id="map-point-tooltip"
        {...DEFAULT_TOOLTIP_OPTIONS}
        clickable
        isOpen={Boolean(tooltipOpen)}
        className={twMerge(
          classNames(DEFAULT_TOOLTIP_OPTIONS.className),
          '!bg-tw-surface-strong !text-tw-main-text dark:!bg-tw-surface-strong-dark dark:!text-tw-main-text-dark',
        )}
        render={TooltipRender}
      />
      {viewMap && (
        <TransformWrapper
          minScale={0.8}
          maxScale={1.5}
          centerOnInit
          initialScale={1}
        >
          <TransformComponent
            contentClass="!h-[450px] !w-[850px]"
            wrapperClass="relative flex dark:ring-2 dark:ring-[#131517] !h-[445px] !w-full overflow-hidden rounded-lg border-2 border-tw-surface-strong dark:border-tw-surface-strong-dark bg-tw-surface-strong dark:border-0 dark:bg-tw-surface-strong-dark"
          >
            <svg
              className="h-[450px] w-[850px] overflow-visible fill-[#2961eb66]"
              id="MapChartHolder"
            >
              {points.map((point) => (
                <circle
                  key={`${point.cx}-${point.cy}`}
                  cx={point.cx}
                  cy={point.cy}
                  r="1.9"
                />
              ))}
            </svg>
            {Object.entries(closestPoints).map(([key, loc]) => {
              if (loc) {
                const { closestPoint, id } = loc[0];
                return (
                  <KeepScale
                    key={id}
                    style={{
                      position: 'absolute',
                      zIndex: 2,
                      left: `${closestPoint?.cx}px`,
                      top: `${closestPoint?.cy}px`,
                      width: 0,
                      height: 0,
                    }}
                  >
                    <svg
                      className="group h-1 w-1 overflow-visible"
                      data-tooltip-id={'map-point-tooltip'}
                      data-tooltip-content={key}
                      onMouseEnter={() =>
                        setTooltipOpen((prev) =>
                          !prev || prev === 'hover' ? 'hover' : key,
                        )
                      }
                      onMouseLeave={() =>
                        tooltipOpen === 'hover' && setTooltipOpen(null)
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        setTooltipOpen((prev) =>
                          prev !== 'hover' ? null : key,
                        );
                      }}
                    >
                      <g>
                        <circle
                          r="7"
                          className={classNames(
                            'group-hover:opacity-90',
                            tooltipOpen === key ? 'opacity-100' : 'opacity-40',
                          )}
                          fill="white"
                        />
                        <circle
                          data-testid="location-element"
                          data-test-value={loc.map(({ id }) => id).join('-')}
                          className={classNames(
                            loc.some(({ status }) => status === 'offline')
                              ? 'fill-tw-danger-color dark:fill-tw-danger-color-dark'
                              : 'fill-tw-main-color dark:fill-tw-main-color-dark',
                          )}
                          stroke="white"
                          strokeWidth="1"
                          r="5"
                        />
                      </g>
                    </svg>
                  </KeepScale>
                );
              }
              return null;
            })}
          </TransformComponent>
          {!hasLocations &&
            (messageResponsePending || showFirstTimeModal === true) && (
              <div className="absolute bottom-4 right-4 flex w-72 flex-col items-center justify-center gap-2 rounded-lg border border-tw-border bg-tw-background p-4 text-center font-graphikRegular dark:border-tw-border-dark dark:bg-tw-background-dark">
                <Icon iconName={IconName.GlobeIcon} className="h-8 w-8" />
                <div>Monitor devices by geo-location</div>
                <div className="text-xs opacity-75">
                  View device locations and statuses on the map. Update device
                  locations using the 'Settings' icon{' '}
                  {
                    <Icon
                      className="inline-block h-3 w-3"
                      iconName={IconName.SettingsIcon}
                    />
                  }
                  .
                </div>
                <div>
                  <SimpleButton
                    variant="soft"
                    className="w-24"
                    onClick={() => {
                      setViewMap(false);
                      setShowFirstTimeModal(false);
                      messageResponsePending = false;
                    }}
                  >
                    Not now
                  </SimpleButton>
                  <SimpleButton
                    variant="primary"
                    className="w-24"
                    onClick={() => {
                      setViewMap(true);
                      setShowFirstTimeModal(false);
                      messageResponsePending = false;
                    }}
                  >
                    Ok
                  </SimpleButton>
                </div>
              </div>
            )}
        </TransformWrapper>
      )}
    </div>
  );
};

export default Map;
