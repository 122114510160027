import React from 'react';

export const LogoutIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M7.49875 11.0359L7.49875 9.42364L10.4992 9.42364C11.3274 9.42364 11.9994 8.75186 11.9994 7.92384L11.9994 5.15547C11.9994 4.32746 11.3274 3.65568 10.4992 3.65568L7.49875 3.65568L7.49875 2.0434C7.49875 0.71233 5.88289 0.0374213 4.939 0.984167L0.438345 5.48043C-0.146116 6.06472 -0.146116 7.01459 0.438344 7.60201L4.939 12.1014C5.87977 13.0356 7.49875 12.3701 7.49875 11.0359ZM1.49787 6.53653L5.99853 2.03715L5.99853 5.15235L10.4992 5.15235L10.4992 7.92072L5.99853 7.92072L5.99853 11.0359L1.49787 6.53653ZM12.9996 12.5357L10.3742 12.5357C10.1679 12.5357 9.99912 12.367 9.99912 12.1608L9.99912 11.4109C9.99912 11.2046 10.1679 11.0359 10.3742 11.0359L12.9996 11.0359C13.8278 11.0359 14.4998 10.3641 14.4998 9.53612L14.4998 3.53694C14.4998 2.70893 13.8278 2.03715 12.9996 2.03715L10.3742 2.03715C10.1679 2.03715 9.99912 1.86842 9.99912 1.6622L9.99912 0.912302C9.99912 0.706081 10.1679 0.537354 10.3742 0.537354L12.9996 0.537354C14.6561 0.537354 16 1.88092 16 3.53694L16 9.53612C16 11.1921 14.6561 12.5357 12.9996 12.5357Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LogoutIcon;
