import { Flex, View } from '@adobe/react-spectrum';
import { ModeSwitch } from '../../../components/Elements/ModeSwitch';

export const ThemeButton = () => {
  return (
    <Flex justifyContent={'end'}>
      <View marginEnd={'size-200'} marginTop="size-200">
        <ModeSwitch />
      </View>
    </Flex>
  );
};
