export const SearchIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.544 12.4792L18.9081 16.8428C19.4769 17.4115 19.4769 18.3374 18.9079 18.9076C18.3384 19.477 17.4149 19.477 16.8454 18.9076L12.4813 14.544C11.0084 15.5364 9.16727 16.0258 7.20581 15.7637C3.86913 15.319 1.1537 12.5827 0.730781 9.24204C0.102456 4.27731 4.27768 0.102548 9.24296 0.730767C12.5826 1.15353 15.3169 3.86829 15.7639 7.20424C16.0261 9.16549 15.5364 11.0061 14.544 12.4792ZM8.21848 3.58431C5.64524 3.58431 3.55179 5.67753 3.55179 8.25048C3.55179 10.8242 5.64524 12.9166 8.21848 12.9166C10.7917 12.9166 12.8852 10.8234 12.8852 8.25048C12.8852 5.67753 10.7917 3.58431 8.21848 3.58431Z"
        fill="currentColor"
      />
    </svg>
  );
};
