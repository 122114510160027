import { gql } from '@apollo/client';

export const REGISTER_APPLIANCE = gql`
  mutation RegisterAppliance($input: RegisterApplianceInput!) {
    registerAppliance(input: $input) {
      applianceId
      confirmationCode
    }
  }
`;

export const GET_APPLIANCE = gql`
  query GetAppliance($applianceId: String!) {
    appliance(id: $applianceId) {
      id
      applianceId
      description
      status
      installedProducts {
        id
        product {
          kind
          name
          categories
        }
      }
    }
  }
`;

export const CONFIRM_APPLIANCE = gql`
  mutation ConfirmAppliance($applianceId: String!, $confirmationCode: String!) {
    confirmAppliance(
      input: { applianceId: $applianceId, confirmationCode: $confirmationCode }
    )
  }
`;

// More detail can be added for products
export const GET_APPLIANCES = gql`
  query GetAppliances($filter: QueryApplianceInput) {
    appliances(filter: $filter) {
      id
      applianceId
      description
      node
      status
      installedProducts {
        id
        product {
          name
          kind
          categories
        }
      }
    }
  }
`;
