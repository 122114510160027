import { Disclosure, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import SearchInput from '../fields/SearchInput/SearchInput';
import { getMenuButtonClassName } from '../fields/utils';
import { OpenAccordionIcon } from './OpenAccordionIcon';

export interface AccordionItem<T> {
  id: string;
  label: React.ReactNode;
  children?: AccordionItem<T>[];
  value: T;
}

const filterByText = <T,>(items: AccordionItem<T>[], text: string) => {
  if (!text) return items;
  const filteredItems = items.filter((item) => {
    if (String(item.label).toLowerCase().includes(text.toLowerCase()))
      return true;
    if (item.children) {
      const filteredChildren = filterByText(item.children, text);
      if (filteredChildren.length) return true;
    }
    return false;
  });
  return filteredItems;
};

export const AccordionMenu = <T,>({
  level = 0,
  items,
  currentTarget: currentTargetProp,
  searchable = false,
  onSearch,
  onClick,
  filteringMethod,
  defaultOpen = false,
}: {
  level?: number;
  items: AccordionItem<T>[];
  currentTarget?: AccordionItem<T> | null;
  searchable?: boolean;
  onSearch?: (text: string) => void;
  onClick: (item: AccordionItem<T>) => void;
  filteringMethod?: (
    items: AccordionItem<T>[],
    text: string,
  ) => AccordionItem<T>[];
  // If true, all items will be open by default. If a number, all items in level <= number will be open by default.
  defaultOpen?: boolean | number;
}) => {
  const [currentTarget, setCurrentTarget] = useState<
    AccordionItem<T> | null | undefined
  >(currentTargetProp);

  const [filteredItems, setFilteredItems] = useState<AccordionItem<T>[]>(items);

  const handleOnSearch = (text: string) => {
    if (onSearch) {
      onSearch(text);
    }
    const filtered = filteringMethod
      ? filteringMethod(items, text)
      : searchable && text
      ? filterByText(items, text)
      : items;
    setFilteredItems(filtered);
  };

  useEffect(() => {
    setCurrentTarget(currentTargetProp);
  }, [currentTargetProp]);

  const handleClick = (item: AccordionItem<T>) => {
    setCurrentTarget(item);
    onClick(item);
  };

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  return (
    <div
      className={classNames('flex w-full flex-col gap-2', {
        // 'pl-2': level > 0,
      })}
    >
      {searchable && (
        <SearchInput placeholder="Search" onChange={handleOnSearch} />
      )}
      {filteredItems.length === 0 && (
        <div className="text-center text-tw-description-text">
          No results found
        </div>
      )}
      <div className="menu-items">
        {filteredItems.map((item) => (
          <Disclosure
            key={String(item.id)}
            defaultOpen={
              typeof defaultOpen === 'boolean'
                ? defaultOpen
                : defaultOpen > level
            }
          >
            {({ open }) => {
              const openable = Boolean(item.children && item.children.length);
              const selected = currentTarget?.id === item.id;
              return (
                <>
                  <Disclosure.Button
                    onClick={() => handleClick(item)}
                    className={classNames(
                      getMenuButtonClassName({
                        selected,
                        openable,
                      }),
                      'group flex flex-row',
                    )}
                  >
                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 -left-4 flex w-5 items-center',
                          'bg-tw-light-hover dark:bg-tw-strong-shade-dark',
                          'transition dark:group-hover:bg-tw-light-hover-dark',
                        )}
                      >
                        <span className="h-6 w-1 rounded-r-full bg-blue-600" />
                      </span>
                    )}
                    {openable && (
                      <OpenAccordionIcon
                        open={open}
                        className={classNames({})}
                      />
                    )}
                    {level > 0 && (
                      <div id="level-separators">
                        {Array.from({ length: level }).map((_, index) => (
                          <span key={index} className="inline-block h-1 w-6" />
                        ))}
                      </div>
                    )}
                    <span className={classNames('block truncate')}>
                      {item.label}
                    </span>
                  </Disclosure.Button>
                  {openable && (
                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel className="p-0">
                        <AccordionMenu
                          items={item.children || []}
                          onClick={handleClick}
                          level={level + 1}
                          currentTarget={currentTarget}
                          defaultOpen={defaultOpen}
                        />
                      </Disclosure.Panel>
                    </Transition>
                  )}
                </>
              );
            }}
          </Disclosure>
        ))}
      </div>
    </div>
  );
};
