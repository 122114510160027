import React, { MutableRefObject, RefObject } from 'react';
import { MAIN_TOOLTIP_ID } from '../../App';

export const useIsOverflow = (
  ref: RefObject<HTMLElement> | MutableRefObject<HTMLElement | null>,
  callback?: (hasOverflow: boolean) => void,
  type?: 'width' | 'height',
) => {
  const [isOverflow, setIsOverflow] = React.useState<boolean>(false);

  React.useLayoutEffect(() => {
    const { current } = ref;

    let observer: ResizeObserver;

    const trigger = () => {
      const hasOverflow =
        !!current &&
        (type === 'height'
          ? current.scrollHeight > current.clientHeight
          : current.scrollWidth > current.clientWidth);

      setIsOverflow(hasOverflow);
      callback?.(hasOverflow);
    };

    if (current) {
      if ('ResizeObserver' in window) {
        observer = new ResizeObserver(trigger);
        observer.observe(current);
      }

      trigger();
    }

    return () => {
      current && observer?.unobserve(current);
    };
  }, [callback, ref]);

  return isOverflow;
};

export const useIsOverflowTooltip = (
  ref: RefObject<HTMLElement> | MutableRefObject<HTMLElement | null>,
  tooltip: string,
  callback?: (hasOverflow: boolean) => void,
) => {
  const isOverflow = useIsOverflow(ref, callback);

  return isOverflow
    ? {
        'data-tooltip-id': MAIN_TOOLTIP_ID,
        'data-tooltip-content': tooltip,
      }
    : {};
};
