import React from 'react';

export const PlusIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      {...props}
    >
      <path
        d="M9.14296 1.15625C9.14296 0.568555 8.63225 0.09375 8.0001 0.09375C7.36796 0.09375 6.85725 0.568555 6.85725 1.15625V5.9375H1.71439C1.08225 5.9375 0.571533 6.4123 0.571533 7C0.571533 7.5877 1.08225 8.0625 1.71439 8.0625H6.85725V12.8438C6.85725 13.4314 7.36796 13.9062 8.0001 13.9062C8.63225 13.9062 9.14296 13.4314 9.14296 12.8438V8.0625H14.2858C14.918 8.0625 15.4287 7.5877 15.4287 7C15.4287 6.4123 14.918 5.9375 14.2858 5.9375H9.14296V1.15625Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PlusIcon;
