import { useMutation } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  CLEAR_ALERTS,
  CLEAR_ALL_ALERTS,
  GET_ALERTS_COUNT,
  MAX_ALERTS_TO_CLEAR,
} from '../../api/alerts/queries';
import { GET_PRODUCT_ALERTS } from '../../api/dashboard/queries';
import { AlertFilterSelect } from '../../components/AlertFilters/AlertFilterSelect';
import { AlertFilterSelectInline } from '../../components/AlertFilters/AlertFilterSelectInline';
import { AlertComponentBone } from '../../components/Elements/Alerts/Alert';
import AlertDrawer from '../../components/Elements/Alerts/AlertDrawer';
import AlertList, {
  AlertGroupedByCode,
  getAlertGroupKey,
} from '../../components/Elements/Alerts/AlertList';
import NoAlerts from '../../components/Elements/Alerts/NoAlerts';
import Tabs from '../../components/Elements/Tabs/Tabs';
import { ContentLayout } from '../../components/Layout';
import { useSearchParamsReduxFilter } from '../../components/Layout/hooks/useSearchParamsReduxFilter';
import {
  Alert,
  AlertSeverity,
  ClearAllAlertsMutation,
  DeleteAlertsMutation,
  MutationClearAllAlertsArgs,
  MutationDeleteAlertsArgs,
} from '../../gql/graphql';
import { UseAlertsQueryResult, useAlertsQuery } from './useAlertsQuery';

export interface ITab {
  id: string;
  name: string;
  children: JSX.Element;
}

export const Alerts = () => {
  const [activeAlerts, setActiveAlerts] = useState<boolean>(true);

  // Set current filters on url
  useSearchParamsReduxFilter();

  const filters = useMemo(
    () => ({
      critical: {
        severity: [AlertSeverity.Critical],
        active: activeAlerts,
      },
      standard: {
        severity: [AlertSeverity.Standard, AlertSeverity.Error],
        active: activeAlerts,
      },
    }),
    [activeAlerts],
  );

  const critical = useAlertsQuery({ filter: filters.critical });
  const standard = useAlertsQuery({ filter: filters.standard });

  return (
    <ContentLayout
      title="Alerts"
      data-testid="alerts-page"
      titleActions={<AlertFilterSelect />}
      variant="full"
    >
      <Tabs
        sectionClass="pt-4"
        sections={[
          {
            key: 'Active',
          },
          {
            key: 'Cleared',
          },
        ]}
        onChange={(key) => {
          setActiveAlerts(key === 'Active');
        }}
        tabsClass="px-8"
      />
      <div className="mx-auto w-full max-w-[100rem] px-4 sm:px-6 md:px-8">
        <div className="flex w-full items-center py-4">
          <AlertFilterSelectInline />
        </div>
        <AlertsGrouped
          critical={critical}
          standard={standard}
          active={activeAlerts}
          className="mb-4"
          keyId={`alerts-${activeAlerts ? 'active' : 'cleared'}`}
        />
      </div>
    </ContentLayout>
  );
};

const getClearToastMessage = (number: number | string, success: boolean) => {
  const action = success ? 'Success' : 'Error';
  return `${action} clearing ${number} alerts`;
};

const AlertsGrouped = ({
  critical,
  standard,
  keyId = 'alerts',
  className,
  active,
}: {
  critical: UseAlertsQueryResult;
  standard: UseAlertsQueryResult;
  keyId?: string;
  className?: string;
  active: boolean;
}) => {
  const criticalNodes = critical?.data?.alerts?.nodes;
  const standardNodes = standard?.data?.alerts?.nodes;
  const loading = critical.loading || standard.loading;

  const [selectedAlert, setSelectedAlert] =
    useState<AlertGroupedByCode | null>();
  const [clearAlerts, clearMutation] = useMutation<
    DeleteAlertsMutation,
    MutationDeleteAlertsArgs
  >(CLEAR_ALERTS, {
    refetchQueries: [GET_ALERTS_COUNT, GET_PRODUCT_ALERTS],
  });
  const [clearAllAlerts, clearAllMutation] = useMutation<
    ClearAllAlertsMutation,
    MutationClearAllAlertsArgs
  >(CLEAR_ALL_ALERTS, {
    refetchQueries: [GET_ALERTS_COUNT, GET_PRODUCT_ALERTS],
  });

  const handleOnClear =
    (query?: UseAlertsQueryResult) => (clearAll: boolean) => {
      return active
        ? async (alerts?: string[]) => {
            const ids = alerts?.slice(0, MAX_ALERTS_TO_CLEAR) || [];
            if (!clearMutation.loading && alerts?.length) {
              // For the "clear all" option, clear cached ids before result
              clearAll && query?.clearCachedAlerts(ids);
              return await clearAlerts({
                variables: { ids },
              })
                .then((result) => {
                  // For the single "clear", clear cached ids after success
                  !clearAll && query?.clearCachedAlerts(ids);

                  // Close details panel if deleted alert is selected
                  selectedAlert?.alerts.some(({ id }) => alerts.includes(id)) &&
                    setSelectedAlert(null);

                  toast.success(getClearToastMessage(ids.length, true));
                  return Boolean(result?.data?.deleteAlerts);
                })
                .catch(() => {
                  toast.error(getClearToastMessage(ids.length, false));
                  return false;
                });
            } else {
              return false;
            }
          }
        : undefined;
    };

  const handleOnClearAll = useCallback(
    (query?: UseAlertsQueryResult) =>
      active
        ? async () => {
            if (!clearAllMutation.loading && query?.variables?.filter) {
              query?.clearCachedAlerts();
              return await clearAllAlerts({
                variables: {
                  filter: query?.variables?.filter,
                },
              })
                .then((result) => {
                  // Close details panel if deleted alert is selected
                  selectedAlert?.alerts.some(
                    ({ severity }) =>
                      severity &&
                      query?.variables?.filter?.severity?.includes(severity),
                  ) && setSelectedAlert(null);

                  toast.success(
                    getClearToastMessage(
                      `${result.data?.clearAllAlerts} ${query?.variables?.filter.severity}`,
                      true,
                    ),
                  );
                  return Boolean(result?.data?.clearAllAlerts);
                })
                .catch(() => {
                  toast.error(
                    getClearToastMessage(
                      `${query?.variables?.filter.severity}`,
                      false,
                    ),
                  );
                  return false;
                });
            } else {
              return false;
            }
          }
        : undefined,
    [active, clearAllMutation, selectedAlert],
  );

  const handleSelectAlert = useCallback(
    (alert: AlertGroupedByCode | null) => {
      const isCurrentAlert = selectedAlert?.id === alert?.id;
      setSelectedAlert(isCurrentAlert ? null : alert);
    },
    [setSelectedAlert, selectedAlert],
  );

  const handleCloseDrawer = useCallback(() => {
    setSelectedAlert(null);
  }, [setSelectedAlert]);

  return (
    <section
      data-testid="alerts-container"
      className={`${className} relative flex flex-row gap-8`}
    >
      {!(
        standard?.data?.alerts?.nodes?.length ||
        standard.loading ||
        standard.error ||
        critical?.data?.alerts?.nodes?.length ||
        critical.loading ||
        critical.error
      ) ? (
        <NoAlerts active={keyId === 'alerts-active'} />
      ) : (
        <>
          <div className="grow">
            {Boolean(criticalNodes?.length || critical.error) && (
              <AlertList
                // The alerts here will be changed to filter the list of alerts for only the ones that are active
                alerts={(criticalNodes || []) as Alert[]}
                loading={critical.loading}
                onFetchMore={critical.handleFetchMore}
                hasMore={critical.hasMore}
                error={critical.error?.message}
                totalCount={critical?.data?.alerts?.totalItems || 0}
                title="Critical"
                keyId={`${keyId}-critical`}
                className="rounded-b-none"
                selectedId={getAlertGroupKey(selectedAlert)}
                onClear={handleOnClear(critical)}
                onClearAll={handleOnClearAll(critical)}
                refetch={critical.refetch}
                onSelect={handleSelectAlert}
                onUpdateSelected={setSelectedAlert}
                data-test-severity="critical"
              />
            )}
            {Boolean(standardNodes?.length || standard.error) && (
              <AlertList
                // The alerts here will be changed to filter the list of alerts for only the ones that are active
                className="rounded-t-none"
                alerts={(standardNodes || []) as Alert[]}
                loading={standard.loading}
                onFetchMore={standard.handleFetchMore}
                hasMore={standard.hasMore}
                error={standard.error?.message}
                totalCount={standard?.data?.alerts?.totalItems || 0}
                title="Standard"
                keyId={`${keyId}-standard`}
                selectedId={getAlertGroupKey(selectedAlert)}
                onClear={handleOnClear(standard)}
                onClearAll={handleOnClearAll(standard)}
                refetch={standard.refetch}
                onSelect={handleSelectAlert}
                onUpdateSelected={setSelectedAlert}
                data-test-severity="standard"
              />
            )}
            {loading && (
              <AlertComponentBone data-testid="alerts-general-loader" />
            )}
          </div>
          {/* Selected Alert Panel */}
          {selectedAlert ? (
            <div className="absolute left-0 top-0 h-full min-h-[30rem] w-full pb-4 lg:relative lg:h-auto lg:w-[400px] lg:pb-0">
              <AlertDrawer
                selectedAlert={selectedAlert}
                onClose={handleCloseDrawer}
                onClear={handleOnClear(
                  selectedAlert.severity === AlertSeverity.Critical
                    ? critical
                    : standard,
                )(false)}
              />
            </div>
          ) : null}
        </>
      )}
    </section>
  );
};
