import React from 'react';

export const CheckmarkBoldIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      strokeWidth="1.5"
      height="16"
      width="16"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M12.6347 5.91953C13.0727 5.48158 13.0727 4.76641 12.6347 4.32846C12.1968 3.89051 11.4816 3.89051 11.0436 4.32846L6.69633 8.67578L4.92044 6.89989C4.48249 6.46194 3.76731 6.46194 3.32936 6.89989C2.89021 7.33783 2.89021 8.05301 3.32936 8.49096L5.90079 11.0624C6.33874 11.5003 7.05392 11.5003 7.49186 11.0624L12.6347 5.91953Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CheckmarkBoldIcon;
