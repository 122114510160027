import { ButtonHTMLAttributes, DetailedHTMLProps, useState } from 'react';
import { BoundsId, Modal } from './Modal';

export interface ModalTriggerProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: React.ReactNode;
  modalContent: React.ReactNode | ((close: () => void) => React.ReactNode);
  modalTitle?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClose?: () => void;
  disabled?: boolean;
  layoutArea?: BoundsId;
}
export const ModalTrigger = ({
  children,
  onClick,
  onClose,
  modalContent,
  modalTitle,
  disabled,
  layoutArea,
  ...rest
}: ModalTriggerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    onClick?.(e);
    setIsOpen(true);
  };

  const onCloseHandler = () => {
    onClose?.();
    setIsOpen(false);
  };

  return (
    <>
      <button
        onClick={onClickHandler}
        disabled={disabled}
        data-testid="modal-trigger-button"
        {...rest}
      >
        {children}
      </button>
      <Modal
        title={modalTitle}
        isOpen={isOpen}
        onClose={onCloseHandler}
        layoutArea={layoutArea}
      >
        {typeof modalContent === 'function'
          ? modalContent(onCloseHandler)
          : modalContent}
      </Modal>
    </>
  );
};
