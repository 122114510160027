import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import { default as dayjs } from 'dayjs';
import { isNil } from 'ramda';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CLEAR_ALERTS, CLEAR_ALL_ALERTS } from '../../../api/alerts/queries';
import { ProductKind } from '../../../api/product/types';
import { Icon, IconName } from '../../../assets/icons/Icon';
import SolidCloseIcon from '../../../assets/icons/SolidCloseIcon';
import { from } from '../../../components/Elements/Notification/NotificationWidgetContent';
import {
  Alert,
  AlertSeverity,
  ClearAllAlertsMutation,
  DeleteAlertsMutation,
  MutationClearAllAlertsArgs,
  MutationDeleteAlertsArgs,
} from '../../../gql/graphql';
import { formatNumber, toTitleCase } from '../../../utils/format';
import { ProductIcon } from '../Icons/ProductIcons/ProductIcon';
import Spinner from '../Spinner/Spinner';
import { notificationTypeToIconVariant } from './utils';

const titleClassName = (disabled?: boolean) =>
  disabled
    ? 'text-tw-inactive-color dark:text-tw-inactive-color-dark'
    : 'text-tw-main-text dark:text-tw-main-text-dark';
const descriptionClassName = (disabled?: boolean) =>
  disabled
    ? 'text-tw-inactive-color dark:text-tw-inactive-color-dark'
    : 'text-tw-description-text';

export const ErrorMessage = ({
  message,
  onClose,
}: {
  message: string;
  onClose?: () => void;
}) => {
  return (
    <div className="absolute bottom-0 left-0 right-0 flex bg-yellow-200 p-1 text-red-500">
      <div className="grow self-stretch">{message}</div>
      <Icon
        iconName={IconName.CloseIcon}
        onClick={onClose}
        className="flex-none cursor-pointer self-start p-1"
      />
    </div>
  );
};

export interface INotificationRowProps extends Alert {
  onClearClick?: (id: string) => void;
  isClearing?: boolean;
}
export const NotificationRow = ({
  appliance,
  product,
  severity,
  message,
  createdAt,
  code,
  id,
  onClearClick,
  isClearing,
  active,
}: INotificationRowProps) => {
  const [clearAlerts, { loading, error, data, reset }] = useMutation<
    DeleteAlertsMutation,
    MutationDeleteAlertsArgs
  >(CLEAR_ALERTS);

  const { t } = useTranslation(['notifications', 'alertCodes', 'productInfo']);

  const handleClick = useCallback(() => {
    clearAlerts({
      variables: { ids: [id] },
    });
    onClearClick && onClearClick(id);
  }, [id, clearAlerts, onClearClick]);

  const actionsDisabled = useMemo(
    () => loading || error || data || isClearing,
    [loading, error, data, isClearing],
  );

  return (
    <div className="relative flex items-center bg-tw-dark-shade p-5 dark:bg-tw-light-shade-dark">
      <ProductIcon
        productKind={product.kind as ProductKind}
        severityVariant={notificationTypeToIconVariant(severity)}
        className="mr-4 h-9 w-9"
        disabled={!active || !isNil(data)}
      />
      <div className="align-center -mt-1 mr-5 min-w-0 shrink text-ellipsis">
        <p
          className={classNames(
            'truncate overflow-ellipsis text-base font-medium text-tw-main-text dark:text-tw-main-text-dark',
            titleClassName(),
          )}
        >
          {t(`${code}.description`, message, { ns: 'alertCodes' })}

          {message}
        </p>
        <p
          className={classNames(
            'text-xs font-normal text-tw-description-text',
            descriptionClassName(),
          )}
        >
          {t(`${product.kind}.name`, product.kind, {
            ns: 'productInfo',
          })}
          {' · '}
          {appliance.description}
          {' · '}
          {dayjs(createdAt)?.format('MMM D, YYYY [at] H:mm')}
        </p>
      </div>
      {!actionsDisabled && (
        <button
          onClick={handleClick}
          className="ml-auto text-tw-round-small-icon-color transition hover:text-tw-description-text dark:text-tw-strong-shade/25 dark:hover:text-tw-strong-shade"
        >
          <SolidCloseIcon />
        </button>
      )}
      {(loading || isClearing) && (
        <div className="ml-auto">
          <Spinner className="h-5 w-5" />
        </div>
      )}
      {error && (
        <ErrorMessage
          message={t('notifications.error.clearing')}
          onClose={reset}
        />
      )}
    </div>
  );
};

export interface INotificationsTypeBlockProps {
  notifications: Alert[];
  total?: number;
  title: string;
  severity: AlertSeverity | AlertSeverity[];
  onClearClick?: (id: string) => void;
  onClearAllClick?: (event: React.MouseEvent<HTMLElement>) => void;
}
export const NotificationsTypeBlock = ({
  notifications,
  title = 'title',
  total,
  onClearClick = () => null,
  onClearAllClick,
  severity,
}: INotificationsTypeBlockProps) => {
  const { t } = useTranslation('notifications');
  const count = notifications?.length;

  const [clearAll, { loading, error, reset }] = useMutation<
    ClearAllAlertsMutation,
    MutationClearAllAlertsArgs
  >(CLEAR_ALL_ALERTS, {
    refetchQueries: ['GetAlerts', 'GetAlertsCount'],
  });

  const totalCount = useMemo(() => total || count, [count, total]);
  const totalCountFormated = useMemo(
    () => formatNumber(totalCount || 0),
    [totalCount],
  );

  const handleClearAll = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      onClearAllClick && onClearAllClick(event);
      const severitiesString = toTitleCase(
        Array.isArray(severity) ? severity.join(', ') : severity,
      );
      clearAll({
        variables: {
          filter: {
            from,
            severity: Array.isArray(severity) ? severity : [severity],
          },
        },
      })
        .then((result) =>
          toast.success(
            `${result.data?.clearAllAlerts} ${severitiesString} ${
              result.data?.clearAllAlerts === 1 ? 'alert' : 'alerts'
            } cleared`,
          ),
        )
        .catch(() =>
          toast.error(`Error clearing ${total} ${severitiesString} alerts.`),
        );
    },
    [onClearAllClick, clearAll, severity, total],
  );

  return notifications && notifications.length ? (
    <div
      data-testid={`notifications-severity-${severity}`}
      className="divide-black-200 divide-y dark:divide-gray-700"
    >
      <div className="flex bg-gray-100 px-5 py-1 text-tw-description-text dark:bg-tw-dark-shade-dark dark:text-tw-description-text">
        <div
          data-testid={`notifications-severity-${severity}-title`}
          className="text-sm"
        >
          {title} ({totalCountFormated})
        </div>
        {loading ? (
          <Spinner
            data-testid={`notifications-severity-${severity}-spinner`}
            className="ml-auto h-5 w-5"
          />
        ) : (
          <button
            data-testid={`notifications-severity-${severity}-clear-all`}
            onClick={handleClearAll}
            aria-label={`${t('notifications.clearAll')} ${title}`}
            className="ml-auto text-sm text-neutral-800 dark:text-neutral-100"
          >
            {t('notifications.clearAll')}
          </button>
        )}
        {error && (
          <ErrorMessage
            message={t('notifications.error.clearingAll')}
            onClose={reset}
          />
        )}
      </div>
      {notifications.map((notification) => (
        <NotificationRow
          key={`notification-row-${notification.severity}-${notification.id}`}
          onClearClick={onClearClick}
          isClearing={loading}
          {...notification}
        />
      ))}
    </div>
  ) : null;
};
