import { useMemo } from 'react';
import { Category } from '../../../gql/graphql';
import { useAppSelector } from '../../../redux/hooks';
import { productsByCategory } from '../../../utils/products';

export type CoverageItem = {
  category: Category;
  score: number;
  products: Array<string>;
  enabled: boolean;
};

interface CoverageTotal {
  category: Category;
  count: number;
  total: number;
  average: number;
}

const DEFAULT_TOTAL_VALUE: CoverageTotal[] = [];

export const useCoverage = () => {
  const { coverages } = useAppSelector((state) => state.products);
  const { installed, available } = useAppSelector(
    (state) => state.products.products,
  );

  const coverageTotals = useMemo(
    () =>
      installed
        .filter((p) => p.inCurrentApplianceFilter !== false)
        .reduce((acc, curr) => {
          const categories = curr.categories;
          let next: CoverageTotal[] = [...acc];
          categories.forEach((category) => {
            const accCategory = next.find((item) => item.category === category);

            const currCoverage = coverages.find(
              (coverage) => coverage.kind === curr.kind,
            )?.coverage;

            const categoryValue = currCoverage?.health.find(
              (health) => health.key === category,
            )?.value;

            if (accCategory) {
              const total = accCategory.total + (categoryValue || 0);
              const count = accCategory.count + 1;
              const average = total / count;
              next = next.map((item) =>
                item.category === category
                  ? {
                      ...accCategory,
                      count,
                      total,
                      average,
                    }
                  : item,
              );
            } else {
              next = [
                ...next,
                {
                  category,
                  count: 1,
                  total: categoryValue || 0,
                  average: categoryValue || 0,
                },
              ];
            }
          });
          return next;
        }, DEFAULT_TOTAL_VALUE),
    [installed, coverages],
  );

  const coverageItems: CoverageItem[] = useMemo(
    () =>
      Object.values(Category).map((category) => {
        const coverageTotal = coverageTotals.find(
          (item) => item.category === category,
        );
        return {
          category,
          score: coverageTotal?.average || 0,
          products: productsByCategory(category, available),
          enabled: (coverageTotal?.count || 0) > 0,
        };
      }),
    [coverageTotals],
  );

  return { coverageTotals, coverageItems };
};
