import React from 'react';

export const ContentSearchAnalyticsIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M24.1097 10.1992L21.1097 7.08203C20.7883 6.74805 20.3561 6.5625 19.9026 6.5625H12.6169C11.6704 6.56621 10.899 7.36406 10.899 8.34746V16.6562H9.18471C8.87042 16.6562 8.61328 16.9234 8.61328 17.25V17.8438C8.61328 18.1703 8.87042 18.4375 9.18471 18.4375H13.2276L14.3883 20.8533C14.599 21.2912 15.199 21.2912 15.4097 20.8533L17.1847 17.1646L17.799 18.4375H20.899C21.3704 18.4375 21.7561 18.0367 21.7561 17.5469C21.7561 17.057 21.3704 16.6562 20.899 16.6562H18.8561L17.6954 14.2404C17.4847 13.8025 16.8847 13.8025 16.674 14.2404L14.899 17.9291L14.2883 16.6562H12.6133V8.34746H18.3276V12.2068C18.3276 12.7004 18.7097 13.0975 19.1847 13.0975H22.899V23.7812H12.6169V20.2188H10.899V23.7812C10.899 24.7646 11.6669 25.5625 12.6133 25.5625H22.899C23.8454 25.5625 24.6133 24.7646 24.6133 23.7812V11.4609C24.6133 10.9859 24.4311 10.5332 24.1097 10.1992ZM20.0419 11.3162V8.49219L22.7597 11.3162H20.0419Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ContentSearchAnalyticsIcon;
