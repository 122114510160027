import React from 'react';

export const RSWModal = () => {
  return (
    <div className="justify-start text-start dark:text-white">
      <ul className="list-outside">
        <li className="list-disc">
          No active ransomware events (75%)
          <ul className="list-inside">
            <li className="list-disc">One or more Critical events: 0%</li>
            <li className="list-disc">
              One or more major events, but no critical: 25%
            </li>
            <li className="list-disc">
              One or more warning events, but no major or critical events: 50%
            </li>
          </ul>
        </li>
        <li className="list-disc">
          Security Guard Passed the last time it was run (25%)
        </li>
      </ul>
    </div>
  );
};

export const EasyAuditorModal = () => {
  return (
    <div className="justify-start text-start dark:text-white">
      <ul className="list-outside">
        <li className="list-disc">
          No active Active Auditor events(75%)
          <ul className="list-inside">
            <li className="list-disc">One or more Critical events: 0%</li>
            <li className="list-disc">
              One or more major events, but no critical: 25%
            </li>
            <li className="list-disc">
              One or more warning events, but no major or critical events: 50%
            </li>
          </ul>
        </li>
        <li className="list-disc">
          RoboAudit Passed the last time it was run (25%)
        </li>
      </ul>
    </div>
  );
};

export const DRModal = () => {
  return (
    <div className="justify-start text-start dark:text-white">
      <ul className="list-outside">
        <li className="list-disc">
          Readiness (Zone, Pool, Policy) is all OK. (75%)
        </li>

        <li className="list-disc">
          Runbook Robot passed the last time it was run. (25%)
        </li>
      </ul>
    </div>
  );
};

export const AGModal = () => {
  return (
    <div className="justify-start text-start dark:text-white">
      <ul className="list-outside">
        <li className="list-disc">
          All jobs ran successfully last time they were started.
        </li>
      </ul>
    </div>
  );
};

export const CSMModal = () => {
  return (
    <div className="justify-start text-start dark:text-white">
      <ul className="list-outside">
        <li className="list-disc">
          All jobs ran successfully last time they were started.
        </li>
      </ul>
    </div>
  );
};
