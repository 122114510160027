import { MutationResult, useMutation } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  CONFIRM_APPLIANCE,
  REGISTER_APPLIANCE,
} from '../../api/appliance/queries';
import { SimpleButton } from '../../components/Buttons/SimpleButton';
import { useLogOut } from '../../components/Elements/User';
import { Message } from '../../components/common/Message';
import { InputText } from '../../components/form/fields';
import {
  ConfirmApplianceMutation,
  ConfirmApplianceMutationVariables,
  RegisterApplianceMutation,
  RegisterApplianceMutationVariables,
} from '../../gql/graphql';
import { useAppSelector } from '../../redux/hooks';
import { Capabilities } from '../loginPage/components/ExploreApps';

export const ApplianceRegistrationForm = ({
  appliance,
  onSubmit,
}: {
  appliance: {
    applianceId: string;
    description?: string | null;
    publicKey: string;
    products: string[];
  };
  onSubmit: () => void;
  error?: string;
  loading?: boolean;
}) => {
  const { available: allProducts } = useAppSelector(
    (state) => state.products.products,
  );
  const filteredApps = useMemo(() => {
    return allProducts.filter((currentProduct) => {
      return appliance?.products?.some(
        (product) => product === currentProduct.kind,
      );
    });
  }, [allProducts, appliance]);

  const { logOutButton } = useLogOut({
    navigateAfterLogout: false,
  });

  const [description, setDescription] = useState(
    appliance.description || undefined,
  );

  const [registerAppliance, registerApplianceMutation] = useMutation<
    RegisterApplianceMutation,
    RegisterApplianceMutationVariables
  >(REGISTER_APPLIANCE);

  const [confirmApplianceMutation, setConfirmApplianceMutation] =
    useState<MutationResult<ConfirmApplianceMutation>>();

  const [descriptionMessage, setDescriptionMessage] = useState('');

  const handleSubmit = async () => {
    description
      ? await registerAppliance({
          variables: {
            input: {
              applianceId: appliance.applianceId,
              description,
              publicKey: appliance.publicKey,
              products: appliance.products,
            },
          },
        })
          .catch(console.log)
          .then(() => {
            onSubmit();
          })
      : setDescriptionMessage('Description is required');
  };

  const confirmationCode = useMemo(() => {
    return registerApplianceMutation?.data?.registerAppliance?.confirmationCode;
  }, [registerApplianceMutation?.data]);

  const applianceId = useMemo(() => {
    return registerApplianceMutation?.data?.registerAppliance?.applianceId;
  }, [registerApplianceMutation?.data]);

  const isRegistered = useMemo(() => {
    return Boolean(applianceId && confirmationCode);
  }, [confirmationCode]);

  const stepMessage: {
    message: string | JSX.Element;
    type?: 'info' | 'success' | 'error' | 'loading';
  } = useMemo(() => {
    const isFinished = Boolean(
      isRegistered && confirmApplianceMutation?.data?.confirmAppliance,
    );

    return isFinished
      ? {
          message: (
            <>
              Appliance successfully confirmed!
              <Link
                to="/"
                className="ml-auto text-sm font-normal text-tw-main-color underline"
                data-testid="appliance-registration-go-home"
              >
                Go home
              </Link>
            </>
          ),
          type: 'success',
        }
      : confirmApplianceMutation?.error
      ? {
          message:
            confirmApplianceMutation?.error?.message || 'Something went wrong',
          type: 'error',
        }
      : confirmApplianceMutation?.loading
      ? {
          message: 'Confirming appliance',
          type: 'loading',
        }
      : registerApplianceMutation.error
      ? {
          message:
            registerApplianceMutation.error?.message || 'Something went wrong',
          type: 'error',
        }
      : registerApplianceMutation.loading
      ? {
          message: 'Registering appliance',
          type: 'loading',
        }
      : {
          message:
            'If you agree with the above information, register the appliance.',
        };
  }, [isRegistered, registerApplianceMutation]);

  return (
    <div
      data-testid="appliance-registration-form"
      className="flex flex-col items-center justify-center"
    >
      <div className="w-[35rem] max-w-[40rem] rounded-2xl bg-tw-dark-shade p-6 font-graphikRegular text-tw-main-text dark:bg-tw-light-shade-dark dark:text-tw-dark-shade">
        <h1 className="text-2xl font-bold">Appliance Registration</h1>
        <div className="flex flex-col gap-4 py-4">
          <div data-testid="appliance-id-container" className="text-xs">
            <span className="text-tw-description-text dark:text-tw-description-text-dark">
              Id:{' '}
            </span>
            <span data-testid="appliance-id">{appliance.applianceId}</span>
          </div>
          <div>
            <InputText
              name="description"
              label="Description"
              placeholder="Description"
              type="text"
              readOnly={isRegistered}
              value={description}
              onChange={(e) => {
                e.target.value ? setDescriptionMessage('') : null;
                setDescription(e.target.value);
              }}
              error={descriptionMessage}
              required
              data-testid="appliance-description"
            />
          </div>
          <div className="">
            <h5>Product list</h5>
            <Capabilities
              filteredProducts={filteredApps}
              customClassNames={{
                container: 'mt-2 gap-2',
                app: 'bg-tw-light-shade dark:bg-tw-light-shade/5 w-auto',
              }}
              showTryButton={false}
              showWhy={false}
              data-testid="appliance-products"
            />
          </div>
        </div>

        <div className="mb-4">
          <Message
            data-testid="appliance-registration-step-message"
            children={stepMessage.message}
            type={stepMessage.type}
          />
        </div>

        {isRegistered ? (
          <ApplianceConfirmationLoader
            applianceId={applianceId || ''}
            confirmationCode={confirmationCode || ''}
            autoConfirm={true}
            onChange={setConfirmApplianceMutation}
          />
        ) : (
          <SimpleButton
            disabled={registerApplianceMutation.loading}
            onClick={handleSubmit}
            className="m-0 h-14 w-full rounded-xl font-graphikRegular font-normal"
            data-testid="appliance-registration-submit"
          >
            <div className="flex items-center justify-center gap-2">
              Register
            </div>
          </SimpleButton>
        )}
      </div>
      <div className="flex w-full justify-end">{logOutButton}</div>
    </div>
  );
};

export const ApplianceConfirmationLoader = ({
  applianceId,
  confirmationCode,
  autoConfirm = false,
  onChange,
}: {
  applianceId: string;
  confirmationCode: string;
  autoConfirm?: boolean;
  onChange?: (data: MutationResult<ConfirmApplianceMutation>) => void;
}) => {
  const [confirmAppliance, confirmApplianceData] = useMutation<
    ConfirmApplianceMutation,
    ConfirmApplianceMutationVariables
  >(CONFIRM_APPLIANCE, {
    variables: {
      applianceId,
      confirmationCode,
    },
  });

  useEffect(() => {
    autoConfirm && confirmAppliance();
  }, [autoConfirm]);

  useEffect(() => {
    onChange && onChange(confirmApplianceData);
  }, [confirmApplianceData]);

  return confirmApplianceData.error ? (
    <div className="flex flex-col">
      <SimpleButton
        disabled={confirmApplianceData.loading}
        className="m-0 h-14 w-full rounded-xl font-graphikRegular font-normal"
        onClick={() => confirmAppliance()}
        data-testid="appliance-registration-retry"
      >
        Retry confirmation
      </SimpleButton>
    </div>
  ) : null;
};
