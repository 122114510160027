import React from 'react';

export const InventoryIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <mask
        id="mask0_3739_156557"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="10"
        width="20"
        height="8"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.253906 10.2881H19.9949V17.6795H0.253906V10.2881Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0_3739_156557)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1066 12.1251C7.69376 12.1251 5.2809 12.1247 2.86805 12.1253C2.31029 12.1255 2.08268 12.3558 2.08235 12.9161C2.08186 13.6238 2.08202 14.3315 2.08227 15.0392C2.08243 15.6182 2.31567 15.8524 2.893 15.8524C7.71863 15.8526 12.5443 15.8526 17.3701 15.8524C17.9225 15.8524 18.1662 15.6132 18.1676 15.0656C18.1694 14.3442 18.1709 13.6227 18.1653 12.9014C18.1643 12.7732 18.1483 12.638 18.1046 12.5187C17.9827 12.1858 17.7025 12.1238 17.3864 12.124C14.9598 12.1259 12.5332 12.1251 10.1066 12.1251ZM10.1121 17.6733C7.69879 17.6733 5.28552 17.6716 2.87225 17.6746C2.34474 17.6752 1.84498 17.5759 1.4019 17.2815C0.726088 16.8325 0.314651 16.2004 0.281687 15.3883C0.243765 14.4554 0.246244 13.5189 0.279539 12.5857C0.32093 11.4218 1.29789 10.4321 2.45991 10.3244C2.69198 10.3029 2.92571 10.2903 3.15869 10.2902C7.90277 10.2882 12.6468 10.2865 17.3909 10.2905C18.3696 10.2914 19.1457 10.6877 19.659 11.5392C19.8871 11.9179 19.9853 12.3395 19.9889 12.7785C19.9956 13.5825 19.9978 14.3866 19.9894 15.1905C19.9751 16.5586 18.9687 17.6121 17.5991 17.6624C16.7545 17.6934 15.908 17.6726 15.0624 17.673C13.4123 17.6739 11.7622 17.6733 10.1121 17.6733Z"
          fill="currentColor"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1101 5.96787C12.5435 5.96787 14.9771 5.96853 17.4105 5.96738C17.9107 5.96713 18.1564 5.75282 18.1644 5.2733C18.1773 4.49711 18.1776 3.72034 18.1643 2.94422C18.1564 2.48032 17.9151 2.26238 17.4501 2.24866C17.4089 2.24751 17.3676 2.24833 17.3264 2.24833C12.5282 2.24833 7.72997 2.24833 2.93177 2.24875C2.84949 2.24875 2.76629 2.24792 2.68516 2.25916C2.2952 2.31302 2.09147 2.52015 2.08626 2.91456C2.07561 3.71141 2.07519 4.50868 2.08635 5.30553C2.09213 5.72159 2.33189 5.9454 2.74787 5.96655C2.81644 5.97002 2.88534 5.96787 2.95408 5.96787C5.33943 5.96787 7.72477 5.96787 10.1101 5.96787ZM10.137 0.420238C12.5569 0.420238 14.9768 0.420899 17.3966 0.41999C18.3837 0.419577 19.1463 0.82581 19.6615 1.67223C19.8882 2.04459 19.9813 2.46123 19.9863 2.8915C19.996 3.70901 19.9995 4.52693 19.9864 5.34443C19.9658 6.62699 19.0373 7.63741 17.7715 7.77092C17.5807 7.79108 17.3882 7.80339 17.1965 7.80347C12.4186 7.8052 7.64071 7.80653 2.86283 7.80339C1.88323 7.80281 1.10877 7.41046 0.59431 6.55685C0.395697 6.22728 0.279536 5.86542 0.274166 5.48364C0.261278 4.57699 0.244424 3.66919 0.27524 2.76345C0.318201 1.50014 1.3439 0.482119 2.60969 0.432962C3.35788 0.403963 4.10805 0.421065 4.85731 0.420651C6.61715 0.419495 8.37708 0.420238 10.137 0.420238Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9219 13.9878C12.9178 13.4986 13.3259 13.0569 13.8191 13.0789C14.3816 13.1039 14.7055 13.4079 14.7426 13.9847C14.7744 14.4782 14.3174 14.9067 13.8233 14.8995C13.3219 14.8922 12.9262 14.492 12.9219 13.9878Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2883 13.0695C16.7738 13.0584 17.2477 13.495 17.2089 13.9823C17.1639 14.5455 16.9249 14.8367 16.3136 14.8937C15.8326 14.9387 15.3848 14.4836 15.379 13.9881C15.3733 13.5203 15.8087 13.0805 16.2883 13.0695Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2991 5.0264C15.7198 4.97741 15.4111 4.63364 15.3874 4.10067C15.3659 3.61975 15.8343 3.16857 16.3033 3.20303C16.8807 3.24541 17.1929 3.54234 17.2025 4.10546C17.2115 4.63306 16.9072 4.97634 16.2991 5.0264Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8311 5.02336C13.327 5.02435 12.9134 4.60556 12.922 4.10292C12.9304 3.60762 13.3443 3.1961 13.8317 3.19825C14.3139 3.20032 14.741 3.6215 14.7491 4.10283C14.7573 4.58978 14.3258 5.02237 13.8311 5.02336Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default InventoryIcon;
