import React from 'react';

export const NotificationBellDotIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <mask
        id="mask0_1224_135426"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="6"
        width="24"
        height="23"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.3335 6.6665H31.6848V28.3162H8.3335V6.6665Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1224_135426)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.9361 25.8194C27.8716 25.7103 27.827 25.6301 27.7779 25.5529C26.6239 23.7373 26.0053 21.7489 25.8898 19.6068C25.8478 18.8305 25.8692 18.0509 25.8582 17.2728C25.8433 16.2041 25.6374 15.1846 25.0778 14.2557C24.4963 13.2901 23.6977 12.5749 22.6611 12.1232C21.4588 11.5992 20.1946 11.5572 18.928 11.7021C17.5339 11.8616 16.3413 12.466 15.4311 13.5624C14.7315 14.4052 14.3109 15.3771 14.2233 16.4669C14.1648 17.194 14.1928 17.9278 14.1752 18.6585C14.1515 19.6374 14.0816 20.6107 13.8728 21.5721C13.5592 23.0154 12.9975 24.3533 12.2165 25.6029C12.1768 25.6665 12.1431 25.7339 12.0956 25.8194H27.9361ZM18.3662 9.26114C18.3662 8.90617 18.3745 8.56442 18.3643 8.22316C18.3459 7.60319 18.8072 6.86157 19.6182 6.70888C20.3151 6.57779 20.926 6.74097 21.3666 7.33691C21.5672 7.60819 21.6609 7.92261 21.6657 8.25984C21.6704 8.58805 21.6667 8.91641 21.6667 9.2642C21.8881 9.31459 22.1024 9.35869 22.3142 9.4123C24.0993 9.86377 25.5609 10.8078 26.6913 12.2674C27.5844 13.4206 28.1251 14.7155 28.2656 16.1653C28.3256 16.7845 28.3093 17.4107 28.342 18.033C28.3859 18.8689 28.3805 19.7139 28.508 20.5377C28.7916 22.3713 29.5798 23.9944 30.7385 25.4405C30.9858 25.7492 31.2271 26.0632 31.4601 26.3828C31.9622 27.0717 31.5894 28.058 30.6739 28.273C30.5449 28.3034 30.4086 28.3145 30.2757 28.3146C23.4302 28.3165 16.5849 28.3163 9.73948 28.3161C9.05993 28.3161 8.55404 27.9676 8.38031 27.3703C8.25736 26.9476 8.3866 26.5805 8.65095 26.2462C9.24141 25.4992 9.84211 24.76 10.3111 23.9252C10.9651 22.7612 11.4274 21.5269 11.5579 20.1968C11.65 19.2584 11.6725 18.3106 11.6777 17.3668C11.6844 16.1157 11.8923 14.9131 12.4065 13.77C12.7422 13.0233 13.2185 12.371 13.7609 11.7601C14.5466 10.8751 15.488 10.2118 16.5845 9.78307C17.0871 9.58652 17.6204 9.46865 18.1401 9.31604C18.2041 9.29726 18.2697 9.28436 18.3662 9.26114V9.26114Z"
          fill="currentColor"
          fillOpacity="0.8"
        />
      </g>
      <mask
        id="mask1_1224_135426"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="16"
        y="30"
        width="8"
        height="4"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6963 30.0142H23.3216V33.3331H16.6963V30.0142Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_1224_135426)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6972 30.0142H23.3215C23.3296 31.1112 22.8489 31.9658 22.0107 32.6168C20.9845 33.4138 19.8367 33.5451 18.6596 33.0136C17.4348 32.4605 16.6661 31.2372 16.6972 30.0142"
          fill="currentColor"
          fillOpacity="0.8"
        />
      </g>
      <circle
        cx="26.6665"
        cy="11.6665"
        r="5"
        fill="currentColor"
        stroke="white"
      />
    </svg>
  );
};

export default NotificationBellDotIcon;
