import classNames from 'classnames';
import { useMemo } from 'react';
import { AlertSeverity, GetAlertsCountQuery } from '../../../gql/graphql';
import { useAlertCountQuery } from '../../../useAlertCountQuery';

export const MAX_DISPLAY_NOTIFICATIONS_NUMBER = 100;
export const MAX_DISPLAY_NOTIFICATIONS_TEXT = `${
  MAX_DISPLAY_NOTIFICATIONS_NUMBER - 1
}+`;

const getSeverity = (notifications: GetAlertsCountQuery) =>
  (notifications.CRITICAL?.totalItems && AlertSeverity.Critical) ||
  (notifications.ERROR?.totalItems && AlertSeverity.Error) ||
  AlertSeverity.Standard;

export function NotificationBadgeWithQuery({
  notifications: propsNotifications,
}: {
  notifications?: GetAlertsCountQuery;
}) {
  const { data } = useAlertCountQuery();

  const notifications = propsNotifications ||
    data || {
      CRITICAL: { totalItems: 0 },
      ERROR: { totalItems: 0 },
      STANDARD: { totalItems: 0 },
    };

  const severity = getSeverity(notifications);

  const notificationsCount =
    notifications.CRITICAL?.totalItems ||
    notifications.ERROR?.totalItems ||
    notifications.STANDARD?.totalItems ||
    0;

  return (
    <NotificationBadge
      notificationsCount={notificationsCount}
      severity={severity}
    />
  );
}

export const NotificationBadge = ({
  severity,
  notificationsCount,
}: {
  severity: AlertSeverity;
  notificationsCount: number;
}) => {
  const badgeClassNames = useMemo(
    () =>
      classNames(
        'notification-badge mr-1.5 h-3.5 flex items-center justify-center px-1 ml-0.5',
        severity.toLowerCase(),
      ),
    [severity],
  );

  const smallNumberOfNotifications = useMemo(
    () => notificationsCount < MAX_DISPLAY_NOTIFICATIONS_NUMBER,
    [notificationsCount],
  );

  const notificationsCountToDisplay = smallNumberOfNotifications
    ? notificationsCount
    : MAX_DISPLAY_NOTIFICATIONS_TEXT;

  const hasNotifications = useMemo(
    () => +notificationsCount > 0,
    [notificationsCount],
  );

  return hasNotifications ? (
    <div data-testid="NotificationWidgetBadge" className={badgeClassNames}>
      <div
        data-testid="notification-widget-badge-number"
        data-test-severity={severity}
        className={
          'px-0.5 pt-[1px] text-center font-graphikSemi text-xs text-white'
        }
      >
        {notificationsCountToDisplay}
      </div>
    </div>
  ) : null;
};
