import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import Spinner from './Spinner';

export const SpinnerWithText = ({
  text = 'Loading...',
  className,
  children,
  spinnerProps,
  ...props
}: {
  text?: string;
  className?: string;
  children?: React.ReactNode;
  spinnerProps?: React.ComponentProps<typeof Spinner> & {
    'data-testid'?: string;
  };
}) => {
  return (
    <div
      className={twMerge(
        classNames(
          'flex flex-col items-center justify-center gap-4',
          className,
        ),
      )}
      {...props}
    >
      <Spinner {...spinnerProps} />
      <span className="text-xs text-tw-description-text dark:text-tw-description-text-dark">
        {children || text}
      </span>
    </div>
  );
};
