import { Icon, IconName } from '../assets/icons/Icon';
import { ProductIcon } from '../components/Elements/Icons/ProductIcons/ProductIcon';
import { SideNav } from '../components/SideNavigation/DBsideNavigation';
import { DefaultSidebar } from '../components/SideNavigation/DBsideNavigation/DBsideNavItems';

export type ProductProps = {
  id: string;
  title: string;
  description: string;
  icon: React.ReactNode;
  sideNav: JSX.Element | null;
  to: string;
  disabled?: boolean;
};

export const dashBoardNav: ProductProps = {
  id: 'app/dashboard',
  title: 'Superna Dashboard',
  description: 'System overview & health',
  icon: <ProductIcon className="h-9 w-9" productKind="DASHBOARD" />,
  sideNav: <DefaultSidebar />,
  to: '/app/dashboard',
};

export const userNav: ProductProps = {
  id: 'user',
  title: 'User',
  description: 'User',
  icon: (
    <Icon
      iconName={IconName.TeamIcon}
      className="h-9 w-9 rounded-lg bg-tw-main-fill-color p-2 text-white shadow-dark-bottom-20"
    />
  ),
  sideNav: (
    <SideNav
      sideNavGroups={[
        {
          gridView: 'first',
          items: [
            {
              icon: <Icon iconName={IconName.ChevronLeftIcon} />,
              title: 'Back to Dashboard',
              sideNavPath: '/app/dashboard',
            },
          ],
        },
        {
          gridView: 'second',
          items: [
            {
              icon: <Icon iconName={IconName.TeamIcon} />,
              title: 'User Info',
              sideNavPath: '/user/information',
            },
            {
              icon: <Icon iconName={IconName.SettingsIcon} />,
              title: 'User preferences',
              sideNavPath: '/user/preferences',
            },
          ],
        },
      ]}
    />
  ),
  to: '/user',
};

export const navigationList: Array<ProductProps> = [dashBoardNav, userNav];
