import React from 'react';

export const CrossIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M16.274 14.3915C16.7948 14.9123 16.7948 15.756 16.274 16.2768C16.0157 16.5372 15.674 16.6663 15.3324 16.6663C14.9908 16.6663 14.6499 16.5361 14.39 16.2757L9.99947 11.8875L5.6094 16.2747C5.349 16.5372 5.00778 16.6663 4.66656 16.6663C4.32533 16.6663 3.98453 16.5372 3.72392 16.2747C3.20313 15.7539 3.20313 14.9102 3.72392 14.3894L8.11525 9.99811L3.72392 5.60887C3.20313 5.08808 3.20313 4.24439 3.72392 3.7236C4.24472 3.20281 5.0884 3.20281 5.60919 3.7236L9.99947 8.11701L14.3908 3.72569C14.9116 3.20489 15.7553 3.20489 16.2761 3.72569C16.7969 4.24648 16.7969 5.09016 16.2761 5.61095L11.8847 10.0023L16.274 14.3915Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CrossIcon;
