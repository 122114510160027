import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useId } from 'react';
import { ProductKind } from '../../../api/product/types';
import { ProductIcon } from '../../../components/Elements/Icons/ProductIcons/ProductIcon';
import { IFilterButton } from '../types';

const renderProductIcon = (product: IFilterButton, isSelected: boolean) => (
  <ProductIcon
    key={product.name}
    productKind={product.kind as ProductKind}
    className={`h-10 w-10 rounded-md border-2 ${
      isSelected
        ? 'border-[#EEF4FF] bg-white fill-white text-[#013FFF]'
        : 'bg-white fill-white text-tw-inactive-color dark:border-[#383942] dark:bg-[#25282D] dark:fill-[#25282D] dark:text-[#897D7D]'
    } `}
  />
);
interface IProps {
  product: IFilterButton;
  updateSelected?: (product: IFilterButton) => void;
  selectedProducts: Array<IFilterButton> | null;
}
const InventoryFilterButton = ({
  product,
  selectedProducts,
  updateSelected,
  ...rest
}: IProps) => {
  // no selection means all is selected
  const isSelected =
    !selectedProducts ||
    !selectedProducts.length ||
    selectedProducts.includes(product);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    updateSelected?.(product);
  };

  const id = useId();

  return (
    <button
      data-testid="appliance-filter-option-button"
      data-test-value={product.name}
      role="option"
      aria-checked={isSelected}
      aria-labelledby={`${id}-description`}
      onClick={handleClick}
      className={`flex h-[70px] flex-grow basis-1/4 cursor-pointer flex-row items-center justify-between rounded-md border-2 ${
        isSelected ? 'border-[#2A63EF]' : 'dark:border-[#25282D]'
      } p-3 dark:bg-[#25282D]`}
      {...rest}
    >
      <div className="flex flex-row items-center gap-2">
        <div className="flex flex-row items-center gap-1">
          {renderProductIcon(product, isSelected)}
        </div>
        <div data-testid="info" className="flex flex-col items-start gap-1">
          <h5
            data-testid="description"
            id={`${id}-description`}
            className="font-graphikMedium dark:text-white"
            style={{ fontSize: '12px' }}
          >
            {product.name}
          </h5>
          {!!product.description && (
            <p
              data-testid="node"
              className="font-graphikMedium text-[#897D7D]"
              style={{ fontSize: '10px' }}
            >
              {product.description}
            </p>
          )}
        </div>
      </div>
      <input
        data-testid="appliance-filter-option-checkbox"
        type="checkbox"
        className="hidden"
        aria-labelledby={`${id}-description`}
        checked={isSelected}
        readOnly={true}
      />
      {isSelected && (
        <FontAwesomeIcon
          icon={faCheck}
          data-testid="selected-icon"
          className="h-[10px] w-[16px]"
        />
      )}
    </button>
  );
};
export default InventoryFilterButton;
