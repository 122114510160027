import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useEffect } from 'react';
import { GET_ALERTS_COUNT } from './api/alerts/queries';
import { GetAlertsCountQuery } from './gql/graphql';
import { useAppDispatch } from './redux/hooks';
import { updateAlertsCount } from './redux/products/productsSlice';
import { useSession } from './utils/hooks/useSession';
dayjs.extend(duration);

export const useAlertCountQuery = (): {
  data: GetAlertsCountQuery | undefined;
  loading?: boolean;
  error?: Error;
} => {
  const { skip } = useSession();

  const query = useQuery<GetAlertsCountQuery>(GET_ALERTS_COUNT, {
    pollInterval: 10000,
    skip,
    variables: {
      from: dayjs().startOf('day').subtract(dayjs.duration('P10Y')).toDate(),
    },
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (query.data) {
      dispatch(updateAlertsCount(query.data));
    }
  }, [query.data]);

  return {
    ...query,
  };
};
