import { IPoint } from '../types';

// ***** CY *****
// Total Number of Latitude Lines = 180 (0 is Equator)
// Total Height of SVG points = 60
// Each point will be equivalent to 3 Lines of Latitude
// 0 latitude would be the 37th point starting from the lowest
// Avg spacing between points is 6.78
// Max point = 429.1
// Min point = 28.9

// ***** CX *****
// Total Number of Longitude Lines = 360 (0 is Prime Meridian)
// Total Width of SVG points = 120
// 0 longitude would be the 55th point starting from the lowest point
// Each point will be 3 lines of Longitude
// Avg Spacing Between points is 6.78
// Max Point = 826.1
// Min Point = 19

export const points: Array<IPoint> = [
  { cx: '826.1', cy: '110.3' },
  { cx: '819.3', cy: '110.3' },
  { cx: '819.3', cy: '117.1' },
  { cx: '812.6', cy: '90' },
  { cx: '812.6', cy: '103.5' },
  { cx: '812.6', cy: '110.3' },
  { cx: '805.8', cy: '90' },
  { cx: '805.8', cy: '103.5' },
  { cx: '805.8', cy: '110.3' },
  { cx: '805.8', cy: '117.1' },
  { cx: '805.8', cy: '123.9' },
  { cx: '805.8', cy: '381.6' },
  { cx: '799', cy: '96.7' },
  { cx: '799', cy: '103.5' },
  { cx: '799', cy: '110.3' },
  { cx: '799', cy: '117.1' },
  { cx: '799', cy: '123.9' },
  { cx: '799', cy: '374.8' },
  { cx: '799', cy: '381.6' },
  { cx: '799', cy: '388.4' },
  { cx: '792.2', cy: '96.7' },
  { cx: '792.2', cy: '103.5' },
  { cx: '792.2', cy: '110.3' },
  { cx: '792.2', cy: '117.1' },
  { cx: '792.2', cy: '123.9' },
  { cx: '792.2', cy: '368' },
  { cx: '792.2', cy: '374.8' },
  { cx: '792.2', cy: '381.6' },
  { cx: '792.2', cy: '388.4' },
  { cx: '792.2', cy: '395.2' },
  { cx: '785.4', cy: '96.7' },
  { cx: '785.4', cy: '103.5' },
  { cx: '785.4', cy: '110.3' },
  { cx: '785.4', cy: '117.1' },
  { cx: '785.4', cy: '123.9' },
  { cx: '785.4', cy: '130.7' },
  { cx: '785.4', cy: '395.2' },
  { cx: '785.4', cy: '402' },
  { cx: '778.7', cy: '96.7' },
  { cx: '778.7', cy: '103.5' },
  { cx: '778.7', cy: '110.3' },
  { cx: '778.7', cy: '117.1' },
  { cx: '778.7', cy: '123.9' },
  { cx: '778.7', cy: '130.7' },
  { cx: '778.7', cy: '334.1' },
  { cx: '778.7', cy: '340.9' },
  { cx: '778.7', cy: '395.2' },
  { cx: '778.7', cy: '402' },
  { cx: '771.9', cy: '96.7' },
  { cx: '771.9', cy: '103.5' },
  { cx: '771.9', cy: '110.3' },
  { cx: '771.9', cy: '117.1' },
  { cx: '771.9', cy: '123.9' },
  { cx: '771.9', cy: '130.7' },
  { cx: '771.9', cy: '334.1' },
  { cx: '771.9', cy: '340.9' },
  { cx: '765.1', cy: '96.7' },
  { cx: '765.1', cy: '103.5' },
  { cx: '765.1', cy: '110.3' },
  { cx: '765.1', cy: '117.1' },
  { cx: '765.1', cy: '123.9' },
  { cx: '765.1', cy: '130.7' },
  { cx: '765.1', cy: '137.4' },
  { cx: '765.1', cy: '144.2' },
  { cx: '765.1', cy: '151' },
  { cx: '758.3', cy: '96.7' },
  { cx: '758.3', cy: '103.5' },
  { cx: '758.3', cy: '110.3' },
  { cx: '758.3', cy: '117.1' },
  { cx: '758.3', cy: '123.9' },
  { cx: '758.3', cy: '137.4' },
  { cx: '758.3', cy: '144.2' },
  { cx: '758.3', cy: '151' },
  { cx: '758.3', cy: '157.8' },
  { cx: '758.3', cy: '307' },
  { cx: '751.5', cy: '96.7' },
  { cx: '751.5', cy: '103.5' },
  { cx: '751.5', cy: '110.3' },
  { cx: '751.5', cy: '117.1' },
  { cx: '751.5', cy: '123.9' },
  { cx: '751.5', cy: '130.7' },
  { cx: '751.5', cy: '151' },
  { cx: '751.5', cy: '307' },
  { cx: '744.7', cy: '90' },
  { cx: '744.7', cy: '96.7' },
  { cx: '744.7', cy: '103.5' },
  { cx: '744.7', cy: '110.3' },
  { cx: '744.7', cy: '117.1' },
  { cx: '744.7', cy: '123.9' },
  { cx: '744.7', cy: '130.7' },
  { cx: '744.7', cy: '300.2' },
  { cx: '744.7', cy: '347.7' },
  { cx: '744.7', cy: '354.5' },
  { cx: '744.7', cy: '361.3' },
  { cx: '738', cy: '76.4' },
  { cx: '738', cy: '90' },
  { cx: '738', cy: '96.7' },
  { cx: '738', cy: '103.5' },
  { cx: '738', cy: '110.3' },
  { cx: '738', cy: '117.1' },
  { cx: '738', cy: '123.9' },
  { cx: '738', cy: '130.7' },
  { cx: '738', cy: '300.2' },
  { cx: '738', cy: '313.8' },
  { cx: '738', cy: '340.9' },
  { cx: '738', cy: '347.7' },
  { cx: '738', cy: '354.5' },
  { cx: '738', cy: '361.3' },
  { cx: '738', cy: '368' },
  { cx: '738', cy: '374.8' },
  { cx: '731.2', cy: '76.4' },
  { cx: '731.2', cy: '90' },
  { cx: '731.2', cy: '96.7' },
  { cx: '731.2', cy: '103.5' },
  { cx: '731.2', cy: '110.3' },
  { cx: '731.2', cy: '117.1' },
  { cx: '731.2', cy: '123.9' },
  { cx: '731.2', cy: '130.7' },
  { cx: '731.2', cy: '300.2' },
  { cx: '731.2', cy: '307' },
  { cx: '731.2', cy: '327.3' },
  { cx: '731.2', cy: '334.1' },
  { cx: '731.2', cy: '340.9' },
  { cx: '731.2', cy: '347.7' },
  { cx: '731.2', cy: '354.5' },
  { cx: '731.2', cy: '361.3' },
  { cx: '731.2', cy: '368' },
  { cx: '731.2', cy: '374.8' },
  { cx: '731.2', cy: '381.6' },
  { cx: '731.2', cy: '388.4' },
  { cx: '724.4', cy: '69.6' },
  { cx: '724.4', cy: '76.4' },
  { cx: '724.4', cy: '83.2' },
  { cx: '724.4', cy: '90' },
  { cx: '724.4', cy: '96.7' },
  { cx: '724.4', cy: '103.5' },
  { cx: '724.4', cy: '110.3' },
  { cx: '724.4', cy: '117.1' },
  { cx: '724.4', cy: '123.9' },
  { cx: '724.4', cy: '130.7' },
  { cx: '724.4', cy: '157.8' },
  { cx: '724.4', cy: '164.6' },
  { cx: '724.4', cy: '171.3' },
  { cx: '724.4', cy: '178.1' },
  { cx: '724.4', cy: '184.9' },
  { cx: '724.4', cy: '300.2' },
  { cx: '724.4', cy: '307' },
  { cx: '724.4', cy: '320.6' },
  { cx: '724.4', cy: '327.3' },
  { cx: '724.4', cy: '334.1' },
  { cx: '724.4', cy: '340.9' },
  { cx: '724.4', cy: '347.7' },
  { cx: '724.4', cy: '354.5' },
  { cx: '724.4', cy: '361.3' },
  { cx: '724.4', cy: '368' },
  { cx: '724.4', cy: '374.8' },
  { cx: '724.4', cy: '381.6' },
  { cx: '717.6', cy: '69.6' },
  { cx: '717.6', cy: '76.4' },
  { cx: '717.6', cy: '83.2' },
  { cx: '717.6', cy: '90' },
  { cx: '717.6', cy: '96.7' },
  { cx: '717.6', cy: '103.5' },
  { cx: '717.6', cy: '110.3' },
  { cx: '717.6', cy: '117.1' },
  { cx: '717.6', cy: '123.9' },
  { cx: '717.6', cy: '130.7' },
  { cx: '717.6', cy: '137.4' },
  { cx: '717.6', cy: '151' },
  { cx: '717.6', cy: '157.8' },
  { cx: '717.6', cy: '164.6' },
  { cx: '717.6', cy: '171.3' },
  { cx: '717.6', cy: '178.1' },
  { cx: '717.6', cy: '184.9' },
  { cx: '717.6', cy: '191.7' },
  { cx: '717.6', cy: '198.5' },
  { cx: '717.6', cy: '205.3' },
  { cx: '717.6', cy: '293.4' },
  { cx: '717.6', cy: '300.2' },
  { cx: '717.6', cy: '307' },
  { cx: '717.6', cy: '327.3' },
  { cx: '717.6', cy: '334.1' },
  { cx: '717.6', cy: '340.9' },
  { cx: '717.6', cy: '347.7' },
  { cx: '717.6', cy: '354.5' },
  { cx: '717.6', cy: '361.3' },
  { cx: '717.6', cy: '368' },
  { cx: '717.6', cy: '374.8' },
  { cx: '710.8', cy: '69.6' },
  { cx: '710.8', cy: '76.4' },
  { cx: '710.8', cy: '90' },
  { cx: '710.8', cy: '96.7' },
  { cx: '710.8', cy: '103.5' },
  { cx: '710.8', cy: '110.3' },
  { cx: '710.8', cy: '117.1' },
  { cx: '710.8', cy: '123.9' },
  { cx: '710.8', cy: '130.7' },
  { cx: '710.8', cy: '137.4' },
  { cx: '710.8', cy: '144.2' },
  { cx: '710.8', cy: '151' },
  { cx: '710.8', cy: '157.8' },
  { cx: '710.8', cy: '164.6' },
  { cx: '710.8', cy: '171.3' },
  { cx: '710.8', cy: '178.1' },
  { cx: '710.8', cy: '198.5' },
  { cx: '710.8', cy: '205.3' },
  { cx: '710.8', cy: '293.4' },
  { cx: '710.8', cy: '300.2' },
  { cx: '710.8', cy: '307' },
  { cx: '710.8', cy: '327.3' },
  { cx: '710.8', cy: '334.1' },
  { cx: '710.8', cy: '340.9' },
  { cx: '710.8', cy: '347.7' },
  { cx: '710.8', cy: '354.5' },
  { cx: '710.8', cy: '361.3' },
  { cx: '710.8', cy: '368' },
  { cx: '704', cy: '90' },
  { cx: '704', cy: '96.7' },
  { cx: '704', cy: '103.5' },
  { cx: '704', cy: '110.3' },
  { cx: '704', cy: '117.1' },
  { cx: '704', cy: '123.9' },
  { cx: '704', cy: '130.7' },
  { cx: '704', cy: '137.4' },
  { cx: '704', cy: '144.2' },
  { cx: '704', cy: '151' },
  { cx: '704', cy: '157.8' },
  { cx: '704', cy: '164.6' },
  { cx: '704', cy: '171.3' },
  { cx: '704', cy: '178.1' },
  { cx: '704', cy: '184.9' },
  { cx: '704', cy: '205.3' },
  { cx: '704', cy: '293.4' },
  { cx: '704', cy: '300.2' },
  { cx: '704', cy: '307' },
  { cx: '704', cy: '320.6' },
  { cx: '704', cy: '327.3' },
  { cx: '704', cy: '334.1' },
  { cx: '704', cy: '340.9' },
  { cx: '704', cy: '347.7' },
  { cx: '704', cy: '354.5' },
  { cx: '704', cy: '361.3' },
  { cx: '704', cy: '368' },
  { cx: '697.3', cy: '90' },
  { cx: '697.3', cy: '96.7' },
  { cx: '697.3', cy: '103.5' },
  { cx: '697.3', cy: '110.3' },
  { cx: '697.3', cy: '117.1' },
  { cx: '697.3', cy: '123.9' },
  { cx: '697.3', cy: '130.7' },
  { cx: '697.3', cy: '137.4' },
  { cx: '697.3', cy: '144.2' },
  { cx: '697.3', cy: '151' },
  { cx: '697.3', cy: '157.8' },
  { cx: '697.3', cy: '164.6' },
  { cx: '697.3', cy: '171.3' },
  { cx: '697.3', cy: '178.1' },
  { cx: '697.3', cy: '184.9' },
  { cx: '697.3', cy: '205.3' },
  { cx: '697.3', cy: '212' },
  { cx: '697.3', cy: '218.8' },
  { cx: '697.3', cy: '293.4' },
  { cx: '697.3', cy: '307' },
  { cx: '697.3', cy: '313.8' },
  { cx: '697.3', cy: '320.6' },
  { cx: '697.3', cy: '327.3' },
  { cx: '697.3', cy: '334.1' },
  { cx: '697.3', cy: '340.9' },
  { cx: '697.3', cy: '347.7' },
  { cx: '697.3', cy: '354.5' },
  { cx: '697.3', cy: '361.3' },
  { cx: '690.5', cy: '83.2' },
  { cx: '690.5', cy: '90' },
  { cx: '690.5', cy: '96.7' },
  { cx: '690.5', cy: '103.5' },
  { cx: '690.5', cy: '110.3' },
  { cx: '690.5', cy: '117.1' },
  { cx: '690.5', cy: '123.9' },
  { cx: '690.5', cy: '130.7' },
  { cx: '690.5', cy: '137.4' },
  { cx: '690.5', cy: '144.2' },
  { cx: '690.5', cy: '151' },
  { cx: '690.5', cy: '157.8' },
  { cx: '690.5', cy: '164.6' },
  { cx: '690.5', cy: '171.3' },
  { cx: '690.5', cy: '178.1' },
  { cx: '690.5', cy: '184.9' },
  { cx: '690.5', cy: '191.7' },
  { cx: '690.5', cy: '198.5' },
  { cx: '690.5', cy: '205.3' },
  { cx: '690.5', cy: '225.6' },
  { cx: '690.5', cy: '286.6' },
  { cx: '690.5', cy: '293.4' },
  { cx: '690.5', cy: '320.6' },
  { cx: '690.5', cy: '327.3' },
  { cx: '690.5', cy: '334.1' },
  { cx: '690.5', cy: '340.9' },
  { cx: '690.5', cy: '347.7' },
  { cx: '690.5', cy: '354.5' },
  { cx: '690.5', cy: '361.3' },
  { cx: '683.7', cy: '83.2' },
  { cx: '683.7', cy: '90' },
  { cx: '683.7', cy: '96.7' },
  { cx: '683.7', cy: '103.5' },
  { cx: '683.7', cy: '110.3' },
  { cx: '683.7', cy: '117.1' },
  { cx: '683.7', cy: '123.9' },
  { cx: '683.7', cy: '130.7' },
  { cx: '683.7', cy: '137.4' },
  { cx: '683.7', cy: '144.2' },
  { cx: '683.7', cy: '151' },
  { cx: '683.7', cy: '157.8' },
  { cx: '683.7', cy: '164.6' },
  { cx: '683.7', cy: '171.3' },
  { cx: '683.7', cy: '178.1' },
  { cx: '683.7', cy: '184.9' },
  { cx: '683.7', cy: '191.7' },
  { cx: '683.7', cy: '198.5' },
  { cx: '683.7', cy: '259.5' },
  { cx: '683.7', cy: '266.3' },
  { cx: '683.7', cy: '273.1' },
  { cx: '683.7', cy: '286.6' },
  { cx: '683.7', cy: '293.4' },
  { cx: '683.7', cy: '300.2' },
  { cx: '683.7', cy: '307' },
  { cx: '683.7', cy: '320.6' },
  { cx: '683.7', cy: '327.3' },
  { cx: '683.7', cy: '334.1' },
  { cx: '683.7', cy: '340.9' },
  { cx: '683.7', cy: '347.7' },
  { cx: '683.7', cy: '354.5' },
  { cx: '683.7', cy: '361.3' },
  { cx: '676.9', cy: '83.2' },
  { cx: '676.9', cy: '90' },
  { cx: '676.9', cy: '96.7' },
  { cx: '676.9', cy: '103.5' },
  { cx: '676.9', cy: '110.3' },
  { cx: '676.9', cy: '117.1' },
  { cx: '676.9', cy: '123.9' },
  { cx: '676.9', cy: '130.7' },
  { cx: '676.9', cy: '137.4' },
  { cx: '676.9', cy: '144.2' },
  { cx: '676.9', cy: '151' },
  { cx: '676.9', cy: '157.8' },
  { cx: '676.9', cy: '164.6' },
  { cx: '676.9', cy: '171.3' },
  { cx: '676.9', cy: '178.1' },
  { cx: '676.9', cy: '184.9' },
  { cx: '676.9', cy: '191.7' },
  { cx: '676.9', cy: '246' },
  { cx: '676.9', cy: '252.7' },
  { cx: '676.9', cy: '259.5' },
  { cx: '676.9', cy: '266.3' },
  { cx: '676.9', cy: '273.1' },
  { cx: '676.9', cy: '286.6' },
  { cx: '676.9', cy: '293.4' },
  { cx: '676.9', cy: '300.2' },
  { cx: '676.9', cy: '307' },
  { cx: '676.9', cy: '313.8' },
  { cx: '676.9', cy: '327.3' },
  { cx: '676.9', cy: '334.1' },
  { cx: '676.9', cy: '340.9' },
  { cx: '676.9', cy: '347.7' },
  { cx: '676.9', cy: '354.5' },
  { cx: '676.9', cy: '361.3' },
  { cx: '676.9', cy: '368' },
  { cx: '670.1', cy: '83.2' },
  { cx: '670.1', cy: '90' },
  { cx: '670.1', cy: '96.7' },
  { cx: '670.1', cy: '103.5' },
  { cx: '670.1', cy: '110.3' },
  { cx: '670.1', cy: '117.1' },
  { cx: '670.1', cy: '123.9' },
  { cx: '670.1', cy: '130.7' },
  { cx: '670.1', cy: '137.4' },
  { cx: '670.1', cy: '144.2' },
  { cx: '670.1', cy: '151' },
  { cx: '670.1', cy: '157.8' },
  { cx: '670.1', cy: '164.6' },
  { cx: '670.1', cy: '171.3' },
  { cx: '670.1', cy: '178.1' },
  { cx: '670.1', cy: '184.9' },
  { cx: '670.1', cy: '191.7' },
  { cx: '670.1', cy: '198.5' },
  { cx: '670.1', cy: '205.3' },
  { cx: '670.1', cy: '212' },
  { cx: '670.1', cy: '218.8' },
  { cx: '670.1', cy: '225.6' },
  { cx: '670.1', cy: '232.4' },
  { cx: '670.1', cy: '246' },
  { cx: '670.1', cy: '252.7' },
  { cx: '670.1', cy: '259.5' },
  { cx: '670.1', cy: '286.6' },
  { cx: '670.1', cy: '293.4' },
  { cx: '670.1', cy: '300.2' },
  { cx: '670.1', cy: '307' },
  { cx: '670.1', cy: '313.8' },
  { cx: '670.1', cy: '327.3' },
  { cx: '670.1', cy: '334.1' },
  { cx: '670.1', cy: '340.9' },
  { cx: '670.1', cy: '347.7' },
  { cx: '670.1', cy: '354.5' },
  { cx: '670.1', cy: '361.3' },
  { cx: '670.1', cy: '368' },
  { cx: '663.4', cy: '83.2' },
  { cx: '663.4', cy: '90' },
  { cx: '663.4', cy: '96.7' },
  { cx: '663.4', cy: '103.5' },
  { cx: '663.4', cy: '110.3' },
  { cx: '663.4', cy: '117.1' },
  { cx: '663.4', cy: '123.9' },
  { cx: '663.4', cy: '130.7' },
  { cx: '663.4', cy: '137.4' },
  { cx: '663.4', cy: '144.2' },
  { cx: '663.4', cy: '151' },
  { cx: '663.4', cy: '157.8' },
  { cx: '663.4', cy: '164.6' },
  { cx: '663.4', cy: '171.3' },
  { cx: '663.4', cy: '178.1' },
  { cx: '663.4', cy: '184.9' },
  { cx: '663.4', cy: '191.7' },
  { cx: '663.4', cy: '198.5' },
  { cx: '663.4', cy: '205.3' },
  { cx: '663.4', cy: '212' },
  { cx: '663.4', cy: '218.8' },
  { cx: '663.4', cy: '225.6' },
  { cx: '663.4', cy: '232.4' },
  { cx: '663.4', cy: '273.1' },
  { cx: '663.4', cy: '279.9' },
  { cx: '663.4', cy: '286.6' },
  { cx: '663.4', cy: '293.4' },
  { cx: '663.4', cy: '307' },
  { cx: '663.4', cy: '340.9' },
  { cx: '663.4', cy: '347.7' },
  { cx: '663.4', cy: '354.5' },
  { cx: '663.4', cy: '361.3' },
  { cx: '663.4', cy: '368' },
  { cx: '656.6', cy: '69.6' },
  { cx: '656.6', cy: '76.4' },
  { cx: '656.6', cy: '83.2' },
  { cx: '656.6', cy: '90' },
  { cx: '656.6', cy: '96.7' },
  { cx: '656.6', cy: '103.5' },
  { cx: '656.6', cy: '110.3' },
  { cx: '656.6', cy: '117.1' },
  { cx: '656.6', cy: '123.9' },
  { cx: '656.6', cy: '130.7' },
  { cx: '656.6', cy: '137.4' },
  { cx: '656.6', cy: '144.2' },
  { cx: '656.6', cy: '151' },
  { cx: '656.6', cy: '157.8' },
  { cx: '656.6', cy: '164.6' },
  { cx: '656.6', cy: '171.3' },
  { cx: '656.6', cy: '178.1' },
  { cx: '656.6', cy: '184.9' },
  { cx: '656.6', cy: '191.7' },
  { cx: '656.6', cy: '198.5' },
  { cx: '656.6', cy: '205.3' },
  { cx: '656.6', cy: '212' },
  { cx: '656.6', cy: '218.8' },
  { cx: '656.6', cy: '225.6' },
  { cx: '656.6', cy: '232.4' },
  { cx: '656.6', cy: '279.9' },
  { cx: '656.6', cy: '286.6' },
  { cx: '656.6', cy: '293.4' },
  { cx: '656.6', cy: '307' },
  { cx: '656.6', cy: '340.9' },
  { cx: '656.6', cy: '347.7' },
  { cx: '656.6', cy: '354.5' },
  { cx: '649.8', cy: '69.6' },
  { cx: '649.8', cy: '76.4' },
  { cx: '649.8', cy: '83.2' },
  { cx: '649.8', cy: '90' },
  { cx: '649.8', cy: '96.7' },
  { cx: '649.8', cy: '103.5' },
  { cx: '649.8', cy: '110.3' },
  { cx: '649.8', cy: '117.1' },
  { cx: '649.8', cy: '123.9' },
  { cx: '649.8', cy: '130.7' },
  { cx: '649.8', cy: '137.4' },
  { cx: '649.8', cy: '144.2' },
  { cx: '649.8', cy: '151' },
  { cx: '649.8', cy: '157.8' },
  { cx: '649.8', cy: '164.6' },
  { cx: '649.8', cy: '171.3' },
  { cx: '649.8', cy: '178.1' },
  { cx: '649.8', cy: '184.9' },
  { cx: '649.8', cy: '191.7' },
  { cx: '649.8', cy: '198.5' },
  { cx: '649.8', cy: '205.3' },
  { cx: '649.8', cy: '212' },
  { cx: '649.8', cy: '218.8' },
  { cx: '649.8', cy: '225.6' },
  { cx: '649.8', cy: '232.4' },
  { cx: '649.8', cy: '239.2' },
  { cx: '649.8', cy: '246' },
  { cx: '649.8', cy: '286.6' },
  { cx: '649.8', cy: '293.4' },
  { cx: '649.8', cy: '307' },
  { cx: '643', cy: '69.6' },
  { cx: '643', cy: '76.4' },
  { cx: '643', cy: '83.2' },
  { cx: '643', cy: '90' },
  { cx: '643', cy: '96.7' },
  { cx: '643', cy: '103.5' },
  { cx: '643', cy: '110.3' },
  { cx: '643', cy: '117.1' },
  { cx: '643', cy: '123.9' },
  { cx: '643', cy: '130.7' },
  { cx: '643', cy: '137.4' },
  { cx: '643', cy: '144.2' },
  { cx: '643', cy: '151' },
  { cx: '643', cy: '157.8' },
  { cx: '643', cy: '164.6' },
  { cx: '643', cy: '171.3' },
  { cx: '643', cy: '178.1' },
  { cx: '643', cy: '184.9' },
  { cx: '643', cy: '191.7' },
  { cx: '643', cy: '198.5' },
  { cx: '643', cy: '205.3' },
  { cx: '643', cy: '212' },
  { cx: '643', cy: '218.8' },
  { cx: '643', cy: '225.6' },
  { cx: '643', cy: '232.4' },
  { cx: '643', cy: '239.2' },
  { cx: '643', cy: '252.7' },
  { cx: '643', cy: '259.5' },
  { cx: '643', cy: '300.2' },
  { cx: '643', cy: '307' },
  { cx: '636.2', cy: '56' },
  { cx: '636.2', cy: '62.8' },
  { cx: '636.2', cy: '69.6' },
  { cx: '636.2', cy: '76.4' },
  { cx: '636.2', cy: '83.2' },
  { cx: '636.2', cy: '90' },
  { cx: '636.2', cy: '96.7' },
  { cx: '636.2', cy: '103.5' },
  { cx: '636.2', cy: '110.3' },
  { cx: '636.2', cy: '117.1' },
  { cx: '636.2', cy: '123.9' },
  { cx: '636.2', cy: '130.7' },
  { cx: '636.2', cy: '137.4' },
  { cx: '636.2', cy: '144.2' },
  { cx: '636.2', cy: '151' },
  { cx: '636.2', cy: '157.8' },
  { cx: '636.2', cy: '164.6' },
  { cx: '636.2', cy: '171.3' },
  { cx: '636.2', cy: '178.1' },
  { cx: '636.2', cy: '184.9' },
  { cx: '636.2', cy: '191.7' },
  { cx: '636.2', cy: '198.5' },
  { cx: '636.2', cy: '205.3' },
  { cx: '636.2', cy: '212' },
  { cx: '636.2', cy: '218.8' },
  { cx: '636.2', cy: '225.6' },
  { cx: '636.2', cy: '232.4' },
  { cx: '636.2', cy: '239.2' },
  { cx: '636.2', cy: '246' },
  { cx: '636.2', cy: '252.7' },
  { cx: '636.2', cy: '259.5' },
  { cx: '636.2', cy: '266.3' },
  { cx: '636.2', cy: '279.9' },
  { cx: '636.2', cy: '286.6' },
  { cx: '636.2', cy: '293.4' },
  { cx: '636.2', cy: '300.2' },
  { cx: '629.4', cy: '56' },
  { cx: '629.4', cy: '62.8' },
  { cx: '629.4', cy: '69.6' },
  { cx: '629.4', cy: '76.4' },
  { cx: '629.4', cy: '83.2' },
  { cx: '629.4', cy: '90' },
  { cx: '629.4', cy: '96.7' },
  { cx: '629.4', cy: '103.5' },
  { cx: '629.4', cy: '110.3' },
  { cx: '629.4', cy: '117.1' },
  { cx: '629.4', cy: '123.9' },
  { cx: '629.4', cy: '130.7' },
  { cx: '629.4', cy: '137.4' },
  { cx: '629.4', cy: '144.2' },
  { cx: '629.4', cy: '151' },
  { cx: '629.4', cy: '157.8' },
  { cx: '629.4', cy: '164.6' },
  { cx: '629.4', cy: '171.3' },
  { cx: '629.4', cy: '178.1' },
  { cx: '629.4', cy: '184.9' },
  { cx: '629.4', cy: '191.7' },
  { cx: '629.4', cy: '198.5' },
  { cx: '629.4', cy: '205.3' },
  { cx: '629.4', cy: '212' },
  { cx: '629.4', cy: '218.8' },
  { cx: '629.4', cy: '225.6' },
  { cx: '629.4', cy: '232.4' },
  { cx: '629.4', cy: '239.2' },
  { cx: '629.4', cy: '246' },
  { cx: '629.4', cy: '252.7' },
  { cx: '629.4', cy: '259.5' },
  { cx: '629.4', cy: '273.1' },
  { cx: '629.4', cy: '279.9' },
  { cx: '629.4', cy: '286.6' },
  { cx: '629.4', cy: '293.4' },
  { cx: '622.7', cy: '49.3' },
  { cx: '622.7', cy: '56' },
  { cx: '622.7', cy: '69.6' },
  { cx: '622.7', cy: '76.4' },
  { cx: '622.7', cy: '83.2' },
  { cx: '622.7', cy: '90' },
  { cx: '622.7', cy: '96.7' },
  { cx: '622.7', cy: '103.5' },
  { cx: '622.7', cy: '110.3' },
  { cx: '622.7', cy: '117.1' },
  { cx: '622.7', cy: '123.9' },
  { cx: '622.7', cy: '130.7' },
  { cx: '622.7', cy: '137.4' },
  { cx: '622.7', cy: '144.2' },
  { cx: '622.7', cy: '151' },
  { cx: '622.7', cy: '157.8' },
  { cx: '622.7', cy: '164.6' },
  { cx: '622.7', cy: '171.3' },
  { cx: '622.7', cy: '178.1' },
  { cx: '622.7', cy: '184.9' },
  { cx: '622.7', cy: '191.7' },
  { cx: '622.7', cy: '198.5' },
  { cx: '622.7', cy: '205.3' },
  { cx: '622.7', cy: '212' },
  { cx: '622.7', cy: '218.8' },
  { cx: '622.7', cy: '225.6' },
  { cx: '622.7', cy: '232.4' },
  { cx: '622.7', cy: '239.2' },
  { cx: '622.7', cy: '246' },
  { cx: '622.7', cy: '252.7' },
  { cx: '622.7', cy: '259.5' },
  { cx: '622.7', cy: '266.3' },
  { cx: '622.7', cy: '273.1' },
  { cx: '622.7', cy: '279.9' },
  { cx: '622.7', cy: '286.6' },
  { cx: '615.9', cy: '42.5' },
  { cx: '615.9', cy: '49.3' },
  { cx: '615.9', cy: '56' },
  { cx: '615.9', cy: '69.6' },
  { cx: '615.9', cy: '76.4' },
  { cx: '615.9', cy: '83.2' },
  { cx: '615.9', cy: '90' },
  { cx: '615.9', cy: '96.7' },
  { cx: '615.9', cy: '103.5' },
  { cx: '615.9', cy: '110.3' },
  { cx: '615.9', cy: '117.1' },
  { cx: '615.9', cy: '123.9' },
  { cx: '615.9', cy: '130.7' },
  { cx: '615.9', cy: '137.4' },
  { cx: '615.9', cy: '144.2' },
  { cx: '615.9', cy: '151' },
  { cx: '615.9', cy: '157.8' },
  { cx: '615.9', cy: '164.6' },
  { cx: '615.9', cy: '171.3' },
  { cx: '615.9', cy: '178.1' },
  { cx: '615.9', cy: '184.9' },
  { cx: '615.9', cy: '191.7' },
  { cx: '615.9', cy: '198.5' },
  { cx: '615.9', cy: '205.3' },
  { cx: '615.9', cy: '212' },
  { cx: '615.9', cy: '218.8' },
  { cx: '615.9', cy: '225.6' },
  { cx: '615.9', cy: '232.4' },
  { cx: '615.9', cy: '239.2' },
  { cx: '615.9', cy: '246' },
  { cx: '615.9', cy: '279.9' },
  { cx: '609.1', cy: '49.3' },
  { cx: '609.1', cy: '69.6' },
  { cx: '609.1', cy: '76.4' },
  { cx: '609.1', cy: '83.2' },
  { cx: '609.1', cy: '90' },
  { cx: '609.1', cy: '96.7' },
  { cx: '609.1', cy: '103.5' },
  { cx: '609.1', cy: '110.3' },
  { cx: '609.1', cy: '117.1' },
  { cx: '609.1', cy: '123.9' },
  { cx: '609.1', cy: '130.7' },
  { cx: '609.1', cy: '137.4' },
  { cx: '609.1', cy: '144.2' },
  { cx: '609.1', cy: '151' },
  { cx: '609.1', cy: '157.8' },
  { cx: '609.1', cy: '164.6' },
  { cx: '609.1', cy: '171.3' },
  { cx: '609.1', cy: '178.1' },
  { cx: '609.1', cy: '184.9' },
  { cx: '609.1', cy: '191.7' },
  { cx: '609.1', cy: '198.5' },
  { cx: '609.1', cy: '205.3' },
  { cx: '609.1', cy: '212' },
  { cx: '609.1', cy: '218.8' },
  { cx: '609.1', cy: '225.6' },
  { cx: '609.1', cy: '232.4' },
  { cx: '609.1', cy: '239.2' },
  { cx: '602.3', cy: '76.4' },
  { cx: '602.3', cy: '83.2' },
  { cx: '602.3', cy: '90' },
  { cx: '602.3', cy: '96.7' },
  { cx: '602.3', cy: '103.5' },
  { cx: '602.3', cy: '110.3' },
  { cx: '602.3', cy: '117.1' },
  { cx: '602.3', cy: '123.9' },
  { cx: '602.3', cy: '130.7' },
  { cx: '602.3', cy: '137.4' },
  { cx: '602.3', cy: '144.2' },
  { cx: '602.3', cy: '151' },
  { cx: '602.3', cy: '157.8' },
  { cx: '602.3', cy: '164.6' },
  { cx: '602.3', cy: '171.3' },
  { cx: '602.3', cy: '178.1' },
  { cx: '602.3', cy: '184.9' },
  { cx: '602.3', cy: '191.7' },
  { cx: '602.3', cy: '198.5' },
  { cx: '602.3', cy: '205.3' },
  { cx: '602.3', cy: '212' },
  { cx: '602.3', cy: '218.8' },
  { cx: '602.3', cy: '225.6' },
  { cx: '602.3', cy: '232.4' },
  { cx: '602.3', cy: '239.2' },
  { cx: '595.5', cy: '76.4' },
  { cx: '595.5', cy: '83.2' },
  { cx: '595.5', cy: '90' },
  { cx: '595.5', cy: '96.7' },
  { cx: '595.5', cy: '103.5' },
  { cx: '595.5', cy: '110.3' },
  { cx: '595.5', cy: '117.1' },
  { cx: '595.5', cy: '123.9' },
  { cx: '595.5', cy: '130.7' },
  { cx: '595.5', cy: '137.4' },
  { cx: '595.5', cy: '144.2' },
  { cx: '595.5', cy: '151' },
  { cx: '595.5', cy: '157.8' },
  { cx: '595.5', cy: '164.6' },
  { cx: '595.5', cy: '171.3' },
  { cx: '595.5', cy: '178.1' },
  { cx: '595.5', cy: '184.9' },
  { cx: '595.5', cy: '191.7' },
  { cx: '595.5', cy: '198.5' },
  { cx: '595.5', cy: '205.3' },
  { cx: '595.5', cy: '212' },
  { cx: '595.5', cy: '218.8' },
  { cx: '595.5', cy: '225.6' },
  { cx: '595.5', cy: '232.4' },
  { cx: '595.5', cy: '239.2' },
  { cx: '588.7', cy: '83.2' },
  { cx: '588.7', cy: '90' },
  { cx: '588.7', cy: '96.7' },
  { cx: '588.7', cy: '103.5' },
  { cx: '588.7', cy: '110.3' },
  { cx: '588.7', cy: '117.1' },
  { cx: '588.7', cy: '123.9' },
  { cx: '588.7', cy: '130.7' },
  { cx: '588.7', cy: '137.4' },
  { cx: '588.7', cy: '144.2' },
  { cx: '588.7', cy: '151' },
  { cx: '588.7', cy: '157.8' },
  { cx: '588.7', cy: '164.6' },
  { cx: '588.7', cy: '171.3' },
  { cx: '588.7', cy: '178.1' },
  { cx: '588.7', cy: '184.9' },
  { cx: '588.7', cy: '191.7' },
  { cx: '588.7', cy: '198.5' },
  { cx: '588.7', cy: '205.3' },
  { cx: '588.7', cy: '212' },
  { cx: '588.7', cy: '218.8' },
  { cx: '588.7', cy: '225.6' },
  { cx: '588.7', cy: '232.4' },
  { cx: '588.7', cy: '239.2' },
  { cx: '588.7', cy: '246' },
  { cx: '582', cy: '83.2' },
  { cx: '582', cy: '90' },
  { cx: '582', cy: '96.7' },
  { cx: '582', cy: '103.5' },
  { cx: '582', cy: '110.3' },
  { cx: '582', cy: '117.1' },
  { cx: '582', cy: '123.9' },
  { cx: '582', cy: '130.7' },
  { cx: '582', cy: '137.4' },
  { cx: '582', cy: '144.2' },
  { cx: '582', cy: '151' },
  { cx: '582', cy: '157.8' },
  { cx: '582', cy: '164.6' },
  { cx: '582', cy: '171.3' },
  { cx: '582', cy: '178.1' },
  { cx: '582', cy: '184.9' },
  { cx: '582', cy: '191.7' },
  { cx: '582', cy: '198.5' },
  { cx: '582', cy: '205.3' },
  { cx: '582', cy: '212' },
  { cx: '582', cy: '218.8' },
  { cx: '582', cy: '225.6' },
  { cx: '582', cy: '232.4' },
  { cx: '582', cy: '239.2' },
  { cx: '582', cy: '246' },
  { cx: '582', cy: '252.7' },
  { cx: '582', cy: '259.5' },
  { cx: '582', cy: '273.1' },
  { cx: '575.2', cy: '83.2' },
  { cx: '575.2', cy: '90' },
  { cx: '575.2', cy: '96.7' },
  { cx: '575.2', cy: '103.5' },
  { cx: '575.2', cy: '110.3' },
  { cx: '575.2', cy: '117.1' },
  { cx: '575.2', cy: '123.9' },
  { cx: '575.2', cy: '130.7' },
  { cx: '575.2', cy: '137.4' },
  { cx: '575.2', cy: '144.2' },
  { cx: '575.2', cy: '151' },
  { cx: '575.2', cy: '157.8' },
  { cx: '575.2', cy: '164.6' },
  { cx: '575.2', cy: '171.3' },
  { cx: '575.2', cy: '178.1' },
  { cx: '575.2', cy: '184.9' },
  { cx: '575.2', cy: '191.7' },
  { cx: '575.2', cy: '198.5' },
  { cx: '575.2', cy: '205.3' },
  { cx: '575.2', cy: '212' },
  { cx: '575.2', cy: '218.8' },
  { cx: '575.2', cy: '225.6' },
  { cx: '575.2', cy: '232.4' },
  { cx: '575.2', cy: '239.2' },
  { cx: '575.2', cy: '246' },
  { cx: '575.2', cy: '252.7' },
  { cx: '575.2', cy: '259.5' },
  { cx: '575.2', cy: '266.3' },
  { cx: '568.4', cy: '83.2' },
  { cx: '568.4', cy: '90' },
  { cx: '568.4', cy: '96.7' },
  { cx: '568.4', cy: '103.5' },
  { cx: '568.4', cy: '110.3' },
  { cx: '568.4', cy: '117.1' },
  { cx: '568.4', cy: '123.9' },
  { cx: '568.4', cy: '130.7' },
  { cx: '568.4', cy: '137.4' },
  { cx: '568.4', cy: '144.2' },
  { cx: '568.4', cy: '151' },
  { cx: '568.4', cy: '157.8' },
  { cx: '568.4', cy: '164.6' },
  { cx: '568.4', cy: '171.3' },
  { cx: '568.4', cy: '178.1' },
  { cx: '568.4', cy: '184.9' },
  { cx: '568.4', cy: '191.7' },
  { cx: '568.4', cy: '198.5' },
  { cx: '568.4', cy: '205.3' },
  { cx: '568.4', cy: '212' },
  { cx: '568.4', cy: '218.8' },
  { cx: '568.4', cy: '225.6' },
  { cx: '568.4', cy: '232.4' },
  { cx: '568.4', cy: '239.2' },
  { cx: '568.4', cy: '246' },
  { cx: '568.4', cy: '252.7' },
  { cx: '568.4', cy: '259.5' },
  { cx: '561.6', cy: '83.2' },
  { cx: '561.6', cy: '90' },
  { cx: '561.6', cy: '96.7' },
  { cx: '561.6', cy: '103.5' },
  { cx: '561.6', cy: '110.3' },
  { cx: '561.6', cy: '117.1' },
  { cx: '561.6', cy: '123.9' },
  { cx: '561.6', cy: '130.7' },
  { cx: '561.6', cy: '137.4' },
  { cx: '561.6', cy: '144.2' },
  { cx: '561.6', cy: '151' },
  { cx: '561.6', cy: '157.8' },
  { cx: '561.6', cy: '164.6' },
  { cx: '561.6', cy: '171.3' },
  { cx: '561.6', cy: '178.1' },
  { cx: '561.6', cy: '184.9' },
  { cx: '561.6', cy: '191.7' },
  { cx: '561.6', cy: '198.5' },
  { cx: '561.6', cy: '205.3' },
  { cx: '561.6', cy: '212' },
  { cx: '561.6', cy: '218.8' },
  { cx: '561.6', cy: '225.6' },
  { cx: '561.6', cy: '232.4' },
  { cx: '561.6', cy: '239.2' },
  { cx: '554.8', cy: '69.6' },
  { cx: '554.8', cy: '83.2' },
  { cx: '554.8', cy: '90' },
  { cx: '554.8', cy: '96.7' },
  { cx: '541.3', cy: '49.3' },
  { cx: '548.1', cy: '49.3' },
  { cx: '534.5', cy: '42.5' },
  { cx: '534.5', cy: '49.3' },
  { cx: '527.7', cy: '42.5' },
  { cx: '527.7', cy: '49.3' },
  { cx: '520.9', cy: '49.3' },
  { cx: '507.4', cy: '49.3' },
  { cx: '500.6', cy: '42.5' },
  { cx: '500.6', cy: '49.3' },
  { cx: '493.8', cy: '42.5' },
  { cx: '554.8', cy: '103.5' },
  { cx: '554.8', cy: '110.3' },
  { cx: '554.8', cy: '117.1' },
  { cx: '554.8', cy: '123.9' },
  { cx: '554.8', cy: '130.7' },
  { cx: '554.8', cy: '137.4' },
  { cx: '554.8', cy: '144.2' },
  { cx: '554.8', cy: '151' },
  { cx: '554.8', cy: '157.8' },
  { cx: '554.8', cy: '164.6' },
  { cx: '554.8', cy: '171.3' },
  { cx: '554.8', cy: '178.1' },
  { cx: '554.8', cy: '184.9' },
  { cx: '554.8', cy: '191.7' },
  { cx: '554.8', cy: '198.5' },
  { cx: '554.8', cy: '205.3' },
  { cx: '554.8', cy: '212' },
  { cx: '554.8', cy: '218.8' },
  { cx: '554.8', cy: '225.6' },
  { cx: '554.8', cy: '232.4' },
  { cx: '554.8', cy: '239.2' },
  { cx: '548.1', cy: '69.6' },
  { cx: '548.1', cy: '90' },
  { cx: '548.1', cy: '96.7' },
  { cx: '548.1', cy: '103.5' },
  { cx: '548.1', cy: '110.3' },
  { cx: '548.1', cy: '117.1' },
  { cx: '548.1', cy: '123.9' },
  { cx: '548.1', cy: '130.7' },
  { cx: '548.1', cy: '137.4' },
  { cx: '548.1', cy: '144.2' },
  { cx: '548.1', cy: '151' },
  { cx: '548.1', cy: '157.8' },
  { cx: '548.1', cy: '164.6' },
  { cx: '548.1', cy: '171.3' },
  { cx: '548.1', cy: '178.1' },
  { cx: '548.1', cy: '184.9' },
  { cx: '548.1', cy: '191.7' },
  { cx: '548.1', cy: '198.5' },
  { cx: '548.1', cy: '205.3' },
  { cx: '548.1', cy: '212' },
  { cx: '548.1', cy: '218.8' },
  { cx: '548.1', cy: '225.6' },
  { cx: '541.3', cy: '69.6' },
  { cx: '541.3', cy: '96.7' },
  { cx: '541.3', cy: '103.5' },
  { cx: '541.3', cy: '110.3' },
  { cx: '541.3', cy: '117.1' },
  { cx: '541.3', cy: '123.9' },
  { cx: '541.3', cy: '130.7' },
  { cx: '541.3', cy: '137.4' },
  { cx: '541.3', cy: '144.2' },
  { cx: '541.3', cy: '151' },
  { cx: '541.3', cy: '157.8' },
  { cx: '541.3', cy: '164.6' },
  { cx: '541.3', cy: '171.3' },
  { cx: '541.3', cy: '178.1' },
  { cx: '541.3', cy: '184.9' },
  { cx: '541.3', cy: '191.7' },
  { cx: '541.3', cy: '198.5' },
  { cx: '541.3', cy: '205.3' },
  { cx: '541.3', cy: '212' },
  { cx: '541.3', cy: '218.8' },
  { cx: '541.3', cy: '225.6' },
  { cx: '534.5', cy: '69.6' },
  { cx: '534.5', cy: '76.4' },
  { cx: '534.5', cy: '96.7' },
  { cx: '534.5', cy: '103.5' },
  { cx: '534.5', cy: '110.3' },
  { cx: '534.5', cy: '117.1' },
  { cx: '534.5', cy: '123.9' },
  { cx: '534.5', cy: '130.7' },
  { cx: '534.5', cy: '137.4' },
  { cx: '534.5', cy: '144.2' },
  { cx: '534.5', cy: '151' },
  { cx: '534.5', cy: '157.8' },
  { cx: '534.5', cy: '164.6' },
  { cx: '534.5', cy: '171.3' },
  { cx: '534.5', cy: '178.1' },
  { cx: '534.5', cy: '184.9' },
  { cx: '534.5', cy: '191.7' },
  { cx: '534.5', cy: '198.5' },
  { cx: '534.5', cy: '205.3' },
  { cx: '534.5', cy: '212' },
  { cx: '534.5', cy: '218.8' },
  { cx: '534.5', cy: '225.6' },
  { cx: '527.7', cy: '76.4' },
  { cx: '527.7', cy: '83.2' },
  { cx: '527.7', cy: '90' },
  { cx: '527.7', cy: '103.5' },
  { cx: '527.7', cy: '110.3' },
  { cx: '527.7', cy: '117.1' },
  { cx: '527.7', cy: '123.9' },
  { cx: '527.7', cy: '130.7' },
  { cx: '527.7', cy: '137.4' },
  { cx: '527.7', cy: '144.2' },
  { cx: '527.7', cy: '151' },
  { cx: '527.7', cy: '157.8' },
  { cx: '527.7', cy: '164.6' },
  { cx: '527.7', cy: '171.3' },
  { cx: '527.7', cy: '178.1' },
  { cx: '527.7', cy: '184.9' },
  { cx: '527.7', cy: '191.7' },
  { cx: '527.7', cy: '198.5' },
  { cx: '527.7', cy: '205.3' },
  { cx: '527.7', cy: '212' },
  { cx: '527.7', cy: '218.8' },
  { cx: '527.7', cy: '225.6' },
  { cx: '527.7', cy: '239.2' },
  { cx: '520.9', cy: '83.2' },
  { cx: '520.9', cy: '90' },
  { cx: '520.9', cy: '103.5' },
  { cx: '520.9', cy: '110.3' },
  { cx: '520.9', cy: '117.1' },
  { cx: '520.9', cy: '123.9' },
  { cx: '520.9', cy: '130.7' },
  { cx: '520.9', cy: '137.4' },
  { cx: '520.9', cy: '144.2' },
  { cx: '520.9', cy: '151' },
  { cx: '520.9', cy: '157.8' },
  { cx: '520.9', cy: '164.6' },
  { cx: '520.9', cy: '171.3' },
  { cx: '520.9', cy: '178.1' },
  { cx: '520.9', cy: '184.9' },
  { cx: '520.9', cy: '191.7' },
  { cx: '520.9', cy: '198.5' },
  { cx: '520.9', cy: '205.3' },
  { cx: '520.9', cy: '212' },
  { cx: '520.9', cy: '218.8' },
  { cx: '520.9', cy: '225.6' },
  { cx: '520.9', cy: '239.2' },
  { cx: '520.9', cy: '246' },
  { cx: '514.1', cy: '90' },
  { cx: '514.1', cy: '103.5' },
  { cx: '514.1', cy: '110.3' },
  { cx: '514.1', cy: '117.1' },
  { cx: '514.1', cy: '123.9' },
  { cx: '514.1', cy: '130.7' },
  { cx: '514.1', cy: '137.4' },
  { cx: '514.1', cy: '144.2' },
  { cx: '514.1', cy: '151' },
  { cx: '514.1', cy: '157.8' },
  { cx: '514.1', cy: '164.6' },
  { cx: '514.1', cy: '171.3' },
  { cx: '514.1', cy: '178.1' },
  { cx: '514.1', cy: '184.9' },
  { cx: '514.1', cy: '191.7' },
  { cx: '514.1', cy: '198.5' },
  { cx: '514.1', cy: '205.3' },
  { cx: '514.1', cy: '212' },
  { cx: '514.1', cy: '218.8' },
  { cx: '514.1', cy: '232.4' },
  { cx: '514.1', cy: '239.2' },
  { cx: '514.1', cy: '246' },
  { cx: '514.1', cy: '252.7' },
  { cx: '514.1', cy: '266.3' },
  { cx: '514.1', cy: '273.1' },
  { cx: '514.1', cy: '320.6' },
  { cx: '507.4', cy: '96.7' },
  { cx: '507.4', cy: '103.5' },
  { cx: '507.4', cy: '110.3' },
  { cx: '507.4', cy: '117.1' },
  { cx: '507.4', cy: '123.9' },
  { cx: '507.4', cy: '130.7' },
  { cx: '507.4', cy: '137.4' },
  { cx: '507.4', cy: '144.2' },
  { cx: '507.4', cy: '151' },
  { cx: '507.4', cy: '157.8' },
  { cx: '507.4', cy: '164.6' },
  { cx: '507.4', cy: '171.3' },
  { cx: '507.4', cy: '178.1' },
  { cx: '507.4', cy: '184.9' },
  { cx: '507.4', cy: '191.7' },
  { cx: '507.4', cy: '198.5' },
  { cx: '507.4', cy: '205.3' },
  { cx: '507.4', cy: '212' },
  { cx: '507.4', cy: '218.8' },
  { cx: '507.4', cy: '225.6' },
  { cx: '507.4', cy: '232.4' },
  { cx: '507.4', cy: '239.2' },
  { cx: '507.4', cy: '246' },
  { cx: '507.4', cy: '252.7' },
  { cx: '507.4', cy: '266.3' },
  { cx: '507.4', cy: '273.1' },
  { cx: '507.4', cy: '320.6' },
  { cx: '507.4', cy: '327.3' },
  { cx: '507.4', cy: '334.1' },
  { cx: '507.4', cy: '340.9' },
  { cx: '500.6', cy: '103.5' },
  { cx: '500.6', cy: '110.3' },
  { cx: '500.6', cy: '117.1' },
  { cx: '500.6', cy: '123.9' },
  { cx: '500.6', cy: '130.7' },
  { cx: '500.6', cy: '137.4' },
  { cx: '500.6', cy: '144.2' },
  { cx: '500.6', cy: '151' },
  { cx: '500.6', cy: '157.8' },
  { cx: '500.6', cy: '164.6' },
  { cx: '500.6', cy: '171.3' },
  { cx: '500.6', cy: '178.1' },
  { cx: '500.6', cy: '184.9' },
  { cx: '405.6', cy: '157.8' },
  { cx: '398.8', cy: '151' },
  { cx: '392.1', cy: '130.7' },
  { cx: '398.8', cy: '130.7' },
  { cx: '392.1', cy: '137.4' },
  { cx: '392.1', cy: '144.2' },
  { cx: '392.1', cy: '151' },
  { cx: '392.1', cy: '157.8' },
  { cx: '385.3', cy: '137.4' },
  { cx: '385.3', cy: '144.2' },
  { cx: '385.3', cy: '151' },
  { cx: '385.3', cy: '157.8' },
  { cx: '378.5', cy: '123.9' },
  { cx: '378.5', cy: '144.2' },
  { cx: '378.5', cy: '151' },
  { cx: '371.7', cy: '151' },
  { cx: '500.6', cy: '191.7' },
  { cx: '500.6', cy: '198.5' },
  { cx: '500.6', cy: '205.3' },
  { cx: '500.6', cy: '212' },
  { cx: '500.6', cy: '218.8' },
  { cx: '500.6', cy: '225.6' },
  { cx: '500.6', cy: '232.4' },
  { cx: '500.6', cy: '239.2' },
  { cx: '500.6', cy: '246' },
  { cx: '500.6', cy: '252.7' },
  { cx: '500.6', cy: '259.5' },
  { cx: '500.6', cy: '266.3' },
  { cx: '500.6', cy: '273.1' },
  { cx: '500.6', cy: '279.9' },
  { cx: '500.6', cy: '327.3' },
  { cx: '500.6', cy: '334.1' },
  { cx: '500.6', cy: '340.9' },
  { cx: '500.6', cy: '347.7' },
  { cx: '493.8', cy: '103.5' },
  { cx: '493.8', cy: '110.3' },
  { cx: '493.8', cy: '117.1' },
  { cx: '493.8', cy: '123.9' },
  { cx: '493.8', cy: '130.7' },
  { cx: '493.8', cy: '137.4' },
  { cx: '493.8', cy: '144.2' },
  { cx: '493.8', cy: '151' },
  { cx: '493.8', cy: '157.8' },
  { cx: '493.8', cy: '164.6' },
  { cx: '493.8', cy: '171.3' },
  { cx: '493.8', cy: '178.1' },
  { cx: '493.8', cy: '184.9' },
  { cx: '493.8', cy: '191.7' },
  { cx: '493.8', cy: '198.5' },
  { cx: '493.8', cy: '205.3' },
  { cx: '493.8', cy: '212' },
  { cx: '493.8', cy: '218.8' },
  { cx: '493.8', cy: '225.6' },
  { cx: '493.8', cy: '232.4' },
  { cx: '493.8', cy: '239.2' },
  { cx: '493.8', cy: '246' },
  { cx: '493.8', cy: '259.5' },
  { cx: '493.8', cy: '266.3' },
  { cx: '493.8', cy: '273.1' },
  { cx: '493.8', cy: '279.9' },
  { cx: '493.8', cy: '286.6' },
  { cx: '487', cy: '103.5' },
  { cx: '487', cy: '110.3' },
  { cx: '487', cy: '117.1' },
  { cx: '487', cy: '123.9' },
  { cx: '487', cy: '130.7' },
  { cx: '487', cy: '137.4' },
  { cx: '487', cy: '144.2' },
  { cx: '487', cy: '151' },
  { cx: '487', cy: '157.8' },
  { cx: '487', cy: '164.6' },
  { cx: '487', cy: '171.3' },
  { cx: '487', cy: '178.1' },
  { cx: '487', cy: '191.7' },
  { cx: '487', cy: '198.5' },
  { cx: '487', cy: '205.3' },
  { cx: '487', cy: '212' },
  { cx: '487', cy: '218.8' },
  { cx: '487', cy: '225.6' },
  { cx: '487', cy: '232.4' },
  { cx: '487', cy: '239.2' },
  { cx: '487', cy: '252.7' },
  { cx: '487', cy: '259.5' },
  { cx: '487', cy: '266.3' },
  { cx: '487', cy: '273.1' },
  { cx: '487', cy: '279.9' },
  { cx: '487', cy: '286.6' },
  { cx: '487', cy: '293.4' },
  { cx: '487', cy: '300.2' },
  { cx: '487', cy: '307' },
  { cx: '487', cy: '313.8' },
  { cx: '487', cy: '320.6' },
  { cx: '487', cy: '327.3' },
  { cx: '480.2', cy: '103.5' },
  { cx: '480.2', cy: '110.3' },
  { cx: '480.2', cy: '117.1' },
  { cx: '480.2', cy: '123.9' },
  { cx: '480.2', cy: '130.7' },
  { cx: '480.2', cy: '137.4' },
  { cx: '480.2', cy: '144.2' },
  { cx: '480.2', cy: '151' },
  { cx: '480.2', cy: '157.8' },
  { cx: '358.1', cy: '110.3' },
  { cx: '351.4', cy: '110.3' },
  { cx: '351.4', cy: '117.1' },
  { cx: '344.6', cy: '110.3' },
  { cx: '344.6', cy: '117.1' },
  { cx: '480.2', cy: '164.6' },
  { cx: '480.2', cy: '171.3' },
  { cx: '480.2', cy: '178.1' },
  { cx: '480.2', cy: '191.7' },
  { cx: '480.2', cy: '198.5' },
  { cx: '480.2', cy: '205.3' },
  { cx: '480.2', cy: '212' },
  { cx: '480.2', cy: '218.8' },
  { cx: '480.2', cy: '225.6' },
  { cx: '480.2', cy: '239.2' },
  { cx: '480.2', cy: '246' },
  { cx: '480.2', cy: '252.7' },
  { cx: '480.2', cy: '259.5' },
  { cx: '480.2', cy: '266.3' },
  { cx: '480.2', cy: '273.1' },
  { cx: '480.2', cy: '279.9' },
  { cx: '480.2', cy: '286.6' },
  { cx: '480.2', cy: '293.4' },
  { cx: '480.2', cy: '300.2' },
  { cx: '480.2', cy: '307' },
  { cx: '480.2', cy: '313.8' },
  { cx: '480.2', cy: '320.6' },
  { cx: '480.2', cy: '327.3' },
  { cx: '480.2', cy: '334.1' },
  { cx: '480.2', cy: '340.9' },
  { cx: '480.2', cy: '347.7' },
  { cx: '473.4', cy: '103.5' },
  { cx: '473.4', cy: '110.3' },
  { cx: '473.4', cy: '117.1' },
  { cx: '473.4', cy: '123.9' },
  { cx: '473.4', cy: '130.7' },
  { cx: '473.4', cy: '137.4' },
  { cx: '473.4', cy: '144.2' },
  { cx: '473.4', cy: '151' },
  { cx: '473.4', cy: '157.8' },
  { cx: '473.4', cy: '164.6' },
  { cx: '473.4', cy: '171.3' },
  { cx: '473.4', cy: '178.1' },
  { cx: '473.4', cy: '191.7' },
  { cx: '473.4', cy: '198.5' },
  { cx: '473.4', cy: '205.3' },
  { cx: '473.4', cy: '218.8' },
  { cx: '473.4', cy: '225.6' },
  { cx: '473.4', cy: '232.4' },
  { cx: '473.4', cy: '239.2' },
  { cx: '473.4', cy: '246' },
  { cx: '473.4', cy: '252.7' },
  { cx: '473.4', cy: '259.5' },
  { cx: '473.4', cy: '266.3' },
  { cx: '473.4', cy: '273.1' },
  { cx: '473.4', cy: '279.9' },
  { cx: '473.4', cy: '286.6' },
  { cx: '473.4', cy: '293.4' },
  { cx: '473.4', cy: '300.2' },
  { cx: '473.4', cy: '307' },
  { cx: '473.4', cy: '313.8' },
  { cx: '473.4', cy: '320.6' },
  { cx: '473.4', cy: '327.3' },
  { cx: '473.4', cy: '334.1' },
  { cx: '473.4', cy: '340.9' },
  { cx: '473.4', cy: '347.7' },
  { cx: '466.7', cy: '90' },
  { cx: '466.7', cy: '96.7' },
  { cx: '466.7', cy: '103.5' },
  { cx: '466.7', cy: '110.3' },
  { cx: '466.7', cy: '117.1' },
  { cx: '466.7', cy: '123.9' },
  { cx: '466.7', cy: '130.7' },
  { cx: '466.7', cy: '137.4' },
  { cx: '466.7', cy: '144.2' },
  { cx: '466.7', cy: '151' },
  { cx: '466.7', cy: '157.8' },
  { cx: '466.7', cy: '164.6' },
  { cx: '466.7', cy: '171.3' },
  { cx: '466.7', cy: '178.1' },
  { cx: '466.7', cy: '191.7' },
  { cx: '466.7', cy: '198.5' },
  { cx: '466.7', cy: '218.8' },
  { cx: '466.7', cy: '225.6' },
  { cx: '466.7', cy: '232.4' },
  { cx: '466.7', cy: '239.2' },
  { cx: '466.7', cy: '246' },
  { cx: '466.7', cy: '252.7' },
  { cx: '466.7', cy: '259.5' },
  { cx: '466.7', cy: '266.3' },
  { cx: '466.7', cy: '273.1' },
  { cx: '466.7', cy: '279.9' },
  { cx: '466.7', cy: '286.6' },
  { cx: '466.7', cy: '293.4' },
  { cx: '466.7', cy: '300.2' },
  { cx: '466.7', cy: '307' },
  { cx: '466.7', cy: '313.8' },
  { cx: '466.7', cy: '320.6' },
  { cx: '466.7', cy: '327.3' },
  { cx: '466.7', cy: '334.1' },
  { cx: '466.7', cy: '340.9' },
  { cx: '466.7', cy: '347.7' },
  { cx: '466.7', cy: '354.5' },
  { cx: '466.7', cy: '361.3' },
  { cx: '459.9', cy: '49.3' },
  { cx: '459.9', cy: '90' },
  { cx: '459.9', cy: '96.7' },
  { cx: '459.9', cy: '103.5' },
  { cx: '459.9', cy: '110.3' },
  { cx: '459.9', cy: '117.1' },
  { cx: '459.9', cy: '123.9' },
  { cx: '459.9', cy: '130.7' },
  { cx: '459.9', cy: '137.4' },
  { cx: '459.9', cy: '144.2' },
  { cx: '459.9', cy: '151' },
  { cx: '459.9', cy: '157.8' },
  { cx: '459.9', cy: '164.6' },
  { cx: '459.9', cy: '171.3' },
  { cx: '459.9', cy: '178.1' },
  { cx: '459.9', cy: '184.9' },
  { cx: '459.9', cy: '191.7' },
  { cx: '459.9', cy: '198.5' },
  { cx: '459.9', cy: '218.8' },
  { cx: '459.9', cy: '225.6' },
  { cx: '459.9', cy: '232.4' },
  { cx: '459.9', cy: '239.2' },
  { cx: '459.9', cy: '246' },
  { cx: '459.9', cy: '252.7' },
  { cx: '459.9', cy: '259.5' },
  { cx: '459.9', cy: '266.3' },
  { cx: '459.9', cy: '273.1' },
  { cx: '459.9', cy: '279.9' },
  { cx: '459.9', cy: '286.6' },
  { cx: '459.9', cy: '293.4' },
  { cx: '459.9', cy: '300.2' },
  { cx: '459.9', cy: '307' },
  { cx: '459.9', cy: '313.8' },
  { cx: '459.9', cy: '320.6' },
  { cx: '459.9', cy: '327.3' },
  { cx: '459.9', cy: '334.1' },
  { cx: '459.9', cy: '340.9' },
  { cx: '459.9', cy: '347.7' },
  { cx: '459.9', cy: '354.5' },
  { cx: '459.9', cy: '361.3' },
  { cx: '459.9', cy: '368' },
  { cx: '453.1', cy: '49.3' },
  { cx: '453.1', cy: '62.8' },
  { cx: '453.1', cy: '96.7' },
  { cx: '453.1', cy: '103.5' },
  { cx: '453.1', cy: '110.3' },
  { cx: '453.1', cy: '117.1' },
  { cx: '453.1', cy: '123.9' },
  { cx: '453.1', cy: '130.7' },
  { cx: '453.1', cy: '137.4' },
  { cx: '453.1', cy: '144.2' },
  { cx: '453.1', cy: '151' },
  { cx: '453.1', cy: '157.8' },
  { cx: '453.1', cy: '164.6' },
  { cx: '453.1', cy: '171.3' },
  { cx: '453.1', cy: '178.1' },
  { cx: '453.1', cy: '184.9' },
  { cx: '453.1', cy: '191.7' },
  { cx: '453.1', cy: '198.5' },
  { cx: '453.1', cy: '212' },
  { cx: '453.1', cy: '218.8' },
  { cx: '453.1', cy: '225.6' },
  { cx: '453.1', cy: '232.4' },
  { cx: '453.1', cy: '239.2' },
  { cx: '453.1', cy: '246' },
  { cx: '453.1', cy: '252.7' },
  { cx: '453.1', cy: '259.5' },
  { cx: '453.1', cy: '266.3' },
  { cx: '453.1', cy: '273.1' },
  { cx: '453.1', cy: '279.9' },
  { cx: '453.1', cy: '286.6' },
  { cx: '453.1', cy: '293.4' },
  { cx: '453.1', cy: '300.2' },
  { cx: '453.1', cy: '307' },
  { cx: '453.1', cy: '313.8' },
  { cx: '453.1', cy: '320.6' },
  { cx: '453.1', cy: '327.3' },
  { cx: '453.1', cy: '334.1' },
  { cx: '453.1', cy: '340.9' },
  { cx: '453.1', cy: '347.7' },
  { cx: '453.1', cy: '354.5' },
  { cx: '453.1', cy: '361.3' },
  { cx: '453.1', cy: '368' },
  { cx: '446.3', cy: '49.3' },
  { cx: '446.3', cy: '56' },
  { cx: '446.3', cy: '96.7' },
  { cx: '446.3', cy: '103.5' },
  { cx: '446.3', cy: '110.3' },
  { cx: '446.3', cy: '117.1' },
  { cx: '446.3', cy: '123.9' },
  { cx: '446.3', cy: '144.2' },
  { cx: '446.3', cy: '151' },
  { cx: '446.3', cy: '157.8' },
  { cx: '446.3', cy: '164.6' },
  { cx: '446.3', cy: '171.3' },
  { cx: '446.3', cy: '178.1' },
  { cx: '446.3', cy: '184.9' },
  { cx: '446.3', cy: '191.7' },
  { cx: '446.3', cy: '198.5' },
  { cx: '446.3', cy: '212' },
  { cx: '446.3', cy: '218.8' },
  { cx: '446.3', cy: '225.6' },
  { cx: '446.3', cy: '232.4' },
  { cx: '446.3', cy: '239.2' },
  { cx: '446.3', cy: '246' },
  { cx: '446.3', cy: '252.7' },
  { cx: '446.3', cy: '259.5' },
  { cx: '446.3', cy: '266.3' },
  { cx: '446.3', cy: '273.1' },
  { cx: '446.3', cy: '279.9' },
  { cx: '446.3', cy: '286.6' },
  { cx: '446.3', cy: '293.4' },
  { cx: '446.3', cy: '300.2' },
  { cx: '446.3', cy: '307' },
  { cx: '446.3', cy: '313.8' },
  { cx: '446.3', cy: '320.6' },
  { cx: '446.3', cy: '327.3' },
  { cx: '446.3', cy: '334.1' },
  { cx: '446.3', cy: '340.9' },
  { cx: '446.3', cy: '347.7' },
  { cx: '446.3', cy: '354.5' },
  { cx: '446.3', cy: '361.3' },
  { cx: '446.3', cy: '368' },
  { cx: '439.5', cy: '49.3' },
  { cx: '439.5', cy: '56' },
  { cx: '439.5', cy: '62.8' },
  { cx: '439.5', cy: '96.7' },
  { cx: '439.5', cy: '103.5' },
  { cx: '439.5', cy: '110.3' },
  { cx: '439.5', cy: '117.1' },
  { cx: '439.5', cy: '130.7' },
  { cx: '439.5', cy: '151' },
  { cx: '439.5', cy: '157.8' },
  { cx: '439.5', cy: '164.6' },
  { cx: '439.5', cy: '171.3' },
  { cx: '439.5', cy: '178.1' },
  { cx: '439.5', cy: '184.9' },
  { cx: '439.5', cy: '218.8' },
  { cx: '439.5', cy: '225.6' },
  { cx: '439.5', cy: '232.4' },
  { cx: '439.5', cy: '239.2' },
  { cx: '439.5', cy: '246' },
  { cx: '439.5', cy: '252.7' },
  { cx: '439.5', cy: '259.5' },
  { cx: '439.5', cy: '266.3' },
  { cx: '439.5', cy: '273.1' },
  { cx: '439.5', cy: '279.9' },
  { cx: '439.5', cy: '286.6' },
  { cx: '439.5', cy: '293.4' },
  { cx: '439.5', cy: '300.2' },
  { cx: '439.5', cy: '307' },
  { cx: '439.5', cy: '313.8' },
  { cx: '439.5', cy: '320.6' },
  { cx: '439.5', cy: '327.3' },
  { cx: '439.5', cy: '334.1' },
  { cx: '439.5', cy: '340.9' },
  { cx: '439.5', cy: '347.7' },
  { cx: '439.5', cy: '354.5' },
  { cx: '439.5', cy: '361.3' },
  { cx: '439.5', cy: '368' },
  { cx: '432.8', cy: '56' },
  { cx: '432.8', cy: '62.8' },
  { cx: '432.8', cy: '103.5' },
  { cx: '432.8', cy: '110.3' },
  { cx: '432.8', cy: '117.1' },
  { cx: '432.8', cy: '123.9' },
  { cx: '432.8', cy: '130.7' },
  { cx: '432.8', cy: '137.4' },
  { cx: '432.8', cy: '144.2' },
  { cx: '432.8', cy: '151' },
  { cx: '432.8', cy: '157.8' },
  { cx: '432.8', cy: '164.6' },
  { cx: '432.8', cy: '171.3' },
  { cx: '432.8', cy: '178.1' },
  { cx: '432.8', cy: '191.7' },
  { cx: '432.8', cy: '198.5' },
  { cx: '432.8', cy: '218.8' },
  { cx: '432.8', cy: '225.6' },
  { cx: '432.8', cy: '232.4' },
  { cx: '432.8', cy: '239.2' },
  { cx: '432.8', cy: '246' },
  { cx: '432.8', cy: '252.7' },
  { cx: '432.8', cy: '259.5' },
  { cx: '432.8', cy: '266.3' },
  { cx: '432.8', cy: '273.1' },
  { cx: '432.8', cy: '279.9' },
  { cx: '432.8', cy: '286.6' },
  { cx: '432.8', cy: '293.4' },
  { cx: '432.8', cy: '300.2' },
  { cx: '432.8', cy: '307' },
  { cx: '432.8', cy: '313.8' },
  { cx: '432.8', cy: '320.6' },
  { cx: '432.8', cy: '327.3' },
  { cx: '432.8', cy: '334.1' },
  { cx: '432.8', cy: '340.9' },
  { cx: '432.8', cy: '347.7' },
  { cx: '426', cy: '56' },
  { cx: '426', cy: '110.3' },
  { cx: '426', cy: '117.1' },
  { cx: '426', cy: '123.9' },
  { cx: '426', cy: '130.7' },
  { cx: '426', cy: '137.4' },
  { cx: '426', cy: '151' },
  { cx: '426', cy: '157.8' },
  { cx: '426', cy: '164.6' },
  { cx: '426', cy: '171.3' },
  { cx: '426', cy: '178.1' },
  { cx: '426', cy: '184.9' },
  { cx: '426', cy: '198.5' },
  { cx: '426', cy: '212' },
  { cx: '426', cy: '218.8' },
  { cx: '426', cy: '225.6' },
  { cx: '426', cy: '232.4' },
  { cx: '426', cy: '239.2' },
  { cx: '426', cy: '246' },
  { cx: '426', cy: '252.7' },
  { cx: '426', cy: '259.5' },
  { cx: '426', cy: '266.3' },
  { cx: '426', cy: '273.1' },
  { cx: '426', cy: '279.9' },
  { cx: '426', cy: '286.6' },
  { cx: '426', cy: '293.4' },
  { cx: '426', cy: '300.2' },
  { cx: '426', cy: '320.6' },
  { cx: '426', cy: '327.3' },
  { cx: '419.2', cy: '117.1' },
  { cx: '419.2', cy: '123.9' },
  { cx: '419.2', cy: '130.7' },
  { cx: '419.2', cy: '137.4' },
  { cx: '419.2', cy: '144.2' },
  { cx: '419.2', cy: '151' },
  { cx: '419.2', cy: '157.8' },
  { cx: '419.2', cy: '164.6' },
  { cx: '419.2', cy: '171.3' },
  { cx: '419.2', cy: '178.1' },
  { cx: '419.2', cy: '184.9' },
  { cx: '419.2', cy: '191.7' },
  { cx: '419.2', cy: '205.3' },
  { cx: '419.2', cy: '212' },
  { cx: '419.2', cy: '218.8' },
  { cx: '419.2', cy: '225.6' },
  { cx: '419.2', cy: '232.4' },
  { cx: '419.2', cy: '239.2' },
  { cx: '419.2', cy: '246' },
  { cx: '419.2', cy: '252.7' },
  { cx: '419.2', cy: '259.5' },
  { cx: '419.2', cy: '266.3' },
  { cx: '419.2', cy: '273.1' },
  { cx: '419.2', cy: '279.9' },
  { cx: '419.2', cy: '286.6' },
  { cx: '419.2', cy: '293.4' },
  { cx: '412.4', cy: '123.9' },
  { cx: '412.4', cy: '130.7' },
  { cx: '412.4', cy: '137.4' },
  { cx: '412.4', cy: '157.8' },
  { cx: '412.4', cy: '164.6' },
  { cx: '412.4', cy: '171.3' },
  { cx: '412.4', cy: '178.1' },
  { cx: '412.4', cy: '184.9' },
  { cx: '412.4', cy: '205.3' },
  { cx: '412.4', cy: '212' },
  { cx: '412.4', cy: '218.8' },
  { cx: '412.4', cy: '225.6' },
  { cx: '412.4', cy: '232.4' },
  { cx: '412.4', cy: '239.2' },
  { cx: '412.4', cy: '246' },
  { cx: '412.4', cy: '252.7' },
  { cx: '412.4', cy: '259.5' },
  { cx: '412.4', cy: '266.3' },
  { cx: '412.4', cy: '273.1' },
  { cx: '405.6', cy: '164.6' },
  { cx: '405.6', cy: '171.3' },
  { cx: '405.6', cy: '178.1' },
  { cx: '405.6', cy: '184.9' },
  { cx: '405.6', cy: '205.3' },
  { cx: '405.6', cy: '212' },
  { cx: '405.6', cy: '218.8' },
  { cx: '405.6', cy: '225.6' },
  { cx: '405.6', cy: '232.4' },
  { cx: '405.6', cy: '239.2' },
  { cx: '405.6', cy: '246' },
  { cx: '405.6', cy: '252.7' },
  { cx: '405.6', cy: '259.5' },
  { cx: '405.6', cy: '266.3' },
  { cx: '405.6', cy: '273.1' },
  { cx: '398.8', cy: '164.6' },
  { cx: '398.8', cy: '171.3' },
  { cx: '392.1', cy: '171.3' },
  { cx: '385.3', cy: '171.3' },
  { cx: '398.8', cy: '178.1' },
  { cx: '398.8', cy: '184.9' },
  { cx: '398.8', cy: '191.7' },
  { cx: '398.8', cy: '205.3' },
  { cx: '398.8', cy: '212' },
  { cx: '398.8', cy: '218.8' },
  { cx: '398.8', cy: '225.6' },
  { cx: '398.8', cy: '232.4' },
  { cx: '398.8', cy: '239.2' },
  { cx: '398.8', cy: '246' },
  { cx: '398.8', cy: '252.7' },
  { cx: '398.8', cy: '259.5' },
  { cx: '398.8', cy: '266.3' },
  { cx: '398.8', cy: '273.1' },
  { cx: '392.1', cy: '184.9' },
  { cx: '392.1', cy: '191.7' },
  { cx: '392.1', cy: '198.5' },
  { cx: '392.1', cy: '205.3' },
  { cx: '392.1', cy: '212' },
  { cx: '392.1', cy: '218.8' },
  { cx: '392.1', cy: '225.6' },
  { cx: '392.1', cy: '232.4' },
  { cx: '392.1', cy: '239.2' },
  { cx: '392.1', cy: '246' },
  { cx: '392.1', cy: '252.7' },
  { cx: '392.1', cy: '259.5' },
  { cx: '392.1', cy: '266.3' },
  { cx: '392.1', cy: '273.1' },
  { cx: '385.3', cy: '184.9' },
  { cx: '385.3', cy: '191.7' },
  { cx: '385.3', cy: '198.5' },
  { cx: '385.3', cy: '205.3' },
  { cx: '385.3', cy: '212' },
  { cx: '385.3', cy: '218.8' },
  { cx: '385.3', cy: '225.6' },
  { cx: '385.3', cy: '232.4' },
  { cx: '385.3', cy: '239.2' },
  { cx: '385.3', cy: '246' },
  { cx: '385.3', cy: '252.7' },
  { cx: '385.3', cy: '259.5' },
  { cx: '385.3', cy: '266.3' },
  { cx: '385.3', cy: '273.1' },
  { cx: '385.3', cy: '279.9' },
  { cx: '378.5', cy: '184.9' },
  { cx: '378.5', cy: '191.7' },
  { cx: '378.5', cy: '198.5' },
  { cx: '378.5', cy: '212' },
  { cx: '378.5', cy: '218.8' },
  { cx: '378.5', cy: '225.6' },
  { cx: '378.5', cy: '232.4' },
  { cx: '378.5', cy: '239.2' },
  { cx: '378.5', cy: '246' },
  { cx: '378.5', cy: '252.7' },
  { cx: '378.5', cy: '259.5' },
  { cx: '378.5', cy: '266.3' },
  { cx: '378.5', cy: '273.1' },
  { cx: '378.5', cy: '279.9' },
  { cx: '371.7', cy: '42.5' },
  { cx: '371.7', cy: '225.6' },
  { cx: '371.7', cy: '232.4' },
  { cx: '371.7', cy: '239.2' },
  { cx: '371.7', cy: '246' },
  { cx: '371.7', cy: '252.7' },
  { cx: '371.7', cy: '259.5' },
  { cx: '371.7', cy: '266.3' },
  { cx: '371.7', cy: '273.1' },
  { cx: '364.9', cy: '42.5' },
  { cx: '364.9', cy: '232.4' },
  { cx: '364.9', cy: '239.2' },
  { cx: '364.9', cy: '246' },
  { cx: '364.9', cy: '252.7' },
  { cx: '364.9', cy: '259.5' },
  { cx: '358.1', cy: '42.5' },
  { cx: '358.1', cy: '49.3' },
  { cx: '358.1', cy: '239.2' },
  { cx: '351.4', cy: '35.7' },
  { cx: '351.4', cy: '42.5' },
  { cx: '351.4', cy: '49.3' },
  { cx: '351.4', cy: '56' },
  { cx: '351.4', cy: '62.8' },
  { cx: '351.4', cy: '69.6' },
  { cx: '351.4', cy: '76.4' },
  { cx: '344.6', cy: '35.7' },
  { cx: '344.6', cy: '42.5' },
  { cx: '344.6', cy: '49.3' },
  { cx: '344.6', cy: '56' },
  { cx: '344.6', cy: '62.8' },
  { cx: '344.6', cy: '69.6' },
  { cx: '344.6', cy: '76.4' },
  { cx: '344.6', cy: '83.2' },
  { cx: '344.6', cy: '90' },
  { cx: '344.6', cy: '96.7' },
  { cx: '337.8', cy: '28.9' },
  { cx: '337.8', cy: '35.7' },
  { cx: '337.8', cy: '42.5' },
  { cx: '337.8', cy: '49.3' },
  { cx: '337.8', cy: '56' },
  { cx: '337.8', cy: '62.8' },
  { cx: '337.8', cy: '69.6' },
  { cx: '337.8', cy: '76.4' },
  { cx: '337.8', cy: '83.2' },
  { cx: '337.8', cy: '90' },
  { cx: '337.8', cy: '96.7' },
  { cx: '331', cy: '28.9' },
  { cx: '331', cy: '35.7' },
  { cx: '331', cy: '42.5' },
  { cx: '331', cy: '49.3' },
  { cx: '331', cy: '56' },
  { cx: '331', cy: '62.8' },
  { cx: '331', cy: '69.6' },
  { cx: '331', cy: '76.4' },
  { cx: '331', cy: '83.2' },
  { cx: '331', cy: '90' },
  { cx: '331', cy: '96.7' },
  { cx: '331', cy: '103.5' },
  { cx: '324.2', cy: '28.9' },
  { cx: '324.2', cy: '35.7' },
  { cx: '324.2', cy: '42.5' },
  { cx: '324.2', cy: '49.3' },
  { cx: '324.2', cy: '56' },
  { cx: '324.2', cy: '62.8' },
  { cx: '324.2', cy: '69.6' },
  { cx: '324.2', cy: '76.4' },
  { cx: '324.2', cy: '83.2' },
  { cx: '324.2', cy: '90' },
  { cx: '324.2', cy: '96.7' },
  { cx: '324.2', cy: '103.5' },
  { cx: '317.5', cy: '28.9' },
  { cx: '317.5', cy: '35.7' },
  { cx: '317.5', cy: '42.5' },
  { cx: '317.5', cy: '49.3' },
  { cx: '317.5', cy: '56' },
  { cx: '317.5', cy: '62.8' },
  { cx: '317.5', cy: '69.6' },
  { cx: '317.5', cy: '76.4' },
  { cx: '317.5', cy: '83.2' },
  { cx: '317.5', cy: '90' },
  { cx: '317.5', cy: '96.7' },
  { cx: '317.5', cy: '103.5' },
  { cx: '317.5', cy: '110.3' },
  { cx: '317.5', cy: '300.2' },
  { cx: '317.5', cy: '307' },
  { cx: '310.7', cy: '28.9' },
  { cx: '310.7', cy: '35.7' },
  { cx: '310.7', cy: '42.5' },
  { cx: '310.7', cy: '49.3' },
  { cx: '310.7', cy: '56' },
  { cx: '310.7', cy: '62.8' },
  { cx: '310.7', cy: '69.6' },
  { cx: '310.7', cy: '76.4' },
  { cx: '310.7', cy: '83.2' },
  { cx: '310.7', cy: '90' },
  { cx: '310.7', cy: '96.7' },
  { cx: '310.7', cy: '103.5' },
  { cx: '310.7', cy: '110.3' },
  { cx: '310.7', cy: '300.2' },
  { cx: '310.7', cy: '307' },
  { cx: '310.7', cy: '313.8' },
  { cx: '310.7', cy: '320.6' },
  { cx: '310.7', cy: '327.3' },
  { cx: '303.9', cy: '35.7' },
  { cx: '303.9', cy: '42.5' },
  { cx: '303.9', cy: '49.3' },
  { cx: '303.9', cy: '56' },
  { cx: '303.9', cy: '62.8' },
  { cx: '303.9', cy: '69.6' },
  { cx: '303.9', cy: '76.4' },
  { cx: '303.9', cy: '83.2' },
  { cx: '303.9', cy: '90' },
  { cx: '303.9', cy: '96.7' },
  { cx: '303.9', cy: '103.5' },
  { cx: '303.9', cy: '110.3' },
  { cx: '303.9', cy: '117.1' },
  { cx: '303.9', cy: '123.9' },
  { cx: '303.9', cy: '300.2' },
  { cx: '303.9', cy: '307' },
  { cx: '303.9', cy: '313.8' },
  { cx: '303.9', cy: '320.6' },
  { cx: '303.9', cy: '327.3' },
  { cx: '303.9', cy: '334.1' },
  { cx: '303.9', cy: '340.9' },
  { cx: '297.1', cy: '35.7' },
  { cx: '297.1', cy: '42.5' },
  { cx: '297.1', cy: '49.3' },
  { cx: '297.1', cy: '56' },
  { cx: '297.1', cy: '62.8' },
  { cx: '297.1', cy: '69.6' },
  { cx: '297.1', cy: '76.4' },
  { cx: '297.1', cy: '83.2' },
  { cx: '297.1', cy: '90' },
  { cx: '297.1', cy: '96.7' },
  { cx: '297.1', cy: '103.5' },
  { cx: '297.1', cy: '110.3' },
  { cx: '297.1', cy: '117.1' },
  { cx: '297.1', cy: '123.9' },
  { cx: '297.1', cy: '130.7' },
  { cx: '297.1', cy: '293.4' },
  { cx: '297.1', cy: '300.2' },
  { cx: '297.1', cy: '307' },
  { cx: '297.1', cy: '313.8' },
  { cx: '297.1', cy: '320.6' },
  { cx: '297.1', cy: '327.3' },
  { cx: '297.1', cy: '334.1' },
  { cx: '297.1', cy: '340.9' },
  { cx: '290.3', cy: '35.7' },
  { cx: '290.3', cy: '42.5' },
  { cx: '290.3', cy: '49.3' },
  { cx: '290.3', cy: '56' },
  { cx: '290.3', cy: '62.8' },
  { cx: '290.3', cy: '69.6' },
  { cx: '290.3', cy: '76.4' },
  { cx: '290.3', cy: '83.2' },
  { cx: '290.3', cy: '90' },
  { cx: '290.3', cy: '96.7' },
  { cx: '290.3', cy: '103.5' },
  { cx: '290.3', cy: '110.3' },
  { cx: '290.3', cy: '117.1' },
  { cx: '290.3', cy: '123.9' },
  { cx: '290.3', cy: '130.7' },
  { cx: '290.3', cy: '293.4' },
  { cx: '290.3', cy: '300.2' },
  { cx: '290.3', cy: '307' },
  { cx: '290.3', cy: '313.8' },
  { cx: '290.3', cy: '320.6' },
  { cx: '290.3', cy: '327.3' },
  { cx: '290.3', cy: '334.1' },
  { cx: '290.3', cy: '340.9' },
  { cx: '290.3', cy: '347.7' },
  { cx: '283.5', cy: '35.7' },
  { cx: '283.5', cy: '42.5' },
  { cx: '283.5', cy: '49.3' },
  { cx: '283.5', cy: '56' },
  { cx: '283.5', cy: '62.8' },
  { cx: '283.5', cy: '69.6' },
  { cx: '283.5', cy: '76.4' },
  { cx: '283.5', cy: '83.2' },
  { cx: '283.5', cy: '90' },
  { cx: '283.5', cy: '96.7' },
  { cx: '283.5', cy: '103.5' },
  { cx: '283.5', cy: '110.3' },
  { cx: '283.5', cy: '117.1' },
  { cx: '283.5', cy: '123.9' },
  { cx: '283.5', cy: '279.9' },
  { cx: '283.5', cy: '286.6' },
  { cx: '283.5', cy: '293.4' },
  { cx: '283.5', cy: '300.2' },
  { cx: '283.5', cy: '307' },
  { cx: '283.5', cy: '313.8' },
  { cx: '283.5', cy: '320.6' },
  { cx: '283.5', cy: '327.3' },
  { cx: '283.5', cy: '334.1' },
  { cx: '283.5', cy: '340.9' },
  { cx: '283.5', cy: '347.7' },
  { cx: '283.5', cy: '354.5' },
  { cx: '283.5', cy: '361.3' },
  { cx: '276.8', cy: '42.5' },
  { cx: '276.8', cy: '49.3' },
  { cx: '276.8', cy: '56' },
  { cx: '276.8', cy: '62.8' },
  { cx: '276.8', cy: '69.6' },
  { cx: '276.8', cy: '76.4' },
  { cx: '276.8', cy: '83.2' },
  { cx: '276.8', cy: '90' },
  { cx: '276.8', cy: '96.7' },
  { cx: '276.8', cy: '103.5' },
  { cx: '276.8', cy: '110.3' },
  { cx: '276.8', cy: '171.3' },
  { cx: '276.8', cy: '279.9' },
  { cx: '276.8', cy: '286.6' },
  { cx: '276.8', cy: '293.4' },
  { cx: '276.8', cy: '300.2' },
  { cx: '276.8', cy: '307' },
  { cx: '276.8', cy: '313.8' },
  { cx: '276.8', cy: '320.6' },
  { cx: '276.8', cy: '327.3' },
  { cx: '276.8', cy: '334.1' },
  { cx: '276.8', cy: '340.9' },
  { cx: '276.8', cy: '347.7' },
  { cx: '276.8', cy: '354.5' },
  { cx: '276.8', cy: '361.3' },
  { cx: '276.8', cy: '368' },
  { cx: '270', cy: '42.5' },
  { cx: '270', cy: '49.3' },
  { cx: '270', cy: '56' },
  { cx: '270', cy: '62.8' },
  { cx: '270', cy: '69.6' },
  { cx: '270', cy: '76.4' },
  { cx: '270', cy: '157.8' },
  { cx: '270', cy: '164.6' },
  { cx: '270', cy: '171.3' },
  { cx: '270', cy: '279.9' },
  { cx: '270', cy: '286.6' },
  { cx: '270', cy: '293.4' },
  { cx: '270', cy: '300.2' },
  { cx: '270', cy: '307' },
  { cx: '270', cy: '313.8' },
  { cx: '270', cy: '320.6' },
  { cx: '270', cy: '327.3' },
  { cx: '270', cy: '334.1' },
  { cx: '270', cy: '340.9' },
  { cx: '270', cy: '347.7' },
  { cx: '270', cy: '354.5' },
  { cx: '270', cy: '361.3' },
  { cx: '270', cy: '368' },
  { cx: '270', cy: '374.8' },
  { cx: '263.2', cy: '42.5' },
  { cx: '263.2', cy: '49.3' },
  { cx: '263.2', cy: '56' },
  { cx: '263.2', cy: '62.8' },
  { cx: '263.2', cy: '69.6' },
  { cx: '263.2', cy: '151' },
  { cx: '263.2', cy: '157.8' },
  { cx: '263.2', cy: '171.3' },
  { cx: '263.2', cy: '273.1' },
  { cx: '263.2', cy: '279.9' },
  { cx: '263.2', cy: '286.6' },
  { cx: '263.2', cy: '293.4' },
  { cx: '263.2', cy: '300.2' },
  { cx: '263.2', cy: '307' },
  { cx: '263.2', cy: '313.8' },
  { cx: '263.2', cy: '320.6' },
  { cx: '263.2', cy: '327.3' },
  { cx: '263.2', cy: '334.1' },
  { cx: '263.2', cy: '340.9' },
  { cx: '263.2', cy: '347.7' },
  { cx: '263.2', cy: '354.5' },
  { cx: '263.2', cy: '361.3' },
  { cx: '263.2', cy: '368' },
  { cx: '263.2', cy: '374.8' },
  { cx: '263.2', cy: '381.6' },
  { cx: '256.4', cy: '35.7' },
  { cx: '256.4', cy: '42.5' },
  { cx: '256.4', cy: '49.3' },
  { cx: '256.4', cy: '56' },
  { cx: '256.4', cy: '62.8' },
  { cx: '256.4', cy: '69.6' },
  { cx: '256.4', cy: '110.3' },
  { cx: '256.4', cy: '144.2' },
  { cx: '256.4', cy: '151' },
  { cx: '256.4', cy: '157.8' },
  { cx: '256.4', cy: '178.1' },
  { cx: '256.4', cy: '266.3' },
  { cx: '256.4', cy: '273.1' },
  { cx: '256.4', cy: '279.9' },
  { cx: '256.4', cy: '286.6' },
  { cx: '256.4', cy: '293.4' },
  { cx: '256.4', cy: '300.2' },
  { cx: '256.4', cy: '307' },
  { cx: '256.4', cy: '313.8' },
  { cx: '256.4', cy: '320.6' },
  { cx: '256.4', cy: '327.3' },
  { cx: '256.4', cy: '334.1' },
  { cx: '256.4', cy: '340.9' },
  { cx: '256.4', cy: '347.7' },
  { cx: '256.4', cy: '354.5' },
  { cx: '256.4', cy: '361.3' },
  { cx: '256.4', cy: '368' },
  { cx: '256.4', cy: '374.8' },
  { cx: '256.4', cy: '381.6' },
  { cx: '256.4', cy: '388.4' },
  { cx: '249.6', cy: '35.7' },
  { cx: '249.6', cy: '42.5' },
  { cx: '249.6', cy: '49.3' },
  { cx: '249.6', cy: '56' },
  { cx: '249.6', cy: '62.8' },
  { cx: '249.6', cy: '69.6' },
  { cx: '249.6', cy: '103.5' },
  { cx: '249.6', cy: '110.3' },
  { cx: '249.6', cy: '117.1' },
  { cx: '249.6', cy: '123.9' },
  { cx: '249.6', cy: '137.4' },
  { cx: '249.6', cy: '144.2' },
  { cx: '249.6', cy: '151' },
  { cx: '249.6', cy: '157.8' },
  { cx: '249.6', cy: '171.3' },
  { cx: '249.6', cy: '178.1' },
  { cx: '249.6', cy: '246' },
  { cx: '249.6', cy: '266.3' },
  { cx: '249.6', cy: '273.1' },
  { cx: '249.6', cy: '279.9' },
  { cx: '249.6', cy: '286.6' },
  { cx: '249.6', cy: '293.4' },
  { cx: '249.6', cy: '300.2' },
  { cx: '249.6', cy: '307' },
  { cx: '249.6', cy: '313.8' },
  { cx: '249.6', cy: '320.6' },
  { cx: '249.6', cy: '327.3' },
  { cx: '249.6', cy: '334.1' },
  { cx: '249.6', cy: '340.9' },
  { cx: '249.6', cy: '347.7' },
  { cx: '249.6', cy: '354.5' },
  { cx: '249.6', cy: '361.3' },
  { cx: '249.6', cy: '368' },
  { cx: '249.6', cy: '374.8' },
  { cx: '249.6', cy: '381.6' },
  { cx: '249.6', cy: '388.4' },
  { cx: '249.6', cy: '395.2' },
  { cx: '249.6', cy: '429.1' },
  { cx: '242.8', cy: '35.7' },
  { cx: '242.8', cy: '42.5' },
  { cx: '242.8', cy: '56' },
  { cx: '242.8', cy: '62.8' },
  { cx: '242.8', cy: '69.6' },
  { cx: '242.8', cy: '96.7' },
  { cx: '242.8', cy: '103.5' },
  { cx: '242.8', cy: '110.3' },
  { cx: '242.8', cy: '117.1' },
  { cx: '242.8', cy: '123.9' },
  { cx: '242.8', cy: '137.4' },
  { cx: '242.8', cy: '144.2' },
  { cx: '242.8', cy: '151' },
  { cx: '242.8', cy: '157.8' },
  { cx: '242.8', cy: '164.6' },
  { cx: '242.8', cy: '171.3' },
  { cx: '242.8', cy: '178.1' },
  { cx: '242.8', cy: '246' },
  { cx: '242.8', cy: '266.3' },
  { cx: '242.8', cy: '273.1' },
  { cx: '242.8', cy: '279.9' },
  { cx: '242.8', cy: '286.6' },
  { cx: '242.8', cy: '293.4' },
  { cx: '242.8', cy: '300.2' },
  { cx: '242.8', cy: '307' },
  { cx: '242.8', cy: '313.8' },
  { cx: '242.8', cy: '320.6' },
  { cx: '242.8', cy: '327.3' },
  { cx: '242.8', cy: '334.1' },
  { cx: '242.8', cy: '340.9' },
  { cx: '242.8', cy: '347.7' },
  { cx: '242.8', cy: '354.5' },
  { cx: '242.8', cy: '361.3' },
  { cx: '242.8', cy: '368' },
  { cx: '242.8', cy: '374.8' },
  { cx: '242.8', cy: '381.6' },
  { cx: '242.8', cy: '388.4' },
  { cx: '242.8', cy: '395.2' },
  { cx: '242.8', cy: '402' },
  { cx: '242.8', cy: '408.7' },
  { cx: '242.8', cy: '422.3' },
  { cx: '242.8', cy: '429.1' },
  { cx: '236.1', cy: '35.7' },
  { cx: '236.1', cy: '42.5' },
  { cx: '236.1', cy: '49.3' },
  { cx: '236.1', cy: '56' },
  { cx: '236.1', cy: '62.8' },
  { cx: '236.1', cy: '90' },
  { cx: '236.1', cy: '96.7' },
  { cx: '236.1', cy: '103.5' },
  { cx: '236.1', cy: '110.3' },
  { cx: '236.1', cy: '117.1' },
  { cx: '236.1', cy: '130.7' },
  { cx: '236.1', cy: '137.4' },
  { cx: '236.1', cy: '144.2' },
  { cx: '236.1', cy: '151' },
  { cx: '236.1', cy: '157.8' },
  { cx: '236.1', cy: '164.6' },
  { cx: '236.1', cy: '171.3' },
  { cx: '236.1', cy: '178.1' },
  { cx: '236.1', cy: '184.9' },
  { cx: '236.1', cy: '239.2' },
  { cx: '236.1', cy: '246' },
  { cx: '236.1', cy: '259.5' },
  { cx: '236.1', cy: '266.3' },
  { cx: '236.1', cy: '273.1' },
  { cx: '236.1', cy: '279.9' },
  { cx: '236.1', cy: '286.6' },
  { cx: '236.1', cy: '293.4' },
  { cx: '236.1', cy: '300.2' },
  { cx: '236.1', cy: '307' },
  { cx: '236.1', cy: '313.8' },
  { cx: '236.1', cy: '320.6' },
  { cx: '236.1', cy: '327.3' },
  { cx: '236.1', cy: '354.5' },
  { cx: '236.1', cy: '361.3' },
  { cx: '236.1', cy: '368' },
  { cx: '236.1', cy: '374.8' },
  { cx: '236.1', cy: '381.6' },
  { cx: '236.1', cy: '388.4' },
  { cx: '236.1', cy: '395.2' },
  { cx: '236.1', cy: '402' },
  { cx: '236.1', cy: '408.7' },
  { cx: '236.1', cy: '415.5' },
  { cx: '236.1', cy: '422.3' },
  { cx: '236.1', cy: '429.1' },
  { cx: '229.3', cy: '35.7' },
  { cx: '229.3', cy: '42.5' },
  { cx: '229.3', cy: '49.3' },
  { cx: '229.3', cy: '90' },
  { cx: '229.3', cy: '96.7' },
  { cx: '229.3', cy: '117.1' },
  { cx: '229.3', cy: '123.9' },
  { cx: '229.3', cy: '130.7' },
  { cx: '229.3', cy: '137.4' },
  { cx: '229.3', cy: '144.2' },
  { cx: '229.3', cy: '151' },
  { cx: '229.3', cy: '157.8' },
  { cx: '229.3', cy: '164.6' },
  { cx: '229.3', cy: '171.3' },
  { cx: '229.3', cy: '178.1' },
  { cx: '229.3', cy: '184.9' },
  { cx: '229.3', cy: '191.7' },
  { cx: '229.3', cy: '239.2' },
  { cx: '229.3', cy: '246' },
  { cx: '229.3', cy: '266.3' },
  { cx: '229.3', cy: '273.1' },
  { cx: '229.3', cy: '279.9' },
  { cx: '229.3', cy: '286.6' },
  { cx: '229.3', cy: '293.4' },
  { cx: '229.3', cy: '300.2' },
  { cx: '229.3', cy: '307' },
  { cx: '229.3', cy: '313.8' },
  { cx: '229.3', cy: '320.6' },
  { cx: '229.3', cy: '388.4' },
  { cx: '229.3', cy: '395.2' },
  { cx: '229.3', cy: '402' },
  { cx: '229.3', cy: '408.7' },
  { cx: '229.3', cy: '415.5' },
  { cx: '229.3', cy: '422.3' },
  { cx: '222.5', cy: '35.7' },
  { cx: '222.5', cy: '42.5' },
  { cx: '222.5', cy: '49.3' },
  { cx: '222.5', cy: '56' },
  { cx: '222.5', cy: '90' },
  { cx: '222.5', cy: '96.7' },
  { cx: '222.5', cy: '103.5' },
  { cx: '222.5', cy: '117.1' },
  { cx: '222.5', cy: '123.9' },
  { cx: '222.5', cy: '130.7' },
  { cx: '222.5', cy: '137.4' },
  { cx: '222.5', cy: '151' },
  { cx: '222.5', cy: '157.8' },
  { cx: '222.5', cy: '164.6' },
  { cx: '222.5', cy: '171.3' },
  { cx: '222.5', cy: '178.1' },
  { cx: '222.5', cy: '184.9' },
  { cx: '222.5', cy: '191.7' },
  { cx: '222.5', cy: '198.5' },
  { cx: '222.5', cy: '205.3' },
  { cx: '222.5', cy: '239.2' },
  { cx: '222.5', cy: '246' },
  { cx: '222.5', cy: '266.3' },
  { cx: '222.5', cy: '273.1' },
  { cx: '222.5', cy: '279.9' },
  { cx: '222.5', cy: '286.6' },
  { cx: '222.5', cy: '293.4' },
  { cx: '222.5', cy: '300.2' },
  { cx: '222.5', cy: '307' },
  { cx: '222.5', cy: '313.8' },
  { cx: '215.7', cy: '35.7' },
  { cx: '215.7', cy: '42.5' },
  { cx: '215.7', cy: '49.3' },
  { cx: '215.7', cy: '56' },
  { cx: '215.7', cy: '62.8' },
  { cx: '215.7', cy: '76.4' },
  { cx: '215.7', cy: '83.2' },
  { cx: '215.7', cy: '90' },
  { cx: '215.7', cy: '96.7' },
  { cx: '215.7', cy: '164.6' },
  { cx: '215.7', cy: '171.3' },
  { cx: '215.7', cy: '178.1' },
  { cx: '215.7', cy: '184.9' },
  { cx: '215.7', cy: '191.7' },
  { cx: '215.7', cy: '198.5' },
  { cx: '215.7', cy: '205.3' },
  { cx: '215.7', cy: '212' },
  { cx: '215.7', cy: '218.8' },
  { cx: '215.7', cy: '225.6' },
  { cx: '215.7', cy: '239.2' },
  { cx: '215.7', cy: '266.3' },
  { cx: '215.7', cy: '273.1' },
  { cx: '215.7', cy: '286.6' },
  { cx: '215.7', cy: '293.4' },
  { cx: '215.7', cy: '300.2' },
  { cx: '208.9', cy: '35.7' },
  { cx: '208.9', cy: '42.5' },
  { cx: '208.9', cy: '49.3' },
  { cx: '208.9', cy: '56' },
  { cx: '208.9', cy: '62.8' },
  { cx: '208.9', cy: '76.4' },
  { cx: '208.9', cy: '83.2' },
  { cx: '208.9', cy: '90' },
  { cx: '208.9', cy: '96.7' },
  { cx: '208.9', cy: '103.5' },
  { cx: '208.9', cy: '110.3' },
  { cx: '208.9', cy: '117.1' },
  { cx: '208.9', cy: '123.9' },
  { cx: '208.9', cy: '151' },
  { cx: '208.9', cy: '157.8' },
  { cx: '208.9', cy: '164.6' },
  { cx: '208.9', cy: '171.3' },
  { cx: '208.9', cy: '178.1' },
  { cx: '208.9', cy: '184.9' },
  { cx: '208.9', cy: '191.7' },
  { cx: '208.9', cy: '198.5' },
  { cx: '208.9', cy: '205.3' },
  { cx: '208.9', cy: '212' },
  { cx: '208.9', cy: '218.8' },
  { cx: '208.9', cy: '252.7' },
  { cx: '208.9', cy: '259.5' },
  { cx: '208.9', cy: '266.3' },
  { cx: '202.2', cy: '42.5' },
  { cx: '202.2', cy: '49.3' },
  { cx: '202.2', cy: '56' },
  { cx: '202.2', cy: '62.8' },
  { cx: '202.2', cy: '76.4' },
  { cx: '202.2', cy: '83.2' },
  { cx: '202.2', cy: '90' },
  { cx: '202.2', cy: '96.7' },
  { cx: '202.2', cy: '103.5' },
  { cx: '202.2', cy: '110.3' },
  { cx: '202.2', cy: '117.1' },
  { cx: '202.2', cy: '151' },
  { cx: '202.2', cy: '157.8' },
  { cx: '202.2', cy: '164.6' },
  { cx: '202.2', cy: '171.3' },
  { cx: '202.2', cy: '178.1' },
  { cx: '202.2', cy: '184.9' },
  { cx: '202.2', cy: '191.7' },
  { cx: '202.2', cy: '198.5' },
  { cx: '202.2', cy: '205.3' },
  { cx: '202.2', cy: '212' },
  { cx: '202.2', cy: '218.8' },
  { cx: '202.2', cy: '239.2' },
  { cx: '202.2', cy: '252.7' },
  { cx: '202.2', cy: '259.5' },
  { cx: '202.2', cy: '266.3' },
  { cx: '195.4', cy: '42.5' },
  { cx: '195.4', cy: '49.3' },
  { cx: '195.4', cy: '56' },
  { cx: '195.4', cy: '69.6' },
  { cx: '195.4', cy: '76.4' },
  { cx: '195.4', cy: '83.2' },
  { cx: '195.4', cy: '90' },
  { cx: '195.4', cy: '103.5' },
  { cx: '195.4', cy: '110.3' },
  { cx: '195.4', cy: '117.1' },
  { cx: '195.4', cy: '144.2' },
  { cx: '195.4', cy: '151' },
  { cx: '195.4', cy: '157.8' },
  { cx: '195.4', cy: '164.6' },
  { cx: '195.4', cy: '171.3' },
  { cx: '195.4', cy: '178.1' },
  { cx: '195.4', cy: '184.9' },
  { cx: '195.4', cy: '191.7' },
  { cx: '195.4', cy: '198.5' },
  { cx: '195.4', cy: '205.3' },
  { cx: '195.4', cy: '212' },
  { cx: '195.4', cy: '218.8' },
  { cx: '195.4', cy: '239.2' },
  { cx: '195.4', cy: '246' },
  { cx: '195.4', cy: '252.7' },
  { cx: '195.4', cy: '259.5' },
  { cx: '188.6', cy: '42.5' },
  { cx: '188.6', cy: '49.3' },
  { cx: '188.6', cy: '56' },
  { cx: '188.6', cy: '69.6' },
  { cx: '188.6', cy: '83.2' },
  { cx: '188.6', cy: '96.7' },
  { cx: '188.6', cy: '103.5' },
  { cx: '188.6', cy: '110.3' },
  { cx: '188.6', cy: '117.1' },
  { cx: '188.6', cy: '123.9' },
  { cx: '188.6', cy: '137.4' },
  { cx: '188.6', cy: '144.2' },
  { cx: '188.6', cy: '151' },
  { cx: '188.6', cy: '157.8' },
  { cx: '188.6', cy: '164.6' },
  { cx: '188.6', cy: '171.3' },
  { cx: '188.6', cy: '178.1' },
  { cx: '188.6', cy: '184.9' },
  { cx: '188.6', cy: '191.7' },
  { cx: '188.6', cy: '198.5' },
  { cx: '188.6', cy: '205.3' },
  { cx: '188.6', cy: '212' },
  { cx: '188.6', cy: '218.8' },
  { cx: '188.6', cy: '246' },
  { cx: '188.6', cy: '252.7' },
  { cx: '181.8', cy: '49.3' },
  { cx: '181.8', cy: '62.8' },
  { cx: '181.8', cy: '83.2' },
  { cx: '181.8', cy: '90' },
  { cx: '181.8', cy: '96.7' },
  { cx: '181.8', cy: '103.5' },
  { cx: '181.8', cy: '110.3' },
  { cx: '181.8', cy: '117.1' },
  { cx: '181.8', cy: '123.9' },
  { cx: '181.8', cy: '130.7' },
  { cx: '181.8', cy: '137.4' },
  { cx: '181.8', cy: '144.2' },
  { cx: '181.8', cy: '151' },
  { cx: '181.8', cy: '157.8' },
  { cx: '181.8', cy: '164.6' },
  { cx: '181.8', cy: '171.3' },
  { cx: '181.8', cy: '178.1' },
  { cx: '181.8', cy: '184.9' },
  { cx: '181.8', cy: '191.7' },
  { cx: '181.8', cy: '198.5' },
  { cx: '181.8', cy: '205.3' },
  { cx: '181.8', cy: '212' },
  { cx: '181.8', cy: '218.8' },
  { cx: '181.8', cy: '246' },
  { cx: '181.8', cy: '252.7' },
  { cx: '175', cy: '56' },
  { cx: '175', cy: '69.6' },
  { cx: '175', cy: '83.2' },
  { cx: '175', cy: '90' },
  { cx: '175', cy: '96.7' },
  { cx: '175', cy: '103.5' },
  { cx: '175', cy: '110.3' },
  { cx: '175', cy: '117.1' },
  { cx: '175', cy: '123.9' },
  { cx: '175', cy: '130.7' },
  { cx: '175', cy: '137.4' },
  { cx: '175', cy: '144.2' },
  { cx: '175', cy: '151' },
  { cx: '175', cy: '157.8' },
  { cx: '175', cy: '164.6' },
  { cx: '175', cy: '171.3' },
  { cx: '175', cy: '178.1' },
  { cx: '175', cy: '184.9' },
  { cx: '175', cy: '191.7' },
  { cx: '175', cy: '198.5' },
  { cx: '175', cy: '205.3' },
  { cx: '175', cy: '212' },
  { cx: '175', cy: '218.8' },
  { cx: '175', cy: '225.6' },
  { cx: '175', cy: '232.4' },
  { cx: '175', cy: '239.2' },
  { cx: '175', cy: '246' },
  { cx: '175', cy: '252.7' },
  { cx: '168.2', cy: '49.3' },
  { cx: '168.2', cy: '56' },
  { cx: '168.2', cy: '69.6' },
  { cx: '168.2', cy: '83.2' },
  { cx: '168.2', cy: '96.7' },
  { cx: '168.2', cy: '103.5' },
  { cx: '168.2', cy: '110.3' },
  { cx: '168.2', cy: '117.1' },
  { cx: '168.2', cy: '123.9' },
  { cx: '168.2', cy: '130.7' },
  { cx: '168.2', cy: '137.4' },
  { cx: '168.2', cy: '144.2' },
  { cx: '168.2', cy: '151' },
  { cx: '168.2', cy: '157.8' },
  { cx: '168.2', cy: '164.6' },
  { cx: '168.2', cy: '171.3' },
  { cx: '168.2', cy: '178.1' },
  { cx: '168.2', cy: '184.9' },
  { cx: '168.2', cy: '191.7' },
  { cx: '168.2', cy: '198.5' },
  { cx: '168.2', cy: '205.3' },
  { cx: '168.2', cy: '212' },
  { cx: '168.2', cy: '218.8' },
  { cx: '168.2', cy: '225.6' },
  { cx: '168.2', cy: '232.4' },
  { cx: '168.2', cy: '239.2' },
  { cx: '168.2', cy: '246' },
  { cx: '161.5', cy: '56' },
  { cx: '161.5', cy: '62.8' },
  { cx: '161.5', cy: '69.6' },
  { cx: '161.5', cy: '83.2' },
  { cx: '161.5', cy: '90' },
  { cx: '161.5', cy: '96.7' },
  { cx: '161.5', cy: '103.5' },
  { cx: '161.5', cy: '110.3' },
  { cx: '161.5', cy: '117.1' },
  { cx: '161.5', cy: '123.9' },
  { cx: '161.5', cy: '130.7' },
  { cx: '161.5', cy: '137.4' },
  { cx: '161.5', cy: '144.2' },
  { cx: '161.5', cy: '151' },
  { cx: '161.5', cy: '157.8' },
  { cx: '161.5', cy: '164.6' },
  { cx: '161.5', cy: '171.3' },
  { cx: '161.5', cy: '178.1' },
  { cx: '161.5', cy: '184.9' },
  { cx: '161.5', cy: '191.7' },
  { cx: '161.5', cy: '198.5' },
  { cx: '161.5', cy: '205.3' },
  { cx: '161.5', cy: '212' },
  { cx: '161.5', cy: '218.8' },
  { cx: '161.5', cy: '225.6' },
  { cx: '161.5', cy: '232.4' },
  { cx: '161.5', cy: '239.2' },
  { cx: '161.5', cy: '246' },
  { cx: '154.7', cy: '69.6' },
  { cx: '154.7', cy: '83.2' },
  { cx: '154.7', cy: '90' },
  { cx: '154.7', cy: '96.7' },
  { cx: '154.7', cy: '103.5' },
  { cx: '154.7', cy: '110.3' },
  { cx: '154.7', cy: '117.1' },
  { cx: '154.7', cy: '123.9' },
  { cx: '154.7', cy: '130.7' },
  { cx: '154.7', cy: '137.4' },
  { cx: '154.7', cy: '144.2' },
  { cx: '154.7', cy: '151' },
  { cx: '154.7', cy: '157.8' },
  { cx: '154.7', cy: '164.6' },
  { cx: '154.7', cy: '171.3' },
  { cx: '154.7', cy: '178.1' },
  { cx: '154.7', cy: '184.9' },
  { cx: '154.7', cy: '191.7' },
  { cx: '154.7', cy: '198.5' },
  { cx: '154.7', cy: '205.3' },
  { cx: '154.7', cy: '212' },
  { cx: '154.7', cy: '218.8' },
  { cx: '154.7', cy: '225.6' },
  { cx: '154.7', cy: '232.4' },
  { cx: '147.9', cy: '56' },
  { cx: '147.9', cy: '62.8' },
  { cx: '147.9', cy: '69.6' },
  { cx: '147.9', cy: '90' },
  { cx: '147.9', cy: '96.7' },
  { cx: '147.9', cy: '110.3' },
  { cx: '147.9', cy: '117.1' },
  { cx: '147.9', cy: '123.9' },
  { cx: '147.9', cy: '130.7' },
  { cx: '147.9', cy: '137.4' },
  { cx: '147.9', cy: '144.2' },
  { cx: '147.9', cy: '151' },
  { cx: '147.9', cy: '157.8' },
  { cx: '147.9', cy: '164.6' },
  { cx: '147.9', cy: '171.3' },
  { cx: '147.9', cy: '178.1' },
  { cx: '147.9', cy: '184.9' },
  { cx: '147.9', cy: '191.7' },
  { cx: '147.9', cy: '198.5' },
  { cx: '147.9', cy: '205.3' },
  { cx: '147.9', cy: '212' },
  { cx: '147.9', cy: '218.8' },
  { cx: '147.9', cy: '225.6' },
  { cx: '147.9', cy: '232.4' },
  { cx: '141.1', cy: '62.8' },
  { cx: '141.1', cy: '69.6' },
  { cx: '141.1', cy: '76.4' },
  { cx: '141.1', cy: '90' },
  { cx: '141.1', cy: '96.7' },
  { cx: '141.1', cy: '110.3' },
  { cx: '141.1', cy: '117.1' },
  { cx: '141.1', cy: '123.9' },
  { cx: '141.1', cy: '130.7' },
  { cx: '141.1', cy: '137.4' },
  { cx: '141.1', cy: '144.2' },
  { cx: '141.1', cy: '151' },
  { cx: '141.1', cy: '157.8' },
  { cx: '141.1', cy: '164.6' },
  { cx: '141.1', cy: '171.3' },
  { cx: '141.1', cy: '178.1' },
  { cx: '141.1', cy: '184.9' },
  { cx: '141.1', cy: '191.7' },
  { cx: '141.1', cy: '198.5' },
  { cx: '141.1', cy: '205.3' },
  { cx: '141.1', cy: '212' },
  { cx: '141.1', cy: '218.8' },
  { cx: '141.1', cy: '225.6' },
  { cx: '134.3', cy: '62.8' },
  { cx: '134.3', cy: '69.6' },
  { cx: '134.3', cy: '83.2' },
  { cx: '134.3', cy: '90' },
  { cx: '134.3', cy: '96.7' },
  { cx: '134.3', cy: '103.5' },
  { cx: '134.3', cy: '110.3' },
  { cx: '134.3', cy: '117.1' },
  { cx: '134.3', cy: '123.9' },
  { cx: '134.3', cy: '130.7' },
  { cx: '134.3', cy: '137.4' },
  { cx: '134.3', cy: '144.2' },
  { cx: '134.3', cy: '151' },
  { cx: '134.3', cy: '157.8' },
  { cx: '134.3', cy: '164.6' },
  { cx: '134.3', cy: '171.3' },
  { cx: '134.3', cy: '178.1' },
  { cx: '134.3', cy: '184.9' },
  { cx: '134.3', cy: '191.7' },
  { cx: '134.3', cy: '198.5' },
  { cx: '134.3', cy: '205.3' },
  { cx: '134.3', cy: '212' },
  { cx: '127.5', cy: '62.8' },
  { cx: '127.5', cy: '69.6' },
  { cx: '127.5', cy: '83.2' },
  { cx: '127.5', cy: '90' },
  { cx: '127.5', cy: '103.5' },
  { cx: '127.5', cy: '110.3' },
  { cx: '127.5', cy: '117.1' },
  { cx: '127.5', cy: '123.9' },
  { cx: '127.5', cy: '130.7' },
  { cx: '127.5', cy: '137.4' },
  { cx: '127.5', cy: '144.2' },
  { cx: '127.5', cy: '151' },
  { cx: '127.5', cy: '157.8' },
  { cx: '127.5', cy: '164.6' },
  { cx: '127.5', cy: '171.3' },
  { cx: '127.5', cy: '178.1' },
  { cx: '127.5', cy: '184.9' },
  { cx: '127.5', cy: '191.7' },
  { cx: '127.5', cy: '198.5' },
  { cx: '127.5', cy: '205.3' },
  { cx: '120.8', cy: '69.6' },
  { cx: '120.8', cy: '76.4' },
  { cx: '120.8', cy: '83.2' },
  { cx: '120.8', cy: '90' },
  { cx: '120.8', cy: '96.7' },
  { cx: '120.8', cy: '103.5' },
  { cx: '120.8', cy: '110.3' },
  { cx: '120.8', cy: '117.1' },
  { cx: '120.8', cy: '123.9' },
  { cx: '120.8', cy: '130.7' },
  { cx: '120.8', cy: '137.4' },
  { cx: '120.8', cy: '144.2' },
  { cx: '120.8', cy: '151' },
  { cx: '120.8', cy: '157.8' },
  { cx: '120.8', cy: '164.6' },
  { cx: '120.8', cy: '171.3' },
  { cx: '120.8', cy: '178.1' },
  { cx: '120.8', cy: '184.9' },
  { cx: '120.8', cy: '191.7' },
  { cx: '120.8', cy: '198.5' },
  { cx: '114', cy: '83.2' },
  { cx: '114', cy: '90' },
  { cx: '114', cy: '96.7' },
  { cx: '114', cy: '103.5' },
  { cx: '114', cy: '110.3' },
  { cx: '114', cy: '117.1' },
  { cx: '114', cy: '123.9' },
  { cx: '114', cy: '130.7' },
  { cx: '114', cy: '137.4' },
  { cx: '114', cy: '144.2' },
  { cx: '114', cy: '151' },
  { cx: '114', cy: '157.8' },
  { cx: '114', cy: '164.6' },
  { cx: '107.2', cy: '96.7' },
  { cx: '107.2', cy: '103.5' },
  { cx: '107.2', cy: '110.3' },
  { cx: '107.2', cy: '117.1' },
  { cx: '107.2', cy: '123.9' },
  { cx: '107.2', cy: '130.7' },
  { cx: '107.2', cy: '137.4' },
  { cx: '107.2', cy: '144.2' },
  { cx: '107.2', cy: '151' },
  { cx: '107.2', cy: '157.8' },
  { cx: '107.2', cy: '164.6' },
  { cx: '100.4', cy: '96.7' },
  { cx: '100.4', cy: '103.5' },
  { cx: '100.4', cy: '110.3' },
  { cx: '100.4', cy: '117.1' },
  { cx: '100.4', cy: '123.9' },
  { cx: '100.4', cy: '130.7' },
  { cx: '100.4', cy: '137.4' },
  { cx: '100.4', cy: '144.2' },
  { cx: '100.4', cy: '151' },
  { cx: '93.6', cy: '103.5' },
  { cx: '93.6', cy: '110.3' },
  { cx: '93.6', cy: '117.1' },
  { cx: '93.6', cy: '123.9' },
  { cx: '93.6', cy: '130.7' },
  { cx: '93.6', cy: '137.4' },
  { cx: '93.6', cy: '144.2' },
  { cx: '93.6', cy: '151' },
  { cx: '86.9', cy: '103.5' },
  { cx: '86.9', cy: '110.3' },
  { cx: '86.9', cy: '117.1' },
  { cx: '86.9', cy: '123.9' },
  { cx: '86.9', cy: '130.7' },
  { cx: '86.9', cy: '137.4' },
  { cx: '80.1', cy: '96.7' },
  { cx: '80.1', cy: '103.5' },
  { cx: '80.1', cy: '110.3' },
  { cx: '80.1', cy: '117.1' },
  { cx: '80.1', cy: '123.9' },
  { cx: '80.1', cy: '130.7' },
  { cx: '73.3', cy: '96.7' },
  { cx: '73.3', cy: '103.5' },
  { cx: '73.3', cy: '110.3' },
  { cx: '73.3', cy: '117.1' },
  { cx: '73.3', cy: '123.9' },
  { cx: '73.3', cy: '130.7' },
  { cx: '66.5', cy: '96.7' },
  { cx: '66.5', cy: '103.5' },
  { cx: '66.5', cy: '110.3' },
  { cx: '66.5', cy: '117.1' },
  { cx: '66.5', cy: '123.9' },
  { cx: '66.5', cy: '130.7' },
  { cx: '59.7', cy: '96.7' },
  { cx: '59.7', cy: '103.5' },
  { cx: '59.7', cy: '110.3' },
  { cx: '59.7', cy: '117.1' },
  { cx: '59.7', cy: '123.9' },
  { cx: '59.7', cy: '130.7' },
  { cx: '52.9', cy: '96.7' },
  { cx: '52.9', cy: '103.5' },
  { cx: '52.9', cy: '110.3' },
  { cx: '52.9', cy: '117.1' },
  { cx: '52.9', cy: '123.9' },
  { cx: '52.9', cy: '130.7' },
  { cx: '52.9', cy: '137.4' },
  { cx: '46.2', cy: '90' },
  { cx: '46.2', cy: '96.7' },
  { cx: '46.2', cy: '103.5' },
  { cx: '46.2', cy: '110.3' },
  { cx: '46.2', cy: '117.1' },
  { cx: '46.2', cy: '123.9' },
  { cx: '46.2', cy: '130.7' },
  { cx: '46.2', cy: '137.4' },
  { cx: '46.2', cy: '144.2' },
  { cx: '39.4', cy: '90' },
  { cx: '39.4', cy: '96.7' },
  { cx: '39.4', cy: '103.5' },
  { cx: '39.4', cy: '110.3' },
  { cx: '39.4', cy: '117.1' },
  { cx: '39.4', cy: '123.9' },
  { cx: '39.4', cy: '130.7' },
  { cx: '39.4', cy: '137.4' },
  { cx: '39.4', cy: '144.2' },
  { cx: '32.6', cy: '96.7' },
  { cx: '32.6', cy: '103.5' },
  { cx: '32.6', cy: '110.3' },
  { cx: '32.6', cy: '117.1' },
  { cx: '32.6', cy: '123.9' },
  { cx: '32.6', cy: '130.7' },
  { cx: '32.6', cy: '137.4' },
  { cx: '32.6', cy: '144.2' },
  { cx: '32.6', cy: '151' },
  { cx: '25.8', cy: '103.5' },
  { cx: '25.8', cy: '110.3' },
  { cx: '25.8', cy: '117.1' },
  { cx: '25.8', cy: '123.9' },
  { cx: '25.8', cy: '130.7' },
  { cx: '25.8', cy: '151' },
  { cx: '19', cy: '103.5' },
  { cx: '19', cy: '110.3' },
  { cx: '19', cy: '117.1' },
  { cx: '19', cy: '151' },
  { cx: '19', cy: '157.8' },
];
