export const CircleCrossIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    className="fill-current text-[#E22134]"
    {...props}
  >
    <circle
      cx="8"
      cy="8"
      r="7.25"
      stroke="currentColor"
      strokeWidth="1.5"
      fill="transparent"
    />
    <path
      d="M10.8233 9.97633C11.0577 10.2107 11.0577 10.5903 10.8233 10.8247C10.7071 10.9419 10.5533 11 10.3996 11C10.2458 11 10.0925 10.9414 9.97549 10.8242L7.99977 8.84954L6.02423 10.8238C5.90705 10.9419 5.7535 11 5.59995 11C5.4464 11 5.29304 10.9419 5.17577 10.8238C4.94141 10.5894 4.94141 10.2097 5.17577 9.97539L7.15186 7.9993L5.17577 6.02414C4.94141 5.78978 4.94141 5.41012 5.17577 5.17577C5.41012 4.94141 5.78978 4.94141 6.02414 5.17577L7.99977 7.1528L9.97586 5.17671C10.2102 4.94235 10.5899 4.94235 10.8242 5.17671C11.0586 5.41106 11.0586 5.79072 10.8242 6.02508L8.84814 8.00117L10.8233 9.97633Z"
      fill="currentColor"
    />
  </svg>
);
