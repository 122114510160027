import classNames from 'classnames';
import { ChevronDownIcon } from '../../../../assets/icons';

export const DropDownIcon = ({
  open,
  disabled,
}: {
  open?: boolean;
  disabled?: boolean;
}) => (
  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
    <ChevronDownIcon
      className={classNames(
        'h-7 w-7 transition',
        open && 'rotate-180',
        !disabled && 'text-tw-main-text dark:text-tw-main-text-dark',
        disabled && 'text-tw-main-text/10 dark:text-tw-main-text-dark/10',
      )}
      aria-hidden="true"
    />
  </span>
);
