import React from 'react';

export const DiscDriveIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        fill="currentColor"
        d="M80 96V432H432V96c0-8.8-7.2-16-16-16H96c-8.8 0-16 7.2-16 16zm400 0V432h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H456 56 24c-13.3 0-24-10.7-24-24s10.7-24 24-24h8V96c0-35.3 28.7-64 64-64H416c35.3 0 64 28.7 64 64zM112 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm176 0a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
      />
    </svg>
  );
};

export default DiscDriveIcon;
