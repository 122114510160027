import { Session } from '@ory/kratos-client';
import { useAppSelector } from '../../redux/hooks';
import { Role } from '../../redux/login/loginSlice';

export const useSession = () => {
  const session: Session | null = useAppSelector(
    (state) => state.login.session,
  );
  const flowFulfilled = useAppSelector((state) => state.login.flowFulfilled);
  const recovery =
    session?.active &&
    session?.authentication_methods &&
    (session?.authentication_methods[0].method === 'link_recovery' ||
      session?.authentication_methods[0].method === 'code_recovery');
  const auth =
    process.env.REACT_APP_USER_AUTH === 'true' ||
    (session?.active && !recovery);

  const { currentTenant, logoutToken } = useAppSelector((state) => state.login);

  const tenants_info = useAppSelector(
    (state) => state.login.session?.identity?.metadata_public?.tenants_info,
  );

  const isAdmin = currentTenant?.roles?.includes(Role.ORG_ADMIN);

  const isSuperAdmin = currentTenant?.roles?.includes(Role.PL_ADMIN);

  const skip = !auth || !flowFulfilled || !currentTenant;

  return {
    session,
    auth,
    isAdmin,
    isSuperAdmin,
    recovery,
    flowFulfilled,
    currentTenant,
    tenants_info,
    logoutToken,
    skip,
  };
};
