import React from 'react';

export const RealtimePerformanceIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M11.8476 17.083C12.0351 17.3006 12.3392 17.3006 12.5268 17.083L14.0791 15.2827L16.621 18.2313C16.8086 18.4489 17.1126 18.4489 17.3002 18.2313L20.6084 14.3935L21.4935 15.4206C21.9473 15.947 22.7231 15.5741 22.7231 14.8297V11.3157C22.7231 11.0079 22.5082 10.7587 22.2429 10.7587H19.2136C18.5719 10.7587 18.2505 11.6587 18.7043 12.1851L19.5897 13.2118L16.9604 16.2617L14.4186 13.3131C14.231 13.0955 13.9269 13.0955 13.7393 13.3131L11.5081 15.9014C11.3205 16.119 11.3205 16.4717 11.5081 16.6893L11.8476 17.083ZM23.2034 21.3428H9.75708V10.2016C9.75708 9.89382 9.54218 9.64453 9.27686 9.64453H8.79663C8.53131 9.64453 8.31641 9.89382 8.31641 10.2016V21.8999C8.31641 22.5151 8.74651 23.014 9.27686 23.014H23.2034C23.4687 23.014 23.6836 22.7647 23.6836 22.4569V21.8999C23.6836 21.5921 23.4687 21.3428 23.2034 21.3428Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RealtimePerformanceIcon;
