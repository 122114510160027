/**
 *
 * @param score number from 0 to 1
 * @returns
 */
export const getCoverageColor = (score: number) => {
  if (score < 0.3) return '#e22134';
  if (score < 0.5) return '#f90';
  if (score < 0.7) return '#2a63ef';
  return '#20b782';
};
