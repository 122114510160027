import React from 'react';

export const DataProtectionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M6.05625 8.55572C5.88184 8.3813 5.5998 8.3813 5.42539 8.552L4.58301 9.38697C4.40859 9.56138 4.40859 9.84341 4.5793 10.0178L7.94883 13.4133C8.12324 13.5877 8.40527 13.5877 8.57969 13.417L14.9848 7.06392C15.1592 6.88951 15.1592 6.60747 14.9885 6.43306L14.1535 5.59068C13.9791 5.41626 13.6971 5.41626 13.5227 5.58697L8.27539 10.7897L6.05625 8.55572ZM17.3115 3.10806L10.1865 0.139309C9.96949 0.0492739 9.73682 0.00292969 9.50185 0.00292969C9.26688 0.00292969 9.03422 0.0492739 8.81719 0.139309L1.69219 3.10806C1.02793 3.38267 0.59375 4.03208 0.59375 4.752C0.59375 12.1182 4.84277 17.2096 8.81348 18.8647C9.25137 19.0465 9.74492 19.0465 10.1828 18.8647C13.3631 17.5399 18.4062 12.9643 18.4062 4.752C18.4062 4.03208 17.9721 3.38267 17.3115 3.10806ZM9.5 17.2208C5.88184 15.7141 2.375 11.0346 2.375 4.752L9.5 1.78325L16.625 4.752C16.625 11.2016 12.9734 15.7735 9.5 17.2208Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DataProtectionIcon;
