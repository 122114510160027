import React from 'react';

export const CapacityReportIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M14.0156 3.7795L10.8364 0.543896C10.4953 0.196729 10.033 0 9.55206 0H1.8187C0.814796 0.00385742 0 0.833203 0 1.85503V17.8988C0 18.9207 0.814796 19.75 1.8187 19.75H12.7309C13.7352 19.75 14.5526 18.9207 14.5526 17.8988V5.09064C14.5526 4.60113 14.3567 4.12667 14.0156 3.7795ZM9.69986 2.00547L12.5835 4.9402H9.69986V2.00547ZM12.7336 17.8988H1.8187V1.85503H7.88116V5.86598C7.88116 6.37902 8.28666 6.79138 8.7907 6.79138H12.7336V17.8988ZM6.0636 9.9371C4.67958 10.2229 3.63816 11.4677 3.63816 12.9609C3.63816 14.6651 4.99565 16.0469 6.66996 16.0469C8.13659 16.0469 9.35954 14.9865 9.64074 13.5781H6.0636V9.9371ZM7.27632 8.70273V12.3438H10.8535C10.894 12.1406 10.9145 11.9339 10.9145 11.7266C10.9145 10.0224 9.55699 8.64062 7.88268 8.64062C7.675 8.64062 7.47225 8.66223 7.27632 8.70273Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CapacityReportIcon;
