import { LoginFlow, UiNodeInputAttributes, UiText } from '@ory/kratos-client';
import { updateLogin } from '../redux/login/loginSlice';
import { updateProductsFilterAppliance } from '../redux/products/filterSlice';
import { ory } from './config';
import { messagesFromData } from './utils';

export const initializeLoginFlow = async () => {
  const { data: loginFlow } = await ory.frontend.createBrowserLoginFlow();
  return loginFlow;
};

export const submitLoginWithFlow = async (
  loginFlow: LoginFlow | undefined,
  identifier: string,
  password: string,
  dispatch: any,
) => {
  let messages: UiText[] = [];
  try {
    if (loginFlow) {
      dispatch(
        updateLogin({
          flowFulfilled: false,
        }),
      );
      await ory.frontend
        .updateLoginFlow({
          flow: loginFlow.id,
          updateLoginFlowBody: {
            csrf_token: (
              loginFlow.ui.nodes[0].attributes as UiNodeInputAttributes
            ).value,
            identifier,
            method: 'password',
            password,
          },
        })
        .then((r) => {
          ory.frontend.createBrowserLogoutFlow().then(({ data: tokenData }) => {
            //This sets the Appliance Filter to empty so that the redux state is cleared.
            // It will cause the appliance filter to be reset to its default state when a new login occurs
            dispatch(updateProductsFilterAppliance([]));
            dispatch(
              updateLogin({
                id: null,
                email: null,
                company: null,
                token: null,
                session: r.data.session,
                logoutToken: tokenData.logout_token,
                flowFulfilled: true,
              }),
            );
          });
        })
        .catch((e) => {
          dispatch(
            updateLogin({
              flowFulfilled: true,
            }),
          );
          const data = e.response.data;
          messages = messagesFromData(data);
          throw new Error('Registration flow submit failed');
        });
    }
  } catch (error) {
    dispatch(
      updateLogin({
        flowFulfilled: true,
      }),
    );
    return {
      success: false,
      messages,
    };
  }

  return {
    success: true,
    messages,
  };
};
