import React from 'react';

export const ProtectionRecoveryIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M12.9902 15.7668C12.8378 15.6144 12.5913 15.6144 12.4389 15.7636L11.7028 16.4932C11.5504 16.6456 11.5504 16.8921 11.6996 17.0445L14.644 20.0117C14.7965 20.1641 15.0429 20.1641 15.1953 20.0149L20.7924 14.4632C20.9448 14.3108 20.9448 14.0644 20.7957 13.9119L20.066 13.1758C19.9136 13.0234 19.6672 13.0234 19.5147 13.1726L14.9294 17.719L12.9902 15.7668ZM22.8257 11.0064L16.5995 8.41214C16.4098 8.33347 16.2065 8.29297 16.0012 8.29297C15.7958 8.29297 15.5925 8.33347 15.4029 8.41214L9.17667 11.0064C8.59621 11.2464 8.2168 11.8139 8.2168 12.443C8.2168 18.8799 11.9298 23.3291 15.3996 24.7754C15.7823 24.9343 16.2136 24.9343 16.5962 24.7754C19.3753 23.6177 23.7823 19.6193 23.7823 12.443C23.7823 11.8139 23.4029 11.2464 22.8257 11.0064ZM15.9995 23.3388C12.8378 22.0222 9.77335 17.933 9.77335 12.443L15.9995 9.84871L22.2257 12.443C22.2257 18.079 19.0348 22.0741 15.9995 23.3388Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ProtectionRecoveryIcon;
