import React from 'react';

export const ChevronDownIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M12.0004 12.1705L9.54039 9.71047C9.15039 9.32047 8.52039 9.32047 8.13039 9.71047C7.74039 10.1005 7.74039 10.7305 8.13039 11.1205L11.3004 14.3005C11.6904 14.6905 12.3204 14.6905 12.7104 14.3005L15.8804 11.1305C16.2704 10.7405 16.2704 10.1105 15.8804 9.72047C15.4904 9.33047 14.8604 9.33047 14.4704 9.72047L12.0004 12.1705Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronDownIcon;
