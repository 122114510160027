import { useState } from 'react';
import { Switch } from '@headlessui/react';

import { ToggleMoonIcon } from '../../../assets/icons/ToggleMoonIcon';
import { ToggleSunIcon } from '../../../assets/icons/ToggleSunIcon';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { changeTheme } from '../../../redux/theme/themeSlice';

export function ModeSwitch() {
  const theme = useAppSelector((state) => state.theme.value);
  const [enabled, setEnabled] = useState(theme === 'light');
  const dispatch = useAppDispatch();

  const handleSwitch = () => {
    setEnabled((p) => !p);
    dispatch(changeTheme());
  };

  return (
    <Switch
      data-testid="themeSwitch-button"
      aria-label="themeSwitch"
      checked={enabled}
      onChange={handleSwitch}
      className={
        'relative inline-flex h-[28px] w-[69px] items-center rounded-full bg-[#dedede] dark:bg-tw-strong-shade-dark'
      }
    >
      <div
        className={
          'absolute left-[0.12rem] z-0 inline-block h-[24px] w-[32px] transform rounded-full bg-white transition dark:translate-x-[33px] dark:bg-tw-light-shade-dark'
        }
      />
      <div className="z-10 m-[1px] flex h-[24px] w-[32px] items-center justify-center">
        <ToggleSunIcon className="h-[16px] w-[16px] text-[#4B4B4B] transition dark:text-[#737882]" />
      </div>
      <div className="z-10 m-[1px] flex h-[24px] w-[32px] items-center justify-center">
        <ToggleMoonIcon className="h-[16px] w-[16px] text-[#8f8f8f] transition dark:text-white" />
      </div>
    </Switch>
  );
}
