import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import PrimaryButton from '../../Buttons/PrimaryButton';
import Spinner from '../../Elements/Spinner/Spinner';
import { AccordionItem, AccordionMenu } from '../AccordionMenu/AccordionMenu';
import SearchInput from '../fields/SearchInput/SearchInput';
import { DynamicForm, DynamicFormProps } from './DynamicForm';
import { FormFields, FormFieldValue } from './types';
import {
  filterFormFieldsByText,
  findFormFieldsById,
  formFieldsToNav,
  updateFormFieldsValueByPath,
  validateFields,
} from './utils';

export const DynamicFormWithNav = ({
  formFields,
  onChange,
  customValidation,
  liveValidate,
  hasActions,
  onSubmit = async () => {
    return {
      saved: true,
    };
  },
  accordion = true,
  search = true,
}: DynamicFormProps & {
  hasActions?: boolean;
  onSubmit?: (values: FormFields) => Promise<{
    saved: boolean;
  }>;
  accordion?: boolean;
  search?: boolean;
}) => {
  const [formFieldsChanged, setFormFieldsChanged] = useState([...formFields]);
  const [selectedFields, setSelectedFields] =
    useState<FormFields>(formFieldsChanged);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentTarget, setCurrentTarget] =
    useState<AccordionItem<FormFields>>();
  const [accordionMenuItems, setAccordionMenuItems] = useState<
    AccordionItem<FormFields>[]
  >(formFieldsToNav(formFieldsChanged));
  const [submitting, setSubmitting] = useState(false);

  const [filteredFormFields, setFilteredFormFields] =
    useState<FormFields>(selectedFields);

  const handleOnSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    const filteredFormFields = filterFormFieldsByText(
      formFieldsChanged,
      searchTerm,
    );
    setFilteredFormFields(filteredFormFields);
    setAccordionMenuItems(formFieldsToNav(filteredFormFields));
    setSelectedFields(filteredFormFields);
    setCurrentTarget(undefined);
  };

  const [touched, setTouched] = useState(false);

  const validate = useMemo(
    () => customValidation || validateFields,
    [customValidation],
  );

  const handleChange = ({
    id,
    value,
    pathArray,
  }: {
    id: string;
    value: FormFieldValue;
    data: FormFields;
    pathArray?: string[];
  }) => {
    setTouched(true);
    const newFormData = updateFormFieldsValueByPath(
      formFieldsChanged,
      id,
      pathArray,
      value,
    );
    const validationResult = liveValidate
      ? handleValidation(newFormData)
      : undefined;

    const result =
      liveValidate && validationResult
        ? validationResult?.validatedFormFields
        : newFormData;
    onChange &&
      onChange({
        id,
        value,
        data: result,
        pathArray,
        ...(validationResult && { isValid: validationResult.isValid }),
      });
    !liveValidate &&
      setAccordionMenuItems(
        formFieldsToNav(filterFormFieldsByText(result, searchTerm)),
      );

    setFormFieldsChanged(result);
  };

  const handleReset = () => {
    setFilteredFormFields(formFields);
    setFormFieldsChanged(formFields);
    setAccordionMenuItems(formFieldsToNav(formFields));
    setSelectedFields([...formFields]);
    setCurrentTarget(undefined);
    setSearchTerm('');
    onChange &&
      onChange({
        id: '',
        value: '',
        data: formFields,
      });
    setTouched(false);
  };

  const handleValidation = (formFieldsChanged: FormFields) => {
    const { isValid, validatedFormFields } = validate(formFieldsChanged);
    setFormFieldsChanged(validatedFormFields);
    setFilteredFormFields(validatedFormFields);
    const newAccordionItems = formFieldsToNav(
      filterFormFieldsByText(validatedFormFields, searchTerm),
    );
    setAccordionMenuItems(newAccordionItems);
    setSelectedFields(
      searchTerm
        ? filterFormFieldsByText(
            findFormFieldsById(validatedFormFields, currentTarget?.id),
            searchTerm,
          )
        : findFormFieldsById(validatedFormFields, currentTarget?.id ?? ''),
    );
    return {
      isValid,
      validatedFormFields,
    };
  };

  const handleOnSubmit = async () => {
    setSubmitting(true);
    const { isValid } = handleValidation(formFieldsChanged);
    if (isValid) {
      const result = await onSubmit(formFieldsChanged);
      result.saved && setTouched(false);
    }
    setSubmitting(false);
  };

  return (
    <div className="flex h-full w-full flex-col overflow-hidden bg-tw-dark-shade dark:bg-tw-dark-shade-dark">
      {search && (
        <div className="p-6">
          <SearchInput
            placeholder="Search settings..."
            onChange={handleOnSearch}
          />
        </div>
      )}
      <div className="relative flex h-full w-full gap-1 bg-tw-dark-shade dark:bg-tw-dark-shade-dark sm:flex-col md:flex-row">
        {accordion && (
          <div
            id="settings-menu"
            className={classNames(
              'scrollbar hidden w-80 overflow-y-auto p-4',
              'md:block',
            )}
          >
            <div
              id="clickable_background"
              onClick={() => {
                setCurrentTarget(undefined);
                setSelectedFields(filteredFormFields);
              }}
              className={classNames(
                'absolute left-0 top-0 h-full w-80 cursor-pointer',
              )}
            />
            <AccordionMenu
              onClick={(item) => {
                setCurrentTarget(item);
                setSelectedFields(item.value);
              }}
              items={accordionMenuItems}
              currentTarget={currentTarget}
              defaultOpen={2}
            />
          </div>
        )}
        <div
          className={`scrollbar flex w-full grow flex-col overflow-y-auto ${
            accordion && 'border-l'
          } border-black/10 bg-tw-dark-shade pb-20 dark:border-white/10 dark:bg-tw-dark-shade-dark`}
        >
          <div className="scrollbar grow overflow-y-auto">
            <div className="mx-auto max-w-5xl">
              <DynamicForm
                formFields={selectedFields}
                onChange={handleChange}
                searchTerm={searchTerm}
              />
            </div>
          </div>
          {hasActions && (
            <FormActions
              touched={touched}
              onReset={handleReset}
              submitting={submitting}
              onSubmit={handleOnSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default DynamicFormWithNav;

export const FormActions = ({
  touched,
  onReset,
  submitting,
  onSubmit,
}: {
  touched: boolean;
  onReset: () => void;
  submitting: boolean;
  onSubmit: () => Promise<void>;
}) => {
  return (
    <Transition
      show={touched}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        id="actions"
        className="flex flex-row items-center justify-end gap-2 border-t border-black/10 bg-tw-dark-shade p-4 dark:border-white/10 dark:bg-tw-dark-shade-dark"
      >
        <PrimaryButton
          type="reset"
          onClick={onReset}
          disabled={!onReset || !touched}
        >
          Reset
        </PrimaryButton>
        <PrimaryButton
          type="submit"
          disabled={!touched || submitting}
          onClick={onSubmit}
        >
          {submitting ? <Spinner /> : 'Submit'}
        </PrimaryButton>
      </div>
    </Transition>
  );
};
