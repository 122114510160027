import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

export enum TextButtonSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  XSmall = 'xsmall',
}

export const TEXT_BUTTON_TEST_ID = 'textButtonTestID';

export const getContainerClassNames = ({
  active,
  size,
  className,
}: {
  active?: boolean;
  size?: string | TextButtonSize;
  className?: string;
}) =>
  twMerge(
    classNames(
      `mt-4 rounded-xl bg-transparent font-graphikRegular text-white`,
      `${
        active
          ? 'text-tw-main-color hover:bg-[#EEF4FF] dark:text-tw-main-color dark:hover:bg-[#2D3036]'
          : 'text-tw-inactive-blue dark:text-[#2D3036]'
      }`,
      size === TextButtonSize.Large && 'mt-4 h-[78px] w-[333px] ',
      size === TextButtonSize.Medium && 'mt-4 h-[49px] w-[205px] ',
      size === TextButtonSize.Small && 'mt-4 h-[40px] w-[140px] ',
      size === TextButtonSize.XSmall && 'm-0 px-2 pb-0.5 pt-1',

      size === TextButtonSize.Large && 'text-xl',
      size === TextButtonSize.Medium && '',
      size === TextButtonSize.Small && 'text-sm',
      size === TextButtonSize.XSmall && 'text-xs',
      className,
    ),
  );

export interface ITextButtonProps {
  active?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  size?: string | TextButtonSize;
  className?: string;
}

const TextButton = ({
  active = false,
  onClick = () => null,
  children,
  size,
  className,
  // data-testid,
  ...rest
}: ITextButtonProps) => {
  return (
    <button
      data-testid={TEXT_BUTTON_TEST_ID}
      disabled={!active}
      className={getContainerClassNames({
        active,
        className,
        size,
      })}
      onClick={onClick}
      {...rest}
    >
      <span>{children}</span>
    </button>
  );
};

export default TextButton;
