import { Switch as HeadlessSwitch } from '@headlessui/react';
import classNames, { Argument } from 'classnames';
import { twMerge } from 'tailwind-merge';
import { UseControlledOrUncontrolled } from '../../hooks/UseControlledOrUncontrolled';
import { Label } from '../Common';

export interface IProps {
  children?: React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
  className?: (props: { checked: boolean }) => Argument;
  labelPosition?: 'left' | 'right';
  checkboxPosition?: 'top' | 'middle';
}

export const Switch = ({
  children,
  checked,
  disabled,
  onChange,
  className,
  defaultChecked,
  labelPosition = 'left',
  ...rest
}: IProps) => {
  const { value, setValue } = UseControlledOrUncontrolled({
    value: checked,
    defaultValue: defaultChecked,
    defaultInternalValue: false,
  });

  const handleDataChange = (checked: boolean) => {
    setValue(checked);
    onChange?.(checked);
  };

  return (
    <HeadlessSwitch.Group
      as="div"
      className={classNames(
        'flex items-center gap-2',
        labelPosition === 'right' && 'flex-row-reverse',
      )}
      data-testid="switch-container"
      {...rest}
    >
      {children && (
        <span className="flex grow flex-col">
          <HeadlessSwitch.Label as="span">
            <Label placement="none">{children}</Label>
          </HeadlessSwitch.Label>
        </span>
      )}
      <HeadlessSwitch
        checked={value}
        disabled={disabled}
        data-testid="switch"
        onChange={handleDataChange}
        className={twMerge(
          classNames(
            value
              ? 'bg-tw-main-color'
              : 'bg-tw-inactive-color/75 dark:bg-tw-inactive-color-dark/50',
            'relative inline-flex h-5 w-9 flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out',
            'focus:outline-none focus:ring-2 focus:ring-tw-main-color focus:ring-offset-1',
            'disabled:cursor-not-allowed disabled:opacity-50',
            className?.({ checked: value }),
          ),
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            value ? 'translate-x-4' : 'translate-x-0',
            'pointer-events-none m-0.5 inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </HeadlessSwitch>
    </HeadlessSwitch.Group>
  );
};
