import { JSONSchema7Type } from 'json-schema';
import { IconName } from '../../../assets/icons/Icon';
import { Direction, LabelType, PickerOptions, Variant } from '../fields';
import { FormDataTable, FormTableSelect } from './DynamicFormDataTable';

export enum FormFieldType {
  GROUP = 'group',
  TEXT = 'text',
  TEXT_AREA = 'text-area',
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  CHECKBOX_GROUP = 'checkbox-group',
  INPUT_NUMBER = 'input-number',
  MULTI_SELECT = 'multi-select',
  RADIOGROUP = 'radiogroup',
  DATA_TABLE = 'data-table',
  TABLE_SELECT = 'table-select',
  LIST = 'list',
  MEMBERS_LIST = 'members-list',
  DATE = 'date',
  DATE_TIME = 'date-time',
  TIME = 'time',
}

export interface BaseFormField {
  id: string;
  type: FormFieldType | `${FormFieldType}`;
  title: string;
  label?: string;
  disabled?: boolean;
  description?: string;
  touched?: boolean;
  required?: boolean | string;
  error?: string | string[];
  examples?: JSONSchema7Type | undefined;
  // TODO: this property only exists to make the table sortable
  sortable?: boolean;
  info?: React.ReactNode;
  variant?: Variant;
  value?: FormFieldValue;
  defaultValue?: FormFieldValue;
  path?: string[];
}

export interface FormGroup extends BaseFormField {
  type: FormFieldType.GROUP;
  fields: FormFields;
  value?: {
    [key: string]: FormFieldValue;
  };
  defaultValue?: {
    [key: string]: FormFieldValue;
  };
  iconName?: IconName;
}
export interface FormText extends BaseFormField {
  type: FormFieldType.TEXT;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  minLength?: MinMaxValue;
  maxLength?: MinMaxValue;
  pattern?: string;
  secret?: boolean;
}

export interface FormTextArea extends BaseFormField {
  type: FormFieldType.TEXT_AREA;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  minLength?: MinMaxValue;
  maxLength?: MinMaxValue;
  pattern?: string;
}

export interface FormDate extends BaseFormField {
  type: FormFieldType.DATE;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
}

export interface FormDateTime extends BaseFormField {
  type: FormFieldType.DATE_TIME;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
}

export interface FormTime extends BaseFormField {
  type: FormFieldType.TIME;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
}

export interface FormSelect extends BaseFormField {
  type: FormFieldType.SELECT;
  placeholder?: string;
  options: PickerOptions;
  clearable?: boolean;
  iconName?: IconName;
  value?: string;
  defaultValue?: string;
}

export interface FormMultiSelect extends BaseFormField {
  type: FormFieldType.MULTI_SELECT;
  options: PickerOptions;
  buttonLabel?: string;
  showSelectAllOption?: boolean;
  iconName?: IconName;
  value?: string[];
  defaultValue?: string[];
  labelType?: LabelType;
  minItems?: MinMaxValue;
  maxItems?: MinMaxValue;
}

export interface FormCheckbox extends BaseFormField {
  type: FormFieldType.CHECKBOX;
  value?: boolean;
  defaultValue?: boolean;
}

export interface FormCheckBoxGroup extends BaseFormField {
  type: FormFieldType.CHECKBOX_GROUP;
  value?: string[];
  defaultValue?: string[];
  options: PickerOptions;
  searchable?: boolean;
  searchPlaceholder?: string;
}

export type OnChangeValue =
  | FormFieldElement['value']
  | FormSelect['value']
  | FormMultiSelect['value']
  | FormDataTable['value']
  | FormTableSelect['value']
  | FormInputNumber['value']
  | FormList['value']
  | FormMembersList['value']
  | FormTextArea['value']
  | FormCheckbox['value']
  | FormCheckBoxGroup['value'];

export interface ItemsTypeFormat {
  type?: 'text' | 'number';
  format?: 'date' | 'time' | 'date-time';
}
export interface FormList extends BaseFormField {
  type: FormFieldType.LIST;
  value?: (string | number)[];
  defaultValue?: (string | number)[];
  maxItems?: number;
  minItems?: number;
  uniqueItems?: boolean;
  placeholder?: string;
  allowDuplicates?: boolean;
  inputLabel?: string;
  items?: ItemsTypeFormat;
  formFieldElement?: (index: number) => FormFieldElement | null;
}
export interface FormMembersList extends BaseFormField {
  type: FormFieldType.MEMBERS_LIST;
  value?: (string | number)[];
  defaultValue?: (string | number)[];
  maxItems?: number;
  minItems?: number;
  uniqueItems?: boolean;
  placeholder?: string;
  allowDuplicates?: boolean;
  inputLabel?: string;
  items?: ItemsTypeFormat;
  formFieldElement?: (index: number) => FormFieldElement | null;
}
export interface FormInputNumber extends BaseFormField {
  value?: number;
  defaultValue?: number;
  type: FormFieldType.INPUT_NUMBER;
  format?: 'integer' | 'number';
  placeholder?: string;
  min?: MinMaxValue;
  max?: MinMaxValue;
  multipleOf?: number;
}

export interface FormRadioGroup extends BaseFormField {
  value?: string;
  defaultValue?: string;
  type: FormFieldType.RADIOGROUP;
  options: PickerOptions;
  direction?: Direction;
}

export type MinMaxValue =
  | number
  | {
      value: number;
      message: string;
    };

export type FormFieldElement =
  | FormText
  | FormTextArea
  | FormSelect
  | FormGroup
  | FormMultiSelect
  | FormDataTable
  | FormTableSelect
  | FormList
  | FormMembersList
  | FormInputNumber
  | FormCheckbox
  | FormRadioGroup
  | FormCheckBoxGroup
  | FormDate
  | FormDateTime
  | FormTime;

export type FormFields = Array<FormFieldElement>;

export type FormFieldValue =
  | FormFieldElement['value']
  | FormSelect['value']
  | FormMultiSelect['value']
  | FormDataTable['value']
  | FormTableSelect['value']
  | FormInputNumber['value']
  | FormList['value']
  | FormMembersList['value']
  | FormTextArea['value']
  | FormCheckbox['value']
  | FormRadioGroup['value']
  | FormCheckBoxGroup['value']
  | FormDate['value']
  | FormDateTime['value']
  | FormFieldValue[];

export type ChangeHandler = ({
  id,
  value,
  pathArray,
}: {
  id: string;
  value: FormFieldValue;
  pathArray?: string[];
}) => void;

export type JSONValue =
  | string
  | number
  | boolean
  | JSONObject
  | Array<JSONValue>;

export interface JSONObject {
  [x: string]: JSONValue | null;
}
