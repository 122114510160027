import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { FormCheckbox } from '../../DynamicForm';
import { FieldError } from '../Common/FieldError';
import { Label } from '../Common/Label';

export const CheckBox = ({
  field,
  onChange,
  error,
  ...props
}: {
  field: FormCheckbox;
  error?: string | string[];
} & DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>) => {
  return (
    <>
      <div
        className="flex items-center space-x-1"
        data-testid="CheckBoxContainer"
      >
        <input
          data-testid="CheckBox"
          key={field.id}
          id={field.id}
          type="checkbox"
          checked={field.value}
          disabled={field.disabled}
          onChange={onChange}
          {...props}
        />
        <Label htmlFor={field.id} size="medium" className="mt-[0.405rem]">
          {field.title}
        </Label>
      </div>
      {error && <FieldError error={error} />}
    </>
  );
};
