import classNames from 'classnames';

export const HighlightedText = ({
  text,
  searchTerm,
}: {
  text: string;
  searchTerm?: string;
}) => {
  if (!searchTerm) {
    return <>{text}</>;
  }
  const regex = new RegExp(`(${searchTerm})`, 'gi');
  const parts = text.split(regex);

  if (parts.length === 1) {
    return <>{text}</>;
  }

  return (
    <span>
      {parts.map((part, index) => {
        const isHighlighted = searchTerm.toLowerCase() === part.toLowerCase();
        return (
          <span
            key={index}
            className={classNames(
              isHighlighted &&
                'bg-yellow-300 text-tw-label-primary dark:bg-yellow-300 dark:text-tw-label-primary',
            )}
          >
            {part}
          </span>
        );
      })}
    </span>
  );
};
