// RWD('Ransomware Defender', ProductStatus.ACTIVE),
// EA('User Behaviour Auditing', ProductStatus.ACTIVE),
// AG('Cyber Vault', ProductStatus.ACTIVE),
// PA('Audit Driven Performance', ProductStatus.ACTIVE),
// DR('Disaster Recovery', ProductStatus.ACTIVE),
// CSM('File Storage Monitoring Automation', ProductStatus.COMING_SOON);

export enum ProductKind {
  Ag = 'AG',
  Csm = 'CSM',
  Ea = 'EA',
  Dr = 'DR',
  GC = 'GC',
  Pa = 'PA',
  Rwd = 'RWD',
  ANY_COPY = 'ANY_COPY',
  SEARCH = 'SEARCH',
  SMART_ARCHIVER = 'SMART_ARCHIVER',
}
