// Used for handle both controlled and uncontrolled components

import { useState } from 'react';

export const UseControlledOrUncontrolled = <T,>({
  value: valueProp,
  defaultValue,
  defaultInternalValue,
}: {
  value?: T;
  defaultValue?: T;
  // Is used when defaultValue is undefined, for example '' for string
  defaultInternalValue: T;
}) => {
  const isControlled = valueProp !== undefined;
  const hasDefaultValue = defaultValue !== undefined;

  const [internalValue, setInternalValue] = useState<T>(
    defaultValue ?? defaultInternalValue,
  );

  const value = isControlled ? valueProp : internalValue;

  return {
    value,
    setValue: (newValue: T) => {
      if (!isControlled) {
        setInternalValue(newValue);
      }
    },
    isControlled,
    hasDefaultValue,
  };
};
