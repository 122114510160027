import { gql } from '@apollo/client';

export const USER_PREFERENCES = gql`
  query UserPreferences {
    userPreferences {
      id
      timeZone
      dashboard {
        timeSpan
        apps {
          key
          value {
            charts
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_PREFERENCES = gql`
  mutation UpdateUserPreferences($input: UpdateUserPreferencesInput!) {
    updateUserPreferences(input: $input) {
      id
      timeZone
      dashboard {
        timeSpan
        apps {
          key
          value {
            charts
          }
        }
      }
    }
  }
`;
