import { useQuery } from '@apollo/client';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import classNames from 'classnames';
import { useFeature } from 'featurehub-react-sdk';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_COVERAGES } from '../../../../api/dashboard/queries';
import { ProductKind } from '../../../../api/product/types';
import {
  ArchiveAvailabilityIcon,
  CapacityManagementReportingIcon,
  ComplianceIcon,
  ContentSearchAnalyticsIcon,
  ProtectionRecoveryIcon,
  RealtimePerformanceIcon,
  SecurityAndAuditIcon,
} from '../../../../assets/icons/appCategory';
import {
  Category,
  GetCoveragesQuery,
  GetCoveragesQueryVariables,
} from '../../../../gql/graphql';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { updateInstalledProductCoverage } from '../../../../redux/products/productsSlice';
import { FeatureFlag } from '../../../../utils/featureHub/constants';
import { formatAsCeroToTen } from '../../../../utils/format';
import { useSession } from '../../../../utils/hooks/useSession';
import { rangeIdToValue } from '../../../CalenderRangePicker/utils';
import Spinner from '../../../Elements/Spinner/Spinner';
import { FieldError } from '../../../form/fields';
import { getCoverageColor } from '../utils';
import { CardCoverageTooltip } from './CardCoverageTooltip';
import CardInfoModal from './CardInfoModal';
import {
  AGBreakdown,
  CSMBreakdown,
  DRBreakdown,
  EABreakdown,
  RWDBreakdown,
} from './CoverageBreakdowns';

interface CoverageIconProps extends React.SVGProps<SVGSVGElement> {
  iconName: Category | string;
}

export const CoverageIcon = ({ iconName, ...props }: CoverageIconProps) => {
  switch (iconName) {
    case Category.SecurityAndAudit:
      return <SecurityAndAuditIcon {...props} />;
    case Category.Compliance:
      return <ComplianceIcon {...props} />;
    case Category.RealtimePerformance:
      return <RealtimePerformanceIcon {...props} />;
    case Category.ContentSearchAndAnalytics:
      return <ContentSearchAnalyticsIcon {...props} />;
    case Category.CapacityManagementAndReporting:
      return <CapacityManagementReportingIcon {...props} />;
    case Category.ProtectionAndRecovery:
      return <ProtectionRecoveryIcon {...props} />;
    case Category.ArchiveAndAvailability:
    default:
      return <ArchiveAvailabilityIcon {...props} />;
  }
};

export interface IProps {
  disabled?: boolean;
  productKind: ProductKind;
}

function formatCoverageName(name: string): string {
  // CONSTANT to Sentence case
  return name
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
}

/**
 * @param score number from 0 to 1
 * @returns
 * @example
 * <ScoreBar score={0.5} />
 * <ScoreBar score={0.8} />
 *
 */
export const ScoreBar = ({ score }: { score: number }) => (
  <div
    data-testid="score-bar"
    className="flex h-[7px] w-full rounded-lg border border-white bg-tw-hover-alternative dark:border-black dark:bg-tw-strong-shade-dark"
  >
    <div
      role="progressbar"
      className="items-center rounded-lg transition-all duration-300 ease-in-out"
      style={{
        width: `calc(${score} * 100%)`,
        background: getCoverageColor(score),
      }}
    />
  </div>
);

const CardCoverageStrengths = ({ disabled, productKind }: IProps) => {
  const { duration, appliances } = useAppSelector(
    (state) => state.productFilter,
  );

  const dateRange = useMemo(() => {
    return rangeIdToValue(duration);
  }, [duration]);

  const { skip } = useSession();

  const { data, loading, error } = useQuery<
    GetCoveragesQuery,
    GetCoveragesQueryVariables
  >(GET_COVERAGES, {
    variables: {
      productKind,
      from: dateRange?.from,
      appliance: appliances,
    },
    skip: skip || disabled,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    data?.coverage &&
      dispatch(
        updateInstalledProductCoverage({
          kind: productKind,
          coverage: data?.coverage,
          loading,
          error,
        }),
      );
  }, [data, loading, error]);

  const [parent] = useAutoAnimate();

  return data?.coverage?.health.length || loading ? (
    <div
      data-testid="coverage-strengths"
      className={classNames(
        'flex flex-wrap items-center p-4 pr-6 xl:flex-nowrap',
        'gap-4',
      )}
      ref={parent}
    >
      {loading && <Spinner className="h-5 w-5" />}
      {error && <FieldError error={'Error loading coverage strength'} />}
      {data?.coverage?.health.map(({ key, value }) => (
        <CardCoverageStrength
          key={key}
          category={key}
          score={Number(value)}
          disabled={disabled}
          productKind={productKind}
        />
      ))}
    </div>
  ) : null;
};

interface CardCoverageStrengthProps {
  category: Category;
  score: number;
  disabled?: boolean;
  productKind?: ProductKind;
}

const getCoverageBreakdown = (productKind: string | undefined) => {
  switch (productKind) {
    case ProductKind.Ea:
      return <EABreakdown />;
    case ProductKind.Dr:
      return <DRBreakdown />;
    case ProductKind.Ag:
      return <AGBreakdown />;
    case ProductKind.Csm:
      return <CSMBreakdown />;
    case ProductKind.Rwd:
      return <RWDBreakdown />;
    default:
      return undefined;
  }
};

const CardCoverageStrength = ({
  category,
  score,
  disabled,
  productKind,
}: CardCoverageStrengthProps) => {
  const { t } = useTranslation('coverage');
  //RELEASE FLAG
  const coverageTooltipFeature = useFeature(
    FeatureFlag.COVERAGE_TOOLTIP_FEATURE,
  );

  return (
    <div
      data-testid="coverage-strength"
      data-test-category={category}
      className="flex w-full items-center gap-2"
    >
      <CoverageIcon
        iconName={category}
        className={`text-tw-main-text dark:text-tw-main-text-dark ${getCoverageColor(
          Number(score),
        )} h-8 w-8 flex-shrink-0 flex-grow-0 rounded-lg text-opacity-80`}
      />
      <div className={'flex w-full flex-col gap-1'}>
        <div className="flex justify-between gap-2">
          <p
            data-testid="coverage-strength-name"
            className="font-graphikMedium text-[12px] leading-[13px] text-tw-description-text"
          >
            {t(
              `coverage.category.${category}.name`,
              formatCoverageName(category),
            )}
            <CardInfoModal
              disabled={disabled}
              category={category}
              appName={productKind}
            />
          </p>
          <CardCoverageTooltip
            content={
              coverageTooltipFeature
                ? getCoverageBreakdown(productKind)
                : undefined
            }
            color={getCoverageColor(Number(score))}
            disabled={disabled}
          >
            <p
              className={classNames(
                'font-graphikMedium text-[12px] leading-[13px]',
                disabled && 'text-tw-light-text',
              )}
              style={
                !disabled ? { color: getCoverageColor(Number(score)) } : {}
              }
            >
              {!disabled ? formatAsCeroToTen(score) : '0'}
            </p>
          </CardCoverageTooltip>
        </div>
        <ScoreBar score={!disabled ? Number(score) : 0} />
      </div>
    </div>
  );
};

export default CardCoverageStrengths;
