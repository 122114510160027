import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { CONFIRM_APPLIANCE, GET_APPLIANCE } from '../../api/appliance/queries';
import { SpinnerWithText } from '../../components/Elements/Spinner/SpinnerWithText';
import {
  ApplianceStatus,
  ConfirmApplianceMutation,
  ConfirmApplianceMutationVariables,
  GetApplianceQuery,
  GetApplianceQueryVariables,
} from '../../gql/graphql';
import { useSession } from '../../utils/hooks/useSession';
import { ApplianceConfirmationForm } from './ApplianceConfirmationForm';

export const ApplianceConfirmationLoader = ({
  applianceId,
  confirmationCode,
}: {
  applianceId: string;
  confirmationCode: string;
}) => {
  const { skip } = useSession();
  const { data, loading, error } = useQuery<
    GetApplianceQuery,
    GetApplianceQueryVariables
  >(GET_APPLIANCE, {
    variables: {
      applianceId,
    },
    skip,
  });

  const [confirmAppliance, confirmApplianceData] = useMutation<
    ConfirmApplianceMutation,
    ConfirmApplianceMutationVariables
  >(CONFIRM_APPLIANCE, {
    variables: {
      applianceId,
      confirmationCode,
    },
  });

  const handleSubmit = () => {
    confirmAppliance().then((res) => {
      setSubmitted(res.data?.confirmAppliance || false);
    });
  };

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setSubmitted(data?.appliance?.status !== ApplianceStatus.Pending || false);
  }, [data]);

  return loading ? (
    <SpinnerWithText text="Loading appliance confirmation..." />
  ) : error ? (
    <div>Something went wrong</div>
  ) : data?.appliance ? (
    <ApplianceConfirmationForm
      appliance={data.appliance}
      onSubmit={handleSubmit}
      error={confirmApplianceData.error?.message}
      loading={confirmApplianceData.loading}
      submitted={submitted}
    />
  ) : null;
};
