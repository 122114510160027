/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An arbitrary precision signed decimal */
  BigDecimal: any;
  /** A Java OffsetDateTime type */
  DateTime: any;
  /** Decimal scalar to represent a Float as a String with two decimals */
  Decimal: any;
  /**
   * Duration scalar
   *
   * The input/output is a string in ISO8601 format.
   */
  Duration: any;
  /** The input/output is a string in ISO8601 format ('±HH:mm'). */
  FixedOffset: any;
  /** A scalar that can represent any JSON value. */
  JSON: any;
  /** A 64-bit signed integer */
  Long: any;
  /** A universally unique identifier compliant UUID Scalar */
  UUID: any;
};

/** This type represents an alert */
export type Alert = {
  __typename?: 'Alert';
  /** Alert's status */
  active: Scalars['Boolean'];
  /** The appliance this alert belongs to */
  appliance: Appliance;
  /** Alert's code */
  code: Scalars['String'];
  /** When the alert was generated */
  createdAt: Scalars['DateTime'];
  /** Alert's identifier */
  id: Scalars['ID'];
  /** Additional information */
  info?: Maybe<Scalars['JSON']>;
  /** Alert's message */
  message: Scalars['String'];
  /** The product this alert belongs to */
  product: Product;
  /** Alert's severity */
  severity: AlertSeverity;
  /** Alert's type */
  type: AlertType;
};

/** An alert pagination connection */
export type AlertConnection = {
  __typename?: 'AlertConnection';
  /** A list of edges containing nodes */
  edges: Array<AlertEdge>;
  /** A list of nodes */
  nodes: Array<Alert>;
  /** Information to aid in pagination */
  pageInfo: PageInfo;
  /** Total number of items in this connection */
  totalItems?: Maybe<Scalars['Int']>;
};

/** This type represents the alert count for each severity */
export type AlertCount = {
  __typename?: 'AlertCount';
  /** Alerts count for critical severity */
  critical: Scalars['Int'];
  /** Alerts count for error severity */
  error: Scalars['Int'];
  /** Alerts count for standard severity */
  standard: Scalars['Int'];
};

/** An edge in a connection */
export type AlertEdge = {
  __typename?: 'AlertEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Alert;
};

/** Severity of an alert */
export enum AlertSeverity {
  Critical = 'CRITICAL',
  Error = 'ERROR',
  Standard = 'STANDARD',
}

/** The type of an alert */
export enum AlertType {
  Health = 'HEALTH',
  Unknown = 'UNKNOWN',
}

/** Application's dynamic configuration */
export type AppConfig = {
  __typename?: 'AppConfig';
  /** Identifier */
  id: Scalars['ID'];
  /** Actual json value with the configuration */
  value: Scalars['JSON'];
};

/** Schema for an application's configuration */
export type AppConfigSchema = {
  __typename?: 'AppConfigSchema';
  /** Identifier */
  id: Scalars['ID'];
  /** Actual value with the json-schema */
  value: Scalars['JSON'];
};

/** Application dashboard preferences */
export type AppDashboardPreferences = {
  __typename?: 'AppDashboardPreferences';
  /** List of application's charts to be shown on the dashboard */
  charts: Array<Scalars['String']>;
};

/**
 * Entry for AppDashboardPreferences.
 *
 * Each entry has an unique key on the list.
 */
export type AppDashboardPreferencesEntry = {
  __typename?: 'AppDashboardPreferencesEntry';
  /** Entry key, it is unique within the same list */
  key: Scalars['String'];
  /** Entry value */
  value: AppDashboardPreferences;
};

/** This type represents an appliance */
export type Appliance = {
  __typename?: 'Appliance';
  /** Appliance identifier */
  applianceId: Scalars['String'];
  /** Appliance description */
  description: Scalars['String'];
  /** Opaque identifier */
  id: Scalars['ID'];
  /** List of products installed on the appliance */
  installedProducts: Array<InstalledProduct>;
  /** Internal IPv4 address for the appliance */
  ipv4?: Maybe<Scalars['String']>;
  /** Last time the appliance have been seen online */
  lastSeen?: Maybe<Scalars['DateTime']>;
  /** The appliance location */
  location?: Maybe<Location>;
  /** Node code where this appliance belongs to */
  node: Scalars['String'];
  /** Whether the appliance is online */
  online: Scalars['Boolean'];
  /** OS version where the appliance is running */
  osVersion?: Maybe<Scalars['String']>;
  /** List of servers related to the appliance */
  servers: Array<Server>;
  /** Appliance status */
  status: ApplianceStatus;
};

/** Status of an appliance */
export enum ApplianceStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
}

/** Type describing the deployment of an application */
export type ApplicationDeployment = {
  __typename?: 'ApplicationDeployment';
  /** Identifier */
  id: Scalars['ID'];
  /** Number of instances to be deployed */
  instances: Scalars['Int'];
  /** Location of the deployment */
  location: DeploymentLocation;
};

/** Settings for an application */
export type ApplicationSettings = {
  __typename?: 'ApplicationSettings';
  /** Application-specific configuration */
  config: AppConfig;
  /** Schema for the dynamic application-specific configuration */
  configSchema: AppConfigSchema;
  /** Deployment settings */
  deployment: ApplicationDeployment;
  /** Identifier */
  id: Scalars['ID'];
};

/**
 * Entry for ApplicationSettings.
 *
 * Each entry has an unique key on the list.
 */
export type ApplicationSettingsEntry = {
  __typename?: 'ApplicationSettingsEntry';
  /** Entry key, it is unique within the same list */
  key: Scalars['String'];
  /** Entry value */
  value: ApplicationSettings;
};

/** Info of an AuditAuthor */
export type AuditAuthor = {
  __typename?: 'AuditAuthor';
  /** Email of the user */
  userEmail: Scalars['String'];
  /** Id of the user */
  userId: Scalars['ID'];
};

/** Info of an AuditLog */
export type AuditLog = {
  __typename?: 'AuditLog';
  /** Actions of the audit log */
  action: AuditLogAction;
  /** Who has generate the log */
  author: AuditAuthor;
  /** When was generated */
  createdAt: Scalars['DateTime'];
  /** Default message for the audit action */
  displayName: Scalars['String'];
  /** If the log is an error */
  error: Scalars['Boolean'];
  /** List of errors that occurred during the execution of the action, if any. */
  errors?: Maybe<Array<Scalars['String']>>;
  /** Audit log id */
  id: Scalars['ID'];
  /** message of the log */
  message: Scalars['String'];
  /** Properties of the log */
  properties: Array<AuditLogPropertyEntry>;
  /** tenant where the log was generated */
  tenant: Scalars['UUID'];
};

/** Actions availables to configure audit logs */
export enum AuditLogAction {
  AddOrg = 'ADD_ORG',
  EditOrg = 'EDIT_ORG',
  RemoveOrg = 'REMOVE_ORG',
}

/** AuditLog pagination connection */
export type AuditLogConnection = {
  __typename?: 'AuditLogConnection';
  /** A list of edges */
  edges: Array<AuditLogEdge>;
  /** A list of nodes */
  nodes: Array<AuditLog>;
  /** Information to aid in pagination */
  pageInfo: PageInfo;
  /** Total number of items in this connection */
  totalItems?: Maybe<Scalars['Int']>;
};

/** An Audit Log edge in a connection */
export type AuditLogEdge = {
  __typename?: 'AuditLogEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: AuditLog;
};

/**
 * Entry for Audit Log properties.
 *
 * Each entry has an unique key on the list.
 */
export type AuditLogPropertyEntry = {
  __typename?: 'AuditLogPropertyEntry';
  /** Entry key, it is unique within the same list */
  key: Scalars['String'];
  /** Entry value */
  value: Scalars['String'];
};

/** Batch Config information */
export type BatchConfig = {
  __typename?: 'BatchConfig';
  /** A condition associated to the threshold */
  condition: Scalars['String'];
  /** Batch config status */
  enabled: Scalars['Boolean'];
  /** Batch threshold */
  threshold: Scalars['Int'];
  /** Type of Batch config */
  type: BatchTypes;
};

/** Batch Config information */
export type BatchConfigInput = {
  /** A condition associated to the threshold */
  condition: Scalars['String'];
  /** Batch config status */
  enabled: Scalars['Boolean'];
  /** Batch threshold */
  threshold: Scalars['Int'];
  /** Type of Batch config */
  type: BatchTypes;
};

/** Batch types to deliver forward notifications */
export enum BatchTypes {
  Counter = 'COUNTER',
  Finding = 'FINDING',
  TimeWindow = 'TIME_WINDOW',
}

/** Category of a product */
export enum Category {
  ArchiveAndAvailability = 'ARCHIVE_AND_AVAILABILITY',
  CapacityManagementAndReporting = 'CAPACITY_MANAGEMENT_AND_REPORTING',
  Compliance = 'COMPLIANCE',
  ContentSearchAndAnalytics = 'CONTENT_SEARCH_AND_ANALYTICS',
  ProtectionAndRecovery = 'PROTECTION_AND_RECOVERY',
  RealtimePerformance = 'REALTIME_PERFORMANCE',
  SecurityAndAudit = 'SECURITY_AND_AUDIT',
}

/** Channel information */
export type Channel = {
  __typename?: 'Channel';
  /** Channel type */
  type: ChannelTypes;
  /** A list of accepted values for the given channel */
  valueList: Array<ChannelProperty>;
};

/** Channel information */
export type ChannelInput = {
  /** Channel type */
  type: ChannelTypes;
  /** A list of accepted values for the given channel */
  valueList: Array<ChannelPropertyInput>;
};

/** Channel information */
export type ChannelProperty = {
  __typename?: 'ChannelProperty';
  /** Channel type */
  key: Scalars['String'];
  /** A list of accepted values for the given channel */
  value?: Maybe<Scalars['String']>;
};

/** Channel information */
export type ChannelPropertyInput = {
  /** Channel type */
  key: Scalars['String'];
  /** A list of accepted values for the given channel */
  value?: InputMaybe<Scalars['String']>;
};

/** This type represents the delivery results for a Channel */
export type ChannelResults = {
  __typename?: 'ChannelResults';
  /** Channel type */
  channel: ChannelTypes;
  /** Delivery resuls for every Item configured for the channel */
  results: Array<ItemResult>;
};

/** Channel types to deliver forward notifications */
export enum ChannelTypes {
  Email = 'EMAIL',
  Slack = 'SLACK',
  Webhook = 'WEBHOOK',
}

/** Type to represent a chart */
export type Chart = {
  __typename?: 'Chart';
  /** Chart's code identifier */
  code: Scalars['String'];
  /** Actual data to graphically represent this chart */
  data: ChartData;
  /** Difference in percentage between the value for this time range and the previous (0-100) */
  diffPercentage?: Maybe<Scalars['Decimal']>;
  /** The owner of this chart */
  owner: ChartOwner;
  /** Time range of the chart in **minutes** */
  timeRange: Scalars['Int'];
  /** Value for this chart on the time range */
  value?: Maybe<Scalars['Float']>;
};

/** Data to represent a chart */
export type ChartData = TimedLineChart;

/** Type to represent the generic information of a chart */
export type ChartInfo = {
  __typename?: 'ChartInfo';
  /** Chart's code identifier */
  code: Scalars['String'];
  /** Chart's description */
  description: Scalars['String'];
  /** Chart's polarity. */
  polarity: ChartPolarity;
  /** Chart's title */
  title: Scalars['String'];
  /** Chart's unit */
  unit: Scalars['String'];
};

/** Owner of the chart */
export type ChartOwner = Product;

/** Polarity of a chart */
export enum ChartPolarity {
  /** The lower the value, the better */
  Negative = 'NEGATIVE',
  /** There are no better or worse values */
  Neutral = 'NEUTRAL',
  /** The higher the value, the better */
  Positive = 'POSITIVE',
}

/** Input when confirming an appliance */
export type ConfirmApplianceInput = {
  /** Appliance identifier */
  applianceId: Scalars['String'];
  /** Confirmation code */
  confirmationCode: Scalars['String'];
};

/** Coverage Strength */
export type Coverage = {
  __typename?: 'Coverage';
  /** Health Score */
  health: Array<CoverageEntry>;
};

/**
 * Entry for Coverage.
 *
 * Each entry has an unique key on the list.
 */
export type CoverageEntry = {
  __typename?: 'CoverageEntry';
  /** Entry key, it is unique within the same list */
  key: Category;
  /** Entry value */
  value: Scalars['Float'];
};

export type CreateAdminOrganizationInput = {
  /** The admin's email */
  email: Scalars['String'];
  /** The admin's name */
  name?: InputMaybe<Scalars['String']>;
};

/** Type describing the deployment of an application */
export type CreateApplicationDeploymentInput = {
  /** Number of instances to be deployed */
  instances: Scalars['Int'];
  /** Location of the deployment */
  location: CreateDeploymentLocationInput;
};

/** Create settings for an application */
export type CreateApplicationSettingsInput = {
  /** Full schema for the dynamic application-specific configuration */
  configSchema: Scalars['JSON'];
  /** Full application-specific default configuration */
  defaultConfig: Scalars['JSON'];
  /** Deployment settings */
  deployment: CreateApplicationDeploymentInput;
};

/** Location of a deployment */
export type CreateDeploymentLocationInput = {
  /** Host (ip or hostname) */
  host: Scalars['String'];
  /** Port */
  port: Scalars['String'];
};

/** Input when creating a new forward workflow */
export type CreateForwardWorkflowInput = {
  /** The batch configs to group some alerts in the same forward */
  batchConfigs: Array<BatchConfigInput>;
  /** The channels to deliver the forward group */
  channels: Array<ChannelInput>;
  /** Workflow name */
  name: Scalars['String'];
  /** The trigger rules to raise a forward */
  triggerRules: Array<TriggerRuleInput>;
};

export type CreateOrganizationInput = {
  /** The admins for the organization */
  admins?: InputMaybe<Array<CreateAdminOrganizationInput>>;
  /** The domain of the organization */
  domain?: InputMaybe<Scalars['String']>;
  /** The name of the organization */
  name: Scalars['String'];
};

/** Input when creating a new user */
export type CreateUserInput = {
  /** User email address */
  email: Scalars['String'];
  /** User name */
  name: Scalars['String'];
  /** A complete list of roles that the user has assigned */
  roles: Array<UserRole>;
};

/** Dashboard preferences */
export type DashboardPreferences = {
  __typename?: 'DashboardPreferences';
  /** Per-application preferences */
  apps: Array<AppDashboardPreferencesEntry>;
  /** Time span for the chart metrics on the dashboard */
  timeSpan: Scalars['Duration'];
};

/** Simple payload when deleting entities */
export type DeletePayload = {
  __typename?: 'DeletePayload';
  /** Identifier of the deleted item */
  id: Scalars['ID'];
};

/** Location of a deployment */
export type DeploymentLocation = {
  __typename?: 'DeploymentLocation';
  /** Host (ip or hostname) */
  host: Scalars['String'];
  /** Port */
  port: Scalars['String'];
};

/** This type represents a Forward Group */
export type ForwardGroup = {
  __typename?: 'ForwardGroup';
  /** Alert codes forwarded in this group */
  alertCodes: Array<Scalars['String']>;
  /** Forward results pper Channel */
  channelResults: Array<ChannelResults>;
  /** ForwardGroup's identifier */
  id: Scalars['ID'];
  /** ForwardGroup's name */
  name: Scalars['String'];
  /** When the ForwardGroup was delivered */
  sentAt: Scalars['DateTime'];
  /** Forward Workflow who generated this Forward group */
  workflow: ForwardWorkflow;
};

/** An ForwardGroup pagination connection */
export type ForwardGroupConnection = {
  __typename?: 'ForwardGroupConnection';
  /** A list of edges */
  edges: Array<ForwardGroupEdge>;
  /** A list of nodes */
  nodes: Array<ForwardGroup>;
  /** Information to aid in pagination */
  pageInfo: PageInfo;
  /** Total number of items in this connection */
  totalItems?: Maybe<Scalars['Int']>;
};

/** An ForwardGroup edge in a connection */
export type ForwardGroupEdge = {
  __typename?: 'ForwardGroupEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: ForwardGroup;
};

/** Forward Workflow information */
export type ForwardWorkflow = {
  __typename?: 'ForwardWorkflow';
  /** The batch configs to group several alerts in the same delivery */
  batchConfigs?: Maybe<Array<BatchConfig>>;
  /** The channels to deliver the forward */
  channels: Array<Channel>;
  /** Workflow status */
  enabled: Scalars['Boolean'];
  /** Workflow id */
  id: Scalars['ID'];
  /** Last time the workflow sent out a forward delivery */
  lastSent?: Maybe<Scalars['DateTime']>;
  /** Last time the workflow was updated */
  lastUpdate?: Maybe<Scalars['DateTime']>;
  /** Workflow name */
  name: Scalars['String'];
  /** Workflow revision */
  revision?: Maybe<Scalars['Long']>;
  /** The trigger rules to forward an alert */
  triggerRules: Array<TriggerRule>;
};

/** The health status of the server component */
export enum HealthStatus {
  Down = 'DOWN',
  Up = 'UP',
}

/** An installation of a product in an appliance */
export type InstalledProduct = {
  __typename?: 'InstalledProduct';
  /** The appliance where this product is installed */
  appliance: Appliance;
  /** Opaque identifier */
  id: Scalars['ID'];
  /** The licenses */
  licenses: Array<InstalledProductLicense>;
  /** The product definition */
  product: Product;
  /** The software version */
  softwareVersion?: Maybe<Scalars['String']>;
};

/** Information about a product license */
export type InstalledProductLicense = {
  __typename?: 'InstalledProductLicense';
  /** The description of the license */
  description: Scalars['String'];
  /**
   * The expiration date of the support for this license.
   * If the license is not perpetual the license will expire on this same date.
   */
  expiration: Scalars['DateTime'];
  /** The unique serial number of the license */
  id: Scalars['ID'];
  /** The installed product where this license belongs */
  installedProduct: InstalledProduct;
  /** The kind of license */
  kind: LicenseKind;
  /** The managed devices linked to this license */
  licensedDevices: Array<LicensedDeviceLink>;
  /**
   * Boolean indicating if the license is perpetual.
   * That means that the functionality doesn't expire.
   */
  perpetual: Scalars['Boolean'];
  /** The platform this license applies to */
  platform: PlatformType;
  /**
   * The size consumed.
   * The unit depends on the license kind:
   *   - CAPACITY: megabytes
   *   - CLUSTER: number of clusters
   *   - NODE: number of nodes
   */
  sizeConsumed: Scalars['BigDecimal'];
  /**
   * The size licensed.
   * The unit depends on the license kind:
   *   - CAPACITY: megabytes
   *   - CLUSTER: number of clusters
   *   - NODE: number of nodes
   */
  sizeLicensed: Scalars['BigDecimal'];
};

/** This type represents an integration */
export type Integration = {
  __typename?: 'Integration';
  /** The integration global config */
  config?: Maybe<Scalars['JSON']>;
  /** The json schema of the integration global config */
  configSchema?: Maybe<Scalars['JSON']>;
  /** The internal id of the integration */
  id: Scalars['ID'];
  /** The integration name */
  name: Scalars['String'];
};

/** This type represents a delivery result for a concrete channel item */
export type ItemResult = {
  __typename?: 'ItemResult';
  /** Item key */
  item: Scalars['String'];
  /** Delivery result true or false */
  result: Scalars['Boolean'];
};

/** The kind of license */
export enum LicenseKind {
  Capacity = 'CAPACITY',
  Cluster = 'CLUSTER',
  Node = 'NODE',
}

/** Link between an installed product license and a managed device */
export type LicensedDeviceLink = {
  __typename?: 'LicensedDeviceLink';
  /** The managed device side of the link */
  device: ManagedDevice;
  /** The license side of the link */
  license: InstalledProductLicense;
  /** The state of the link */
  state: ManagedDeviceLicenseState;
};

/** A location on the Earth */
export type Location = {
  __typename?: 'Location';
  /** City name */
  city?: Maybe<Scalars['String']>;
  /** 3-digit country code as in ISO 3166 */
  country: Scalars['String'];
  /** Identifier */
  id: Scalars['ID'];
  /** Latitude in decimal degrees */
  latitude: Scalars['Float'];
  /** Longitude in decimal degrees */
  longitude: Scalars['Float'];
};

/** A location candidate */
export type LocationCandidate = {
  __typename?: 'LocationCandidate';
  /** The candidate identifier */
  id: Scalars['ID'];
  /** The label of the candidate */
  label: Scalars['String'];
};

/** Device managed by an installed Superna product */
export type ManagedDevice = {
  __typename?: 'ManagedDevice';
  /** The total device capacity in megabytes */
  diskCapacity?: Maybe<Scalars['BigDecimal']>;
  /** The device used capacity in megabytes */
  diskUsage?: Maybe<Scalars['BigDecimal']>;
  /** The device globally unique identifier */
  guid: Scalars['ID'];
  /** Internal IPv4 address for the device */
  ipv4?: Maybe<Scalars['String']>;
  /** The licenses linked to this device */
  licenseLinks: Array<LicensedDeviceLink>;
  /** The device location */
  location?: Maybe<Location>;
  /** Device name */
  name?: Maybe<Scalars['String']>;
  /** The number of nodes on this device */
  nodeCount?: Maybe<Scalars['Int']>;
  /** The platform of Device */
  platform?: Maybe<PlatformType>;
};

/** The state of the managed device license within an installed product */
export enum ManagedDeviceLicenseState {
  InGracePeriod = 'IN_GRACE_PERIOD',
  Licensed = 'LICENSED',
  Revoked = 'REVOKED',
  Suspended = 'SUSPENDED',
  Unlicensed = 'UNLICENSED',
}

/** Mutate this SEED API */
export type Mutation = {
  __typename?: 'Mutation';
  /** Change status alerts from active to inactive */
  clearAllAlerts: Scalars['Int'];
  /** Confirms a previously registered appliance */
  confirmAppliance: Scalars['Boolean'];
  /** Creates a forward workflow in the current organization */
  createForwardWorkflow: ForwardWorkflow;
  /** Creates an organization */
  createOrganization: Organization;
  /** Create settings for a deployed application */
  createSettings: ApplicationSettings;
  /** Creates an user in the current organization */
  createUser: User;
  /** Deletes a list of alerts from the current organization */
  deleteAlerts: Scalars['Boolean'];
  /** Deletes an appliance */
  deleteAppliance: Scalars['Boolean'];
  /** Deletes the location of an appliance */
  deleteApplianceLocation: Scalars['Boolean'];
  /** Deletes a forward workflow in the current organization */
  deleteForwardWorkflow: DeletePayload;
  /** Deletes the config of an integration, leaving it unavailable */
  deleteIntegrationConfig: Scalars['Boolean'];
  deleteManagedDevice: Scalars['Boolean'];
  /** Deletes the location of a managed device */
  deleteManagedDeviceLocation: Scalars['Boolean'];
  /** Deletes an organization */
  deleteOrganization: DeletePayload;
  /** Delete settings for a un-deployed application */
  deleteSettings: DeletePayload;
  /** Deletes a Slack installation */
  deleteSlackInstallation: Scalars['Boolean'];
  /** Deletes an user from the current organization */
  deleteUser: DeletePayload;
  /** Registers a new appliance */
  registerAppliance: RegisterAppliancePayload;
  /** Requests a trial for a product */
  requestTrial: Scalars['Boolean'];
  /** Restore application-specific configuration */
  restoreSettingsConfig: AppConfig;
  /** Updates an existing appliance */
  updateAppliance: Appliance;
  /** Updates a forward workflow in the current organization */
  updateForwardWorkflow: ForwardWorkflow;
  /** Updates the config of an integration */
  updateIntegrationConfig: Scalars['Boolean'];
  /** Updates a managed device */
  updateManagedDevice: ManagedDevice;
  /** Updates an organization */
  updateOrganization: Organization;
  /** Update application-specific configuration */
  updateSettingsConfig: AppConfig;
  /** Update deployment settings for an application */
  updateSettingsDeployment: ApplicationSettings;
  /** Updates an user from the current organization */
  updateUser: User;
  /** Updates the current user's preferences */
  updateUserPreferences: UserPreferences;
};

/** Mutate this SEED API */
export type MutationClearAllAlertsArgs = {
  filter: QueryAlertInput;
};

/** Mutate this SEED API */
export type MutationConfirmApplianceArgs = {
  input: ConfirmApplianceInput;
};

/** Mutate this SEED API */
export type MutationCreateForwardWorkflowArgs = {
  input: CreateForwardWorkflowInput;
};

/** Mutate this SEED API */
export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

/** Mutate this SEED API */
export type MutationCreateSettingsArgs = {
  id: Scalars['ID'];
  input: CreateApplicationSettingsInput;
};

/** Mutate this SEED API */
export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

/** Mutate this SEED API */
export type MutationDeleteAlertsArgs = {
  ids: Array<Scalars['ID']>;
};

/** Mutate this SEED API */
export type MutationDeleteApplianceArgs = {
  id: Scalars['String'];
};

/** Mutate this SEED API */
export type MutationDeleteApplianceLocationArgs = {
  id: Scalars['String'];
};

/** Mutate this SEED API */
export type MutationDeleteForwardWorkflowArgs = {
  id: Scalars['ID'];
};

/** Mutate this SEED API */
export type MutationDeleteIntegrationConfigArgs = {
  id: Scalars['ID'];
};

/** Mutate this SEED API */
export type MutationDeleteManagedDeviceArgs = {
  appliance?: InputMaybe<Scalars['String']>;
  guid: Scalars['ID'];
};

/** Mutate this SEED API */
export type MutationDeleteManagedDeviceLocationArgs = {
  guid: Scalars['ID'];
};

/** Mutate this SEED API */
export type MutationDeleteOrganizationArgs = {
  tenant: Scalars['UUID'];
};

/** Mutate this SEED API */
export type MutationDeleteSettingsArgs = {
  id: Scalars['ID'];
};

/** Mutate this SEED API */
export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};

/** Mutate this SEED API */
export type MutationRegisterApplianceArgs = {
  input: RegisterApplianceInput;
};

/** Mutate this SEED API */
export type MutationRequestTrialArgs = {
  input: RequestTrialInput;
};

/** Mutate this SEED API */
export type MutationRestoreSettingsConfigArgs = {
  id: Scalars['ID'];
};

/** Mutate this SEED API */
export type MutationUpdateApplianceArgs = {
  input: UpdateApplianceInput;
};

/** Mutate this SEED API */
export type MutationUpdateForwardWorkflowArgs = {
  input: UpdateForwardWorkflowInput;
  notify?: InputMaybe<Scalars['Boolean']>;
};

/** Mutate this SEED API */
export type MutationUpdateIntegrationConfigArgs = {
  config: Scalars['JSON'];
  id: Scalars['ID'];
};

/** Mutate this SEED API */
export type MutationUpdateManagedDeviceArgs = {
  input: UpdateManagedDeviceInput;
};

/** Mutate this SEED API */
export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};

/** Mutate this SEED API */
export type MutationUpdateSettingsConfigArgs = {
  id: Scalars['ID'];
  input: Scalars['JSON'];
};

/** Mutate this SEED API */
export type MutationUpdateSettingsDeploymentArgs = {
  force?: Scalars['Boolean'];
  id: Scalars['ID'];
  input: UpdateApplicationSettingsInput;
};

/** Mutate this SEED API */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** Mutate this SEED API */
export type MutationUpdateUserPreferencesArgs = {
  input: UpdateUserPreferencesInput;
};

export type Organization = {
  __typename?: 'Organization';
  /** The user related to the organization will be the one with the oldest admin role */
  admins: Array<User>;
  /** The date and time in which the organization was created */
  createdAt: Scalars['DateTime'];
  /** The domain of the organization */
  domain?: Maybe<Scalars['String']>;
  /** The internal identifier of the organization */
  id: Scalars['ID'];
  /** The name of the organization */
  name: Scalars['String'];
  /** The unique identifier of the organization */
  tenant: Scalars['UUID'];
};

export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  /** A list of edges */
  edges: Array<OrganizationEdge>;
  /** A list of nodes */
  nodes: Array<Organization>;
  /** Information to aid in pagination */
  pageInfo: PageInfo;
  /** Total number of items in this connection */
  totalItems?: Maybe<Scalars['Int']>;
};

export type OrganizationEdge = {
  __typename?: 'OrganizationEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Organization;
};

/** Information about pagination in a connection */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** Platforms we support */
export enum PlatformType {
  Aws = 'AWS',
  Ecs = 'ECS',
  Isilon = 'ISILON',
  Qumulo = 'QUMULO',
  Vast = 'VAST',
}

/** This type contains the information of a Superna product */
export type Product = {
  __typename?: 'Product';
  /**
   * Retrieve the alerts count aggregating all installations of this product across every appliance.
   *
   * If no dates are given, the default user time span for the dashboard will be used.
   */
  alertsCount: AlertCount;
  /** The list of categories this product belongs to */
  categories: Array<Category>;
  /** Whether the product is coming soon and it's not active yet */
  comingSoon: Scalars['Boolean'];
  /** Retrieves the product's coverage score */
  coverage: Coverage;
  /** Opaque product's identifier */
  id: Scalars['ID'];
  /** The kind of product */
  kind: Scalars['String'];
  /** Product's name */
  name: Scalars['String'];
  /** Product's statistics */
  stats: Array<Chart>;
  /** Query for all available statistics for this product */
  statsAvailable: Array<ChartInfo>;
};

/** This type contains the information of a Superna product */
export type ProductAlertsCountArgs = {
  appliance?: InputMaybe<Array<Scalars['String']>>;
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

/** This type contains the information of a Superna product */
export type ProductCoverageArgs = {
  appliance?: InputMaybe<Array<Scalars['String']>>;
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

/** This type contains the information of a Superna product */
export type ProductStatsArgs = {
  appliance?: InputMaybe<Array<Scalars['String']>>;
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

/** Perform any read-only query to the API */
export type Query = {
  __typename?: 'Query';
  /** Returns alert */
  alert: Alert;
  /** Query for paginated alerts using the [relay](https://relay.dev/graphql/connections.htm) specification */
  alerts: AlertConnection;
  /** Returns the settings for a specific application */
  appSettings: ApplicationSettings;
  /** Retrieves an appliance */
  appliance: Appliance;
  /** Retrieves the appliance server components */
  applianceServerComponents: Array<ServerComponent>;
  /** Query all the appliances for the tenant */
  appliances: Array<Appliance>;
  /** Lists audit logs filtered by the given criteria */
  auditLogs: AuditLogConnection;
  /** Query for a chart */
  chart: Chart;
  /** Query for a coverage */
  coverage: Coverage;
  /** Find location candidates based on a text search */
  findLocationCandidates: Array<LocationCandidate>;
  /** Query for paginated forwarded groups using the [relay](https://relay.dev/graphql/connections.htm) specification */
  forwardGroups: ForwardGroupConnection;
  /** Get a workflow by its id */
  forwardWorkflow?: Maybe<ForwardWorkflow>;
  /** Lists workflows filtered by the given criteria */
  forwardWorkflows: Array<ForwardWorkflow>;
  /** Get the additional specifications for the webhooks channel, other than the integration identifier */
  forwardWorkflowsWebhookChannelSpecs?: Maybe<Scalars['JSON']>;
  /** Retrieves an integration */
  integration: Integration;
  /** Query all of the available integrations */
  integrations: Array<Integration>;
  /** Retrieves a managed device */
  managedDevice: ManagedDevice;
  /** Query all the managed devices present on the tenant */
  managedDevices: Array<ManagedDevice>;
  /** Query for a specific organization by its id */
  organization: Organization;
  /** Query for paginated Organizations by name. The user related to the organization will be the one with the oldest admin role */
  organizations: OrganizationConnection;
  /** Retrieves a product present on the tenant */
  product: Product;
  /** Query all the products present on the tenant */
  products: Array<Product>;
  /** Resolves the location data of a candidate */
  resolveLocationCandidate: Location;
  /** Lists all the settings for deployed applications */
  settings: Settings;
  /**
   * Get the slack channels related to the tenant in context
   * Optional filter by channel type
   */
  slackChannels: Array<SlackChannel>;
  /** Check if exists a slack installation for tenant related in context */
  slackInstallation?: Maybe<SlackInstallation>;
  /** Returns the user preferences */
  userPreferences: UserPreferences;
  /** Lists users filtered by the given criteria */
  users: Array<User>;
};

/** Perform any read-only query to the API */
export type QueryAlertArgs = {
  id: Scalars['ID'];
};

/** Perform any read-only query to the API */
export type QueryAlertsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: QueryAlertInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Perform any read-only query to the API */
export type QueryAppSettingsArgs = {
  id: Scalars['ID'];
};

/** Perform any read-only query to the API */
export type QueryApplianceArgs = {
  id: Scalars['String'];
};

/** Perform any read-only query to the API */
export type QueryApplianceServerComponentsArgs = {
  applianceId: Scalars['String'];
  serverId: Scalars['ID'];
};

/** Perform any read-only query to the API */
export type QueryAppliancesArgs = {
  filter?: InputMaybe<QueryApplianceInput>;
};

/** Perform any read-only query to the API */
export type QueryAuditLogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: QueryAuditLogInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Perform any read-only query to the API */
export type QueryChartArgs = {
  filter: QueryChartInput;
};

/** Perform any read-only query to the API */
export type QueryCoverageArgs = {
  filter: QueryCoverageInput;
};

/** Perform any read-only query to the API */
export type QueryFindLocationCandidatesArgs = {
  language?: InputMaybe<Scalars['String']>;
  search: Scalars['String'];
};

/** Perform any read-only query to the API */
export type QueryForwardGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter: QueryForwardGroupsInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Perform any read-only query to the API */
export type QueryForwardWorkflowArgs = {
  id: Scalars['String'];
};

/** Perform any read-only query to the API */
export type QueryForwardWorkflowsArgs = {
  filter?: InputMaybe<QueryForwardWorkflowsInput>;
};

/** Perform any read-only query to the API */
export type QueryForwardWorkflowsWebhookChannelSpecsArgs = {
  integrationId: Scalars['ID'];
};

/** Perform any read-only query to the API */
export type QueryIntegrationArgs = {
  id: Scalars['ID'];
};

/** Perform any read-only query to the API */
export type QueryManagedDeviceArgs = {
  guid: Scalars['ID'];
};

/** Perform any read-only query to the API */
export type QueryManagedDevicesArgs = {
  filter?: InputMaybe<QueryManagedDeviceInput>;
};

/** Perform any read-only query to the API */
export type QueryOrganizationArgs = {
  tenant: Scalars['UUID'];
};

/** Perform any read-only query to the API */
export type QueryOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<QueryOrganizationInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Perform any read-only query to the API */
export type QueryProductArgs = {
  kind: Scalars['String'];
};

/** Perform any read-only query to the API */
export type QueryResolveLocationCandidateArgs = {
  candidateId: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
};

/** Perform any read-only query to the API */
export type QuerySlackChannelsArgs = {
  channelType?: InputMaybe<Array<SlackChannelType>>;
};

/** Perform any read-only query to the API */
export type QueryUsersArgs = {
  filter?: InputMaybe<QueryUserInput>;
};

/** Input when querying alerts */
export type QueryAlertInput = {
  /** Alert status to filter to */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Alert appliance identifier to filter to */
  appliance?: InputMaybe<Array<Scalars['String']>>;
  /** Alert code to filter to */
  code?: InputMaybe<Array<Scalars['String']>>;
  /** Start date for the alerts */
  from: Scalars['DateTime'];
  /** Alert product kind to filter to */
  productKind?: InputMaybe<Array<Scalars['String']>>;
  /** Alert severities to filter to */
  severity?: InputMaybe<Array<AlertSeverity>>;
  /** End date for the alerts */
  to?: InputMaybe<Scalars['DateTime']>;
  /** Alert type to filter to */
  type?: InputMaybe<Array<AlertType>>;
};

/** Input when querying for appliances */
export type QueryApplianceInput = {
  /** Appliance identifiers to filter by */
  appliance?: InputMaybe<Array<Scalars['String']>>;
  /** Appliance status to filter by */
  statuses?: InputMaybe<Array<ApplianceStatus>>;
};

/** Input when querying audit logs */
export type QueryAuditLogInput = {
  /** List of actions */
  action?: InputMaybe<Array<AuditLogAction>>;
  /** Filter by error logs */
  error?: InputMaybe<Scalars['Boolean']>;
  /** Start date to start filter */
  from: Scalars['DateTime'];
  /** Message to filter */
  message?: InputMaybe<Scalars['String']>;
  /** Tenant ID where the log was generated */
  tenant?: InputMaybe<Scalars['UUID']>;
  /** End date to start filter */
  to?: InputMaybe<Scalars['DateTime']>;
  /** User email that generate the log */
  userEmail?: InputMaybe<Scalars['String']>;
  /** User ID that generate the log */
  userId?: InputMaybe<Scalars['ID']>;
};

/** Input when querying charts */
export type QueryChartInput =
  /** Query for a product's chart */
  { product: QueryProductChartInput };

/** Input when querying product coverage */
export type QueryCoverageInput = {
  /** Appliance identifiers to filter to */
  appliance?: InputMaybe<Array<Scalars['String']>>;
  /** Start date to take into account when computing coverage */
  from: Scalars['DateTime'];
  /** Kind of Product */
  productKind: Scalars['String'];
  /** End date to take into account when computing coverage */
  to?: InputMaybe<Scalars['DateTime']>;
};

/** Input when querying forwarded groups */
export type QueryForwardGroupsInput = {
  /** Start date for the groups */
  from: Scalars['DateTime'];
  /** Name of the group */
  name?: InputMaybe<Scalars['String']>;
  /** End date for the groups */
  to?: InputMaybe<Scalars['DateTime']>;
};

/** Input when querying forward workflows */
export type QueryForwardWorkflowsInput = {
  /** Parameter to make a like search by workflow name */
  name?: InputMaybe<Scalars['String']>;
};

/** Input when querying for managed devices */
export type QueryManagedDeviceInput = {
  /** Appliance identifiers to filter */
  appliance?: InputMaybe<Array<Scalars['String']>>;
  /** Device identifiers to filter */
  guid?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryOrganizationInput = {
  /** Parameter to make a like search by organization name */
  name: Scalars['String'];
};

/** Input when querying product charts */
export type QueryProductChartInput = {
  /** Appliance identifiers to filter to */
  appliance?: InputMaybe<Array<Scalars['String']>>;
  /** Chart's code identifier */
  chartCode: Scalars['String'];
  /** Wether to return a detailed chart with more points or the regular one */
  detailed?: InputMaybe<Scalars['Boolean']>;
  /** Start date for the chart data */
  from: Scalars['DateTime'];
  /** Product's kind */
  productKind: Scalars['String'];
  /** End date for the chart data */
  to?: InputMaybe<Scalars['DateTime']>;
};

/** Input when querying users */
export type QueryUserInput = {
  /** User emails to filter to */
  emails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** User role to filter to */
  role?: InputMaybe<UserRole>;
  /** Wildcard parameter to make a like search by user name or email */
  wildcard?: InputMaybe<Scalars['String']>;
};

/** Input when registering a new appliance */
export type RegisterApplianceInput = {
  /** Appliance identifier */
  applianceId: Scalars['String'];
  /** Description of the appliance */
  description: Scalars['String'];
  /** List of product kinds installed on the appliance */
  products: Array<Scalars['String']>;
  /** Public RSA key for the appliance */
  publicKey: Scalars['String'];
};

/** Output payload when registering a new appliance */
export type RegisterAppliancePayload = {
  __typename?: 'RegisterAppliancePayload';
  /** Appliance identifier */
  applianceId: Scalars['String'];
  /** Confirmation code */
  confirmationCode: Scalars['String'];
};

/** Input parameters to request a trial */
export type RequestTrialInput = {
  /** The name of the company requesting the trial */
  company: Scalars['String'];
  /** Contact email of the user requesting the trial */
  email: Scalars['String'];
  /** The name of the product the trial has been requested for */
  product: Scalars['String'];
  /** Name of the user requesting the trial */
  user: Scalars['String'];
};

/** The server related to an appliance */
export type Server = {
  __typename?: 'Server';
  /** Date of creation of the server */
  createdAt: Scalars['DateTime'];
  /** Opaque identifier */
  id: Scalars['ID'];
  /** The server ip v4 address */
  ipv4?: Maybe<Scalars['String']>;
  /** The server name */
  name?: Maybe<Scalars['String']>;
  /** The port where the server is deployed */
  port?: Maybe<Scalars['Int']>;
  /** The state of the server */
  state: ServerState;
  /** The type of the server */
  type: ServerType;
  /** Date of last server update */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServerComponent = {
  __typename?: 'ServerComponent';
  /** The component CPU usage (percentage). Example: 0.5 for 0.5% */
  cpu?: Maybe<Scalars['Float']>;
  /** Whatever details the on-premises server component has */
  details?: Maybe<Scalars['String']>;
  /** The health status of the server component */
  healthStatus: HealthStatus;
  /** The server component name */
  name: Scalars['String'];
  /** The component RAM usage in mega bytes. Example: 1024 (1GB) */
  ram?: Maybe<Scalars['Float']>;
  /** The web address of the server component */
  webAddress?: Maybe<Scalars['String']>;
};

/** The state of the appliance server */
export enum ServerState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

/** The type of the appliance server */
export enum ServerType {
  Master = 'MASTER',
  Worker = 'WORKER',
}

/** Settings for applications */
export type Settings = {
  __typename?: 'Settings';
  apps: Array<ApplicationSettingsEntry>;
};

export type SlackChannel = {
  __typename?: 'SlackChannel';
  /** The channel type (public or private) */
  channelType: SlackChannelType;
  /** The channel identifier */
  id: Scalars['String'];
  /** True if the channel is archived */
  isArchived: Scalars['Boolean'];
  /** True if it's the general channel */
  isGeneral: Scalars['Boolean'];
  /** The channel name */
  name: Scalars['String'];
  /** The channel purpose */
  purpose?: Maybe<Scalars['String']>;
  /** The channel topic */
  topic?: Maybe<Scalars['String']>;
};

/** Represents the type of the slack channel */
export enum SlackChannelType {
  PrivateChannel = 'PRIVATE_CHANNEL',
  PublicChannel = 'PUBLIC_CHANNEL',
}

export type SlackInstallation = {
  __typename?: 'SlackInstallation';
  /** When the application was installed */
  installedAt: Scalars['DateTime'];
  /** The team name */
  teamName: Scalars['String'];
};

/** Line chart with a time-based axis */
export type TimedLineChart = {
  __typename?: 'TimedLineChart';
  /** Values for this chart */
  values: Array<TimedLineChartValue>;
};

/** Represent a single value or dot in a `TimedLineChart` */
export type TimedLineChartValue = {
  __typename?: 'TimedLineChartValue';
  /** The time of the value, usually represented on the x-axis */
  time: Scalars['DateTime'];
  /** The actual value at the time, usually represented on the y-axis */
  value: Scalars['Float'];
};

/** Trigger Rule information */
export type TriggerRule = {
  __typename?: 'TriggerRule';
  /** Trigger Rule Source */
  source: TriggerRuleSources;
  /** A list of accepted values for the given source */
  valueList: Array<Scalars['String']>;
};

/** Trigger Rule information */
export type TriggerRuleInput = {
  /** Trigger Rule Source */
  source: TriggerRuleSources;
  /** A list of accepted values for the given source */
  valueList: Array<Scalars['String']>;
};

/** Rules Sources available to configure alerts workflows */
export enum TriggerRuleSources {
  AlertCode = 'ALERT_CODE',
  AlertSeverity = 'ALERT_SEVERITY',
  ApplianceId = 'APPLIANCE_ID',
  ProductKind = 'PRODUCT_KIND',
  Regexp = 'REGEXP',
}

export type UpdateAdminOrganizationInput = {
  /** The admin's email */
  email: Scalars['String'];
  /** User identifier */
  id?: InputMaybe<Scalars['ID']>;
  /** The admin's name */
  name?: InputMaybe<Scalars['String']>;
};

/** Application dashboard preferences */
export type UpdateAppDashboardPreferencesInput = {
  /** List of application's charts to be shown on the dashboard */
  charts?: InputMaybe<Array<Scalars['String']>>;
};

/**
 * Entry for UpdateAppDashboardPreferencesInput.
 *
 * Each entry **must have an unique key** on the list.
 */
export type UpdateAppDashboardPreferencesInputEntry = {
  /** Entry key, it is unique within the same list */
  key: Scalars['String'];
  /** Entry value */
  value: UpdateAppDashboardPreferencesInput;
};

/** Input when updating an appliance */
export type UpdateApplianceInput = {
  /** Appliance identifier */
  applianceId: Scalars['String'];
  /** Appliance description */
  description?: InputMaybe<Scalars['String']>;
  /** Internal IPv4 address for the appliance */
  ipv4?: InputMaybe<Scalars['String']>;
  /** The appliance location */
  location?: InputMaybe<UpdateLocationInput>;
};

/** Type describing the deployment of an application */
export type UpdateApplicationDeploymentInput = {
  /** Number of instances to be deployed */
  instances?: InputMaybe<Scalars['Int']>;
  /** Location of the deployment */
  location?: InputMaybe<UpdateDeploymentLocationInput>;
};

/** Update settings for an application */
export type UpdateApplicationSettingsInput = {
  /** Full schema for the dynamic application-specific configuration */
  configSchema?: InputMaybe<Scalars['JSON']>;
  /** Full application-specific default configuration */
  defaultConfig?: InputMaybe<Scalars['JSON']>;
  /** Deployment settings */
  deployment?: InputMaybe<UpdateApplicationDeploymentInput>;
};

/** Dashboard preferences */
export type UpdateDashboardPreferencesInput = {
  /** Per-application preferences */
  apps?: InputMaybe<Array<UpdateAppDashboardPreferencesInputEntry>>;
  /** Time span for the chart metrics on the dashboard */
  timeSpan?: InputMaybe<Scalars['Duration']>;
};

/** Location of a deployment */
export type UpdateDeploymentLocationInput = {
  /** Host (ip or hostname) */
  host?: InputMaybe<Scalars['String']>;
  /** Port */
  port?: InputMaybe<Scalars['String']>;
};

/** Input when updating a new forward workflow */
export type UpdateForwardWorkflowInput = {
  /** The batch configs to group some alerts in the same forward */
  batchConfigs?: InputMaybe<Array<InputMaybe<BatchConfigInput>>>;
  /** The channels to deliver the forward group */
  channels?: InputMaybe<Array<InputMaybe<ChannelInput>>>;
  /** Enabled true or false */
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** Workflow id */
  id: Scalars['String'];
  /** Workflow name */
  name?: InputMaybe<Scalars['String']>;
  /** The trigger rules to raise a forward */
  triggerRules?: InputMaybe<Array<InputMaybe<TriggerRuleInput>>>;
};

/** Input when updating a location */
export type UpdateLocationInput = {
  /** City name */
  city?: InputMaybe<Scalars['String']>;
  /** 3-digit country code as in ISO 3166 */
  country: Scalars['String'];
  /** Latitude in decimal degrees */
  latitude: Scalars['Float'];
  /** Longitude in decimal degrees */
  longitude: Scalars['Float'];
};

/** Input when updating a managed device */
export type UpdateManagedDeviceInput = {
  /** The device globally unique identifier */
  guid: Scalars['ID'];
  /** Internal IPv4 address for the device */
  ipv4?: InputMaybe<Scalars['String']>;
  /** The device location */
  location?: InputMaybe<UpdateLocationInput>;
};

export type UpdateOrganizationInput = {
  /** The list of admin's emails to be added */
  adminsAdded?: InputMaybe<Array<UpdateAdminOrganizationInput>>;
  /** The list of admin's emails to be delete */
  adminsDeleted?: InputMaybe<Array<Scalars['String']>>;
  /** The domain of the organization */
  domain?: InputMaybe<Scalars['String']>;
  /** The name of the organization */
  name?: InputMaybe<Scalars['String']>;
  /** The tenant of the organization */
  tenant: Scalars['UUID'];
};

/** Input when updating a user */
export type UpdateUserInput = {
  /** User identifier */
  id: Scalars['ID'];
  /** User name */
  name?: InputMaybe<Scalars['String']>;
  /** A complete list of roles that the user has assigned */
  roles?: InputMaybe<Array<UserRole>>;
};

/** User preferences */
export type UpdateUserPreferencesInput = {
  /** Dashboard preferences */
  dashboard?: InputMaybe<UpdateDashboardPreferencesInput>;
  /** User time zone */
  timeZone?: InputMaybe<Scalars['FixedOffset']>;
};

/** User information */
export type User = {
  __typename?: 'User';
  /** User email address */
  email: Scalars['String'];
  /** User identifier */
  id: Scalars['ID'];
  /** User name */
  name: Scalars['String'];
  /** A complete list of roles that the user has assigned */
  roles: Array<UserRole>;
};

/** User preferences */
export type UserPreferences = {
  __typename?: 'UserPreferences';
  /** Dashboard preferences */
  dashboard: DashboardPreferences;
  /** User's identifier */
  id: Scalars['ID'];
  /** User time zone */
  timeZone: Scalars['FixedOffset'];
};

/** Roles available to platform users */
export enum UserRole {
  OrgAdmin = 'ORG_ADMIN',
  OrgUser = 'ORG_USER',
  PlAdmin = 'PL_ADMIN',
}

export type GetForwardWorkflowQueryVariables = Exact<{
  forwardWorkflowId: Scalars['String'];
}>;

export type GetForwardWorkflowQuery = {
  __typename?: 'Query';
  forwardWorkflow?: {
    __typename?: 'ForwardWorkflow';
    id: string;
    name: string;
    enabled: boolean;
    lastSent?: any | null;
    triggerRules: Array<{
      __typename?: 'TriggerRule';
      source: TriggerRuleSources;
      valueList: Array<string>;
    }>;
    batchConfigs?: Array<{
      __typename?: 'BatchConfig';
      type: BatchTypes;
      threshold: number;
      condition: string;
      enabled: boolean;
    }> | null;
    channels: Array<{
      __typename?: 'Channel';
      type: ChannelTypes;
      valueList: Array<{
        __typename?: 'ChannelProperty';
        key: string;
        value?: string | null;
      }>;
    }>;
  } | null;
};

export type GetAlertWorkflowsQueryVariables = Exact<{
  filter?: InputMaybe<QueryForwardWorkflowsInput>;
}>;

export type GetAlertWorkflowsQuery = {
  __typename?: 'Query';
  forwardWorkflows: Array<{
    __typename?: 'ForwardWorkflow';
    id: string;
    name: string;
    enabled: boolean;
    lastSent?: any | null;
    triggerRules: Array<{
      __typename?: 'TriggerRule';
      source: TriggerRuleSources;
      valueList: Array<string>;
    }>;
    channels: Array<{
      __typename?: 'Channel';
      type: ChannelTypes;
      valueList: Array<{
        __typename?: 'ChannelProperty';
        key: string;
        value?: string | null;
      }>;
    }>;
  }>;
};

export type CreateForwardWorkflowMutationVariables = Exact<{
  input: CreateForwardWorkflowInput;
}>;

export type CreateForwardWorkflowMutation = {
  __typename?: 'Mutation';
  createForwardWorkflow: {
    __typename?: 'ForwardWorkflow';
    id: string;
    name: string;
    enabled: boolean;
    lastSent?: any | null;
    triggerRules: Array<{
      __typename?: 'TriggerRule';
      source: TriggerRuleSources;
      valueList: Array<string>;
    }>;
    batchConfigs?: Array<{
      __typename?: 'BatchConfig';
      type: BatchTypes;
      threshold: number;
      condition: string;
      enabled: boolean;
    }> | null;
    channels: Array<{
      __typename?: 'Channel';
      type: ChannelTypes;
      valueList: Array<{
        __typename?: 'ChannelProperty';
        key: string;
        value?: string | null;
      }>;
    }>;
  };
};

export type UpdateForwardWorkflowMutationVariables = Exact<{
  input: UpdateForwardWorkflowInput;
  notify?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateForwardWorkflowMutation = {
  __typename?: 'Mutation';
  updateForwardWorkflow: {
    __typename?: 'ForwardWorkflow';
    id: string;
    name: string;
    enabled: boolean;
    lastSent?: any | null;
    triggerRules: Array<{
      __typename?: 'TriggerRule';
      source: TriggerRuleSources;
      valueList: Array<string>;
    }>;
    batchConfigs?: Array<{
      __typename?: 'BatchConfig';
      type: BatchTypes;
      threshold: number;
      condition: string;
      enabled: boolean;
    }> | null;
    channels: Array<{
      __typename?: 'Channel';
      type: ChannelTypes;
      valueList: Array<{
        __typename?: 'ChannelProperty';
        key: string;
        value?: string | null;
      }>;
    }>;
  };
};

export type DeleteForwardWorkflowMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteForwardWorkflowMutation = {
  __typename?: 'Mutation';
  deleteForwardWorkflow: { __typename?: 'DeletePayload'; id: string };
};

export type GetSlackInstallationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSlackInstallationQuery = {
  __typename?: 'Query';
  slackInstallation?: {
    __typename?: 'SlackInstallation';
    teamName: string;
    installedAt: any;
  } | null;
};

export type GetSlackChannelsQueryVariables = Exact<{
  channelType?: InputMaybe<Array<SlackChannelType> | SlackChannelType>;
}>;

export type GetSlackChannelsQuery = {
  __typename?: 'Query';
  slackChannels: Array<{
    __typename?: 'SlackChannel';
    id: string;
    name: string;
    topic?: string | null;
    purpose?: string | null;
    channelType: SlackChannelType;
    isArchived: boolean;
    isGeneral: boolean;
  }>;
};

export type GetForwardGroupsQueryVariables = Exact<{
  filter: QueryForwardGroupsInput;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;

export type GetForwardGroupsQuery = {
  __typename?: 'Query';
  forwardGroups: {
    __typename?: 'ForwardGroupConnection';
    totalItems?: number | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasPreviousPage: boolean;
      hasNextPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    nodes: Array<{
      __typename?: 'ForwardGroup';
      id: string;
      sentAt: any;
      alertCodes: Array<string>;
      channelResults: Array<{
        __typename?: 'ChannelResults';
        channel: ChannelTypes;
        results: Array<{
          __typename?: 'ItemResult';
          item: string;
          result: boolean;
        }>;
      }>;
      workflow: { __typename?: 'ForwardWorkflow'; name: string };
    }>;
  };
};

export type GetAlertsQueryVariables = Exact<{
  filter: QueryAlertInput;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;

export type GetAlertsQuery = {
  __typename?: 'Query';
  alerts: {
    __typename?: 'AlertConnection';
    totalItems?: number | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasPreviousPage: boolean;
      hasNextPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    nodes: Array<{
      __typename?: 'Alert';
      id: string;
      code: string;
      type: AlertType;
      severity: AlertSeverity;
      message: string;
      createdAt: any;
      active: boolean;
      product: { __typename?: 'Product'; kind: string };
      appliance: {
        __typename?: 'Appliance';
        description: string;
        applianceId: string;
      };
    }>;
  };
};

export type GetAlertsCountQueryVariables = Exact<{
  appliance?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  from: Scalars['DateTime'];
  to?: InputMaybe<Scalars['DateTime']>;
  productKind?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  type?: InputMaybe<Array<AlertType> | AlertType>;
}>;

export type GetAlertsCountQuery = {
  __typename?: 'Query';
  CRITICAL: { __typename?: 'AlertConnection'; totalItems?: number | null };
  ERROR: { __typename?: 'AlertConnection'; totalItems?: number | null };
  STANDARD: { __typename?: 'AlertConnection'; totalItems?: number | null };
};

export type GetAlertQueryVariables = Exact<{
  alertId: Scalars['ID'];
}>;

export type GetAlertQuery = {
  __typename?: 'Query';
  alert: {
    __typename?: 'Alert';
    id: string;
    code: string;
    type: AlertType;
    severity: AlertSeverity;
    message: string;
    createdAt: any;
    active: boolean;
    info?: any | null;
    product: { __typename?: 'Product'; kind: string };
    appliance: {
      __typename?: 'Appliance';
      description: string;
      applianceId: string;
    };
  };
};

export type DeleteAlertsMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type DeleteAlertsMutation = {
  __typename?: 'Mutation';
  deleteAlerts: boolean;
};

export type ClearAllAlertsMutationVariables = Exact<{
  filter: QueryAlertInput;
}>;

export type ClearAllAlertsMutation = {
  __typename?: 'Mutation';
  clearAllAlerts: number;
};

export type RegisterApplianceMutationVariables = Exact<{
  input: RegisterApplianceInput;
}>;

export type RegisterApplianceMutation = {
  __typename?: 'Mutation';
  registerAppliance: {
    __typename?: 'RegisterAppliancePayload';
    applianceId: string;
    confirmationCode: string;
  };
};

export type GetApplianceQueryVariables = Exact<{
  applianceId: Scalars['String'];
}>;

export type GetApplianceQuery = {
  __typename?: 'Query';
  appliance: {
    __typename?: 'Appliance';
    id: string;
    applianceId: string;
    description: string;
    status: ApplianceStatus;
    installedProducts: Array<{
      __typename?: 'InstalledProduct';
      id: string;
      product: {
        __typename?: 'Product';
        kind: string;
        name: string;
        categories: Array<Category>;
      };
    }>;
  };
};

export type ConfirmApplianceMutationVariables = Exact<{
  applianceId: Scalars['String'];
  confirmationCode: Scalars['String'];
}>;

export type ConfirmApplianceMutation = {
  __typename?: 'Mutation';
  confirmAppliance: boolean;
};

export type GetAppliancesQueryVariables = Exact<{
  filter?: InputMaybe<QueryApplianceInput>;
}>;

export type GetAppliancesQuery = {
  __typename?: 'Query';
  appliances: Array<{
    __typename?: 'Appliance';
    id: string;
    applianceId: string;
    description: string;
    node: string;
    status: ApplianceStatus;
    installedProducts: Array<{
      __typename?: 'InstalledProduct';
      id: string;
      product: {
        __typename?: 'Product';
        name: string;
        kind: string;
        categories: Array<Category>;
      };
    }>;
  }>;
};

export type GetIntegrationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetIntegrationsQuery = {
  __typename?: 'Query';
  integrations: Array<{ __typename?: 'Integration'; id: string; name: string }>;
};

export type GetIntegrationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetIntegrationQuery = {
  __typename?: 'Query';
  integration: {
    __typename?: 'Integration';
    id: string;
    name: string;
    configSchema?: any | null;
    config?: any | null;
  };
};

export type DeleteIntegrationConfigMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteIntegrationConfigMutation = {
  __typename?: 'Mutation';
  deleteIntegrationConfig: boolean;
};

export type GetForwardWorkflowsWebhookChannelSpecsQueryVariables = Exact<{
  integrationId: Scalars['ID'];
}>;

export type GetForwardWorkflowsWebhookChannelSpecsQuery = {
  __typename?: 'Query';
  forwardWorkflowsWebhookChannelSpecs?: any | null;
};

export type UpdateIntegrationConfigMutationVariables = Exact<{
  id: Scalars['ID'];
  config: Scalars['JSON'];
}>;

export type UpdateIntegrationConfigMutation = {
  __typename?: 'Mutation';
  updateIntegrationConfig: boolean;
};

export type GetChartQueryVariables = Exact<{
  productKind: Scalars['String'];
  chartCode: Scalars['String'];
  from: Scalars['DateTime'];
  appliances?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetChartQuery = {
  __typename?: 'Query';
  chart: {
    __typename?: 'Chart';
    timeRange: number;
    value?: number | null;
    diffPercentage?: any | null;
    code: string;
    owner: { __typename?: 'Product'; kind: string };
    data: {
      __typename?: 'TimedLineChart';
      values: Array<{
        __typename?: 'TimedLineChartValue';
        time: any;
        value: number;
      }>;
    };
  };
};

export type GetCoveragesQueryVariables = Exact<{
  productKind: Scalars['String'];
  from: Scalars['DateTime'];
  appliance?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetCoveragesQuery = {
  __typename?: 'Query';
  coverage: {
    __typename?: 'Coverage';
    health: Array<{
      __typename?: 'CoverageEntry';
      key: Category;
      value: number;
    }>;
  };
};

export type GetInstalledProductsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetInstalledProductsQuery = {
  __typename?: 'Query';
  products: Array<{
    __typename?: 'Product';
    id: string;
    kind: string;
    categories: Array<Category>;
    name: string;
  }>;
};

export type GetInstalledProductsAlertsQueryVariables = Exact<{
  from?: InputMaybe<Scalars['DateTime']>;
  appliance?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetInstalledProductsAlertsQuery = {
  __typename?: 'Query';
  products: Array<{
    __typename?: 'Product';
    id: string;
    kind: string;
    categories: Array<Category>;
    name: string;
    alertsCount: {
      __typename?: 'AlertCount';
      critical: number;
      error: number;
      standard: number;
    };
  }>;
};

export type GetProductAlertsQueryVariables = Exact<{
  from: Scalars['DateTime'];
  appliance?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  productKind?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetProductAlertsQuery = {
  __typename?: 'Query';
  CRITICAL: { __typename?: 'AlertConnection'; totalItems?: number | null };
  ERROR: { __typename?: 'AlertConnection'; totalItems?: number | null };
  STANDARD: { __typename?: 'AlertConnection'; totalItems?: number | null };
};

export type GetInventoryApplianceQueryVariables = Exact<{
  applianceId: Scalars['String'];
}>;

export type GetInventoryApplianceQuery = {
  __typename?: 'Query';
  appliance: {
    __typename?: 'Appliance';
    id: string;
    applianceId: string;
    description: string;
    status: ApplianceStatus;
    online: boolean;
    ipv4?: string | null;
    osVersion?: string | null;
    lastSeen?: any | null;
    location?: {
      __typename?: 'Location';
      id: string;
      city?: string | null;
      country: string;
      latitude: number;
      longitude: number;
    } | null;
    installedProducts: Array<{
      __typename?: 'InstalledProduct';
      id: string;
      licenses: Array<{
        __typename?: 'InstalledProductLicense';
        expiration: any;
        licensedDevices: Array<{
          __typename?: 'LicensedDeviceLink';
          state: ManagedDeviceLicenseState;
          device: {
            __typename?: 'ManagedDevice';
            ipv4?: string | null;
            guid: string;
            name?: string | null;
            diskUsage?: any | null;
            diskCapacity?: any | null;
          };
        }>;
      }>;
      product: {
        __typename?: 'Product';
        name: string;
        kind: string;
        categories: Array<Category>;
      };
    }>;
  };
};

export type GetInventoryQueryVariables = Exact<{
  filter?: InputMaybe<QueryApplianceInput>;
}>;

export type GetInventoryQuery = {
  __typename?: 'Query';
  appliances: Array<{
    __typename?: 'Appliance';
    id: string;
    applianceId: string;
    description: string;
    node: string;
    status: ApplianceStatus;
    online: boolean;
    ipv4?: string | null;
    osVersion?: string | null;
    lastSeen?: any | null;
    location?: {
      __typename?: 'Location';
      id: string;
      city?: string | null;
      country: string;
      latitude: number;
      longitude: number;
    } | null;
    servers: Array<{
      __typename?: 'Server';
      id: string;
      createdAt: any;
      ipv4?: string | null;
      name?: string | null;
      port?: number | null;
      state: ServerState;
      type: ServerType;
      updatedAt?: any | null;
    }>;
    installedProducts: Array<{
      __typename?: 'InstalledProduct';
      id: string;
      softwareVersion?: string | null;
      product: {
        __typename?: 'Product';
        name: string;
        kind: string;
        categories: Array<Category>;
      };
      licenses: Array<{
        __typename?: 'InstalledProductLicense';
        expiration: any;
        perpetual: boolean;
        kind: LicenseKind;
        sizeConsumed: any;
        sizeLicensed: any;
        licensedDevices: Array<{
          __typename?: 'LicensedDeviceLink';
          state: ManagedDeviceLicenseState;
          device: {
            __typename?: 'ManagedDevice';
            ipv4?: string | null;
            guid: string;
            name?: string | null;
            diskUsage?: any | null;
            diskCapacity?: any | null;
          };
        }>;
      }>;
    }>;
  }>;
};

export type UpdateApplianceMutationVariables = Exact<{
  input: UpdateApplianceInput;
}>;

export type UpdateApplianceMutation = {
  __typename?: 'Mutation';
  updateAppliance: {
    __typename?: 'Appliance';
    id: string;
    applianceId: string;
    description: string;
    node: string;
    status: ApplianceStatus;
    online: boolean;
    ipv4?: string | null;
    osVersion?: string | null;
    lastSeen?: any | null;
    location?: {
      __typename?: 'Location';
      id: string;
      city?: string | null;
      country: string;
      latitude: number;
      longitude: number;
    } | null;
    installedProducts: Array<{
      __typename?: 'InstalledProduct';
      id: string;
      softwareVersion?: string | null;
      licenses: Array<{
        __typename?: 'InstalledProductLicense';
        expiration: any;
        licensedDevices: Array<{
          __typename?: 'LicensedDeviceLink';
          state: ManagedDeviceLicenseState;
          device: {
            __typename?: 'ManagedDevice';
            ipv4?: string | null;
            guid: string;
            name?: string | null;
            diskUsage?: any | null;
            diskCapacity?: any | null;
          };
        }>;
      }>;
      product: {
        __typename?: 'Product';
        name: string;
        kind: string;
        categories: Array<Category>;
      };
    }>;
  };
};

export type GetManagedDevicesQueryVariables = Exact<{ [key: string]: never }>;

export type GetManagedDevicesQuery = {
  __typename?: 'Query';
  managedDevices: Array<{
    __typename?: 'ManagedDevice';
    guid: string;
    name?: string | null;
    ipv4?: string | null;
    diskCapacity?: any | null;
    diskUsage?: any | null;
    platform?: PlatformType | null;
    location?: {
      __typename?: 'Location';
      id: string;
      city?: string | null;
      country: string;
      latitude: number;
      longitude: number;
    } | null;
    licenseLinks: Array<{
      __typename?: 'LicensedDeviceLink';
      state: ManagedDeviceLicenseState;
      device: {
        __typename?: 'ManagedDevice';
        ipv4?: string | null;
        guid: string;
        name?: string | null;
        diskUsage?: any | null;
        diskCapacity?: any | null;
      };
      license: {
        __typename?: 'InstalledProductLicense';
        expiration: any;
        licensedDevices: Array<{
          __typename?: 'LicensedDeviceLink';
          state: ManagedDeviceLicenseState;
          device: {
            __typename?: 'ManagedDevice';
            ipv4?: string | null;
            guid: string;
            name?: string | null;
            diskUsage?: any | null;
            diskCapacity?: any | null;
          };
        }>;
        installedProduct: {
          __typename?: 'InstalledProduct';
          product: { __typename?: 'Product'; kind: string; name: string };
        };
      };
    }>;
  }>;
};

export type GetManagedDeviceQueryVariables = Exact<{
  guid: Scalars['ID'];
}>;

export type GetManagedDeviceQuery = {
  __typename?: 'Query';
  managedDevice: {
    __typename?: 'ManagedDevice';
    guid: string;
    name?: string | null;
    ipv4?: string | null;
    diskCapacity?: any | null;
    diskUsage?: any | null;
    platform?: PlatformType | null;
    location?: {
      __typename?: 'Location';
      id: string;
      city?: string | null;
      country: string;
      latitude: number;
      longitude: number;
    } | null;
    licenseLinks: Array<{
      __typename?: 'LicensedDeviceLink';
      state: ManagedDeviceLicenseState;
      device: {
        __typename?: 'ManagedDevice';
        ipv4?: string | null;
        guid: string;
        name?: string | null;
        diskUsage?: any | null;
        diskCapacity?: any | null;
      };
    }>;
  };
};

export type UpdateManagedDeviceMutationVariables = Exact<{
  input: UpdateManagedDeviceInput;
}>;

export type UpdateManagedDeviceMutation = {
  __typename?: 'Mutation';
  updateManagedDevice: {
    __typename?: 'ManagedDevice';
    guid: string;
    name?: string | null;
    ipv4?: string | null;
    diskCapacity?: any | null;
    diskUsage?: any | null;
    location?: {
      __typename?: 'Location';
      id: string;
      city?: string | null;
      country: string;
      latitude: number;
      longitude: number;
    } | null;
    licenseLinks: Array<{
      __typename?: 'LicensedDeviceLink';
      state: ManagedDeviceLicenseState;
      device: {
        __typename?: 'ManagedDevice';
        ipv4?: string | null;
        guid: string;
        name?: string | null;
        diskUsage?: any | null;
        diskCapacity?: any | null;
      };
    }>;
  };
};

export type DeleteApplianceMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteApplianceMutation = {
  __typename?: 'Mutation';
  deleteAppliance: boolean;
};

export type DeleteManagedDeviceMutationVariables = Exact<{
  guid: Scalars['ID'];
  appliance?: InputMaybe<Scalars['String']>;
}>;

export type DeleteManagedDeviceMutation = {
  __typename?: 'Mutation';
  deleteManagedDevice: boolean;
};

export type GetApplianceServerComponentsQueryVariables = Exact<{
  serverId: Scalars['ID'];
  applianceId: Scalars['String'];
}>;

export type GetApplianceServerComponentsQuery = {
  __typename?: 'Query';
  applianceServerComponents: Array<{
    __typename?: 'ServerComponent';
    cpu?: number | null;
    details?: string | null;
    healthStatus: HealthStatus;
    name: string;
    ram?: number | null;
    webAddress?: string | null;
  }>;
};

export type FindLocationCandidatesQueryVariables = Exact<{
  search: Scalars['String'];
}>;

export type FindLocationCandidatesQuery = {
  __typename?: 'Query';
  findLocationCandidates: Array<{
    __typename?: 'LocationCandidate';
    id: string;
    label: string;
  }>;
};

export type ResolveLocationCandidateQueryVariables = Exact<{
  candidateId: Scalars['ID'];
}>;

export type ResolveLocationCandidateQuery = {
  __typename?: 'Query';
  resolveLocationCandidate: {
    __typename?: 'Location';
    id: string;
    latitude: number;
    longitude: number;
    country: string;
    city?: string | null;
  };
};

export type DeleteApplianceLocationMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteApplianceLocationMutation = {
  __typename?: 'Mutation';
  deleteApplianceLocation: boolean;
};

export type DeleteManagedDeviceLocationMutationVariables = Exact<{
  guid: Scalars['ID'];
}>;

export type DeleteManagedDeviceLocationMutation = {
  __typename?: 'Mutation';
  deleteManagedDeviceLocation: boolean;
};

export type GetAuditLogsQueryVariables = Exact<{
  filter: QueryAuditLogInput;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;

export type GetAuditLogsQuery = {
  __typename?: 'Query';
  auditLogs: {
    __typename?: 'AuditLogConnection';
    totalItems?: number | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    nodes: Array<{
      __typename?: 'AuditLog';
      action: AuditLogAction;
      createdAt: any;
      id: string;
      message: string;
      tenant: any;
      error: boolean;
      author: { __typename?: 'AuditAuthor'; userEmail: string; userId: string };
      properties: Array<{
        __typename?: 'AuditLogPropertyEntry';
        key: string;
        value: string;
      }>;
    }>;
  };
};

export type GetOrganizationsQueryVariables = Exact<{
  filter: QueryOrganizationInput;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;

export type GetOrganizationsQuery = {
  __typename?: 'Query';
  organizations: {
    __typename?: 'OrganizationConnection';
    totalItems?: number | null;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    edges: Array<{
      __typename?: 'OrganizationEdge';
      cursor: string;
      node: {
        __typename?: 'Organization';
        id: string;
        tenant: any;
        name: string;
        domain?: string | null;
        createdAt: any;
        admins: Array<{
          __typename?: 'User';
          id: string;
          name: string;
          email: string;
        }>;
      };
    }>;
  };
};

export type GetOrganizationQueryVariables = Exact<{
  tenant: Scalars['UUID'];
}>;

export type GetOrganizationQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    tenant: any;
    name: string;
    domain?: string | null;
    createdAt: any;
    admins: Array<{
      __typename?: 'User';
      id: string;
      name: string;
      email: string;
    }>;
  };
};

export type CreateOrganizationMutationVariables = Exact<{
  input: CreateOrganizationInput;
}>;

export type CreateOrganizationMutation = {
  __typename?: 'Mutation';
  createOrganization: {
    __typename?: 'Organization';
    name: string;
    tenant: any;
    admins: Array<{
      __typename?: 'User';
      email: string;
      id: string;
      name: string;
      roles: Array<UserRole>;
    }>;
  };
};

export type UpdateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;

export type UpdateOrganizationMutation = {
  __typename?: 'Mutation';
  updateOrganization: {
    __typename?: 'Organization';
    id: string;
    tenant: any;
    name: string;
    domain?: string | null;
    createdAt: any;
    admins: Array<{
      __typename?: 'User';
      id: string;
      email: string;
      name: string;
      roles: Array<UserRole>;
    }>;
  };
};

export type DeleteOrganizationMutationVariables = Exact<{
  tenant: Scalars['UUID'];
}>;

export type DeleteOrganizationMutation = {
  __typename?: 'Mutation';
  deleteOrganization: { __typename?: 'DeletePayload'; id: string };
};

export type GetAvailableStatsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAvailableStatsQuery = {
  __typename?: 'Query';
  products: Array<{
    __typename?: 'Product';
    id: string;
    kind: string;
    statsAvailable: Array<{
      __typename?: 'ChartInfo';
      code: string;
      title: string;
      description: string;
      unit: string;
      polarity: ChartPolarity;
    }>;
  }>;
};

export type GetAppAvailableStatsQueryVariables = Exact<{
  kind: Scalars['String'];
}>;

export type GetAppAvailableStatsQuery = {
  __typename?: 'Query';
  product: {
    __typename?: 'Product';
    id: string;
    kind: string;
    statsAvailable: Array<{
      __typename?: 'ChartInfo';
      code: string;
      title: string;
      description: string;
      unit: string;
      polarity: ChartPolarity;
    }>;
  };
};

export type GetAppSettingsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetAppSettingsQuery = {
  __typename?: 'Query';
  appSettings: {
    __typename?: 'ApplicationSettings';
    id: string;
    deployment: {
      __typename?: 'ApplicationDeployment';
      instances: number;
      location: {
        __typename?: 'DeploymentLocation';
        host: string;
        port: string;
      };
    };
    configSchema: { __typename?: 'AppConfigSchema'; id: string; value: any };
    config: { __typename?: 'AppConfig'; id: string; value: any };
  };
};

export type UpdateSettingsAppConfigMutationVariables = Exact<{
  id: Scalars['ID'];
  config: Scalars['JSON'];
}>;

export type UpdateSettingsAppConfigMutation = {
  __typename?: 'Mutation';
  updateSettingsConfig: { __typename?: 'AppConfig'; id: string; value: any };
};

export type GetUsersQueryVariables = Exact<{
  filter: QueryUserInput;
}>;

export type GetUsersQuery = {
  __typename?: 'Query';
  users: Array<{
    __typename?: 'User';
    id: string;
    email: string;
    name: string;
    roles: Array<UserRole>;
  }>;
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: {
    __typename?: 'User';
    id: string;
    email: string;
    name: string;
    roles: Array<UserRole>;
  };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: string;
    email: string;
    name: string;
    roles: Array<UserRole>;
  };
};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteUserMutation = {
  __typename?: 'Mutation';
  deleteUser: { __typename?: 'DeletePayload'; id: string };
};

export type UserPreferencesQueryVariables = Exact<{ [key: string]: never }>;

export type UserPreferencesQuery = {
  __typename?: 'Query';
  userPreferences: {
    __typename?: 'UserPreferences';
    id: string;
    timeZone: any;
    dashboard: {
      __typename?: 'DashboardPreferences';
      timeSpan: any;
      apps: Array<{
        __typename?: 'AppDashboardPreferencesEntry';
        key: string;
        value: {
          __typename?: 'AppDashboardPreferences';
          charts: Array<string>;
        };
      }>;
    };
  };
};

export type UpdateUserPreferencesMutationVariables = Exact<{
  input: UpdateUserPreferencesInput;
}>;

export type UpdateUserPreferencesMutation = {
  __typename?: 'Mutation';
  updateUserPreferences: {
    __typename?: 'UserPreferences';
    id: string;
    timeZone: any;
    dashboard: {
      __typename?: 'DashboardPreferences';
      timeSpan: any;
      apps: Array<{
        __typename?: 'AppDashboardPreferencesEntry';
        key: string;
        value: {
          __typename?: 'AppDashboardPreferences';
          charts: Array<string>;
        };
      }>;
    };
  };
};

export type GetAvailablePublicProductsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAvailablePublicProductsQuery = {
  __typename?: 'Query';
  products: Array<{
    __typename?: 'Product';
    kind: string;
    name: string;
    categories: Array<Category>;
    comingSoon: boolean;
  }>;
};

export type RequestTrialMutationVariables = Exact<{
  input: RequestTrialInput;
}>;

export type RequestTrialMutation = {
  __typename?: 'Mutation';
  requestTrial: boolean;
};

export const GetForwardWorkflowDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetForwardWorkflow' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'forwardWorkflowId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forwardWorkflow' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'forwardWorkflowId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'triggerRules' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'source' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valueList' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'batchConfigs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'threshold' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'condition' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valueList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetForwardWorkflowQuery,
  GetForwardWorkflowQueryVariables
>;
export const GetAlertWorkflowsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAlertWorkflows' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'QueryForwardWorkflowsInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forwardWorkflows' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'triggerRules' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'source' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valueList' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valueList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSent' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAlertWorkflowsQuery,
  GetAlertWorkflowsQueryVariables
>;
export const CreateForwardWorkflowDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateForwardWorkflow' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateForwardWorkflowInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createForwardWorkflow' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'triggerRules' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'source' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valueList' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'batchConfigs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'threshold' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'condition' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valueList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateForwardWorkflowMutation,
  CreateForwardWorkflowMutationVariables
>;
export const UpdateForwardWorkflowDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateForwardWorkflow' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateForwardWorkflowInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'notify' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateForwardWorkflow' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notify' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'notify' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSent' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'triggerRules' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'source' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valueList' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'batchConfigs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'threshold' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'condition' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'channels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'valueList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateForwardWorkflowMutation,
  UpdateForwardWorkflowMutationVariables
>;
export const DeleteForwardWorkflowDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteForwardWorkflow' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteForwardWorkflow' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteForwardWorkflowMutation,
  DeleteForwardWorkflowMutationVariables
>;
export const GetSlackInstallationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSlackInstallation' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slackInstallation' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'teamName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'installedAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSlackInstallationQuery,
  GetSlackInstallationQueryVariables
>;
export const GetSlackChannelsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSlackChannels' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'channelType' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SlackChannelType' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slackChannels' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'channelType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'channelType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'topic' } },
                { kind: 'Field', name: { kind: 'Name', value: 'purpose' } },
                { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isArchived' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isGeneral' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSlackChannelsQuery,
  GetSlackChannelsQueryVariables
>;
export const GetForwardGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetForwardGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'QueryForwardGroupsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forwardGroups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasPreviousPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalItems' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sentAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'alertCodes' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'channelResults' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'channel' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'results' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'item' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'result' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workflow' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetForwardGroupsQuery,
  GetForwardGroupsQueryVariables
>;
export const GetAlertsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAlerts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'QueryAlertInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alerts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasPreviousPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalItems' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'severity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kind' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'appliance' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applianceId' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAlertsQuery, GetAlertsQueryVariables>;
export const GetAlertsCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAlertsCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appliance' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productKind' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'AlertType' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'CRITICAL' },
            name: { kind: 'Name', value: 'alerts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'severity' },
                      value: { kind: 'EnumValue', value: 'CRITICAL' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'from' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'from' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'to' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'to' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'appliance' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'appliance' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'productKind' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'productKind' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalItems' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ERROR' },
            name: { kind: 'Name', value: 'alerts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'severity' },
                      value: { kind: 'EnumValue', value: 'ERROR' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'from' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'from' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'to' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'to' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'appliance' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'appliance' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'productKind' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'productKind' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalItems' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'STANDARD' },
            name: { kind: 'Name', value: 'alerts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'severity' },
                      value: { kind: 'EnumValue', value: 'STANDARD' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'from' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'from' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'to' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'to' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'appliance' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'appliance' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'productKind' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'productKind' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'type' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalItems' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAlertsCountQuery, GetAlertsCountQueryVariables>;
export const GetAlertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAlert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'alertId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alert' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'alertId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'severity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'active' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appliance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applianceId' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'info' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAlertQuery, GetAlertQueryVariables>;
export const DeleteAlertsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAlerts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAlerts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAlertsMutation,
  DeleteAlertsMutationVariables
>;
export const ClearAllAlertsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ClearAllAlerts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'QueryAlertInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clearAllAlerts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClearAllAlertsMutation,
  ClearAllAlertsMutationVariables
>;
export const RegisterApplianceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegisterAppliance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RegisterApplianceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registerAppliance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'applianceId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'confirmationCode' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterApplianceMutation,
  RegisterApplianceMutationVariables
>;
export const GetApplianceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAppliance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applianceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appliance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'applianceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applianceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'installedProducts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kind' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'categories' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetApplianceQuery, GetApplianceQueryVariables>;
export const ConfirmApplianceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConfirmAppliance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applianceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'confirmationCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmAppliance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'applianceId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'applianceId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'confirmationCode' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'confirmationCode' },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfirmApplianceMutation,
  ConfirmApplianceMutationVariables
>;
export const GetAppliancesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAppliances' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'QueryApplianceInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appliances' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applianceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'node' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'installedProducts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kind' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'categories' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAppliancesQuery, GetAppliancesQueryVariables>;
export const GetIntegrationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetIntegrations' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'integrations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetIntegrationsQuery,
  GetIntegrationsQueryVariables
>;
export const GetIntegrationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetIntegration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'integration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'configSchema' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'config' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetIntegrationQuery, GetIntegrationQueryVariables>;
export const DeleteIntegrationConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteIntegrationConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteIntegrationConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteIntegrationConfigMutation,
  DeleteIntegrationConfigMutationVariables
>;
export const GetForwardWorkflowsWebhookChannelSpecsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetForwardWorkflowsWebhookChannelSpecs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'integrationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'forwardWorkflowsWebhookChannelSpecs',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'integrationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'integrationId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetForwardWorkflowsWebhookChannelSpecsQuery,
  GetForwardWorkflowsWebhookChannelSpecsQueryVariables
>;
export const UpdateIntegrationConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateIntegrationConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'config' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateIntegrationConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'config' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'config' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateIntegrationConfigMutation,
  UpdateIntegrationConfigMutationVariables
>;
export const GetChartDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetChart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productKind' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chartCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appliances' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'product' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'productKind' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'productKind' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'chartCode' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'chartCode' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'from' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'from' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'appliance' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'appliances' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'timeRange' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'diffPercentage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'owner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Product' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kind' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'TimedLineChart' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'values' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'time' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetChartQuery, GetChartQueryVariables>;
export const GetCoveragesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCoverages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productKind' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appliance' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coverage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'productKind' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'productKind' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'from' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'from' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'appliance' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'appliance' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'health' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCoveragesQuery, GetCoveragesQueryVariables>;
export const GetInstalledProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInstalledProducts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInstalledProductsQuery,
  GetInstalledProductsQueryVariables
>;
export const GetInstalledProductsAlertsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInstalledProductsAlerts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appliance' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alertsCount' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'from' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'from' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'appliance' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'appliance' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'critical' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'standard' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInstalledProductsAlertsQuery,
  GetInstalledProductsAlertsQueryVariables
>;
export const GetProductAlertsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProductAlerts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'from' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appliance' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productKind' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'CRITICAL' },
            name: { kind: 'Name', value: 'alerts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'severity' },
                      value: { kind: 'EnumValue', value: 'CRITICAL' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'from' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'from' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'appliance' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'appliance' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'productKind' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'productKind' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalItems' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'ERROR' },
            name: { kind: 'Name', value: 'alerts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'severity' },
                      value: { kind: 'EnumValue', value: 'ERROR' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'from' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'from' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'appliance' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'appliance' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'productKind' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'productKind' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalItems' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'STANDARD' },
            name: { kind: 'Name', value: 'alerts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'severity' },
                      value: { kind: 'EnumValue', value: 'STANDARD' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'from' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'from' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: { kind: 'BooleanValue', value: true },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'appliance' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'appliance' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'productKind' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'productKind' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalItems' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetProductAlertsQuery,
  GetProductAlertsQueryVariables
>;
export const GetInventoryApplianceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInventoryAppliance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applianceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appliance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'applianceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applianceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'online' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipv4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'osVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSeen' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'installedProducts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'licenses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'expiration' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'licensedDevices' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'device' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'ipv4' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'guid' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'diskUsage',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'diskCapacity',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kind' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'categories' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInventoryApplianceQuery,
  GetInventoryApplianceQueryVariables
>;
export const GetInventoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetInventory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'QueryApplianceInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appliances' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applianceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'node' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'online' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipv4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'osVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSeen' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'servers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'ipv4' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'port' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'installedProducts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'softwareVersion' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kind' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'categories' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'licenses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'expiration' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'perpetual' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kind' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sizeConsumed' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sizeLicensed' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'licensedDevices' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'device' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'ipv4' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'guid' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'diskUsage',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'diskCapacity',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetInventoryQuery, GetInventoryQueryVariables>;
export const UpdateApplianceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAppliance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateApplianceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAppliance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'applianceId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'node' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'online' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipv4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'osVersion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastSeen' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'installedProducts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'softwareVersion' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'licenses' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'expiration' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'licensedDevices' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'device' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'ipv4' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'guid' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'diskUsage',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'diskCapacity',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kind' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'categories' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateApplianceMutation,
  UpdateApplianceMutationVariables
>;
export const GetManagedDevicesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getManagedDevices' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managedDevices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipv4' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'diskCapacity' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'diskUsage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'platform' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'licenseLinks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'device' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ipv4' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'guid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'diskUsage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'diskCapacity' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'license' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'expiration' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'licensedDevices' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'state' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'device' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'ipv4' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'guid' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'diskUsage',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'diskCapacity',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'installedProduct' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'product' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'kind' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetManagedDevicesQuery,
  GetManagedDevicesQueryVariables
>;
export const GetManagedDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetManagedDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'guid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'managedDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'guid' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipv4' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'diskCapacity' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'diskUsage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'platform' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'licenseLinks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'device' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ipv4' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'guid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'diskUsage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'diskCapacity' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetManagedDeviceQuery,
  GetManagedDeviceQueryVariables
>;
export const UpdateManagedDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateManagedDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateManagedDeviceInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateManagedDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'guid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ipv4' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'diskCapacity' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'diskUsage' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latitude' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'longitude' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'licenseLinks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'device' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ipv4' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'guid' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'diskUsage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'diskCapacity' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateManagedDeviceMutation,
  UpdateManagedDeviceMutationVariables
>;
export const DeleteApplianceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAppliance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAppliance' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteApplianceMutation,
  DeleteApplianceMutationVariables
>;
export const DeleteManagedDeviceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteManagedDevice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'guid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appliance' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteManagedDevice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'appliance' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'appliance' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'guid' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteManagedDeviceMutation,
  DeleteManagedDeviceMutationVariables
>;
export const GetApplianceServerComponentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetApplianceServerComponents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'serverId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applianceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applianceServerComponents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'serverId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'serverId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'applianceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'applianceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cpu' } },
                { kind: 'Field', name: { kind: 'Name', value: 'details' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'healthStatus' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ram' } },
                { kind: 'Field', name: { kind: 'Name', value: 'webAddress' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetApplianceServerComponentsQuery,
  GetApplianceServerComponentsQueryVariables
>;
export const FindLocationCandidatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindLocationCandidates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findLocationCandidates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'search' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'StringValue', value: 'en', block: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindLocationCandidatesQuery,
  FindLocationCandidatesQueryVariables
>;
export const ResolveLocationCandidateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ResolveLocationCandidate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'candidateId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resolveLocationCandidate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'candidateId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'candidateId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'language' },
                value: { kind: 'StringValue', value: 'en', block: false },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResolveLocationCandidateQuery,
  ResolveLocationCandidateQueryVariables
>;
export const DeleteApplianceLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteApplianceLocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteApplianceLocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteApplianceLocationMutation,
  DeleteApplianceLocationMutationVariables
>;
export const DeleteManagedDeviceLocationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteManagedDeviceLocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'guid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteManagedDeviceLocation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'guid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'guid' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteManagedDeviceLocationMutation,
  DeleteManagedDeviceLocationMutationVariables
>;
export const GetAuditLogsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAuditLogs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'QueryAuditLogInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auditLogs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalItems' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasPreviousPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'action' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'author' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userEmail' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenant' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'error' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'properties' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAuditLogsQuery, GetAuditLogsQueryVariables>;
export const GetOrganizationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrganizations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'QueryOrganizationInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalItems' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasPreviousPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tenant' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'domain' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'admins' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationsQuery,
  GetOrganizationsQueryVariables
>;
export const GetOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tenant' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenant' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tenant' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenant' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'admins' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetOrganizationQuery,
  GetOrganizationQueryVariables
>;
export const CreateOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateOrganizationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'admins' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenant' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;
export const UpdateOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateOrganizationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tenant' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'admins' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;
export const DeleteOrganizationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tenant' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tenant' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'tenant' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables
>;
export const GetAvailableStatsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAvailableStats' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'statsAvailable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'polarity' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAvailableStatsQuery,
  GetAvailableStatsQueryVariables
>;
export const GetAppAvailableStatsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAppAvailableStats' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'kind' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'kind' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'kind' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'statsAvailable' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'unit' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'polarity' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAppAvailableStatsQuery,
  GetAppAvailableStatsQueryVariables
>;
export const GetAppSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAppSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deployment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'host' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'port' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instances' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'configSchema' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'config' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAppSettingsQuery, GetAppSettingsQueryVariables>;
export const UpdateSettingsAppConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSettingsAppConfig' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'config' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSettingsConfig' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'config' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSettingsAppConfigMutation,
  UpdateSettingsAppConfigMutationVariables
>;
export const GetUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'QueryUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUsersQuery, GetUsersQueryVariables>;
export const CreateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>;
export const UserPreferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserPreferences' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userPreferences' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dashboard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timeSpan' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'apps' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'charts' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserPreferencesQuery,
  UserPreferencesQueryVariables
>;
export const UpdateUserPreferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserPreferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUserPreferencesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserPreferences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dashboard' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timeSpan' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'apps' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'charts' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateUserPreferencesMutation,
  UpdateUserPreferencesMutationVariables
>;
export const GetAvailablePublicProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAvailablePublicProducts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comingSoon' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAvailablePublicProductsQuery,
  GetAvailablePublicProductsQueryVariables
>;
export const RequestTrialDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RequestTrial' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestTrialInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestTrial' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestTrialMutation,
  RequestTrialMutationVariables
>;
