import { useState } from 'react';
import { SimpleButton, SimpleButtonProps } from '../../Buttons/SimpleButton';
import Spinner from '../../Elements/Spinner/Spinner';
import { Modal, ModalProps } from './Modal';

export interface ConfirmationModalProps extends ModalProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onConfirm?: () => boolean | Promise<boolean>;
  onCancel?: () => boolean | Promise<boolean>;
  disabled?: boolean;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  cancelButtonProps?: Partial<SimpleButtonProps>;
  confirmButtonProps?: Partial<SimpleButtonProps>;
}

export const ConfirmationModal = ({
  children,
  onConfirm,
  onClose,
  onCancel,
  disabled,
  confirmButtonLabel = 'Confirm',
  cancelButtonLabel = 'Cancel',
  confirmButtonProps,
  cancelButtonProps,
  ...props
}: ConfirmationModalProps) => {
  const [isWaiting, setIsWaiting] = useState(false);

  const onConfirmHandler = async () => {
    setIsWaiting(true);
    const isOk = onConfirm ? await onConfirm() : true;
    setIsWaiting(false);
    isOk && onClose?.();
  };
  const onCancelHandler = async () => {
    setIsWaiting(true);
    const isOk = onCancel ? await onCancel() : true;
    setIsWaiting(false);
    isOk && onClose?.();
  };

  return (
    <Modal {...props} onClose={onClose}>
      <>
        {children}
        <div className="mt-4 flex items-center justify-end gap-2">
          {isWaiting && <Spinner className="h-5 w-5" />}
          <SimpleButton
            className="text-sm"
            variant="default-ghost"
            onClick={onCancelHandler}
            disabled={isWaiting}
            {...cancelButtonProps}
          >
            {cancelButtonLabel}
          </SimpleButton>
          <SimpleButton
            data-testid="confirm-modal-button"
            className="px-4 text-sm"
            variant="default"
            onClick={onConfirmHandler}
            disabled={isWaiting}
            {...confirmButtonProps}
          >
            {confirmButtonLabel}
          </SimpleButton>
        </div>
      </>
    </Modal>
  );
};
