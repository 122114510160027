import { useQuery } from '@apollo/client';
import { useFeature } from 'featurehub-react-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  GET_INVENTORY,
  GET_MANAGED_DEVICES,
} from '../../api/inventory/queries';
import Appliances, {
  AppliancesProps,
} from '../../components/Inventory/Appliances/Appliances';
import { ServerComponentsModal } from '../../components/Inventory/Appliances/components/ServerComponentsModal';
import EditApplianceModalWithQueries from '../../components/Inventory/EditApplianceModalWithQueries';
import EditDeviceModalWithQueries from '../../components/Inventory/EditDeviceModalWithQueries';
import InventoryFilterButtonHolder from '../../components/Inventory/InventoryFilter/InventoryFilterButtonHolder';
import ManagedDevices, {
  ManagedDevicesProps,
} from '../../components/Inventory/ManagedDevices/ManagedDevices';
import Map from '../../components/Inventory/Map/Map';
import {
  LocationPoint,
  sortedLocationPoints,
} from '../../components/Inventory/Map/functions';
import { IFilterButton } from '../../components/Inventory/types';
import { ContentLayout } from '../../components/Layout/ContentLayout';
import {
  ApplianceStatus,
  GetInventoryQuery,
  GetManagedDevicesQuery,
} from '../../gql/graphql';
import { FeatureFlag } from '../../utils/featureHub/constants';
import { useScrollToAnchor } from '../../utils/hooks/useScrollToAnchor';
import { useSession } from '../../utils/hooks/useSession';
import { isProductKind } from '../../utils/products';
import { useProducts } from '../dashboard/hooks/useProducts';
import { AppliancesStatusBar } from './AppliancesStatusBar';
import { devicesToFilterButton } from './utils';

export enum InventorySearchParams {
  Capabilites = 'capabilities',
  Appliance = 'appliance',
  Server = 'supporting-node',
  Device = 'device',
}

export const InventoryPage = () => {
  const {
    loading,
    data: inventory,
    error,
    refetch: refetchInventory,
  } = useQuery<GetInventoryQuery>(GET_INVENTORY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: devices,
    loading: loadingDevices,
    error: errorDevices,
    refetch: refetchDevices,
  } = useQuery<GetManagedDevicesQuery>(GET_MANAGED_DEVICES, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const { installedProducts } = useProducts();
  // list of products in managed devices
  const filterButtonProducts = useMemo(
    () => devicesToFilterButton(devices, inventory, installedProducts),
    [devices, installedProducts, inventory],
  );

  const selectedProducts = useMemo(
    () =>
      searchParams
        .get(InventorySearchParams.Capabilites)
        ?.split(', ')
        .map((kind) =>
          filterButtonProducts?.find(
            (filter) => isProductKind(kind) && filter.kind === kind,
          ),
        )
        .filter((item): item is IFilterButton => !!item) || [],
    [searchParams, filterButtonProducts],
  );
  const setSelectedProducts = useCallback(
    (params?: Array<IFilterButton> | null) =>
      setSearchParams((searchParams) => {
        params?.length
          ? searchParams.set(
              InventorySearchParams.Capabilites,
              params
                ?.map((capabilitiesFilter) => capabilitiesFilter.kind)
                .join(', ') || '',
            )
          : searchParams.delete(InventorySearchParams.Capabilites);
        return searchParams;
      }),
    [setSearchParams],
  );

  const filteredInventory = useMemo(
    () =>
      inventory?.appliances.filter(
        (app) =>
          !selectedProducts?.length ||
          selectedProducts.some((prod) =>
            app.installedProducts.find(
              (iProd) => iProd.product.kind === prod.kind,
            ),
          ),
      ),
    [inventory, selectedProducts],
  );
  const filteredDevices = useMemo(
    () =>
      devices?.managedDevices.filter(
        (dev) =>
          !selectedProducts?.length ||
          selectedProducts.some((prod) =>
            dev.licenseLinks.find(
              (licenseLink) =>
                licenseLink.license.installedProduct.product.kind === prod.kind,
            ),
          ),
      ),
    [devices, selectedProducts],
  );

  const locations: LocationPoint[] = useMemo(
    () =>
      sortedLocationPoints(
        (filteredDevices || [])
          .filter(({ location }) => Boolean(location))
          .map(
            ({ location, guid, name }): LocationPoint => ({
              id: guid,
              location,
              type: 'device',
              status: 'online',
              text: name || guid,
            }),
          )
          .concat(
            (filteredInventory || [])
              .filter(({ location }) => Boolean(location))
              .map(
                ({ id, location, description, status }): LocationPoint => ({
                  id,
                  location,
                  type: 'appliance',
                  status:
                    status === ApplianceStatus.Active ? 'online' : 'offline',
                  text: description,
                }),
              ),
          ),
      ) || [],
    [filteredDevices, filteredInventory],
  );

  const { isAdmin, isSuperAdmin } = useSession();

  //RELEASE FLAGS
  const inventoryManagedDevicesFlag = useFeature(
    FeatureFlag.INVENTORY_MANAGED_DEVICES,
  );
  const serverComponentsFlag = useFeature(
    FeatureFlag.INVENTORY_APPLIANCE_SERVER_COMPONENTS,
  );

  const [serverId, setServerId] = useState('');
  useEffect(() => {
    const id = searchParams.get(InventorySearchParams.Server);
    !!id && setServerId(id);
  }, [searchParams]);

  const currentServer = useMemo(
    () =>
      serverId
        ? inventory?.appliances
            .flatMap((appliance) => ({
              applianceId: appliance.applianceId,
              server: appliance.servers.find(
                (server) => server.id === serverId,
              ),
            }))
            .find(
              (serverWithApplianceId) =>
                serverWithApplianceId.server?.id === serverId,
            )
        : undefined,
    [serverId],
  );

  const handleSettingsClick = useCallback<AppliancesProps['onSettingsClick']>(
    (appliance) =>
      appliance?.id &&
      setSearchParams((searchParams) => {
        searchParams.set(InventorySearchParams.Appliance, appliance?.id);
        return searchParams;
      }),
    [setSearchParams],
  );

  const handleServerDetailsClick = useCallback<
    Required<AppliancesProps>['onClickServerDetails']
  >(
    (server) =>
      server?.id &&
      setSearchParams((searchParams) => {
        searchParams.set(InventorySearchParams.Server, server?.id);
        return searchParams;
      }),
    [setSearchParams],
  );
  const handleDeviceSettingsClick = useCallback<
    ManagedDevicesProps['onSettingsClick']
  >(
    (device) => {
      device?.guid &&
        setSearchParams((searchParams) => {
          searchParams.set(InventorySearchParams.Device, device?.guid);
          return searchParams;
        });
    },
    [setSearchParams],
  );

  const handleModalClose = useCallback(
    (inventorySearchParam: InventorySearchParams) =>
      setSearchParams((searchParams) => {
        searchParams.delete(inventorySearchParam);
        return searchParams;
      }),
    [setSearchParams],
  );

  const handleServerComponentsClose = useCallback(
    () => handleModalClose(InventorySearchParams.Server),
    [handleModalClose],
  );
  const handleApplianceClose = useCallback(
    () => handleModalClose(InventorySearchParams.Appliance),
    [handleModalClose],
  );
  const handleDeviceClose = useCallback(
    () => handleModalClose(InventorySearchParams.Device),
    [handleModalClose],
  );

  // Scrolls to anchor after loading both devices and inventory
  const scrollTrigger = useMemo(
    () => !!filteredDevices && !!filteredInventory,
    [filteredDevices, filteredInventory],
  );
  useScrollToAnchor([scrollTrigger]);

  return (
    <>
      {serverComponentsFlag && (
        <ServerComponentsModal
          isOpen={Boolean(searchParams.get(InventorySearchParams.Server))}
          server={currentServer?.server || undefined}
          applianceId={currentServer?.applianceId}
          onClose={handleServerComponentsClose}
        />
      )}
      {(isAdmin || isSuperAdmin) && (
        <>
          <EditApplianceModalWithQueries
            id={searchParams.get(InventorySearchParams.Appliance) || undefined}
            onClose={handleApplianceClose}
          />
          <EditDeviceModalWithQueries
            id={searchParams.get(InventorySearchParams.Device) || undefined}
            onClose={handleDeviceClose}
          />
        </>
      )}
      <ContentLayout data-testid="inventory-content-layout" title={'Inventory'}>
        <div className="flex flex-col gap-y-4">
          {inventoryManagedDevicesFlag && (
            <InventoryFilterButtonHolder
              products={filterButtonProducts}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
            />
          )}
          <AppliancesStatusBar appliances={filteredInventory} />
          <Map listOfLocations={locations} />
          <Appliances
            loading={loading}
            error={error && 'Unexpected error loading Appliances'}
            refetch={refetchInventory}
            inventoryAppliances={filteredInventory}
            onSettingsClick={handleSettingsClick}
            onClickServerDetails={handleServerDetailsClick}
          />
          <ManagedDevices
            loading={loadingDevices}
            error={errorDevices && 'Unexpected error loading Managed Devices'}
            refetch={refetchDevices}
            devices={filteredDevices}
            onSettingsClick={handleDeviceSettingsClick}
          />
        </div>
      </ContentLayout>
    </>
  );
};
