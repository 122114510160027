import * as Sentry from '@sentry/react';
import { Event, TransactionContext } from '@sentry/types';

export const URL_UUID_REGEXP =
  /\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g;
export const URL_HASH_REGEXP = /\/[a-f0-9]{32}/g;
export const URL_DIGITS_REGEXP = /\/\d+/g;
export const maskURL = (url?: string) =>
  url
    ?.replace(URL_HASH_REGEXP, '/:hash')
    .replace(URL_UUID_REGEXP, '/:UUID')
    .replace(URL_DIGITS_REGEXP, '/:digits') || url;
/**
 * URL PII scrubbing for event
 * Can be used in
 *  * beforeSend,
 *  * beforeBreadcrumb,
 *  * beforeSendTransaction,
 * @param event
 * @returns event
 */

export const maskEventUrl = <T extends Event>(event: T): T => ({
  ...event,
  request: event.request?.url
    ? {
        ...event.request,
        url: maskURL(event.request.url),
      }
    : event.request,
});
/**
 * PII scrubbing for event
 * Can be used in
 *  * beforeSend,
 *  * beforeBreadcrumb,
 *  * beforeSendTransaction,
 * @param event
 * @returns
 */

export const maskEventUser = <T extends Event>(event: T): T => {
  // Modify or drop the event here
  if (event.user) {
    // Don't send user's email address
    delete event.user.email;
    // Don't send user's username
    delete event.user.username;
    // Don't send user's ip address
    delete event.user.ip_address;
  }
  return event;
  // return maskEventUrl(event);
};
/**
 * Remove UII from URL
 */

export const maskBreadcrumbURL = (
  breadcrumb: Sentry.Breadcrumb,
): Sentry.Breadcrumb => ({
  ...breadcrumb,
  data: breadcrumb.data
    ? {
        ...breadcrumb.data,
        url: maskURL(breadcrumb.data?.url),
        ...(breadcrumb.data.from &&
          typeof breadcrumb.data.from === 'string' && {
            from: maskURL(breadcrumb.data?.from),
          }),
        ...(breadcrumb.data.to &&
          typeof breadcrumb.data.to === 'string' && {
            to: maskURL(breadcrumb.data?.to),
          }),
      }
    : breadcrumb.data,
});
/**
 * Use if navigation URL contains UII
 */

export const browserTracingWithNavigateUrlMask = new Sentry.BrowserTracing({
  beforeNavigate: (context) => {
    const newContext: TransactionContext = {
      ...context,
      // You could use your UI's routing library to find the matching
      // route template here. We don't have one right now, so do some basic
      // parameter replacements.
      name: maskURL(location.pathname) || location.pathname,
    };
    return newContext;
  },
});
