import { useQuery } from '@apollo/client';
import {
  faBell,
  faCalendarDay,
  faGrid2,
  faServer,
} from '@fortawesome/pro-regular-svg-icons';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_APPLIANCES } from '../../api/appliance/queries';
import {
  AlertType,
  GetAppliancesQuery,
  GetAppliancesQueryVariables,
} from '../../gql/graphql';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  updateFilterAlertCodes,
  updateFilterAlertType,
  updateProductsFilterAppliance,
  updateProductsFilterDuration,
  updateProductsFilterProducts,
} from '../../redux/products/filterSlice';
import { toTitleCase } from '../../utils/format';
import { useSession } from '../../utils/hooks/useSession';
import { isInStringEnum, isProductKind } from '../../utils/products';
import { RANGE_OPTIONS } from '../CalenderRangePicker/utils';
import { FilterSelect } from '../FilterSelect/FilterSelect';
import { PickerOptions } from '../form/fields';

export const AlertFilterSelect = () => {
  const productFilter = useAppSelector((state) => state.productFilter);
  const { installed: installedProducts } = useAppSelector(
    (state) => state.products.products,
  );

  const { skip } = useSession();
  const appliancesQuery = useQuery<
    GetAppliancesQuery,
    GetAppliancesQueryVariables
  >(GET_APPLIANCES, {
    skip,
  });

  const dispatch = useAppDispatch();

  const productOptions = useMemo(
    () =>
      installedProducts.map((p) => ({
        label: p.name,
        value: p.id,
      })),
    [installedProducts],
  );

  const appliancesOptions = useMemo(
    () =>
      appliancesQuery?.data?.appliances.map((appliance) => ({
        label: appliance.description,
        value: appliance.id,
      })) || [],
    [appliancesQuery],
  );

  const durationOptions = useMemo(() => RANGE_OPTIONS, [RANGE_OPTIONS]);

  const { t, i18n } = useTranslation(['alertCodes', 'notifications']);

  const codes = useMemo(
    () =>
      Object.keys(i18n.getResourceBundle('en', 'alertCodes'))
        // Hide search alert codes in alert
        .filter((code) => !code.startsWith('ES')),
    [i18n],
  );
  const codeNames = useMemo(
    () =>
      codes.map((code) => ({
        label: t(`alertCodes:${code}.description`),
        value: code,
      })),
    [codes, t],
  );
  const alertCodesOptions = useMemo(
    (): PickerOptions =>
      codeNames.map((code) => ({
        value: code.value,
        textValue: code.label,
        label: (
          <>
            <span
              data-testid={`alarm-code-option`}
              data-test-value={code.value}
            >
              {code.value}:{' '}
            </span>
            {code.label}
          </>
        ),
      })),
    [codeNames],
  );
  const alertTypeOptions = useMemo(
    (): PickerOptions =>
      Object.values(AlertType).map((value) => ({
        value,
        label: t(`notifications.type.${value}`, toTitleCase(value), {
          ns: 'notifications',
        }),
      })),
    [],
  );

  const handleSaveFilters = useCallback(
    (value: {
      capability?: string[];
      appliance?: string[];
      alertCode?: string[];
      alertType?: string[];
      duration?: string;
    }) => {
      value?.capability &&
        value.capability.every(isProductKind) &&
        dispatch(updateProductsFilterProducts(value.capability));
      value?.appliance &&
        dispatch(updateProductsFilterAppliance(value?.appliance));
      value?.duration &&
        dispatch(updateProductsFilterDuration(value?.duration));
      value?.alertCode && dispatch(updateFilterAlertCodes(value?.alertCode));
      value?.alertType &&
        value.alertType.every(isInStringEnum(AlertType)) &&
        dispatch(updateFilterAlertType(value?.alertType));
    },
    [],
  );

  return (
    <FilterSelect
      filters={{
        capability: {
          label: 'Capability',
          dataTestType: 'CAPABILITY',
          type: 'multi',
          options: productOptions,
          icon: faGrid2,
        },
        appliance: {
          label: 'Appliance',
          dataTestType: 'APPLIANCE',
          type: 'multi',
          options: appliancesOptions,
          icon: faServer,
        },
        alertCode: {
          label: 'Alert',
          dataTestType: 'ALERT_CODE',
          type: 'multi',
          options: alertCodesOptions,
          icon: faBell,
          pickerProps: {
            checkboxPosition: 'top',
          },
        },
        alertType: {
          label: 'Alert type',
          dataTestType: 'ALERT_TYPE',
          type: 'multi',
          options: alertTypeOptions,
          icon: faBell,
        },
        duration: {
          label: 'Date',
          dataTestType: 'DATE',
          type: 'single',
          options: durationOptions,
          icon: faCalendarDay,
        },
      }}
      value={{
        capability: productFilter.products,
        appliance: productFilter.appliances || undefined,
        alertCode: productFilter.alertCodes,
        duration: productFilter.duration,
        alertType: productFilter.alertType,
      }}
      onSave={handleSaveFilters}
    />
  );
};
