import { faRefresh, faSquareCode } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Spinner from '../../../components/Elements/Spinner/Spinner';
import { Message } from '../../../components/common/Message';
import { GetInventoryQuery } from '../../../gql/graphql';
import { SimpleButton } from '../../Buttons/SimpleButton';
import { EmptyMessage } from '../common/EmptyMessage';
import { ApplianceCardDisclosure } from './ApplianceCardDisclosure';
import { SupportingNodeProps } from './components/SupportingNode';

export const APPLIANCE_HASH_START = '#appliance-';
export interface AppliancesProps {
  inventoryAppliances: Array<GetInventoryQuery['appliances']['0']> | undefined;
  onSettingsClick: (
    args: GetInventoryQuery['appliances']['0'] | undefined,
  ) => void;
  loading?: boolean;
  error?: string;
  refetch?: () => void;
  onClickServerDetails?: SupportingNodeProps['onClickDetails'];
}

const Appliances = ({
  inventoryAppliances,
  onSettingsClick,
  loading,
  error,
  refetch,
  onClickServerDetails,
}: AppliancesProps) => {
  const location = useLocation();
  const hashAppliance = useMemo(
    () =>
      location.hash?.startsWith(APPLIANCE_HASH_START)
        ? location.hash.replace(APPLIANCE_HASH_START, '')
        : undefined,
    [location.hash],
  );

  return (
    <div data-testid="appliances-section" className="flex flex-col gap-3">
      <div className="flex flex-row items-center gap-4">
        <FontAwesomeIcon icon={faSquareCode} className="h-5 w-5" />
        <h5
          className="font-graphikMedium text-tw-label-primary dark:text-tw-label-primary-dark"
          style={{ fontSize: '14px' }}
        >
          Appliances
        </h5>
      </div>
      <div className="w-full rounded-lg bg-tw-surface-strong p-3 dark:bg-tw-surface-strong-dark">
        {loading && <Spinner className="h-5 w-5" />}
        {error && (
          <Message type="error">
            <div className="flex items-center gap-3">
              {error}
              {refetch && (
                <SimpleButton
                  onClick={() => refetch?.()}
                  variant="soft"
                  className="m-0 p-0"
                >
                  <FontAwesomeIcon icon={faRefresh} />
                </SimpleButton>
              )}
            </div>
          </Message>
        )}
        {!loading && !error && !inventoryAppliances?.length && (
          <EmptyMessage
            title="No Appliances"
            description="There are no appliances."
          />
        )}
        <div data-testid="appliance-cards" className="flex flex-col gap-2">
          {inventoryAppliances?.map((appliance) => (
            <ApplianceCardDisclosure
              key={appliance.applianceId}
              appliance={appliance}
              hashAppliance={hashAppliance}
              onSettingsClick={onSettingsClick}
              onClickServerDetails={onClickServerDetails}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Appliances;
