import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import { ProductKind } from '../../../../api/product/types';
import { Icon, IconName } from '../../../../assets/icons/Icon';
import { AlertSeverity } from '../../../../gql/graphql';
import { useAppSelector } from '../../../../redux/hooks';
import { toTitleCase } from '../../../../utils/format';
import { DEFAULT_TOOLTIP_OPTIONS } from '../../../../utils/tooltilp';
import { Severity, getIconClassBySeverity } from './utils';

export const AlertCountTooltipRender = ({
  content,
}: {
  content: string | null;
  activeAnchor: HTMLElement | null;
}) => {
  const { alertCountByProductAndSeverity: products, installed } =
    useAppSelector((state) => state.products.products);

  const product = products?.[content as ProductKind];

  const { t } = useTranslation('productInfo');

  const isInstalled = !!product;

  const installedProduct = installed.find((p) => p.id === content);

  const alertSeverity: Severity =
    (isInstalled &&
      product &&
      (((product.data?.CRITICAL.totalItems || 0) > 0 && 'critical') ||
        ((product.data?.ERROR.totalItems || 0) > 0 && 'error') ||
        ((product.data?.STANDARD.totalItems || 0) > 0 && 'standard'))) ||
    undefined;

  const alertSeverityContent = useMemo(() => {
    const entries = product && [
      {
        severity: AlertSeverity.Critical,
        count: product.data?.CRITICAL.totalItems,
      },
      { severity: AlertSeverity.Error, count: product.data?.ERROR.totalItems },
      {
        severity: AlertSeverity.Standard,
        count: product.data?.STANDARD.totalItems,
      },
    ];
    const total = entries?.reduce((a, c) => a + (c.count || 0), 0);
    return installedProduct?.inCurrentApplianceFilter === false ? (
      <p
        className={
          'font-graphikMedium text-[0.75rem] leading-[0.813rem] text-tw-main-text '
        }
      >
        Not licensed for selected appliance(s)
      </p>
    ) : product ? (
      !total ? (
        <AlertSeverityText count={0} emphasys />
      ) : (
        entries
          ?.filter(({ count }) => count)
          .map(
            ({ severity, count }, i) =>
              !!count && (
                <AlertSeverityText
                  key={severity}
                  count={count}
                  severity={severity}
                  emphasys={i === 0}
                />
              ),
          )
      )
    ) : (
      <p
        className={
          'font-graphikMedium text-[0.75rem] leading-[0.813rem] text-tw-main-text '
        }
      >
        Not Activated
      </p>
    );
  }, [product]);

  return (
    <div
      data-testid="rosechart-product-tooltip-content"
      className={classNames('flex flex-col items-center gap-2')}
    >
      {content && (
        <>
          <h5 className="font-graphikMedium text-[0.875rem] leading-[0.938rem] text-tw-main-text">
            {t(`${content}.name`, content)}
          </h5>
          <div
            className={`flex flex-col items-center gap-1 font-graphikMedium text-[0.75rem] leading-[0.813rem] ${getIconClassBySeverity(
              alertSeverity,
            )}`}
          >
            {alertSeverityContent}
          </div>
        </>
      )}
    </div>
  );
};

const AlertSeverityText = ({
  count: value,
  severity: key,
  emphasys,
}: {
  count: number;
  severity?: AlertSeverity;
  emphasys?: boolean;
}) => {
  const formatedValue = Intl.NumberFormat('en', {
    maximumSignificantDigits: 5,
    notation: 'standard',
  }).format(value || 0);

  return (
    <p
      className={classNames(
        `flex flex-row items-end justify-center gap-1 font-graphikMedium text-[0.75rem] leading-[0.813rem]`,
        emphasys ? getIconClassBySeverity(key) : 'text-tw-description-text',
      )}
    >
      {emphasys && (
        <span>
          <Icon
            iconName={
              !value ? IconName.CheckmarkIcon : IconName.WarningCircleSolidIcon
            }
            height="15"
            width="15"
          />
        </span>
      )}
      <span
        data-testid="alert-severity-text"
        data-test-severity={key}
        data-test-value={value}
      >
        {formatedValue} {toTitleCase(key?.replace('error', 'Warning') || '')}
        {+value > 1 || value === 0 ? ' Alerts' : ' Alert'}
      </span>
    </p>
  );
};

export const AlertCountTooltip = ({
  id = 'rosechart-product-tooltip',
  float,
}: {
  id?: string;
  float?: boolean;
}) => {
  return (
    <Tooltip
      offset={15}
      id={id}
      {...DEFAULT_TOOLTIP_OPTIONS}
      className={twMerge(
        classNames(DEFAULT_TOOLTIP_OPTIONS.className),
        '!bg-white !text-tw-main-text ring-1 ring-tw-border drop-shadow-[0_2px_1px_rgba(0,0,0,0.50)] dark:!bg-white dark:!text-tw-main-text',
      )}
      render={AlertCountTooltipRender}
      float={float}
      opacity={1}
    />
  );
};
