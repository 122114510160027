import * as React from 'react';

import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setSidebarCollapse } from '../../redux/sidebar/sidebarCollapseSlice';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import { Head } from '../Head';
import { Sidebar } from '../Sidebar';
import { LayoutAreaId } from './types';

export type MainLayoutProps = {
  children: React.ReactNode;
  head?: React.ReactNode;
  sidebar?: React.ReactNode;
};

export const MainLayout = ({
  children,
  head = <Head />,
  sidebar = <Sidebar />,
}: MainLayoutProps) => {
  const sidebarCollapsed = useAppSelector(
    (state) => state.sidebarCollapse.collapsed,
  );
  const dispatch = useAppDispatch();

  const { width } = useWindowDimensions();
  React.useEffect(() => {
    if (width < 1024) {
      dispatch(setSidebarCollapse(true));
    }
  }, [width]);

  return (
    <div
      data-testid="MainLayout"
      className="flex h-screen w-screen flex-col overflow-hidden"
      id="MainLayout"
    >
      <div id={LayoutAreaId.HEAD}>{head}</div>
      <div
        className="flex"
        style={{
          height: 'calc(100vh - 66px)',
        }}
        id={LayoutAreaId.BODY}
      >
        <div className="z-[100]" id={LayoutAreaId.SIDEBAR}>
          {sidebar}
        </div>

        <div
          // This id is used for infinite scroll on alerts page
          id={LayoutAreaId.CONTENT}
          className={classNames(
            'scrollbar w-full overflow-x-auto overflow-y-auto bg-white dark:bg-[#1A1C1E] md:overflow-x-hidden',
            sidebar && 'ml-[80px] lg:ml-0',
          )}
          style={{
            height: 'calc(100vh - 66px)',
          }}
        >
          {!sidebarCollapsed ? (
            <div
              className="fixed inset-0 top-[62px] z-50 bg-black/20 lg:hidden"
              aria-hidden="true"
              style={{
                height: 'calc(100vh - 66px)',
              }}
            />
          ) : null}
          {children}
        </div>
      </div>
    </div>
  );
};
