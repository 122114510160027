import { equals } from 'ramda';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ProductKind } from '../../../api/product/types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

import { AlertType, Category } from '../../../gql/graphql';
import {
  DEFAULT_PRODUCT_FILTER,
  updateFilterAlertCodes,
  updateFilterAlertType,
  updateProductsFilterAppliance,
  updateProductsFilterCategory,
  updateProductsFilterDuration,
  updateProductsFilterProducts,
} from '../../../redux/products/filterSlice';

export const SEPARATOR = '|-|';
export enum FilterSearchParams {
  appliances = 'appliances',
  duration = 'duration',
  selected = 'selected',
  category = 'category',
  products = 'products',
  alertCodes = 'alertCodes',
  alertType = 'alertType',
}

/**
 * Syncs the product filter in redux and search params.
 * The first time updates the redux to search params value (if there is any),
 * then the search params are updated on each redux change
 *
 * @returns {object} productFilter and currentSearchParamsFilter
 */
export const useSearchParamsReduxFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const productFilter = useAppSelector((state) => state.productFilter);
  const dispatch = useAppDispatch();

  const currentSearchParamsFilter = useMemo(() => {
    const values = Object.values(FilterSearchParams).reduce<{
      [key in FilterSearchParams]?: string | null;
    }>((prev, current) => {
      return {
        ...prev,
        [current]: searchParams.get(current),
      };
    }, {});

    return Object.values(values).some(Boolean) ? values : undefined;
  }, [searchParams]);

  useEffect(() => {
    if (!currentSearchParamsFilter) {
      return;
    }
    const filterFromParam = currentSearchParamsFilter && {
      products:
        currentSearchParamsFilter.products
          ?.split(SEPARATOR)
          .filter((kind) =>
            Object.values(ProductKind).includes(kind as ProductKind),
          )
          .map((kind) => kind as ProductKind) || null,
      appliances:
        currentSearchParamsFilter.appliances?.split(SEPARATOR) || null,
      alertCodes:
        currentSearchParamsFilter.alertCodes?.split(SEPARATOR) || null,
      alertType:
        currentSearchParamsFilter.alertType
          ?.split(SEPARATOR)
          .filter((alertType) =>
            Object.values(AlertType).includes(alertType as AlertType),
          )
          .map((kind) => kind as AlertType) || null,
      duration: currentSearchParamsFilter.duration || null,
      category: Object.values(Category).includes(
        currentSearchParamsFilter.category as Category,
      )
        ? (currentSearchParamsFilter.category as Category)
        : null,
    };

    !equals(filterFromParam?.products, productFilter.products) &&
      dispatch(updateProductsFilterProducts(filterFromParam?.products || []));
    !equals(filterFromParam?.appliances, productFilter.appliances) &&
      dispatch(
        updateProductsFilterAppliance(filterFromParam?.appliances || []),
      );
    !equals(filterFromParam?.alertCodes, productFilter.alertCodes) &&
      dispatch(updateFilterAlertCodes(filterFromParam?.alertCodes || []));
    !equals(filterFromParam?.alertType, productFilter.alertType) &&
      dispatch(updateFilterAlertType(filterFromParam?.alertType || undefined));
    !equals(filterFromParam?.duration, productFilter.duration) &&
      dispatch(
        updateProductsFilterDuration(
          filterFromParam?.duration || DEFAULT_PRODUCT_FILTER.duration,
        ),
      );
    !equals(filterFromParam?.category, productFilter.category) &&
      dispatch(updateProductsFilterCategory(filterFromParam?.category || null));
  }, []);
  useEffect(() => {
    const paramsFromState = {
      appliances: productFilter.appliances?.join(SEPARATOR) || null,
      duration: productFilter.duration || null,
      category: productFilter.category || null,
      alertCodes: productFilter.alertCodes?.join(SEPARATOR) || null,
      alertType: productFilter.alertType?.join(SEPARATOR) || null,
      products: productFilter.products?.join(SEPARATOR) || null,
    };

    setSearchParams(
      (prev) => {
        paramsFromState.products &&
        productFilter.products !== DEFAULT_PRODUCT_FILTER.products
          ? prev.set(FilterSearchParams.products, paramsFromState.products)
          : prev.delete(FilterSearchParams.products);
        paramsFromState.appliances &&
        productFilter.appliances !== DEFAULT_PRODUCT_FILTER.appliances
          ? prev.set(FilterSearchParams.appliances, paramsFromState.appliances)
          : prev.delete(FilterSearchParams.appliances);
        paramsFromState.alertCodes &&
        productFilter.alertCodes !== DEFAULT_PRODUCT_FILTER.alertCodes
          ? prev.set(FilterSearchParams.alertCodes, paramsFromState.alertCodes)
          : prev.delete(FilterSearchParams.alertCodes);
        paramsFromState.alertType &&
        productFilter.alertType !== DEFAULT_PRODUCT_FILTER.alertType
          ? prev.set(FilterSearchParams.alertType, paramsFromState.alertType)
          : prev.delete(FilterSearchParams.alertType);
        paramsFromState.duration &&
        productFilter.duration !== DEFAULT_PRODUCT_FILTER.duration
          ? prev.set(FilterSearchParams.duration, paramsFromState.duration)
          : prev.delete(FilterSearchParams.duration);
        paramsFromState.category &&
        productFilter.category !== DEFAULT_PRODUCT_FILTER.category
          ? prev.set(FilterSearchParams.category, paramsFromState.category)
          : prev.delete(FilterSearchParams.category);
        return prev;
      },
      {
        replace: true,
      },
    );
  }, [productFilter]);

  return {
    productFilter,
    currentSearchParamsFilter,
  };
};
