import { useFeature } from 'featurehub-react-sdk';
import { Helmet } from 'react-helmet';
import { FeatureFlag } from '../featureHub/constants';

export const Umami = () => {
  const flag = useFeature(FeatureFlag.UMAMI);

  return flag && window.env.REACT_APP_UMAMI_URL ? (
    <Helmet>
      <script
        async
        src={`${window.env.REACT_APP_UMAMI_URL}/script.js`}
        data-website-id={`${window.env.REACT_APP_UMAMI_WEB_ID}`}
      ></script>
    </Helmet>
  ) : null;
};
