import React from 'react';

export const NotificationBellIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9018 14.3647C14.8534 14.2829 14.8199 14.2228 14.7831 14.1648C13.9176 12.8032 13.4537 11.3118 13.367 9.70525C13.3356 9.12304 13.3516 8.53834 13.3434 7.95479C13.3322 7.15327 13.1777 6.38857 12.7581 5.6919C12.3219 4.96777 11.723 4.43133 10.9455 4.09254C10.0438 3.69958 9.09566 3.66808 8.14569 3.77674C7.10014 3.8964 6.20562 4.34964 5.52303 5.17196C4.99832 5.80406 4.68287 6.53296 4.61715 7.35033C4.57331 7.89566 4.59429 8.44601 4.58105 8.994C4.56328 9.72823 4.51085 10.4582 4.35425 11.1792C4.1191 12.2617 3.69778 13.2651 3.11206 14.2023C3.08225 14.25 3.05698 14.3006 3.02136 14.3647H14.9018ZM7.72453 1.94598C7.72453 1.67975 7.73076 1.42344 7.72314 1.16749C7.7093 0.702518 8.05528 0.146302 8.66355 0.0317821C9.18621 -0.0665337 9.64435 0.0558471 9.97485 0.502803C10.1253 0.706267 10.1955 0.94208 10.1992 1.195C10.2027 1.44116 10.1999 1.68743 10.1999 1.94827C10.3659 1.98607 10.5267 2.01914 10.6856 2.05935C12.0244 2.39795 13.1205 3.10599 13.9684 4.20071C14.6382 5.0656 15.0437 6.03678 15.1491 7.12406C15.1941 7.58849 15.1819 8.05812 15.2064 8.52485C15.2393 9.15181 15.2353 9.78554 15.3309 10.4034C15.5436 11.7786 16.1348 12.9959 17.0038 14.0805C17.1893 14.312 17.3702 14.5475 17.545 14.7873C17.9216 15.3039 17.642 16.0436 16.9553 16.2049C16.8586 16.2276 16.7563 16.236 16.6566 16.236C11.5226 16.2375 6.38856 16.2374 1.2545 16.2372C0.74484 16.2372 0.365424 15.9758 0.235122 15.5278C0.142913 15.2108 0.239838 14.9355 0.438102 14.6848C0.880947 14.1245 1.33147 13.5701 1.68319 12.944C2.17368 12.0711 2.52045 11.1453 2.61834 10.1477C2.68739 9.44391 2.70426 8.73309 2.70813 8.02523C2.71321 7.08688 2.86909 6.18498 3.25473 5.32765C3.50657 4.76757 3.8638 4.27841 4.27054 3.8202C4.85983 3.15642 5.56588 2.65898 6.38826 2.33743C6.7652 2.19001 7.16517 2.10161 7.55493 1.98715C7.60294 1.97307 7.65216 1.96339 7.72453 1.94598V1.94598Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.47291 17.5108H11.4411C11.4472 18.3336 11.0867 18.9745 10.458 19.4628C9.68837 20.0605 8.82753 20.159 7.94469 19.7604C7.0261 19.3455 6.44957 18.4281 6.47291 17.5108"
        fill="currentColor"
      />
    </svg>
  );
};

export default NotificationBellIcon;
