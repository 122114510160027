import React from 'react';

export const TrashIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M9.9375 1.875C10.2492 1.875 10.5 2.12695 10.5 2.4375C10.5 2.74922 10.2492 3 9.9375 3H9.66562L9.10312 10.6102C9.04453 11.393 8.39297 12 7.60781 12H2.89219C2.1075 12 1.45523 11.393 1.39711 10.6102L0.833438 3H0.5625C0.251953 3 0 2.74922 0 2.4375C0 2.12695 0.251953 1.875 0.5625 1.875H2.19891L3.05859 0.584531C3.30234 0.219305 3.7125 0 4.15078 0H6.34922C6.7875 0 7.19766 0.219328 7.44141 0.584531L8.30156 1.875H9.9375ZM4.15078 1.125C4.08984 1.125 4.01016 1.15641 3.99609 1.20844L3.55078 1.875H6.94922L6.50391 1.20844C6.46875 1.15641 6.41016 1.125 6.34922 1.125H4.15078ZM8.53828 3H1.96148L2.51953 10.5281C2.53359 10.7227 2.69766 10.875 2.89219 10.875H7.60781C7.80234 10.875 7.96641 10.7227 7.98047 10.5281L8.53828 3Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TrashIcon;
