import { SUPPORTED_LANGUAGES } from './constants';

export const getSupportedLang = (languageCode: string) => {
  if (SUPPORTED_LANGUAGES.includes(languageCode as any)) return languageCode;
  return (
    SUPPORTED_LANGUAGES.find((code) => {
      const supportedLang = code.split('-')[0];
      const lang = languageCode.split('-')[0];
      return supportedLang.toLowerCase() === lang.toLocaleLowerCase();
    }) || SUPPORTED_LANGUAGES[0]
  );
};
