import { faClose, faFilter } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, {
  Fragment,
  MouseEventHandler,
  useCallback,
  useMemo,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { SimpleButton } from '../Buttons/SimpleButton';
import { Badge } from '../common/Badge';
import { PickerOptions, RadioGroup } from '../form/fields';
import { CheckBoxSwitchGroup } from '../form/fields/CheckBox/CheckBoxGroup';
import {
  mapPickerOptionToValue,
  pickerOptionLabel,
} from '../form/fields/pickers/utils';
import { FilterOptionProps } from './FilterSelect';

export type FilterSelectValue<T = FilterOptionProps> = T extends {
  type: 'multi' | 'single';
}
  ? T['type'] extends 'single'
    ? string
    : string[]
  : never;

export interface FilterSelectProps<
  T = FilterOptionProps,
  U = FilterSelectValue<T>,
> {
  value?: U;

  label: string;
  filterValue: T;
  onClear?: () => void;
  onChange?: (value: U) => void;
}

export const InlineFilter = ({
  value,
  label,
  filterValue,
  onClear,
  onChange,
}: FilterSelectProps) => {
  const handleOnClear: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      onClear?.();
    },
    [onClear],
  );

  const handleOnChangeMulti = useCallback<(selected: string[]) => void>(
    (selected) => {
      onChange?.(selected);
    },
    [],
  );
  const handleOnChangeSingle = useCallback<(selected: string) => void>(
    (selected) => {
      onChange?.(selected);
    },
    [],
  );

  const selectedLabel = useMemo(() => {
    if (filterValue.type === 'single') {
      const option = (filterValue.options as PickerOptions<any>).find(
        (option) => mapPickerOptionToValue(option) === value,
      );
      return pickerOptionLabel(option) || value;
    }
    const options = (filterValue.options as PickerOptions<any>).filter(
      (option) => value?.includes(mapPickerOptionToValue(option).toString()),
    );

    const optionLabel = pickerOptionLabel(options[0]);

    return optionLabel ? (
      <>
        <span className="max-w-[10rem] truncate">
          {pickerOptionLabel(options[0])}
        </span>
        {!!options.slice(1).length && (
          <span
            data-testid="filter-count"
            data-test-value={options.length}
            className="pl-2"
          >
            +{options.slice(1).length}
          </span>
        )}
      </>
    ) : (
      value
    );
  }, [value, filterValue]);

  return !isEmpty(value) ? (
    <FilterDropDown
      itemsClassName="w-auto border dark:border-white/5 border-black/5 max-w-[30rem]"
      triggerCont={
        <div
          data-testid="inline-filter"
          data-test-type={filterValue.dataTestType || filterValue.label}
          className="flex flex-row items-center gap-0.5"
        >
          <Badge
            data-testid="filter-type"
            variant="primarySoft"
            className="flex items-center gap-2"
          >
            <FontAwesomeIcon
              icon={filterValue.icon || faFilter}
              className="h-3.5 w-3.5 text-tw-primary-strong"
            />
            {label}
          </Badge>
          <Badge
            data-testid="filter-eq"
            variant="primarySoft"
            className="text-tw-label-tertiary dark:text-tw-label-tertiary-dark"
          >
            is
          </Badge>
          <Badge
            data-testid="filter-value"
            variant="primarySoft"
            className="flex items-center truncate"
          >
            {selectedLabel}
            {onClear && !filterValue.required && (
              <SimpleButton
                data-testid="filter-clear-button"
                variant="default-ghost"
                className="m-0 flex  items-center p-0 pl-2"
                onClick={handleOnClear}
              >
                <FontAwesomeIcon className="h-3 w-3" icon={faClose} />
              </SimpleButton>
            )}
          </Badge>
        </div>
      }
    >
      {filterValue.type === 'multi' && (
        <CheckBoxSwitchGroup
          data-testid={`filter-${label}-options`.toLowerCase()}
          value={value ? (value as string[]) : []}
          onChange={handleOnChangeMulti}
          options={filterValue.options}
          {...filterValue.pickerProps}
        />
      )}
      {filterValue.type === 'single' && (
        <RadioGroup
          data-testid={`filter-${label}-options`.toLowerCase()}
          value={typeof value === 'string' ? (value as string) : ''}
          onChange={handleOnChangeSingle}
          options={filterValue.options}
          {...filterValue.pickerProps}
        />
      )}
    </FilterDropDown>
  ) : null;
};

export const FilterDropDown = ({
  children,
  triggerCont,
  itemsClassName,
  ...rest
}: {
  children: React.ReactNode;
  triggerCont: React.ReactNode;
  itemsClassName?: string;
}) => {
  return (
    <div data-testid="inline-filter" {...rest}>
      <Menu>
        {({ open }) => (
          <div className={classNames('relative')}>
            <Menu.Button>{triggerCont}</Menu.Button>
            <Transition
              as={Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="transform opacity-0"
              enterTo="transform opacity-100"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Menu.Items
                className={twMerge(
                  classNames(
                    'scrollbar overflow-auto',
                    'mt-1 p-3',
                    'absolute z-50 max-h-96 w-full rounded-md font-graphikRegular text-tw-light-text shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                    'dark:text-tw-light-text-dark dark:ring-white/5',
                    'bg-tw-surface-soft dark:bg-tw-surface-soft-dark',
                    itemsClassName,
                  ),
                )}
              >
                {children}
              </Menu.Items>
            </Transition>
          </div>
        )}
      </Menu>
    </div>
  );
};
