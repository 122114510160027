import { Switch as HeadlessSwitch } from '@headlessui/react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { Icon, IconName } from '../../../../assets/icons/Icon';
import { UseControlledOrUncontrolled } from '../../hooks/UseControlledOrUncontrolled';
import { Label } from '../Common';
import { IProps } from './Switch';

export const CheckboxSwitch = ({
  children,
  checked,
  onChange,
  className,
  defaultChecked,
  checkboxPosition,
  ...rest
}: IProps) => {
  const { value, setValue } = UseControlledOrUncontrolled({
    value: checked,
    defaultValue: defaultChecked,
    defaultInternalValue: false,
  });

  const handleDataChange = (checked: boolean) => {
    setValue(checked);
    onChange?.(checked);
  };

  return (
    <HeadlessSwitch.Group
      as="div"
      className={classNames(
        'group flex gap-3',
        checkboxPosition === 'top' ? 'items-start' : 'items-center',
      )}
      data-testid="checkbox-container"
      {...rest}
    >
      <HeadlessSwitch
        checked={value}
        data-testid="checkbox"
        onChange={handleDataChange}
        className={twMerge(
          classNames(
            value
              ? 'bg-tw-main-color ring-tw-main-color'
              : 'bg-white ring-tw-main-text/20 dark:bg-transparent',
            'ring-2',
            checked
              ? 'ring-tw-main-color'
              : 'ring-tw-label-tertiary/60 dark:ring-white/60',
            'relative inline-flex h-4 w-4 flex-shrink-0 cursor-pointer rounded-sm transition-colors duration-200 ease-in-out',
            checked
              ? 'hover:ring-tw-label-tertiary/60 group-hover:ring-tw-label-tertiary/60 dark:hover:dark:ring-white/60 dark:group-hover:dark:ring-white/60'
              : 'hover:ring-tw-main-color group-hover:ring-tw-main-color dark:hover:ring-tw-main-color dark:group-hover:ring-tw-main-color',
            'focus:outline-none focus-visible:border-0 focus-visible:ring-2 focus-visible:ring-tw-main-color focus-visible:ring-offset-1',
            'disabled:cursor-not-allowed disabled:opacity-50',
            checkboxPosition === 'top' && 'mt-1',
            className?.({ checked: value }),
          ),
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            'pointer-events-none inline-block text-white',
            !value && 'hidden',
          )}
        >
          <Icon className="h-4 w-4" iconName={IconName.CheckmarkBoldIcon} />
        </span>
      </HeadlessSwitch>
      {children && (
        <span className="flex flex-col">
          <HeadlessSwitch.Label as="span">
            <Label placement="none" className="cursor-pointer">
              {children}
            </Label>
          </HeadlessSwitch.Label>
        </span>
      )}
    </HeadlessSwitch.Group>
  );
};
