import classNames from 'classnames';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';

interface ContentLayoutProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title' | 'children'> {
  title?: React.ReactNode;
  description?: string;
  children: React.ReactNode;
  titleActions?: React.ReactNode;
  variant?: 'full' | 'xl' | 'md';
}

export const ContentLayout = ({
  children,
  title,
  description,
  titleActions,
  variant = 'xl',
  className,
  ...rest
}: ContentLayoutProps) => {
  return (
    <div
      data-testid="content-layout"
      className={twMerge(
        classNames(
          'min-w-[688px] bg-tw-dark-shade dark:bg-tw-dark-shade-dark',
          className,
        ),
      )}
      {...rest}
    >
      {(title || titleActions) && (
        <div
          data-testid="page-header"
          className="flex min-h-[4rem] w-full items-center justify-between border-b border-b-[#eef4ff] p-3 px-8 dark:border-b-[#292b2f]"
        >
          {title && (
            <div className="flex flex-col">
              <h2 data-testid="title" className="font-graphikSemi text-lg">
                {title}
              </h2>
              {description && (
                <p
                  data-testid="description"
                  className="font-graphikRegular text-xs"
                >
                  {description}
                </p>
              )}
            </div>
          )}
          {titleActions && (
            <div data-testid="actions" className="flex items-center gap-2">
              {titleActions}
            </div>
          )}
        </div>
      )}
      <div
        data-testid="page-content"
        className={classNames(
          'scrollbar mx-auto flex h-full w-full flex-col overflow-auto',
          {
            'max-w-7xl px-4 py-4 sm:px-6 md:px-8': variant === 'xl',
            'max-w-5xl px-4 py-4 sm:px-6 md:px-8': variant === 'md',
            'w-full': variant === 'full',
          },
        )}
      >
        {children}
      </div>
    </div>
  );
};
