import { ErrorBoundary } from '@sentry/react';
import { useFeature } from 'featurehub-react-sdk';
import { toast } from 'react-toastify';
import { PageNotFound } from '../../pages/404/PageNotFound';
import { FeatureFlag } from '../featureHub/constants';
import { useSentryInit } from './sentryInit';

const ErrorFallback = () => {
  return <PageNotFound />;
};

const myErrorHandler = (
  error: Error,
  componentStack: string,
  eventId: string,
) => {
  toast.error(error.message, {
    type: 'error',
  });
  console.log('SENTRY ERROR: ', error);
  console.log('THE STACK: ', componentStack);
  console.log('THE EVENT_ID: ', eventId);
};

export const SentryErrorHandler = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const sentryEnabled = useFeature(FeatureFlag.SENTRY);
  // Sentry init config
  useSentryInit();

  return sentryEnabled ? (
    <ErrorBoundary fallback={ErrorFallback} onError={myErrorHandler}>
      {children}
    </ErrorBoundary>
  ) : (
    <>{children}</>
  );
};
