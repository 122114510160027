import React from 'react';

export const SystemHealthIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="19"
      viewBox="0 0 27 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M4.65039 3.13184C4.65039 2.96242 4.78859 2.82422 4.95801 2.82422H20.3389C20.5083 2.82422 20.6465 2.96242 20.6465 3.13184V12.0527H22.4922V3.13184C22.4922 1.94482 21.527 0.978516 20.3389 0.978516H4.95801C3.77099 0.978516 2.80469 1.94482 2.80469 3.13184V12.0527H4.65039V3.13184ZM24.0303 13.2832H1.2666C0.757111 13.2832 0.34375 13.6985 0.34375 14.2061V14.8213C0.34375 16.6862 1.86146 18.2051 3.72754 18.2051H21.5693C23.4354 18.2051 24.9531 16.6874 24.9531 14.8213V14.2061C24.9531 13.6985 24.5417 13.2832 24.0303 13.2832ZM21.5693 16.3594H3.72754C2.98503 16.3594 2.36326 15.8299 2.22021 15.1289H23.0767C22.9344 15.8287 22.3115 16.3594 21.5693 16.3594ZM12.0332 12.0527H13.2637C13.6021 12.0527 13.8789 11.7758 13.8789 11.4375V9.55719H15.7246C16.063 9.55719 16.3398 9.28026 16.3398 8.97656V7.71149C16.3398 7.40772 16.063 7.13086 15.7246 7.13086H13.8789V5.28516C13.8789 4.94678 13.6021 4.66992 13.2637 4.66992H12.0332C11.6948 4.66992 11.418 4.94678 11.418 5.28516V7.13086H9.57227C9.23389 7.13086 8.95703 7.40772 8.95703 7.71149V8.97656C8.95703 9.31483 9.23385 9.55719 9.57227 9.55719H11.418V11.4029C11.418 11.7759 11.6948 12.0527 12.0332 12.0527Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SystemHealthIcon;
