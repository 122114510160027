import React from 'react';

export const ArchiveBoxIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 0H14.5C15.3281 0 16 0.671562 16 1.5V3.52813C16 4.05 15.55 4.5 15 4.5V12C15 13.1047 14.1047 14 13 14H3C1.89531 14 1 13.1047 1 12L0.971875 4.5C0.45 4.5 0 4.05 0 3.5V1.5C0 0.671562 0.671562 0 1.5 0ZM3 12.5H13C13.275 12.5 13.5 12.275 13.5 12V4.5H2.5V12C2.5 12.2762 2.72384 12.5 3 12.5ZM14.5 3H1.5V1.5H14.5V3ZM10.2219 7.5H5.72188C5.33437 7.5 5 7.16563 5 6.72188C5 6.33437 5.33437 6 5.72188 6H10.25C10.6656 6 11 6.33437 11 6.75C11 7.16563 10.6656 7.5 10.2219 7.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
