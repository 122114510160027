import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  UiNodeInputAttributes,
  UiText,
  UpdateSettingsFlowBody,
} from '@ory/kratos-client';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import {
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetFocus,
  UseFormWatch,
  useForm,
} from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import CloseIcon from '../../../assets/icons/CloseIcon';
import WarningCircleIcon from '../../../assets/icons/WarningCircleIcon';
import { SimpleButton } from '../../../components/Buttons/SimpleButton';
import { EyeIcon } from '../../../components/Elements/Icons/EyeIcon';
import { EyeSlashIcon } from '../../../components/Elements/Icons/EyeSlashIcon';
import Spinner from '../../../components/Elements/Spinner/Spinner';
import { useLogOut } from '../../../components/Elements/User';
import { Message } from '../../../components/common/Message';
import { ory } from '../../../ory/config';
import { messagesFromData } from '../../../ory/utils';
import { useSession } from '../../../utils/hooks/useSession';

type Input = {
  password: string;
  passwordConfirm: string;
};

export const ChangePasswordForm = () => {
  const [isPasswordReset, setIsPasswordReset] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [errorMessages, setErrorMessages] = useState<UiText[] | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    setFocus,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Input>();

  const { recovery, flowFulfilled } = useSession();

  const { logOut } = useLogOut();

  const passwordRecovery = async (password: string) => {
    try {
      const { data } = await ory.frontend.createBrowserSettingsFlow();
      const id = data.id;
      const token = (data.ui.nodes[0].attributes as UiNodeInputAttributes)
        .value;

      const updateSettingsFlowBody: UpdateSettingsFlowBody = {
        csrf_token: token,
        method: 'password',
        password,
      };

      let errorMessages: UiText[] = [];

      const settingsFlowResponse = await ory.frontend
        .updateSettingsFlow({
          flow: id,
          updateSettingsFlowBody,
        })
        .catch((error: AxiosError<any, unknown>): void => {
          errorMessages = messagesFromData(error.response?.data);
        });

      settingsFlowResponse && logOut();

      return {
        settingsFlowResponse,
        errorMessages,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const onSubmit: SubmitHandler<Input> = (data) => {
    setLoading(true);
    setErrorMessages(null);
    setError('');
    passwordRecovery(data.password)
      .then((response) => {
        response.settingsFlowResponse?.data && setIsPasswordReset(true);
        response.errorMessages.length &&
          setErrorMessages(response.errorMessages);
      })
      .catch(() => {
        // TODO notify user if the password recovery or logout fails
        setError(
          'Something went wrong. Please, try again or contact an administrator',
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setFocus('password');
  }, [setFocus]);

  if (!flowFulfilled) {
    return <Spinner />;
  }

  return (
    <div className="min-h-[355px] w-96 rounded-2xl bg-white p-6 dark:bg-neutral-800">
      <div className="flex flex-col items-center gap-2">
        <h1 className="mt-2 font-graphikBold text-xl">Password Recovery</h1>
        {isPasswordReset ? (
          <SuccessMessage />
        ) : recovery ? (
          <InitialForm
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            register={register}
            errors={errors}
            watch={watch}
            setFocus={setFocus}
            loading={loading}
          />
        ) : (
          <div className="flex flex-col items-center">
            <Message type="error">Something went wrong</Message>
            <p>
              <Link className="text-tw-main-color" to={'/passwordRecovery'}>
                Password recovery page
              </Link>
            </p>
          </div>
        )}
        {(errorMessages || error) && (
          <Message
            data-testid="password-reset"
            children={
              <div className="flex flex-col gap-2">
                {errorMessages?.map((error) => (
                  <p
                    key={error.id}
                    data-testid="error-message"
                    data-test-value={error.id}
                  >
                    {error.text}
                  </p>
                )) || error}
              </div>
            }
            type="error"
          />
        )}
      </div>
    </div>
  );
};

const SuccessMessage = () => {
  const navigate = useNavigate();
  return (
    <div
      data-testid="password-reset-success"
      className="flex flex-col items-center gap-8"
    >
      <div data-testid="password-reset-text" className="mb-4 text-center">
        Your password has been changed successfully! You can now use it to log
        in.
      </div>
      <FontAwesomeIcon
        className="h-24 w-24 text-tw-success-strong"
        icon={faCheckCircle}
      />
      <SimpleButton
        data-testid="go-to-login"
        className="w-full rounded-xl py-4 text-lg"
        onClick={() => navigate('/')}
      >
        Go to Login
      </SimpleButton>
    </div>
  );
};

// at least 10 characters and a maximum of 30 characters, uppercase, lowercase, one digit and at least 1 special character ("!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~").
const PASS_PATTERN =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{10,30}$/;

const InitialForm = ({
  handleSubmit,
  onSubmit,
  register,
  errors,
  watch,
  setFocus,
  loading,
}: {
  handleSubmit: UseFormHandleSubmit<Input>;
  onSubmit: SubmitHandler<Input>;
  register: UseFormRegister<Input>;
  errors: FieldErrors<Input>;
  watch: UseFormWatch<Input>;
  setFocus: UseFormSetFocus<Input>;
  loading: boolean;
}) => {
  const [passVisible, setPassVisible] = useState<boolean>(false);
  const [confirmPassVisible, setConfirmPassVisible] = useState<boolean>(false);

  return (
    <>
      <h5 className="mb-1 font-graphikRegular text-xs">
        Enter your new password twice.
      </h5>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full p-3">
        <div className="group relative z-0 mb-3 w-full">
          <input
            {...register('password', {
              required: true,

              pattern: {
                value: PASS_PATTERN,
                message:
                  'Password must have between 10 and 30 characters, uppercase, lowercase, one digit and special character.',
              },
            })}
            type={`${passVisible ? 'text' : 'password'}`}
            id="floating_password"
            className={`${
              errors.passwordConfirm
                ? 'border-2 border-red-500 outline-red-500'
                : 'focus:outline-blue-600'
            } peer block h-12 w-full appearance-none  rounded-lg bg-tw-light-shade px-4 pt-3 text-sm text-gray-900 placeholder-shown:pt-0 focus:pt-3 dark:bg-tw-dark-shade-dark dark:text-tw-main-text-dark`}
            placeholder=" "
            disabled={loading}
          />
          <label
            htmlFor="floating_password"
            className="absolute left-4 top-4 origin-[0] -translate-y-2.5 scale-90 transform font-graphikRegular text-xs text-tw-description-text duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100  peer-focus:-translate-y-2.5 peer-focus:scale-90 peer-focus:font-medium peer-focus:text-blue-600 dark:text-tw-description-text peer-focus:dark:text-tw-main-color"
          >
            New password
          </label>
          {watch('password')?.length > 0 && (
            <button
              type="button"
              onClick={() => {
                setPassVisible((p) => !p);
                setFocus('password');
              }}
              className="absolute right-6 top-3 scale-90 transform font-graphikRegular text-tw-description-text duration-300 hover:text-blue-600 dark:text-tw-description-text dark:hover:text-tw-main-color"
              aria-label={passVisible ? 'Hide' : 'Show'}
            >
              {passVisible ? (
                <EyeSlashIcon className="h-6 w-6" />
              ) : (
                <EyeIcon className="h-6 w-6" />
              )}
            </button>
          )}
          {(errors.passwordConfirm || errors.password) && (
            <CloseIcon className="absolute -right-8 top-3 w-8 scale-90 transform font-graphikRegular text-[#E22134] duration-300" />
          )}
        </div>

        {/* SECOND INPUT */}
        <div className="group relative z-0 mb-3 w-full">
          <input
            {...register('passwordConfirm', {
              required: true,
              validate: (value: string) => value === watch('password'),
            })}
            type={`${confirmPassVisible ? 'text' : 'password'}`}
            id="floating_passwordConfirm"
            className={`${
              errors.passwordConfirm || errors.password
                ? 'border-2 border-red-500 outline-red-500'
                : 'border-none focus:outline-blue-600'
            } peer block h-12 w-full appearance-none  rounded-lg bg-tw-light-shade px-4 pt-3 text-sm text-gray-900 placeholder-shown:pt-0
            focus:pt-3 dark:bg-tw-dark-shade-dark dark:text-tw-main-text-dark`}
            placeholder=" "
            disabled={loading}
          />
          <label
            htmlFor="floating_passwordConfirm"
            className="absolute left-4 top-4 origin-[0] -translate-y-2.5 scale-90 transform font-graphikRegular text-xs text-tw-description-text duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100  peer-focus:-translate-y-2.5 peer-focus:scale-90 peer-focus:font-medium peer-focus:text-blue-600 dark:text-tw-description-text peer-focus:dark:text-tw-main-color"
          >
            Repeat new password
          </label>
          {watch('passwordConfirm')?.length > 0 && (
            <button
              type="button"
              onClick={() => {
                setConfirmPassVisible((p) => !p);
                setFocus('passwordConfirm');
              }}
              className="absolute right-6 top-3 scale-90 transform font-graphikRegular text-tw-description-text duration-300 hover:text-blue-600 dark:text-tw-description-text dark:hover:text-tw-main-color"
              aria-label={confirmPassVisible ? 'Hide' : 'Show'}
            >
              {confirmPassVisible ? (
                <EyeSlashIcon className="h-6 w-6" />
              ) : (
                <EyeIcon className="h-6 w-6" />
              )}
            </button>
          )}
          {(errors.passwordConfirm || errors.password) && (
            <CloseIcon className="absolute -right-8 top-3 w-8 scale-90 transform font-graphikRegular text-[#E22134] duration-300" />
          )}
        </div>
        {errors.password?.message && (
          <div
            data-testid="error-password"
            className="flex w-[320px] items-center justify-center text-xs text-red-600"
          >
            <WarningCircleIcon className="w-5" />
            <div className="w-full">{errors.password.message}</div>
          </div>
        )}
        {errors.passwordConfirm && (
          <div
            data-testid="error-password-confirm"
            className="flex w-[320px] items-center justify-center text-xs text-red-600"
          >
            <WarningCircleIcon className="mt-0.5 w-5" />
            <div className="w-full">
              Both pass inputs don't match. Check them and try again.
            </div>
          </div>
        )}
        <SimpleButton
          data-testid="change-password-button"
          disabled={
            loading ||
            (watch('passwordConfirm')?.length === 0 &&
            watch('password')?.length === 0
              ? true
              : false)
          }
          type="submit"
          className="m-0 w-full rounded-lg p-4"
          loading={loading}
        >
          Change my password
        </SimpleButton>
      </form>
    </>
  );
};
