import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductKind } from '../../api/product/types';
import { Duration } from '../../components/CalenderRangePicker/utils';
import { AlertType, Category } from '../../gql/graphql';

export interface ProductFilterState {
  products: Array<ProductKind | `${ProductKind}`>;
  category: Category | null;
  duration: Duration;
  appliances: Array<string> | null;
  alertCodes: Array<string>;
  alertType?: Array<AlertType>;
}

export const DEFAULT_PRODUCT_FILTER: ProductFilterState = {
  products: [],
  duration: 'P7D',
  appliances: null,
  category: null,
  alertCodes: [],
  alertType: [],
};

const initialState: ProductFilterState = DEFAULT_PRODUCT_FILTER;

const productFilterSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    updateProductsFilterCategory(
      state,
      action: PayloadAction<ProductFilterState['category']>,
    ): ProductFilterState {
      const category = action.payload;

      return {
        ...state,
        category,
        products: [],
      };
    },
    updateProductsFilterProducts(
      state,
      action: PayloadAction<Array<ProductKind | `${ProductKind}`>>,
    ): ProductFilterState {
      return {
        ...state,
        products: action.payload,
        category: null,
      };
    },
    resetProductsFilter(): ProductFilterState {
      return DEFAULT_PRODUCT_FILTER;
    },
    updateProductsFilterDuration(
      state,
      action: PayloadAction<Duration>,
    ): ProductFilterState {
      return {
        ...state,
        duration: action.payload,
      };
    },
    updateProductsFilterAppliance(
      state,
      action: PayloadAction<ProductFilterState['appliances']>,
    ): ProductFilterState {
      return {
        ...state,
        appliances: action.payload,
      };
    },
    updateFilterAlertCodes(
      state,
      action: PayloadAction<ProductFilterState['alertCodes']>,
    ): ProductFilterState {
      return {
        ...state,
        alertCodes: action.payload,
      };
    },
    updateFilterAlertType(
      state,
      action: PayloadAction<ProductFilterState['alertType']>,
    ): ProductFilterState {
      return {
        ...state,
        alertType: action.payload,
      };
    },
  },
});

export const {
  updateProductsFilterCategory,
  updateProductsFilterProducts,
  resetProductsFilter,
  updateProductsFilterDuration,
  updateProductsFilterAppliance,
  updateFilterAlertCodes,
  updateFilterAlertType,
} = productFilterSlice.actions;
export default productFilterSlice.reducer;
