import { Link } from 'react-router-dom';
import { Icon, IconName } from '../../assets/icons/Icon';
import { LoginForm } from '../../pages/loginPage/components/LoginForm';
import { Role } from '../../redux/login/loginSlice';
import { useSession } from '../../utils/hooks/useSession';
import { SpinnerWithText } from '../Elements/Spinner/SpinnerWithText';
import { useLogOut } from '../Elements/User';

export const hasAnyRole = ({
  requiredRole,
  currentTenantRoles = [],
}: {
  requiredRole?: Role | Role[];
  currentTenantRoles?: Role[];
}) => {
  if (!requiredRole) {
    return true;
  }
  if (Array.isArray(requiredRole)) {
    return requiredRole.some((r) => currentTenantRoles.includes(r));
  }
  return currentTenantRoles.includes(requiredRole);
};

/**
 * This component is used to protect routes from unauthorized access.
 * If the user is not logged in, it will display the login form.
 * @param message Shows a message above the login form
 * @param children The content to display if the user is logged in
 * @returns A component that will display the login form if the user is not logged in or the content if the user is logged in
 */
export const ProtectedComponent = ({
  message,
  children,
  requiredRole,
  unauthorizedMessage = <UnauthorizedMessage />,
}: {
  message?: string;
  children: React.ReactNode;
  requiredRole?: Role | Role[];
  unauthorizedMessage?: React.ReactNode;
}) => {
  const { auth, flowFulfilled, currentTenant } = useSession();
  const roleAccess = hasAnyRole({
    requiredRole,
    currentTenantRoles: currentTenant?.roles,
  });
  return (
    <>
      {!flowFulfilled ? (
        <SpinnerWithText
          text="Checking login status..."
          data-testid="protected-component-spinner"
          spinnerProps={{
            'data-testid': 'auth-spinner',
          }}
        />
      ) : auth ? (
        roleAccess ? (
          children
        ) : (
          unauthorizedMessage
        )
      ) : (
        <div className="flex flex-col items-center justify-center gap-4">
          {message && <h1 className="pb-4 text-xl font-bold">{message}</h1>}
          <LoginForm />
        </div>
      )}
    </>
  );
};

export const UnauthorizedMessage = ({
  message = 'Current user does not have access to this page',
}: {
  message?: string;
}) => {
  const { logOutButton } = useLogOut({
    navigateAfterLogout: false,
  });
  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <div className="rounded-xl bg-white p-6 dark:bg-tw-light-shade-dark">
        <h3 className="flex flex-row items-center gap-2 pb-4 text-sm font-bold">
          <Icon iconName={IconName.ErrorIcon} className="h-5 w-5" />
          {message}
        </h3>
      </div>
      <nav className="flex flex-row items-center justify-end gap-2 pb-4">
        <Link
          to="/"
          className="text-sm font-normal text-tw-main-color underline"
        >
          Go home
        </Link>
        {logOutButton}
      </nav>
    </div>
  );
};
