import { useQuery } from '@apollo/client';
import {
  faChevronRight,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { isEmpty, mapObjIndexed } from 'ramda';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { JSONTree } from 'react-json-tree';
import { toast } from 'react-toastify';
import { GET_ALERT } from '../../../api/alerts/queries';
import { MAIN_TOOLTIP_ID } from '../../../App';
import { SimpleButton } from '../../../components/Buttons/SimpleButton';
import {
  ConfirmationModalTrigger,
  Modal,
} from '../../../components/common/Modal';
import { FieldError } from '../../../components/form/fields';
import { GetAlertQuery, GetAlertQueryVariables } from '../../../gql/graphql';
import {
  DEFAULT_DATE_TIME_FORMAT,
  MACHINE_READABLE_DATE_TIME_FORMAT,
} from '../../../utils/format';
import { useIsOverflow } from '../../../utils/hooks/useIsOverflow';
import Spinner, { WithSpinner } from '../Spinner/Spinner';
import { AlertGroupedByCode } from './AlertList';

interface IProps {
  selectedAlert: AlertGroupedByCode;
  onClose?: () => void;
  onClear?: (alerts?: string[] | undefined) => Promise<boolean>;
}

const MAX_ALERTS = 20;

const AlertDrawer = ({ selectedAlert, onClose, onClear }: IProps) => {
  const { t } = useTranslation(['notifications', 'alertCodes', 'productInfo']);
  const { code, alerts } = selectedAlert;
  const [loading, setLoading] = useState(false);

  const alertInfo = useQuery<GetAlertQuery, GetAlertQueryVariables>(GET_ALERT, {
    variables: {
      alertId: selectedAlert.id || '',
    },
    skip: !selectedAlert.id,
  });

  const handleClear = useCallback(() => {
    !loading && onClear && setLoading(true);
    !loading &&
      onClear?.(selectedAlert?.alerts.map((a) => a.id))
        .then(() => {
          setLoading(false);
          onClose?.();
        })
        .catch(toast.error);
  }, [selectedAlert, onClear]);

  const [alertDetailId, setAlertDetailId] = useState<string | null>(null);
  const handleClearInfo = useCallback(async () => {
    const res = await onClear?.([alertDetailId || ''])
      .then(() => {
        onClose?.();
        setAlertDetailId(null);
        return true;
      })
      .catch((error) => {
        toast.error(error);
        return false;
      });
    return res || false;
  }, [alertDetailId, onClose, onClear]);

  // restart scroll position when selectedAlert changes
  useEffect(() => {
    const element = document.getElementById('alertDrawer');
    element?.scrollTo(0, 0);
    setShowAdditionalAlerts(false);
  }, [selectedAlert]);

  const [showAdditionalAlerts, setShowAdditionalAlerts] = useState(false);

  const alertsToShow = useMemo(
    () => (showAdditionalAlerts ? alerts : alerts?.slice(0, MAX_ALERTS)),
    [showAdditionalAlerts, alerts],
  );

  // use 'code' to lookup information from CDN
  return (
    <div
      data-testid="alertDrawer"
      className="flex h-full flex-col rounded-xl border border-[#EEF4FF] bg-white p-4 shadow-lg dark:border-[#1A1C1E] dark:bg-[#25282E]"
    >
      <AlertInfoModal
        onClose={() => setAlertDetailId(null)}
        alertId={alertDetailId}
        onClear={handleClearInfo}
      />
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-center justify-between border-b border-black/5 pb-4 dark:border-white/5">
          <h3
            className="font-graphikSemi dark:text-white"
            style={{ fontSize: '13px', lineHeight: '14px' }}
          >
            {t(`notifications.detail.title`, 'Alert details', {
              ns: 'notifications',
            })}
          </h3>
          <button onClick={() => onClose?.()}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>
      <div
        className="scrollbar flex max-h-[48rem] flex-col overflow-y-scroll py-4 pr-4"
        id="alertDrawer"
      >
        <div className="flex flex-col gap-6">
          <Section
            title={
              <>
                Time since alert
                {Boolean(alerts?.length) &&
                  alerts.length > MAX_ALERTS &&
                  ` (${alerts?.length})`}
              </>
            }
          >
            <ul className="w-full list-disc pl-4">
              {alertsToShow.map((alert) => (
                <li
                  key={alert.id}
                  className="list-item font-graphikRegular"
                  style={{ fontSize: '15px', lineHeight: '17px' }}
                >
                  <button
                    onClick={() => setAlertDetailId(alert.id)}
                    className="flex w-full flex-row items-center justify-between gap-2 hover:underline"
                  >
                    <span>{dayjs(alert.createdAt).fromNow()}</span>
                    <span className="text-tw-inactive-color">
                      {dayjs(alert.createdAt).format('MM/DD/YY HH:mm')}
                    </span>
                  </button>
                </li>
              ))}
            </ul>
          </Section>
          <Section title="Alert Description">
            <p
              className="font-graphikRegular"
              style={{ fontSize: '15px', lineHeight: '17px' }}
            >
              {t(`${code}.description`, {
                ns: 'alertCodes',
              })}
            </p>
          </Section>
          <AlertInfoSection info={alertInfo?.data?.alert.info} />
          <Section title="How to solve it">
            <p
              className="font-graphikRegular"
              style={{ fontSize: '15px', lineHeight: '17px' }}
            >
              {t(`${code}.help`, {
                ns: 'alertCodes',
              })}
            </p>
          </Section>
        </div>
      </div>
      {selectedAlert?.active && (
        <div className="mt-auto flex flex-row justify-between gap-3 border-b border-t border-black/5 pt-4 dark:border-white/5">
          <button
            onClick={handleClear}
            className="flex h-10 w-full flex-row items-center justify-center gap-2 rounded-[6px] border border-[#E22134] bg-white py-2 font-graphikRegular text-[#E22134] dark:bg-tw-light-shade-dark"
          >
            {loading ? (
              <Spinner className="h-full" />
            ) : (
              t(`notifications.detail.clear`, 'Clear alert', {
                ns: 'notifications',
              })
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export const Section = ({
  title,
  children,
  ...rest
}: {
  title: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <section className="flex flex-col gap-2" {...rest}>
      <h4
        className="flex flex-row items-center gap-2 font-graphikMedium dark:text-white"
        style={{ fontSize: '13px', lineHeight: '14px' }}
      >
        {title}
      </h4>
      {children}
    </section>
  );
};

export const AlertInfoModal = ({
  alertId,
  onClose,
  onClear,
}: {
  alertId?: string | null;
  onClose?: () => void;
  onClear?: () => Promise<boolean>;
}) => {
  const { t } = useTranslation(['notifications', 'alertCodes', 'productInfo']);
  const isOpen = useMemo(() => Boolean(alertId), [alertId]);

  const { data, loading, error } = useQuery<
    GetAlertQuery,
    GetAlertQueryVariables
  >(GET_ALERT, {
    variables: {
      alertId: alertId || '',
    },
    skip: !alertId,
  });

  const { code } = data?.alert || {};

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faInfoCircle} />
          Alert info
          <span>{dayjs(data?.alert.createdAt).format('MM/DD/YY HH:mm')}</span>
        </div>
      }
    >
      <div className="text-wrap max-w-prose p-4 pb-0 text-left">
        <WithSpinner loading={loading}>
          <div className="flex flex-col gap-6">
            <Section title="Alert Description">
              <p
                className="font-graphikRegular"
                style={{ fontSize: '15px', lineHeight: '17px' }}
              >
                {t(`${code}.description`, {
                  ns: 'alertCodes',
                })}
              </p>
            </Section>
            <AlertInfoSection info={data?.alert.info} />
            <Section title="How to solve it">
              <p
                className="font-graphikRegular"
                style={{ fontSize: '15px', lineHeight: '17px' }}
              >
                {t(`${code}.help`, {
                  ns: 'alertCodes',
                })}
              </p>
            </Section>
          </div>
          <FieldError error={error?.message} />
          <div className="actions sticky bottom-0 mt-4 flex w-full flex-row items-center justify-end gap-2 bg-white dark:bg-[#1F2225]">
            <ConfirmationModalTrigger
              onConfirm={onClear}
              modalTitle="Delete alert"
              modalContent="This action will delete alert. Are you sure?"
            >
              <SimpleButton
                data-testid="clear-button"
                variant="critical"
                className="m-0 px-4"
                onClick={(e) => e.preventDefault()}
              >
                Clear
              </SimpleButton>
            </ConfirmationModalTrigger>
            <SimpleButton
              onClick={onClose}
              data-testid="close-button"
              className="m-0"
            >
              Close
            </SimpleButton>
          </div>
        </WithSpinner>
      </div>
    </Modal>
  );
};

type JSONValue =
  | string
  | number
  | boolean
  | { [x: string]: JSONValue }
  | Array<JSONValue>;

const safeJsonParse = <T,>(str: string) => {
  try {
    const jsonValue: T = JSON.parse(str);
    return jsonValue;
  } catch {
    return undefined;
  }
};

const safeJsonParseOrValue = (value: JSONValue): JSONValue => {
  return typeof value === 'string'
    ? safeJsonParse<JSONValue>(value) || value
    : typeof value === 'object'
    ? Array.isArray(value)
      ? value.map(safeJsonParseOrValue)
      : mapObjIndexed(safeJsonParseOrValue, value)
    : value;
};

/**
 * Checks value is a number and is between a date range.
 * By default min is '2000/1/1' and max '2100/1/1'
 * @param value
 * @returns boolean
 */
const isDateNumber = (
  value: unknown,
  min = '2000/1/1',
  max = '2100/1/1',
): value is number =>
  typeof value === 'number' &&
  value > dayjs(min).valueOf() &&
  value < dayjs(max).valueOf();

const jsonTreeValueRenderer = (raw: unknown) =>
  isDateNumber(raw) ? (
    <time
      data-tooltip-id={MAIN_TOOLTIP_ID}
      data-tooltip-content={`${raw}`}
      dateTime={dayjs(raw).format(MACHINE_READABLE_DATE_TIME_FORMAT)}
    >
      {dayjs(raw).format(DEFAULT_DATE_TIME_FORMAT)}
    </time>
  ) : (
    <>{raw}</>
  );

export const AlertInfoSection = ({ info }: { info?: JSONValue }) => {
  const safeJson = useMemo(() => info && safeJsonParseOrValue(info), [info]);
  return info && safeJson && !isEmpty(safeJson) ? (
    <Section title="Details">
      {typeof safeJson === 'object' || Array.isArray(safeJson) ? (
        <JSONTreeViewer safeJson={safeJson} />
      ) : (
        safeJson
      )}
    </Section>
  ) : null;
};

const JSONTreeViewer = ({ safeJson }: { safeJson: JSONValue }) => {
  const [collapsed, setCollapsed] = useState(true);
  const ref = useRef(null);
  const isOverflow = useIsOverflow(ref, undefined, 'height');
  return (
    <>
      <div
        className={classNames(
          collapsed && 'scrollbar relative max-h-[160px] overflow-hidden',
        )}
        ref={ref}
      >
        <JSONTree
          hideRoot
          shouldExpandNodeInitially={() => true}
          valueRenderer={jsonTreeValueRenderer}
          data={safeJson}
          theme={{
            scheme: 'unset',
            base00: 'unset',
            base01: 'unset',
            base02: 'unset',
            base03: 'unset',
            base04: 'unset',
            base05: 'unset',
            base06: 'unset',
            base07: 'unset',
            base08: 'unset',
            base09: 'unset',
            base0A: 'unset',
            base0B: 'unset',
            base0C: 'unset',
            base0D: 'unset',
            base0E: 'unset',
            base0F: 'unset',

            tree: () => ({
              className: 'm-0 p-0 font-graphikRegular text-sm text-nowrap',
            }),
            nestedNodeItemString: () => ({
              className:
                'text-tw-description-text/75 dark:text-tw-description-text-dark/75',
            }),
            valuesLabel: () => ({
              className:
                'text-tw-description-text dark:text-tw-description-text-dark',
            }),
            valueText: () => ({
              style: {
                wordBreak: 'normal',
              },
              className:
                'text-tw-description-text dark:text-tw-description-text-dark break-words',
            }),
          }}
        />
        {collapsed && isOverflow && (
          <div className="pointer-events-none absolute bottom-0 h-5 w-full  bg-gradient-to-b from-transparent to-white  dark:to-[#25282E]">
            {' '}
          </div>
        )}
      </div>
      {isOverflow && collapsed ? (
        <SimpleButton
          variant="default-ghost"
          onClick={() => setCollapsed(false)}
        >
          show all
        </SimpleButton>
      ) : (
        !collapsed && (
          <SimpleButton
            variant="default-ghost"
            onClick={() => setCollapsed(true)}
          >
            show less
          </SimpleButton>
        )
      )}
    </>
  );
};

export default AlertDrawer;
