import { FieldError } from 'react-hook-form';
import { WarningIcon } from '../Elements/Icons/WarningIcon';

interface IProps {
  error: FieldError | undefined;
}

export const ErrorsField = ({ error }: IProps) => {
  return error ? (
    <div className="flex w-full grow items-center text-xs text-red-600">
      <div className="mr-2 h-5 w-5">
        <WarningIcon className="h-5 w-5" />
      </div>
      <div>{error.message}</div>
    </div>
  ) : null;
};
