import { Logout } from '../../components/Elements/Logout/Logout';
import { User, useLogOut } from '../../components/Elements/User';
import { useSession } from '../../utils/hooks/useSession';
import { ChangePasswordForm } from './components/ChangePasswordForm';
import { SupernaHeader } from './components/SupernaHeader';
import { ThemeButton } from './components/ThemeButton';

export const ChangePasswordPage = () => {
  const sesion = useSession();
  const { logOut } = useLogOut();
  return (
    <div className="scrollbar h-screen overflow-auto">
      <ThemeButton />
      <div className="flex h-full flex-col items-center justify-center">
        <SupernaHeader />
        <div className="mt-14 flex items-start justify-start gap-10">
          <ChangePasswordForm />
        </div>
        {(sesion.auth || sesion.recovery) && (
          <div className="flex flex-row items-center gap-4 p-4">
            <User className="w-min" />
            <Logout onClick={logOut} />
          </div>
        )}
      </div>
    </div>
  );
};
