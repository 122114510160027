import React from 'react';

export const OrganizationsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 4.5H9V6.5H11V4.5ZM9 3H11C11.8125 3 12.5 3.6875 12.5 4.5V6.5C12.5 7.34375 11.8125 8 11 8H10.75V9.25H15.5C16.4375 9.25 17.25 10.0625 17.25 11V12H17.5C18.3125 12 19 12.6875 19 13.5V15.5C19 16.3438 18.3125 17 17.5 17H15.5C14.6562 17 14 16.3438 14 15.5V13.5C14 12.6875 14.6562 12 15.5 12H15.75V11C15.75 10.875 15.625 10.75 15.5 10.75H10.75V12H11C11.8125 12 12.5 12.6875 12.5 13.5V15.5C12.5 16.3438 11.8125 17 11 17H9C8.15625 17 7.5 16.3438 7.5 15.5V13.5C7.5 12.6875 8.15625 12 9 12H9.25V10.75H4.5C4.34375 10.75 4.25 10.875 4.25 11V12H4.5C5.3125 12 6 12.6875 6 13.5V15.5C6 16.3438 5.3125 17 4.5 17H2.5C1.65625 17 1 16.3438 1 15.5V13.5C1 12.6875 1.65625 12 2.5 12H2.75V11C2.75 10.0625 3.53125 9.25 4.5 9.25H9.25V8H9C8.15625 8 7.5 7.34375 7.5 6.5V4.5C7.5 3.6875 8.15625 3 9 3ZM2.5 13.5V15.5H4.5V13.5H2.5ZM9 13.5V15.5H11V13.5H9ZM15.5 13.5V15.5H17.5V13.5H15.5Z"
        fill="currentColor"
        fill-opacity="0.95"
      />
    </svg>
  );
};

export default OrganizationsIcon;
