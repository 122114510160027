import { useCallback, useEffect, useMemo, useState } from 'react';
import { Icon, IconName } from '../../../assets/icons/Icon';
import { SimpleButton } from '../../Buttons/SimpleButton';
import { DataTable, DataTableColumn } from '../../DataTable/DataTable';
import { FormField } from './FormField';
import {
  BaseFormField,
  ChangeHandler,
  FormFieldElement,
  FormFieldType,
  FormFieldValue,
} from './types';
import { AddRowForm } from './components/AddRowForm';

export interface DataTableRow {
  internalUseIdDynamicRow: string;
  unDeletable?: boolean;
  disabled?: boolean;
  [key: string]: FormFieldValue | boolean;
}
export interface FormDataTable extends BaseFormField {
  type: FormFieldType.DATA_TABLE | `${FormFieldType.DATA_TABLE}`;
  columns: FormFieldElement[];
  value?: DataTableRow[];
  defaultValue?: DataTableRow[];
}
export interface FormTableSelect extends BaseFormField {
  type: FormFieldType.TABLE_SELECT;
  rows: DataTableRow[];
  columns: {
    id: string;
    label: string;
    description?: string;
    sortable?: boolean;
  }[];
  value?: string[];
  defaultValue?: string[];
}

const removeInternalId = (row: DataTableRow) => {
  const { internalUseIdDynamicRow, ...rest } = row;
  return rest;
};
const removeInternalIds = (rows?: DataTableRow[]) =>
  rows?.map(removeInternalId);

export const DynamicFormDataTable = ({
  field,
  onChange,
  formAdd,
}: {
  field: FormDataTable;
  onChange: ChangeHandler;
  formAdd?: boolean;
}) => {
  const [fieldRows, setFieldRows] = useState<(typeof field)['value']>(
    field.value || [],
  );

  useEffect(() => {
    setFieldRows(
      field.value?.map((row, index) => ({
        ...row,
        internalUseIdDynamicRow:
          row?.internalUseIdDynamicRow || `${field.id}.item[${index}]`,
      })) || [],
    );
  }, [field.value]);

  const columns: DataTableColumn<DataTableRow>[] = [
    ...field.columns.map(
      (col): DataTableColumn<DataTableRow> => ({
        field: col.id,
        headerName: col.title,
        sortable: col.sortable === undefined ? true : col.sortable,
        description: col.description,
        renderCell: ({ row }) => {
          const rowValue = row?.[col.id] as any; // as typeof col['value'];
          return (
            <FormField
              field={{ ...col, value: rowValue, disabled: row?.disabled }}
              onChange={({ id: changeId, value: changeValue }) => {
                const newRows = fieldRows?.map((r) => {
                  if (
                    r.internalUseIdDynamicRow === row?.internalUseIdDynamicRow
                  ) {
                    return {
                      ...r,
                      [changeId]: changeValue,
                    };
                  }
                  return r;
                });
                setFieldRows(newRows);
                onChange({
                  id: field.id,
                  value: removeInternalIds(newRows),
                  pathArray: field.path,
                });
              }}
            />
          );
        },
      }),
    ),
    {
      field: 'actions',
      headerName: (
        <Icon className="mx-auto h-3 w-8" iconName={IconName.SettingsIcon} />
      ),
      classNames: {
        thContainer: 'justify-center',
      },
      renderCell: ({ row }) => (
        <div className="flex w-full items-center justify-center">
          <SimpleButton
            variant="default-ghost"
            aria-label="Delete row"
            disabled={row?.disabled || row?.unDeletable}
            onClick={() => {
              const newRows = fieldRows?.filter(
                (r) =>
                  r.internalUseIdDynamicRow !== row?.internalUseIdDynamicRow,
              );
              setFieldRows(newRows);
              onChange({
                id: field.id,
                value: removeInternalIds(newRows),
                pathArray: [],
              });
            }}
          >
            <Icon iconName={IconName.CrossIcon} className="h-3 w-3" />
          </SimpleButton>
        </div>
      ),
    },
  ];

  const handleAdd = useCallback(
    (values?: { [x: string]: FormFieldValue }) => {
      const newRows = [
        ...(fieldRows || []),
        {
          internalUseIdDynamicRow: `${field.id}.item[${
            fieldRows?.length || 0
          }]`,
          ...values,
        },
      ];
      setFieldRows(newRows);
      onChange({
        id: field.id,
        value: removeInternalIds(newRows),
        pathArray: [],
      });
    },
    [fieldRows, field.id, onChange, field.defaultValue],
  );

  const defaultValues = useMemo(
    () =>
      field.columns.reduce((acc, cur) => {
        return { ...acc, [cur.id]: cur.defaultValue || cur.value };
      }, {}),
    [field.columns],
  );

  return (
    <>
      <DataTable
        key={field.id}
        // id={field.id}
        rows={fieldRows || []}
        columns={columns}
        keyExtractor={(row) => row?.internalUseIdDynamicRow}
        pagination
        pageSize={5}
      />
      {!formAdd ? (
        <SimpleButton
          // disabled={isAddDisabled}
          variant="primary"
          onClick={() => handleAdd(defaultValues)}
          className="ml-auto"
          aria-label="Add item"
        >
          <Icon iconName={IconName.CrossIcon} className="h-3 w-3 rotate-45" />
        </SimpleButton>
      ) : (
        <div id="add-row">
          <AddRowForm field={field} onAdd={handleAdd} />
        </div>
      )}
    </>
  );
};

export const DynamicFormTableSelect = ({
  field,
  onChange,
}: {
  field: FormTableSelect;
  onChange: ChangeHandler;
}) => {
  return (
    <DataTable
      key={field.id}
      // id={field.id}
      rows={field.rows as any[]}
      keyExtractor={(row) => row.internalUseIdDynamicRow}
      columns={
        field.columns.map((col) => ({
          field: col.id,
          headerName: col.label,
          sortable: col.sortable,
          description: col.description,
        })) as DataTableColumn<DataTableRow>[]
      }
      isRowSelectionDisabled={(row) => Boolean(row.disabled)}
      checkboxSelection
      defaultSelectedRows={field.value}
      onSelect={(value) => {
        onChange({ id: field.id, value, pathArray: [] });
      }}
    />
  );
};
