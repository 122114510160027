import { GetInventoryQuery, LicenseKind } from '../../../../../gql/graphql';

export const sizeByKind = (
  product: GetInventoryQuery['appliances'][0]['installedProducts'][0],
  kind: LicenseKind,
) =>
  product.licenses
    .filter((license) => license.kind === kind)
    .reduce(
      (acc, current) => ({
        sizeLicensed: acc.sizeLicensed + current.sizeLicensed,
        sizeConsumed: acc.sizeConsumed + current.sizeConsumed,
      }),
      {
        sizeLicensed: 0,
        sizeConsumed: 0,
      },
    );
