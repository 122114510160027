import {
  UiNodeInputAttributes,
  UiText,
  RegistrationFlow,
} from '@ory/kratos-client';
import { ory } from './config';
import { messagesFromData } from './utils';

export const submitRegistrationFlow = async ({
  flow,
  identifier,
  password,
  name,
  surname,
}: {
  flow: RegistrationFlow;
  identifier: string;
  password: string;
  name: string;
  surname: string;
}) => {
  let messages: UiText[] = [];

  try {
    // Get the flow ID to submit the Registration  request ( And set the cookie )
    const { id: flowId } = flow;

    // With the ID, we can now submit the registration form to ORY

    const tokenAttributes = flow.ui.nodes.find(
      (node) =>
        (node.attributes as UiNodeInputAttributes)?.name === 'csrf_token',
    )?.attributes as UiNodeInputAttributes;

    await ory.frontend
      .updateRegistrationFlow({
        flow: flowId,
        updateRegistrationFlowBody: {
          method: 'password',
          password,
          csrf_token: tokenAttributes.value,
          traits: {
            email: identifier,
            name,
            surname,
          },
        },
      })
      .catch(async (e) => {
        const data: RegistrationFlow = e.response.data;
        messages = messagesFromData(data);

        throw new Error('Registration failed');
      });
  } catch (error) {
    return {
      success: false,
      messages,
    };
  }
  return {
    success: true,
    messages,
  };
};
