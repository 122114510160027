import { useRef } from 'react';
import { useIsOverflowTooltip } from '../../utils/hooks/useIsOverflow';

export interface OverflowTooltipProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  children: string;
}
export const OverflowTooltip = ({
  children,
  ...rest
}: OverflowTooltipProps) => {
  const ref = useRef(null);
  const tooltip = useIsOverflowTooltip(ref, children);

  return (
    <span ref={ref} {...tooltip} {...rest}>
      {children}
    </span>
  );
};
