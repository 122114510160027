export const EyeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="fill-current text-teal-500"
    width="1em"
    height="1em"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <path
      d="M5.5558 7.61905C5.5558 5.21429 7.54547 3.26531 10.0004 3.26531C12.4554 3.26531 14.4451 5.21429 14.4451 7.61905C14.4451 10.0238 12.4554 11.9728 10.0004 11.9728C7.54547 11.9728 5.5558 10.0238 5.5558 7.61905ZM10.0004 10.3401C11.5352 10.3401 12.7783 9.12245 12.7783 7.61905C12.7783 6.11565 11.5352 4.89796 10.0004 4.89796C9.97613 4.89796 9.9553 4.89796 9.89974 4.89796C9.97613 5.07143 10.0004 5.2551 10.0004 5.44218C10.0004 6.64286 9.00387 7.61905 7.77812 7.61905C7.58714 7.61905 7.39963 7.59524 7.22254 7.52041C7.22254 7.57483 7.22254 7.59524 7.22254 7.58844C7.22254 9.12245 8.46565 10.3401 10.0004 10.3401ZM3.31334 2.7415C4.94814 1.25306 7.19476 0 10.0004 0C12.8061 0 15.0527 1.25306 16.6882 2.7415C18.3133 4.21769 19.4001 5.95578 19.9141 7.20068C20.0286 7.46939 20.0286 7.76871 19.9141 8.03741C19.4001 9.2517 18.3133 10.9898 16.6882 12.4966C15.0527 13.9864 12.8061 15.2381 10.0004 15.2381C7.19476 15.2381 4.94814 13.9864 3.31334 12.4966C1.68827 10.9898 0.602114 9.2517 0.0854594 8.03741C-0.0284865 7.76871 -0.0284865 7.46939 0.0854594 7.20068C0.602114 5.95578 1.68827 4.21769 3.31334 2.7415ZM10.0004 1.63265C7.73645 1.63265 5.87526 2.63946 4.44811 3.93537C3.11125 5.15306 2.18829 6.56803 1.71675 7.61905C2.18829 8.63946 3.11125 10.085 4.44811 11.3027C5.87526 12.5986 7.73645 13.6054 10.0004 13.6054C12.2644 13.6054 14.1256 12.5986 15.5528 11.3027C16.8896 10.085 17.782 8.63946 18.2855 7.61905C17.782 6.56803 16.8896 5.15306 15.5528 3.93537C14.1256 2.63946 12.2644 1.63265 10.0004 1.63265Z"
      fill="currentColor"
    />
  </svg>
);
