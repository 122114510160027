import { linearGradientDef } from '@nivo/core';
import { CustomLayerProps, ResponsiveLine, Serie } from '@nivo/line';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { TimedLineChart } from '../../gql/graphql';
import { useAppSelector } from '../../redux/hooks';
import { humanizeValue } from '../../utils/format';

const CustomPoint = (props: CustomLayerProps) => {
  // Nivo Custom line canvas layers have different props then custom svg line layers
  const { currentPoint, borderWidth, borderColor } =
    props as CustomLayerProps & {
      currentPoint: {
        x: number;
        y: number;
      };
      borderWidth: number;
      borderColor: string;
    };
  // it will show the current point
  if (currentPoint) {
    return (
      <g>
        <circle
          fill={'#A8EEFF'}
          r={10}
          fillOpacity={0.35}
          cx={currentPoint.x}
          cy={currentPoint.y}
        />
        <circle
          r={4}
          strokeWidth={borderWidth}
          stroke={borderColor}
          fill={'#1a6dfc'}
          cx={currentPoint.x}
          cy={currentPoint.y}
        />
      </g>
    );
  }
  return null;
};

export const GraphLine = ({
  statsData,
  timeRange,
  showPointOnHover = true,
  unit,
  maxDensity = 50,
}: {
  statsData: TimedLineChart;
  timeRange?: number;
  showPointOnHover?: boolean;
  unit?: string;
  maxDensity?: number;
}) => {
  const mappedData = useMemo(
    () =>
      statsData.values?.map((value) => ({
        ...value,
        x: dayjs(value.time).toDate(),
        y: value.value,
      })),
    [statsData],
  );

  const data = useMemo(() => {
    if (!mappedData) return [];
    const dataLength = mappedData.length;
    if (dataLength < maxDensity) return mappedData;
    const step = Math.floor(dataLength / maxDensity);
    return mappedData.filter((_, index) => index % step === 0);
  }, [mappedData, maxDensity]);

  const series: Serie[] = useMemo(
    () => [
      {
        id: 'week',
        data,
      },
    ],
    [data],
  );
  const theme = useAppSelector((state) => state.theme.value);

  const { language } = useAppSelector((state) => state.locale);

  return (
    <ResponsiveLine
      data={series}
      curve="catmullRom"
      xScale={{
        type: 'time',
        precision: 'second',
        min: timeRange
          ? dayjs().subtract(timeRange, 'minutes').toDate()
          : 'auto',
        max: timeRange ? dayjs().toDate() : 'auto',
      }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        reverse: false,
      }}
      margin={{
        top: 10,
        right: 10,
        bottom: 20,
        left: 20,
      }}
      axisBottom={null}
      axisLeft={null}
      animate={true}
      defs={[
        linearGradientDef('gradientA', [
          { offset: 50, color: '#013FFF', opacity: 1 },
          { offset: 90, color: ' #A6CEE3', opacity: 0.4 },
          { offset: 100, color: ' #A6CEE3', opacity: 0 },
        ]),
      ]}
      fill={[{ match: '*', id: 'gradientA' }]}
      lineWidth={3}
      enableArea
      enablePoints={false}
      {...(showPointOnHover && {
        layers: [
          CustomPoint,
          'grid',
          'markers',
          'areas',
          'crosshair',
          'lines',
          'slices',
          'axes',
          'points',
          'mesh',
          'legends',
        ],
      })}
      useMesh
      enableGridX={false}
      enableGridY={false}
      colors={theme === 'light' ? '#2A63EF' : '#2A63EF'}
      enableCrosshair={false}
      tooltip={({ point }) => {
        return (
          <div className="flex w-auto flex-col gap-2 rounded-md border border-black/10 bg-white p-3 text-xs text-tw-main-text shadow-dark-bottom-20 dark:border-white/10 dark:bg-tw-dark-shade-dark dark:text-tw-main-text-dark">
            <span>{dayjs(point.data.x).format('DD/MM/YYYY HH:mm')}</span>
            <span>
              {humanizeValue({
                value: +point.data.yFormatted,
                locale: language,
                unit,
                formatOptions: {
                  maximumSignificantDigits: 4,
                },
              })}
            </span>
          </div>
        );
      }}
    />
  );
};
