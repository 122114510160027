import classNames from 'classnames';
import CheckSolidCircleIcon from '../../../../assets/icons/CheckSolidCircleIcon';
import Select, { SelectProps } from './Select';

export const SelectWithCheckMark = (props: SelectProps) => {
  return (
    <Select
      variant="white"
      size="small"
      CustomCheckComponent={({ checked }) => (
        <CheckSolidCircleIcon
          aria-checked={checked}
          className={classNames(
            'h-4 w-4 rounded-full',
            checked && 'fill-tw-main-fill-color text-white',
            !checked &&
              'fill-transparent text-transparent ring-2 ring-inset ring-[#595B5C]',
          )}
        />
      )}
      customClassNames={{
        optionsContainer: 'bg-tw-light-shade dark:bg-tw-dark-shade-dark',
        optionContent: ({ selected }) =>
          classNames(
            selected && 'bg-tw-light-shade dark:bg-tw-dark-shade-dark',
            'gap-2 font-normal',
          ),
        container: 'w-48',
      }}
      {...props}
    />
  );
};
