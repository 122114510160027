import { useQuery } from '@apollo/client';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure, Transition } from '@headlessui/react';
import { useMemo } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { GET_ALERTS_COUNT } from '../../../api/alerts/queries';
import { Message } from '../../../components/common/Message';
import { StatusBar } from '../../../components/common/StatusBar';
import {
  AlertSeverity,
  AlertType,
  GetAlertsCountQuery,
  GetAlertsCountQueryVariables,
  GetInventoryQuery,
} from '../../../gql/graphql';
import { useAppSelector } from '../../../redux/hooks';
import { formatNumber, toTitleCase } from '../../../utils/format';
import { useSession } from '../../../utils/hooks/useSession';
import { SimpleButton } from '../../Buttons/SimpleButton';
import { rangeIdToValue } from '../../CalenderRangePicker/utils';
import { getAlertCountTotals } from '../../ProductAlertCount';
import { AppliancesProps } from './Appliances';
import { ApplianceCardDetails } from './components/ApplianceCardDetails';
import { ApplianceSupportingNodes } from './components/ApplianceSupportingNodes';
import { InstalledProduct } from './components/InstalledProduct/InstalledProduct';

export const ApplianceCardDisclosurePanel = ({
  appliance,
  onClickServerDetails,
}: {
  appliance: GetInventoryQuery['appliances']['0'];
  onClickServerDetails?: AppliancesProps['onClickServerDetails'];
}) => {
  const { skip } = useSession();

  const { duration } = useAppSelector((state) => state.productFilter);
  const alertsCountQuery = useQuery<
    GetAlertsCountQuery,
    GetAlertsCountQueryVariables
  >(GET_ALERTS_COUNT, {
    variables: {
      from: rangeIdToValue(duration)?.from?.toISOString(),
      appliance: appliance.applianceId,
      type: [AlertType.Health],
    },
    skip: skip || !appliance.applianceId || !open,
  });

  const healthAlertCount = useMemo(
    () => getAlertCountTotals(alertsCountQuery.data),
    [alertsCountQuery.data],
  );
  const formattedMostSevereAlertsCount = useMemo(
    () => formatNumber(healthAlertCount.mostSevereCount || 0),
    [healthAlertCount.mostSevereCount],
  );

  const navigate = useNavigate();

  return (
    <Disclosure.Panel className="flex flex-col">
      <ApplianceCardDetails appliance={appliance} />
      <div className="flex flex-col gap-4 rounded-b-lg border-x border-b border-tw-border px-4 py-3 dark:border-tw-border-dark">
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          show={!!healthAlertCount.total}
        >
          <StatusBar
            hideIcon
            type={
              healthAlertCount.mostSeverity === AlertSeverity.Critical
                ? 'critical'
                : 'warning'
            }
          >
            <div className="flex w-full items-center font-graphikMedium">
              <div className="flex items-center gap-4 text-base text-tw-label-primary dark:text-tw-label-primary-dark">
                <FontAwesomeIcon icon={faCircleInfo} className="h-5 w-5" />
                <div>
                  <data
                    data-testid={`health-alert-count`}
                    value={healthAlertCount.mostSevereCount}
                  >
                    {formattedMostSevereAlertsCount}
                  </data>{' '}
                  <span data-testid="health-alert-severity">
                    {toTitleCase(healthAlertCount.mostSeverity)}
                  </span>{' '}
                  Health Alerts Detected
                </div>
              </div>
              <SimpleButton
                data-testid="see-alerts-button"
                onClick={() =>
                  navigate({
                    pathname: '/app/dashboard/alerts',
                    search: createSearchParams({
                      appliances: appliance.applianceId,
                      duration,
                      alertType: [AlertType.Health],
                    }).toString(),
                  })
                }
                variant="critical-ghost-ring"
                className="m-0 ml-auto bg-tw-surface-soft px-4 dark:bg-tw-surface-soft-dark"
              >
                See Alerts
              </SimpleButton>
            </div>
          </StatusBar>
        </Transition>

        <h6 className="mt-2 font-graphikMedium text-sm">Capabilities</h6>
        {appliance?.installedProducts.length ? (
          <div
            className="grid grid-cols-3 gap-4 rounded-b-lg"
            data-testid="appliance-card-products"
          >
            {appliance?.installedProducts.map((product) => (
              <InstalledProduct
                key={product.id}
                product={product}
                applianceId={appliance.applianceId}
              />
            ))}
          </div>
        ) : (
          <Message type="info">
            No installed products found for current appliance
          </Message>
        )}
        <ApplianceSupportingNodes
          servers={appliance.servers}
          onClickServerDetails={onClickServerDetails}
        />
      </div>
    </Disclosure.Panel>
  );
};
