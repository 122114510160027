import { JSONSchema7 } from 'json-schema';
import { DynamicFormOnChangeEvent } from './DynamicForm';
import DynamicFormWithNav from './DynamicFormWithNav';
import {
  configSchemaToFormFields,
  formFieldsToConfigValues,
  pathAndValueToJSON,
} from './jsonSchemaUtils';
import { FormFieldValue, FormFields, JSONObject } from './types';
import { useValidation } from './useValidation';
export interface DynamicFormSchemaOnChangeEvent
  extends DynamicFormOnChangeEvent {
  valueJSON: JSONObject;
}

export interface DynamicFormSchemaProps {
  configSchema: JSONSchema7;
  config: JSONObject;
  onSubmit?: (values: JSONObject) => Promise<{
    saved: boolean;
  }>;
  onChange?: (data: DynamicFormSchemaOnChangeEvent) => void;
  accordion?: boolean;
  search?: boolean;
}

export const DynamicFormSchema = ({
  configSchema,
  config,
  onSubmit,
  onChange,
  accordion,
  search,
}: DynamicFormSchemaProps) => {
  const { formFieldsValidation } = useValidation(configSchema, config);

  const handleChanges = (data: {
    id: string;
    value: FormFieldValue;
    data: FormFields;
    pathArray?: string[];
  }) => {
    onChange &&
      onChange({
        ...data,
        valueJSON: pathAndValueToJSON(data.pathArray || [], data.value),
      });
  };

  return (
    <div
      className="flex h-full items-center justify-between"
      data-testid="dynamic-form-schema"
    >
      <DynamicFormWithNav
        formFields={configSchemaToFormFields(configSchema, config)}
        customValidation={formFieldsValidation}
        liveValidate
        onSubmit={
          onSubmit && ((val) => onSubmit(formFieldsToConfigValues(val)))
        }
        hasActions
        onChange={handleChanges}
        accordion={accordion}
        search={search}
      />
    </div>
  );
};
