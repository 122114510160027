import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Icon, IconName } from '../../assets/icons/Icon';
import { SimpleButton } from '../../components/Buttons/SimpleButton';
import Spinner from '../../components/Elements/Spinner/Spinner';
import { useLogOut } from '../../components/Elements/User';
import { GetApplianceQuery } from '../../gql/graphql';
import { useAppSelector } from '../../redux/hooks';
import { Capabilities } from '../loginPage/components/ExploreApps';

export const ApplianceConfirmationForm = ({
  appliance,
  onSubmit,
  error,
  loading,
  submitted,
}: {
  appliance: GetApplianceQuery['appliance'];
  onSubmit: () => void;
  error?: string;
  loading?: boolean;
  submitted?: boolean;
}) => {
  const { available } = useAppSelector((state) => state.products.products);
  const filteredApps = useMemo(() => {
    return available.filter((app) => {
      return appliance?.installedProducts?.some(
        (installedProduct) => installedProduct.product.kind === app.kind,
      );
    });
  }, [available, appliance]);

  const { logOutButton } = useLogOut({
    navigateAfterLogout: false,
  });

  return (
    <div className="flex flex-col items-center justify-center">
      <div
        className={`w-auto max-w-[40rem] rounded-2xl bg-tw-dark-shade p-6 font-graphikRegular text-tw-main-text dark:bg-tw-light-shade-dark dark:text-tw-dark-shade`}
      >
        <h1 className="text-2xl font-bold">Appliance Confirmation</h1>
        <div className="flex flex-col gap-4 py-4">
          <div className="text-xs">
            <span className="text-tw-description-text dark:text-tw-description-text-dark">
              Id:{' '}
            </span>
            {appliance.id}
          </div>
          <div className="text-sm font-normal text-tw-description-text">
            {appliance?.description}
          </div>
          <div className="">
            <h5>Product list</h5>
            <Capabilities
              filteredProducts={filteredApps}
              customClassNames={{
                container: 'mt-2 gap-2',
                app: 'bg-tw-light-shade dark:bg-tw-light-shade/5 w-auto',
              }}
              showTryButton={false}
              showWhy={false}
            />
          </div>
        </div>

        {submitted ? (
          <div className="flex flex-row items-center justify-start gap-1 text-center">
            <Icon
              iconName={IconName.CheckCircleIcon}
              className="h-10 w-10 text-green-500"
            />
            Appliance confirmed!{' '}
            <Link
              to="/"
              className="text-sm font-normal text-tw-main-color underline"
            >
              Go home
            </Link>
          </div>
        ) : (
          <>
            <h5 className="mb-6 mt-3 font-graphikRegular text-[1rem] leading-[1.1rem]">
              Please confirm your appliance by clicking the button below.
            </h5>
            <SimpleButton
              disabled={loading}
              onClick={onSubmit}
              className="h-14 w-full rounded-xl font-graphikRegular font-normal"
            >
              <div className="flex items-center justify-center gap-2">
                {loading && <Spinner className="h-5 w-5" />}
                Confirm
              </div>
            </SimpleButton>
          </>
        )}
        {error && (
          <div className="w-96 p-4 text-sm font-normal text-red-500">
            {error}
          </div>
        )}
      </div>
      <div className="flex w-full justify-end">{logOutButton}</div>
    </div>
  );
};
