import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { Description, Direction, FieldError, Label, PickerOptions } from '../';
import { FOCUSABLE, HOVERABLE, optionsMapper } from '../utils';
import { RadioCheck } from './RadioCheck';

export interface RadioGroupProps {
  id?: string;
  label?: string;
  options: PickerOptions;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  error?: string | string[];
  direction?: Direction;
}

export function RadioGroup({
  id,
  label,
  options: defaultOptions,
  value,
  onChange,
  disabled,
  error,
  direction = 'vertical',
  ...rest
}: RadioGroupProps) {
  const [options, setOptions] = useState(defaultOptions.map(optionsMapper));

  useEffect(() => {
    setOptions(defaultOptions.map(optionsMapper));
  }, [defaultOptions]);

  return (
    <HeadlessRadioGroup value={value} onChange={onChange}>
      <HeadlessRadioGroup.Label>{label}</HeadlessRadioGroup.Label>
      <div
        id="options-container"
        className={classNames('flex', {
          'flex-row': direction === 'horizontal',
          'flex-col': direction === 'vertical',
        })}
        {...rest}
      >
        {options.map((option) => (
          /* Use the `checked` state to conditionally style the checked option. */
          <HeadlessRadioGroup.Option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
            as={Fragment}
          >
            {({ checked }) => (
              <li
                data-testid="radio-group-option"
                data-test-value={option.value}
                data-test-checked={checked}
                className={classNames(
                  'w-fit list-none rounded-md p-1 px-3',
                  option.description && 'flex flex-col',
                  !option.disabled && HOVERABLE,
                  FOCUSABLE,
                )}
              >
                <Label
                  htmlFor={`${id}-${option.value}`}
                  placement="none"
                  disabled={option.disabled}
                >
                  <div className="flex items-center gap-2">
                    <RadioCheck disabled={option.disabled} checked={checked} />
                    <span className="pt-[2px]">{option.label}</span>
                  </div>
                </Label>
                {option.description && (
                  <Description
                    position="bottom"
                    className="ml-5"
                    disabled={option.disabled}
                  >
                    {option.description}
                  </Description>
                )}
              </li>
            )}
          </HeadlessRadioGroup.Option>
        ))}
      </div>
      <FieldError error={error} />
    </HeadlessRadioGroup>
  );
}
