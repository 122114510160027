import classNames from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { GetInventoryQuery } from '../../../../../gql/graphql';
import {
  MACHINE_READABLE_DATE_TIME_FORMAT,
  formatDate,
} from '../../../../../utils/format';

export const Expiration = ({
  product,
}: {
  product: GetInventoryQuery['appliances'][0]['installedProducts'][0];
}) => {
  const hasOnlyPerpetual = useMemo(
    () => !product.licenses.some((license) => !license.perpetual),
    [product],
  );

  const minimunExpiration = useMemo(
    () =>
      product.licenses
        .filter((license) => hasOnlyPerpetual || !license.perpetual)
        .map((p) => p.expiration)
        .sort((a, b) => a - b)?.[0],
    [product],
  );

  return (
    <>
      <span>{hasOnlyPerpetual ? 'Support Expiration' : 'Expiration'}: </span>
      {minimunExpiration ? (
        <time
          dateTime={formatDate(
            minimunExpiration,
            MACHINE_READABLE_DATE_TIME_FORMAT,
          )}
          className=""
        >
          {`${dayjs(minimunExpiration).format('MM/DD/YY')} (${dayjs(
            minimunExpiration,
          ).fromNow(true)})`}
        </time>
      ) : (
        <span
          className={classNames(
            'text-xs text-tw-label-tertiary opacity-50 dark:text-tw-label-tertiary-dark',
          )}
        >
          -
        </span>
      )}
    </>
  );
};
