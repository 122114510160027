import { isEmpty } from 'ramda';
import { useMemo } from 'react';
import { ProductKind } from '../../../api/product/types';
import { ProductCardProps } from '../../../components/DashCards/ProductCard/ProductCard';
import { GetAvailablePublicProductsQuery } from '../../../gql/graphql';
import { useProductsAndCategoriesByFilter } from '../../../utils/products';
import { ExtendedInstalledProduct } from './useProducts';

export const useProductCards = (
  installedProducts: ExtendedInstalledProduct[],
  availableProducts: GetAvailablePublicProductsQuery['products'],
): ProductCardProps[] => {
  const { products: selectedProducts } = useProductsAndCategoriesByFilter();

  const installedProductCards = useMemo(
    () =>
      installedProducts.map((product) => {
        return {
          key: product.kind,
          kind: product.kind as ProductKind,
          connected: true,
          comingSoon: false,
          name: product.name,
          inCurrentApplianceFilter: product.inCurrentApplianceFilter,
        };
      }),
    [installedProducts],
  );

  const availableProductCards = useMemo(
    () =>
      availableProducts
        .filter(
          (product) =>
            // not in installed products
            !installedProducts.find(
              (installedProduct) => installedProduct.kind === product.kind,
            ),
        )
        .sort((a, b) => (a.comingSoon ? 1 : 0) - (b.comingSoon ? 1 : 0))
        .map((product) => {
          return {
            product,
            key: product.kind,
            kind: product.kind as ProductKind,
            connected: false,
            comingSoon: product.comingSoon,
            name: product.name,
            inCurrentApplianceFilter: true,
          };
        }),
    [availableProducts, installedProducts],
  );

  const filteredProductCards = useMemo(
    () =>
      [...installedProductCards, ...availableProductCards].filter(
        (product) =>
          isEmpty(selectedProducts) || selectedProducts.includes(product.kind),
      ),
    [installedProductCards, availableProductCards, selectedProducts],
  );

  return filteredProductCards;
};
