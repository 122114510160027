import React from 'react';

export const CapacityManagementReportingIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M23.1276 10.4785L20.0554 7.51395C19.7258 7.19587 19.2791 7.01562 18.8143 7.01562H11.3414C10.3713 7.01916 9.58398 7.77902 9.58398 8.71523V23.4148C9.58398 24.351 10.3713 25.1109 11.3414 25.1109H21.8861C22.8566 25.1109 23.6465 24.351 23.6465 23.4148V11.6797C23.6465 11.2312 23.4572 10.7965 23.1276 10.4785ZM18.9572 8.85307L21.7437 11.5419H18.9572V8.85307ZM21.8887 23.4148H11.3414V8.71523H17.1997V12.3901C17.1997 12.8602 17.5916 13.238 18.0786 13.238H21.8887V23.4148ZM15.4434 16.1201C14.106 16.382 13.0996 17.5225 13.0996 18.8906C13.0996 20.452 14.4114 21.718 16.0293 21.718C17.4465 21.718 18.6283 20.7464 18.9 19.4561H15.4434V16.1201ZM16.6152 14.9892V18.3251H20.0719C20.1111 18.139 20.1309 17.9496 20.1309 17.7597C20.1309 16.1983 18.8191 14.9323 17.2012 14.9323C17.0005 14.9323 16.8046 14.9521 16.6152 14.9892Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CapacityManagementReportingIcon;
