import classNames from 'classnames';
import { PickerOption } from './';
import { Size, Variant } from './types';

export interface FormFieldsStates {
  touched?: boolean;
  size?: Size;
  variant?: Variant;
  open?: boolean;
  hasIcon?: boolean;
  disabled?: boolean;
  selected?: boolean;
  checked?: boolean;
  openable?: boolean;
  error?: string | boolean | string[];
}

export const FOCUSABLE =
  'focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white dark:focus-visible:ring-black focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-tw-main-color';
export const WITH_BORDER = 'ring-1 ring-black ring-opacity-5 dark:ring-white/5';
export const HOVERABLE =
  'transition-colors hover:bg-tw-light-hover dark:hover:bg-tw-light-hover-dark';
export const CLICKABLE = `cursor-pointer ${HOVERABLE}`;

export const BASE_BG = 'bg-tw-dark-shade dark:bg-tw-dark-shade-dark';

export const getRingColorAsText = ({
  disabled,
  checked,
  size,
}: FormFieldsStates) =>
  classNames(
    size === 'small' ? 'ring-1' : 'ring-2',
    checked
      ? 'ring-tw-main-color'
      : disabled
      ? 'ring-tw-inactive-color dark:ring-tw-inactive-color-dark'
      : 'ring-tw-light-text dark:ring-tw-light-text-dark',
    disabled && 'bg-gray-200 dark:bg-gray-800',
  );

export const getDescriptionTextColorClassNames = ({
  disabled,
}: FormFieldsStates = {}) =>
  classNames(
    disabled
      ? 'text-tw-inactive-color dark:text-tw-inactive-color-dark'
      : 'text-tw-description-text dark:text-tw-description-text-dark',
  );
export const getTextColorClassNames = ({ disabled }: FormFieldsStates = {}) =>
  classNames(
    disabled
      ? 'text-tw-inactive-color dark:text-tw-inactive-color-dark'
      : 'text-tw-light-text dark:text-tw-light-text-dark',
  );

export const getBgColorClassNames = ({
  disabled,
  variant = 'default',
  selected,
  selectedClass = 'font-bold',
}: FormFieldsStates & {
  selectedClass?: string;
} = {}) =>
  classNames(
    !selected &&
      variant === 'default' &&
      'bg-tw-light-shade dark:bg-tw-strong-shade-dark',
    !selected && variant === 'white' && 'bg-white dark:bg-tw-dark-shade-dark',
    !selected &&
      variant === 'soft' &&
      'bg-tw-surface-soft dark:bg-tw-surface-soft-dark',
    selected &&
      `bg-tw-light-hover dark:bg-tw-light-hover-dark ${selectedClass}`,
  );

export const getBaseTextAndBgClassNames = ({
  size = 'medium',
  variant = 'default',
  selected,
  disabled,
  selectedClass,
}: FormFieldsStates & {
  selectedClass?: string;
} = {}) =>
  classNames(
    'font-graphikRegular',
    getTextColorClassNames({ variant, disabled }),
    getBgColorClassNames({ variant, selected, disabled, selectedClass }),
  );

export const getBaseButtonClassName = ({
  size = 'medium',
  variant = 'default',
  selected,
  disabled,
  hasIcon,
  error,
}: FormFieldsStates = {}) =>
  classNames(
    getBaseTextAndBgClassNames({ variant, disabled, selected }),
    FOCUSABLE,
    error ? 'ring-2 ring-red-500' : WITH_BORDER,
    CLICKABLE,
    'relative w-full text-left',
    size === 'large'
      ? 'rounded-xl text-base py-3.5'
      : 'rounded-md text-sm py-3',
    hasIcon ? 'pl-10' : size === 'large' ? 'pl-5' : 'pl-4',
  );
export const getMenuButtonClassName = ({
  size = 'medium',
  variant = 'default',
  selected,
  disabled,
  hasIcon,
  openable,
}: FormFieldsStates = {}) =>
  classNames(
    getTextColorClassNames({
      variant,
      disabled,
      selected,
    }),
    selected && `bg-tw-light-hover dark:bg-tw-strong-shade-dark`,
    FOCUSABLE,
    CLICKABLE,
    'relative w-full text-left',
    size === 'large' ? 'rounded-md text-base py-2' : 'rounded-sm text-sm py-1',
    hasIcon ? 'pl-8' : size === 'large' ? 'pl-2.5' : 'pl-2',
    // padding right for the chevron
    openable && 'pr-8',
  );

export const getPickerButtonClassName = ({
  size = 'medium',
  hasIcon,
  open,
  variant = 'default',
  disabled,
  error,
}: FormFieldsStates = {}) =>
  classNames(
    getBaseButtonClassName({
      size,
      variant,
      disabled,
      error,
    }),
    'pr-10',
    hasIcon && 'flex items-center',
    open && 'rounded-b-none font-semibold',
  );

export const optionsMapper = (option: PickerOption | string | number) => {
  if (typeof option === 'string' || typeof option === 'number') {
    return {
      label: option,
      value: option,
    };
  }
  return option;
};

export const getInputFieldClassName = ({
  size = 'medium',
  variant = 'default',
  disabled,
  error,
}: FormFieldsStates = {}) =>
  classNames(
    size === 'small' && 'rounded-md text-sm p-2 px-3',
    size === 'medium' && 'rounded-lg text-md p-2.5 px-4',
    size === 'large' && 'rounded-xl text-lg p-4 px-5',
    'w-full',
    getBaseTextAndBgClassNames({ variant, disabled }),
    FOCUSABLE,
    HOVERABLE,
    error
      ? '!ring-2 dark:ring-tw-danger-strong-dark ring-tw-danger-strong'
      : WITH_BORDER,
  );
