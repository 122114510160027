import { GetManagedDevicesQuery } from '@/gql/graphql';
import { Disclosure, Transition } from '@headlessui/react';
import classNames from 'classnames';
import {
  ReactEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { MAIN_TOOLTIP_ID } from '../../../App';
import { ProductKind } from '../../../api/product/types';
import { Icon, IconName } from '../../../assets/icons/Icon';
import { SimpleButton } from '../../../components/Buttons/SimpleButton';
import { useAppSelector } from '../../../redux/hooks';
import { toTitleCase } from '../../../utils/format';
import { useSession } from '../../../utils/hooks/useSession';
import { ProductIcon } from '../../Elements/Icons/ProductIcons/ProductIcon';
import { convertSize } from '../functions';

export const getCoverageColor = (score: number) => {
  if (score < 0.7) return '#2961EB';
  if (score < 0.9) return '#FFB84D';
  if (score >= 0.9) return '#F54E55';
  return '#2961EB';
};

const CapacityBar = ({ score }: { score: number }) => (
  <div
    data-testid="score-bar"
    className="flex h-[7px] w-full rounded-lg border border-white bg-tw-hover-alternative dark:border-black dark:bg-tw-strong-shade-dark"
  >
    <div
      role="progressbar"
      className="items-center rounded-lg transition-all duration-300 ease-in-out"
      style={{
        width: `calc(${score} * 100%)`,
        background: getCoverageColor(score),
      }}
    />
  </div>
);
interface IProps {
  open: boolean;
  device: GetManagedDevicesQuery['managedDevices']['0'] | undefined;
  onSettingsClick: (
    args: GetManagedDevicesQuery['managedDevices']['0'] | undefined,
  ) => void;
}

const ManagedCards = ({ open, device, onSettingsClick }: IProps) => {
  const getScore = () =>
    Number.parseFloat(device?.diskUsage) /
    Number.parseFloat(device?.diskCapacity);

  const { isAdmin, isSuperAdmin } = useSession();
  const { t } = useTranslation('productInfo');

  const products = useMemo(
    () => device?.licenseLinks.map((p) => p.license.installedProduct.product),
    [device],
  );

  const { diskUsage, diskCapacity } = device || {};

  const REACT_APP_CDN_BASE_URL = window.env
    ? window.env.REACT_APP_CDN_BASE_URL
    : '';

  const theme = useAppSelector((state) => state.theme.value);

  const [hasImgError, setHasImgError] = useState(false);
  const handleOnError = useCallback<ReactEventHandler<HTMLImageElement>>(
    ({ currentTarget }) => {
      setHasImgError(true);
      currentTarget.onerror = null; // prevents looping
      if (!hasImgError)
        currentTarget.src = `${REACT_APP_CDN_BASE_URL}/images/device-icons/${theme}/default.svg`;
    },
    [theme, hasImgError],
  );
  useEffect(() => setHasImgError(false), [theme]);

  return (
    <>
      <Disclosure.Button
        data-testid="device-details-button"
        className={'w-full'}
      >
        <div
          data-testid="managed-device-card"
          data-test-state={open ? 'open' : 'closed'}
          className={classNames(
            'flex w-full flex-row items-center justify-between gap-4 border-tw-border bg-tw-background py-2 pl-3 pr-4',
            'border-md dark:border-tw-border-dark dark:bg-tw-background-dark',
            open ? 'rounded-t-lg border-x border-t' : 'rounded-lg border',
            'hover:bg-tw-light-hover hover:dark:bg-tw-light-hover-dark',
          )}
        >
          <div className="flex grow flex-row items-center justify-between gap-4">
            <div className="flex flex-row items-center gap-4">
              <img
                src={`${REACT_APP_CDN_BASE_URL}/images/device-icons/${theme}/${device?.platform?.toLowerCase()}.svg`}
                onError={handleOnError}
                alt={(device?.platform || 'platform').toLowerCase()}
                title={(device?.platform || 'platform').toLowerCase()}
                className="h-8 w-8 rounded-md bg-tw-hover-strong p-0.5 dark:bg-tw-surface-strong-dark
              "
              />
              <div className="flex flex-col items-start">
                <h6
                  data-testid="device-name"
                  className="font-graphikMedium text-tw-label-primary dark:text-tw-label-primary-dark"
                  style={{ fontSize: '12px' }}
                >
                  {device?.name || device?.guid || 'Name'}
                </h6>
                <p
                  className="font-graphikRegular text-tw-label-tertiary dark:text-tw-label-tertiary-dark"
                  style={{ fontSize: '10px' }}
                >
                  {toTitleCase(device?.platform || '-')}
                </p>
              </div>
            </div>
            <div
              data-testid="device-products"
              className="flex flex-row flex-wrap gap-2"
            >
              {products?.map((product) => {
                return (
                  <div
                    key={product.kind}
                    data-testid="device-product"
                    data-test-value={product.kind}
                  >
                    <ProductIcon
                      data-tooltip-id={MAIN_TOOLTIP_ID}
                      data-tooltip-content={t(
                        `${product.kind}.name`,
                        product.kind,
                      )}
                      productKind={product.kind as ProductKind}
                      className="h-6 w-6 rounded-md border-2 border-[#EEF4FF] bg-white fill-white text-[#013FFF]"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div
            data-testid="device-disk-usage-container"
            className="flex w-96 flex-col gap-1"
          >
            <p
              className="text-left font-graphikRegular text-tw-label-primary dark:text-tw-label-primary-dark"
              style={{ fontSize: '12px' }}
            >
              {(diskUsage || diskCapacity) && (
                <>
                  <span
                    data-testid="device-disk-usage"
                    data-test-value={diskUsage}
                  >
                    {convertSize(diskUsage)}
                  </span>{' '}
                  /{' '}
                  <span
                    data-testid="device-disk-capacity"
                    data-test-value={diskCapacity}
                  >
                    {convertSize(diskCapacity)}
                  </span>{' '}
                </>
              )}
              <span className="text-tw-label-tertiary dark:text-tw-label-tertiary-dark">
                Disk Usage
              </span>
            </p>
            <CapacityBar score={getScore()} />
          </div>
          <div className="flex flex-row items-center gap-3">
            {(isAdmin || isSuperAdmin) && (
              <SimpleButton
                data-testid="device-edit-button"
                variant="default-ghost"
                onClick={(e) => {
                  e.stopPropagation();
                  onSettingsClick(device);
                }}
              >
                <Icon iconName={IconName.SettingsIcon} height="14" width="14" />
              </SimpleButton>
            )}
            <Icon
              iconName={IconName.ChevronDownIcon}
              className={`transition-all duration-300 ${
                !open ? '' : 'rotate-180'
              }`}
            />
          </div>
        </div>
      </Disclosure.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Disclosure.Panel
          data-testid="device-detail"
          className="grid grid-cols-4 rounded-b-lg border-x border-b border-tw-border bg-tw-surface-strong px-4 py-3 dark:border-tw-border-dark dark:bg-tw-surface-strong-dark"
        >
          {/* <InfoCard
            data-testid="info-licenses"
            title="Licenses"
            content={device?.productLinks
              .map((prod) => prod.installedProduct.product.name)
              .join(', ')}
          /> */}
          <InfoCard
            data-testid="info-id"
            title="ID"
            content={`${device?.guid}`}
          />
          <InfoCard
            data-testid="info-ip-address"
            title="IP Address"
            content={device?.ipv4 || '-'}
          />
          <InfoCard
            data-testid="info-location"
            title="Location"
            content={[device?.location?.city, device?.location?.country]
              .filter(Boolean)
              .join(', ')}
          />
        </Disclosure.Panel>
      </Transition>
    </>
  );
};

const InfoCard = ({
  title,
  content,
  ...rest
}: {
  title: string;
  content: string | undefined;
}) => (
  <div className="flex flex-col gap-1" {...rest}>
    <h6
      className="font-graphikRegular text-tw-label-tertiary opacity-50 dark:text-tw-label-tertiary-dark"
      style={{ fontSize: '10px' }}
    >
      {title}
    </h6>
    <p
      className="font-graphikMedium text-tw-label-primary dark:text-tw-label-primary-dark"
      style={{ fontSize: '12px' }}
    >
      {content}
    </p>
  </div>
);

export default ManagedCards;
