import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { getSupportedLang } from '../lang/lang';
import { useAwsCdn } from './useAwsCdn';

const REACT_APP_CDN_BASE_URL = window.env
  ? window.env.REACT_APP_CDN_BASE_URL
  : '';
/**
 *
 * This hook is used to fetch the translation files from CDN and add them to the i18next instance.
 *
 * @param {string} ns - namespace
 * @param {string} path - path to the file
 * @returns {object} resources
 *
 */
export const useCDNTranslation = <T>({
  ns,
  path,
}: {
  ns: string;
  path: string;
}): T | null => {
  const { i18n } = useTranslation();
  const language = i18n.language;

  const langCode = getSupportedLang(language);

  const bucketLink = `${REACT_APP_CDN_BASE_URL}/${langCode}/${path}`;

  const resources = useAwsCdn<T>(bucketLink);
  resources && i18next.addResourceBundle(language, ns, resources);

  return resources;
};
