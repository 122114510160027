import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Icon, IconName } from '../../assets/icons/Icon';
import { DEFAULT_TITLE, TITLE_JOIN } from '../../routes';
import { TrialModalContent } from '../Elements/Modal/TrialModalContent';
import { Head } from '../Head';
import { ModalTrigger } from '../common/Modal';
import { LayoutAreaId } from './types';

type LearnMoreLayoutProps = {
  children: React.ReactNode;
  product: string;
};

export const LearnMoreLayout = ({
  children,
  product,
}: LearnMoreLayoutProps) => {
  useEffect(() => {
    document.title = [DEFAULT_TITLE, `Learn more about ${product}`].join(
      TITLE_JOIN,
    );
  }, []);

  return (
    <div
      id={LayoutAreaId.CONTAINER}
      className="flex h-screen w-full flex-col overflow-hidden"
    >
      <Head id={LayoutAreaId.HEAD} />
      <div
        id={LayoutAreaId.BODY}
        className="explorer-scroll flex h-full w-full overflow-auto bg-white dark:bg-[#1A1C1E]"
      >
        <div
          data-testid="LearnMoreLayout"
          id={LayoutAreaId.CONTENT}
          className="m-auto mb-4 flex bg-white p-4 pt-0 text-black dark:bg-[#1A1C1E] dark:text-white"
        >
          <div className="flex w-full justify-center px-3 lg:px-0">
            <div className="flex flex-col content-center gap-2">
              <div className="my-2 flex h-[121px] max-w-[95vw] items-center justify-between">
                <Link
                  to={'/'}
                  className="flex flex-row items-center gap-2 font-graphikMedium"
                  style={{ fontSize: '28px', lineHeight: '30px' }}
                >
                  <Icon iconName={IconName.ArrowLeftIcon} />
                  Back
                </Link>
                <div className="block lg:hidden">
                  <ModalTrigger
                    modalContent={(close) => (
                      <TrialModalContent product={product} onSubmit={close} />
                    )}
                    modalTitle={`Request a free trial of ${product}`}
                    className="h-[50px] w-[205px] rounded-lg bg-blue-600 font-graphikSemi text-white hover:bg-blue-400"
                    style={{ fontSize: '16px', lineHeight: '17px' }}
                  >
                    Request Free Trial
                  </ModalTrigger>
                </div>
              </div>
              <div className="container flex flex-col gap-10">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
