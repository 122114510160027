import { ActionButton, Content, Divider, Footer } from '@adobe/react-spectrum';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { GET_ALERTS } from '../../../api/alerts/queries';
import ChevronRightIcon from '../../../assets/icons/ChevronRightIcon';
import SettingsIcon from '../../../assets/icons/DBSizeNavIcons/SettingsIcon';
import {
  Alert,
  AlertSeverity,
  GetAlertsQuery,
  GetAlertsQueryVariables,
} from '../../../gql/graphql';
import { useAppSelector } from '../../../redux/hooks';
import { formatNumber } from '../../../utils/format';
import { useSession } from '../../../utils/hooks/useSession';
import Spinner from '../Spinner/Spinner';
import { NotificationsTypeBlock } from './NotificationsTypeBlock';

const FIRST = 5;
export const from = dayjs('2020-01-01').startOf('day').toDate();

export interface INotificationWidgetContentProps {
  count: number;
  settingsUrl?: string;
  onViewAllClick?: () => void;
}

export const NotificationWidgetContent = ({
  count,
  settingsUrl,
  onViewAllClick,
}: INotificationWidgetContentProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('notifications');
  const theme = useAppSelector((state) => state.theme.value);

  const variables: GetAlertsQueryVariables = useMemo(
    () => ({
      filter: {
        severity: [AlertSeverity.Critical],
        from,
        active: true,
      },
      first: FIRST,
    }),
    [],
  );

  const { skip } = useSession();
  const { data, error, loading } = useQuery<
    GetAlertsQuery,
    GetAlertsQueryVariables
  >(GET_ALERTS, {
    variables,
    pollInterval: 10000,
    skip,
  });

  const variablesError: GetAlertsQueryVariables = useMemo(
    () => ({
      filter: {
        severity: [AlertSeverity.Error],
        from,
        active: true,
      },
      first: FIRST,
    }),
    [],
  );

  const {
    data: dataError,
    error: errorError,
    loading: loadingError,
  } = useQuery<GetAlertsQuery, GetAlertsQueryVariables>(GET_ALERTS, {
    variables: variablesError,
    pollInterval: 10000,
    skip,
  });

  const variablesStandard: GetAlertsQueryVariables = useMemo(
    () => ({
      filter: {
        severity: [AlertSeverity.Standard],
        from,
        active: true,
      },
      first: FIRST,
    }),
    [],
  );

  const {
    data: dataStandard,
    error: errorStandard,
    loading: loadingStandards,
  } = useQuery<GetAlertsQuery, GetAlertsQueryVariables>(GET_ALERTS, {
    variables: variablesStandard,
    pollInterval: 10000,
    skip,
  });

  const critical = data?.alerts?.nodes || [];
  const errorNotifications = dataError?.alerts?.nodes || [];
  const standardNotifications = dataStandard?.alerts?.nodes || [];

  const errorMessage = useMemo(
    () => error?.message || errorStandard?.message || errorError?.message,
    [error, errorStandard, errorError],
  );

  const location = useLocation();

  const totalCount = useMemo(
    () =>
      Number(data?.alerts?.totalItems) +
        Number(dataError?.alerts?.totalItems) +
        Number(dataStandard?.alerts?.totalItems) || count,
    [count, data, dataError, dataStandard],
  );
  const totalCountFormated = useMemo(
    () => formatNumber(totalCount || 0),
    [totalCount],
  );

  return (
    <div
      data-testid="notification-widget-content"
      className={classNames(
        theme,
        'flex max-h-min w-[26.75rem] flex-col overflow-hidden rounded-sm',
        'bg-tw-dark-shade dark:bg-tw-light-shade-dark',
      )}
    >
      <div className="flex flex-row items-center gap-2 pt-3">
        <h3 className="grow bg-tw-dark-shade p-5 font-graphikSemi text-[24px] leading-[26px] text-tw-main-text dark:bg-tw-light-shade-dark dark:text-tw-main-text-dark">
          {`Alerts (${totalCountFormated})`}
        </h3>
        <div className="p-5">
          {(loading || loadingStandards || loadingError) && (
            <Spinner
              data-testid="notification-widget-content-spinner"
              className="mr-2 h-5 w-5"
            />
          )}

          {settingsUrl && (
            <ActionButton
              data-testid="notification-widget-content-settings"
              aria-label={t('notifications.settings')}
              isQuiet
            >
              <SettingsIcon onClick={() => navigate(settingsUrl)} />
            </ActionButton>
          )}
        </div>
      </div>
      <Divider size="S" />
      <Content UNSAFE_className="overflow-y-auto max-h-min">
        <div className="divide-black-200 dark:gray-300 divide-y dark:divide-gray-700">
          {errorMessage && <p>{errorMessage}</p>}
          {Boolean(data) && (
            <>
              <NotificationsTypeBlock
                notifications={critical as Alert[]}
                total={data?.alerts?.totalItems || undefined}
                title={t('notifications.severity.critical')}
                severity={AlertSeverity.Critical}
              />
              <NotificationsTypeBlock
                notifications={errorNotifications as Alert[]}
                total={dataError?.alerts?.totalItems || undefined}
                title={t('notifications.severity.error')}
                severity={AlertSeverity.Error}
              />
              <NotificationsTypeBlock
                notifications={standardNotifications as Alert[]}
                total={dataStandard?.alerts?.totalItems || undefined}
                title={t('notifications.severity.standard')}
                severity={[AlertSeverity.Standard]}
              />
            </>
          )}
        </div>
      </Content>
      <Divider size="S" />
      <Footer>
        {/* hide if we are currently on alerts page */}
        {location.pathname !== '/app/dashboard/alerts' && (
          <button
            onClick={() => {
              onViewAllClick && onViewAllClick();
              navigate('/app/dashboard/alerts');
            }}
            className="flex w-full items-center bg-tw-dark-shade p-5 pb-6 text-base font-normal text-tw-main-color dark:bg-tw-light-shade-dark dark:text-tw-main-color"
          >
            {t('notifications.viewAll')}
            <ChevronRightIcon className="ml-auto" />
          </button>
        )}
      </Footer>
    </div>
  );
};
