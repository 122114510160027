import React from 'react';

export const HalfRectangleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="7"
      height="34"
      viewBox="0 0 7 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        d="M0 0H1.35923C4.47454 0 7 2.52546 7 5.64077V28.3592C7 31.4745 4.47454 34 1.35923 34H0V0Z"
        fill="#013FFF"
      />
    </svg>
  );
};

export default HalfRectangleIcon;
