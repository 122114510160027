export const EyeSlashIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="fill-current text-teal-500"
    width="1em"
    height="1em"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <path
      d="M4.70937 2.84741C6.09374 1.78854 7.86874 0.982259 9.99999 0.982259C12.525 0.982259 14.5469 2.11295 16.0187 3.45603C17.4812 4.78806 18.4594 6.35642 18.9219 7.47975C19.025 7.72221 19.025 7.9923 18.9219 8.23477C18.5031 9.22612 17.6625 10.6226 16.425 11.8687L19.7125 14.3977C20.0375 14.6494 20.0969 15.1128 19.8406 15.432C19.5844 15.7512 19.1125 15.8095 18.7875 15.5579L0.287369 1.31649C-0.0386306 1.06543 -0.0957869 0.6026 0.159744 0.28236C0.415307 -0.0378187 0.886557 -0.0939543 1.21281 0.157014L4.70937 2.84741ZM5.93124 3.79057L7.36874 4.89548C8.07187 4.29392 8.99374 3.92869 9.99999 3.92869C12.2094 3.92869 14 5.68734 14 7.85726C14 8.50793 13.8406 9.1187 13.5562 9.65888L15.2375 10.9541C16.2844 9.90748 17.0594 8.68901 17.4562 7.85726C17.0031 6.90888 16.2 5.63209 14.9969 4.53332C13.7125 3.36396 12.0375 2.42785 9.99999 2.42785C8.42187 2.42785 7.03437 2.99964 5.93124 3.79057ZM12.3406 8.72277C12.4437 8.45268 12.5 8.16111 12.5 7.82964C12.5 6.50067 11.3812 5.37428 9.99999 5.37428C9.97812 5.37428 9.95937 5.4019 9.90937 5.4019C9.97812 5.55843 9.99999 5.72417 9.99999 5.86535C9.99999 6.20603 9.92499 6.50067 9.79374 6.76156L12.3406 8.72277ZM12.6344 12.7219L13.9437 13.7348C12.8094 14.3363 11.4937 14.7323 9.99999 14.7323C7.47499 14.7323 5.45312 13.6028 3.98124 12.2585C2.51937 10.8988 1.54187 9.33047 1.07687 8.23477C0.974369 7.9923 0.974369 7.72221 1.07687 7.47975C1.37499 6.7769 1.88406 5.86842 2.59656 4.95687L3.77499 5.86842C3.19062 6.58354 2.80499 7.29253 2.54531 7.82964C2.96937 8.77802 3.79999 10.0824 5.00312 11.1812C6.28749 12.3506 7.96249 13.259 9.99999 13.259C10.9594 13.259 11.8375 13.0565 12.6344 12.7219ZM5.99999 7.82964C5.99999 7.76825 6.00312 7.68232 6.00937 7.59638L7.76249 8.95296C8.09062 9.60056 8.70312 10.0855 9.43749 10.2236L11.1937 11.6078C10.8156 11.6968 10.4156 11.7858 9.97187 11.7858C7.79062 11.7858 5.97187 10.0272 5.97187 7.82964H5.99999Z"
      fill="currentColor"
    />
  </svg>
);
