import classNames from 'classnames';
import { TextareaHTMLAttributes } from 'react';
import { FieldError, Label } from '../Common';
import { Variant } from '../types';
import { getInputFieldClassName } from '../utils';

export interface TextAreaProps
  extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'required'> {
  placeholder?: string;
  label?: string;
  variant?: Variant;
  error?: string | string[];
  required?: boolean | string;
}

export const TextArea = ({
  label,
  variant = 'default',
  className,
  error,
  required,
  ...props
}: TextAreaProps) => {
  return (
    <>
      {label && <Label htmlFor={props.id}>{label}</Label>}
      <textarea
        required={Boolean(required)}
        data-testid="InputField"
        className={classNames(
          getInputFieldClassName({
            disabled: props.disabled,
            variant,
            error,
          }),
          className,
        )}
        rows={4}
        {...props}
      />
      {error && <FieldError error={error} />}
    </>
  );
};
