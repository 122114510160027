import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { SingleFormLayout } from '../../components/Layout/SingleFormLayout';
import { DEFAULT_TITLE, TITLE_JOIN } from '../../routes';
import { AuthLoader } from '../../routes/protected';
import { useSession } from '../../utils/hooks/useSession';
import { ExploreApps } from './components/ExploreApps';
import { LoginForm } from './components/LoginForm';

const APP_MAIN_PAGE = 'app/dashboard';

export const LoginPage = () => {
  const { auth, flowFulfilled } = useSession();
  const [isAppSuiteHidden, setIsAppSuiteHidden] = useState(false);

  useEffect(() => {
    document.title = [DEFAULT_TITLE, `Sign In`].join(TITLE_JOIN);
  }, []);

  return auth ? (
    <Navigate to={APP_MAIN_PAGE} />
  ) : (
    <>
      <SingleFormLayout
        data-testid="LoginPage"
        className={classNames(
          'flex',
          'flex-col lg:h-screen',
          isAppSuiteHidden ? 'h-screen' : 'h-full',
          !flowFulfilled && 'hidden',
        )}
      >
        <div className="flex h-full flex-shrink flex-col gap-6 lg:flex-row">
          <div className="flex h-full flex-shrink flex-col items-center justify-start gap-4">
            <LoginForm />
            <div
              onClick={() => setIsAppSuiteHidden(!isAppSuiteHidden)}
              className="mt-4 cursor-pointer font-graphikMedium text-tw-main-text/50 dark:text-tw-main-text-dark/50"
              style={{ fontSize: '17px', lineHeight: '19px' }}
            >
              {isAppSuiteHidden ? 'Show' : 'Hide'} Capabilities
            </div>
          </div>
          {!isAppSuiteHidden && (
            <>
              <div className="divider items- flex flex-col justify-center border-r border-[#C5C5C5] dark:border-[#25282E]" />
              <div className="flex h-full flex-shrink flex-col overflow-auto">
                <ExploreApps />
              </div>
            </>
          )}
        </div>
      </SingleFormLayout>
      {!flowFulfilled && <AuthLoader />}
    </>
  );
};
