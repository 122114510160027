import { Flex, View } from '@adobe/react-spectrum';
import SupernaLogo from '../../../assets/icons/SupernaLogo';
import { Link } from 'react-router-dom';

export const SupernaHeader = () => {
  return (
    <View>
      <Flex justifyContent="center">
        <Link to="/">
          <SupernaLogo className="w-72 text-tw-main-text dark:text-tw-main-text-dark" />
        </Link>
      </Flex>
    </View>
  );
};
