import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { ProductKind } from '../../../../api/product/types';
import {
  ProductIconOnlyLogo,
  ProductIconWithBg,
  ProductIconWithRoundedBgAndBorder,
} from './ProductIcons';

export enum SeverityVariant {
  Critical = 'Critical',
  Warning = 'Warning',
  Standard = 'Standard',
}

export enum IconVariant {
  Default = 'Default',
  Rounded = 'Rounded',
  OnlyIcon = 'OnlyIcon',
}

const DEFAULT_CLASS_NAMES =
  'product-icon drop-shadow-dark-bottom-soft dark:drop-shadow-dark-bottom-50 overflow-visible';

export const getIconVariantComponent = (
  variant: `${IconVariant}` = IconVariant.Default,
) => {
  switch (variant) {
    case IconVariant.Rounded:
      return ProductIconWithRoundedBgAndBorder;
    case IconVariant.OnlyIcon:
      return ProductIconOnlyLogo;
    case IconVariant.Default:
    default:
      return ProductIconWithBg;
  }
};

const getSeverityVariantClassName = (
  severityVariant?: `${SeverityVariant}`,
  variant?: `${IconVariant}`,
  disabled?: boolean,
): string | null => {
  if (disabled) {
    switch (variant) {
      case IconVariant.OnlyIcon:
        return 'fill-tw-description-text text-tw-description-text';
      case IconVariant.Rounded:
      case IconVariant.Default:
      default:
        return 'fill-white text-tw-description-text stroke-gray-400 dark:fill-tw-light-shade-dark dark:text-tw-description-text dark:stroke-tw-description-text';
    }
  }
  switch (variant) {
    case IconVariant.OnlyIcon:
      switch (severityVariant) {
        case SeverityVariant.Critical:
          return 'text-tw-critical-color';
        case SeverityVariant.Warning:
          return 'text-tw-warning-color';
        case SeverityVariant.Standard:
          return 'text-tw-main-fill-color';
        default:
          return '';
      }
    case IconVariant.Rounded:
    case IconVariant.Default:
    default:
      switch (severityVariant) {
        case SeverityVariant.Critical:
          return 'fill-tw-critical-color text-white';
        case SeverityVariant.Warning:
          return 'fill-tw-warning-color text-white';
        case SeverityVariant.Standard:
        default:
          return 'fill-tw-main-fill-color text-white';
      }
  }
};

export const getIconVariantClassName = (
  iconVariant: `${IconVariant}`,
): string => {
  switch (iconVariant) {
    case IconVariant.Default:
      return DEFAULT_CLASS_NAMES;
    case IconVariant.Rounded:
      return 'rounded-full';
    default:
      return '';
  }
};

export interface ProductIconProps extends React.SVGProps<SVGSVGElement> {
  productKind: `${ProductKind}` | 'DASHBOARD';
  severityVariant?: `${SeverityVariant}`;
  variant?: `${IconVariant}`;
  className?: string;
  disabled?: boolean;
}

export const ProductIcon = ({
  productKind,
  className,
  severityVariant,
  variant = IconVariant.Default,
  disabled,
  ...rest
}: ProductIconProps) => {
  const componentClassNames = twMerge(
    classNames(
      getSeverityVariantClassName(severityVariant, variant, disabled),
      getIconVariantClassName(variant),
      disabled && 'drop-shadow-none',
      className,
    ),
  );
  const Component = getIconVariantComponent(variant);
  return (
    <Component
      productKind={productKind as ProductKind}
      className={componentClassNames}
      {...rest}
    />
  );
};
