import { AlertSeverity } from '../../../gql/graphql';
import { SeverityVariant } from '../Icons/ProductIcons/ProductIcon';

export const notificationTypeToIconVariant = (
  type: AlertSeverity | string,
): SeverityVariant => {
  switch (type) {
    case AlertSeverity.Critical:
      return SeverityVariant.Critical;
    case AlertSeverity.Error:
      return SeverityVariant.Warning;
    case AlertSeverity.Standard:
    default:
      return SeverityVariant.Standard;
  }
};
