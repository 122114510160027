import classNames from 'classnames';
import { default as dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductKind } from '../../../api/product/types';
import ChevronRightIcon from '../../../assets/icons/ChevronRightIcon';
import { Icon, IconName } from '../../../assets/icons/Icon';
import { AlertType } from '../../../gql/graphql';
import { toTitleCase } from '../../../utils/format';
import {
  TextButtonSize,
  getContainerClassNames,
} from '../../Buttons/TextButton';
import { ConfirmationModalTrigger, ModalTrigger } from '../../common/Modal';
import { ProductIcon } from '../Icons/ProductIcons/ProductIcon';
import { notificationTypeToIconVariant } from '../Notification/utils';
import Spinner from '../Spinner/Spinner';
import { AlertGroupedByCode } from './AlertList';
import SeverityBadge from './SeverityBadge';
import { useSwipeToDismiss } from './useSwipeToDismiss';

dayjs.extend(relativeTime);

export interface AlertCode {
  description: string;
  help: string;
}

export interface IAlertProps {
  onClear?: (ids: string[]) => Promise<boolean>;
  active?: boolean;
  onClick?: (notification: AlertGroupedByCode) => void;
  data: AlertGroupedByCode;
}
export const AlertGroup = ({
  data,
  active,
  onClick,
  onClear,
  ...rest
}: IAlertProps) => {
  const { t } = useTranslation(['notifications', 'alertCodes', 'productInfo']);

  const { code, product, severity, createdAt, message, appliance, type } = data;

  const [helpModal, setHelpModal] = useState(<></>);

  const onModalTriggerClickHandler = useCallback(
    ({ code, title }: { code: string; title: string }) => {
      setHelpModal(
        <div
          data-testid="alert-code-help-modal"
          data-test-code={code}
          className="flex max-w-lg flex-col gap-5"
        >
          <div
            data-testid="alert-code-help-modal-title"
            className="font-graphikSemibold justify-start text-left text-base font-semibold text-tw-main-text dark:text-tw-main-text-dark"
          >
            {t(`${code}.description`, title, { ns: 'alertCodes' })}
          </div>
          <div
            data-testid="alert-code-help-modal-description"
            className="justify-start text-left font-graphikRegular text-sm font-normal text-tw-description-text"
          >
            {t(`${code}.help`, { ns: 'alertCodes' })}
          </div>
        </div>,
      );
    },
    [],
  );

  const [clearing, setClearing] = useState(false);

  const clearAlert = async () => {
    const ids = data.alerts.map((alert) => alert.id);
    setClearing(true);
    if (onClear) {
      return onClear(ids)
        .then((response) => {
          setIsCleared(response);
          return true;
        })
        .catch(() => false)
        .finally(() => {
          setClearing(false);
        });
    }
    return true;
  };

  const ref = useRef<HTMLDivElement | null>(null);
  useSwipeToDismiss(ref, clearAlert);

  const [isCleared, setIsCleared] = useState(false);

  const daysFromNow = useMemo(() => dayjs(createdAt).fromNow(), [createdAt]);

  return (
    <div
      onClick={() => onClick && onClick(data)}
      className={`group cursor-pointer snap-start ${
        active
          ? 'bg-blue-200 dark:bg-blue-900'
          : 'bg-tw-dark-shade dark:bg-tw-light-shade-dark hover:dark:bg-[#1A1C1E]'
      } `}
      ref={!data.active ? null : ref}
      data-test-code={code}
      // rest used for data-testid
      {...rest}
    >
      <div className="flex flex-row flex-wrap content-end items-center justify-items-center gap-4 p-5">
        <ProductIcon
          productKind={product.kind as ProductKind}
          severityVariant={notificationTypeToIconVariant(severity)}
          width="40px"
          height="40px"
          disabled={!data.active || isCleared}
          data-testid="alert-kind-icon"
          aria-label={product.kind}
        />
        <div className="w-[300px] lg:grow">
          <div
            className="font-graphikSemi text-black dark:text-white"
            style={{ fontSize: '16px', lineHeight: '17px' }}
            data-testid="alert-title"
          >
            {message || t(`${code}.description`, message, { ns: 'alertCodes' })}
          </div>
          <div className="infoDesc">
            <div
              className="flex items-center font-graphikRegular text-tw-description-text dark:text-[#F6F6F6]"
              style={{ fontSize: '12px', lineHeight: '13px' }}
            >
              <span className="p-0.5 pl-0 pt-1" data-testid="alert-kind">
                {t(`${product.kind}.name`, product.kind, {
                  ns: 'productInfo',
                })}
              </span>
              <span className="p-0.5 pt-1">·</span>
              <span className="p-0.5 pt-1" data-testid="alert-appliance">
                {t(appliance.description)}
              </span>
              <span className="p-0.5 pt-1">·</span>
              {type !== AlertType.Unknown && (
                <>
                  <span className="p-0.5 pt-1" data-testid="alert-type">
                    {toTitleCase(type)}
                  </span>
                  <span className="p-0.5 pt-1">·</span>
                </>
              )}
              <ModalTrigger
                onClick={(e) => {
                  e.stopPropagation();
                  onModalTriggerClickHandler({ code, title: message });
                }}
                modalTitle={code}
                modalContent={helpModal}
                className={getContainerClassNames({
                  size: TextButtonSize.XSmall,
                  active: true,
                  className: '-ml-1.5 mt-0.5',
                })}
                data-testid="alert-code"
              >
                {t('notifications.code', { code })}
              </ModalTrigger>
              <div className="time flex flex-col justify-end gap-1 font-graphikRegular text-xs text-tw-description-text lg:hidden">
                <span data-testid="alert-time">{daysFromNow}</span>
                {data.count && data.count > 1 && (
                  <span
                    data-testid="alert-count-more"
                    data-test-value={data.count}
                    className="text-tw-text-color text-end text-[0.625rem] dark:text-tw-main-text-dark"
                  >
                    + {data.count - 1} more
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex grow flex-row items-center justify-between gap-1 lg:grow-0 lg:gap-4">
          <div className="time hidden flex-col justify-end gap-0 font-graphikRegular text-xs text-tw-description-text lg:flex">
            <span data-testid="alert-time">{daysFromNow}</span>
            {data.count && data.count > 1 && (
              <span
                data-testid="alert-count-more"
                data-test-value={data.count}
                className="text-tw-text-color text-end text-[0.625rem] dark:text-tw-main-text-dark"
              >
                + {data.count - 1} more
              </span>
            )}
          </div>
          <div className="flex w-auto justify-between lg:grid lg:w-[230px] lg:grid-cols-2">
            <div className="flex justify-center lg:col-start-1">
              <SeverityBadge severity={severity} data-testid="alert-severity" />
            </div>
            {data.active && (
              <div
                className="flex w-full justify-center lg:col-start-2"
                style={{ fontSize: '11px', lineHeight: '12px' }}
              >
                <ConfirmationModalTrigger
                  modalTitle={`Clear ${data.alerts.length} alerts`}
                  modalContent={`Are you sure you want to clear ${data.alerts.length} alerts?`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onConfirm={clearAlert}
                  disabled={clearing || isCleared}
                  data-testid="alert-clear"
                  className={classNames(
                    'flex items-center justify-center gap-1 rounded-md bg-[#EEF4FF] px-2 py-1 font-graphikMedium dark:bg-[#25282E]',
                    clearing || active
                      ? 'translate-y-0 opacity-100'
                      : '-translate-y-5 opacity-0 transition',
                    'group-hover:translate-y-0 group-hover:opacity-100',
                    'hover:ring-2 hover:ring-tw-main-color hover:ring-opacity-50',
                  )}
                >
                  <>
                    {clearing ? (
                      <Spinner className="h-[9px] w-[9px]" />
                    ) : isCleared ? (
                      'Cleared'
                    ) : (
                      <>
                        <Icon
                          iconName={IconName.CrossIcon}
                          className="h-[9px] w-[9px]"
                        />
                        Clear
                      </>
                    )}
                  </>
                </ConfirmationModalTrigger>
              </div>
            )}
          </div>
          <div className="actions flex items-center">
            <button className="rounded-full p-4 transition hover:text-tw-main-color ">
              <ChevronRightIcon
                width="10px"
                height="10px"
                className="self-auto"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AlertComponentBone = (
  props: React.HTMLAttributes<HTMLDivElement>,
) => {
  return (
    <div
      data-testid="alert-bone"
      className="flex flex-row flex-wrap content-end items-center justify-items-center gap-4 bg-tw-dark-shade p-5 dark:bg-tw-light-shade-dark"
      {...props}
    >
      <div className="h-[40px] w-[40px] animate-pulse rounded-md bg-black/10 dark:bg-tw-description-text"></div>
      <div className="flex flex-grow flex-col gap-1">
        <div className="h-[15px] w-[300px] animate-pulse rounded-md bg-black/10 dark:bg-tw-description-text"></div>
        <div className="flex flex-row gap-1">
          <div className="h-[15px] w-[100px] animate-pulse rounded-md bg-black/10 dark:bg-tw-description-text"></div>
          <div className="h-[15px] w-[100px] animate-pulse rounded-md bg-black/10 dark:bg-tw-description-text"></div>
        </div>
      </div>
      <div className="h-[20px] w-[300px] animate-pulse rounded-md bg-black/10 dark:bg-tw-description-text"></div>
      <div className="flex grow flex-row items-center justify-between gap-0 px-4 lg:grow-0 lg:gap-4">
        <div className="h-[20px] w-[20px] animate-pulse rounded-md bg-black/10 dark:bg-tw-description-text"></div>
      </div>
    </div>
  );
};

export default AlertGroup;
