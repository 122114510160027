import classNames from 'classnames';

const SingleError = ({ error }: { error: string }) => (
  <div className="text-xs text-red-500">
    *<span>{error}</span>
  </div>
);

export const FieldError = ({
  error,
  className,
  ...rest
}: {
  error?: string | string[];
  className?: string;
}) => {
  return error ? (
    <div
      data-testid="field-error"
      className={classNames(
        'flex flex-col gap-2 p-2 text-xs text-red-500',
        className,
      )}
      {...rest}
    >
      {Array.isArray(error) ? (
        error.map((e) => <SingleError key={e} error={e} />)
      ) : (
        <SingleError error={error} />
      )}
    </div>
  ) : null;
};
