import { uniq } from 'ramda';
import { ProductKind } from '../api/product/types';
import {
  Category,
  GetAvailablePublicProductsQuery,
  GetInstalledProductsQuery,
} from '../gql/graphql';
import { useAppSelector } from '../redux/hooks';

export const productsByCategory = (
  category: Category | null,
  products:
    | GetAvailablePublicProductsQuery['products']
    | GetInstalledProductsQuery['products'],
): ProductKind[] => {
  const filteredProducts = products
    .map((app) => ({
      categories: app.categories,
      kind: app.kind as ProductKind,
    }))
    .filter((app) => {
      return category === null || app.categories.includes(category);
    });
  const productKinds = filteredProducts.map(({ kind }) => kind);
  return productKinds;
};

/**
 *
 * @returns {object} Current categories, current products and current filter type ('cat' or 'prod')
 */
export const useProductsAndCategoriesByFilter = () => {
  const { products } = useAppSelector((state) => state.products);
  const { category: filterCategory, products: filterProducts } = useAppSelector(
    (state) => state.productFilter,
  );

  const filteredProducts = products?.available?.filter(
    (product) =>
      (filterCategory && product.categories.includes(filterCategory)) ||
      (filterProducts?.length &&
        filterProducts.includes(product.kind as ProductKind)),
  );

  const productKinds = filteredProducts.map(({ kind }) => kind as ProductKind);
  const categories = uniq(
    filteredProducts.flatMap(({ categories }) => categories),
  );

  return {
    type: filterCategory ? 'cat' : filterProducts?.length ? 'prod' : null,
    products: productKinds,
    categories,
  };
};

/**
 *
 * @returns {boolean} Value is in productKind enum
 */
export const isProductKind = (value: string): value is ProductKind => {
  // return value in ProductKind;
  return Object.values(ProductKind).includes(value as ProductKind);
};

/**
 *
 * @returns {boolean} Value is in string enum
 */
export const isInStringEnum = <
  T extends string,
  TEnumValue extends string,
>(enumVariable: { [key in T]: TEnumValue }) => {
  const enumValues = Object.values(enumVariable);
  return (value: string): value is TEnumValue => enumValues.includes(value);
};
