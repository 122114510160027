import { useSearchParams } from 'react-router-dom';
import { SingleFormLayout } from '../../components/Layout/SingleFormLayout';
import {
  ProtectedComponent,
  UnauthorizedMessage,
} from '../../components/common/ProtectedComponent';
import { Role } from '../../redux/login/loginSlice';
import { ApplianceConfirmationLoader } from './ApplianceConfirmationLoader';

const APPLIANCE_ID_PARAM = 'applianceId';
const CONFIRMATION_CODE_PARAM = 'confirmationCode';

export const ApplianceConfirmation = () => {
  const [searchParams] = useSearchParams();
  const applianceId = searchParams.get(APPLIANCE_ID_PARAM);
  const confirmationCode = searchParams.get(CONFIRMATION_CODE_PARAM);
  return (
    <SingleFormLayout>
      <ProtectedComponent
        message="You must be logged in as administrator to view this page."
        requiredRole={[Role.ORG_ADMIN, Role.PL_ADMIN]}
        unauthorizedMessage={
          <UnauthorizedMessage message="User must be administrator to confirm appliances." />
        }
      >
        {applianceId && confirmationCode ? (
          <ApplianceConfirmationLoader {...{ applianceId, confirmationCode }} />
        ) : (
          <div>Something went wrong</div>
        )}
      </ProtectedComponent>
    </SingleFormLayout>
  );
};
