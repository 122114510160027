import React from 'react';

export const DeleteIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.33268 13.7497C8.33268 13.9788 8.14518 14.1663 7.91602 14.1663C7.68685 14.1663 7.49935 13.9788 7.49935 13.7497V8.33301C7.49935 8.10384 7.68685 7.91634 7.91602 7.91634C8.14518 7.91634 8.33268 8.10384 8.33268 8.33301V13.7497ZM10.416 13.7497C10.416 13.9788 10.2285 14.1663 9.99935 14.1663C9.77018 14.1663 9.58268 13.9788 9.58268 13.7497V8.33301C9.58268 8.10384 9.77018 7.91634 9.99935 7.91634C10.2285 7.91634 10.416 8.10384 10.416 8.33301V13.7497ZM12.4993 13.7497C12.4993 13.9788 12.3118 14.1663 12.0827 14.1663C11.8535 14.1663 11.666 13.9788 11.666 13.7497V8.33301C11.666 8.10384 11.8535 7.91634 12.0827 7.91634C12.3118 7.91634 12.4993 8.10384 12.4993 8.33301V13.7497ZM12.4342 3.98249L13.39 5.41634H15.2077C15.554 5.41634 15.8327 5.69629 15.8327 6.04134C15.8327 6.3877 15.554 6.66634 15.2077 6.66634H14.9993V14.583C14.9993 15.734 14.0671 16.6663 12.916 16.6663H7.08268C5.93216 16.6663 4.99935 15.734 4.99935 14.583V6.66634H4.79102C4.44596 6.66634 4.16602 6.3877 4.16602 6.04134C4.16602 5.69629 4.44596 5.41634 4.79102 5.41634H6.60924L7.56445 3.98249C7.83529 3.57668 8.29102 3.33301 8.77799 3.33301H11.2207C11.7077 3.33301 12.1634 3.57671 12.4342 3.98249ZM8.11133 5.41634H11.8874L11.3926 4.67572C11.3535 4.6179 11.2884 4.58301 11.2207 4.58301H8.77799C8.71029 4.58301 8.62174 4.6179 8.60612 4.67572L8.11133 5.41634ZM6.24935 14.583C6.24935 15.0439 6.62253 15.4163 7.08268 15.4163H12.916C13.377 15.4163 13.7493 15.0439 13.7493 14.583V6.66634H6.24935V14.583Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DeleteIcon;
