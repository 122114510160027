import { ActionButton, Tooltip, TooltipTrigger } from '@adobe/react-spectrum';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import HalfRectangleIcon from '../../assets/icons/HalfRectangleIcon';
import { useAppSelector } from '../../redux/hooks';
import { DB_SIDE_NAV_GROUPS } from './DBsideNavigation/DBsideNavItems';
import { SideNavGroupProps, SideNavItemProps } from './interfaces';

type SideNavElementProps = {
  sideNavItem: SideNavItemProps;
  isSelected: boolean;
};

const SideNavElement = ({ sideNavItem, isSelected }: SideNavElementProps) => {
  const sidebarCollapsed = useAppSelector(
    (state) => state.sidebarCollapse.collapsed,
  );

  const { title, icon, content } = sideNavItem;
  const isSelectedClassName = 'bg-[#F8F6F6] dark:bg-black';

  return (
    <div
      className={classNames(
        'flex h-full w-full cursor-pointer items-center gap-2 rounded-[4px] pl-6',
        isSelected && isSelectedClassName,
        'transition-colors duration-200 hover:bg-[#F8F6F6] dark:hover:bg-tw-strong-shade-dark',
      )}
    >
      <div className="flex w-6 items-center justify-center truncate">
        {isSelected && (
          <div
            className={classNames(
              '2-[28px',
              'absolute left-0 w-2',
              isSelectedClassName,
            )}
          >
            <HalfRectangleIcon className="text-[#013FFF]" />
          </div>
        )}
        <div className="truncate text-tw-main-text dark:text-tw-main-text-dark">
          {icon}
          {content && sidebarCollapsed ? (
            <span className="absolute right-5 top-0.5 truncate rounded-full border border-white bg-red-500 p-1"></span>
          ) : null}
        </div>
      </div>
      {!sidebarCollapsed && (
        <div className="my-[4px] ml-[8px] mr-[12px] h-[18px] w-[18px] grow truncate text-left text-tw-main-text dark:text-tw-main-text-dark">
          <div className="flex flex-row items-center justify-between truncate font-graphikMedium text-[14px]">
            {title} {content}
          </div>
        </div>
      )}
    </div>
  );
};

const SideNavElementWithTooltip = ({
  item,
  isSelected,
  sidebarCollapsed,
  onClick,
  ...props
}: {
  item: SideNavItemProps;
  isSelected: boolean;
  sidebarCollapsed: boolean;
  onClick: () => void;
}) => (
  <div className="group relative inline-block w-full items-center py-0.5">
    <TooltipTrigger placement="right" delay={0} isDisabled={!sidebarCollapsed}>
      <ActionButton
        width="100%"
        aria-label={item.title}
        key={item.title}
        isQuiet
        onPress={onClick}
        // used for data-testid
        {...props}
      >
        <SideNavElement sideNavItem={item} isSelected={isSelected} />
      </ActionButton>
      <Tooltip>{item.title}</Tooltip>
    </TooltipTrigger>
  </div>
);

export const SideNav = ({
  sideNavGroups = DB_SIDE_NAV_GROUPS,
}: {
  sideNavGroups?: Array<SideNavGroupProps>;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToItem = (newItem: SideNavItemProps) => {
    navigate(newItem.sideNavPath);
  };

  const sidebarCollapsed = useAppSelector(
    (state) => state.sidebarCollapse.collapsed,
  );

  const paths = useMemo(
    () =>
      sideNavGroups
        .map((group) => group.items)
        .flat()
        .map((item) => item.sideNavPath)
        .sort((a, b) => b.length - a.length),
    [sideNavGroups],
  );

  const mostAccuratePath = useMemo(
    () => paths.filter((item) => location.pathname.startsWith(item))[0],
    [paths, location],
  );

  return (
    <div
      data-testid="DBsideNav"
      className={`item-center flex flex-col pr-2 pt-5 transition-all ${
        sidebarCollapsed ? 'w-[80px]' : 'w-[205px]'
      }`}
    >
      {sideNavGroups.map((group) => {
        return (
          <div
            className="mr-[6px] w-full"
            key={group.gridView}
            data-testid={`DBsideNav-grid-area-${group.gridView}`}
          >
            <div className="flex flex-col gap-1">
              {group.items.map((item) => (
                <SideNavElementWithTooltip
                  data-testid={`DBsideNav-grid-area-${group.gridView}-item-${item.title}`}
                  key={item.title}
                  item={item}
                  isSelected={mostAccuratePath === item.sideNavPath}
                  sidebarCollapsed={sidebarCollapsed}
                  onClick={() => {
                    navigateToItem(item);
                  }}
                />
              ))}
              {group.gridView === 'fourth' ? null : (
                <div className="mx-3 border-t border-[#EEF4FF] dark:border-[#292B2F]"></div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
