import { gql } from '@apollo/client';

export const GET_CHART = gql`
  query GetChart(
    $productKind: String!
    $chartCode: String!
    $from: DateTime!
    $appliances: [String!]
  ) {
    chart(
      filter: {
        product: {
          productKind: $productKind
          chartCode: $chartCode
          from: $from
          appliance: $appliances
        }
      }
    ) {
      timeRange
      value
      diffPercentage
      owner {
        ... on Product {
          kind
        }
      }
      data {
        ... on TimedLineChart {
          values {
            time
            value
          }
        }
      }
      code
    }
  }
`;
