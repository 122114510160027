import classNames from 'classnames';
import { JSONSchema7Type } from 'json-schema';
import { Icon } from '../../../assets/icons/Icon';
import { HighlightedText } from '../../common/HighlightedText';
import { Description, FieldError, Label } from '../fields';
import { FormField } from './FormField';
import { ChangeHandler, FormFieldElement, FormFieldType } from './types';

export interface FormElementProps {
  field: FormFieldElement;
  onChange: ChangeHandler;
  searchTerm?: string;
  isFirst?: boolean;
}

export const FormElement = ({
  field,
  onChange,
  searchTerm,
  isFirst = true,
}: FormElementProps) => {
  const label = <HighlightedText text={field.title} searchTerm={searchTerm} />;
  const description = field.description && (
    <HighlightedText text={field.description} searchTerm={searchTerm} />
  );

  switch (field.type) {
    case FormFieldType.GROUP:
      return (
        <div
          key={field.id}
          className={classNames('flex flex-col space-y-4 p-2')}
        >
          <div
            key={field.id}
            className={classNames('flex flex-col space-y-0 px-4')}
          >
            <Label
              htmlFor={field.id}
              size={isFirst ? 'xl' : 'large'}
              className={classNames('flex items-center', isFirst && 'text-xl')}
            >
              {field.iconName && (
                <Icon iconName={field.iconName} className="h-6 w-6" />
              )}
              {label}
            </Label>
            {description && (
              <Description className={classNames(isFirst && 'pb-4')}>
                {description}
              </Description>
            )}
            {isFirst && (
              <hr className="border-t-2 border-t-black/10 dark:border-t-[#292B2F]" />
            )}
          </div>
          {field.fields?.map((subField) => {
            const subFieldValue =
              (field.value && field.value[subField.path?.at(-1) || '']) ||
              subField.value;
            const formFieldElement = {
              ...subField,
              value: subFieldValue,
            } as FormFieldElement;
            return (
              <FormElement
                key={`${field.id}-${subField.id}`}
                field={formFieldElement}
                onChange={onChange}
                searchTerm={searchTerm}
                isFirst={false}
              />
            );
          })}
        </div>
      );
    default:
      return (
        <div
          key={field.id}
          className={classNames(
            'relative',
            'flex flex-col rounded p-4',
            'ring-gray-200 ring-opacity-50 hover:ring-2 dark:ring-gray-100 dark:ring-opacity-20',
            field.error &&
              'bg-red-50 ring-2 ring-red-500 ring-opacity-50 dark:bg-[#2c0b0e] dark:ring-red-500',
          )}
        >
          <Label htmlFor={field.id} size="small">
            {label}
            {field.touched && <span className="text-tw-main-color">*</span>}
          </Label>
          {description && (
            <Description position="top" size="small" className="text-left">
              {description}
            </Description>
          )}
          <FormField field={field} onChange={onChange} />
          {field.info}
          <Examples examples={field.examples} />
          {field.error && <FieldError error={field.error} />}
        </div>
      );
  }
};

export const Examples = ({
  examples,
  title = 'Examples',
}: {
  examples: JSONSchema7Type | undefined;
  title?: string;
}) => {
  const examplesArray = Array.isArray(examples)
    ? examples
    : typeof examples === 'string' || typeof examples === 'number'
    ? [examples]
    : typeof examples === 'object'
    ? []
    : [];

  if (!examples) {
    return null;
  }
  return (
    <Description position="bottom">
      <div className="flex flex-wrap items-center gap-2">
        <span>{title}:</span>
        {examplesArray.map(
          (example) =>
            (typeof example === 'string' || typeof example === 'number') && (
              <span
                key={example}
                className="rounded bg-gray-100 p-2 py-1 text-xs text-tw-description-text dark:bg-gray-800 dark:text-tw-description-text"
              >
                {example}
              </span>
            ),
        )}
      </div>
    </Description>
  );
};
